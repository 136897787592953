
const MetaobjectFieldDefinitionCreateInput = {
  "name": "MetaobjectFieldDefinitionCreateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "key": "String",
    "type": "String",
    "name": "String",
    "description": "String",
    "required": "Boolean",
    "validations": "MetafieldDefinitionValidationInput"
  }
};
export default MetaobjectFieldDefinitionCreateInput;