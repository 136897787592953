
const Model3d = {
  "name": "Model3d",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "alt": "String",
    "boundingBox": "Model3dBoundingBox",
    "filename": "String",
    "id": "ID",
    "mediaContentType": "MediaContentType",
    "mediaErrors": "MediaError",
    "mediaWarnings": "MediaWarning",
    "originalSource": "Model3dSource",
    "preview": "MediaPreviewImage",
    "sources": "Model3dSource",
    "status": "MediaStatus"
  },
  "implementsNode": true
};
export default Model3d;