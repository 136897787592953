
const ProductDuplicatePayload = {
  "name": "ProductDuplicatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "imageJob": "Job",
    "newProduct": "Product",
    "shop": "Shop",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default ProductDuplicatePayload;