
const MetafieldIdentifierInput = {
  "name": "MetafieldIdentifierInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "ownerId": "ID",
    "namespace": "String",
    "key": "String"
  }
};
export default MetafieldIdentifierInput;