
const SellingPlanFixedBillingPolicyInput = {
  "name": "SellingPlanFixedBillingPolicyInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "remainingBalanceChargeTrigger": "SellingPlanRemainingBalanceChargeTrigger",
    "remainingBalanceChargeExactTime": "DateTime",
    "remainingBalanceChargeTimeAfterCheckout": "String",
    "checkoutCharge": "SellingPlanCheckoutChargeInput"
  }
};
export default SellingPlanFixedBillingPolicyInput;