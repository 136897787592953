
const DeliveryMethod = {
  "name": "DeliveryMethod",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "additionalInformation": "DeliveryMethodAdditionalInformation",
    "brandedPromise": "DeliveryBrandedPromise",
    "id": "ID",
    "maxDeliveryDateTime": "DateTime",
    "methodType": "DeliveryMethodType",
    "minDeliveryDateTime": "DateTime",
    "serviceCode": "String"
  },
  "implementsNode": true
};
export default DeliveryMethod;