
const BrandColorGroup = {
  "name": "BrandColorGroup",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "background": "Color",
    "foreground": "Color"
  },
  "implementsNode": false
};
export default BrandColorGroup;