
const FulfillmentOrderLineItem = {
  "name": "FulfillmentOrderLineItem",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "financialSummaries": "FulfillmentOrderLineItemFinancialSummary",
    "id": "ID",
    "image": "Image",
    "inventoryItemId": "ID",
    "lineItem": "LineItem",
    "originalUnitPriceSet": "MoneyBag",
    "productTitle": "String",
    "remainingQuantity": "Int",
    "requiresShipping": "Boolean",
    "sku": "String",
    "totalQuantity": "Int",
    "variantTitle": "String",
    "vendor": "String",
    "warnings": "FulfillmentOrderLineItemWarning",
    "weight": "Weight"
  },
  "implementsNode": true
};
export default FulfillmentOrderLineItem;