
const TaxonomyCategoryEdge = {
  "name": "TaxonomyCategoryEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "TaxonomyCategory"
  },
  "implementsNode": false
};
export default TaxonomyCategoryEdge;