
const SellingPlanAllocationConnection = {
  "name": "SellingPlanAllocationConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "SellingPlanAllocationEdge",
    "nodes": "SellingPlanAllocation",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default SellingPlanAllocationConnection;