
const GiftCardConnection = {
  "name": "GiftCardConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "GiftCardEdge",
    "nodes": "GiftCard",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default GiftCardConnection;