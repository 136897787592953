
const OrderApp = {
  "name": "OrderApp",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "icon": "Image",
    "id": "ID",
    "name": "String"
  },
  "implementsNode": false
};
export default OrderApp;