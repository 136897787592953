
const ReturnShippingFee = {
  "name": "ReturnShippingFee",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "amountSet": "MoneyBag",
    "id": "ID"
  },
  "implementsNode": false
};
export default ReturnShippingFee;