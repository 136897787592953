
const DeliveryProvince = {
  "name": "DeliveryProvince",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "String",
    "id": "ID",
    "name": "String",
    "translatedName": "String"
  },
  "implementsNode": true
};
export default DeliveryProvince;