
const PriceRuleConnection = {
  "name": "PriceRuleConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "PriceRuleEdge",
    "nodes": "PriceRule",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default PriceRuleConnection;