
const OrderConnection = {
  "name": "OrderConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "OrderEdge",
    "nodes": "Order",
    "pageInfo": "PageInfo",
    "totalCount": "UnsignedInt64"
  },
  "implementsNode": false
};
export default OrderConnection;