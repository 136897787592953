
const File = {
  "name": "File",
  "kind": "INTERFACE",
  "fieldBaseTypes": {
    "alt": "String",
    "createdAt": "DateTime",
    "fileErrors": "FileError",
    "fileStatus": "FileStatus",
    "id": "ID",
    "preview": "MediaPreviewImage",
    "updatedAt": "DateTime"
  },
  "possibleTypes": ["GenericFile", "MediaImage", "Video"]
};
export default File;