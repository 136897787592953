
const ShippingRefund = {
  "name": "ShippingRefund",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "amount": "Money",
    "amountSet": "MoneyBag",
    "maximumRefundable": "Money",
    "maximumRefundableSet": "MoneyBag",
    "tax": "Money",
    "taxSet": "MoneyBag"
  },
  "implementsNode": false
};
export default ShippingRefund;