import React, { Component, Fragment } from 'react'
import Breadcrumb from '../../components/common/bread-crumb'
import { Link } from 'react-router-dom'
import moment from 'moment';

import { AuthContext } from '../../auth/auth'
import Loader from '../loader/loader';
import { toast } from 'react-toastify';


class CustomerList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            customers: [],
            loading: true,
            pageInfo: {}
        }
    }

    componentDidMount = () => {
        this.fetchCustomers();
        window.analytics.page();
    }

    fetchCustomers = async (cursor = null) => {

        const fields = `
        customers (first: 50, query:"tag:end_user_of==${this.context.store_subdomain}", ${cursor ? 'after:"' + cursor + '"' : ""}) {
            pageInfo {
                hasPreviousPage
                hasNextPage
            }
            edges {
                cursor
                node {
                    id
                    lastName
                    firstName
                    email
                    state
                    phone
                    tags
                    defaultAddress {
                        address1
                        address2
                        city
                        provinceCode
                        zip
                        country
                    }
                    lastOrder {
                        updatedAt
                    }
                    numberOfOrders
                    amountSpent {
                        amount
                    }
                }
            }
        }`

        const response = await fetch('/.netlify/functions/server/api/get-customers', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ fields })
        });
        try {
            
            if (!response.ok) throw response;

            const data = await response.json();

            if (data.customers) {
                this.setState((state) => ({
                    customers: state.customers.concat(data.customers.edges),
                    pageInfo: data.customers.pageInfo,
                    loading: false
                }))
            }
        }
        catch (err) {
            //Show response error
            if (err.message) {
                toast.error(`${err.message}`);
            } else {
                toast.error(`${err.status}: ${err.statusText}`);
            }
        }
    }

    render() {

        if (this.state.loading) return <Loader />
        const { customers, pageInfo } = this.state

        let rows = []

        for (let index = 0; index < customers.length; index++) {
            const customer = customers[index]
            if (customer) {
                rows.push(
                    <tr key={index} className="table-disable" >
                        <td >
                            <b> {customer.node.firstName} {customer.node.lastName}</b>
                        </td>
                        <td className="text-secondary">
                            {customer.node.email}
                        </td>
                        <td className="text-secondary">
                            {customer.node.defaultAddress ? `${customer.node.defaultAddress.city}, ${customer.node.defaultAddress.provinceCode}` : ""}
                        </td>
                        <td className="text-custom-date"> {customer.node.lastOrder ? moment(new Date(customer.node.lastOrder.updatedAt)).format("MMM  DD, YYYY") : ""} </td>
                        <td>
                            {customer.node.ordersCount}
                        </td>
                        <td>
                            <span> {customer.node.amountSpent.amount}</span>
                        </td>

                        <td width="1%">
                            <Link
                                className="btn btn-outline-primary btn-outline-custom btn-block btn-custom-white"
                                to={{
                                    pathname: `/customers/${btoa(customer.node.id)}`,
                                    state: { customer: customer.node }
                                }}
                            > <b> View</b> </Link>
                        </td>
                    </tr>
                )
            }
        }

        return (
            <Fragment>
                <Breadcrumb mainTitle="Customers" title="" parent="Customers" />
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <h5>Customers</h5>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-md border rounded">
                                    <thead>
                                        <tr>
                                            <th scope="col">Customer</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Location</th>
                                            <th scope="col">Last Order Update</th>
                                            <th scope="col">Orders</th>
                                            <th scope="col">Spent</th>
                                            <th></th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows}
                                        <tr>
                                            <td colSpan="7">
                                                <div className="pull-right">
                                                    {pageInfo.hasNextPage && <a href="javascript:void(0)" onClick={() => this.fetchCustomers(customers[customers.length - 1].cursor)} className="text-primary uppercase mr-1">
                                                        <b> Load more</b>
                                                    </a>}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div >
            </Fragment>
        )
    }
};

CustomerList.contextType = AuthContext

export default CustomerList;