
const MetaobjectUpsertPayload = {
  "name": "MetaobjectUpsertPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "metaobject": "Metaobject",
    "userErrors": "MetaobjectUserError"
  },
  "implementsNode": false
};
export default MetaobjectUpsertPayload;