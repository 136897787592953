
const QuantityPricingByVariantUpdatePayload = {
  "name": "QuantityPricingByVariantUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "productVariants": "ProductVariant",
    "userErrors": "QuantityPricingByVariantUserError"
  },
  "implementsNode": false
};
export default QuantityPricingByVariantUpdatePayload;