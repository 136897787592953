
const CustomerMergePayload = {
  "name": "CustomerMergePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "job": "Job",
    "resultingCustomerId": "ID",
    "userErrors": "CustomerMergeUserError"
  },
  "implementsNode": false
};
export default CustomerMergePayload;