
const CheckoutBrandingSelect = {
  "name": "CheckoutBrandingSelect",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "border": "CheckoutBrandingBorder",
    "typography": "CheckoutBrandingTypographyStyle"
  },
  "implementsNode": false
};
export default CheckoutBrandingSelect;