import React, { Component } from "react";
import Breadcrumb from "../../components/common/bread-crumb";
import { Link } from 'react-router-dom';
import OrderItem from './components/order-item';
import Loader from '../loader/loader';
import { toast } from "react-toastify";

export class OrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      order: {},
      displayAddress: {},
      customer: {},
      taxExemptions: {},
      shippingLine: {},
      channel: {},
      billingAddress: {},
      shippingAddress: {},
      lineItems: [],
      loading: true
    }
    this.getOrderData = this.getOrderData.bind(this);
    this.getOrderData();
  }

  componentDidMount() {
    window.analytics.page();
  }

  getOrderData = async () => {
    const response = await fetch('/.netlify/functions/server/api/get-order-graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        order_id: this.state.id,
      })
    });
    try {
      
      if (!response.ok) throw response;

      const data = await response.json();
      this.setState({
        order: data.data.order,
        displayAddress: data.data.order.displayAddress,
        lineItems: data.data.order.lineItems.edges,
        shippingLine: data.data.order.shippingLine,
        shippingAddress: data.data.order.shippingAddress,
        billingAddress: data.data.order.billingAddress,
        channel: data.data.order.channel,
        loading: false
      });
      if (data.data.order.customer) {
        this.setState({
          customer: data.data.order.customer
        });
      }
    }
    catch (err) {
      //Show response error
      if (err.message) {
        toast.error(`${err.message}`);
      } else {
        toast.error(`${err.status}: ${err.statusText}`);
      }    
    }
  }

  render() {
    if (this.state.loading) return <Loader />
    return (
      <div className="order-detail">
        <Breadcrumb subTitle="Orders" parent="Orders" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header pt-4">
                  <div className="top-buttons pt-1">
                    <div className="top-left">
                      <Link className="btn-custom-link mb-3" to="/orders/">
                        &lt; Orders
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="d-md-flex">
                    <div className="detail-left">
                      <div className="order-info">
                        <h5>Order {this.state.order.name}</h5>
                        <p className="text-secondary">{
                          (this.state.order.createdAt)
                            ? new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "2-digit",
                              hour: 'numeric',
                              minute: 'numeric'
                            }).format(new Date(this.state.order.createdAt))
                            : null
                        }
                        </p>
                        <p className="text-secondary"> from {(this.state.channel) ? this.state.channel.name : "undefined"}</p>
                      </div>

                      {this.state.displayAddress ?
                        (
                          <div className="mt-4">
                            <h6>{this.state.displayAddress.name}</h6>
                            <p className="text-secondary">{this.state.displayAddress.city}, {this.state.displayAddress.province}, {this.state.displayAddress.country}</p>
                            <p className="text-secondary">Customer for {this.state.customer.lifetimeDuration}</p>
                          </div>
                        )
                        :
                        null
                      }

                      {this.state.customer ?
                        (
                          <div className="mt-4">
                            <h6> Customer Overview</h6>
                            <p className="text-primary">{this.state.customer.email}</p>
                            {(this.state.customer.accepts_marketing)
                              ? <p className="text-secondary">Accepts email marketing</p>
                              : <p className="text-secondary">Does not accept email marketing</p>
                            }
                          </div>
                        )
                        :
                        null
                      }

                      {this.state.displayAddress ?
                        (
                          <div className="mt-4">
                            <div className="d-flex justify-content-between align-items-center">
                              <h6> Address </h6>
                            </div>
                            <p>{this.state.displayAddress.name}</p>
                            <p>{this.state.displayAddress.address1}</p>
                            {(this.state.displayAddress.address2)
                              ? <p>{this.state.displayAddress.address2}</p>
                              : null
                            }
                            <p>{this.state.displayAddress.city}, {this.state.displayAddress.province} {this.state.displayAddress.zip}</p>
                            <p>{this.state.displayAddress.country}</p>
                            <p>{this.state.customer.phone}</p>
                          </div>
                        )
                        :
                        (
                          <div className="mt-4">
                            <div className="d-flex justify-content-between align-items-center">
                              <h6> Address </h6>
                            </div>
                            <p>None</p>
                            <p>{this.state.customer ? this.state.customer.phone : null}</p>
                          </div>

                        )
                      }
                      <div className="mt-4">
                        <div className="d-flex justify-content-between align-items-center">
                          <h6>Tax Setting</h6>
                        </div>
                      </div>
                      {
                        (this.state.customer && this.state.customer.taxExempt)
                          ? <p className="text-secondary"> Tax exempt </p>
                          : <p className="text-secondary"> No exemption </p>
                      }
                    </div>
                    <div className="detail-right w-100 border offset-md-1">
                      <p className="mt-1"> <span className="text-secondary">This order is currently</span> {this.state.order.displayFulfillmentStatus} </p>
                      <div className="table-responsive">
                        <table className="table table-md">
                          <thead>
                            <tr>
                              <th scope="col">Description</th>
                              <th scope="col">Status</th>
                              <th scope="col" className="align-right text-right">
                                Total
                                  </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.lineItems.map((val, index) => (
                              <tr key={index}>
                                <td>
                                  <OrderItem
                                    customAttributes={val.node.customAttributes}
                                    imgSrc={(val.node.image) ? val.node.image.transformedSrc : null}
                                    title={val.node.title}
                                    size={(val.node.variant && val.node.variant.selectedOptions.length > 0) ? val.node.variant.selectedOptions[0].value : null}
                                    quantity={val.node.quantity}
                                    material={(val.node.variant && val.node.variant.selectedOptions.length > 1) ? val.node.variant.selectedOptions[1].value : null}
                                    cut={(val.node.variant && val.node.variant.selectedOptions.length > 2) ? val.node.variant.selectedOptions[2].value : null}
                                    source={"artwork_file_name.ai"}
                                  />
                                </td>
                                <td>
                                  <p>{val.node.fulfillmentStatus}</p>
                                </td>
                                <td>
                                  ${((parseFloat(val.node.originalUnitPriceSet.presentmentMoney.amount) * val.node.quantity) - parseFloat(val.node.totalDiscountSet.presentmentMoney.amount)).toFixed(2)}
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td></td>
                              <td>
                                <p className="mt-1 text-nowrap"><b>Sub total:</b></p>
                                <p className="mt-1 text-nowrap"><b>Shipping:</b></p>
                                <p className="mt-1 text-nowrap"><b>Sales Tax:</b></p>
                                <p className="mt-1 text-nowrap mb-4"><b>Order total: </b></p>
                              </td>
                              <td>
                                <p className="mt-1">${this.state.order.subtotalPriceSet ? parseFloat(this.state.order.subtotalPriceSet.presentmentMoney.amount).toFixed(2) : 0}</p>
                                <p className="mt-1">${this.state.order.totalShippingPriceSet ? parseFloat(this.state.order.totalShippingPriceSet.presentmentMoney.amount).toFixed(2) : 0}</p>
                                <p className="mt-1">${this.state.order.totalTaxSet ? parseFloat(this.state.order.totalTaxSet.presentmentMoney.amount).toFixed(2) : 0}</p>
                                <p className="mt-1">${this.state.order.totalPrice ? parseFloat(this.state.order.totalPrice).toFixed(2) : 0}</p>
                              </td>
                            </tr>
                            <tr>
                              {this.state.shippingAddress ?
                                (
                                  <td>
                                    <p><b>Shipping info:</b></p>
                                    <p className="text-secondary mt-1">{this.state.shippingAddress.name}</p>
                                    <p className="text-secondary mt-1">{this.state.shippingAddress.address1} {this.state.shippingAddress.address2}</p>
                                    <p className="text-secondary mt-1">{this.state.shippingAddress.city}, {this.state.shippingAddress.province} {this.state.shippingAddress.zip}</p>
                                    <p className="text-secondary mt-1">{(this.state.shippingLine) ? this.state.shippingLine.title : ""}</p>
                                  </td>
                                )
                                :
                                (
                                  <td>
                                    <p><b>Shipping info:</b></p>
                                    <p className="text-secondary mt-1">None</p>
                                  </td>
                                )
                              }

                              {this.state.billingAddress ?
                                (<td>
                                  <p><b>Billing info:</b></p>
                                  <p className="text-secondary mt-1">{this.state.billingAddress.name}</p>
                                  <p className="text-secondary mt-1">{this.state.billingAddress.address1} {this.state.billingAddress.address2}</p>
                                  <p className="text-secondary mt-1">{this.state.billingAddress.city}, {this.state.billingAddress.province} {this.state.billingAddress.zip}</p>
                                </td>
                                )
                                :
                                (
                                  <td>
                                    <p><b>Billing info:</b></p>
                                    <p className="text-secondary mt-1">None</p>
                                  </td>
                                )
                              }
                              <td>
                              </td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrderDetail;
