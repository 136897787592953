
const InContextAnnotationType = {
  "name": "InContextAnnotationType",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "kind": "String",
    "name": "String"
  },
  "implementsNode": false
};
export default InContextAnnotationType;