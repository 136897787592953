
const CustomerJourney = {
  "name": "CustomerJourney",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customerOrderIndex": "Int",
    "daysToConversion": "Int",
    "firstVisit": "CustomerVisit",
    "lastVisit": "CustomerVisit",
    "moments": "CustomerMoment"
  },
  "implementsNode": false
};
export default CustomerJourney;