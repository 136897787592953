import React, { Component } from 'react';
import { ReactComponent as AddIcon } from "../../../assets/elegant_font/images/SVG/icon_plus_alt2.svg";
import { ReactComponent as CloseIcon } from "../../../assets/elegant_font/images/SVG/icon_minus_alt2.svg"

class CustomContentDisplay extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  handleBtnClick = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen
    });
  }
  render() {
    const { header, children } = this.props;
    const { isOpen } = this.state;

    return (
      <div className="custom-content-display">
        <div className="content-header d-flex justify-content-between">
          <h6><a href="javascript:void(0)" onClick={this.handleBtnClick} >{header}</a></h6>
          <a href="javascript:void(0)" onClick={this.handleBtnClick}  >
          {isOpen? 
            <CloseIcon width={16} height={16} /> 
            : <AddIcon width={16} height={16} /> }
          </a>
        </div>
        <div 
          className="content-body" 
          style={{display: isOpen? "block": "none"}}
          >
          {children}
        </div>
      </div>
    )
  }
}

export default CustomContentDisplay;