
const ReverseFulfillmentOrderLineItem = {
  "name": "ReverseFulfillmentOrderLineItem",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "dispositions": "ReverseFulfillmentOrderDisposition",
    "fulfillmentLineItem": "FulfillmentLineItem",
    "id": "ID",
    "totalQuantity": "Int"
  },
  "implementsNode": true
};
export default ReverseFulfillmentOrderLineItem;