
const InventoryLevel = {
  "name": "InventoryLevel",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "canDeactivate": "Boolean",
    "createdAt": "DateTime",
    "deactivationAlert": "String",
    "id": "ID",
    "item": "InventoryItem",
    "location": "Location",
    "quantities": "InventoryQuantity",
    "scheduledChanges": "InventoryScheduledChangeConnection",
    "updatedAt": "DateTime"
  },
  "implementsNode": true
};
export default InventoryLevel;