
const Shop = {
  "name": "Shop",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "alerts": "ShopAlert",
    "allProductCategories": "ProductCategory",
    "analyticsToken": "String",
    "assignedFulfillmentOrders": "FulfillmentOrderConnection",
    "availableChannelApps": "AppConnection",
    "billingAddress": "ShopAddress",
    "channelDefinitionsForInstalledChannels": "AvailableChannelDefinitionsByChannel",
    "channels": "ChannelConnection",
    "checkoutApiSupported": "Boolean",
    "collectionByHandle": "Collection",
    "collectionSavedSearches": "SavedSearchConnection",
    "collections": "CollectionConnection",
    "contactEmail": "String",
    "countriesInShippingZones": "CountriesInShippingZones",
    "createdAt": "DateTime",
    "currencyCode": "CurrencyCode",
    "currencyFormats": "CurrencyFormats",
    "currencySettings": "CurrencySettingConnection",
    "customerAccounts": "ShopCustomerAccountsSetting",
    "customerAccountsV2": "CustomerAccountsV2",
    "customerSavedSearches": "SavedSearchConnection",
    "customerTags": "StringConnection",
    "customers": "CustomerConnection",
    "description": "String",
    "domains": "Domain",
    "draftOrderSavedSearches": "SavedSearchConnection",
    "draftOrderTags": "StringConnection",
    "draftOrders": "DraftOrderConnection",
    "email": "String",
    "enabledPresentmentCurrencies": "CurrencyCode",
    "features": "ShopFeatures",
    "fulfillmentOrders": "FulfillmentOrderConnection",
    "fulfillmentServices": "FulfillmentService",
    "ianaTimezone": "String",
    "id": "ID",
    "inventoryItems": "InventoryItemConnection",
    "limitedPendingOrderCount": "LimitedPendingOrderCount",
    "locations": "LocationConnection",
    "marketingEvents": "MarketingEventConnection",
    "marketingSmsConsentEnabledAtCheckout": "Boolean",
    "merchantApprovalSignals": "MerchantApprovalSignals",
    "metafield": "Metafield",
    "metafields": "MetafieldConnection",
    "myshopifyDomain": "String",
    "name": "String",
    "navigationSettings": "NavigationItem",
    "orderNumberFormatPrefix": "String",
    "orderNumberFormatSuffix": "String",
    "orderSavedSearches": "SavedSearchConnection",
    "orderTags": "StringConnection",
    "orders": "OrderConnection",
    "paymentSettings": "PaymentSettings",
    "plan": "ShopPlan",
    "priceRuleSavedSearches": "SavedSearchConnection",
    "priceRules": "PriceRuleConnection",
    "primaryDomain": "Domain",
    "privateMetafield": "PrivateMetafield",
    "privateMetafields": "PrivateMetafieldConnection",
    "productByHandle": "Product",
    "productImages": "ImageConnection",
    "productSavedSearches": "SavedSearchConnection",
    "productTags": "StringConnection",
    "productTypes": "StringConnection",
    "productVariants": "ProductVariantConnection",
    "productVendors": "StringConnection",
    "products": "ProductConnection",
    "publicationCount": "Int",
    "resourceLimits": "ShopResourceLimits",
    "richTextEditorUrl": "URL",
    "search": "SearchResultConnection",
    "searchFilters": "SearchFilterOptions",
    "setupRequired": "Boolean",
    "shipsToCountries": "CountryCode",
    "shopPolicies": "ShopPolicy",
    "staffMembers": "StaffMemberConnection",
    "storefrontAccessTokens": "StorefrontAccessTokenConnection",
    "storefrontUrl": "URL",
    "taxShipping": "Boolean",
    "taxesIncluded": "Boolean",
    "timezoneAbbreviation": "String",
    "timezoneOffset": "String",
    "timezoneOffsetMinutes": "Int",
    "transactionalSmsDisabled": "Boolean",
    "translations": "Translation",
    "unitSystem": "UnitSystem",
    "updatedAt": "DateTime",
    "uploadedImagesByIds": "Image",
    "url": "URL",
    "weightUnit": "WeightUnit"
  },
  "implementsNode": true
};
export default Shop;