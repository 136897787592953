
const MetaobjectCreateInput = {
  "name": "MetaobjectCreateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "type": "String",
    "handle": "String",
    "fields": "MetaobjectFieldInput",
    "capabilities": "MetaobjectCapabilityDataInput"
  }
};
export default MetaobjectCreateInput;