
const TranslatableResource = {
  "name": "TranslatableResource",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "resourceId": "ID",
    "translatableContent": "TranslatableContent",
    "translations": "Translation"
  },
  "implementsNode": false
};
export default TranslatableResource;