
const MarketingActivityCreateInput = {
  "name": "MarketingActivityCreateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "marketingActivityTitle": "String",
    "formData": "String",
    "marketingActivityExtensionId": "ID",
    "context": "String",
    "utm": "UTMInput",
    "status": "MarketingActivityStatus",
    "budget": "MarketingActivityBudgetInput"
  }
};
export default MarketingActivityCreateInput;