
const DiscountItemsInput = {
  "name": "DiscountItemsInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "products": "DiscountProductsInput",
    "collections": "DiscountCollectionsInput",
    "all": "Boolean"
  }
};
export default DiscountItemsInput;