
const MetaobjectSEO = {
  "name": "MetaobjectSEO",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "description": "MetaobjectField",
    "title": "MetaobjectField"
  },
  "implementsNode": false
};
export default MetaobjectSEO;