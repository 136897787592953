
const CustomerEmailMarketingConsentUpdatePayload = {
  "name": "CustomerEmailMarketingConsentUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customer": "Customer",
    "userErrors": "CustomerEmailMarketingConsentUpdateUserError"
  },
  "implementsNode": false
};
export default CustomerEmailMarketingConsentUpdatePayload;