
const QuantityPriceBreakConnection = {
  "name": "QuantityPriceBreakConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "QuantityPriceBreakEdge",
    "nodes": "QuantityPriceBreak",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default QuantityPriceBreakConnection;