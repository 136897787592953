
const CheckoutBrandingHeaderCartLink = {
  "name": "CheckoutBrandingHeaderCartLink",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "contentType": "CheckoutBrandingCartLinkContentType",
    "image": "Image"
  },
  "implementsNode": false
};
export default CheckoutBrandingHeaderCartLink;