
const ShopifyPaymentsBankAccount = {
  "name": "ShopifyPaymentsBankAccount",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "accountNumber": "String",
    "accountNumberLastDigits": "String",
    "bankName": "String",
    "country": "CountryCode",
    "createdAt": "DateTime",
    "currency": "CurrencyCode",
    "id": "ID",
    "payouts": "ShopifyPaymentsPayoutConnection",
    "routingNumber": "String",
    "status": "ShopifyPaymentsBankAccountStatus"
  },
  "implementsNode": true
};
export default ShopifyPaymentsBankAccount;