
const MetaobjectFieldDefinition = {
  "name": "MetaobjectFieldDefinition",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "description": "String",
    "key": "String",
    "name": "String",
    "required": "Boolean",
    "type": "MetafieldDefinitionType",
    "validations": "MetafieldDefinitionValidation"
  },
  "implementsNode": false
};
export default MetaobjectFieldDefinition;