
const MarketingActivityDeleteExternalPayload = {
  "name": "MarketingActivityDeleteExternalPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedMarketingActivityId": "ID",
    "userErrors": "MarketingActivityUserError"
  },
  "implementsNode": false
};
export default MarketingActivityDeleteExternalPayload;