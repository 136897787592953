
const CollectionUnpublishPayload = {
  "name": "CollectionUnpublishPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "collection": "Collection",
    "shop": "Shop",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default CollectionUnpublishPayload;