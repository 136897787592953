
const SubscriptionBillingPolicy = {
  "name": "SubscriptionBillingPolicy",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "anchors": "SellingPlanAnchor",
    "interval": "SellingPlanInterval",
    "intervalCount": "Int",
    "maxCycles": "Int",
    "minCycles": "Int"
  },
  "implementsNode": false
};
export default SubscriptionBillingPolicy;