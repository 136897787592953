
const CustomerPaypalBillingAgreement = {
  "name": "CustomerPaypalBillingAgreement",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "billingAddress": "CustomerPaymentInstrumentBillingAddress",
    "inactive": "Boolean",
    "isRevocable": "Boolean",
    "paypalAccountEmail": "String"
  },
  "implementsNode": false
};
export default CustomerPaypalBillingAgreement;