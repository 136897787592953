
const OrderCaptureInput = {
  "name": "OrderCaptureInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "id": "ID",
    "parentTransactionId": "ID",
    "amount": "Money",
    "currency": "CurrencyCode"
  }
};
export default OrderCaptureInput;