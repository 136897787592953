import React, { Component, Fragment } from "react";
import { AuthContext } from "../../auth/auth";
import Breadcrumb from "../../components/common/bread-crumb";
import CKEditors from "react-ckeditor-component";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Link } from 'react-router-dom'
import ProductVariantForm from "./components/product-variant-form";
import ProductPhotoUpload from "./components/product-photo-upload";
import GeneralInput from '../../components/common/general-input';
import { updateProduct, updateProductMarkup, getPriceGrid } from '../../components/utils/product';
import { Alert } from "reactstrap";
import { EmbedCode } from '../common/embed-code';
import Loader from "../loader/loader";
import FormHandleInput from "../common/form-handle-input";
import { getNetlifySiteSettings } from "../../querys/netlify-rest";
import { Eye } from "react-feather";
import { toast } from "react-toastify";
import { validatePricingJson } from "../utils";

export class ProductAdd extends Component {

  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      content: "",
      product: {},
      loading: true,
      selectedVariants: [],
      ckeditorInstance: null,
      productMarkupValue: null,
      alert: false,
      alertMessage: "",
      alertColor: "",
      imageAlert: false,
      imageAlertMessage: "",
      imageAlertColor: "",
      saveAlert: false,
      saveAlertMessage: "",
      saveAlertColor: "",
      markupHasChanged: false,
      priceGrid: null,
      siteUrl: "",
      isLotPricing: false
    };
  }

  componentDidMount = async () => {

    if (!this.props.location.state && !this.props.match.params.handle) {
      //If comes directly from URL send back to products list
      this.props.history.push(`/products`)
      return;
    }

    this._isMounted = true;

    this.ckeditorRef = React.createRef()

    let handle = ""

    //Comes from product list page
    if (this.props.location.state) {

      handle = this.props.location.state.handle

      // product.id = atob(product.id)

      const { history } = this.props

      history.replace({
        ...this.props.location,
        state: undefined
      })
    }

    if (this.props.match.params.handle) { //Comes from unknown page - fetch product

      handle = this.props.match.params.handle
    }

    const fields = `id
        storefrontId
        title
        productType
        descriptionHtml
        description
        handle
        tags
        seo {
          title
          description
        }
        collections (first: 10) {
            edges {
                node {
                    id
                    title
                }
            }
        }
        metafields (first: 10) {
          edges {
            node {
              key
              value
            }
          }
        }
        variants (first: 100) {
            edges {
                node {
                    id
                    sku
                    inventoryPolicy
                    price
                    selectedOptions {
                        name
                        value
                    }
                }
            }
        }
        options {
            id
            name
        }
        images (first: 10) {
            edges {
                node {
                    id
                    src
                }
            }    
        }`
    const response = await fetch('/.netlify/functions/server/api/get-product-by-handle', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ handle, fields })
    });

    try {
      if (!response.ok) throw response;
      const data = await response.json();

      const product = data.data.productByHandle

        const priceGridIndex = product && product.tags.findIndex(t => t.includes("$="))
        if (priceGridIndex > -1) {
          const priceGridHandle = product && product.tags[priceGridIndex].split("$=")[1];
          const priceGrid = await getPriceGrid(priceGridHandle);

          const { isJsonValid, errorMessage } = validatePricingJson(priceGrid, product.variants.edges);

          if (!isJsonValid) {
            if (process.env.REACT_APP_URL_PRICING_JSON) {
              toast.error(`Pricing JSON contains errors: \n${errorMessage}`, { className: "pricing-error" });
            } else {
              console.log(`Pricing JSON contains errors: \n${errorMessage}`);
            }
          } else {
            this.setState({ priceGrid, isLotPricing: product.tags.includes("Lot Pricing") });
          }
        } else {
          toast.error(`The product ${product.title} doesn't contain the tag $=\nPlease add tag in order to retrieve JSON pricing file.`, { className: "pricing-error" });
        }

      const productMarkupValue = this.getProductPriceMarkup(product)

      if (product) {

        const customCollection = product.collections.edges.find(c => c.node.handle === this.context.reseller_collection_handle)

        const active = customCollection ? true : false
        if (this._isMounted) {
          this.saveProductInState({ product, active, productMarkupValue })
        }
      }

      let siteUrl = `${this.context.store_subdomain}.readysetprint.com`;

      this.setState({
        siteUrl
      }, () => {
        getNetlifySiteSettings(this.context.reseller_site_id)
          .then(result => {
            const response = JSON.parse(result.request.response);
            if (response.domain_aliases.length > 0) {
              response.domain_aliases.forEach(alias => {
                if (alias.indexOf("whitelabellabels.com") === -1 && alias.indexOf("readysetprint.com") === -1) {
                  this.setState({
                    siteUrl: alias
                  });
                  return;
                }
              });
            }
          });
        
        window.analytics.page();
      })
    }
    catch (err) {
      //Show response error
      if (err.message) {
        toast.error(`${err.message}`);
      } else {
        toast.error(`${err.status}: ${err.statusText}`);
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  saveProductInState = async ({ product, active, productMarkupValue }) => {

    let seoTitle = ""
    let seoDescription = ""
    let metafields = []

    if (this.ckeditorRef.editorInstance)
      this.ckeditorRef.editorInstance.setData(product.descriptionHtml)

    metafields = product.metafields.edges

    if (metafields.length > 0) {

      const availableSeoTitleItem = metafields.find(m => m.node.key === 'title_tag')
      if (availableSeoTitleItem) {
        seoTitle = availableSeoTitleItem.node.value
      }
      const availableSeoDescriptionItem = metafields.find(m => m.node.key === 'description_tag')
      if (availableSeoDescriptionItem) {
        seoDescription = availableSeoDescriptionItem.node.value
      }
    }

    this.setState({
      product: {
        ...product,
        active,
        seoTitle,
        seoDescription,
        variants: product.variants,
      },
      productMarkupValue,
      loading: false,
    })
  }

  getProductPriceMarkup = (product) => {
    const productTags = product && product.tags;
    if (productTags && productTags.length > 0) {
      for (const tag of productTags) {
        if (tag.indexOf("markup=") !== -1) {
          const productMarkup = tag.split("=")[1];
          if (productMarkup && productMarkup !== "undefined") {
            return productMarkup;
          } else {
            return "0";
          }
        }
      }
    }

    const localStorageMarkup = this.context.globalMarkup
    if (localStorageMarkup && localStorageMarkup !== "undefined") {
      return localStorageMarkup;
    }
    return "0";
  };

  handleValidSubmit = async (_event, values) => {

    let descriptionHtml = this.ckeditorRef ? this.ckeditorRef.editorInstance.getData() : ""
    this.setState({
      saveAlert: true,
      saveAlertColor: "secondary",
      saveAlertMessage: "Saving product..."
    });

    let { product } = this.state

    const handleHasChanged = product.handle !== values.handle;

    let { products, activeProducts, setContextProducts } = this.context

    product = {
      ...product,
      ...values,
      descriptionHtml
    }

    this.setState({
      product
    })

    //if context available, update context
    if (activeProducts.length > 0) {
      const pIndex = activeProducts.findIndex(p => p.node.id === product.id)
      if (pIndex > -1) {
        activeProducts.splice(pIndex, 1,
          {
            ...activeProducts[pIndex],
            node: { ...product, id: product.id }
          })
      }

      const pIndex2 = products.findIndex(p => p.node.id === product.id);
      if (pIndex2 > -1) {
        products.splice(pIndex, 1,
          {
            ...products[pIndex],
            node: { ...product, id: product.id }
          });
      }
      setContextProducts({ activeProducts, products })

    }

    const updatedProduct = await updateProduct(product);

    if (updatedProduct.product) {

      if (this.state.markupHasChanged) await this.updateProductMarkup(product, this.state.productMarkupValue);

      if (this._isMounted) {
        this.setState({
          saveAlert: true,
          saveAlertColor: "success",
          saveAlertMessage: "Product saved!",
          loading: false
        });

        if (handleHasChanged) {
          this.props.history.push(`/products/${updatedProduct.product.handle}`)
        }
      }
    } else {
      this.setState({
        saveAlert: true,
        saveAlertColor: "danger",
        saveAlertMessage: "Error: product wasn't updated. Please try again.",
        loading: false
      });
    }
  }

  onOptionChange = (variant, name, value) => {

    const { product } = this.state

    let changedOptionIndex = variant.selectedOptions.findIndex(option => option.name === name)
    variant.selectedOptions[changedOptionIndex].value = value

    const variantIndex = product.variants.edges.findIndex(v => v.node.id === variant.id)
    product.variants.edges[variantIndex].node = variant

    this.setState({
      product
    })

  }

  onVariantSkuChange = (variant, value) => {

    const { product } = this.state

    let tagIndex = product.tags.findIndex(t => t.includes("r-sku="))

    if (tagIndex > -1) {

      product.tags[tagIndex] = `r-sku=${value}`
    } else {
      let tag = `r-sku=${value}`
      product.tags.push(tag)
    }

    this.setState({
      product
    })

  }

  setProductActive = (active) => {
    const { product } = this.state

    this.setState({
      product: {
        ...product,
        active
      }
    })
  }

  toggleCustomizableTag = (ev) => {

    const { product } = this.state

    if (ev.target.checked) {
      product.tags.push("Customizable")
    } else {
      const customizableIndex = product.tags.findIndex(tag => tag === "Customizable")
      if (customizableIndex > -1)
        product.tags.splice(product.tags.findIndex(tag => tag === "Customizable"), 1)
    }

    this.setState({
      product
    })
  }

  onImageChange = async (newImage, error = null) => {

    if (error) {
      if (error.error.type === "size") {
        this.setState({
          imageAlert: true,
          imageAlertColor: "danger",
          imageAlertMessage: "Error: image could not be uploaded. Please try again with a smaller image."
        });
      }
      return;
    }

    let { product } = this.state;
    let r = null
    try {
      const response = await fetch('/.netlify/functions/server/api/update-product-image', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ productId: product.id.replace("gid://shopify/Product/", ""), image: newImage })
      });

      if (!response.ok) throw response;

      r = await response.json()
    } catch (err) {
      this.setState({
        imageAlert: true,
        imageAlertColor: "danger",
        imageAlertMessage: "Error: image could not be uploaded. Please try again with a smaller image."
      });
    }

    if (r && r.image) {
      if (newImage.id) {
        product.images.edges.splice(product.images.edges.findIndex(i => i.node.id === newImage.id), 1, { node: { id: `gid://shopify/ProductImage/${r.image.id}`, src: r.image.src } })
      } else {
        product.images.edges.push({
          node: {
            id: `gid://shopify/ProductImage/${r.image.id}`,
            src: r.image.src
          }
        })
      }

      this.setState({ product })

      return r;
    } else {
      this.setState({
        imageAlert: true,
        imageAlertColor: "danger",
        imageAlertMessage: "Error: image could not be uploaded. Please try again with another image."
      })
      return null;
    }
  }

  deleteImage = async (imageId) => {
    try {
      let { product } = this.state;
      const response = await fetch('/.netlify/functions/server/api/delete-product-image', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ productId: product.id.replace("gid://shopify/Product/", ""), imageId: imageId.replace("gid://shopify/ProductImage/", "") })
      });

      if (!response.ok) throw response;

      const r = await response.json()

      const imageIndex = product.images.edges.findIndex(i => i.node.id === imageId);

      if (imageIndex > -1) {
        product.images.edges.splice(imageIndex, 1);
        this.setState({ product });
      }
    } catch (err) {
      //Show response error
      this.setState({
        imageAlert: true,
        imageAlertColor: "danger",
        imageAlertMessage: "Error: image could not be deleted. Please try again."
      });
    }
  }

  onVariantSelectionChange = (checked, variant) => {

    let { selectedVariants, product } = this.state

    const variantIndex = product.variants.edges.findIndex(v => v.node.id === variant.id)

    product.variants.edges[variantIndex].node.inventoryPolicy = checked ? "CONTINUE" : "DENY"

    this.setState({ product })

    this.setState({ selectedVariants })

  }

  updateProductMarkup = async (product, markupInput) => {

    if (markupInput >= -10 && markupInput <= 30) {
      this.setState({
        alert: true,
        alertColor: "secondary",
        alertMessage: "Updating markup..."
      })

      const updatedProduct = await updateProductMarkup(product, markupInput)

      if (updatedProduct) {
        this.setState({
          product: updatedProduct,
          alertColor: "success",
          alertMessage: "Markup updated correctly!",
          markupHasChanged: false
        })
      } else {

        this.setState({
          alertColor: "danger",
          alertMessage: "There was a problem updating your markup. Please try again."
        })
      }
    } else {

      this.setState({
        alertColor: "danger",
        alertMessage: "Invalid markup value. Please choose a value between -10 and 30."
      })
    }
  };

  onBlur = (ev) => {

    if (this.ckeditorRef && this.ckeditorRef.editorInstance)
      this.ckeditorRef.editorInstance.setData(ev.editor.getData())

  }

  updateProductMarkupValue = (ev) => {
    this.setState({
      productMarkupValue: ev.target.value,
      markupHasChanged: true
    })
  }

  toggle() {
    this.setState({
      alert: !this.state.alert
    });
  }

  toggleImageAlert() {
    this.setState({
      imageAlert: !this.state.imageAlert
    });
  }

  toggleSaveAlert() {
    this.setState({
      saveAlert: !this.state.saveAlert
    });
  }

  render() {

    if (this.state.loading)
      return <Loader />

    const { product, selectedVariants, productMarkupValue } = this.state

    return (
      <Fragment>
        <Breadcrumb title="Add products" parent="Products" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="top-buttons d-flex justify-content-between align-items-center flex-nowrap mb-3">
                    <div className="top-left">
                      <Link className="btn-custom-link mb-3" to="/products/">
                        &lt; Products
                      </Link>
                    </div>
                    <div className="top-right d-flex align-items-center">
                      <a class="btn btn-custom-link d-flex align-items-center mr-2 eye-btn" target="_blank" href={`https://${this.state.siteUrl}/products/${product.handle}`} style={{ padding: "0.3rem" }}><Eye /></a>
                      <EmbedCode resourceId={this.state.product.id} type={'product'} />
                      <button type="submit" form="product-form" className="btn btn-primary ml-2 save-btn">Save</button>
                    </div>
                  </div>
                  <Alert className="offset-lg-1" style={{ marginTop: "15px" }} color={this.state.saveAlertColor} isOpen={this.state.saveAlert} toggle={this.toggleSaveAlert.bind(this)} > {this.state.saveAlertMessage} </Alert>
                  <div className="d-flex justify-content-between align-items-center product-title">
                    <h5>Edit Product</h5>
                    <EmbedCode resourceId={this.state.product.id} type={'product'} />
                  </div>
                </div>
                <div className="card-body">
                  <div className="d-flex justify-content-around product-sections">
                    <div className="flex-grow-1 product-image-section" style={{ flexBasis: "30%", width: "50%" }}>
                      <label className="sub-title">
                        Product Images
                      </label>
                      <ProductPhotoUpload images={product.images} onImageChange={this.onImageChange} deleteImage={this.deleteImage} />
                      <p>We recommend using square images that are 1000x1000px, and under 1MB in size.</p>
                      <Alert style={{ marginTop: "15px" }} color={this.state.imageAlertColor} isOpen={this.state.imageAlert} toggle={this.toggleImageAlert.bind(this)} > {this.state.imageAlertMessage} </Alert>
                      <div className="collection-item">
                        <label className="sub-title">Categories</label>
                        {product.collections.edges.map(collection => <div key={`collection-${collection.node.id}`}>
                          <span>{collection.node.title}</span>
                        </div>
                        )}
                      </div>
                    </div>

                    <div className="d-flex flex-grow-1 justify-content-center product-info-section" style={{ flexBasis: "70%", width: "50%" }}>
                      <AvForm
                        className="needs-validation add-product-form"
                        onValidSubmit={this.handleValidSubmit}
                        onInvalidSubmit={this.handleInvalidSubmit}
                        model={product}
                        id="product-form"
                        style={{ maxWidth: "90%", justifyContent: "center" }}
                      >
                        <div className="form form-label-center">
                          <div className="d-flex">
                            <div style={{ flex: "1" }}>
                              <GeneralInput
                                label="Product name"
                                name="title"
                                placeholder="ProductName"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group ">
                          <label className=""> Description</label>
                          <div className="description-sm">
                            <CKEditors
                              ref={instance => { this.ckeditorRef = instance }}
                              activeclassName="p10"
                              content={this.ckeditorRef && this.ckeditorRef.editorInstance ? this.ckeditorRef.editorInstance.getData() : product.descriptionHtml}

                              events={{
                                blur: this.onBlur,
                                afterPaste: this.afterPaste
                              }}
                            />
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="form-group mr-md-5 mr-sm-1">
                            <input
                              className="checkbox_animated"
                              id="chk-ani2"
                              type="checkbox"
                              name="customizable"
                              checked={product.tags.findIndex(tag => tag === "Customizable") > -1}
                              onChange={this.toggleCustomizableTag}
                            />
                            Customizable?
                          </div>
                        </div>


                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label className="mb-2">Increase/Decrease MSRP on this product:</label>
                              <div className="row">
                                <div className="form-field col-sm-6">
                                  <AvField
                                    className="form-control mb-0"
                                    type="text"
                                    name="productMarkup"
                                    placeholder="-10% to 30%"
                                    value={productMarkupValue}
                                    required
                                    min="-10"
                                    max="30"
                                    id="productMarkup"
                                    onChange={this.updateProductMarkupValue}
                                  />
                                </div>
                                <div className="col-sm-6 d-flex align-items-start">
                                  <button
                                    className="btn btn-outline-primary btn-custom-white"
                                    type="button"
                                    onClick={() => this.updateProductMarkup(product, productMarkupValue)}>Save</button>
                                </div>
                              </div>
                              <Alert style={{ marginTop: "15px" }} color={this.state.alertColor} isOpen={this.state.alert} toggle={this.toggle.bind(this)} > {this.state.alertMessage} </Alert>
                            </div>
                          </div>
                        </div>


                        <div className="variant mt-4">
                          <div className="mb-3 sub-title">Variants</div>
                          {product.variants.edges.map(variant =>
                            <ProductVariantForm
                              variant={variant.node}
                              options={product.options}
                              onVariantSkuChange={this.onVariantSkuChange}
                              onVariantSelectionChange={this.onVariantSelectionChange}
                              selectedVariants={selectedVariants}
                              product={product}
                              key={`variant-${variant.node.id}`}
                              priceGrid={this.state.priceGrid}
                              isLotPricing={this.state.isLotPricing}
                              productMarkupValue={productMarkupValue} />
                          )}
                        </div>

                        <div className="mt-5">
                          <div className="mb-4 sub-title">Search engine listing preview</div>
                          <GeneralInput
                            label="Page Title"
                            name="seoTitle"
                            placeholder="Page Title"
                            maxCount={72}
                            required={false}
                          />
                          <GeneralInput
                            label="Description"
                            name="seoDescription"
                            placeholder="Description"
                            required={false}
                            maxCount={320}
                          />
                          <div className="form-group">
                            <FormHandleInput labelText="URL Handle" url={`https://${this.context.store_subdomain}.readysetprint.com/products/`} required={true} name="handle" />
                          </div>
                        </div>
                        <button
                          className="btn btn-primary"
                        >
                          Save
                            </button>
                        <Alert style={{ marginTop: "15px" }} color={this.state.saveAlertColor} isOpen={this.state.saveAlert} toggle={this.toggleSaveAlert.bind(this)} > {this.state.saveAlertMessage} </Alert>
                      </AvForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </Fragment >
    );
  }
}

ProductAdd.contextType = AuthContext

export default ProductAdd;
