
const PublicationCreateInput = {
  "name": "PublicationCreateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "catalogId": "ID",
    "defaultState": "PublicationCreateInputPublicationDefaultState",
    "autoPublish": "Boolean"
  }
};
export default PublicationCreateInput;