
const QuantityPriceBreakEdge = {
  "name": "QuantityPriceBreakEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "QuantityPriceBreak"
  },
  "implementsNode": false
};
export default QuantityPriceBreakEdge;