
const PolarisVizResponse = {
  "name": "PolarisVizResponse",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "data": "PolarisVizDataSeries",
    "parseErrors": "ParseError",
    "tableData": "TableData",
    "vizType": "VisualizationType"
  },
  "implementsNode": false
};
export default PolarisVizResponse;