
const CheckoutBrandingLogoInput = {
  "name": "CheckoutBrandingLogoInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "image": "CheckoutBrandingImageInput",
    "maxWidth": "Int",
    "visibility": "CheckoutBrandingVisibility"
  }
};
export default CheckoutBrandingLogoInput;