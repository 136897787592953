
const ShopifyFunctionConnection = {
  "name": "ShopifyFunctionConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "ShopifyFunctionEdge",
    "nodes": "ShopifyFunction",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default ShopifyFunctionConnection;