
const CheckoutBrandingDesignSystem = {
  "name": "CheckoutBrandingDesignSystem",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "colors": "CheckoutBrandingColors",
    "cornerRadius": "CheckoutBrandingCornerRadiusVariables",
    "typography": "CheckoutBrandingTypography"
  },
  "implementsNode": false
};
export default CheckoutBrandingDesignSystem;