
const MarketRegion = {
  "name": "MarketRegion",
  "kind": "INTERFACE",
  "fieldBaseTypes": {
    "id": "ID",
    "name": "String"
  },
  "possibleTypes": ["MarketRegionCountry"]
};
export default MarketRegion;