import React, { Component, Fragment } from "react";
import Breadcrumb from "../../components/common/bread-crumb";
import classnames from "classnames";
import { Link } from 'react-router-dom';
import { AuthContext } from "../../auth/auth";
import { toast } from "react-toastify";

class OrderList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: {
        hasPreviousPage: false,
        hasNextPage: false,
        cursor: null
      }
    };
  }

  async componentDidMount() {
    this.fetchOrders();
    window.analytics.page();
  }

  fetchOrders = async (cursor = null) => {
    const response = await fetch(`/.netlify/functions/server/api/orders-by-reseller-id`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          reseller_id: this.context.store_subdomain,
          cursor
        })
      });
    try {
      if (!response.ok) {
        throw response;
      }

      const json = await response.json();
      var orders = this.state.data;
      for (let i = 0; i < json.data.orders.edges.length; i++) {
        var order = {
          id: json.data.orders.edges[i].node.legacyResourceId,
          email: (json.data.orders.edges[i].node.customer) ? json.data.orders.edges[i].node.customer.email : null,
          name: (json.data.orders.edges[i].node.customer) ? json.data.orders.edges[i].node.customer.displayName : null,
          order_name: json.data.orders.edges[i].node.name,
          price: json.data.orders.edges[i].node.totalPrice,
          created_at: json.data.orders.edges[i].node.createdAt,
          fulfillment_status: json.data.orders.edges[i].node.displayFulfillmentStatus
        }
        orders.push(order);
      }
      let cursor = null;
      if (json.data.orders.edges.length > 0) {
        cursor = json.data.orders.edges[json.data.orders.edges.length - 1].cursor;
      }
      this.setState({
        data: orders,
        pages: {
          hasPreviousPage: json.data.orders.pageInfo.hasPreviousPage,
          hasNextPage: json.data.orders.pageInfo.hasNextPage,
          cursor
        }
      });
      window.analytics.page();
    }
    catch (err) {
      //Show response error
      if (err.message) {
        toast.error(`${err.message}`);
      } else {
        toast.error(`${err.status}: ${err.statusText}`);
      }    
    }
  }

  render() {
    const { pages } = this.state;
    return (
      <Fragment>
        <Breadcrumb mainTitle="Orders" parent="Orders" />
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h5>Orders</h5>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-md border rounded">
                  <thead>
                    <tr>
                      <th scope="col">Order Number</th>
                      <th scope="col">Order Date</th>
                      <th scope="col" className="align-middle">
                        Customer
                      </th>
                      <th scope="col">Status</th>
                      <th scope="col" className="align-right text-right">
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.items = this.state.data.map((val, key) =>
                      <tr
                        key={key}
                        className={classnames({ "table-disable": key > 1 })}
                      >
                        <td width="22%" className="text-primary py-3">
                          <Link to={`/orders/${val.id}`}> <b>{val.order_name}</b> </Link>
                        </td>
                        <td width="18%" className="text-custom-date">
                          {new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            month: "short",
                            day: "2-digit"
                          }).format(new Date(val.created_at))}
                        </td>
                        <td width="37%">{val.name}</td>
                        <td width="20%">
                          {(val.fulfillment_status === 'UNFULFILLED') && (<span className="text-custom-gold">{val.fulfillment_status}</span>)}
                          {(val.fulfillment_status !== 'UNFULFILLED') && (<span className="text-custom-green">{val.fulfillment_status}</span>)}
                        </td>
                        <td width="1%">${val.price}</td>
                      </tr>

                    )}
                    <tr>
                      <td colSpan="5">
                        <div className="pull-right">
                          {pages.hasNextPage && <a href="javascript:void(0)" onClick={() => this.fetchOrders((pages.cursor))} className="text-primary uppercase mr-1">
                            <b>Load more</b>
                          </a>}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

OrderList.contextType = AuthContext;
export default OrderList;
