
const OrderDisputeSummary = {
  "name": "OrderDisputeSummary",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "initiatedAs": "DisputeType",
    "status": "DisputeStatus"
  },
  "implementsNode": true
};
export default OrderDisputeSummary;