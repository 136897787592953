
const MarketingBudget = {
  "name": "MarketingBudget",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "budgetType": "MarketingBudgetBudgetType",
    "total": "MoneyV2"
  },
  "implementsNode": false
};
export default MarketingBudget;