
const ProductDuplicateAsyncInput = {
  "name": "ProductDuplicateAsyncInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "productId": "ID",
    "newTitle": "String",
    "newStatus": "ProductStatus",
    "includeImages": "Boolean"
  }
};
export default ProductDuplicateAsyncInput;