
const InventoryAdjustQuantitiesPayload = {
  "name": "InventoryAdjustQuantitiesPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "inventoryAdjustmentGroup": "InventoryAdjustmentGroup",
    "userErrors": "InventoryAdjustQuantitiesUserError"
  },
  "implementsNode": false
};
export default InventoryAdjustQuantitiesPayload;