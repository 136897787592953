
const ShopifyPaymentsBankAccountConnection = {
  "name": "ShopifyPaymentsBankAccountConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "ShopifyPaymentsBankAccountEdge",
    "nodes": "ShopifyPaymentsBankAccount",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default ShopifyPaymentsBankAccountConnection;