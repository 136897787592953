import React, { Component, Fragment } from 'react';
import { ReactComponent as SquareImg } from '../../assets/svgs/square.svg';
import Breadcrumb from '../../components/common/bread-crumb';
import { storefrontClient } from '../../index';
import * as storefrontQuerys from '../../querys/storefront-graphql';
import { Link } from 'react-router-dom';
import { Trash2, XCircle } from 'react-feather';
import Modal from 'react-responsive-modal';
import { AuthContext } from "../../auth/auth";
import { getNetlifySnippetByTitle, updateNetlifySnippet } from '../../querys/netlify-rest';
import Loader from '../loader/loader';
import { toast } from 'react-toastify';
import { isBase64 } from '../navigation/utils';

const snippetTitle = "Client's Categories"

class CollectionList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            collections: [],
            baseCollection: {
                handle: 'ready-set-print'
            },
            modalDeleteOpen: false,
            fetched: false,
        }

    }

    componentDidMount = () => {
        this.fetchCollections();
        window.analytics.page();
        if (this.context.collections.length <= 0 && this.context.siteSetupStatus !== "BUILDING") {
            this.setState({
                loading: true
            }, () => {
                this.fetchCollections()
            });
        }
    }

    componentDidUpdate = () => {
        if (this.context.collections.length <= 0 && this.context.siteSetupStatus !== "BUILDING" && !this.state.fetched && !this.state.loading) {
            this.setState({
                loading: true
            }, () => {
                this.fetchCollections();
            })
        }
    }

    decodeSnippet = (snippet) => {
        return JSON.parse(snippet.replace("<script>window.cat=", "").replace("</script>", ""))
    }

    encodeSnippet = (content) => {
        return `<script>window.cat=${JSON.stringify(content)}</script>`
    }

    fetchCollections = async () => {

        if (this.context.collections.length <= 0) {
            let collections = []
            const { setContextCollections } = this.context
            getNetlifySnippetByTitle(this.context.reseller_site_id, snippetTitle)
                .then(async (response) => {
                    if (response.id && response.general) {
                        // Update the state with the Netlify snippet data
                        //Decode snippet
                        const collectionIDs = this.decodeSnippet(response.general)

                        const fields = `
                        id
                        title
                        description
                        handle
                    `

                        for (let i = 0; i < collectionIDs.length; i++) {

                            const id = collectionIDs[i]

                            const response = await fetch('/.netlify/functions/server/api/get-collection-by-id', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({ id, fields })
                            });
                            try {
                                if (!response.ok) throw response;
                                const data = await response.json();

                                if (data.collection) collections.push(data.collection)
                            }
                            catch (err) {
                                //Show response error
                                if (err.message) {
                                    toast.error(`${err.message}`);
                                } else {
                                    toast.error(`${err.status}: ${err.statusText}`);
                                }
                            }
                        }

                        this.setState({ loading: false, fetched: true })
                        setContextCollections({ collections });
                    } else {
                        this.setState({ loading: false, fetched: true })
                    }
                });
        } else {
            this.setState({ loading: false, fetched: false });
        }
    }

    onOpenDeleteModal = ({ collection }) => {
        this.setState({
            modalDeleteOpen: true,
            activeCollection: collection
        })
    }

    onCloseDeleteModal = () => {
        this.setState({
            modalDeleteOpen: false,
            activeCollection: null
        })
    }

    deleteCollection = async () => {
        const { activeCollection } = this.state

        let { collections, setContextCollections } = this.context

        const response = await fetch('/.netlify/functions/server/api/delete-collection', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ collectionId: activeCollection.id })
        });

        try {

            if (!response.ok) throw response;

            const deleteCollection = await response.json();

            getNetlifySnippetByTitle(this.context.reseller_site_id, snippetTitle)
                .then(async (response) => {
                    if (response.id && response.general) {

                        let collectionIDs = this.decodeSnippet(response.general)

                        const collectionIndex = collectionIDs.findIndex(c => c === deleteCollection.deletedCollectionId)
                        if (collectionIndex > -1) {
                            collectionIDs.splice(collectionIDs.findIndex(c => c === deleteCollection.deletedCollectionId), 1)

                            const content = this.encodeSnippet(collectionIDs)

                            updateNetlifySnippet(this.context.reseller_site_id, snippetTitle, content, response.id)
                        }
                    }
                })

            if (deleteCollection.deletedCollectionId) {
                const cIndex = collections.findIndex(c => c.id === activeCollection.id)
                collections.splice(cIndex, 1)
            }

            this.setState({
                modalDeleteOpen: false
            });
            setContextCollections({ collections });
        }
        catch (err) {
            //Show response error
            if (err.message) {
                toast.error(`${err.message}`);
            } else {
                toast.error(`${err.status}: ${err.statusText}`);
            }
        }
    }

    render() {

        if (this.context.siteSetupStatus === "BUILDING") return <div>We are activating your categories, please wait.</div>
        if (this.state.loading) return <Loader />;

        const { baseCollection, modalDeleteOpen } = this.state
        const { collections } = this.context;

        return (
            <Fragment>
                <Breadcrumb mainTitle="Products" title="Categories" parent="Products" />
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header pb-0">
                            <h5>Categories</h5>
                            <p>Categories are groups of products you can compile to your liking. To add products to a category, they must have been activated from the <a href="/products">product list page</a>.</p>
                        </div>
                        <div className="card-body mt-1 pt-0">
                            <div>

                                <Link
                                    className="btn btn-primary pull-right mb-4"
                                    to="/categories/new">
                                    Create Category
                                                            </Link>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-md border rounded">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="align-middle">Category</th>
                                            <th scope="col" className="align-middle">Description</th>
                                            <th scope="col" className="align-middle">Slug</th>
                                            <th scope="col"></th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            collections.map(collection => {
                                                const isBaseOrCustomCollection = collection.handle === this.context.reseller_collection_handle || collection.handle === baseCollection.handle;
                                                const collectionHandle = collection.handle.toLowerCase().replace(`${this.context.store_subdomain.toLowerCase()}-`, "");
                                                let id = collection.id;
                                                if (!isBase64(collection.id)) {
                                                    id = btoa(collection.id);
                                                }
                                                return <Fragment key={`collection-${collection.id}`}>
                                                    {!isBaseOrCustomCollection && <tr>
                                                        <td >
                                                            <div className="text-primary d-flex align-items-center">
                                                                <b> {collection.title} </b>
                                                            </div>
                                                        </td>
                                                        <td className="text-secondary">
                                                            {collection.description}
                                                        </td>
                                                        <td className="text-secondary">
                                                            {collectionHandle}
                                                        </td>
                                                        <td width="1%">
                                                            <Link
                                                                className="btn btn-outline-primary btn-outline-custom btn-block btn-custom-white"
                                                                to={`/categories/edit/${id}`}>
                                                                <b>Edit</b>
                                                            </Link>
                                                        </td>
                                                        <td width="5%">
                                                            {!isBaseOrCustomCollection ? <Trash2 size="20" onClick={() => this.onOpenDeleteModal({ collection })} /> : <></>}
                                                        </td>
                                                    </tr>
                                                    }
                                                </Fragment>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div >
                <Modal
                    open={modalDeleteOpen}
                    showCloseIcon={false}
                    onClose={this.onCloseDeleteModal}
                    styles={{
                        modal: {
                            borderRadius: "10px",
                            'overflow': 'hidden'
                        }
                    }}
                    center
                >
                    <div className="container modal-lg modal-alert" >
                        <div className="py-5 px-3">
                            <div className="d-flex justify-content-between">
                                <h5 style={{ color: "black", fontSize: "150%" }} ><b>Delete Category</b></h5>
                                <div>
                                    <a onClick={this.onCloseDeleteModal}> <XCircle size={24} color="black" /></a>
                                </div>
                            </div>
                            <div className="text-secondary mt-1"
                                style={{ fontSize: "130%", color: "#777777" }} >
                                Are you sure you want to delete this category?
                            </div>
                            <div className="mt-4">
                                <button type="button"
                                    className="btn btn-outline-primary py-2 px-4 mr-3"
                                    onClick={this.onCloseDeleteModal}
                                >
                                    Cancel
                                </button>
                                <button type="button"
                                    className="btn btn-danger py-2 px-4"
                                    style={{ backgroundColor: "rgb(255,0,0) !important" }}
                                    onClick={this.deleteCollection}>
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </Fragment >
        )
    }
};

CollectionList.contextType = AuthContext

export default CollectionList;
