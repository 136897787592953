
const SalesAgreement = {
  "name": "SalesAgreement",
  "kind": "INTERFACE",
  "fieldBaseTypes": {
    "app": "App",
    "happenedAt": "DateTime",
    "id": "ID",
    "reason": "OrderActionType",
    "sales": "SaleConnection",
    "user": "StaffMember"
  },
  "possibleTypes": ["OrderAgreement", "OrderEditAgreement", "RefundAgreement", "ReturnAgreement"]
};
export default SalesAgreement;