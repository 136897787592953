
const BillingAttemptUserError = {
  "name": "BillingAttemptUserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "BillingAttemptUserErrorCode",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
export default BillingAttemptUserError;