
const DisplayableError = {
  "name": "DisplayableError",
  "kind": "INTERFACE",
  "fieldBaseTypes": {
    "field": "String",
    "message": "String"
  },
  "possibleTypes": ["CartUserError", "CheckoutUserError", "CustomerUserError", "MetafieldDeleteUserError", "MetafieldsSetUserError", "UserError"]
};
export default DisplayableError;