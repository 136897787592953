
const UnknownSale = {
  "name": "UnknownSale",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "actionType": "SaleActionType",
    "id": "ID",
    "lineType": "SaleLineType",
    "quantity": "Int",
    "taxes": "SaleTax",
    "totalAmount": "MoneyBag",
    "totalDiscountAmountAfterTaxes": "MoneyBag",
    "totalDiscountAmountBeforeTaxes": "MoneyBag",
    "totalTaxAmount": "MoneyBag"
  },
  "implementsNode": false
};
export default UnknownSale;