
const SegmentValueConnection = {
  "name": "SegmentValueConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "SegmentValueEdge",
    "nodes": "SegmentValue",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default SegmentValueConnection;