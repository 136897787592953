
const DiscountAutomaticFreeShippingInput = {
  "name": "DiscountAutomaticFreeShippingInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "combinesWith": "DiscountCombinesWithInput",
    "title": "String",
    "startsAt": "DateTime",
    "endsAt": "DateTime",
    "minimumRequirement": "DiscountMinimumRequirementInput",
    "destination": "DiscountShippingDestinationSelectionInput",
    "maximumShippingPrice": "Decimal",
    "appliesOnOneTimePurchase": "Boolean",
    "appliesOnSubscription": "Boolean",
    "recurringCycleLimit": "Int"
  }
};
export default DiscountAutomaticFreeShippingInput;