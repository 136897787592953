
const PriceListConnection = {
  "name": "PriceListConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "PriceListEdge",
    "nodes": "PriceList",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default PriceListConnection;