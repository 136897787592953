
const CompanyContactsDeletePayload = {
  "name": "CompanyContactsDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedCompanyContactIds": "ID",
    "userErrors": "BusinessCustomerUserError"
  },
  "implementsNode": false
};
export default CompanyContactsDeletePayload;