
const LineItemSellingPlan = {
  "name": "LineItemSellingPlan",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "name": "String",
    "sellingPlanId": "ID"
  },
  "implementsNode": false
};
export default LineItemSellingPlan;