
const CompanyContactRoleAssignmentEdge = {
  "name": "CompanyContactRoleAssignmentEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "CompanyContactRoleAssignment"
  },
  "implementsNode": false
};
export default CompanyContactRoleAssignmentEdge;