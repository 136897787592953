
const SubscriptionPricingPolicyCycleDiscountsInput = {
  "name": "SubscriptionPricingPolicyCycleDiscountsInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "afterCycle": "Int",
    "adjustmentType": "SellingPlanPricingPolicyAdjustmentType",
    "adjustmentValue": "SellingPlanPricingPolicyValueInput",
    "computedPrice": "Decimal"
  }
};
export default SubscriptionPricingPolicyCycleDiscountsInput;