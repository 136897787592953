
const MetafieldDefinitionPinPayload = {
  "name": "MetafieldDefinitionPinPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "pinnedDefinition": "MetafieldDefinition",
    "userErrors": "MetafieldDefinitionPinUserError"
  },
  "implementsNode": false
};
export default MetafieldDefinitionPinPayload;