
const CartCodeDiscountAllocation = {
  "name": "CartCodeDiscountAllocation",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "String",
    "discountedAmount": "MoneyV2"
  },
  "implementsNode": false
};
export default CartCodeDiscountAllocation;