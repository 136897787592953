
const CheckoutBrandingOrderSummarySection = {
  "name": "CheckoutBrandingOrderSummarySection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "background": "CheckoutBrandingBackground",
    "border": "CheckoutBrandingSimpleBorder",
    "borderStyle": "CheckoutBrandingBorderStyle",
    "borderWidth": "CheckoutBrandingBorderWidth",
    "colorScheme": "CheckoutBrandingColorSchemeSelection",
    "cornerRadius": "CheckoutBrandingCornerRadius",
    "padding": "CheckoutBrandingSpacingKeyword",
    "shadow": "CheckoutBrandingShadow"
  },
  "implementsNode": false
};
export default CheckoutBrandingOrderSummarySection;