
const MetaobjectCapabilityData = {
  "name": "MetaobjectCapabilityData",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "onlineStore": "MetaobjectCapabilityDataOnlineStore",
    "publishable": "MetaobjectCapabilityDataPublishable"
  },
  "implementsNode": false
};
export default MetaobjectCapabilityData;