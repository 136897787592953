
const SubscriptionBillingAttemptEdge = {
  "name": "SubscriptionBillingAttemptEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "SubscriptionBillingAttempt"
  },
  "implementsNode": false
};
export default SubscriptionBillingAttemptEdge;