
const ProductVariantInput = {
  "name": "ProductVariantInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "requiresComponents": "Boolean",
    "barcode": "String",
    "compareAtPrice": "Money",
    "id": "ID",
    "mediaId": "ID",
    "mediaSrc": "String",
    "inventoryPolicy": "ProductVariantInventoryPolicy",
    "inventoryQuantities": "InventoryLevelInput",
    "inventoryItem": "InventoryItemInput",
    "metafields": "MetafieldInput",
    "options": "String",
    "position": "Int",
    "price": "Money",
    "productId": "ID",
    "sku": "String",
    "taxable": "Boolean",
    "taxCode": "String"
  }
};
export default ProductVariantInput;