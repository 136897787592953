
const DeliveryCustomizationUpdatePayload = {
  "name": "DeliveryCustomizationUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deliveryCustomization": "DeliveryCustomization",
    "userErrors": "DeliveryCustomizationError"
  },
  "implementsNode": false
};
export default DeliveryCustomizationUpdatePayload;