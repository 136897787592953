
const GiftCardDisablePayload = {
  "name": "GiftCardDisablePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "giftCard": "GiftCard",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default GiftCardDisablePayload;