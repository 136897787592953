
const CustomerCreditCard = {
  "name": "CustomerCreditCard",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "billingAddress": "CustomerCreditCardBillingAddress",
    "brand": "String",
    "expiresSoon": "Boolean",
    "expiryMonth": "Int",
    "expiryYear": "Int",
    "firstDigits": "String",
    "isRevocable": "Boolean",
    "lastDigits": "String",
    "maskedNumber": "String",
    "name": "String",
    "source": "String",
    "virtualLastDigits": "String"
  },
  "implementsNode": false
};
export default CustomerCreditCard;