
const FulfillmentV2Input = {
  "name": "FulfillmentV2Input",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "trackingInfo": "FulfillmentTrackingInput",
    "notifyCustomer": "Boolean",
    "lineItemsByFulfillmentOrder": "FulfillmentOrderLineItemsInput",
    "originAddress": "FulfillmentOriginAddressInput"
  }
};
export default FulfillmentV2Input;