import React, { Component,Fragment } from 'react'
import { ShoppingBag } from 'react-feather';


export class Notification extends Component {
    render() {
        return (
            <Fragment>
                
                        <ul className="notification-dropdown onhover-show-div p-0">
                            <li>Notifications</li>
                            <li>
                                <div className="media">
                                    <div className="media-body">
                                        <h6 className="mt-0"><span><ShoppingBag /></span>Need help?</h6>
                                        <p className="mb-0">For any questions, contact: <a href="mailto:marketing@stouse.com">marketing@stouse.com</a></p>
                                    </div>
                                </div>
                            </li>
                        </ul>
            </Fragment>
        )
    }
}

export default Notification
