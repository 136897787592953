
const MetafieldIdentifier = {
  "name": "MetafieldIdentifier",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "key": "String",
    "namespace": "String",
    "ownerId": "ID"
  },
  "implementsNode": false
};
export default MetafieldIdentifier;