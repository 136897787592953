
const SellingPlan = {
  "name": "SellingPlan",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkoutCharge": "SellingPlanCheckoutCharge",
    "description": "String",
    "id": "ID",
    "name": "String",
    "options": "SellingPlanOption",
    "priceAdjustments": "SellingPlanPriceAdjustment",
    "recurringDeliveries": "Boolean"
  },
  "implementsNode": false
};
export default SellingPlan;