
const Refund = {
  "name": "Refund",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "createdAt": "DateTime",
    "duties": "RefundDuty",
    "id": "ID",
    "legacyResourceId": "UnsignedInt64",
    "note": "String",
    "order": "Order",
    "refundLineItems": "RefundLineItemConnection",
    "refundShippingLines": "RefundShippingLineConnection",
    "return": "Return",
    "staffMember": "StaffMember",
    "totalRefunded": "MoneyV2",
    "totalRefundedSet": "MoneyBag",
    "transactions": "OrderTransactionConnection",
    "updatedAt": "DateTime"
  },
  "implementsNode": true
};
export default Refund;