
const Metaobject = {
  "name": "Metaobject",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "field": "MetaobjectField",
    "fields": "MetaobjectField",
    "handle": "String",
    "id": "ID",
    "onlineStoreUrl": "URL",
    "seo": "MetaobjectSEO",
    "type": "String",
    "updatedAt": "DateTime"
  },
  "implementsNode": true
};
export default Metaobject;