
const DeliveryMethodDefinitionInput = {
  "name": "DeliveryMethodDefinitionInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "id": "ID",
    "name": "String",
    "description": "String",
    "active": "Boolean",
    "rateDefinition": "DeliveryRateDefinitionInput",
    "participant": "DeliveryParticipantInput",
    "weightConditionsToCreate": "DeliveryWeightConditionInput",
    "priceConditionsToCreate": "DeliveryPriceConditionInput",
    "conditionsToUpdate": "DeliveryUpdateConditionInput"
  }
};
export default DeliveryMethodDefinitionInput;