import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import React, { Component } from 'react';
import { AuthContext } from '../../../auth/auth';
import GeneralInput from '../../../components/common/general-input';
import { addNetlifySnippet, createFormHook, deleteFormNetlifyHook, deleteNetlifySnippet, getNetlifySnippetByTitle, updateNetlifySnippet } from '../../../querys/netlify-rest';
import { Alert } from "reactstrap";

const netlifySnippetTitle = "Customer Service Email";

class CustomerServiceEmail extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            form: {
                email: ""
            },
            alert: {
                color: "",
                message: "",
                isOpen: false
            }
        }
    }

    componentDidMount = async () => {
        await this.fetchNetlifySnippet();
        this.setState({
            loading: false
        })

    }

    fetchNetlifySnippet = () => {
        return getNetlifySnippetByTitle(this.context.reseller_site_id, netlifySnippetTitle)
            .then((response) => {
                if (response.id && response.general) {

                    const email = response.general.replace(`<script>window.customerServiceEmail="`, "").replace(`"</script>`, "")

                    this.setState({
                        form: {
                            email
                        }
                    });
                    return response;
                } else {
                    return null;
                }
            });
    }

    handleValidSubmit = async (_event, values) => {
        let admins = [];
        let currentEmail = this.state.form.email;
        if (this.context && this.context.admins) {
            admins = this.context.admins;
        }

        const isCurrentValueAdmin = admins.find(a => a.node.email === currentEmail);

        const snippet = await this.fetchNetlifySnippet();
        const script = `<script>window.customerServiceEmail="${values.email}"</script>`;

        this.setState({
            alert: {
                color: "secondary",
                message: "Updating customer service email...",
                isOpen: true
            },
            form: {
                ...this.state.form,
                email: values.email
            }
        });

        if (snippet) {
            if (values.email !== "") {

                updateNetlifySnippet(this.context.reseller_site_id, netlifySnippetTitle, script).then(() => {


                    createFormHook(this.context.reseller_site_id, values.email);
                    this.setState((state) => ({
                        alert: {
                            ...state.alert,
                            color: "success",
                            message: "Customer service email updated correctly."
                        }
                    }));
                })
            } else {
                deleteNetlifySnippet(this.context.reseller_site_id, snippet.id).then(() => {
                    this.setState((state) => ({
                        alert: {
                            ...state.alert,
                            color: "success",
                            message: "Customer service email updated correctly."
                        }
                    }));
                })
            }
        } else {

            addNetlifySnippet(this.context.reseller_site_id, netlifySnippetTitle, script).then(response => {
                createFormHook(this.context.reseller_site_id, values.email);
                console.log(response)
                this.setState((state) => ({
                    alert: {
                        ...state.alert,
                        color: "success",
                        message: "Customer service email updated correctly."
                    }
                }));
            })
        }

        if (!isCurrentValueAdmin) { //If the old customer service email is not an admin of the store
            // Delete old hook
            deleteFormNetlifyHook(this.context.reseller_site_id, currentEmail);

        }
    }

    toggle = () => {
        this.setState((state) => ({
            alert: {
                isOpen: !state.alert.isOpen
            }
        }))
    }

    render() {
        if (this.state.loading) return <></>
        return <>
            <AvForm
                className="customer-service-email-form"
                onValidSubmit={this.handleValidSubmit}
                model={this.state.form}
            >
                <div className="form-label-center d-flex flex-wrap">
                    <div className="col-xl-7 col-l-9 col-md-9 p-0">
                        <GeneralInput
                            type="email"
                            name="email"
                            placeholder="Add your customer service email address"
                            required={false}
                        />
                    </div>
                    <div className="p-0 text-md-right mb-3">
                        <button className="btn btn-outline-primary ml-sm-3" type="submit">Add</button>
                    </div>
                </div>
            </AvForm>
            <Alert color={this.state.alert.color} isOpen={this.state.alert.isOpen} toggle={this.toggle.bind(this)} >
                {this.state.alert.message}
            </Alert>
        </>
    }
}

CustomerServiceEmail.contextType = AuthContext;

export default CustomerServiceEmail