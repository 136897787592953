
const CompanyContactRemoveFromCompanyPayload = {
  "name": "CompanyContactRemoveFromCompanyPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "removedCompanyContactId": "ID",
    "userErrors": "BusinessCustomerUserError"
  },
  "implementsNode": false
};
export default CompanyContactRemoveFromCompanyPayload;