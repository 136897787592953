
const SubscriptionLine = {
  "name": "SubscriptionLine",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "currentPrice": "MoneyV2",
    "customAttributes": "Attribute",
    "discountAllocations": "SubscriptionDiscountAllocation",
    "id": "ID",
    "lineDiscountedPrice": "MoneyV2",
    "pricingPolicy": "SubscriptionPricingPolicy",
    "productId": "ID",
    "quantity": "Int",
    "requiresShipping": "Boolean",
    "sellingPlanId": "ID",
    "sellingPlanName": "String",
    "sku": "String",
    "taxable": "Boolean",
    "title": "String",
    "variantId": "ID",
    "variantImage": "Image",
    "variantTitle": "String"
  },
  "implementsNode": false
};
export default SubscriptionLine;