
const SubscriptionContractCreateInput = {
  "name": "SubscriptionContractCreateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "customerId": "ID",
    "nextBillingDate": "DateTime",
    "currencyCode": "CurrencyCode",
    "contract": "SubscriptionDraftInput"
  }
};
export default SubscriptionContractCreateInput;