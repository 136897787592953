
const DiscountCodeAppInput = {
  "name": "DiscountCodeAppInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "combinesWith": "DiscountCombinesWithInput",
    "functionId": "String",
    "title": "String",
    "startsAt": "DateTime",
    "endsAt": "DateTime",
    "usageLimit": "Int",
    "appliesOncePerCustomer": "Boolean",
    "customerSelection": "DiscountCustomerSelectionInput",
    "code": "String",
    "metafields": "MetafieldInput"
  }
};
export default DiscountCodeAppInput;