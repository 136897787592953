
const DiscountCustomerBuys = {
  "name": "DiscountCustomerBuys",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "items": "DiscountItems",
    "value": "DiscountCustomerBuysValue"
  },
  "implementsNode": false
};
export default DiscountCustomerBuys;