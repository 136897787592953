
const ReturnableFulfillment = {
  "name": "ReturnableFulfillment",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "fulfillment": "Fulfillment",
    "id": "ID",
    "returnableFulfillmentLineItems": "ReturnableFulfillmentLineItemConnection"
  },
  "implementsNode": true
};
export default ReturnableFulfillment;