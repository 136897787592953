
const ReverseDeliveryConnection = {
  "name": "ReverseDeliveryConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "ReverseDeliveryEdge",
    "nodes": "ReverseDelivery",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default ReverseDeliveryConnection;