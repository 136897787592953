
const FileCreatePayload = {
  "name": "FileCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "files": "File",
    "userErrors": "FilesUserError"
  },
  "implementsNode": false
};
export default FileCreatePayload;