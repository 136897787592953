
const ShopifyPaymentsPayoutSummary = {
  "name": "ShopifyPaymentsPayoutSummary",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "adjustmentsFee": "MoneyV2",
    "adjustmentsGross": "MoneyV2",
    "chargesFee": "MoneyV2",
    "chargesGross": "MoneyV2",
    "refundsFee": "MoneyV2",
    "refundsFeeGross": "MoneyV2",
    "reservedFundsFee": "MoneyV2",
    "reservedFundsGross": "MoneyV2",
    "retriedPayoutsFee": "MoneyV2",
    "retriedPayoutsGross": "MoneyV2"
  },
  "implementsNode": false
};
export default ShopifyPaymentsPayoutSummary;