
const SegmentValueEdge = {
  "name": "SegmentValueEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "SegmentValue"
  },
  "implementsNode": false
};
export default SegmentValueEdge;