
const OrderEditBeginPayload = {
  "name": "OrderEditBeginPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "calculatedOrder": "CalculatedOrder",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default OrderEditBeginPayload;