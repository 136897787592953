
const FulfillmentOrderSplitResult = {
  "name": "FulfillmentOrderSplitResult",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "fulfillmentOrder": "FulfillmentOrder",
    "remainingFulfillmentOrder": "FulfillmentOrder",
    "replacementFulfillmentOrder": "FulfillmentOrder"
  },
  "implementsNode": false
};
export default FulfillmentOrderSplitResult;