
const FileUpdateInput = {
  "name": "FileUpdateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "id": "ID",
    "alt": "String",
    "originalSource": "String",
    "previewImageSource": "String",
    "filename": "String"
  }
};
export default FileUpdateInput;