
const PriceRuleMoneyRangeInput = {
  "name": "PriceRuleMoneyRangeInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "lessThan": "Money",
    "lessThanOrEqualTo": "Money",
    "greaterThan": "Money",
    "greaterThanOrEqualTo": "Money"
  }
};
export default PriceRuleMoneyRangeInput;