
const FulfillmentConstraintRuleCreatePayload = {
  "name": "FulfillmentConstraintRuleCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "fulfillmentConstraintRule": "FulfillmentConstraintRule",
    "userErrors": "FulfillmentConstraintRuleCreateUserError"
  },
  "implementsNode": false
};
export default FulfillmentConstraintRuleCreatePayload;