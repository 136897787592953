import React from "react";
import { Route, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { getCookieByName } from "../components/navigation/utils";
import { getNetlifySiteSettings } from "../querys/netlify-rest";

var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

const AuthContext = React.createContext({
    loggedIn: false,
    email: null,
    storefrontId: null,
    adminId: null,
    token: null,
    store_subdomain: null,
    reseller_site_id: null,
    reseller_collection_id: null,
    reseller_collection_handle: null,
    loginUser: () => { },
    logoutUser: () => { },
    globalMarkup: null,
    setGlobalMarkup: () => { },
    setContextPages: () => { },
    updateContextPages: () => { },
    setContextProducts: () => { },
    pages: [],
    products: [],
    activeProducts: [],
    admins: [],
    setAdmins: () => { },
    publications: [],
    siteState: "",
    setSiteState: () => { },
    siteSetupStatus: "BUILT",
    setSiteSetupStatus: () => { },
    collections: [],
    collectionsFetched: false,
    setContextCollections: () => { },
    setCustomSubdomain: () => { }
});

class AuthenticationProvider extends React.Component {
    constructor(props) {
        super(props);
        this.loginUser = async (user_data) => {
            const siteSetupCookie = getCookieByName("siteSetup");
            const storeData = user_data['save'];
            let adminId = user_data['id'];
            if (base64regex.test(adminId)) {
                adminId = user_data['id'];
            }
            let publications = [];


            const pr = await fetch('/.netlify/functions/server/api/fetch-publications', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            try {
                
                if (!pr.ok) throw pr;
                
                const publicationResponse = await pr.json()

                if (Array.isArray(publicationResponse)) {
                    publications = publicationResponse.map(p => p.node.id)
                }
                if (storeData) {
                    localStorage.setItem('whitelabelUserEmail', user_data['email']);
                    localStorage.setItem('whitelabelStorefrontUserId', user_data['id']);
                    localStorage.setItem('whitelabelAdminUserId', adminId);
                    localStorage.setItem('whitelabelUserToken', user_data['token']);
                    localStorage.setItem('whitelabelStoreSubdomain', user_data['store_subdomain']);
                    localStorage.setItem('whitelabelResellerSiteId', user_data['reseller_site_id']);
                    localStorage.setItem('whitelabelResellerCollectionId', user_data['reseller_collection_id']);
                    localStorage.setItem('whitelabelResellerCollectionHandle', user_data['reseller_collection_handle']);
                    localStorage.setItem('whitelabelGlobalMarkup', user_data['global_markup']);
                    localStorage.setItem('whitelabelPublications', JSON.stringify(publications));
                } else {
                    sessionStorage.setItem('whitelabelUserEmail', user_data['email']);
                    sessionStorage.setItem('whitelabelStorefrontUserId', user_data['id']);
                    sessionStorage.setItem('whitelabelAdminUserId', adminId);
                    sessionStorage.setItem('whitelabelUserToken', user_data['token']);
                    sessionStorage.setItem('whitelabelStoreSubdomain', user_data['store_subdomain']);
                    sessionStorage.setItem('whitelabelResellerSiteId', user_data['reseller_site_id']);
                    sessionStorage.setItem('whitelabelResellerCollectionId', user_data['reseller_collection_id']);
                    sessionStorage.setItem('whitelabelResellerCollectionHandle', user_data['reseller_collection_handle']);
                    sessionStorage.setItem('whitelabelGlobalMarkup', user_data['global_markup']);
                    sessionStorage.setItem('whitelabelPublications', JSON.stringify(publications));
                }

                // Segment Tracking
                const customerId = adminId.split("/")[4];
                window.analytics.identify(customerId, {
                    company: { name: user_data['store_subdomain'] },
                    email: user_data['email']
                });

                const netlifySettings = await getNetlifySiteSettings(user_data['reseller_site_id']);
                let custom_subdomain = null;
                if (netlifySettings && netlifySettings.request && netlifySettings.request.response) {
                    let response = null;
                    try {
                        response = JSON.parse(netlifySettings.request.response);
                    } catch (err) {
                        console.error("This site no longer exists");
                        return;
                    }
                    if (response && response.domain_aliases && response.domain_aliases.length > 0) {
                        response.domain_aliases.forEach(alias => {
                            if (alias.indexOf("whitelabellabels.com") === -1 && alias.indexOf("readysetprint.com") === -1) {
                                custom_subdomain = alias;
                                if (storeData) {
                                    localStorage.setItem('whitelabelCustomSubdomain', custom_subdomain);
                                } else {
                                    sessionStorage.setItem('whitelabelCustomSubdomain', custom_subdomain);
                                }
                            }
                        });
                    } else {
                        
                        localStorage.removeItem('whitelabelCustomSubdomain');
                        sessionStorage.removeItem('whitelabelCustomSubdomain');
                    }
                }
                   
                this.setState(() => ({
                    loggedIn: true,
                    email: user_data['email'],
                    storefrontId: user_data['id'],
                    adminId: adminId,
                    token: user_data['token'],
                    pages: [],
                    products: [],
                    activeProducts: [],
                    store_subdomain: user_data['store_subdomain'],
                    reseller_site_id: user_data['reseller_site_id'],
                    reseller_collection_id: user_data['reseller_collection_id'],
                    reseller_collection_handle: user_data['reseller_collection_handle'],
                    globalMarkup: user_data['global_markup'],
                    admins: [],
                    publications,
                    collections: [],
                    collectionsFetched: false,
                    siteSetupStatus: siteSetupCookie ? "BUILDING" : "BUILT",
                    custom_subdomain
                }));
            } catch (err) {
                //Show response error
                if (err.message) {
                    toast.error(`${err.message}`);
                } else {
                    toast.error(`${err.status}: ${err.statusText}`);
                }
            }
        };

        this.clearStorage = () => {
            if (localStorage.getItem('whitelabelUserEmail')) {
                localStorage.removeItem('whitelabelUserEmail');
                localStorage.removeItem('whitelabelStorefrontUserId');
                localStorage.removeItem('whitelabelAdminUserId');
                localStorage.removeItem('whitelabelUserToken');
                localStorage.removeItem('whitelabelStoreSubdomain');
                localStorage.removeItem('whitelabelResellerSiteId');
                localStorage.removeItem('whitelabelResellerCollectionId');
                localStorage.removeItem('whitelabelResellerCollectionHandle');
                localStorage.removeItem('whitelabelGlobalMarkup');
                localStorage.removeItem('whitelabelPublications');
                localStorage.removeItem('whitelabelCustomSubdomain');
            } else if (sessionStorage.getItem('whitelabelUserEmail')) {
                sessionStorage.removeItem('whitelabelUserEmail');
                sessionStorage.removeItem('whitelabelStorefrontUserId');
                sessionStorage.removeItem('whitelabelAdminUserId');
                sessionStorage.removeItem('whitelabelUserToken');
                sessionStorage.removeItem('whitelabelStoreSubdomain');
                sessionStorage.removeItem('whitelabelResellerSiteId');
                sessionStorage.removeItem('whitelabelResellerCollectionId');
                sessionStorage.removeItem('whitelabelResellerCollectionHandle');
                sessionStorage.removeItem('whitelabelGlobalMarkup');
                sessionStorage.removeItem('whitelabelPublications');
                sessionStorage.removeItem('whitelabelCustomSubdomain');
            }
        };

        this.logoutUser = () => {
            this.clearStorage();
            this.setState(() => ({
                loggedIn: false,
                email: null,
                storefrontId: null,
                adminId: null,
                token: null,
                pages: [],
                products: [],
                activeProducts: [],
                store_subdomain: null,
                reseller_site_id: null,
                reseller_collection_id: null,
                reseller_collection_handle: null,
                globalMarkup: null,
                admins: [],
                publications: [],
                collections: [],
                collectionsFetched: false,
                custom_subdomain: null
            }));
        };

        // Returns true if successful. If not, it returns a string with the error.
        this.setGlobalMarkup = async (newMarkup) => {
            const validMarkup = /^-?\d+$/;
            if (validMarkup.test(newMarkup)) {
                localStorage.setItem('whitelabelGlobalMarkup', newMarkup);
                sessionStorage.setItem('whitelabelGlobalMarkup', newMarkup);
                const newTag = "markup=" + String(newMarkup);
                const shopifyAdminId = localStorage.getItem("whitelabelAdminUserId") || sessionStorage.getItem("whitelabelAdminUserId");

                const currentTags = await fetch('/.netlify/functions/server/api/get-customer', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        customerId: shopifyAdminId,
                        fields: `id tags`,
                    }),
                })
                    .then(response => {
                        if (response.status === 200) {
                            return response.json();
                        }
                        throw response;
                    })
                    .then(json => {
                        console.log(json);
                        return json.data.customer.tags;
                    })
                    .catch((err) => {
                        //Show response error
                        if (err.message) {
                            toast.error(`${err.message}`);
                            return err.message;
                        } else {
                            toast.error(`${err.status}: ${err.statusText}`);
                            return err.statusText;
                        }
                    });

                if (typeof currentTags !== "string") {
                    let hasMarkupTag = false;
                    currentTags.forEach((tag, tagIndex) => {
                        const markupIndex = tag.indexOf("markup=");
                        if (markupIndex !== -1) {
                            currentTags[tagIndex] = newTag;
                            hasMarkupTag = true;
                        }
                    });
                    if (!hasMarkupTag) {
                        currentTags.push(newTag);
                    }
                } else {
                    return currentTags;
                }

                const customerTagsUpdated = await fetch('/.netlify/functions/server/api/update-customer-tags', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        customerId: shopifyAdminId,
                        tags: currentTags,
                    }),
                })
                    .then(response => {
                        if (response.status === 200) {
                            return true;
                        }
                        throw response;
                    })
                    .catch((err) => {
                        //Show response error
                        if (err.message) {
                            toast.error(`${err.message}`);
                            return err.message;
                        } else {
                            toast.error(`${err.status}: ${err.statusText}`);
                            return err.statusText;
                        }
                    });

                if (typeof customerTagsUpdated !== "string") {
                    console.log("Global markup of " + String(newMarkup) + " applied successfully.");
                    this.setState(state => ({
                        ...state,
                        globalMarkup: newMarkup,
                    }));
                    return true;
                } else {
                    return customerTagsUpdated;
                }
            } else {
                return "Invalid value.";
            }
        };

        /* eslint-disable eqeqeq */
        if (
            localStorage.getItem('whitelabelUserEmail') != undefined &&
            localStorage.getItem('whitelabelUserToken') != undefined
        ) {
            this.state = {
                loggedIn: true,
                email: localStorage.getItem('whitelabelUserEmail'),
                storefrontId: localStorage.getItem('whitelabelStorefrontUserId'),
                adminId: localStorage.getItem('whitelabelAdminUserId'),
                token: localStorage.getItem('whitelabelUserToken'),
                store_subdomain: localStorage.getItem('whitelabelStoreSubdomain'),
                reseller_site_id: localStorage.getItem('whitelabelResellerSiteId'),
                reseller_collection_id: localStorage.getItem('whitelabelResellerCollectionId'),
                reseller_collection_handle: localStorage.getItem('whitelabelResellerCollectionHandle'),
                globalMarkup: localStorage.getItem('whitelabelGlobalMarkup'),
                setGlobalMarkup: this.setGlobalMarkup,
                pages: [],
                products: [],
                activeProducts: [],
                loginUser: this.loginUser,
                logoutUser: this.logoutUser,
                setContextPages: this.setContextPages,
                updateContextPages: this.updateContextPages,
                setContextProducts: this.setContextProducts,
                admins: [],
                setAdmins: this.setAdmins,
                publications: localStorage.getItem('whitelabelPublications'),
                setSiteState: this.setSiteState,
                siteState: localStorage.getItem('whitelabelSiteState'),
                collections: [],
                collectionsFetched: false,
                setContextCollections: this.setContextCollections,
                setSiteSetupStatus: this.setSiteSetupStatus,
                siteSetupStatus: localStorage.getItem('whitelabelSiteSetupStatus'),
                custom_subdomain: localStorage.getItem('whitelabelCustomSubdomain'),
                setCustomSubdomain: this.setCustomSubdomain

            };
        } else if (
            sessionStorage.getItem('whitelabelUserEmail') != undefined &&
            sessionStorage.getItem('whitelabelUserToken') != undefined
        ) {
            this.state = {
                loggedIn: true,
                email: sessionStorage.getItem('whitelabelUserEmail'),
                storefrontId: sessionStorage.getItem('whitelabelStorefrontUserId'),
                adminId: sessionStorage.getItem('whitelabelAdminUserId'),
                token: sessionStorage.getItem('whitelabelUserToken'),
                store_subdomain: sessionStorage.getItem('whitelabelStoreSubdomain'),
                reseller_site_id: sessionStorage.getItem('whitelabelResellerSiteId'),
                reseller_collection_id: sessionStorage.getItem('whitelabelResellerCollectionId'),
                reseller_collection_handle: sessionStorage.getItem('whitelabelResellerCollectionHandle'),
                globalMarkup: sessionStorage.getItem('whitelabelGlobalMarkup'),
                setGlobalMarkup: this.setGlobalMarkup,
                pages: [],
                products: [],
                activeProducts: [],
                loginUser: this.loginUser,
                logoutUser: this.logoutUser,
                setContextPages: this.setContextPages,
                updateContextPages: this.updateContextPages,
                setContextProducts: this.setContextProducts,
                admins: [],
                setAdmins: this.setAdmins,
                publications: localStorage.getItem('whitelabelPublications'),
                setSiteState: this.setSiteState,
                siteState: localStorage.getItem('whitelabelSiteState'),
                collections: [],
                collectionsFetched: false,
                setContextCollections: this.setContextCollections,
                setSiteSetupStatus: this.setSiteSetupStatus,
                siteSetupStatus: localStorage.getItem('whitelabelSiteSetupStatus'),
                custom_subdomain: localStorage.getItem('whitelabelCustomSubdomain'),
                setCustomSubdomain: this.setCustomSubdomain
            };
        } else {
            this.clearStorage();
            this.state = {
                loggedIn: false,
                email: null,
                storefrontId: null,
                adminId: null,
                token: null,
                store_subdomain: null,
                reseller_site_id: null,
                reseller_collection_id: null,
                reseller_collection_handle: null,
                setGlobalMarkup: this.setGlobalMarkup,
                pages: [],
                products: [],
                activeProducts: [],
                loginUser: this.loginUser,
                logoutUser: this.logoutUser,
                setContextPages: this.setContextPages,
                updateContextPages: this.updateContextPages,
                setContextProducts: this.setContextProducts,
                admins: [],
                setAdmins: this.setAdmins,
                publications: [],
                setSiteState: this.setSiteState,
                siteState: "",
                collections: [],
                collectionsFetched: false,
                setContextCollections: this.setContextCollections,
                siteSetupStatus: "",
                setSiteSetupStatus: this.setSiteSetupStatus,
                custom_subdomain: null,
                setCustomSubdomain: this.setCustomSubdomain
            };
        }
    }
    /* eslint-enable eqeqeq */

    setContextPages = (pages) => {
        this.setState({
            pages: pages
        })
    }

    setContextProducts = ({ products, activeProducts }) => {
        if (products) {

            this.setState({
                products,
            })
        }

        if (activeProducts) {
            this.setState({
                activeProducts
            })
        }
    }

    setContextCollections = ({ collections }) => {
        if (collections) {

            this.setState({
                collections,
                collectionsFetched: true
            })
        }
    }

    updateContextPages = (handle, newPage) => {

        let { pages, setContextPages } = this.state
        const index = pages.findIndex(p => p.handle === handle)
        pages.splice(index, 1, newPage)

        setContextPages(pages)
    }


    setAdmins = (admins) => {
        this.setState({
            admins
        })
    }

    setSiteState = (siteState) => {
        localStorage.setItem('whitelabelSiteState', siteState);
        sessionStorage.setItem('whitelabelSiteState', siteState);
        this.setState({
            siteState
        });
    };

    setSiteSetupStatus = (siteState) => {
        localStorage.setItem('whitelabelSiteSetupStatus', siteState);
        sessionStorage.setItem('whitelabelSiteSetupStatus', siteState);
        this.setState({
            siteSetupStatus: siteState
        })
    }

    setCustomSubdomain = (custom_subdomain) => {
        localStorage.setItem('whitelabelCustomSubdomain', custom_subdomain);
        sessionStorage.setItem('whitelabelCustomSubdomain', custom_subdomain);
        this.setState({
            custom_subdomain
        })
    }

    render() {
        return (
            <AuthContext.Provider value={this.state}>
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
    return (
        <AuthContext.Consumer>
            {({
                loggedIn,
                token }) => (
                    <Route
                        {...rest}
                        render={props =>
                            loggedIn &&
                                (token != null) ? (
                                    <Component {...props} />
                                ) : (
                                    <Redirect
                                        to={{
                                            pathname: "/auth/login",
                                            state: { from: props.location },
                                        }}
                                    />
                                )
                        }
                    />
                )}
        </AuthContext.Consumer>
    );
};

export { AuthContext, AuthenticationProvider, AuthenticatedRoute };