
const OptionUpdateInput = {
  "name": "OptionUpdateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "id": "ID",
    "name": "String",
    "position": "Int",
    "linkedMetafield": "LinkedMetafieldUpdateInput"
  }
};
export default OptionUpdateInput;