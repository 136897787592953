
const CompanyContactRevokeRolesPayload = {
  "name": "CompanyContactRevokeRolesPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "revokedRoleAssignmentIds": "ID",
    "userErrors": "BusinessCustomerUserError"
  },
  "implementsNode": false
};
export default CompanyContactRevokeRolesPayload;