
const PrivateMetafieldDeleteInput = {
  "name": "PrivateMetafieldDeleteInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "owner": "ID",
    "namespace": "String",
    "key": "String"
  }
};
export default PrivateMetafieldDeleteInput;