
const CartTransformConnection = {
  "name": "CartTransformConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "CartTransformEdge",
    "nodes": "CartTransform",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default CartTransformConnection;