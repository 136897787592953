
const ProductContextualPricing = {
  "name": "ProductContextualPricing",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "fixedQuantityRulesCount": "Int",
    "maxVariantPricing": "ProductVariantContextualPricing",
    "minVariantPricing": "ProductVariantContextualPricing",
    "priceRange": "ProductPriceRangeV2"
  },
  "implementsNode": false
};
export default ProductContextualPricing;