
const CartDirectPaymentMethodInput = {
  "name": "CartDirectPaymentMethodInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "billingAddress": "MailingAddressInput",
    "sessionId": "String",
    "cardSource": "CartCardSource"
  }
};
export default CartDirectPaymentMethodInput;