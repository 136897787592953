
const ShopResourceFeedbackCreatePayload = {
  "name": "ShopResourceFeedbackCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "feedback": "AppFeedback",
    "userErrors": "ShopResourceFeedbackCreateUserError"
  },
  "implementsNode": false
};
export default ShopResourceFeedbackCreatePayload;