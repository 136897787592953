
const ShopResourceLimits = {
  "name": "ShopResourceLimits",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "locationLimit": "Int",
    "maxProductOptions": "Int",
    "maxProductVariants": "Int",
    "redirectLimitReached": "Boolean"
  },
  "implementsNode": false
};
export default ShopResourceLimits;