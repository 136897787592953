
const InventoryItemConnection = {
  "name": "InventoryItemConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "InventoryItemEdge",
    "nodes": "InventoryItem",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default InventoryItemConnection;