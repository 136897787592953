
const PublicationConnection = {
  "name": "PublicationConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "PublicationEdge",
    "nodes": "Publication",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default PublicationConnection;