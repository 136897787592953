
const MetaobjectCapabilities = {
  "name": "MetaobjectCapabilities",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "onlineStore": "MetaobjectCapabilitiesOnlineStore",
    "publishable": "MetaobjectCapabilitiesPublishable",
    "renderable": "MetaobjectCapabilitiesRenderable",
    "translatable": "MetaobjectCapabilitiesTranslatable"
  },
  "implementsNode": false
};
export default MetaobjectCapabilities;