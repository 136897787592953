
const Localization = {
  "name": "Localization",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "availableCountries": "Country",
    "availableLanguages": "Language",
    "country": "Country",
    "language": "Language",
    "market": "Market"
  },
  "implementsNode": false
};
export default Localization;