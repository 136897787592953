import React, { Component } from "react";
import Breadcrumb from "../../components/common/bread-crumb";
import { Link } from 'react-router-dom';
import moment from 'moment'
import { Alert } from "reactstrap";
import Loader from "../loader/loader";
import { toast } from "react-toastify";

export class CustomerDetail extends Component {


  constructor(props) {
    super(props)
    this.state = {
      customer: {},
      loading: true,
      alert: false,
      alertMessage: "",
      alertColor: "",
      taxExemptEligible: false

    }
  }

  componentDidMount = () => {
    if (this.props.match.params.id) {
      this.fetchCustomer(this.props.match.params.id)
    }

    window.analytics.page();
  }

  fetchCustomer = async (customerId) => {

    const fields = `id
        lastName
        firstName
        email
        state
        createdAt
        lastOrder {
          createdAt
          name
        }
        ordersCount
        totalSpentV2 {
          amount
        }
        averageOrderAmountV2 {
          amount
        }
        taxExempt
        tags
        acceptsMarketing
        state
        phone
        defaultAddress {
          address1
          address2
          city
          provinceCode
          zip
          country
        }
        orders(first: 250) {
          edges {
            node {
              id
              legacyResourceId
              name
              createdAt
              customer {
                firstName
                lastName
              }
              confirmed
              displayFulfillmentStatus
              fullyPaid
              taxesIncluded
              totalPriceSet {
                presentmentMoney {
                  amount
                }
              }

            }
          }
      }`

    const response = await fetch('/.netlify/functions/server/api/get-customer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ customerId, fields })
    });

    try {

      if (!response.ok) throw response;

      const data = await response.json();

      
      if (data.data.customer) {
        const tags = data.data.customer.tags;

        if (tags.find(t => t === "tax_exempt_eligible")) {
          this.setState({
            taxExemptEligible: true
          })
        }

        this.setState({
          customer: data.data.customer,
          loading: false
        })
      }
    }
    catch (err) {
      //Show response error
      if (err.message) {
        toast.error(`${err.message}`);
      } else {
        toast.error(`${err.status}: ${err.statusText}`);
      }
    }
  }

  updateCustomerTaxExempt = async (ev) => {
    
    const isExempt = ev.target.checked;
    this.setState({
      alert: true,
      alertMessage: "Updating client's tax exemption eligibility...",
      alertColor: "secondary",
      taxExemptEligible: isExempt
    });
    let { customer } = this.state;

    // Add a tag to retreive that information on the front-end (taxExempt is not available on the Storefront API)
    let tags = customer.tags;

    const tax_exempt_eligible_tag = customer.tags.findIndex(t => t.includes("tax_exempt_eligible"));

    if (isExempt && tax_exempt_eligible_tag < 0) {
      tags.push(`tax_exempt_eligible`);
    } else if (!isExempt && tax_exempt_eligible_tag >= 0){
      tags.splice(tax_exempt_eligible_tag, 1);
    }

    const customerInput = {
      id: customer.id,
      tags
    }

    const response = await fetch('/.netlify/functions/server/api/update-customer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ customerInput })
    });

    try {

      if (!response.ok) throw response;

      const data = await response.json();

      if (data.data.customerUpdate) {
        this.setState({
          alert: true,
          alertMessage: "Success: Client's tax exemption eligibility has been updated.",
          alertColor: "success",
          customer,
          taxExemptEligible: isExempt
        });
      } else {
        this.setState({
          alert: true,
          alertMessage: "Error: Client's tax exemption couldn't be updated. Please try again.",
          alertColor: "danger",
          taxExemptEligible: !isExempt
        });
      }
    }
    catch (err) {
      //Show response error
      if (err.message) {
        toast.error(`${err.message}`);
      } else {
        toast.error(`${err.status}: ${err.statusText}`);
      }
    }
  }

  toggle() {
    this.setState({
      alert: !this.state.alert
    });
  }

  render() {

    if (this.state.loading) return <Loader />

    const { customer } = this.state

    return (
      <div className="order-detail">
        <Breadcrumb subTitle="Customers" parent="Customers" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header pt-4">
                  <div className="top-buttons pt-1">
                    <div className="top-left">
                      <Link className="btn-custom-link mb-3" to="/customers/">
                        &lt; Customers
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="d-md-flex">
                    <div className="detail-left mb-3">
                      <div>
                        <h5>{`${customer.firstName} ${customer.lastName}`}</h5>
                        <p className="text-secondary">{customer.defaultAddress ? `${customer.defaultAddress.city} ${customer.defaultAddress.provinceCode}, ${customer.defaultAddress.country}` : ""}</p>
                        <p className="text-secondary">{`Customer for about ${moment(customer.createdAt).fromNow(true)}`}</p>
                      </div>
                      <div className="mt-4">
                        <h6> Customer Overview</h6>
                        <p className="text-primary">{customer.email}</p>
                        <p className="text-secondary">{customer.acceptsMarketing ? "Accepts email marketing" : "Doesn't accept email marketing"}</p>
                        <p className="text-secondary">{customer.state === "ENABLED" ? "Has an account" : "Doesn't have an account"}</p>
                      </div>

                      {customer.defaultAddress &&
                        <div className="mt-4">
                          <div className="d-flex justify-content-between align-items-center">
                            <h6>Address</h6>
                          </div>
                          <p>{`${customer.firstName} ${customer.lastName}`}</p>
                          <p>{customer.defaultAddress.address1}</p>
                          <p>{customer.defaultAddress.address2}</p>
                          <p>{`${customer.defaultAddress.city} ${customer.defaultAddress.provinceCode}, ${customer.defaultAddress.zip}`}</p>
                          <p>{customer.defaultAddress.country}</p>
                          <p>{customer.phone}</p>
                        </div>
                      }
                      <div className="mt-4">
                        <div className="d-flex justify-content-between align-items-center">
                          <h6>Tax Exempt Eligible</h6>
                          <input
                            className="checkbox_animated"
                            id="chk-ani2"
                            type="checkbox"
                            name="customizable"
                            checked={this.state.taxExemptEligible}
                            onChange={this.updateCustomerTaxExempt}
                          />
                        </div>
                        <Alert color={this.state.alertColor} isOpen={this.state.alert} toggle={this.toggle.bind(this)} > {this.state.alertMessage} </Alert>
                      </div>
                    </div>
                    <div className="customer-right w-100 offset-md-1">
                      <div className="d-md-flex">
                        <div className="info-box text-center border rounded ">
                          <p className="mb-2"><b>Last Order</b></p>
                          <p className="text-prmary mb-1"><Link to="#">{customer.lastOrder ? customer.lastOrder.name : "--"}</Link></p>
                          <p className="text-secondary">{customer.lastOrder ? moment(customer.lastOrder.createdAt).format("MMM DD, YYYY") : "--"}</p>
                        </div>
                        <div className="info-box text-center border rounded mx-md-4 mx-lg-5">
                          <p className="mb-2"><b>Total Spent to Date</b></p>
                          <p className="text-prmary mb-1 box-price">{customer.totalSpentV2 ? `$${customer.totalSpentV2.amount}` : "--"}</p>
                          <p className="text-secondary">{`${customer.ordersCount} Orders`}</p>
                        </div>
                        <div className="info-box text-center border rounded ">
                          <p className="mb-2"><b>Average Order Value</b></p>
                          <p className="text-prmary mb-1 box-price">{customer.averageOrderAmountV2 ? `$${customer.averageOrderAmountV2.amount}` : "--"}</p>
                          <p className="text-secondary">{`${customer.ordersCount} Orders`}</p>
                        </div>
                      </div>
                      <div className="pt-0 pb-0 px-2 border rounded mt-4">
                        <div className="table-responsive">
                          <table className="table table-md mb-0">
                            <thead>
                              <tr>
                                <th scope="col">
                                  <p className="pt-1 pb-2">Order Placed</p>
                              Order #
                              </th>
                                <th scope="col">Order Date</th>
                                <th scope="col">
                                  Customer
                              </th>
                                <th scope="col">Status</th>
                                <th scope="col" className="align-right text-right">
                                  Total
                              </th>
                              </tr>
                            </thead>
                            <tbody>
                              {customer.orders.edges.map((order, index) => (
                                <tr key={index}>
                                  <td width="22%" className="text-primary py-3">
                                    <Link to={`/orders/${order.node.legacyResourceId}`}> <b>{order.node.name}</b> </Link>
                                  </td>
                                  <td width="18%" className="text-custom-date">
                                    {moment(order.node.createdAt).format("MMM DD, YYYY")}
                                  </td>
                                  <td className="text-secondary" width="37%">{`${order.node.customer.firstName} ${order.node.customer.lastName}`}</td>
                                  <td width="20%">

                                    {(order.node.displayFulfillmentStatus === 'UNFULFILLED') && (<span className="text-custom-gold">In Production</span>)}
                                    {(order.node.displayFulfillmentStatus !== 'UNFULFILLED') && (<span className="text-custom-green">Shipped</span>)}
                                  </td>
                                  <td className="text-secondary" width="1%">{`$${order.node.totalPriceSet.presentmentMoney.amount}`}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerDetail;
