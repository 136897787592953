
const CollectionAddProductsV2UserError = {
  "name": "CollectionAddProductsV2UserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "CollectionAddProductsV2UserErrorCode",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
export default CollectionAddProductsV2UserError;