import React, { Component } from "react";
import { ReactComponent as ComputerIcon } from "../../../assets/svgs/computer.svg";
import GeneralInput from '../../../components/common/general-input';
import { uploadFileToCloudinary } from '../../../querys/cloudinary'
import { getNetlifySnippetByTitle, addNetlifySnippet, updateNetlifySnippet } from '../../../querys/netlify-rest';
import { AuthContext } from '../../../auth/auth'
import { Alert } from "reactstrap";
import Loader from "../../loader/loader";

const style = {
    svgIcon: {
        border: "1px solid #dddddd",
        width: "100px",
        height: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    warning: {
        color: "#EE7E04"
    }
};

const snippetTitle = "Promo Bar"

class CustomizePromoBar extends Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            content: "Cutomized Editor",
            selectedOption: 1,
            snippetId: null,
            promoBar: {
                on: false,
                slots: [{
                    id: "slot-1",
                    title: "Promo slot 1",
                    iconUrl: null,
                    headline: "",
                    headlineSubtitle: "",
                    headlinePlaceholder: "YOUR PROMO TEXT HERE",
                    headlineSubtitlePlaceholder: "Your Supporting Promo Text Here"
                }, {
                    id: "slot-2",
                    title: "Promo slot 2",
                    iconUrl: null,
                    headline: "",
                    headlineSubtitle: "",
                    headlinePlaceholder: "YOUR PROMO TEXT HERE",
                    headlineSubtitlePlaceholder: "Your Supporting Promo Text Here"
                }, {
                    id: "slot-3",
                    title: "Promo slot 3",
                    iconUrl: null,
                    headline: "",
                    headlineSubtitle: "",
                    headlinePlaceholder: "YOUR PROMO TEXT HERE",
                    headlineSubtitlePlaceholder: "Your Supporting Promo Text Here"
                }]
            },
            alert: false,
            alertMessage: "",
            alertColor: ""
        };
    }

    componentDidMount = () => {
        if (!this.state.snippetId) {
            this.fetchNetlifySnippet();
        }
    }

    fetchNetlifySnippet = () => {
        getNetlifySnippetByTitle(this.context.reseller_site_id, snippetTitle)
            .then((response) => {
                if (response.id && response.general) {
                    const promoBarJSON = response.general.replace("<script>window.promoBar=", "").replace("</script>", "");
                    this.setState({
                        snippetId: response.id,
                        promoBar: JSON.parse(promoBarJSON),
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                }
            })
    }

    handleOptionChange = (changeEvent) => {
        const value = changeEvent.target.value;
        this.setState((state) => ({ promoBar: { ...state.promoBar, on: value === "1" } }));
    }

    _handleImgChange(e, slotId) {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        let { promoBar } = this.state;

        uploadFileToCloudinary(file, 100, this.context.store_subdomain)
            .then((imgUrl) => {

                const currentSlotIndex = promoBar.slots.findIndex(slot => slot.id === slotId);
                promoBar.slots[currentSlotIndex].iconUrl = imgUrl;
                this.setState({
                    promoBar
                });
            });
    }

    handleHeadlineChange = (ev, slotId) => {

        let { promoBar } = this.state;

        const value = ev.target.value;

        const slotIndex = promoBar.slots.findIndex(slot => slot.id === slotId);
        promoBar.slots[slotIndex].headline = value;

        this.setState({ promoBar });

    }

    handleHeadlineSubtitleChange = (ev, slotId) => {

        let { promoBar } = this.state;

        const value = ev.target.value;

        const slotIndex = promoBar.slots.findIndex(slot => slot.id === slotId);
        promoBar.slots[slotIndex].headlineSubtitle = value;

        this.setState({ promoBar });
    }

    handleSave = () => {
        const { promoBar, snippetId } = this.state

        const content = `\u003Cscript\u003Ewindow.promoBar=${JSON.stringify(promoBar)}\u003C/script\u003E`;
        if (snippetId) {

            updateNetlifySnippet(this.context.reseller_site_id, snippetTitle, content, snippetId).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    this.setState({
                        alert: true,
                        alertMessage: "Success: Your Promo Bar was updated on your site.",
                        alertColor: "success"
                    });
                    // Fetch the Snippet ID and update state
                    this.fetchNetlifySnippet();
                } else {
                    this.setState({
                        alert: true,
                        alertMessage: response.message,
                        alertColor: "danger"
                    });
                }
            })

        } else {
            addNetlifySnippet(this.context.reseller_site_id, snippetTitle, content).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    this.setState({
                        alert: true,
                        alertMessage: "Success: Your Promo Bar was updated on your site.",
                        alertColor: "success"
                    });
                    // Fetch the Snippet ID and update state
                    this.fetchNetlifySnippet();
                } else {
                    this.setState({
                        alert: true,
                        alertMessage: response.message,
                        alertColor: "danger"
                    });
                }
            })
        }

    }

    toggle() {
        this.setState({
            alert: !this.state.alert
        });
    }

    render() {

        if (this.state.loading) return <Loader />

        const { promoBar } = this.state;

        return (
            <React.Fragment>
                <div className="card">
                    <div className="card-header mb-0">
                        <h5>Promo Bar</h5>
                    </div>
                    <div className="card-body pt-0">
                        <div className="row">
                            <div className="col-xl-2 col-md-12 text-secondary mt-4">
                                <p className="text-secondary">
                                    The promo bar displays up to 3 promotional offers or text with icons on the homepage and product detail page. You can turn this on or off.
                                </p>
                                <p className="text-secondary">
                                    The recommended image size for promo icons is 100px &#x2715; 100px - JPG or PNG (transparent background).
                                </p>
                            </div>
                            <div className="col-xl-9 offset-xl-1 col-md-12 mt-4">
                                <label className="d-block" htmlFor="chk-ani-1">
                                    <input
                                        className="checkbox_animated"
                                        name="promoOnOff"
                                        id="chk-ani-1"
                                        type="radio"
                                        value="1"
                                        checked={promoBar.on}
                                        onChange={this.handleOptionChange}

                                    />
                                    On
                                    <input
                                        className="checkbox_animated ml-5"
                                        name="promoOnOff"
                                        id="chk-ani-2"
                                        type="radio"
                                        value="0"
                                        checked={!promoBar.on}
                                        onChange={this.handleOptionChange}
                                    />
                                   off
                                </label>
                                {promoBar.slots.map((slot, index) => {
                                    return (
                                        <div key={`slot-${index}`}>
                                            <div className="py-4">
                                                <p><b>{slot.title}</b></p>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <small className="text-muted">Icon</small>
                                                    <input style={{
                                                        position: "absolute",
                                                        width: "100px",
                                                        height: "124px",
                                                        left: "15px",
                                                        right: 0,
                                                        opacity: 0,
                                                        top: "17px",
                                                        cursor: "pointer"
                                                    }} type="file" onChange={(e) => this._handleImgChange(e, slot.id)} />
                                                    <div className="p-2" style={style.svgIcon}>
                                                        {
                                                            slot.iconUrl ? <img src={slot.iconUrl} style={{ maxHeight: "100%", width: "100%" }} /> : <ComputerIcon />
                                                        }
                                                    </div>
                                                    <a className="ml-4 text-primary" href="javascript:void(0);">
                                                        Change
                                            </a>
                                                </div>
                                                <div className="col-md-10">
                                                    <div className="form-group">
                                                        <label style={{ fontWeight: 400 }}>Headline</label>
                                                        <input
                                                            className="form-control my-1"
                                                            id={`validationCustom-headline-${index}`}
                                                            type="input"
                                                            required=""
                                                            placeholder={slot.headlinePlaceholder}
                                                            value={slot.headline}
                                                            onChange={(ev) => this.handleHeadlineChange(ev, slot.id)}
                                                        />
                                                        <span className="text-secondary pb-1">20 character limit</span>
                                                        <input
                                                            className="form-control my-1"
                                                            id={`validationCustom-headline2-${index}`}
                                                            type="input"
                                                            required=""
                                                            placeholder={slot.headlineSubtitlePlaceholder}
                                                            value={slot.headlineSubtitle}
                                                            onChange={(ev) => this.handleHeadlineSubtitleChange(ev, slot.id)}
                                                        />
                                                        <span className="text-secondary pb-1">40 character limit</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                <button className="btn btn-primary mt-4" type="button" onClick={this.handleSave}>Save</button>
                                <br></br>
                                <br></br>
                                <Alert color={this.state.alertColor} isOpen={this.state.alert} toggle={this.toggle.bind(this)} > {this.state.alertMessage} </Alert>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

CustomizePromoBar.contextType = AuthContext

export default CustomizePromoBar;
