
const ReverseFulfillmentOrder = {
  "name": "ReverseFulfillmentOrder",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "lineItems": "ReverseFulfillmentOrderLineItemConnection",
    "order": "Order",
    "reverseDeliveries": "ReverseDeliveryConnection",
    "status": "ReverseFulfillmentOrderStatus",
    "thirdPartyConfirmation": "ReverseFulfillmentOrderThirdPartyConfirmation"
  },
  "implementsNode": true
};
export default ReverseFulfillmentOrder;