
const ProductVariantsBulkInput = {
  "name": "ProductVariantsBulkInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "barcode": "String",
    "compareAtPrice": "Money",
    "id": "ID",
    "mediaSrc": "String",
    "inventoryPolicy": "ProductVariantInventoryPolicy",
    "inventoryQuantities": "InventoryLevelInput",
    "inventoryItem": "InventoryItemInput",
    "mediaId": "ID",
    "metafields": "MetafieldInput",
    "optionValues": "VariantOptionValueInput",
    "price": "Money",
    "sku": "String",
    "taxable": "Boolean",
    "taxCode": "String"
  }
};
export default ProductVariantsBulkInput;