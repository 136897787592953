import React, { Component, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { AuthContext } from "../../auth/auth";
import logoImg from  '../../assets/images/RSP_Logo_Dashboard.png';
import { toast } from 'react-toastify';

export class PasswordReset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassOne: '',
            newPassTwo: '',
            messaging: '',
            error: false,
        }
        this.handleNewPassOneChange = this.handleNewPassOneChange.bind(this);
        this.handleNewPassTwoChange = this.handleNewPassTwoChange.bind(this);
    }

    handleNewPassOneChange = (e) => {
        this.setState({
            newPassOne: e.target.value
        })
    }

    handleNewPassTwoChange = (e) => {
        this.setState({
            newPassTwo: e.target.value
        })
    }

    handleChangeSubmit = async (e) => {
        const userId = this.context.adminId.replace('gid://shopify/Customer/', '');
        e.preventDefault();
        if (this.state.newPassOne !== this.state.newPassTwo) {
            this.handleMessageChange('New passwords do not match, please verify and resubmit', true);
        } else {
            const response = await fetch('/.netlify/functions/server/api/reset-password', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					id: userId,
					password: this.state.newPassOne,
				})
            })
            try {
                
                if (!response.ok) throw response;

                const body = await response.text();
                if (response.status == 200) {
                    this.handleMessageChange(`Password reset successful!`);
                    setTimeout(() => { this.props.history.push('/products') }, 2000);
                } else {
                    this.handleMessageChange('There was an error resetting the password, please try again', true);
                }
            }
            catch (err) {
                //Show response error
                if (err.message) {
                    toast.error(`${err.message}`);
                } else {
                    toast.error(`${err.status}: ${err.statusText}`);
                }
            }
        }
    }

    handleMessageChange = (message, error=false) => {
		this.setState({
			messaging: message,
			error: error,
		})
    }
    
    render() {
        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="reset-wrapper">
                        <div className="reset-upper-wrapper">
                             <img src={logoImg} className="reset-image-logo" alt="Logo" />
                             <h3 className="reset-upper-text">Reset your password</h3>
                         </div>
                        <div
							className={classnames({
								"status-banner": true,
								"status-active": this.state.messaging,
								"status-error": this.state.error,
							})}
						>
							{this.state.messaging}
						</div>
                        <form onSubmit={this.handleChangeSubmit} className="form-horizontal auth-form">
                            <div className="form-group">
                                <input 
                                    required
                                    name="newPasswordOne" 
                                    type="password" 
                                    className="form-control" 
                                    placeholder="New Password" 
                                    onChange={this.handleNewPassOneChange}
                                />
                            </div>
                            <div className="form-group">
                                <input 
                                    required
                                    name="newPasswordTwo" 
                                    type="password" 
                                    className="form-control" 
                                    placeholder="Confirm New Password" 
                                    onChange={this.handleNewPassTwoChange}
                                />
                            </div>
                            <div className="form-button">
                                <button className="btn btn-primary" type="submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Fragment>
        )
    }
}

PasswordReset.contextType = AuthContext;

export default withRouter(PasswordReset)
