
const SellingPlanGroupRemoveProductVariantsPayload = {
  "name": "SellingPlanGroupRemoveProductVariantsPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "removedProductVariantIds": "ID",
    "userErrors": "SellingPlanGroupUserError"
  },
  "implementsNode": false
};
export default SellingPlanGroupRemoveProductVariantsPayload;