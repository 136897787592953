
const CustomerSmsMarketingConsentState = {
  "name": "CustomerSmsMarketingConsentState",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "consentCollectedFrom": "CustomerConsentCollectedFrom",
    "consentUpdatedAt": "DateTime",
    "marketingOptInLevel": "CustomerMarketingOptInLevel",
    "marketingState": "CustomerSmsMarketingState"
  },
  "implementsNode": false
};
export default CustomerSmsMarketingConsentState;