
const Abandonment = {
  "name": "Abandonment",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "abandonedCheckoutPayload": "AbandonedCheckout",
    "abandonmentType": "AbandonmentAbandonmentType",
    "app": "App",
    "cartUrl": "URL",
    "createdAt": "DateTime",
    "customer": "Customer",
    "customerHasNoDraftOrderSinceAbandonment": "Boolean",
    "customerHasNoOrderSinceAbandonment": "Boolean",
    "daysSinceLastAbandonmentEmail": "Int",
    "emailSentAt": "DateTime",
    "emailState": "AbandonmentEmailState",
    "hoursSinceLastAbandonedCheckout": "Float",
    "id": "ID",
    "inventoryAvailable": "Boolean",
    "isFromCustomStorefront": "Boolean",
    "isFromOnlineStore": "Boolean",
    "isFromShopApp": "Boolean",
    "isFromShopPay": "Boolean",
    "isMostSignificantAbandonment": "Boolean",
    "lastBrowseAbandonmentDate": "DateTime",
    "lastCartAbandonmentDate": "DateTime",
    "lastCheckoutAbandonmentDate": "DateTime",
    "mostRecentStep": "AbandonmentAbandonmentType",
    "productsAddedToCart": "CustomerVisitProductInfoConnection",
    "productsViewed": "CustomerVisitProductInfoConnection",
    "visitStartedAt": "DateTime"
  },
  "implementsNode": true
};
export default Abandonment;