
const SellingPlanRecurringPricingPolicyInput = {
  "name": "SellingPlanRecurringPricingPolicyInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "id": "ID",
    "adjustmentType": "SellingPlanPricingPolicyAdjustmentType",
    "adjustmentValue": "SellingPlanPricingPolicyValueInput",
    "afterCycle": "Int"
  }
};
export default SellingPlanRecurringPricingPolicyInput;