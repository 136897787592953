
const InventoryMoveQuantitiesInput = {
  "name": "InventoryMoveQuantitiesInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "reason": "String",
    "referenceDocumentUri": "String",
    "changes": "InventoryMoveQuantityChange"
  }
};
export default InventoryMoveQuantitiesInput;