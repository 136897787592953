
const MetaobjectCapabilityUpdateInput = {
  "name": "MetaobjectCapabilityUpdateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "publishable": "MetaobjectCapabilityPublishableInput",
    "translatable": "MetaobjectCapabilityTranslatableInput",
    "renderable": "MetaobjectCapabilityRenderableInput",
    "onlineStore": "MetaobjectCapabilityOnlineStoreInput"
  }
};
export default MetaobjectCapabilityUpdateInput;