
const CustomerPaymentMethodRemoteCreatePayload = {
  "name": "CustomerPaymentMethodRemoteCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customerPaymentMethod": "CustomerPaymentMethod",
    "userErrors": "CustomerPaymentMethodRemoteUserError"
  },
  "implementsNode": false
};
export default CustomerPaymentMethodRemoteCreatePayload;