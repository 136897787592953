
const FailedRequirement = {
  "name": "FailedRequirement",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "action": "NavigationItem",
    "message": "String"
  },
  "implementsNode": false
};
export default FailedRequirement;