
const SubscriptionBillingAttemptCreatePayload = {
  "name": "SubscriptionBillingAttemptCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "subscriptionBillingAttempt": "SubscriptionBillingAttempt",
    "userErrors": "BillingAttemptUserError"
  },
  "implementsNode": false
};
export default SubscriptionBillingAttemptCreatePayload;