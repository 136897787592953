
const DraftOrderInput = {
  "name": "DraftOrderInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "appliedDiscount": "DraftOrderAppliedDiscountInput",
    "billingAddress": "MailingAddressInput",
    "customAttributes": "AttributeInput",
    "email": "String",
    "lineItems": "DraftOrderLineItemInput",
    "metafields": "MetafieldInput",
    "localizationExtensions": "LocalizationExtensionInput",
    "note": "String",
    "shippingAddress": "MailingAddressInput",
    "shippingLine": "ShippingLineInput",
    "tags": "String",
    "taxExempt": "Boolean",
    "useCustomerDefaultAddress": "Boolean",
    "visibleToCustomer": "Boolean",
    "reserveInventoryUntil": "DateTime",
    "presentmentCurrencyCode": "CurrencyCode",
    "marketRegionCountryCode": "CountryCode",
    "phone": "String",
    "paymentTerms": "PaymentTermsInput",
    "purchasingEntity": "PurchasingEntityInput",
    "sourceName": "String",
    "poNumber": "String"
  }
};
export default DraftOrderInput;