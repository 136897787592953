
const OrderStagedChangeAddLineItemDiscount = {
  "name": "OrderStagedChangeAddLineItemDiscount",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "description": "String",
    "id": "ID",
    "value": "PricingValue"
  },
  "implementsNode": false
};
export default OrderStagedChangeAddLineItemDiscount;