
const QuantityPriceBreakInput = {
  "name": "QuantityPriceBreakInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "variantId": "ID",
    "price": "MoneyInput",
    "minimumQuantity": "Int"
  }
};
export default QuantityPriceBreakInput;