
const DraftOrderDeletePayload = {
  "name": "DraftOrderDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedId": "ID",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default DraftOrderDeletePayload;