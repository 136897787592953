
const CheckoutBrandingCornerRadiusVariablesInput = {
  "name": "CheckoutBrandingCornerRadiusVariablesInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "small": "Int",
    "base": "Int",
    "large": "Int"
  }
};
export default CheckoutBrandingCornerRadiusVariablesInput;