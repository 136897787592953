
const PriceListFixedPricesAddPayload = {
  "name": "PriceListFixedPricesAddPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "prices": "PriceListPrice",
    "userErrors": "PriceListPriceUserError"
  },
  "implementsNode": false
};
export default PriceListFixedPricesAddPayload;