
const DiscountRedeemCodeConnection = {
  "name": "DiscountRedeemCodeConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "DiscountRedeemCodeEdge",
    "nodes": "DiscountRedeemCode",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default DiscountRedeemCodeConnection;