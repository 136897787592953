
const InventoryMoveQuantityTerminalInput = {
  "name": "InventoryMoveQuantityTerminalInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "locationId": "ID",
    "name": "String",
    "ledgerDocumentUri": "String"
  }
};
export default InventoryMoveQuantityTerminalInput;