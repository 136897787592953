
const OrderPaymentCollectionDetails = {
  "name": "OrderPaymentCollectionDetails",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "additionalPaymentCollectionUrl": "URL",
    "vaultedPaymentMethods": "PaymentMandate"
  },
  "implementsNode": false
};
export default OrderPaymentCollectionDetails;