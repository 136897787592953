
const SavedSearch = {
  "name": "SavedSearch",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "filters": "SearchFilter",
    "id": "ID",
    "legacyResourceId": "UnsignedInt64",
    "name": "String",
    "query": "String",
    "resourceType": "SearchResultType",
    "searchTerms": "String"
  },
  "implementsNode": true
};
export default SavedSearch;