import React, { Component } from "react";
import { uploadFileToCloudinary } from '../../../querys/cloudinary';
import { Alert } from "reactstrap";
import { getNetlifySnippetByTitle, addNetlifySnippet, updateNetlifySnippet } from '../../../querys/netlify-rest';
import { AuthContext } from "../../../auth/auth";
import FileDropzone from "../../common/file-dropzone";

const snippetTitle = "Logo";


class CustomizeCompanyLogo extends Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        this.state = {
            logoFileName: "No file selected.",
            logoCloudinaryUrl: null,
            alert: false,
            alertMessage: "",
            alertColor: "success",
            netlifySnippetId: "",
            netlifySnippetContent: "",
        };
        this.handleDrop = this.handleDrop.bind(this);
        this.fetchNetlifySnippet = this.fetchNetlifySnippet.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    componentDidMount() {
        this.fetchNetlifySnippet();
    }

    fetchNetlifySnippet() {
        // Check for existing Netlify snippet
        getNetlifySnippetByTitle(this.context.reseller_site_id, snippetTitle)
            .then((response) => {
                if (response.id && response.general) {
                    // Update the state with the Netlify snippet data
                    let logoFileName = "No file selected";
                    if (response.general.includes("logoFileName")) {
                        logoFileName = response.general.replace(/\s/g, '').replace(/(<script>window\.logoUrl=")+.*(";window\.logoFileName=)/g, "").replace(";</script>", "");
                        logoFileName = JSON.parse(logoFileName);
                    } else {
                        const decodedSnippet = response.general.replace(/\s/g, '').replace("<script>window.logoUrl=", "").replace(";</script>", "");
                        const logoUrlSnippetContent = JSON.parse(decodedSnippet);
                        let expression = /[^/\\&\?]+\.\w{3,4}(?=([\?&].*$|$))/g;
                        const regex = new RegExp(expression);
                        const regexExecution = regex.exec(logoUrlSnippetContent);
                        if (regexExecution) {
                            logoFileName = regexExecution[0];
                        }
                    }
                    this.setState({
                        logoFileName,
                        netlifySnippetId: response.id,
                        netlifySnippetContent: response.general
                    });
                } else {
                    console.log(response);
                }
            });
    }

    handleDrop(acceptedFiles) {
        if (acceptedFiles.length > 0) {
            this.setState({ logoFileName: "Uploading..." });
            // Upload file to Cloudinary
            uploadFileToCloudinary(acceptedFiles[0], 160, this.context.store_subdomain)
                .then((imgUrl) => {
                    this.setState({
                        logoFileName: acceptedFiles[0].name,
                        logoCloudinaryUrl: imgUrl
                    });
                });
        } else {
            this.setState({
                alert: true,
                alertMessage: "Wrong file type. Please upload an image.",
                alertColor: "danger"
            });
        }
    }

    handleSave() {
        if (this.state.logoCloudinaryUrl) {
            // Upload logo to Netlify snippet
            let snippetContent = `<script>window.logoUrl = "${this.state.logoCloudinaryUrl}";window.logoFileName="${this.state.logoFileName}";</script>`;
            //Always fetch snippet before saving in case snippet's ID has changed.
            getNetlifySnippetByTitle(this.context.reseller_site_id, snippetTitle)
                .then((response) => {
                    if (response.id && response.general) {
                        // Update Netlify Snippet
                        updateNetlifySnippet(this.context.reseller_site_id, snippetTitle, snippetContent, response.id).then((response) => {
                            if (response.status === 200) {
                                this.setState({
                                    alert: true,
                                    alertMessage: "Success: Your company logo was updated on your site.",
                                    alertColor: "success"
                                });
                                // Fetch the Snippet ID and update state
                                this.fetchNetlifySnippet();
                            } else {
                                this.setState({
                                    alert: true,
                                    alertMessage: response.message,
                                    alertColor: "danger"
                                });
                            }
                        });
                    } else {
                        // Add Netlify Snippet
                        addNetlifySnippet(this.context.reseller_site_id, snippetTitle, snippetContent)
                            .then((response) => {
                                if (response.status === 201) {
                                    this.setState({
                                        alert: true,
                                        alertMessage: "Success: Your company logo was added to your site.",
                                        alertColor: "success"
                                    });
                                    // Fetch the Snippet ID and update state
                                    this.fetchNetlifySnippet();
                                } else {
                                    this.setState({
                                        alert: true,
                                        alertMessage: response.message,
                                        alertColor: "danger"
                                    });
                                }
                            });
                    }
                });
        } else {
            // User hasn't selected a logo to save
            this.setState({
                alert: true,
                alertMessage: "Failed to save. You must first choose a company logo file.",
                alertColor: "danger"
            });
        }

    }

    toggle() {
        this.setState({
            alert: !this.state.alert
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="card">
                    <div className="card-header">
                        <h5>Store Logo</h5>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4 col-xl-2 pr-md-3 mb-4">
                                <p className="text-secondary">
                                    Your store logo displays at a maximum height of 80px. Recommended file size is  160px tall.
                                 </p>
                            </div>
                            <div className="col-md-8 col-xl-9 offset-xl-1">
                                <FileDropzone handleDrop={this.handleDrop} fileName={this.state.logoFileName} fileTypes="image/*" />

                                <button className="btn btn-primary" onClick={this.handleSave}> Save </button>
                                <br></br>
                                <br></br>
                                <Alert color={this.state.alertColor} isOpen={this.state.alert} toggle={this.toggle.bind(this)} > {this.state.alertMessage} </Alert>
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment >
        );
    }
}

CustomizeCompanyLogo.contextType = AuthContext;

export default CustomizeCompanyLogo;
