
const SelectedOption = {
  "name": "SelectedOption",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "name": "String",
    "optionValue": "ProductOptionValue",
    "value": "String"
  },
  "implementsNode": false
};
export default SelectedOption;