
const AppPurchaseOneTime = {
  "name": "AppPurchaseOneTime",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "createdAt": "DateTime",
    "id": "ID",
    "name": "String",
    "price": "MoneyV2",
    "status": "AppPurchaseStatus",
    "test": "Boolean"
  },
  "implementsNode": true
};
export default AppPurchaseOneTime;