
const CalculatedLineItem = {
  "name": "CalculatedLineItem",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "calculatedDiscountAllocations": "CalculatedDiscountAllocation",
    "customAttributes": "Attribute",
    "discountAllocations": "DiscountAllocation",
    "discountedUnitPriceSet": "MoneyBag",
    "editableQuantity": "Int",
    "editableQuantityBeforeChanges": "Int",
    "editableSubtotalSet": "MoneyBag",
    "hasStagedLineItemDiscount": "Boolean",
    "id": "ID",
    "image": "Image",
    "originalUnitPriceSet": "MoneyBag",
    "quantity": "Int",
    "restockable": "Boolean",
    "restocking": "Boolean",
    "sku": "String",
    "stagedChanges": "OrderStagedChange",
    "title": "String",
    "uneditableSubtotalSet": "MoneyBag",
    "variant": "ProductVariant",
    "variantTitle": "String"
  },
  "implementsNode": false
};
export default CalculatedLineItem;