
const Mutation = {
  "name": "Mutation",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "abandonmentEmailStateUpdate": "AbandonmentEmailStateUpdatePayload",
    "abandonmentUpdateActivitiesDeliveryStatuses": "AbandonmentUpdateActivitiesDeliveryStatusesPayload",
    "appPurchaseOneTimeCreate": "AppPurchaseOneTimeCreatePayload",
    "appSubscriptionCancel": "AppSubscriptionCancelPayload",
    "appSubscriptionCreate": "AppSubscriptionCreatePayload",
    "appSubscriptionLineItemUpdate": "AppSubscriptionLineItemUpdatePayload",
    "appSubscriptionTrialExtend": "AppSubscriptionTrialExtendPayload",
    "appUsageRecordCreate": "AppUsageRecordCreatePayload",
    "bulkOperationCancel": "BulkOperationCancelPayload",
    "bulkOperationRunMutation": "BulkOperationRunMutationPayload",
    "bulkOperationRunQuery": "BulkOperationRunQueryPayload",
    "bulkProductResourceFeedbackCreate": "BulkProductResourceFeedbackCreatePayload",
    "cartTransformCreate": "CartTransformCreatePayload",
    "cartTransformDelete": "CartTransformDeletePayload",
    "catalogContextUpdate": "CatalogContextUpdatePayload",
    "catalogCreate": "CatalogCreatePayload",
    "catalogDelete": "CatalogDeletePayload",
    "catalogUpdate": "CatalogUpdatePayload",
    "checkoutBrandingUpsert": "CheckoutBrandingUpsertPayload",
    "collectionAddProducts": "CollectionAddProductsPayload",
    "collectionAddProductsV2": "CollectionAddProductsV2Payload",
    "collectionCreate": "CollectionCreatePayload",
    "collectionDelete": "CollectionDeletePayload",
    "collectionPublish": "CollectionPublishPayload",
    "collectionRemoveProducts": "CollectionRemoveProductsPayload",
    "collectionReorderProducts": "CollectionReorderProductsPayload",
    "collectionUnpublish": "CollectionUnpublishPayload",
    "collectionUpdate": "CollectionUpdatePayload",
    "companiesDelete": "CompaniesDeletePayload",
    "companyAddressDelete": "CompanyAddressDeletePayload",
    "companyAssignCustomerAsContact": "CompanyAssignCustomerAsContactPayload",
    "companyAssignMainContact": "CompanyAssignMainContactPayload",
    "companyContactAssignRole": "CompanyContactAssignRolePayload",
    "companyContactAssignRoles": "CompanyContactAssignRolesPayload",
    "companyContactCreate": "CompanyContactCreatePayload",
    "companyContactDelete": "CompanyContactDeletePayload",
    "companyContactRemoveFromCompany": "CompanyContactRemoveFromCompanyPayload",
    "companyContactRevokeRole": "CompanyContactRevokeRolePayload",
    "companyContactRevokeRoles": "CompanyContactRevokeRolesPayload",
    "companyContactSendWelcomeEmail": "CompanyContactSendWelcomeEmailPayload",
    "companyContactUpdate": "CompanyContactUpdatePayload",
    "companyContactsDelete": "CompanyContactsDeletePayload",
    "companyCreate": "CompanyCreatePayload",
    "companyDelete": "CompanyDeletePayload",
    "companyLocationAssignAddress": "CompanyLocationAssignAddressPayload",
    "companyLocationAssignRoles": "CompanyLocationAssignRolesPayload",
    "companyLocationAssignTaxExemptions": "CompanyLocationAssignTaxExemptionsPayload",
    "companyLocationCreate": "CompanyLocationCreatePayload",
    "companyLocationCreateTaxRegistration": "CompanyLocationCreateTaxRegistrationPayload",
    "companyLocationDelete": "CompanyLocationDeletePayload",
    "companyLocationRevokeRoles": "CompanyLocationRevokeRolesPayload",
    "companyLocationRevokeTaxExemptions": "CompanyLocationRevokeTaxExemptionsPayload",
    "companyLocationRevokeTaxRegistration": "CompanyLocationRevokeTaxRegistrationPayload",
    "companyLocationUpdate": "CompanyLocationUpdatePayload",
    "companyLocationsDelete": "CompanyLocationsDeletePayload",
    "companyRevokeMainContact": "CompanyRevokeMainContactPayload",
    "companyUpdate": "CompanyUpdatePayload",
    "customerAccessTokenCreate": "CustomerAccessTokenCreatePayload",
    "customerAddTaxExemptions": "CustomerAddTaxExemptionsPayload",
    "customerCancelDataErasure": "CustomerCancelDataErasurePayload",
    "customerCreate": "CustomerCreatePayload",
    "customerDelete": "CustomerDeletePayload",
    "customerEmailMarketingConsentUpdate": "CustomerEmailMarketingConsentUpdatePayload",
    "customerGenerateAccountActivationUrl": "CustomerGenerateAccountActivationUrlPayload",
    "customerMerge": "CustomerMergePayload",
    "customerPaymentMethodCreateFromDuplicationData": "CustomerPaymentMethodCreateFromDuplicationDataPayload",
    "customerPaymentMethodCreditCardCreate": "CustomerPaymentMethodCreditCardCreatePayload",
    "customerPaymentMethodCreditCardUpdate": "CustomerPaymentMethodCreditCardUpdatePayload",
    "customerPaymentMethodGetDuplicationData": "CustomerPaymentMethodGetDuplicationDataPayload",
    "customerPaymentMethodGetUpdateUrl": "CustomerPaymentMethodGetUpdateUrlPayload",
    "customerPaymentMethodPaypalBillingAgreementCreate": "CustomerPaymentMethodPaypalBillingAgreementCreatePayload",
    "customerPaymentMethodPaypalBillingAgreementUpdate": "CustomerPaymentMethodPaypalBillingAgreementUpdatePayload",
    "customerPaymentMethodRemoteCreate": "CustomerPaymentMethodRemoteCreatePayload",
    "customerPaymentMethodRemoteCreditCardCreate": "CustomerPaymentMethodRemoteCreditCardCreatePayload",
    "customerPaymentMethodRevoke": "CustomerPaymentMethodRevokePayload",
    "customerPaymentMethodSendUpdateEmail": "CustomerPaymentMethodSendUpdateEmailPayload",
    "customerRemoveTaxExemptions": "CustomerRemoveTaxExemptionsPayload",
    "customerReplaceTaxExemptions": "CustomerReplaceTaxExemptionsPayload",
    "customerRequestDataErasure": "CustomerRequestDataErasurePayload",
    "customerSegmentMembersQueryCreate": "CustomerSegmentMembersQueryCreatePayload",
    "customerSmsMarketingConsentUpdate": "CustomerSmsMarketingConsentUpdatePayload",
    "customerUpdate": "CustomerUpdatePayload",
    "customerUpdateDefaultAddress": "CustomerUpdateDefaultAddressPayload",
    "delegateAccessTokenCreate": "DelegateAccessTokenCreatePayload",
    "delegateAccessTokenDestroy": "DelegateAccessTokenDestroyPayload",
    "deliveryCustomizationActivation": "DeliveryCustomizationActivationPayload",
    "deliveryCustomizationCreate": "DeliveryCustomizationCreatePayload",
    "deliveryCustomizationDelete": "DeliveryCustomizationDeletePayload",
    "deliveryCustomizationUpdate": "DeliveryCustomizationUpdatePayload",
    "deliveryProfileCreate": "deliveryProfileCreatePayload",
    "deliveryProfileRemove": "deliveryProfileRemovePayload",
    "deliveryProfileUpdate": "deliveryProfileUpdatePayload",
    "deliverySettingUpdate": "DeliverySettingUpdatePayload",
    "deliveryShippingOriginAssign": "DeliveryShippingOriginAssignPayload",
    "discountAutomaticActivate": "DiscountAutomaticActivatePayload",
    "discountAutomaticAppCreate": "DiscountAutomaticAppCreatePayload",
    "discountAutomaticAppUpdate": "DiscountAutomaticAppUpdatePayload",
    "discountAutomaticBasicCreate": "DiscountAutomaticBasicCreatePayload",
    "discountAutomaticBasicUpdate": "DiscountAutomaticBasicUpdatePayload",
    "discountAutomaticBulkDelete": "DiscountAutomaticBulkDeletePayload",
    "discountAutomaticBxgyCreate": "DiscountAutomaticBxgyCreatePayload",
    "discountAutomaticBxgyUpdate": "DiscountAutomaticBxgyUpdatePayload",
    "discountAutomaticDeactivate": "DiscountAutomaticDeactivatePayload",
    "discountAutomaticDelete": "DiscountAutomaticDeletePayload",
    "discountAutomaticFreeShippingCreate": "DiscountAutomaticFreeShippingCreatePayload",
    "discountAutomaticFreeShippingUpdate": "DiscountAutomaticFreeShippingUpdatePayload",
    "discountCodeActivate": "DiscountCodeActivatePayload",
    "discountCodeAppCreate": "DiscountCodeAppCreatePayload",
    "discountCodeAppUpdate": "DiscountCodeAppUpdatePayload",
    "discountCodeBasicCreate": "DiscountCodeBasicCreatePayload",
    "discountCodeBasicUpdate": "DiscountCodeBasicUpdatePayload",
    "discountCodeBulkActivate": "DiscountCodeBulkActivatePayload",
    "discountCodeBulkDeactivate": "DiscountCodeBulkDeactivatePayload",
    "discountCodeBulkDelete": "DiscountCodeBulkDeletePayload",
    "discountCodeBxgyCreate": "DiscountCodeBxgyCreatePayload",
    "discountCodeBxgyUpdate": "DiscountCodeBxgyUpdatePayload",
    "discountCodeDeactivate": "DiscountCodeDeactivatePayload",
    "discountCodeDelete": "DiscountCodeDeletePayload",
    "discountCodeFreeShippingCreate": "DiscountCodeFreeShippingCreatePayload",
    "discountCodeFreeShippingUpdate": "DiscountCodeFreeShippingUpdatePayload",
    "discountCodeRedeemCodeBulkDelete": "DiscountCodeRedeemCodeBulkDeletePayload",
    "discountRedeemCodeBulkAdd": "DiscountRedeemCodeBulkAddPayload",
    "disputeEvidenceUpdate": "DisputeEvidenceUpdatePayload",
    "draftOrderBulkAddTags": "DraftOrderBulkAddTagsPayload",
    "draftOrderBulkDelete": "DraftOrderBulkDeletePayload",
    "draftOrderBulkRemoveTags": "DraftOrderBulkRemoveTagsPayload",
    "draftOrderCalculate": "DraftOrderCalculatePayload",
    "draftOrderComplete": "DraftOrderCompletePayload",
    "draftOrderCreate": "DraftOrderCreatePayload",
    "draftOrderCreateFromOrder": "DraftOrderCreateFromOrderPayload",
    "draftOrderCreateMerchantCheckout": "DraftOrderCreateMerchantCheckoutPayload",
    "draftOrderDelete": "DraftOrderDeletePayload",
    "draftOrderDuplicate": "DraftOrderDuplicatePayload",
    "draftOrderInvoicePreview": "DraftOrderInvoicePreviewPayload",
    "draftOrderInvoiceSend": "DraftOrderInvoiceSendPayload",
    "draftOrderUpdate": "DraftOrderUpdatePayload",
    "eventBridgeServerPixelUpdate": "EventBridgeServerPixelUpdatePayload",
    "eventBridgeWebhookSubscriptionCreate": "EventBridgeWebhookSubscriptionCreatePayload",
    "eventBridgeWebhookSubscriptionUpdate": "EventBridgeWebhookSubscriptionUpdatePayload",
    "fileAcknowledgeUpdateFailed": "FileAcknowledgeUpdateFailedPayload",
    "fileCreate": "FileCreatePayload",
    "fileDelete": "FileDeletePayload",
    "fileUpdate": "FileUpdatePayload",
    "flowGenerateSignature": "FlowGenerateSignaturePayload",
    "flowTriggerReceive": "FlowTriggerReceivePayload",
    "fulfillmentCancel": "FulfillmentCancelPayload",
    "fulfillmentConstraintRuleCreate": "FulfillmentConstraintRuleCreatePayload",
    "fulfillmentConstraintRuleDelete": "FulfillmentConstraintRuleDeletePayload",
    "fulfillmentCreateV2": "FulfillmentCreateV2Payload",
    "fulfillmentEventCreate": "FulfillmentEventCreatePayload",
    "fulfillmentOrderAcceptCancellationRequest": "FulfillmentOrderAcceptCancellationRequestPayload",
    "fulfillmentOrderAcceptFulfillmentRequest": "FulfillmentOrderAcceptFulfillmentRequestPayload",
    "fulfillmentOrderCancel": "FulfillmentOrderCancelPayload",
    "fulfillmentOrderClose": "FulfillmentOrderClosePayload",
    "fulfillmentOrderHold": "FulfillmentOrderHoldPayload",
    "fulfillmentOrderLineItemsPreparedForPickup": "FulfillmentOrderLineItemsPreparedForPickupPayload",
    "fulfillmentOrderMerge": "FulfillmentOrderMergePayload",
    "fulfillmentOrderMove": "FulfillmentOrderMovePayload",
    "fulfillmentOrderOpen": "FulfillmentOrderOpenPayload",
    "fulfillmentOrderRejectCancellationRequest": "FulfillmentOrderRejectCancellationRequestPayload",
    "fulfillmentOrderRejectFulfillmentRequest": "FulfillmentOrderRejectFulfillmentRequestPayload",
    "fulfillmentOrderReleaseHold": "FulfillmentOrderReleaseHoldPayload",
    "fulfillmentOrderReschedule": "FulfillmentOrderReschedulePayload",
    "fulfillmentOrderSplit": "FulfillmentOrderSplitPayload",
    "fulfillmentOrderSubmitCancellationRequest": "FulfillmentOrderSubmitCancellationRequestPayload",
    "fulfillmentOrderSubmitFulfillmentRequest": "FulfillmentOrderSubmitFulfillmentRequestPayload",
    "fulfillmentOrdersReleaseHolds": "FulfillmentOrdersReleaseHoldsPayload",
    "fulfillmentOrdersSetFulfillmentDeadline": "FulfillmentOrdersSetFulfillmentDeadlinePayload",
    "fulfillmentServiceCreate": "FulfillmentServiceCreatePayload",
    "fulfillmentServiceDelete": "FulfillmentServiceDeletePayload",
    "fulfillmentServiceUpdate": "FulfillmentServiceUpdatePayload",
    "fulfillmentTrackingInfoUpdateV2": "FulfillmentTrackingInfoUpdateV2Payload",
    "giftCardCreate": "GiftCardCreatePayload",
    "giftCardDisable": "GiftCardDisablePayload",
    "giftCardUpdate": "GiftCardUpdatePayload",
    "inventoryActivate": "InventoryActivatePayload",
    "inventoryAdjustQuantities": "InventoryAdjustQuantitiesPayload",
    "inventoryBulkToggleActivation": "InventoryBulkToggleActivationPayload",
    "inventoryDeactivate": "InventoryDeactivatePayload",
    "inventoryItemUpdate": "InventoryItemUpdatePayload",
    "inventoryMoveQuantities": "InventoryMoveQuantitiesPayload",
    "inventorySetOnHandQuantities": "InventorySetOnHandQuantitiesPayload",
    "inventorySetScheduledChanges": "InventorySetScheduledChangesPayload",
    "locationActivate": "LocationActivatePayload",
    "locationAdd": "LocationAddPayload",
    "locationDeactivate": "LocationDeactivatePayload",
    "locationDelete": "LocationDeletePayload",
    "locationEdit": "LocationEditPayload",
    "locationLocalPickupDisable": "LocationLocalPickupDisablePayload",
    "locationLocalPickupEnable": "LocationLocalPickupEnablePayload",
    "marketCreate": "MarketCreatePayload",
    "marketCurrencySettingsUpdate": "MarketCurrencySettingsUpdatePayload",
    "marketDelete": "MarketDeletePayload",
    "marketLocalizationsRegister": "MarketLocalizationsRegisterPayload",
    "marketLocalizationsRemove": "MarketLocalizationsRemovePayload",
    "marketRegionDelete": "MarketRegionDeletePayload",
    "marketRegionsCreate": "MarketRegionsCreatePayload",
    "marketRegionsDelete": "MarketRegionsDeletePayload",
    "marketUpdate": "MarketUpdatePayload",
    "marketWebPresenceCreate": "MarketWebPresenceCreatePayload",
    "marketWebPresenceDelete": "MarketWebPresenceDeletePayload",
    "marketWebPresenceUpdate": "MarketWebPresenceUpdatePayload",
    "marketingActivitiesDeleteAllExternal": "MarketingActivitiesDeleteAllExternalPayload",
    "marketingActivityCreate": "MarketingActivityCreatePayload",
    "marketingActivityCreateExternal": "MarketingActivityCreateExternalPayload",
    "marketingActivityDeleteExternal": "MarketingActivityDeleteExternalPayload",
    "marketingActivityUpdate": "MarketingActivityUpdatePayload",
    "marketingActivityUpdateExternal": "MarketingActivityUpdateExternalPayload",
    "marketingActivityUpsertExternal": "MarketingActivityUpsertExternalPayload",
    "marketingEngagementCreate": "MarketingEngagementCreatePayload",
    "marketingEngagementsDelete": "MarketingEngagementsDeletePayload",
    "metafieldDefinitionCreate": "MetafieldDefinitionCreatePayload",
    "metafieldDefinitionDelete": "MetafieldDefinitionDeletePayload",
    "metafieldDefinitionPin": "MetafieldDefinitionPinPayload",
    "metafieldDefinitionUnpin": "MetafieldDefinitionUnpinPayload",
    "metafieldDefinitionUpdate": "MetafieldDefinitionUpdatePayload",
    "metafieldDelete": "MetafieldDeletePayload",
    "metafieldStorefrontVisibilityCreate": "MetafieldStorefrontVisibilityCreatePayload",
    "metafieldStorefrontVisibilityDelete": "MetafieldStorefrontVisibilityDeletePayload",
    "metafieldsDelete": "MetafieldsDeletePayload",
    "metafieldsSet": "MetafieldsSetPayload",
    "metaobjectBulkDelete": "MetaobjectBulkDeletePayload",
    "metaobjectCreate": "MetaobjectCreatePayload",
    "metaobjectDefinitionCreate": "MetaobjectDefinitionCreatePayload",
    "metaobjectDefinitionDelete": "MetaobjectDefinitionDeletePayload",
    "metaobjectDefinitionUpdate": "MetaobjectDefinitionUpdatePayload",
    "metaobjectDelete": "MetaobjectDeletePayload",
    "metaobjectUpdate": "MetaobjectUpdatePayload",
    "metaobjectUpsert": "MetaobjectUpsertPayload",
    "orderCancel": "OrderCancelPayload",
    "orderCapture": "OrderCapturePayload",
    "orderClose": "OrderClosePayload",
    "orderCreateMandatePayment": "OrderCreateMandatePaymentPayload",
    "orderEditAddCustomItem": "OrderEditAddCustomItemPayload",
    "orderEditAddLineItemDiscount": "OrderEditAddLineItemDiscountPayload",
    "orderEditAddShippingLine": "OrderEditAddShippingLinePayload",
    "orderEditAddVariant": "OrderEditAddVariantPayload",
    "orderEditBegin": "OrderEditBeginPayload",
    "orderEditCommit": "OrderEditCommitPayload",
    "orderEditRemoveDiscount": "OrderEditRemoveDiscountPayload",
    "orderEditRemoveLineItemDiscount": "OrderEditRemoveLineItemDiscountPayload",
    "orderEditRemoveShippingLine": "OrderEditRemoveShippingLinePayload",
    "orderEditSetQuantity": "OrderEditSetQuantityPayload",
    "orderEditUpdateDiscount": "OrderEditUpdateDiscountPayload",
    "orderEditUpdateShippingLine": "OrderEditUpdateShippingLinePayload",
    "orderInvoiceSend": "OrderInvoiceSendPayload",
    "orderMarkAsPaid": "OrderMarkAsPaidPayload",
    "orderOpen": "OrderOpenPayload",
    "orderRiskAssessmentCreate": "OrderRiskAssessmentCreatePayload",
    "orderUpdate": "OrderUpdatePayload",
    "paymentCustomizationActivation": "PaymentCustomizationActivationPayload",
    "paymentCustomizationCreate": "PaymentCustomizationCreatePayload",
    "paymentCustomizationDelete": "PaymentCustomizationDeletePayload",
    "paymentCustomizationUpdate": "PaymentCustomizationUpdatePayload",
    "paymentReminderSend": "PaymentReminderSendPayload",
    "paymentTermsCreate": "PaymentTermsCreatePayload",
    "paymentTermsDelete": "PaymentTermsDeletePayload",
    "paymentTermsUpdate": "PaymentTermsUpdatePayload",
    "priceListCreate": "PriceListCreatePayload",
    "priceListDelete": "PriceListDeletePayload",
    "priceListFixedPricesAdd": "PriceListFixedPricesAddPayload",
    "priceListFixedPricesByProductUpdate": "PriceListFixedPricesByProductUpdatePayload",
    "priceListFixedPricesDelete": "PriceListFixedPricesDeletePayload",
    "priceListFixedPricesUpdate": "PriceListFixedPricesUpdatePayload",
    "priceListUpdate": "PriceListUpdatePayload",
    "priceRuleActivate": "PriceRuleActivatePayload",
    "priceRuleCreate": "PriceRuleCreatePayload",
    "priceRuleDeactivate": "PriceRuleDeactivatePayload",
    "priceRuleDelete": "PriceRuleDeletePayload",
    "priceRuleDiscountCodeCreate": "PriceRuleDiscountCodeCreatePayload",
    "priceRuleDiscountCodeUpdate": "PriceRuleDiscountCodeUpdatePayload",
    "priceRuleUpdate": "PriceRuleUpdatePayload",
    "privateMetafieldDelete": "PrivateMetafieldDeletePayload",
    "privateMetafieldUpsert": "PrivateMetafieldUpsertPayload",
    "productAppendImages": "ProductAppendImagesPayload",
    "productChangeStatus": "ProductChangeStatusPayload",
    "productCreate": "ProductCreatePayload",
    "productCreateMedia": "ProductCreateMediaPayload",
    "productDelete": "ProductDeletePayload",
    "productDeleteAsync": "ProductDeleteAsyncPayload",
    "productDeleteImages": "ProductDeleteImagesPayload",
    "productDeleteMedia": "ProductDeleteMediaPayload",
    "productDuplicate": "ProductDuplicatePayload",
    "productDuplicateAsyncV2": "ProductDuplicateAsyncV2Payload",
    "productFeedCreate": "ProductFeedCreatePayload",
    "productFeedDelete": "ProductFeedDeletePayload",
    "productFullSync": "ProductFullSyncPayload",
    "productImageUpdate": "ProductImageUpdatePayload",
    "productJoinSellingPlanGroups": "ProductJoinSellingPlanGroupsPayload",
    "productLeaveSellingPlanGroups": "ProductLeaveSellingPlanGroupsPayload",
    "productOptionUpdate": "ProductOptionUpdatePayload",
    "productOptionsCreate": "ProductOptionsCreatePayload",
    "productOptionsDelete": "ProductOptionsDeletePayload",
    "productOptionsReorder": "ProductOptionsReorderPayload",
    "productPublish": "ProductPublishPayload",
    "productReorderImages": "ProductReorderImagesPayload",
    "productReorderMedia": "ProductReorderMediaPayload",
    "productSet": "ProductSetPayload",
    "productUnpublish": "ProductUnpublishPayload",
    "productUpdate": "ProductUpdatePayload",
    "productUpdateMedia": "ProductUpdateMediaPayload",
    "productVariantAppendMedia": "ProductVariantAppendMediaPayload",
    "productVariantCreate": "ProductVariantCreatePayload",
    "productVariantDelete": "ProductVariantDeletePayload",
    "productVariantDetachMedia": "ProductVariantDetachMediaPayload",
    "productVariantJoinSellingPlanGroups": "ProductVariantJoinSellingPlanGroupsPayload",
    "productVariantLeaveSellingPlanGroups": "ProductVariantLeaveSellingPlanGroupsPayload",
    "productVariantRelationshipBulkUpdate": "ProductVariantRelationshipBulkUpdatePayload",
    "productVariantUpdate": "ProductVariantUpdatePayload",
    "productVariantsBulkCreate": "ProductVariantsBulkCreatePayload",
    "productVariantsBulkDelete": "ProductVariantsBulkDeletePayload",
    "productVariantsBulkReorder": "ProductVariantsBulkReorderPayload",
    "productVariantsBulkUpdate": "ProductVariantsBulkUpdatePayload",
    "pubSubServerPixelUpdate": "PubSubServerPixelUpdatePayload",
    "pubSubWebhookSubscriptionCreate": "PubSubWebhookSubscriptionCreatePayload",
    "pubSubWebhookSubscriptionUpdate": "PubSubWebhookSubscriptionUpdatePayload",
    "publicationCreate": "PublicationCreatePayload",
    "publicationDelete": "PublicationDeletePayload",
    "publicationUpdate": "PublicationUpdatePayload",
    "publishablePublish": "PublishablePublishPayload",
    "publishablePublishToCurrentChannel": "PublishablePublishToCurrentChannelPayload",
    "publishableUnpublish": "PublishableUnpublishPayload",
    "publishableUnpublishToCurrentChannel": "PublishableUnpublishToCurrentChannelPayload",
    "quantityPricingByVariantUpdate": "QuantityPricingByVariantUpdatePayload",
    "quantityRulesAdd": "QuantityRulesAddPayload",
    "quantityRulesDelete": "QuantityRulesDeletePayload",
    "refundCreate": "RefundCreatePayload",
    "returnApproveRequest": "ReturnApproveRequestPayload",
    "returnCancel": "ReturnCancelPayload",
    "returnClose": "ReturnClosePayload",
    "returnCreate": "ReturnCreatePayload",
    "returnDeclineRequest": "ReturnDeclineRequestPayload",
    "returnRefund": "ReturnRefundPayload",
    "returnReopen": "ReturnReopenPayload",
    "returnRequest": "ReturnRequestPayload",
    "reverseDeliveryCreateWithShipping": "ReverseDeliveryCreateWithShippingPayload",
    "reverseDeliveryDispose": "ReverseDeliveryDisposePayload",
    "reverseDeliveryShippingUpdate": "ReverseDeliveryShippingUpdatePayload",
    "reverseFulfillmentOrderDispose": "ReverseFulfillmentOrderDisposePayload",
    "savedSearchCreate": "SavedSearchCreatePayload",
    "savedSearchDelete": "SavedSearchDeletePayload",
    "savedSearchUpdate": "SavedSearchUpdatePayload",
    "scriptTagCreate": "ScriptTagCreatePayload",
    "scriptTagDelete": "ScriptTagDeletePayload",
    "scriptTagUpdate": "ScriptTagUpdatePayload",
    "segmentCreate": "SegmentCreatePayload",
    "segmentDelete": "SegmentDeletePayload",
    "segmentUpdate": "SegmentUpdatePayload",
    "sellingPlanGroupAddProductVariants": "SellingPlanGroupAddProductVariantsPayload",
    "sellingPlanGroupAddProducts": "SellingPlanGroupAddProductsPayload",
    "sellingPlanGroupCreate": "SellingPlanGroupCreatePayload",
    "sellingPlanGroupDelete": "SellingPlanGroupDeletePayload",
    "sellingPlanGroupRemoveProductVariants": "SellingPlanGroupRemoveProductVariantsPayload",
    "sellingPlanGroupRemoveProducts": "SellingPlanGroupRemoveProductsPayload",
    "sellingPlanGroupUpdate": "SellingPlanGroupUpdatePayload",
    "serverPixelCreate": "ServerPixelCreatePayload",
    "serverPixelDelete": "ServerPixelDeletePayload",
    "shippingPackageDelete": "ShippingPackageDeletePayload",
    "shippingPackageMakeDefault": "ShippingPackageMakeDefaultPayload",
    "shippingPackageUpdate": "ShippingPackageUpdatePayload",
    "shopLocaleDisable": "ShopLocaleDisablePayload",
    "shopLocaleEnable": "ShopLocaleEnablePayload",
    "shopLocaleUpdate": "ShopLocaleUpdatePayload",
    "shopPolicyUpdate": "ShopPolicyUpdatePayload",
    "shopResourceFeedbackCreate": "ShopResourceFeedbackCreatePayload",
    "stagedUploadTargetGenerate": "StagedUploadTargetGeneratePayload",
    "stagedUploadTargetsGenerate": "StagedUploadTargetsGeneratePayload",
    "stagedUploadsCreate": "StagedUploadsCreatePayload",
    "standardMetafieldDefinitionEnable": "StandardMetafieldDefinitionEnablePayload",
    "standardMetaobjectDefinitionEnable": "StandardMetaobjectDefinitionEnablePayload",
    "storefrontAccessTokenCreate": "StorefrontAccessTokenCreatePayload",
    "storefrontAccessTokenDelete": "StorefrontAccessTokenDeletePayload",
    "subscriptionBillingAttemptCreate": "SubscriptionBillingAttemptCreatePayload",
    "subscriptionBillingCycleContractDraftCommit": "SubscriptionBillingCycleContractDraftCommitPayload",
    "subscriptionBillingCycleContractDraftConcatenate": "SubscriptionBillingCycleContractDraftConcatenatePayload",
    "subscriptionBillingCycleContractEdit": "SubscriptionBillingCycleContractEditPayload",
    "subscriptionBillingCycleEditDelete": "SubscriptionBillingCycleEditDeletePayload",
    "subscriptionBillingCycleEditsDelete": "SubscriptionBillingCycleEditsDeletePayload",
    "subscriptionBillingCycleScheduleEdit": "SubscriptionBillingCycleScheduleEditPayload",
    "subscriptionBillingCycleSkip": "SubscriptionBillingCycleSkipPayload",
    "subscriptionBillingCycleUnskip": "SubscriptionBillingCycleUnskipPayload",
    "subscriptionContractActivate": "SubscriptionContractActivatePayload",
    "subscriptionContractAtomicCreate": "SubscriptionContractAtomicCreatePayload",
    "subscriptionContractCancel": "SubscriptionContractCancelPayload",
    "subscriptionContractCreate": "SubscriptionContractCreatePayload",
    "subscriptionContractExpire": "SubscriptionContractExpirePayload",
    "subscriptionContractFail": "SubscriptionContractFailPayload",
    "subscriptionContractPause": "SubscriptionContractPausePayload",
    "subscriptionContractProductChange": "SubscriptionContractProductChangePayload",
    "subscriptionContractSetNextBillingDate": "SubscriptionContractSetNextBillingDatePayload",
    "subscriptionContractUpdate": "SubscriptionContractUpdatePayload",
    "subscriptionDraftCommit": "SubscriptionDraftCommitPayload",
    "subscriptionDraftDiscountAdd": "SubscriptionDraftDiscountAddPayload",
    "subscriptionDraftDiscountCodeApply": "SubscriptionDraftDiscountCodeApplyPayload",
    "subscriptionDraftDiscountRemove": "SubscriptionDraftDiscountRemovePayload",
    "subscriptionDraftDiscountUpdate": "SubscriptionDraftDiscountUpdatePayload",
    "subscriptionDraftFreeShippingDiscountAdd": "SubscriptionDraftFreeShippingDiscountAddPayload",
    "subscriptionDraftFreeShippingDiscountUpdate": "SubscriptionDraftFreeShippingDiscountUpdatePayload",
    "subscriptionDraftLineAdd": "SubscriptionDraftLineAddPayload",
    "subscriptionDraftLineRemove": "SubscriptionDraftLineRemovePayload",
    "subscriptionDraftLineUpdate": "SubscriptionDraftLineUpdatePayload",
    "subscriptionDraftUpdate": "SubscriptionDraftUpdatePayload",
    "tagsAdd": "TagsAddPayload",
    "tagsRemove": "TagsRemovePayload",
    "taxAppConfigure": "TaxAppConfigurePayload",
    "transactionVoid": "TransactionVoidPayload",
    "translationsRegister": "TranslationsRegisterPayload",
    "translationsRemove": "TranslationsRemovePayload",
    "urlRedirectBulkDeleteAll": "UrlRedirectBulkDeleteAllPayload",
    "urlRedirectBulkDeleteByIds": "UrlRedirectBulkDeleteByIdsPayload",
    "urlRedirectBulkDeleteBySavedSearch": "UrlRedirectBulkDeleteBySavedSearchPayload",
    "urlRedirectBulkDeleteBySearch": "UrlRedirectBulkDeleteBySearchPayload",
    "urlRedirectCreate": "UrlRedirectCreatePayload",
    "urlRedirectDelete": "UrlRedirectDeletePayload",
    "urlRedirectImportCreate": "UrlRedirectImportCreatePayload",
    "urlRedirectImportSubmit": "UrlRedirectImportSubmitPayload",
    "urlRedirectUpdate": "UrlRedirectUpdatePayload",
    "validationCreate": "ValidationCreatePayload",
    "validationDelete": "ValidationDeletePayload",
    "validationUpdate": "ValidationUpdatePayload",
    "webPixelCreate": "WebPixelCreatePayload",
    "webPixelDelete": "WebPixelDeletePayload",
    "webPixelUpdate": "WebPixelUpdatePayload",
    "webhookSubscriptionCreate": "WebhookSubscriptionCreatePayload",
    "webhookSubscriptionDelete": "WebhookSubscriptionDeletePayload",
    "webhookSubscriptionUpdate": "WebhookSubscriptionUpdatePayload"
  },
  "implementsNode": false,
  "relayInputObjectBaseTypes": {
    "catalogCreate": "CatalogCreateInput",
    "catalogUpdate": "CatalogUpdateInput",
    "collectionCreate": "CollectionInput",
    "collectionDelete": "CollectionDeleteInput",
    "collectionPublish": "CollectionPublishInput",
    "collectionUnpublish": "CollectionUnpublishInput",
    "collectionUpdate": "CollectionInput",
    "companyContactCreate": "CompanyContactInput",
    "companyContactUpdate": "CompanyContactInput",
    "companyCreate": "CompanyCreateInput",
    "companyLocationCreate": "CompanyLocationInput",
    "companyLocationUpdate": "CompanyLocationUpdateInput",
    "companyUpdate": "CompanyInput",
    "customerAccessTokenCreate": "CustomerAccessTokenCreateInput",
    "customerCreate": "CustomerInput",
    "customerDelete": "CustomerDeleteInput",
    "customerEmailMarketingConsentUpdate": "CustomerEmailMarketingConsentUpdateInput",
    "customerSegmentMembersQueryCreate": "CustomerSegmentMembersQueryInput",
    "customerSmsMarketingConsentUpdate": "CustomerSmsMarketingConsentUpdateInput",
    "customerUpdate": "CustomerInput",
    "delegateAccessTokenCreate": "DelegateAccessTokenInput",
    "disputeEvidenceUpdate": "ShopifyPaymentsDisputeEvidenceUpdateInput",
    "draftOrderCalculate": "DraftOrderInput",
    "draftOrderCreate": "DraftOrderInput",
    "draftOrderDelete": "DraftOrderDeleteInput",
    "draftOrderUpdate": "DraftOrderInput",
    "fulfillmentOrderLineItemsPreparedForPickup": "FulfillmentOrderLineItemsPreparedForPickupInput",
    "giftCardCreate": "GiftCardCreateInput",
    "giftCardUpdate": "GiftCardUpdateInput",
    "inventoryAdjustQuantities": "InventoryAdjustQuantitiesInput",
    "inventoryItemUpdate": "InventoryItemUpdateInput",
    "inventoryMoveQuantities": "InventoryMoveQuantitiesInput",
    "inventorySetOnHandQuantities": "InventorySetOnHandQuantitiesInput",
    "inventorySetScheduledChanges": "InventorySetScheduledChangesInput",
    "locationAdd": "LocationAddInput",
    "locationEdit": "LocationEditInput",
    "marketCreate": "MarketCreateInput",
    "marketCurrencySettingsUpdate": "MarketCurrencySettingsUpdateInput",
    "marketUpdate": "MarketUpdateInput",
    "marketingActivityCreate": "MarketingActivityCreateInput",
    "marketingActivityCreateExternal": "MarketingActivityCreateExternalInput",
    "marketingActivityUpdate": "MarketingActivityUpdateInput",
    "marketingActivityUpdateExternal": "MarketingActivityUpdateExternalInput",
    "marketingActivityUpsertExternal": "MarketingActivityUpsertExternalInput",
    "metafieldDelete": "MetafieldDeleteInput",
    "metafieldStorefrontVisibilityCreate": "MetafieldStorefrontVisibilityInput",
    "orderCapture": "OrderCaptureInput",
    "orderClose": "OrderCloseInput",
    "orderMarkAsPaid": "OrderMarkAsPaidInput",
    "orderOpen": "OrderOpenInput",
    "orderUpdate": "OrderInput",
    "paymentTermsDelete": "PaymentTermsDeleteInput",
    "paymentTermsUpdate": "PaymentTermsUpdateInput",
    "priceListCreate": "PriceListCreateInput",
    "priceListUpdate": "PriceListUpdateInput",
    "privateMetafieldDelete": "PrivateMetafieldDeleteInput",
    "privateMetafieldUpsert": "PrivateMetafieldInput",
    "productAppendImages": "ProductAppendImagesInput",
    "productCreate": "ProductInput",
    "productDelete": "ProductDeleteInput",
    "productDuplicateAsyncV2": "ProductDuplicateAsyncInput",
    "productFeedCreate": "ProductFeedInput",
    "productPublish": "ProductPublishInput",
    "productSet": "ProductSetInput",
    "productUnpublish": "ProductUnpublishInput",
    "productUpdate": "ProductInput",
    "productVariantCreate": "ProductVariantInput",
    "productVariantRelationshipBulkUpdate": "ProductVariantRelationshipUpdateInput",
    "productVariantUpdate": "ProductVariantInput",
    "publicationCreate": "PublicationCreateInput",
    "publicationUpdate": "PublicationUpdateInput",
    "publishablePublish": "PublicationInput",
    "publishableUnpublish": "PublicationInput",
    "quantityPricingByVariantUpdate": "QuantityPricingByVariantUpdateInput",
    "refundCreate": "RefundInput",
    "returnApproveRequest": "ReturnApproveRequestInput",
    "returnDeclineRequest": "ReturnDeclineRequestInput",
    "returnRequest": "ReturnRequestInput",
    "savedSearchCreate": "SavedSearchCreateInput",
    "savedSearchDelete": "SavedSearchDeleteInput",
    "savedSearchUpdate": "SavedSearchUpdateInput",
    "scriptTagCreate": "ScriptTagInput",
    "scriptTagUpdate": "ScriptTagInput",
    "sellingPlanGroupCreate": "SellingPlanGroupInput",
    "sellingPlanGroupUpdate": "SellingPlanGroupInput",
    "shopResourceFeedbackCreate": "ResourceFeedbackCreateInput",
    "stagedUploadTargetGenerate": "StagedUploadTargetGenerateInput",
    "stagedUploadTargetsGenerate": "StageImageInput",
    "stagedUploadsCreate": "StagedUploadInput",
    "storefrontAccessTokenCreate": "StorefrontAccessTokenInput",
    "storefrontAccessTokenDelete": "StorefrontAccessTokenDeleteInput",
    "subscriptionBillingCycleScheduleEdit": "SubscriptionBillingCycleScheduleEditInput",
    "subscriptionContractAtomicCreate": "SubscriptionContractAtomicCreateInput",
    "subscriptionContractCreate": "SubscriptionContractCreateInput",
    "subscriptionContractProductChange": "SubscriptionContractProductChangeInput",
    "subscriptionDraftDiscountAdd": "SubscriptionManualDiscountInput",
    "subscriptionDraftDiscountUpdate": "SubscriptionManualDiscountInput",
    "subscriptionDraftFreeShippingDiscountAdd": "SubscriptionFreeShippingDiscountInput",
    "subscriptionDraftFreeShippingDiscountUpdate": "SubscriptionFreeShippingDiscountInput",
    "subscriptionDraftLineAdd": "SubscriptionLineInput",
    "subscriptionDraftLineUpdate": "SubscriptionLineUpdateInput",
    "subscriptionDraftUpdate": "SubscriptionDraftInput"
  }
};
export default Mutation;