
const ComponentizableCartLine = {
  "name": "ComponentizableCartLine",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "attribute": "Attribute",
    "attributes": "Attribute",
    "cost": "CartLineCost",
    "discountAllocations": "CartDiscountAllocation",
    "estimatedCost": "CartLineEstimatedCost",
    "id": "ID",
    "lineComponents": "CartLine",
    "merchandise": "Merchandise",
    "quantity": "Int",
    "sellingPlanAllocation": "SellingPlanAllocation"
  },
  "implementsNode": true
};
export default ComponentizableCartLine;