
const ShopifyPaymentsDisputeReasonDetails = {
  "name": "ShopifyPaymentsDisputeReasonDetails",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "networkReasonCode": "String",
    "reason": "ShopifyPaymentsDisputeReason"
  },
  "implementsNode": false
};
export default ShopifyPaymentsDisputeReasonDetails;