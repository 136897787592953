
const DiscountAutomaticApp = {
  "name": "DiscountAutomaticApp",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "appDiscountType": "AppDiscountType",
    "asyncUsageCount": "Int",
    "combinesWith": "DiscountCombinesWith",
    "createdAt": "DateTime",
    "discountClass": "DiscountClass",
    "discountId": "ID",
    "endsAt": "DateTime",
    "errorHistory": "FunctionsErrorHistory",
    "startsAt": "DateTime",
    "status": "DiscountStatus",
    "title": "String",
    "updatedAt": "DateTime"
  },
  "implementsNode": false
};
export default DiscountAutomaticApp;