
const CheckoutBrandingControlColorRolesInput = {
  "name": "CheckoutBrandingControlColorRolesInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "background": "String",
    "text": "String",
    "border": "String",
    "icon": "String",
    "accent": "String",
    "decorative": "String",
    "selected": "CheckoutBrandingColorRolesInput"
  }
};
export default CheckoutBrandingControlColorRolesInput;