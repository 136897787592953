
const MetaobjectBulkDeletePayload = {
  "name": "MetaobjectBulkDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "job": "Job",
    "userErrors": "MetaobjectUserError"
  },
  "implementsNode": false
};
export default MetaobjectBulkDeletePayload;