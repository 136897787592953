
const DeliveryLocalPickupSettings = {
  "name": "DeliveryLocalPickupSettings",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "instructions": "String",
    "pickupTime": "DeliveryLocalPickupTime"
  },
  "implementsNode": false
};
export default DeliveryLocalPickupSettings;