import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import GraphQLClient from 'graphql-js-client';
import './index.scss';
import App from './components/app';
import { ScrollContext } from 'react-router-scroll-4';

import typeBundle from './types/types';
import storefrontTypeBundle from './types-storefront/types';

// Components
import ProductList from './components/products/product-list';
import ProductAdd from './components/products/product-add';
import MenuList from './components/navigation/menu-list';
import MenuEdit from './components/navigation/menu-edit';
import NavigationFooter from './components/navigation/navigation-footer';
import CustomizeTypography from './components/customize/customize-typography';
import CustomizeHomePage from './components/customize/customize-home-page';
import CollectionList from './components/collections/collection-list';
import CollectionEdit from './components/collections/collection-edit';
import PageList from './components/pages/page-list';
import PageEdit from './components/pages/page-edit';
import OrderList from './components/orders/order-list';
import OrderDetail from './components/orders/order-detail';
import CustomersList from './components/customers/customer-list';
import SettingPreferences from './components/settings/setting-preferences';
import SettingHelpFAQ from './components/settings/setting-help-faq';
import CustomizeGoogleAnalytics from './components/settings/setting-google-analytics';
import CustomerDetail from './components/customers/customer-detail';
import Login from './components/auth/login';
import PasswordReset from './components/auth/resetPassword';

import { AuthenticationProvider, AuthenticatedRoute } from "./auth/auth";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SHOPIFY_GRAPHQL_STOREFRONT_API_URL = process.env.REACT_APP_SHOPIFY_GRAPHQL_STOREFRONT_API_URL;
const SHOPIFY_STOREFRONT_ACCESS_TOKEN = process.env.REACT_APP_SHOPIFY_STOREFRONT_ACCESS_TOKEN;

const SHOPIFY_GRAPHQL_ADMIN_API_URL = process.env.REACT_APP_SHOPIFY_GRAPHQL_ADMIN_BASE_URL;
const SHOPIFY_ADMIN_ACCESS_TOKEN = process.env.REACT_APP_SHOPIFY_ADMIN_ACCESS_TOKEN;

export const storefrontClient = new GraphQLClient(storefrontTypeBundle, {
    url: SHOPIFY_GRAPHQL_STOREFRONT_API_URL,
    fetcherOptions: {
        headers: {
            'X-Shopify-Storefront-Access-Token': SHOPIFY_STOREFRONT_ACCESS_TOKEN
        }
    }
});


export const adminClient = new GraphQLClient(typeBundle, {
    url: SHOPIFY_GRAPHQL_ADMIN_API_URL,
    fetcherOptions: {
        headers: {
            'X-Shopify-Admin-Access-Token': SHOPIFY_ADMIN_ACCESS_TOKEN,
            'X-Shopify-Access-Token': SHOPIFY_ADMIN_ACCESS_TOKEN,
            'Access-Control-Allow-Origin': '*'
        }
    }
});




class Root extends Component {
    render() {
        return (
            <React.Fragment>
                <BrowserRouter basename={'/'}>
                    <AuthenticationProvider>
                        <ScrollContext>
                            <Switch>
                                <Route exact path={`${process.env.PUBLIC_URL}/`} component={Login} />
                                <Route exact path={`${process.env.PUBLIC_URL}/auth/login`} component={Login} />
                                <AuthenticatedRoute path={`${process.env.PUBLIC_URL}/reset-password`} component={PasswordReset} />
                                <App>

                                    <AuthenticatedRoute exact path={`${process.env.PUBLIC_URL}/categories/edit/:handle`} component={CollectionEdit} />
                                    <AuthenticatedRoute exact path={`${process.env.PUBLIC_URL}/categories/new`} component={CollectionEdit} />
                                    <AuthenticatedRoute exact path={`${process.env.PUBLIC_URL}/categories/`} component={CollectionList} />

                                    <AuthenticatedRoute exact path={`${process.env.PUBLIC_URL}/pages/new`} component={PageEdit} />
                                    <AuthenticatedRoute exact path={`${process.env.PUBLIC_URL}/pages/edit/:handle`} component={PageEdit} />
                                    <AuthenticatedRoute exact path={`${process.env.PUBLIC_URL}/pages/`} component={PageList} />

                                    <AuthenticatedRoute exact path={`${process.env.PUBLIC_URL}/products`} component={ProductList} />
                                    <AuthenticatedRoute exact path={`${process.env.PUBLIC_URL}/products/:handle`} component={ProductAdd} />

                                    <AuthenticatedRoute exact path={`${process.env.PUBLIC_URL}/navigation/menus`} component={MenuList} />
                                    <AuthenticatedRoute exact path={`${process.env.PUBLIC_URL}/navigation/menus/edit/:handle`} component={MenuEdit} />
                                    <AuthenticatedRoute exact path={`${process.env.PUBLIC_URL}/navigation/footer`} component={NavigationFooter} />

                                    <AuthenticatedRoute exact path={`${process.env.PUBLIC_URL}/customize/typography`} component={CustomizeTypography} />
                                    <AuthenticatedRoute exact path={`${process.env.PUBLIC_URL}/customize/homepage`} component={CustomizeHomePage} />

                                    <AuthenticatedRoute exact path={`${process.env.PUBLIC_URL}/customers`} component={CustomersList} />
                                    <AuthenticatedRoute exact path={`${process.env.PUBLIC_URL}/customers/:id`} component={CustomerDetail} />

                                    <AuthenticatedRoute exact path={`${process.env.PUBLIC_URL}/orders/`} component={OrderList} />
                                    <AuthenticatedRoute exact path={`${process.env.PUBLIC_URL}/orders/:id`} component={OrderDetail} />

                                    <AuthenticatedRoute exact path={`${process.env.PUBLIC_URL}/settings/preferences`} component={SettingPreferences} />
                                    <AuthenticatedRoute exact path={`${process.env.PUBLIC_URL}/settings/help-faq`} component={SettingHelpFAQ} />
                                    <AuthenticatedRoute exact path={`${process.env.PUBLIC_URL}/settings/google-analytics`} component={CustomizeGoogleAnalytics} />

                                </App>
                            </Switch>
                        </ScrollContext>
                    </AuthenticationProvider>
                </BrowserRouter>
                <ToastContainer position="top-right" autoClose={false}/>
            </React.Fragment>
        )
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));
