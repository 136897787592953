
const ProductInput = {
  "name": "ProductInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "descriptionHtml": "String",
    "handle": "String",
    "redirectNewHandle": "Boolean",
    "seo": "SEOInput",
    "productType": "String",
    "standardizedProductType": "StandardizedProductTypeInput",
    "productCategory": "ProductCategoryInput",
    "customProductType": "String",
    "tags": "String",
    "templateSuffix": "String",
    "giftCard": "Boolean",
    "giftCardTemplateSuffix": "String",
    "title": "String",
    "vendor": "String",
    "collectionsToJoin": "ID",
    "collectionsToLeave": "ID",
    "id": "ID",
    "metafields": "MetafieldInput",
    "productOptions": "OptionCreateInput",
    "status": "ProductStatus",
    "requiresSellingPlan": "Boolean",
    "claimOwnership": "ProductClaimOwnershipInput"
  }
};
export default ProductInput;