import axios from 'axios';

export const addNetlifySnippet = async (siteId, snippetTitle, snippetContent) => {
    const existingSnippet = await getNetlifySnippetByTitle(siteId, snippetTitle)
    if (existingSnippet && existingSnippet.id) {
        return updateNetlifySnippet(siteId, snippetTitle, snippetContent, existingSnippet.id);
    } else {
        const body = {
            siteId: siteId,
            snippetTitle: snippetTitle,
            snippetContent: snippetContent
        };
        const request = axios.post('/.netlify/functions/server/api/add-netlify-snippet', body)
            .then((response) => {
                return response;
            })
            .catch(err => err);

        return request;
    }
};

export const getNetlifySnippetByTitle = (siteId, snippetTitle) => {
    const body = { siteId: siteId };
    const request = axios.post('/.netlify/functions/server/api/get-netlify-snippets', body)
        .then((response) => {
            // Look for snippet matching snippetTitle
            for (const snippet in response.data) {
                if (response.data[snippet].title === snippetTitle) {
                    // Return the snippet matching snippetTitle
                    return response.data[snippet];
                }
            }
            // If no snippet found, return this message
            return "No Netlify snippet found for snippet title: " + snippetTitle;
        })
        .catch(err => err);

    return request;
};

export const getNetlifySiteSettings = (siteId) => {
    const body = { siteId: siteId };
    const request = axios.post('/.netlify/functions/server/api/get-netlify-site-settings', body)
        .then((response) => {
            return response;
        })
        .catch(err => err);

    return request;
};

export const updateNetlifySiteSettings = (siteId, newSettings) => {
    const body = {
        siteId: siteId,
        newSettings: newSettings
    };
    const request = axios.post('/.netlify/functions/server/api/update-netlify-site-settings', body)
        .then((response) => {
            return response;
        })
        .catch(err => {
            console.log(err);
        });

    return request;
};

export const updateNetlifySnippet = (siteId, snippetTitle, snippetContent, snippetId) => {
    const body = {
        siteId: siteId,
        snippetTitle: snippetTitle,
        snippetContent: snippetContent,
        snippetId: snippetId
    }
    const request = axios.post('/.netlify/functions/server/api/update-netlify-snippet', body)
        .then((response) => {
            return response;
        })
        .catch(err => err);

    return request;
};

export const deleteNetlifySnippet = (siteId, snippetId) => {
    const body = {
        siteId: siteId,
        snippetId: snippetId
    }
    const request = axios.post('/.netlify/functions/server/api/delete-netlify-snippet', body)
        .then((response) => {
            return response;
        })
        .catch(err => err);

    return request;
};

export const getNetlifyHooks = (site_id) => {
    const request = axios.post('/.netlify/functions/server/api/get-netlify-hooks', { site_id })
        .then((response) => {
            return response.data;
        })
        .catch(err => {
            return err
        });

    return request;
};

export const addNetlifyHook = (data) => {
    const request = axios.post('/.netlify/functions/server/api/add-netlify-hook', { data })
        .then((response) => {
            return response;
        })
        .catch(err => {
            return err
        });

    return request;
};

export const getNetlifyForm = (formName, site_id) => {
    const request = axios.post("/.netlify/functions/server/api/get-netlify-form", { formName, site_id })
        .then((response) => {
            return response.data;
        })
        .catch(err => {
            return err;
        });

    return request;
}


export const createFormHook = async (site_id, email) => {
    const newHook = {
        site_id,
        type: "email",
        event: "submission_created",
        data: {
            email
        }
    };
    try {
        const hooks = await getNetlifyHooks(site_id);
        if (hooks && !hooks.find(h => h.actor === "form_submission" && h.event === "submission_created" && h.data.email.toLowerCase() === email.toLowerCase())) {
            addNetlifyHook(newHook);
        } else {
            console.log("Form submission hook already exists.");
        };
    } catch (err) {
        console.error(`Couldn't retrieve hooks. ${err}`);
    }
};

export const deleteFormNetlifyHook = async (site_id, email) => {

    const hooks = await getNetlifyHooks(site_id);
    const hookExists = hooks.find(h => h.actor === "form_submission" && h.event === "submission_created" && h.data.email.toLowerCase() === email.toLowerCase());

    if (hookExists) {
        const data = {
            hookId: hookExists.id
        }
        const request = axios.post('/.netlify/functions/server/api/delete-netlify-hook', { data })
            .then((response) => {
                return response;
            })
            .catch(err => {
                return err
            });

        return request;
    }

}
