
const PriceRuleQuantityRange = {
  "name": "PriceRuleQuantityRange",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "greaterThan": "Int",
    "greaterThanOrEqualTo": "Int",
    "lessThan": "Int",
    "lessThanOrEqualTo": "Int"
  },
  "implementsNode": false
};
export default PriceRuleQuantityRange;