
const ShopifyPaymentsAdjustmentOrder = {
  "name": "ShopifyPaymentsAdjustmentOrder",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "amount": "MoneyV2",
    "link": "URL",
    "name": "String"
  },
  "implementsNode": false
};
export default ShopifyPaymentsAdjustmentOrder;