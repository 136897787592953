
const ProductVariantComponentConnection = {
  "name": "ProductVariantComponentConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "ProductVariantComponentEdge",
    "nodes": "ProductVariantComponent",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default ProductVariantComponentConnection;