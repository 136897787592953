
const FulfillmentOrderSubmitFulfillmentRequestPayload = {
  "name": "FulfillmentOrderSubmitFulfillmentRequestPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "originalFulfillmentOrder": "FulfillmentOrder",
    "submittedFulfillmentOrder": "FulfillmentOrder",
    "unsubmittedFulfillmentOrder": "FulfillmentOrder",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default FulfillmentOrderSubmitFulfillmentRequestPayload;