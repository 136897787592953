
const PriceListFixedPricesByProductUpdatePayload = {
  "name": "PriceListFixedPricesByProductUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "priceList": "PriceList",
    "pricesToAddProducts": "Product",
    "pricesToDeleteProducts": "Product",
    "userErrors": "PriceListFixedPricesByProductBulkUpdateUserError"
  },
  "implementsNode": false
};
export default PriceListFixedPricesByProductUpdatePayload;