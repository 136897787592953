
const MarketingEngagementInput = {
  "name": "MarketingEngagementInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "occurredOn": "Date",
    "impressionsCount": "Int",
    "viewsCount": "Int",
    "clicksCount": "Int",
    "sharesCount": "Int",
    "favoritesCount": "Int",
    "commentsCount": "Int",
    "unsubscribesCount": "Int",
    "complaintsCount": "Int",
    "failsCount": "Int",
    "sendsCount": "Int",
    "uniqueViewsCount": "Int",
    "uniqueClicksCount": "Int",
    "adSpend": "MoneyInput",
    "isCumulative": "Boolean",
    "utcOffset": "UtcOffset",
    "sales": "MoneyInput",
    "sessionsCount": "Int",
    "orders": "Decimal",
    "firstTimeCustomers": "Decimal",
    "returningCustomers": "Decimal"
  }
};
export default MarketingEngagementInput;