
const MarketRegionCountry = {
  "name": "MarketRegionCountry",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "CountryCode",
    "currency": "CurrencySetting",
    "id": "ID",
    "name": "String"
  },
  "implementsNode": true
};
export default MarketRegionCountry;