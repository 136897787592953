
const SegmentFilterConnection = {
  "name": "SegmentFilterConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "SegmentFilterEdge",
    "nodes": "SegmentFilter",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default SegmentFilterConnection;