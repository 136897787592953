
const MetafieldAccessGrant = {
  "name": "MetafieldAccessGrant",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "access": "MetafieldGrantAccessLevel",
    "grantee": "String"
  },
  "implementsNode": false
};
export default MetafieldAccessGrant;