
const CartDeliveryGroup = {
  "name": "CartDeliveryGroup",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cartLines": "BaseCartLineConnection",
    "deliveryAddress": "MailingAddress",
    "deliveryOptions": "CartDeliveryOption",
    "groupType": "CartDeliveryGroupType",
    "id": "ID",
    "selectedDeliveryOption": "CartDeliveryOption"
  },
  "implementsNode": false
};
export default CartDeliveryGroup;