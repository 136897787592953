
const MediaImage = {
  "name": "MediaImage",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "alt": "String",
    "createdAt": "DateTime",
    "fileErrors": "FileError",
    "fileStatus": "FileStatus",
    "id": "ID",
    "image": "Image",
    "mediaContentType": "MediaContentType",
    "mediaErrors": "MediaError",
    "mediaWarnings": "MediaWarning",
    "metafield": "Metafield",
    "metafields": "MetafieldConnection",
    "mimeType": "String",
    "originalSource": "MediaImageOriginalSource",
    "preview": "MediaPreviewImage",
    "privateMetafield": "PrivateMetafield",
    "privateMetafields": "PrivateMetafieldConnection",
    "status": "MediaStatus",
    "updatedAt": "DateTime"
  },
  "implementsNode": true
};
export default MediaImage;