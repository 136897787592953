
const FulfillmentEvent = {
  "name": "FulfillmentEvent",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "address1": "String",
    "city": "String",
    "country": "String",
    "estimatedDeliveryAt": "DateTime",
    "happenedAt": "DateTime",
    "id": "ID",
    "latitude": "Float",
    "longitude": "Float",
    "message": "String",
    "province": "String",
    "status": "FulfillmentEventStatus",
    "zip": "String"
  },
  "implementsNode": true
};
export default FulfillmentEvent;