
const LocationLocalPickupDisablePayload = {
  "name": "LocationLocalPickupDisablePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "locationId": "ID",
    "userErrors": "DeliveryLocationLocalPickupSettingsError"
  },
  "implementsNode": false
};
export default LocationLocalPickupDisablePayload;