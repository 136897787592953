
const DiscountAutomaticConnection = {
  "name": "DiscountAutomaticConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "DiscountAutomaticEdge",
    "nodes": "DiscountAutomatic",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default DiscountAutomaticConnection;