
const OrderEditRemoveDiscountUserError = {
  "name": "OrderEditRemoveDiscountUserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "OrderEditRemoveDiscountUserErrorCode",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
export default OrderEditRemoveDiscountUserError;