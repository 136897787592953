import { DEFAULT_SNIPPETS, DEFAULT_PAGES_NAMES } from "../../components/utils/constant";
import { addNetlifySnippet } from "../../querys/netlify-rest";
import { updateDefaultMenuWithSubdomain } from "../navigation/utils";

export const decodeSnippet = (snippet, name) => {
    const content = snippet.replace(`<script>window.${name}=`, '').replace('<\/script>', '')
    return JSON.parse(content)
}

export const encodeSnippet = (content, name) => {
    let snippet = `\u003Cscript\u003Ewindow.${name}=${JSON.stringify(content)}\u003C/script\u003E`
    return snippet
}

export const setupDefaultHomepageLayoutSnippets = async (reseller_site_id) => {

    for (let snippet of DEFAULT_SNIPPETS) {
        await addNetlifySnippet(reseller_site_id, snippet.title, snippet.content);
    };
}

export const setupFooter2 = async (reseller_site_id, store_subdomain) => {

    activateDefaultPages(store_subdomain);

    createFooter2Snippet(reseller_site_id, store_subdomain);

}

const activateDefaultPages = (store_subdomain) => {

    DEFAULT_PAGES_NAMES.map(handle => {

        activatePage(handle, store_subdomain);
    });

}

const activatePage = async (handle, store_subdomain) => {

    let response = await fetch('/.netlify/functions/server/api/fetch-page-by-handle', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ handle })
    });

    let page = await response.json();
    delete page.id;
    delete page.admin_graphql_api_id;
    delete page.shop_id;

    if (page) {
        page.handle = `${store_subdomain}-${page.handle}`
        fetch('/.netlify/functions/server/api/create-page', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ page })
        });
    }

}

const createFooter2Snippet = (reseller_site_id, store_subdomain) => {

    let defaultMenus = updateDefaultMenuWithSubdomain(store_subdomain);

    const snippetFriendlyScript = encodeSnippet(defaultMenus, "Menu");

    addNetlifySnippet(reseller_site_id, "Menu", snippetFriendlyScript);
}

export const createSnippet = (reseller_site_id, varName, varValue, snippetName) => {

    const snippetScript = encodeSnippet(varValue, varName);

    addNetlifySnippet(reseller_site_id, snippetName, snippetScript);
}