
const PriceRuleShareableUrl = {
  "name": "PriceRuleShareableUrl",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "targetItemImage": "Image",
    "targetType": "PriceRuleShareableUrlTargetType",
    "title": "String",
    "url": "URL"
  },
  "implementsNode": false
};
export default PriceRuleShareableUrl;