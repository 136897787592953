
const DeliveryCarrierService = {
  "name": "DeliveryCarrierService",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "availableServicesForCountries": "DeliveryAvailableService",
    "formattedName": "String",
    "icon": "Image",
    "id": "ID",
    "name": "String"
  },
  "implementsNode": true
};
export default DeliveryCarrierService;