
const TaxonomyCategory = {
  "name": "TaxonomyCategory",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "ancestorIds": "ID",
    "attributes": "TaxonomyCategoryAttributeConnection",
    "childrenIds": "ID",
    "fullName": "String",
    "id": "ID",
    "isArchived": "Boolean",
    "isLeaf": "Boolean",
    "isRoot": "Boolean",
    "level": "Int",
    "name": "String",
    "parentId": "ID"
  },
  "implementsNode": true
};
export default TaxonomyCategory;