
const AppSubscriptionDiscount = {
  "name": "AppSubscriptionDiscount",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "durationLimitInIntervals": "Int",
    "priceAfterDiscount": "MoneyV2",
    "remainingDurationInIntervals": "Int",
    "value": "AppSubscriptionDiscountValue"
  },
  "implementsNode": false
};
export default AppSubscriptionDiscount;