
const MarketCurrencySettingsUpdatePayload = {
  "name": "MarketCurrencySettingsUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "market": "Market",
    "userErrors": "MarketCurrencySettingsUserError"
  },
  "implementsNode": false
};
export default MarketCurrencySettingsUpdatePayload;