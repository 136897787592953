
const deliveryProfileCreatePayload = {
  "name": "deliveryProfileCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "profile": "DeliveryProfile",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default deliveryProfileCreatePayload;