
const LocationLocalPickupEnablePayload = {
  "name": "LocationLocalPickupEnablePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "localPickupSettings": "DeliveryLocalPickupSettings",
    "userErrors": "DeliveryLocationLocalPickupSettingsError"
  },
  "implementsNode": false
};
export default LocationLocalPickupEnablePayload;