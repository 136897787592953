
const ProductCompareAtPriceRange = {
  "name": "ProductCompareAtPriceRange",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "maxVariantCompareAtPrice": "MoneyV2",
    "minVariantCompareAtPrice": "MoneyV2"
  },
  "implementsNode": false
};
export default ProductCompareAtPriceRange;