
const CartAttributesUpdatePayload = {
  "name": "CartAttributesUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cart": "Cart",
    "userErrors": "CartUserError"
  },
  "implementsNode": false
};
export default CartAttributesUpdatePayload;