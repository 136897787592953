
const SubscriptionDraftFreeShippingDiscountAddPayload = {
  "name": "SubscriptionDraftFreeShippingDiscountAddPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "discountAdded": "SubscriptionManualDiscount",
    "draft": "SubscriptionDraft",
    "userErrors": "SubscriptionDraftUserError"
  },
  "implementsNode": false
};
export default SubscriptionDraftFreeShippingDiscountAddPayload;