
const CartTransform = {
  "name": "CartTransform",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "blockOnFailure": "Boolean",
    "functionId": "String",
    "id": "ID",
    "metafield": "Metafield",
    "metafields": "MetafieldConnection",
    "privateMetafield": "PrivateMetafield",
    "privateMetafields": "PrivateMetafieldConnection"
  },
  "implementsNode": true
};
export default CartTransform;