
const CalculatedDraftOrder = {
  "name": "CalculatedDraftOrder",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "appliedDiscount": "DraftOrderAppliedDiscount",
    "availableShippingRates": "ShippingRate",
    "billingAddressMatchesShippingAddress": "Boolean",
    "currencyCode": "CurrencyCode",
    "customer": "Customer",
    "lineItems": "CalculatedDraftOrderLineItem",
    "lineItemsSubtotalPrice": "MoneyBag",
    "marketName": "String",
    "marketRegionCountryCode": "CountryCode",
    "phone": "String",
    "presentmentCurrencyCode": "CurrencyCode",
    "purchasingEntity": "PurchasingEntity",
    "shippingLine": "ShippingLine",
    "subtotalPrice": "Money",
    "subtotalPriceSet": "MoneyBag",
    "taxLines": "TaxLine",
    "totalDiscountsSet": "MoneyBag",
    "totalLineItemsPriceSet": "MoneyBag",
    "totalPrice": "Money",
    "totalPriceSet": "MoneyBag",
    "totalShippingPrice": "Money",
    "totalShippingPriceSet": "MoneyBag",
    "totalTax": "Money",
    "totalTaxSet": "MoneyBag"
  },
  "implementsNode": false
};
export default CalculatedDraftOrder;