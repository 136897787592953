
const CartLineCost = {
  "name": "CartLineCost",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "amountPerQuantity": "MoneyV2",
    "compareAtAmountPerQuantity": "MoneyV2",
    "subtotalAmount": "MoneyV2",
    "totalAmount": "MoneyV2"
  },
  "implementsNode": false
};
export default CartLineCost;