import React, { Component } from "react";
import { ReactComponent as AddIcon } from "../../../assets/elegant_font/images/SVG/icon_plus_alt2.svg";
import UserImg from "../../../assets/elegant_font/images/user.png";
import { Trash2 } from "react-feather";

class PersonCard extends Component {
  render() {
    const { isNew, id, firstName, lastName, email, handleOpenModal, onDelete } = this.props;

    return (
      <div className="person-card">
        {isNew ? (
          <div className="d-flex justify-content-between blank-card">
            <h6>Add Admin</h6>
            <a
              href="javascript:void(0)"
              onClick={() =>
                handleOpenModal({
                  isNew,
                  firstName: "",
                  lastName: "",
                  email: "",
                })
              }
            >
              <AddIcon />
            </a>
          </div>
        ) : (
            <>
              <div className="d-flex justify-content-between info-card">
                <div className="w-100" style={{ paddingRight: '10px' }}>
                  <h6>
                    {firstName} {lastName}
                  </h6>
                  <p className="text-secondary">{email}</p>
                </div>
                <img src={UserImg} style={{ marginLeft: '-10px' }} alt="User" />
              </div>

              <Trash2 size="20" onClick={() => onDelete(id)} style={{ marginLeft: "20px", marginBottom: "5px" }} />
            </>
          )}
      </div>
    );
  }
}

export default PersonCard;
