
const SearchResultItemConnection = {
  "name": "SearchResultItemConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "SearchResultItemEdge",
    "nodes": "SearchResultItem",
    "pageInfo": "PageInfo",
    "productFilters": "Filter",
    "totalCount": "Int"
  },
  "implementsNode": false
};
export default SearchResultItemConnection;