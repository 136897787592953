
const SegmentMembership = {
  "name": "SegmentMembership",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "isMember": "Boolean",
    "segmentId": "ID"
  },
  "implementsNode": false
};
export default SegmentMembership;