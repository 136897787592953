
const ScriptDiscountApplication = {
  "name": "ScriptDiscountApplication",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "allocationMethod": "DiscountApplicationAllocationMethod",
    "description": "String",
    "index": "Int",
    "targetSelection": "DiscountApplicationTargetSelection",
    "targetType": "DiscountApplicationTargetType",
    "title": "String",
    "value": "PricingValue"
  },
  "implementsNode": false
};
export default ScriptDiscountApplication;