
const InventoryItemInput = {
  "name": "InventoryItemInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "cost": "Decimal",
    "tracked": "Boolean",
    "harmonizedSystemCode": "String",
    "measurement": "InventoryItemMeasurementInput",
    "requiresShipping": "Boolean"
  }
};
export default InventoryItemInput;