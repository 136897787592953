
const Catalog = {
  "name": "Catalog",
  "kind": "INTERFACE",
  "fieldBaseTypes": {
    "id": "ID",
    "operations": "ResourceOperation",
    "priceList": "PriceList",
    "publication": "Publication",
    "status": "CatalogStatus",
    "title": "String"
  },
  "possibleTypes": ["AppCatalog", "CompanyLocationCatalog", "MarketCatalog"]
};
export default Catalog;