
const CheckoutBrandingOrderSummaryInput = {
  "name": "CheckoutBrandingOrderSummaryInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "colorScheme": "CheckoutBrandingColorSchemeSelection",
    "backgroundImage": "CheckoutBrandingImageInput",
    "section": "CheckoutBrandingOrderSummarySectionInput"
  }
};
export default CheckoutBrandingOrderSummaryInput;