
const ShopAlert = {
  "name": "ShopAlert",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "action": "ShopAlertAction",
    "description": "String"
  },
  "implementsNode": false
};
export default ShopAlert;