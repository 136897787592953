
const SubscriptionBillingCycleEditDeletePayload = {
  "name": "SubscriptionBillingCycleEditDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "billingCycles": "SubscriptionBillingCycle",
    "userErrors": "SubscriptionBillingCycleUserError"
  },
  "implementsNode": false
};
export default SubscriptionBillingCycleEditDeletePayload;