
const CashTrackingAdjustment = {
  "name": "CashTrackingAdjustment",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cash": "MoneyV2",
    "id": "ID",
    "note": "String",
    "staffMember": "StaffMember",
    "time": "DateTime"
  },
  "implementsNode": true
};
export default CashTrackingAdjustment;