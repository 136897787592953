
const PriceRuleInput = {
  "name": "PriceRuleInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "combinesWith": "DiscountCombinesWithInput",
    "validityPeriod": "PriceRuleValidityPeriodInput",
    "oncePerCustomer": "Boolean",
    "customerSelection": "PriceRuleCustomerSelectionInput",
    "usageLimit": "Int",
    "title": "String",
    "allocationLimit": "Int",
    "allocationMethod": "PriceRuleAllocationMethod",
    "value": "PriceRuleValueInput",
    "target": "PriceRuleTarget",
    "prerequisiteSubtotalRange": "PriceRuleMoneyRangeInput",
    "prerequisiteQuantityRange": "PriceRuleQuantityRangeInput",
    "prerequisiteShippingPriceRange": "PriceRuleMoneyRangeInput",
    "itemEntitlements": "PriceRuleItemEntitlementsInput",
    "itemPrerequisites": "PriceRuleItemPrerequisitesInput",
    "shippingEntitlements": "PriceRuleShippingEntitlementsInput",
    "prerequisiteToEntitlementQuantityRatio": "PriceRulePrerequisiteToEntitlementQuantityRatioInput"
  }
};
export default PriceRuleInput;