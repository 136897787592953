
const ExchangeV2LineItem = {
  "name": "ExchangeV2LineItem",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customAttributes": "Attribute",
    "discountedTotalSet": "MoneyBag",
    "discountedUnitPriceSet": "MoneyBag",
    "fulfillmentService": "FulfillmentService",
    "giftCard": "Boolean",
    "giftCards": "GiftCard",
    "isGiftCard": "Boolean",
    "lineItem": "LineItem",
    "name": "String",
    "originalTotalSet": "MoneyBag",
    "originalUnitPriceSet": "MoneyBag",
    "quantity": "Int",
    "requiresShipping": "Boolean",
    "sku": "String",
    "taxLines": "TaxLine",
    "taxable": "Boolean",
    "title": "String",
    "variant": "ProductVariant",
    "variantTitle": "String",
    "vendor": "String"
  },
  "implementsNode": false
};
export default ExchangeV2LineItem;