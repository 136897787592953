
const Market = {
  "name": "Market",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "handle": "String",
    "id": "ID",
    "metafield": "Metafield",
    "metafields": "Metafield"
  },
  "implementsNode": true
};
export default Market;