
const CompanyContactInput = {
  "name": "CompanyContactInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "firstName": "String",
    "lastName": "String",
    "email": "String",
    "title": "String",
    "locale": "String",
    "phone": "String"
  }
};
export default CompanyContactInput;