
const PrivateMetafieldUpsertPayload = {
  "name": "PrivateMetafieldUpsertPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "privateMetafield": "PrivateMetafield",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default PrivateMetafieldUpsertPayload;