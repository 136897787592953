
const MetaobjectCapabilityDefinitionDataRenderable = {
  "name": "MetaobjectCapabilityDefinitionDataRenderable",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "metaDescriptionKey": "String",
    "metaTitleKey": "String"
  },
  "implementsNode": false
};
export default MetaobjectCapabilityDefinitionDataRenderable;