
const MarketingEngagementCreatePayload = {
  "name": "MarketingEngagementCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "marketingEngagement": "MarketingEngagement",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default MarketingEngagementCreatePayload;