
const MetafieldsSetInput = {
  "name": "MetafieldsSetInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "ownerId": "ID",
    "namespace": "String",
    "key": "String",
    "value": "String",
    "type": "String"
  }
};
export default MetafieldsSetInput;