
const ProductVariantSetInput = {
  "name": "ProductVariantSetInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "requiresComponents": "Boolean",
    "barcode": "String",
    "compareAtPrice": "Money",
    "harmonizedSystemCode": "String",
    "id": "ID",
    "mediaId": "ID",
    "inventoryPolicy": "ProductVariantInventoryPolicy",
    "metafields": "MetafieldInput",
    "optionValues": "VariantOptionValueInput",
    "position": "Int",
    "price": "Money",
    "requiresShipping": "Boolean",
    "sku": "String",
    "taxable": "Boolean",
    "taxCode": "String",
    "weight": "Float",
    "weightUnit": "WeightUnit"
  }
};
export default ProductVariantSetInput;