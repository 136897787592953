import React, { Component } from 'react';
import { ReactComponent as LoaderIcon } from "../../assets/svgs/loader.svg";
import styled from 'styled-components';

const LoaderContainer = styled.div`
    width: 100%;
    // height: 100%;
    display: flex;
    background: transparent;
    justify-content: center;
    align-items: center;
    flex: 1;
`;

export class Loader extends Component {
    render() {
        const { size } = this.props;
        return (
            <LoaderContainer>
                <LoaderIcon style={{ maxHeight: "100px", width: `${size ? size : "auto"}`, height:  `${size ? size : "auto"}`}} />
            </LoaderContainer>
        )
    }
}

export default Loader
