
const SellingPlanAnchor = {
  "name": "SellingPlanAnchor",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cutoffDay": "Int",
    "day": "Int",
    "month": "Int",
    "type": "SellingPlanAnchorType"
  },
  "implementsNode": false
};
export default SellingPlanAnchor;