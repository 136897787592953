
const AbandonedCheckout = {
  "name": "AbandonedCheckout",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "abandonedCheckoutUrl": "URL",
    "defaultCursor": "String",
    "id": "ID",
    "lineItems": "AbandonedCheckoutLineItemConnection",
    "lineItemsQuantity": "Int",
    "totalPriceSet": "MoneyBag"
  },
  "implementsNode": true
};
export default AbandonedCheckout;