
const FulfillmentConnection = {
  "name": "FulfillmentConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "FulfillmentEdge",
    "nodes": "Fulfillment",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default FulfillmentConnection;