
const Market = {
  "name": "Market",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "catalogs": "MarketCatalogConnection",
    "catalogsCount": "Count",
    "currencySettings": "MarketCurrencySettings",
    "enabled": "Boolean",
    "handle": "String",
    "id": "ID",
    "metafield": "Metafield",
    "metafieldDefinitions": "MetafieldDefinitionConnection",
    "metafields": "MetafieldConnection",
    "name": "String",
    "priceList": "PriceList",
    "primary": "Boolean",
    "privateMetafield": "PrivateMetafield",
    "privateMetafields": "PrivateMetafieldConnection",
    "regions": "MarketRegionConnection",
    "webPresence": "MarketWebPresence",
    "webPresences": "MarketWebPresenceConnection"
  },
  "implementsNode": true
};
export default Market;