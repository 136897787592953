
const SubscriptionContractAtomicCreateInput = {
  "name": "SubscriptionContractAtomicCreateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "customerId": "ID",
    "nextBillingDate": "DateTime",
    "currencyCode": "CurrencyCode",
    "contract": "SubscriptionDraftInput",
    "lines": "SubscriptionAtomicLineInput",
    "discountCodes": "String"
  }
};
export default SubscriptionContractAtomicCreateInput;