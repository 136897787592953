
const PaymentCustomizationDeletePayload = {
  "name": "PaymentCustomizationDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedId": "ID",
    "userErrors": "PaymentCustomizationError"
  },
  "implementsNode": false
};
export default PaymentCustomizationDeletePayload;