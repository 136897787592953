
const CheckoutBrandingMain = {
  "name": "CheckoutBrandingMain",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "backgroundImage": "CheckoutBrandingImage",
    "colorScheme": "CheckoutBrandingColorSchemeSelection",
    "section": "CheckoutBrandingMainSection"
  },
  "implementsNode": false
};
export default CheckoutBrandingMain;