
const DeliveryProfileItemConnection = {
  "name": "DeliveryProfileItemConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "DeliveryProfileItemEdge",
    "nodes": "DeliveryProfileItem",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default DeliveryProfileItemConnection;