
const CompanyContactUpdatePayload = {
  "name": "CompanyContactUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "companyContact": "CompanyContact",
    "userErrors": "BusinessCustomerUserError"
  },
  "implementsNode": false
};
export default CompanyContactUpdatePayload;