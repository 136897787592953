
const PubSubServerPixelUpdatePayload = {
  "name": "PubSubServerPixelUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "serverPixel": "ServerPixel",
    "userErrors": "ErrorsServerPixelUserError"
  },
  "implementsNode": false
};
export default PubSubServerPixelUpdatePayload;