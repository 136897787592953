
const ProductOptionsDeletePayload = {
  "name": "ProductOptionsDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedOptionsIds": "ID",
    "product": "Product",
    "userErrors": "ProductOptionsDeleteUserError"
  },
  "implementsNode": false
};
export default ProductOptionsDeletePayload;