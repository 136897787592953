
const CheckoutBrandingFooter = {
  "name": "CheckoutBrandingFooter",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "alignment": "CheckoutBrandingFooterAlignment",
    "colorScheme": "CheckoutBrandingColorSchemeSelection",
    "content": "CheckoutBrandingFooterContent",
    "padding": "CheckoutBrandingSpacingKeyword",
    "position": "CheckoutBrandingFooterPosition"
  },
  "implementsNode": false
};
export default CheckoutBrandingFooter;