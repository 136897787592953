
const StagedUploadTargetsGeneratePayload = {
  "name": "StagedUploadTargetsGeneratePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "urls": "StagedUploadTarget",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default StagedUploadTargetsGeneratePayload;