
const CartLinesUpdatePayload = {
  "name": "CartLinesUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cart": "Cart",
    "userErrors": "CartUserError"
  },
  "implementsNode": false
};
export default CartLinesUpdatePayload;