
const MarketingActivity = {
  "name": "MarketingActivity",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "activityListUrl": "URL",
    "adSpend": "MoneyV2",
    "app": "App",
    "appErrors": "MarketingActivityExtensionAppErrors",
    "budget": "MarketingBudget",
    "createdAt": "DateTime",
    "formData": "String",
    "hierarchyLevel": "MarketingActivityHierarchyLevel",
    "id": "ID",
    "inMainWorkflowVersion": "Boolean",
    "isExternal": "Boolean",
    "marketingChannel": "MarketingChannel",
    "marketingChannelType": "MarketingChannel",
    "marketingEvent": "MarketingEvent",
    "parentActivityId": "ID",
    "parentRemoteId": "String",
    "sourceAndMedium": "String",
    "status": "MarketingActivityStatus",
    "statusBadgeType": "MarketingActivityStatusBadgeType",
    "statusBadgeTypeV2": "BadgeType",
    "statusLabel": "String",
    "statusTransitionedAt": "DateTime",
    "tactic": "MarketingTactic",
    "targetStatus": "MarketingActivityStatus",
    "title": "String",
    "updatedAt": "DateTime",
    "urlParameterValue": "String",
    "utmParameters": "UTMParameters"
  },
  "implementsNode": true
};
export default MarketingActivity;