
const PredictiveSearchResult = {
  "name": "PredictiveSearchResult",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "articles": "Article",
    "collections": "Collection",
    "pages": "Page",
    "products": "Product",
    "queries": "SearchQuerySuggestion"
  },
  "implementsNode": false
};
export default PredictiveSearchResult;