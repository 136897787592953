
const OptionSetInput = {
  "name": "OptionSetInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "id": "ID",
    "name": "String",
    "position": "Int",
    "values": "OptionValueSetInput"
  }
};
export default OptionSetInput;