
const DisputeEvidenceUpdatePayload = {
  "name": "DisputeEvidenceUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "disputeEvidence": "ShopifyPaymentsDisputeEvidence",
    "userErrors": "DisputeEvidenceUpdateUserError"
  },
  "implementsNode": false
};
export default DisputeEvidenceUpdatePayload;