
const CheckoutBrandingShopifyFontGroupInput = {
  "name": "CheckoutBrandingShopifyFontGroupInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "name": "String",
    "baseWeight": "Int",
    "boldWeight": "Int",
    "loadingStrategy": "CheckoutBrandingFontLoadingStrategy"
  }
};
export default CheckoutBrandingShopifyFontGroupInput;