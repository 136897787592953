
const CollectionCreatePayload = {
  "name": "CollectionCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "collection": "Collection",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default CollectionCreatePayload;