
const ShopifyPaymentsJpChargeStatementDescriptor = {
  "name": "ShopifyPaymentsJpChargeStatementDescriptor",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "default": "String",
    "kana": "String",
    "kanji": "String",
    "prefix": "String"
  },
  "implementsNode": false
};
export default ShopifyPaymentsJpChargeStatementDescriptor;