
const PaymentScheduleConnection = {
  "name": "PaymentScheduleConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "PaymentScheduleEdge",
    "nodes": "PaymentSchedule",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default PaymentScheduleConnection;