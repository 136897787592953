
const BuyerExperienceConfiguration = {
  "name": "BuyerExperienceConfiguration",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkoutToDraft": "Boolean",
    "editableShippingAddress": "Boolean",
    "payNowOnly": "Boolean",
    "paymentTermsTemplate": "PaymentTermsTemplate"
  },
  "implementsNode": false
};
export default BuyerExperienceConfiguration;