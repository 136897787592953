
const OrderTransactionConnection = {
  "name": "OrderTransactionConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "OrderTransactionEdge",
    "nodes": "OrderTransaction",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default OrderTransactionConnection;