
const ProductResourceFeedbackInput = {
  "name": "ProductResourceFeedbackInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "productId": "ID",
    "state": "ResourceFeedbackState",
    "feedbackGeneratedAt": "DateTime",
    "productUpdatedAt": "DateTime",
    "messages": "String"
  }
};
export default ProductResourceFeedbackInput;