
const CheckoutBrandingColorGlobalInput = {
  "name": "CheckoutBrandingColorGlobalInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "info": "String",
    "success": "String",
    "warning": "String",
    "critical": "String",
    "brand": "String",
    "accent": "String",
    "decorative": "String"
  }
};
export default CheckoutBrandingColorGlobalInput;