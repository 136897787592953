
const MetaobjectDefinitionCreateInput = {
  "name": "MetaobjectDefinitionCreateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "name": "String",
    "description": "String",
    "type": "String",
    "fieldDefinitions": "MetaobjectFieldDefinitionCreateInput",
    "access": "MetaobjectAccessInput",
    "displayNameKey": "String",
    "capabilities": "MetaobjectCapabilityCreateInput"
  }
};
export default MetaobjectDefinitionCreateInput;