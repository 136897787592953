
const DeliveryCustomization = {
  "name": "DeliveryCustomization",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "enabled": "Boolean",
    "errorHistory": "FunctionsErrorHistory",
    "functionId": "String",
    "id": "ID",
    "metafield": "Metafield",
    "metafieldDefinitions": "MetafieldDefinitionConnection",
    "metafields": "MetafieldConnection",
    "privateMetafield": "PrivateMetafield",
    "privateMetafields": "PrivateMetafieldConnection",
    "shopifyFunction": "ShopifyFunction",
    "title": "String"
  },
  "implementsNode": true
};
export default DeliveryCustomization;