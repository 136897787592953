
const CatalogUpdateInput = {
  "name": "CatalogUpdateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "title": "String",
    "status": "CatalogStatus",
    "context": "CatalogContextInput",
    "priceListId": "ID",
    "publicationId": "ID"
  }
};
export default CatalogUpdateInput;