
const PriceListUpdatePayload = {
  "name": "PriceListUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "priceList": "PriceList",
    "userErrors": "PriceListUserError"
  },
  "implementsNode": false
};
export default PriceListUpdatePayload;