
const CashTrackingAdjustmentEdge = {
  "name": "CashTrackingAdjustmentEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "CashTrackingAdjustment"
  },
  "implementsNode": false
};
export default CashTrackingAdjustmentEdge;