
const ShippingLabel = {
  "name": "ShippingLabel",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cancellable": "Boolean",
    "id": "ID",
    "location": "Location",
    "printed": "Boolean"
  },
  "implementsNode": true
};
export default ShippingLabel;