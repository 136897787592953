
const ShopifyPaymentsDisputeFulfillment = {
  "name": "ShopifyPaymentsDisputeFulfillment",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "shippingCarrier": "String",
    "shippingDate": "Date",
    "shippingTrackingNumber": "String"
  },
  "implementsNode": true
};
export default ShopifyPaymentsDisputeFulfillment;