
const OrderStagedChangeAddCustomItem = {
  "name": "OrderStagedChangeAddCustomItem",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "originalUnitPrice": "MoneyV2",
    "quantity": "Int",
    "title": "String"
  },
  "implementsNode": false
};
export default OrderStagedChangeAddCustomItem;