
const SubscriptionContractUserError = {
  "name": "SubscriptionContractUserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "SubscriptionContractErrorCode",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
export default SubscriptionContractUserError;