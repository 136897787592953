
const CustomerCreatePayload = {
  "name": "CustomerCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customer": "Customer",
    "customerUserErrors": "CustomerUserError",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default CustomerCreatePayload;