
const CollectionPublishPayload = {
  "name": "CollectionPublishPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "collection": "Collection",
    "collectionPublications": "CollectionPublication",
    "shop": "Shop",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default CollectionPublishPayload;