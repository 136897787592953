
const CustomerCreditCardBillingAddress = {
  "name": "CustomerCreditCardBillingAddress",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "address1": "String",
    "city": "String",
    "country": "String",
    "countryCode": "CountryCode",
    "firstName": "String",
    "lastName": "String",
    "province": "String",
    "provinceCode": "String",
    "zip": "String"
  },
  "implementsNode": false
};
export default CustomerCreditCardBillingAddress;