
const ImageTransformInput = {
  "name": "ImageTransformInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "crop": "CropRegion",
    "maxWidth": "Int",
    "maxHeight": "Int",
    "scale": "Int",
    "preferredContentType": "ImageContentType"
  }
};
export default ImageTransformInput;