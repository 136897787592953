
const Language = {
  "name": "Language",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "endonymName": "String",
    "isoCode": "LanguageCode",
    "name": "String"
  },
  "implementsNode": false
};
export default Language;