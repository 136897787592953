
const AppPurchase = {
  "name": "AppPurchase",
  "kind": "INTERFACE",
  "fieldBaseTypes": {
    "createdAt": "DateTime",
    "name": "String",
    "price": "MoneyV2",
    "status": "AppPurchaseStatus",
    "test": "Boolean"
  },
  "possibleTypes": ["AppPurchaseOneTime"]
};
export default AppPurchase;