
const CartTransformDeletePayload = {
  "name": "CartTransformDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedId": "ID",
    "userErrors": "CartTransformDeleteUserError"
  },
  "implementsNode": false
};
export default CartTransformDeletePayload;