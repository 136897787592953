
const CountryHarmonizedSystemCode = {
  "name": "CountryHarmonizedSystemCode",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "countryCode": "CountryCode",
    "harmonizedSystemCode": "String"
  },
  "implementsNode": false
};
export default CountryHarmonizedSystemCode;