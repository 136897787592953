
const Metafield = {
  "name": "Metafield",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "createdAt": "DateTime",
    "definition": "MetafieldDefinition",
    "description": "String",
    "id": "ID",
    "key": "String",
    "legacyResourceId": "UnsignedInt64",
    "namespace": "String",
    "owner": "HasMetafields",
    "ownerType": "MetafieldOwnerType",
    "reference": "MetafieldReference",
    "references": "MetafieldReferenceConnection",
    "type": "String",
    "updatedAt": "DateTime",
    "value": "String"
  },
  "implementsNode": true
};
export default Metafield;