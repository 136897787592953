
const TaxonomyValue = {
  "name": "TaxonomyValue",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "name": "String"
  },
  "implementsNode": true
};
export default TaxonomyValue;