
const MarketRegionEdge = {
  "name": "MarketRegionEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "MarketRegion"
  },
  "implementsNode": false
};
export default MarketRegionEdge;