
const ProductPublicationConnection = {
  "name": "ProductPublicationConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "ProductPublicationEdge",
    "nodes": "ProductPublication",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default ProductPublicationConnection;