
const BusinessCustomerUserError = {
  "name": "BusinessCustomerUserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "BusinessCustomerErrorCode",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
export default BusinessCustomerUserError;