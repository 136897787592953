
const CartBuyerIdentity = {
  "name": "CartBuyerIdentity",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "countryCode": "CountryCode",
    "customer": "Customer",
    "deliveryAddressPreferences": "DeliveryAddress",
    "email": "String",
    "phone": "String",
    "purchasingCompany": "PurchasingCompany",
    "walletPreferences": "String"
  },
  "implementsNode": false
};
export default CartBuyerIdentity;