import React, { Component, Fragment } from 'react';
import Breadcrumb from "../../components/common/bread-crumb";
import CustomContentDisplay from "./components/custom-content-display";
import login from "../../assets/images/help/login.png";
import products from "../../assets/images/help/products.png";
import categories from "../../assets/images/help/categories.png";
import color_and_typography from "../../assets/images/help/color_and_typography.png";
import customers from "../../assets/images/help/customers.png";
import direct_payouts from "../../assets/images/help/direct_payouts.png";
import edit_category from "../../assets/images/help/edit_category.png";
import edit_product from "../../assets/images/help/edit_product.png";
import google_analytics from "../../assets/images/help/google_analytics.png";
import homepage_layout from "../../assets/images/help/homepage_layout.png";
import menus1 from "../../assets/images/help/menus1.png";
import menus2 from "../../assets/images/help/menus2.png";
import menus4 from "../../assets/images/help/menus4.png";
import orders from "../../assets/images/help/orders.png";
import pages from "../../assets/images/help/pages.png";
import customer_service_email from "../../assets/images/help/customer_service_email.png";
import custom_domains from "../../assets/images/help/custom_domains.png";


export class Tabset_profile extends Component {
  componentDidMount() {
    window.analytics.page();
  }
  render() {
    return (
      <Fragment>
        <Breadcrumb mainTitle="Settings" parent="Settings" title="Help & FAQs" />
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h5>Help & FAQs</h5>
            </div>
            <div className="card-body d-md-flex settings-help-faq pt-2">
              <div style={{ width: '230px' }}>
                <p className="text-secondary mt-3 mb-3">Welcome to Ready.Set.Print. Whether you're opening a new online store or are interested in using Ready.Set.Print. as your ecommerce platform, you can find out more information about your options here. Click <a href="https://drive.google.com/file/d/1aJKdbVTVP-ib4lCZ41FmR8YM6HhPea8m/view" target="_blank">here</a> for a PDF version of the reseller setup instructions.</p>
              </div>
              <div className="offset-md-1 w-100 border rounded content-area">
                <CustomContentDisplay
                  header="OVERVIEW"
                >
                  <p>The purpose of this documentation is to provide detailed instruction for the build and management of a
                  custom reseller site. Stouse commissioned a CMS (Customer Management System) called
                  Ready.Set.Print. (RSP for short), a user-friendly system for our resellers to create and manage their sites,
                  and for the Stouse team to assist resellers in managing those stores.</p>
                  <p>As you go through these steps to build and edit your reseller store, please remember to always allow time
                  for the change to occur on the live site. Times vary, but can take several minutes for certain processes to
populate. Also, please remember to always <b>SAVE</b> your changes prior to leaving a module. Let’s get
started!</p>
                </CustomContentDisplay>
                <CustomContentDisplay
                  header="LOGIN"
                >
                  <p>The first step in accessing your RSP site is registration. The Stouse team has full control over who gets
                  access to the Ready.Set.Print. platform. Once the distributor/vendor agrees to become an RSP customer,
a temporary password will be sent to the customer’s email address.</p>
                  <p>Use the temporary password obtained along with your email address to register your store. Please ensure
                  you have selected the “Register” tab and not the “Login” tab. The email address must be the same that
was provided to your Stouse rep at the time of signup.</p>
                  <img src={login} />
                  <p>You will be asked to create a subdomain; we suggest that your subdomain correspond to the name of your
store. Once you set it, you <u>cannot</u> change it. After registering and creating your domain name, you will be
directed to reset your password. After doing so, you will be taken to the reseller dashboard of your store.
After registration, you can always log back in at <a href="https://readysetprint.com">readysetprint.com</a></p>
                </CustomContentDisplay>
                <CustomContentDisplay
                  header="THE AUTO-BUILD PROCESS"
                >
                  <p>Upon your first login, the RSP Dashboard will automatically begin building the core/default site. You will
                  see the “Building” icon next to certain menu options along the left side of the page. Please be patient as
                  this initial build process typically takes about 5-10 minutes. Loading times vary based on internet speed
                  and other localized machine-based factors. Do not make changes to your RSP site until this process is fully
complete. You will know it’s complete when there are no longer “Building” icons in the left side panel.</p>
                  <p>The default RSP store consists of six categories: Decals & Clings, Labels, Magnets, Signs, Paper
                  Products, and Plastic Products. These six categories are automatically created during the initial build
                  sequence and populated with related products. Additionally, these six categories are added to the main
                  menu as well as the hamburger (left side) menu. All categories and menus can be edited, renamed,
                  reorganized, or completely deleted after the initial build. Please refer to the appropriate section later in this
document for editing instructions.</p>
                  <p>As previously stated, the categories are populated with related products specific to the category type. The
                  default store currently consists of 116 products. Overtime, Stouse will add additional products to this core
                  set. As this happens, the products will be added to the <b>Products</b> tab, but will not auto populate into a
                  category. It will be up to the reseller to add new products to their categories. At this time, the only product
                  not activated upon site creation is Beverage Boxes. If you’d like to add this product to a category, you must
first activate it and then add it to a category. More on this process is outlined in the next step.</p>
                </CustomContentDisplay>
                <CustomContentDisplay
                  header="PRODUCTS"
                >
                  <p>As a reseller, you have a variety of products that you can add or remove from your site. They are found in the <b>Products</b> tab on the left side RSP menu. Your store will auto-build with the core set of products. New
products will periodically be added by Stouse. They will populate at the bottom of this list but will not be
auto activated. Use the following steps to add/remove products.</p>
                  <p>To display a new product on your site, you must click the “Activate” button next to it. You can activate one
                  at a time, or in bulk using the “Actions” drop down menu at the top of the page. When activating products,
                  please be patient as load times vary based on the number of products you activate at once. Keep in mind
                  that simply activating a product will not populate it on your reseller site, you will need to add it to a
Category. This is explained later in Part III of this document.</p>
                  <img src={products} />
                  <p>Once a product is activated, you will have the option to either “Delete” or “Edit” the product. Deleting a
                  product does not remove it from the core product list. Deleting will reset the product back to the stock
                  version that came with the store. All user edits including name, description, pricing, images, etc. will be
                  reset to default. Deleting a product will also remove it from any category it was previously placed in. If you
                  decide to stop selling a certain product, the best course of action is to remove it from a Category instead
of deleting it. We will cover more on that in Part III. Remember to save your edits!</p>
                  <p>You also have the option of adding a markup or markdown to an individual product using the “Edit” button
                  to the right of each product. Once inside the “Edit Product” screen, there is a field labeled
                  “Increase/Decrease MSRP on this product”. The markup/down range is limited to -10% of the MSRP or up
to 30% of the MSRP. Markups applied at the product level <u>will</u> override any applied global markup.</p>
                  <p>Using the global or bulk markup field, the reseller can change the markup of their active products all at
                  once. This can be found at the upper right side of the products page. The global/bulk markup only applies
to active products. We recommend activating products before changing the global markup.</p>
                  <img src={edit_product} />
                </CustomContentDisplay>
                <CustomContentDisplay
                  header="CATEGORIES"
                >
                  <p><b>Categories</b> are groups of products you can compile to your liking. Your RSP site comes pre-populated
with six default categories as previously mentioned. Follow these steps to add additional categories to
your RSP site. Some useful category suggestions are Popular Products and All Products.</p>
                  <p>To add products to a category, they must first be activated from the Products page (see Part II). Once on
the Category page, click Create Category.</p>
                  <img src={categories} />
                  <p>After entering your category name and description, the next step is to select the products that will live
                  inside the specific category you’re building. Click the “Add Products” button in the center of the page to
                  begin selecting products. Click the check box to the left of each product to add it to the category. There is
no set limit on the number of products you can add to a category.</p>
                  <p>To rearrange your products, hover over the product row, a hand will appear. Click, hold, and drag that
                  product up or down in the list of products. The order you set your products here, is the order in which they
                  will appear on the site by default. From there, a user can sort by name, price, or newest product. Please
                  be patient with this process. All actions taken in the RSP dashboard require an action in our Shopify
database that can, at times, require a minute or two to populate in your store.</p>
                  <p>After selecting products, you will assign the category a “URL Handle”. The URL Handle is the address link
                  within your site that directs a user to a specific category/page. This is an important step as you will need
this link for buttons and menus later during the site building process. When finished, remember to save.</p>
                  <p>You can always come back to your categories to edit the details and product offerings. Removing a
                  product from a category is typically the best option, rather than deleting it from the Products page.
                  Removing a product from a category retains all your custom name, descriptions, images, etc. As
                  previously stated, if you delete a product from the Products page, it will be removed from any category
                  that it was previously in. Upon reactivating that product, you will need to add it back to the appropriate
category.</p>
                </CustomContentDisplay>
                <CustomContentDisplay
                  header="MENUS"
                >
                  <p>There are multiple menus that a reseller can customize on their site.</p>
                  <ul className="ml-4 mb-3">
                    <li style={{ display: "block" }}><p>1) The <b>Primary Menu</b> is seen at the top of your website</p></li>
                    <li style={{ display: "block" }}><p>2) The <b>Hamburger Menu</b> pulls out from the left-hand side of your site</p></li>
                    <li style={{ display: "block" }}><p>3) The <b>Footer Menu</b> is located at the bottom of your website.</p></li>
                  </ul>
                  <img src={menus1} />
                  <p>To customize these, navigate to the <b>Menus</b> tab and click the edit button on the right side of the page.
Here, you will have the option to add/delete menu items. Menu items can be linked to categories,
products, or pages you created earlier in Part IV. It is important to know the URLs you wish to use prior to
creating these menus. You can find your Category URLs in the <b>Categories</b> tab.</p>
                  <img src={menus2} />
                  <p>The <b>Menus &gt; Footer</b> tab is where you will add company information, social media links, as well as links to
any pages you have activated. There are two columns available for adding links to the footer. Links at the
bottom of a website are typically reserved for pages such as Contact US, FAQs, Privacy Policy, Terms
and Conditions, etc. Page setup will be discussed in Part V of this document.</p>
                  <img src={menus4} />
                </CustomContentDisplay>
                <CustomContentDisplay
                  header="PAGES"
                >
                  <p>The <b>Pages</b> tab in the RSP dashboard allows the reseller to add up to seven additional pages to their site.
These pages are standard for most websites and help to provide the end user with additional information.
These pages are optional and are not required to be added to your site. The pages available to be activated
are:</p>
                  <div className="ml-4 mb-3 mt-3">
                    <h6>About Us</h6>
                    <p className="ml-3">Tell your customers about your great business! This page gives you a space to introduce
yourself and tell your success story.</p>
                    <h6>Contact Us</h6>
                    <p className="ml-3">An important page used to provide your end user the ability to contact you directly. The
                    email generated from this page will be delivered to the email used during the RSP
registration process.</p>
                    <h6>Privacy Policy</h6>
                    <p className="ml-3">This page comes preloaded with legal information as it pertains to Stouse (S.I. Ultimate
OpCo) and our resellers. There are fields throughout the text that <u>must be</u> edited by the
reseller. This page is <u>mandatory</u> and must be added to your site.</p>
                    <h6>Terms and Conditions</h6>
                    <p className="ml-3">Like the Privacy Policy page, this page also includes areas to be edited by the reseller
with their specific information. This page is <u>mandatory</u> and must be added to your
site.</p>
                    <h6>Art Guidelines</h6>
                    <p className="ml-3">From file types to cut line and bleed, the art guidelines page can be activated to help end
                    users with art setup and general printing terms. This page is preloaded with our standard
art guidelines. Please do not edit or alter these guidelines.</p>
                    <h6>Shipping & Production Info</h6>
                    <p className="ml-3">Stouse will ship the products purchased in your store direct to your customer. This page
                    provides useful info regarding shipping options as well as a detailed table of our product
production times. It is your choice to add this page to your footer menu.</p>
                    <h6>FAQs</h6>
                    <p className="ml-3">A helpful page for your customers, the FAQ page comes preloaded with common
questions and answers based on our experience in the print industry.</p>
                  </div>
                  <img src={pages} />
                </CustomContentDisplay>
                <CustomContentDisplay
                  header="CUSTOMIZE"
                >
                  <p>The <b>Homepage Layout</b> tab is where you will customize the look and feel of your RSP site. You can add
design elements such as your store logo, hero image (the featured image when visiting your site), promo
bar icons, and more. Having your logo, images, icons, and other company attributes available prior to this
step will make the process a bit smoother. Here is an overview of the options available:</p>
                  <img src={homepage_layout} />
                  <div className="ml-4 mt-3 mb-3">
                    <h6>Store Logo</h6>
                    <p className="ml-3">Your logo will appear at the top & bottom left corner of the website. A PNG file type with a
                    transparent background usually works best. The recommended image height is 160px,
width is not specified.</p>
                    <h6>Flexible Messaging Area</h6>
                    <p className="ml-3">The flexible messaging area appears at the very top of your website above your primary
                    menu. It can be used to advertise promotions, new products, etc. If you don’t want to
display a message, you can leave the field blank.</p>
                    <h6>Homepage Hero Image + Text</h6>
                    <p className="ml-3">The hero image & text area is your primary visual messaging area on the home page.
                    Upload your image, edit your headline, and add a call-to-action button. Minimum image
size required for the hero image is 1600px ✕ 700px - JPG or PNG.</p>
                    <h6>Promo Bar</h6>
                    <p className="ml-3">The promo bar displays up to 3 promotional offers, or text with icons, on the homepage
                    and product detail page. You can turn this on or off. The recommended image size for
promo icons is 100px ✕ 100px - JPG or PNG (transparent background).</p>
                    <h6>Category Grid</h6>
                    <p className="ml-3">The Category Grid displays up to 6 categories for easy navigation on the home page.
                    You must have previously set up categories for this feature to function. If no categories
are selected, this component will not display on the homepage.</p>
                    <h6>Product Grid</h6>
                    <p className="ml-3">The product grid displays products in increments of 4. You have the option of 4, 8, 12, or
                    16. Name your grid in the field provided and select the category of which products will be
displayed. Any categories previously set up are available in the drop-down menu.</p>
                  </div>
                  <p>The <b>Color & Typography</b> area of the Customize tab, is where you will choose the colors of your RSP
site. You have the option to adjust the color for titles, body text, homepage hero text, primary buttons, and
links. To choose a color, click into the corresponding box, and a color field will appear or enter the color
HEX code in the text box. Please note, you must begin the HEX code with the number sign symbol (#).
Remember to click save after you’ve made your selections.</p>
                  <img src={color_and_typography} />
                  <p>Also included in this section are the font options for titles, body text, and buttons. The typography choices
                  allow you more ways to personalize your site. The title text options are bold to make them stand out more
                  on your pages. Again, be sure to hit the save button once you’ve made your selections! You can change
these at any time.</p>
                </CustomContentDisplay>
                <CustomContentDisplay
                  header="CUSTOMERS"
                >
                  <p>The <b>Customers</b> page displays all current and past customers of your store. You can click the “View”
button to the right of their name to see information such as shipping address, past orders, total spent, etc.
You can also indicate with a check box whether they are tax exempt.</p>
                  <img src={customers} />
                </CustomContentDisplay>
                <CustomContentDisplay
                  header="ORDERS"
                >
                  <p>When a customer places an order on your site, it will show up on the <b>Orders</b> page of the RSP dashboard.
To see order info, click the green order number. Within each order the reseller can see all order
information including costs, status, shipping info, profile details, as well as a preview image of the item
purchased.</p>
                  <img src={orders} />
                </CustomContentDisplay>
                <CustomContentDisplay
                  header="SETTINGS"
                >
                  <p>You don’t need to manage your store all by yourself! You have the option of adding up to two other
                  admins on the Settings &gt; Preferences page. Just click the “Add Admin” button located at the top of the
                  settings page. In addition, the Settings page is where you will find other key items which are outlined
below.</p>
                  <p><b>Customer Service Email</b></p>
                  <p>To receive customer service emails, you will need to add an email address where you’d like the requests
                  to be sent. If no email address is added here, the emails will be sent to the admin address used to setup
your account.</p>
                  <img src={customer_service_email} />
                  <p><b>Direct Payouts</b></p>
                  <p>To receive payouts from Stouse, you must first setup a <a href="https://stripe.com">Stripe account</a>. Click the "Connect Stripe
Account" button on the Settings &gt; Preferences page to get started. If you already have a Stripe account,
you can use this button to login and connect your existing account. If you have questions about the
payout process or do not see this option in your dashboard, please contact your Stouse representative. </p>
                  <img src={direct_payouts} />
                  <p><b>Custom Domains</b></p>
                  <p>You have the option to redirect your store to a custom domain. To do so, under Settings>Preferences,
                  scroll down to the fourth option. There you will see the instructions and information related to this process.
                  Please follow these instructions exactly as listed to prevent errors. Technical questions concerning your
domain, DNS records, and CNAME should be directed to you host provider (i.e., GoDaddy).</p>
                  <img src={custom_domains} />
                  <p><b>Google Analytics</b></p>
                  <p>If you use Google Analytics, you can copy and paste your GA code into this section and click save.
Simple as that! This feature is located as the 3rd options within the Settings tab.</p>
                  <img src={google_analytics} />
                  <p>We know that building your perfect RSP store can be a lot of work! Keep in mind that you can always
reference this Ready.Set.Print. PDF document by navigating to <b>Settings &gt; Help & FAQs</b></p>
                </CustomContentDisplay>
                <CustomContentDisplay
                  header="CONCLUSION"
                >
                  <p>Congratulations, you’ve now successfully activated and setup your store. It’s ready for business! If you
                  experienced any issues during this process, please contact your Stouse representative. We will be happy
to investigate the issue and provide assistance. Thank you for being a Stouse/RSP customer!</p>
                </CustomContentDisplay>

              </div>
            </div>
          </div>
        </div>

      </Fragment>
    )
  }
}

export default Tabset_profile
