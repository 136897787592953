
const ReturnRequestLineItemInput = {
  "name": "ReturnRequestLineItemInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "fulfillmentLineItemId": "ID",
    "quantity": "Int",
    "returnReason": "ReturnReason",
    "customerNote": "String"
  }
};
export default ReturnRequestLineItemInput;