import React, { Component, Fragment } from "react";
import { Dropdown, DropdownItem } from 'react-bootstrap'

import { Alert } from "reactstrap";

import styled from 'styled-components';
import { updateNetlifySnippet, addNetlifySnippet } from "../../../querys/netlify-rest";


import { AuthContext } from '../../../auth/auth';

class CustomizeTypographySelect extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      fontName: "",
      fontCode: "",
      alert: false,
      alertMessage: "",
      alertColor: "success",
    }
  }

  handleSave = () => {
    const { id } = this.props

    const { fontName, fontCode, fontGoogle } = this.props

    let CSS = "";

    if (id === "body") {
      CSS = `/*body_typography_name=${fontName}*//*body_typography_code=${fontCode}*//*body_typography_google=${fontGoogle}*/
      body, span, p, div, .nav-link, .logo-description, .pt-2 .footer-copyright, .info-detail, .info-tip, h4.headline, header .main-navbar .nav-menu>li>a {
        ${fontCode}
        font-weight: 400 !important;
      }
      .btn, .btn span {
        ${fontCode}
        font-weight: 700 !important;
      }`

      this.saveSnippet(CSS, fontGoogle);
    } else if (id === "titles") {

      CSS = `/*titles_typography_name=${fontName}*//*titles_typography_code=${fontCode}*//*titles_typography_google=${fontGoogle}*/
      h1, h2, h3, h4, h5, h6, .sidebar-menu li a, .sub-heading, .info-price-main, .nav-link .react-tabs__tab--selected, .category-page h1 {
        ${fontCode}
        font-weight: 700 !important;
      }`
      this.saveSnippet(CSS, fontGoogle);
    };

  }

  saveSnippet = (content, fontGoogle) => {

    const { snippetId } = this.props

    let snippetContent = `
    <style type="text/css">${content}</style>
    ${fontGoogle}
    `;

    if (snippetId) {
      updateNetlifySnippet(this.context.reseller_site_id, this.props.snippetTitle, snippetContent, snippetId)
        .then(response => {
          if (response.status === 200) {
            this.setState({
              alert: true,
              alertMessage: "Success: The typography was updated on your site.",
              alertColor: "success"
            })
          } else {
            console.log(response);
            this.setState({
              alert: true,
              alertMessage: response.message,
              alertColor: "danger"
            });
          }
        })
    } else {
      addNetlifySnippet(this.context.reseller_site_id, this.props.snippetTitle, snippetContent)
        .then(response => {
          if (response.status === 201) {
            this.setState({
              alert: true,
              alertMessage: "Success: The custom typography was added to your site.",
              alertColor: "success"
            });
            this.props.fetchSnippets();
          } else {
            console.log(response);
            this.setState({
              alert: true,
              alertMessage: response.message,
              alertColor: "danger"
            });
          }
        })
    }

  }

  toggle() {
    this.setState({
      alert: !this.state.alert
    });
  }

  render() {
    const {
      id,
      title,
      fonts,
    } = this.props;

    const { fontName, fontCode } = this.props

    const SampleText = styled.p`
      ${fontCode}
      font-size: 20px;
      ${id === "titles" ? "font-weight: bold;" : ""}
    `

    const Select = styled.select`
      ${fontCode}
      ${id === "titles" ? "font-weight: bold;" : ""}
    `

    return (
      <Fragment>
        <h5 className="pb-3 mt-4"> {title} </h5>
        <div className="p-3 border border-black rounded">
          <SampleText>Sample text</SampleText>
          <div className="typography-container align-content-between d-flex flex-wrap justify-content-between">
            <Select name="select" className="is-touched is-pristine av-valid form-control mr-3 mb-3" style={{ maxWidth: "70%", minWidth: "200px" }} onChange={(ev) => this.props.onTypographySelect(ev.target.value, id)} value={fontName}>
              {fonts.map(font => {
                const Option = styled.option`
                  ${font.code}
                  ${id === "titles" ? "font-weight: bold;" : ""}
                `
                return <Option value={font.name} key={font.name}>{font.name}</Option>
              })}
            </Select>
            <button type="button" className="btn btn-primary mb-3" onClick={this.handleSave}>Save</button>
          </div >
        </div>
        <br />
        <br />
        <Alert color={this.state.alertColor} isOpen={this.state.alert} toggle={this.toggle.bind(this)} > {this.state.alertMessage} </Alert>
      </Fragment>
    );
  }
}

CustomizeTypographySelect.contextType = AuthContext;

export default CustomizeTypographySelect;
