
const MarketRegionConnection = {
  "name": "MarketRegionConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "MarketRegionEdge",
    "nodes": "MarketRegion",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default MarketRegionConnection;