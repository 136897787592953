
const ReverseDeliveryShippingDeliverable = {
  "name": "ReverseDeliveryShippingDeliverable",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "label": "ReverseDeliveryLabelV2",
    "tracking": "ReverseDeliveryTrackingV2"
  },
  "implementsNode": false
};
export default ReverseDeliveryShippingDeliverable;