
const AppCredit = {
  "name": "AppCredit",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "amount": "MoneyV2",
    "createdAt": "DateTime",
    "description": "String",
    "id": "ID",
    "test": "Boolean"
  },
  "implementsNode": true
};
export default AppCredit;