
const ReverseDeliveryDisposeInput = {
  "name": "ReverseDeliveryDisposeInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "reverseDeliveryLineItemId": "ID",
    "quantity": "Int",
    "dispositionType": "ReverseFulfillmentOrderDispositionType",
    "locationId": "ID"
  }
};
export default ReverseDeliveryDisposeInput;