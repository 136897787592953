
const UrlRedirectImportCreatePayload = {
  "name": "UrlRedirectImportCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "urlRedirectImport": "UrlRedirectImport",
    "userErrors": "UrlRedirectImportUserError"
  },
  "implementsNode": false
};
export default UrlRedirectImportCreatePayload;