
const GenericFile = {
  "name": "GenericFile",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "alt": "String",
    "createdAt": "DateTime",
    "fileErrors": "FileError",
    "fileStatus": "FileStatus",
    "id": "ID",
    "mimeType": "String",
    "originalFileSize": "Int",
    "preview": "MediaPreviewImage",
    "updatedAt": "DateTime",
    "url": "URL"
  },
  "implementsNode": true
};
export default GenericFile;