
const SegmentStringFilter = {
  "name": "SegmentStringFilter",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "localizedName": "String",
    "multiValue": "Boolean",
    "queryName": "String"
  },
  "implementsNode": false
};
export default SegmentStringFilter;