
const ProductOption = {
  "name": "ProductOption",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "linkedMetafield": "LinkedMetafield",
    "name": "String",
    "optionValues": "ProductOptionValue",
    "position": "Int",
    "translations": "Translation",
    "values": "String"
  },
  "implementsNode": true
};
export default ProductOption;