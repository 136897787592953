
const ShopPlan = {
  "name": "ShopPlan",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "displayName": "String",
    "partnerDevelopment": "Boolean",
    "shopifyPlus": "Boolean"
  },
  "implementsNode": false
};
export default ShopPlan;