
const ResourceFeedback = {
  "name": "ResourceFeedback",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "appFeedback": "AppFeedback",
    "details": "AppFeedback",
    "summary": "String"
  },
  "implementsNode": false
};
export default ResourceFeedback;