
const SegmentEventFilterParameter = {
  "name": "SegmentEventFilterParameter",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "acceptsMultipleValues": "Boolean",
    "localizedDescription": "String",
    "localizedName": "String",
    "optional": "Boolean",
    "parameterType": "String",
    "queryName": "String"
  },
  "implementsNode": false
};
export default SegmentEventFilterParameter;