
const CartTransformCreatePayload = {
  "name": "CartTransformCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cartTransform": "CartTransform",
    "userErrors": "CartTransformCreateUserError"
  },
  "implementsNode": false
};
export default CartTransformCreatePayload;