
const CompanyContact = {
  "name": "CompanyContact",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "company": "Company",
    "createdAt": "DateTime",
    "customer": "Customer",
    "draftOrders": "DraftOrderConnection",
    "id": "ID",
    "isMainContact": "Boolean",
    "lifetimeDuration": "String",
    "locale": "String",
    "orders": "OrderConnection",
    "roleAssignments": "CompanyContactRoleAssignmentConnection",
    "title": "String",
    "updatedAt": "DateTime"
  },
  "implementsNode": true
};
export default CompanyContact;