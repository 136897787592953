
const InContextAnnotation = {
  "name": "InContextAnnotation",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "description": "String",
    "type": "InContextAnnotationType"
  },
  "implementsNode": false
};
export default InContextAnnotation;