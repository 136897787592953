
const StandardMetaobjectDefinitionEnablePayload = {
  "name": "StandardMetaobjectDefinitionEnablePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "metaobjectDefinition": "MetaobjectDefinition",
    "userErrors": "MetaobjectUserError"
  },
  "implementsNode": false
};
export default StandardMetaobjectDefinitionEnablePayload;