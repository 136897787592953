
const AbandonmentEmailStateUpdatePayload = {
  "name": "AbandonmentEmailStateUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "abandonment": "Abandonment",
    "userErrors": "AbandonmentEmailStateUpdateUserError"
  },
  "implementsNode": false
};
export default AbandonmentEmailStateUpdatePayload;