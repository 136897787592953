
const PriceListUpdateInput = {
  "name": "PriceListUpdateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "name": "String",
    "currency": "CurrencyCode",
    "parent": "PriceListParentUpdateInput",
    "catalogId": "ID"
  }
};
export default PriceListUpdateInput;