
const SubscriptionBillingCycleEditsDeletePayload = {
  "name": "SubscriptionBillingCycleEditsDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "billingCycles": "SubscriptionBillingCycle",
    "userErrors": "SubscriptionBillingCycleUserError"
  },
  "implementsNode": false
};
export default SubscriptionBillingCycleEditsDeletePayload;