
const DeliveryRateDefinition = {
  "name": "DeliveryRateDefinition",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "price": "MoneyV2"
  },
  "implementsNode": true
};
export default DeliveryRateDefinition;