
const PriceRuleItemEntitlementsInput = {
  "name": "PriceRuleItemEntitlementsInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "targetAllLineItems": "Boolean",
    "productIds": "ID",
    "productVariantIds": "ID",
    "collectionIds": "ID"
  }
};
export default PriceRuleItemEntitlementsInput;