
const PaymentReminderSendPayload = {
  "name": "PaymentReminderSendPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "success": "Boolean",
    "userErrors": "PaymentReminderSendUserError"
  },
  "implementsNode": false
};
export default PaymentReminderSendPayload;