
const ShopAlertAction = {
  "name": "ShopAlertAction",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "title": "String",
    "url": "URL"
  },
  "implementsNode": false
};
export default ShopAlertAction;