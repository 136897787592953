
const ProductFeedCreatePayload = {
  "name": "ProductFeedCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "productFeed": "ProductFeed",
    "userErrors": "ProductFeedCreateUserError"
  },
  "implementsNode": false
};
export default ProductFeedCreatePayload;