
const LocalizationExtensionConnection = {
  "name": "LocalizationExtensionConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "LocalizationExtensionEdge",
    "nodes": "LocalizationExtension",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default LocalizationExtensionConnection;