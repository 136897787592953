
const ProductFilter = {
  "name": "ProductFilter",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "available": "Boolean",
    "variantOption": "VariantOptionFilter",
    "productType": "String",
    "productVendor": "String",
    "price": "PriceRangeFilter",
    "productMetafield": "MetafieldFilter",
    "variantMetafield": "MetafieldFilter",
    "tag": "String"
  }
};
export default ProductFilter;