
const InventoryQuantityName = {
  "name": "InventoryQuantityName",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "belongsTo": "String",
    "comprises": "String",
    "displayName": "String",
    "isInUse": "Boolean",
    "name": "String"
  },
  "implementsNode": false
};
export default InventoryQuantityName;