
const CollectionRuleConditions = {
  "name": "CollectionRuleConditions",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "allowedRelations": "CollectionRuleRelation",
    "defaultRelation": "CollectionRuleRelation",
    "ruleObject": "CollectionRuleConditionsRuleObject",
    "ruleType": "CollectionRuleColumn"
  },
  "implementsNode": false
};
export default CollectionRuleConditions;