
const CartLinesRemovePayload = {
  "name": "CartLinesRemovePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cart": "Cart",
    "userErrors": "CartUserError"
  },
  "implementsNode": false
};
export default CartLinesRemovePayload;