
const SellingPlanPriceAdjustment = {
  "name": "SellingPlanPriceAdjustment",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "adjustmentValue": "SellingPlanPriceAdjustmentValue",
    "orderCount": "Int"
  },
  "implementsNode": false
};
export default SellingPlanPriceAdjustment;