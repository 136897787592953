
const ShopifyPaymentsPayoutConnection = {
  "name": "ShopifyPaymentsPayoutConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "ShopifyPaymentsPayoutEdge",
    "nodes": "ShopifyPaymentsPayout",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default ShopifyPaymentsPayoutConnection;