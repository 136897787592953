
const SegmentEventFilter = {
  "name": "SegmentEventFilter",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "localizedName": "String",
    "multiValue": "Boolean",
    "parameters": "SegmentEventFilterParameter",
    "queryName": "String",
    "returnValueType": "String"
  },
  "implementsNode": false
};
export default SegmentEventFilter;