
const MediaPreviewImage = {
  "name": "MediaPreviewImage",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "image": "Image",
    "status": "MediaPreviewImageStatus"
  },
  "implementsNode": false
};
export default MediaPreviewImage;