
const MarketingEvent = {
  "name": "MarketingEvent",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "app": "App",
    "channel": "MarketingChannel",
    "channelHandle": "String",
    "description": "String",
    "endedAt": "DateTime",
    "id": "ID",
    "legacyResourceId": "UnsignedInt64",
    "manageUrl": "URL",
    "marketingChannelType": "MarketingChannel",
    "previewUrl": "URL",
    "remoteId": "String",
    "scheduledToEndAt": "DateTime",
    "sourceAndMedium": "String",
    "startedAt": "DateTime",
    "targetTypeDisplayText": "String",
    "type": "MarketingTactic",
    "utmCampaign": "String",
    "utmMedium": "String",
    "utmSource": "String"
  },
  "implementsNode": true
};
export default MarketingEvent;