
const CheckoutBrandingTypographyStyleGlobal = {
  "name": "CheckoutBrandingTypographyStyleGlobal",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "kerning": "CheckoutBrandingTypographyKerning",
    "letterCase": "CheckoutBrandingTypographyLetterCase"
  },
  "implementsNode": false
};
export default CheckoutBrandingTypographyStyleGlobal;