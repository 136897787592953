
const DraftOrderAppliedDiscountInput = {
  "name": "DraftOrderAppliedDiscountInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "amount": "Money",
    "description": "String",
    "title": "String",
    "value": "Float",
    "valueType": "DraftOrderAppliedDiscountType"
  }
};
export default DraftOrderAppliedDiscountInput;