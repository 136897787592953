
const DraftOrderTag = {
  "name": "DraftOrderTag",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "handle": "String",
    "id": "ID",
    "title": "String"
  },
  "implementsNode": true
};
export default DraftOrderTag;