
const VideoSource = {
  "name": "VideoSource",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "fileSize": "Int",
    "format": "String",
    "height": "Int",
    "mimeType": "String",
    "url": "String",
    "width": "Int"
  },
  "implementsNode": false
};
export default VideoSource;