
const Duty = {
  "name": "Duty",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "countryCodeOfOrigin": "CountryCode",
    "harmonizedSystemCode": "String",
    "id": "ID",
    "price": "MoneyBag",
    "taxLines": "TaxLine"
  },
  "implementsNode": true
};
export default Duty;