
const DiscountCodeNodeEdge = {
  "name": "DiscountCodeNodeEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "DiscountCodeNode"
  },
  "implementsNode": false
};
export default DiscountCodeNodeEdge;