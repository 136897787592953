import reorderIcon from "../../assets/images/Reorder_Icon.png";
import proofsIcon from "../../assets/images/Proofs_Icon.png";
import customizeIcon from "../../assets/images/Customize_Icon.png";

const PAGE_NAMES = [
  "about-us",
  "contact-us",
  "faqs",
  "privacy-policy",
  "shipping-info",
  "terms-and-conditions",
  "art-guidelines"
];

const DEFAULT_PAGES_NAMES = [
  "terms-and-conditions",
  "privacy-policy"
];

const DEFAULT_MENUS = [
  {
    title: "Primary Menu",
    description: `The primary menu appears at the top of your site. It is a global component which appears on every page. When adding links, use a full URL address.`,
    handle: "primary-menu",
    id: "primary-menu",
    maxItemsCount: 6,
    items: [
      {
        id: "primary-menu-1",
        title: "Kwik - ShoppingBag"
      },
      {
        id: "primary-menu-2",
        title: "Recorder"
      },
      {
        id: "primary-menu-3",
        title: "Stickers"
      },
      {
        id: "primary-menu-4",
        title: "Decals"
      },
      {
        id: "primary-menu-5",
        title: "Paper"
      }
    ]
  },
  {
    title: "Hamburger Menu",
    description: `The hamburger menu will appear on the lefthand side of the page, and is useful for displaying popular categories. When adding links, use a full URL address.`,
    handle: "hamburger-menu",
    id: "hamburger-menu",
    maxItemsCount: 6,
    items: [
      {
        id: "hamburger-menu-1",
        title: "Category 1"
      },
      {
        id: "hamburger-menu-2",
        title: "Category 2"
      },
      {
        id: "hamburger-menu-3",
        title: "Category 3"
      },
      {
        id: "hamburger-menu-4",
        title: "Category 4"
      },
      {
        id: "hamburger-menu-5",
        title: "Category 5"
      },
      {
        id: "hamburger-menu-6",
        title: "Category 6"
      }]
  },
  {
    title: "Footer Menu1",
    description: `The footer menu 1 appears at bottom of your screen, to the left of footer menu 2. When adding links, use a full URL address.`,
    handle: "footer-menu1",
    id: "footer-menu1",
    maxItemsCount: 4,
    items: []
  },
  {
    title: "Legal",
    description: `The footer menu 2 appears at bottom of your screen, to the far right. When adding links, use a full URL address.`,
    handle: "footer-menu2",
    id: "footer-menu2",
    maxItemsCount: 4,
    items: [
      {
        id: "footer-menu2-1",
        title: "Privacy Policy",
        handle: "privacy-policy"
      },
      {
        id: "footer-menu2-2",
        title: "Terms and Conditions",
        handle: "terms-and-conditions"
      },
    ]
  }
];

const DEFAULT_SNIPPETS = [
  {
    title: "Flexible Messaging Area",
    content: `<script>window.flexibleMessage="Get all your custom labels, stickers, and packaging in one place with Ready.Set.Print."</script>`
  },
  {
    title: "Homepage Hero Image + Text",
    content: `<script>window.homepageSettings=${JSON.stringify({
      label: "Now Available",
      headline: "Custom Labels & Stickers",
      verticalAlignment: "center",
      horizontalAlignment: "left",
      headlineAlignment: "left",
      ctaButtonLabel: "DESIGN NOW",
      ctaButtonLink: "/"
    })};</script>`
  },
  {
    title: "Promo Bar",
    content: `<script>window.promoBar=${JSON.stringify({
      on: "true",
      capitalized: true,
      slots: [
        {
          id: "slot-1",
          headline: "Customize Your Products",
          headlineSubtitle: "Add new colors, text and artwork",
          iconUrl: customizeIcon
        },
        {
          id: "slot-2",
          headline: "Free Online Proofs",
          headlineSubtitle: "Approve your artwork online",
          iconUrl: proofsIcon
        },
        {
          id: "slot-3",
          headline: "Quick & Easy Reorders",
          headlineSubtitle: "Artwork library for easy reorders",
          iconUrl: reorderIcon
        }
      ]
    })}</script>`
  },
  {
    title: "Category Grid",
    content: `<script>window.categoryGrid=${JSON.stringify({
      headline: "Browse by Category",
      collections: [
        {
          id: "collection-1",
          categoryId: ""
        },
        {
          id: "collection-2",
          categoryId: ""
        },
        {
          id: "collection-3",
          categoryId: ""
        },
        {
          id: "collection-4",
          categoryId: ""
        },
        {
          id: "collection-5",
          categoryId: ""
        },
        {
          id: "collection-6",
          categoryId: ""
        }
      ]
    })}</script>`
  },
  {
    title: "Product Grid",
    content: `<script>window.productGrid=${JSON.stringify({
      count: 12,
      headline: "Popular Products"
    })}</script>`
  }
];

const DEFAULT_MENU_LINK_OPTIONS = [{
  value: "page",
  label: "Page",
  path: "/pages/"
},
{
  value: "product",
  label: "Product",
  path: "/products/"
},
{
  value: "category",
  label: "Category",
  path: "/category/"
}]

const CHECKOUT_URLS = [
  {
      "customSubdomain": "www.gfsimpress.com",
      "checkoutUrl": "checkout.gfsimpress.com"
  },
  {
      "customSubdomain": "www.range-digital-checkout-test.com",
      "checkoutUrl": "checkout.rangempls.com"
  },
  {
      "customSubdomain": "fr.gfsimpress.com",
      "checkoutUrl": "checkout.fr.gfsimpress.com"
  },
  {
      "customSubdomain": "ca.gfsimpress.com",
      "checkoutUrl": "checkout.ca.gfsimpress.com"
  }
]

export { PAGE_NAMES, DEFAULT_SNIPPETS, DEFAULT_PAGES_NAMES, DEFAULT_MENUS, DEFAULT_MENU_LINK_OPTIONS, CHECKOUT_URLS };