
const InventoryAdjustmentGroup = {
  "name": "InventoryAdjustmentGroup",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "app": "App",
    "changes": "InventoryChange",
    "createdAt": "DateTime",
    "id": "ID",
    "reason": "String",
    "referenceDocumentUri": "String",
    "staffMember": "StaffMember"
  },
  "implementsNode": true
};
export default InventoryAdjustmentGroup;