
const CartLineUpdateInput = {
  "name": "CartLineUpdateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "id": "ID",
    "quantity": "Int",
    "merchandiseId": "ID",
    "attributes": "AttributeInput",
    "sellingPlanId": "ID"
  }
};
export default CartLineUpdateInput;