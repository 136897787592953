
const CustomerMergeOverrideFields = {
  "name": "CustomerMergeOverrideFields",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "customerIdOfFirstNameToKeep": "ID",
    "customerIdOfLastNameToKeep": "ID",
    "customerIdOfEmailToKeep": "ID",
    "customerIdOfPhoneNumberToKeep": "ID",
    "customerIdOfDefaultAddressToKeep": "ID",
    "note": "String",
    "tags": "String"
  }
};
export default CustomerMergeOverrideFields;