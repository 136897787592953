
const OnlineStoreArticle = {
  "name": "OnlineStoreArticle",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "defaultCursor": "String",
    "id": "ID",
    "translations": "Translation"
  },
  "implementsNode": true
};
export default OnlineStoreArticle;