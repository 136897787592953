
const DiscountRedeemCode = {
  "name": "DiscountRedeemCode",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "asyncUsageCount": "Int",
    "code": "String",
    "createdBy": "App",
    "id": "ID"
  },
  "implementsNode": false
};
export default DiscountRedeemCode;