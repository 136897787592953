
const DeliveryProfileLocationGroup = {
  "name": "DeliveryProfileLocationGroup",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "countriesInAnyZone": "DeliveryCountryAndZone",
    "locationGroup": "DeliveryLocationGroup",
    "locationGroupZones": "DeliveryLocationGroupZoneConnection"
  },
  "implementsNode": false
};
export default DeliveryProfileLocationGroup;