
const AppInstallation = {
  "name": "AppInstallation",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "accessScopes": "AccessScope",
    "activeSubscriptions": "AppSubscription",
    "allSubscriptions": "AppSubscriptionConnection",
    "app": "App",
    "channel": "Channel",
    "credits": "AppCreditConnection",
    "id": "ID",
    "launchUrl": "URL",
    "metafield": "Metafield",
    "metafields": "MetafieldConnection",
    "oneTimePurchases": "AppPurchaseOneTimeConnection",
    "privateMetafield": "PrivateMetafield",
    "privateMetafields": "PrivateMetafieldConnection",
    "publication": "Publication",
    "revenueAttributionRecords": "AppRevenueAttributionRecordConnection",
    "subscriptions": "AppSubscription",
    "uninstallUrl": "URL"
  },
  "implementsNode": true
};
export default AppInstallation;