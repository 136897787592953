
const CompanyLocationConnection = {
  "name": "CompanyLocationConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "CompanyLocationEdge",
    "nodes": "CompanyLocation",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default CompanyLocationConnection;