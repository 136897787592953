
const FinancialSummaryDiscountAllocation = {
  "name": "FinancialSummaryDiscountAllocation",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "approximateAllocatedAmountPerItem": "MoneyBag",
    "discountApplication": "FinancialSummaryDiscountApplication"
  },
  "implementsNode": false
};
export default FinancialSummaryDiscountAllocation;