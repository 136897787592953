
const MetafieldDefinition = {
  "name": "MetafieldDefinition",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "access": "MetafieldAccess",
    "description": "String",
    "id": "ID",
    "key": "String",
    "metafields": "MetafieldConnection",
    "metafieldsCount": "Int",
    "name": "String",
    "namespace": "String",
    "ownerType": "MetafieldOwnerType",
    "pinnedPosition": "Int",
    "standardTemplate": "StandardMetafieldDefinitionTemplate",
    "type": "MetafieldDefinitionType",
    "useAsCollectionCondition": "Boolean",
    "validationStatus": "MetafieldDefinitionValidationStatus",
    "validations": "MetafieldDefinitionValidation",
    "visibleToStorefrontApi": "Boolean"
  },
  "implementsNode": true
};
export default MetafieldDefinition;