
const CustomerSegmentMemberConnection = {
  "name": "CustomerSegmentMemberConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "CustomerSegmentMemberEdge",
    "pageInfo": "PageInfo",
    "statistics": "SegmentStatistics",
    "totalCount": "Int"
  },
  "implementsNode": false
};
export default CustomerSegmentMemberConnection;