
const ReturnCancelPayload = {
  "name": "ReturnCancelPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "return": "Return",
    "userErrors": "ReturnUserError"
  },
  "implementsNode": false
};
export default ReturnCancelPayload;