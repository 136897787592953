
const Translation = {
  "name": "Translation",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "key": "String",
    "locale": "String",
    "market": "Market",
    "outdated": "Boolean",
    "updatedAt": "DateTime",
    "value": "String"
  },
  "implementsNode": false
};
export default Translation;