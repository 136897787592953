
const ReturnConnection = {
  "name": "ReturnConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "ReturnEdge",
    "nodes": "Return",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default ReturnConnection;