
const ShopifyFunction = {
  "name": "ShopifyFunction",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "apiType": "String",
    "apiVersion": "String",
    "app": "App",
    "appBridge": "FunctionsAppBridge",
    "appKey": "String",
    "description": "String",
    "id": "String",
    "inputQuery": "String",
    "title": "String",
    "useCreationUi": "Boolean"
  },
  "implementsNode": false
};
export default ShopifyFunction;