
const DeliveryCountryInput = {
  "name": "DeliveryCountryInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "code": "CountryCode",
    "restOfWorld": "Boolean",
    "provinces": "DeliveryProvinceInput",
    "includeAllProvinces": "Boolean"
  }
};
export default DeliveryCountryInput;