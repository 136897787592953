
const DeliveryCarrierServiceAndLocations = {
  "name": "DeliveryCarrierServiceAndLocations",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "carrierService": "DeliveryCarrierService",
    "locations": "Location"
  },
  "implementsNode": false
};
export default DeliveryCarrierServiceAndLocations;