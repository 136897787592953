
const CartDeliveryOption = {
  "name": "CartDeliveryOption",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "String",
    "deliveryMethodType": "DeliveryMethodType",
    "description": "String",
    "estimatedCost": "MoneyV2",
    "handle": "String",
    "title": "String"
  },
  "implementsNode": false
};
export default CartDeliveryOption;