
const PriceListFixedPricesDeletePayload = {
  "name": "PriceListFixedPricesDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedFixedPriceVariantIds": "ID",
    "userErrors": "PriceListPriceUserError"
  },
  "implementsNode": false
};
export default PriceListFixedPricesDeletePayload;