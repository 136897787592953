
const DiscountCodeAppUpdatePayload = {
  "name": "DiscountCodeAppUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "codeAppDiscount": "DiscountCodeApp",
    "userErrors": "DiscountUserError"
  },
  "implementsNode": false
};
export default DiscountCodeAppUpdatePayload;