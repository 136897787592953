
const MarketCatalogEdge = {
  "name": "MarketCatalogEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "MarketCatalog"
  },
  "implementsNode": false
};
export default MarketCatalogEdge;