
const SellingPlanFixedDeliveryPolicyInput = {
  "name": "SellingPlanFixedDeliveryPolicyInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "anchors": "SellingPlanAnchorInput",
    "fulfillmentTrigger": "SellingPlanFulfillmentTrigger",
    "fulfillmentExactTime": "DateTime",
    "cutoff": "Int",
    "intent": "SellingPlanFixedDeliveryPolicyIntent",
    "preAnchorBehavior": "SellingPlanFixedDeliveryPolicyPreAnchorBehavior"
  }
};
export default SellingPlanFixedDeliveryPolicyInput;