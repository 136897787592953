
const CashTrackingSessionConnection = {
  "name": "CashTrackingSessionConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "CashTrackingSessionEdge",
    "nodes": "CashTrackingSession",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default CashTrackingSessionConnection;