
const CollectionPublication = {
  "name": "CollectionPublication",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "channel": "Channel",
    "collection": "Collection",
    "isPublished": "Boolean",
    "publication": "Publication",
    "publishDate": "DateTime"
  },
  "implementsNode": false
};
export default CollectionPublication;