
const LinkedMetafieldCreateInput = {
  "name": "LinkedMetafieldCreateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "namespace": "String",
    "key": "String",
    "values": "String"
  }
};
export default LinkedMetafieldCreateInput;