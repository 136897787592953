
const MarketLocalizableResource = {
  "name": "MarketLocalizableResource",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "marketLocalizableContent": "MarketLocalizableContent",
    "marketLocalizations": "MarketLocalization",
    "resourceId": "ID"
  },
  "implementsNode": false
};
export default MarketLocalizableResource;