
const ResourceAlertAction = {
  "name": "ResourceAlertAction",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "primary": "Boolean",
    "show": "String",
    "title": "String",
    "url": "URL"
  },
  "implementsNode": false
};
export default ResourceAlertAction;