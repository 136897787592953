
const DraftOrderAppliedDiscount = {
  "name": "DraftOrderAppliedDiscount",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "amount": "Money",
    "amountSet": "MoneyBag",
    "amountV2": "MoneyV2",
    "description": "String",
    "title": "String",
    "value": "Float",
    "valueType": "DraftOrderAppliedDiscountType"
  },
  "implementsNode": false
};
export default DraftOrderAppliedDiscount;