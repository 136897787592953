import { getNetlifySnippetByTitle } from "../../querys/netlify-rest";
import { toast } from "react-toastify";

const collectionIdsSnippetTitle = "Client's Categories"

export const fetchCollectionsFromSnippet = (reseller_site_id, fields) => {
    let collections = [];
    return getNetlifySnippetByTitle(reseller_site_id, collectionIdsSnippetTitle)
        .then(async (response) => {
            if (response.id && response.general) {
                const collectionIDs = JSON.parse(response.general.replace("<script>window.cat=", "").replace("</script>", ""));

                for (let i = 0; i < collectionIDs.length; i++) {

                    const id = collectionIDs[i];

                    const response = await fetch('/.netlify/functions/server/api/get-collection-by-id', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ id, fields })
                    });
                    try {
                        
                        if (!response.ok) throw response;

                        const data = await response.json();

                        if (data.collection) collections.push(data.collection);
                    } catch (err) {
                        //Show response error
                        if (err.message) {
                            toast.error(`${err.message}`);
                        } else {
                            toast.error(`${err.status}: ${err.statusText}`);
                        }
                    }

                }

            }

            return collections;

        })
        .catch(error => { throw error })
}