
const AppRecurringPricing = {
  "name": "AppRecurringPricing",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "discount": "AppSubscriptionDiscount",
    "interval": "AppPricingInterval",
    "price": "MoneyV2"
  },
  "implementsNode": false
};
export default AppRecurringPricing;