
const FulfillmentOriginAddress = {
  "name": "FulfillmentOriginAddress",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "address1": "String",
    "address2": "String",
    "city": "String",
    "countryCode": "String",
    "provinceCode": "String",
    "zip": "String"
  },
  "implementsNode": false
};
export default FulfillmentOriginAddress;