
const RefundCreatePayload = {
  "name": "RefundCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "order": "Order",
    "refund": "Refund",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default RefundCreatePayload;