
const MarketingEngagement = {
  "name": "MarketingEngagement",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "adSpend": "MoneyV2",
    "channelHandle": "String",
    "clicksCount": "Int",
    "commentsCount": "Int",
    "complaintsCount": "Int",
    "failsCount": "Int",
    "favoritesCount": "Int",
    "firstTimeCustomers": "Decimal",
    "impressionsCount": "Int",
    "isCumulative": "Boolean",
    "marketingActivity": "MarketingActivity",
    "occurredOn": "Date",
    "orders": "Decimal",
    "returningCustomers": "Decimal",
    "sales": "MoneyV2",
    "sendsCount": "Int",
    "sessionsCount": "Int",
    "sharesCount": "Int",
    "uniqueClicksCount": "Int",
    "uniqueViewsCount": "Int",
    "unsubscribesCount": "Int",
    "utcOffset": "UtcOffset",
    "viewsCount": "Int"
  },
  "implementsNode": false
};
export default MarketingEngagement;