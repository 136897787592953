
const AppUsagePricing = {
  "name": "AppUsagePricing",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "balanceUsed": "MoneyV2",
    "cappedAmount": "MoneyV2",
    "interval": "AppPricingInterval",
    "terms": "String"
  },
  "implementsNode": false
};
export default AppUsagePricing;