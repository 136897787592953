
const FulfillmentOrderLocationForMove = {
  "name": "FulfillmentOrderLocationForMove",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "availableLineItems": "FulfillmentOrderLineItemConnection",
    "availableLineItemsCount": "Count",
    "location": "Location",
    "message": "String",
    "movable": "Boolean",
    "unavailableLineItems": "FulfillmentOrderLineItemConnection",
    "unavailableLineItemsCount": "Count"
  },
  "implementsNode": false
};
export default FulfillmentOrderLocationForMove;