
const MetaobjectFieldDefinitionUpdateInput = {
  "name": "MetaobjectFieldDefinitionUpdateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "key": "String",
    "name": "String",
    "description": "String",
    "required": "Boolean",
    "validations": "MetafieldDefinitionValidationInput"
  }
};
export default MetaobjectFieldDefinitionUpdateInput;