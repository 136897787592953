
const MediaWarning = {
  "name": "MediaWarning",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "MediaWarningCode",
    "message": "String"
  },
  "implementsNode": false
};
export default MediaWarning;