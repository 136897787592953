
const OptionValueUpdateInput = {
  "name": "OptionValueUpdateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "id": "ID",
    "name": "String",
    "linkedMetafieldValue": "String"
  }
};
export default OptionValueUpdateInput;