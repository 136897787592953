
const CheckoutProfileEdge = {
  "name": "CheckoutProfileEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "CheckoutProfile"
  },
  "implementsNode": false
};
export default CheckoutProfileEdge;