
const InventorySetQuantityInput = {
  "name": "InventorySetQuantityInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "inventoryItemId": "ID",
    "locationId": "ID",
    "quantity": "Int"
  }
};
export default InventorySetQuantityInput;