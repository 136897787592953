
const Link = {
  "name": "Link",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "label": "String",
    "translations": "Translation",
    "url": "URL"
  },
  "implementsNode": false
};
export default Link;