
const SegmentValue = {
  "name": "SegmentValue",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "localizedValue": "String",
    "queryName": "String"
  },
  "implementsNode": false
};
export default SegmentValue;