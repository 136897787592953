
const SubscriptionDeliveryMethodLocalDeliveryOptionInput = {
  "name": "SubscriptionDeliveryMethodLocalDeliveryOptionInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "title": "String",
    "presentmentTitle": "String",
    "description": "String",
    "code": "String",
    "phone": "String",
    "instructions": "String"
  }
};
export default SubscriptionDeliveryMethodLocalDeliveryOptionInput;