
const SubscriptionDeliveryPolicy = {
  "name": "SubscriptionDeliveryPolicy",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "anchors": "SellingPlanAnchor",
    "interval": "SellingPlanInterval",
    "intervalCount": "Int"
  },
  "implementsNode": false
};
export default SubscriptionDeliveryPolicy;