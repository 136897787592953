import React, { useContext, useEffect, useRef, useState } from 'react';
import { X, Check } from 'react-feather';
import { Alert } from "reactstrap";
import { AuthContext } from '../../../auth/auth';
import { addNetlifySnippet, getNetlifySiteSettings, getNetlifySnippetByTitle, updateNetlifySiteSettings, updateNetlifySnippet } from "../../../querys/netlify-rest";
import { encodeSnippet } from "../../utils/snippets";

export const CustomDomain = () => {
  const [defaultDomain, setDefaultDomain] = useState("");
  const [domainAlias, setDomainAlias] = useState("");
  const [inputError, setInputError] = useState(false);
  const siteId = localStorage.getItem("whitelabelResellerSiteId") || sessionStorage.getItem("whitelabelResellerSiteId");
  const inputValue = useRef("");
  const context = useContext(AuthContext)

  const isApexDomain = (domain) => {
    const count = [...domain].filter(char => char === ".").length;
    if (count === 1) {
      return true;
    }
    return false;
  };

  const subdomainSnippetExists = async () => {
    const response = await getNetlifySnippetByTitle(siteId, "Subdomain");
    if (response.id && response.general) {
      return response.id;
    }
    return null;
  }
  
  const customSubdomainSnippetExists = async () => {
    const response = await getNetlifySnippetByTitle(siteId, "CustomSubdomain");
    if (response.id && response.general) {
      return response.id;
    }
    return null;
  }

  const handleSubmit = async (userInput) => {
    setInputError(false);
    let error = false;
    let netlifySnippetUpdate = null;
    let domainAliasesUpdate = null;
    let primaryDomainUpdate = null;
    const validDomain = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;

    if (validDomain.test(userInput) === true) {
      const siteSettings = await getNetlifySiteSettings(siteId);
      const response = JSON.parse(siteSettings.request.response);
      const originalDomain = response.custom_domain;
      const originalSubdomain = response.custom_domain.split(".")[0];
      const snippetId = await subdomainSnippetExists();
      const customSubdomainSnippetId = await customSubdomainSnippetExists();

      if (snippetId !== null) {
        netlifySnippetUpdate = await updateNetlifySnippet(siteId, "Subdomain", encodeSnippet(originalSubdomain, "subdomain"), snippetId);
      } else {
        netlifySnippetUpdate = await addNetlifySnippet(siteId, "Subdomain", encodeSnippet(originalSubdomain, "subdomain"))
      }

      if (netlifySnippetUpdate.status === 200 || netlifySnippetUpdate.status === 201) {
        domainAliasesUpdate = await updateNetlifySiteSettings(siteId, [{
          title: "domain_aliases",
          value: [userInput, originalDomain]
        }]);

        const domainAliasesJSON = JSON.parse(domainAliasesUpdate.request.response);

        if (domainAliasesJSON && domainAliasesJSON.domain_aliases[0] === userInput) {
          let customDomain;
          if (isApexDomain(userInput)) {
            customDomain = {
              title: "custom_domain",
              value: "www." + userInput
            };
          } else {
            customDomain = {
              title: "custom_domain",
              value: userInput
            };
          }

          primaryDomainUpdate = await updateNetlifySiteSettings(siteId, [customDomain]);
          const primaryDomainJSON = JSON.parse(primaryDomainUpdate.request.response);
          if (primaryDomainJSON.custom_domain === customDomain.value) {
            if (customSubdomainSnippetId !== null) {
              netlifySnippetUpdate = await updateNetlifySnippet(siteId, "CustomSubdomain", encodeSnippet(customDomain.value, "customSubdomain"), snippetId);
            } else {
              netlifySnippetUpdate = await addNetlifySnippet(siteId, "CustomSubdomain", encodeSnippet(customDomain.value, "customSubdomain"));
            }
            if (netlifySnippetUpdate.status === 200 || netlifySnippetUpdate.status === 201) {
              setDomainAlias(userInput);
              context.setCustomSubdomain(userInput);
              setInputError(false);
              return true;
            } else {
              return false;
            }
          }
        }
      }
      error = true;
    } else {
      error = true;
    }
    if (error) {
      setInputError(false);
      setTimeout(() => setInputError(true), 750);
    }
  };

  useEffect(() => {
    if (context.custom_subdomain) {
      setDomainAlias(context.custom_subdomain);
    } else {
      getNetlifySiteSettings(siteId)
      .then(result => {
        const response = JSON.parse(result.request.response);
        if (response.domain_aliases.length > 0) {
          response.domain_aliases.forEach(alias => {
            if (alias.indexOf("whitelabellabels.com") === -1 && alias.indexOf("readysetprint.com") === -1) {
              setDomainAlias(alias);
            }
          });
        }
        setDefaultDomain(response.name + ".netlify.app");
      })
      .catch(error => error);
    }
  }, [domainAlias, siteId]);

  return (
    <div className="custom-domain">
      <div className="d-sm-flex mb-3 mb-md-4 align-items-center">
        <div className="flex-grow-1">
          <div className="row">
            <div className="col-sm-8 mb-3 d-flex">
              <input 
                type="text" 
                className={domainAlias ? "form-control disabled" : "form-control"} 
                placeholder="Verfiy your domain setup"
                ref={inputValue}
                defaultValue={domainAlias ? domainAlias : ""}
              />
              <div className="custom-domain-status">
                {
                  domainAlias ?
                  (
                    <div className="status-connected">
                      <span className="left-text"> Connected </span>
                      <span className="right-symbol"><Check size={14}/></span>
                    </div>
                  ) :
                  (
                    <div className="status-disconnected">
                      <span className="left-text"> Not connected </span>
                      <span className="right-symbol"><X size={14}/></span>
                    </div>
                  )
                }
              </div>
            </div>
            <div className="col-sm-4 mb-3 d-flex">
              <button className={domainAlias ? "btn btn-primary disabled ml-sm-3" : "btn btn-outline-primary ml-sm-3"} onClick={domainAlias ? null : () => handleSubmit(inputValue.current.value)} >
                {domainAlias ? "Verified" : "Verify"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Alert color={"danger"} isOpen={inputError} toggle={() => setInputError(false)}>Invalid input. Please enter a valid domain.</Alert>
      <div className="domain-statement">
        <p className="mb-4">The status above does not necessarily indicate that your custom domain's DNS provider settings are correctly configured. It only shows if any custom domains are associated with your storefront. If your custom domain is still not valid even after verifying, please check your DNS provider settings. Depending on your DNS provider, changes to DNS records can take several hours to propagate and take effect for the entire internet.</p>
        <p className="mb-4">Do not include the http or https protocol when verifying your custom domain.</p>
        <h6 className="mb-3">If using a subdomain, such as <code>store.exampledomain.com</code> : </h6>
        <p>Find your DNS provider's DNS record settings for your apex domain. In those settings:</p>
        <ol className="pt-1">
          <li>Add an CNAME record with your subdomain as the host (<code>store</code> in this example).</li>
          <li>Point the record to your Netlify subdomain, <code>{defaultDomain}</code></li>
          <li>Save your settings. It may take a full day for the settings to propagate across the global Domain Name System.</li>
          <li>Enter your custom domain in the input box above and click Verify.</li>
        </ol>
        <h6 className="mb-3">If using an apex domain, such as <code>exampledomain.com</code> : </h6>
        <p>Find your DNS provider's DNS record settings for your apex domain. In those settings:</p>
        <ol className="pt-1">
          <li>Add an A record. Either leave the "Name" field blank or enter <code>@</code>. Follow your provider's instructions for this process. Point this record to Netlify's load balancer IP address: <code>75.2.60.5</code></li>
          <li>Add a CNAME record, using <code>www</code> for the "Name" field. Point the record to your Netlify subdomain, <code>{defaultDomain}</code></li>
          <li>Save your settings. It may take a full day for the settings to propagate across the global Domain Name System.</li>
          <li>Enter your custom domain in the input box above and click Verify.</li>
        </ol>
        <h6 className="netlify-domain-info mb-3"><a href="https://docs.netlify.com/domains-https/custom-domains/configure-external-dns/" target="_blank" rel="noopener noreferrer">Read more about using custom domains with Netlify here.</a></h6>
      </div>
    </div>
  )
};
