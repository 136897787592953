
const OptionCreateInput = {
  "name": "OptionCreateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "name": "String",
    "position": "Int",
    "values": "OptionValueCreateInput",
    "linkedMetafield": "LinkedMetafieldCreateInput"
  }
};
export default OptionCreateInput;