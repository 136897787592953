
const MarketingActivityUpdateExternalPayload = {
  "name": "MarketingActivityUpdateExternalPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "marketingActivity": "MarketingActivity",
    "userErrors": "MarketingActivityUserError"
  },
  "implementsNode": false
};
export default MarketingActivityUpdateExternalPayload;