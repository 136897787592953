
const ValidationDeletePayload = {
  "name": "ValidationDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedId": "ID",
    "userErrors": "ValidationUserError"
  },
  "implementsNode": false
};
export default ValidationDeletePayload;