
const CustomerMergePreviewBlockingFields = {
  "name": "CustomerMergePreviewBlockingFields",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "note": "String",
    "tags": "String"
  },
  "implementsNode": false
};
export default CustomerMergePreviewBlockingFields;