
const ObjectDimensionsInput = {
  "name": "ObjectDimensionsInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "length": "Float",
    "width": "Float",
    "height": "Float",
    "unit": "LengthUnit"
  }
};
export default ObjectDimensionsInput;