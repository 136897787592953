
const CompanyContactAssignRolePayload = {
  "name": "CompanyContactAssignRolePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "companyContactRoleAssignment": "CompanyContactRoleAssignment",
    "userErrors": "BusinessCustomerUserError"
  },
  "implementsNode": false
};
export default CompanyContactAssignRolePayload;