
const WebPixel = {
  "name": "WebPixel",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "settings": "JSON"
  },
  "implementsNode": true
};
export default WebPixel;