
const ShippingLineInput = {
  "name": "ShippingLineInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "price": "Money",
    "priceWithCurrency": "MoneyInput",
    "shippingRateHandle": "String",
    "title": "String"
  }
};
export default ShippingLineInput;