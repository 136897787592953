
const MetafieldStorefrontVisibilityInput = {
  "name": "MetafieldStorefrontVisibilityInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "namespace": "String",
    "key": "String",
    "ownerType": "MetafieldOwnerType"
  }
};
export default MetafieldStorefrontVisibilityInput;