
const UrlRedirectBulkDeleteByIdsPayload = {
  "name": "UrlRedirectBulkDeleteByIdsPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "job": "Job",
    "userErrors": "UrlRedirectBulkDeleteByIdsUserError"
  },
  "implementsNode": false
};
export default UrlRedirectBulkDeleteByIdsPayload;