
const CartAutomaticDiscountAllocation = {
  "name": "CartAutomaticDiscountAllocation",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "discountedAmount": "MoneyV2",
    "title": "String"
  },
  "implementsNode": false
};
export default CartAutomaticDiscountAllocation;