
const ReverseDeliveryDisposePayload = {
  "name": "ReverseDeliveryDisposePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "reverseDeliveryLineItems": "ReverseDeliveryLineItem",
    "userErrors": "ReturnUserError"
  },
  "implementsNode": false
};
export default ReverseDeliveryDisposePayload;