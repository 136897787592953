
const InventorySetScheduledChangesInput = {
  "name": "InventorySetScheduledChangesInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "reason": "String",
    "items": "InventoryScheduledChangeItemInput",
    "referenceDocumentUri": "URL"
  }
};
export default InventorySetScheduledChangesInput;