
const PriceListFixedPricesUpdatePayload = {
  "name": "PriceListFixedPricesUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedFixedPriceVariantIds": "ID",
    "priceList": "PriceList",
    "pricesAdded": "PriceListPrice",
    "userErrors": "PriceListPriceUserError"
  },
  "implementsNode": false
};
export default PriceListFixedPricesUpdatePayload;