
const AppDiscountType = {
  "name": "AppDiscountType",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "app": "App",
    "appBridge": "FunctionsAppBridge",
    "appKey": "String",
    "description": "String",
    "discountClass": "DiscountClass",
    "functionId": "String",
    "targetType": "DiscountApplicationTargetType",
    "title": "String"
  },
  "implementsNode": false
};
export default AppDiscountType;