
const CustomerActivateByUrlPayload = {
  "name": "CustomerActivateByUrlPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customer": "Customer",
    "customerAccessToken": "CustomerAccessToken",
    "customerUserErrors": "CustomerUserError"
  },
  "implementsNode": false
};
export default CustomerActivateByUrlPayload;