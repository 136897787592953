
const CustomerPaymentInstrumentBillingAddress = {
  "name": "CustomerPaymentInstrumentBillingAddress",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "address1": "String",
    "city": "String",
    "country": "String",
    "countryCode": "CountryCode",
    "name": "String",
    "province": "String",
    "provinceCode": "String",
    "zip": "String"
  },
  "implementsNode": false
};
export default CustomerPaymentInstrumentBillingAddress;