
const DiscountAutomaticNodeEdge = {
  "name": "DiscountAutomaticNodeEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "DiscountAutomaticNode"
  },
  "implementsNode": false
};
export default DiscountAutomaticNodeEdge;