
const DiscountCustomerSelectionInput = {
  "name": "DiscountCustomerSelectionInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "all": "Boolean",
    "customers": "DiscountCustomersInput",
    "customerSegments": "DiscountCustomerSegmentsInput"
  }
};
export default DiscountCustomerSelectionInput;