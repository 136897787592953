
const StandardMetafieldDefinitionTemplateConnection = {
  "name": "StandardMetafieldDefinitionTemplateConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "StandardMetafieldDefinitionTemplateEdge",
    "nodes": "StandardMetafieldDefinitionTemplate",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default StandardMetafieldDefinitionTemplateConnection;