
const MutationsStagedUploadTargetGenerateUploadParameter = {
  "name": "MutationsStagedUploadTargetGenerateUploadParameter",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "name": "String",
    "value": "String"
  },
  "implementsNode": false
};
export default MutationsStagedUploadTargetGenerateUploadParameter;