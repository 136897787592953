
const BulkOperationRunMutationPayload = {
  "name": "BulkOperationRunMutationPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "bulkOperation": "BulkOperation",
    "userErrors": "BulkMutationUserError"
  },
  "implementsNode": false
};
export default BulkOperationRunMutationPayload;