
const ShippingLine = {
  "name": "ShippingLine",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "carrierIdentifier": "String",
    "code": "String",
    "custom": "Boolean",
    "deliveryCategory": "String",
    "discountAllocations": "DiscountAllocation",
    "discountedPrice": "MoneyV2",
    "discountedPriceSet": "MoneyBag",
    "id": "ID",
    "isRemoved": "Boolean",
    "originalPrice": "MoneyV2",
    "originalPriceSet": "MoneyBag",
    "phone": "String",
    "price": "Money",
    "requestedFulfillmentService": "FulfillmentService",
    "shippingRateHandle": "String",
    "source": "String",
    "taxLines": "TaxLine",
    "title": "String"
  },
  "implementsNode": false
};
export default ShippingLine;