
const SellingPlanFixedPricingPolicyInput = {
  "name": "SellingPlanFixedPricingPolicyInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "id": "ID",
    "adjustmentType": "SellingPlanPricingPolicyAdjustmentType",
    "adjustmentValue": "SellingPlanPricingPolicyValueInput"
  }
};
export default SellingPlanFixedPricingPolicyInput;