
const GiftCardCreatePayload = {
  "name": "GiftCardCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "giftCard": "GiftCard",
    "giftCardCode": "String",
    "userErrors": "GiftCardUserError"
  },
  "implementsNode": false
};
export default GiftCardCreatePayload;