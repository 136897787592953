import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/bread-crumb";
import { getNetlifySnippetByTitle, addNetlifySnippet } from '../../querys/netlify-rest';
import { encodeSnippet, decodeSnippet } from '../utils/snippets'
import { AuthContext } from "../../auth/auth";
import MenuItems from "./menu-items";
import { updateDefaultMenuWithSubdomain } from "./utils";

export class MenuList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menus: [],
      snippet: {
        value: null,
        netlifySnippetId: null,
        netlifySnippetContent: null,
      }
    }

  }

  componentDidMount = () => {
    this.fetchMenuSnippet();
    window.analytics.page();
  }

  fetchMenuSnippet = () => {

    getNetlifySnippetByTitle(this.context.reseller_site_id, "Menu")
      .then((response) => {
        if (response.id && response.general) {
          // Update the state with the Netlify snippet data
          //Decode snippet
          const menus = decodeSnippet(response.general, "Menu")

          this.setState({
            snippet: {
              value: response.general,
              netlifySnippetId: response.id,
              netlifySnippetContent: response.general,
            },
            menus
          });
        }
      });
  }

  render() {
    if (this.context.siteSetupStatus === "BUILDING") return <p>We are activating your menus. Please wait.</p>
    const { menus, snippet } = this.state
    return (
      <Fragment>
        <Breadcrumb title="Menu list" parent="Navigation" />
        <div className="container-fluid">
          <div className="card">
            <div className="card-header mb-0 pb-0">
              <h5>Menus</h5>
            </div>
            <div className="card-body pt-0">
              <div className="row">
                <div className="col-sm-12 col-md-3 container px-3 pr-md-5 mt-4">
                  <p className="text-secondary mb-1">
                    Menus, or link lists, help your customers navigate
                    around your online store.
                  </p>
                  <p className="text-secondary mb-1">Your store supports 2 menu variations.</p>
                </div>
                <div className="col-sm-12 pl-md-5 col-md-9 mt-4">
                  <MenuItems menus={menus.filter(m => m.id !== "footer-menu1" && m.id !== "footer-menu2")} snippet={snippet} />
                </div>
              </div>
            </div>
          </div>
        </div>

      </Fragment >
    );
  }
}

MenuList.contextType = AuthContext

export default MenuList;
