
const SegmentEdge = {
  "name": "SegmentEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "Segment"
  },
  "implementsNode": false
};
export default SegmentEdge;