
const PubSubWebhookSubscriptionUpdatePayload = {
  "name": "PubSubWebhookSubscriptionUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "userErrors": "PubSubWebhookSubscriptionUpdateUserError",
    "webhookSubscription": "WebhookSubscription"
  },
  "implementsNode": false
};
export default PubSubWebhookSubscriptionUpdatePayload;