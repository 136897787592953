
const InventoryChange = {
  "name": "InventoryChange",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "delta": "Int",
    "item": "InventoryItem",
    "ledgerDocumentUri": "String",
    "location": "Location",
    "name": "String",
    "quantityAfterChange": "Int"
  },
  "implementsNode": false
};
export default InventoryChange;