
const CustomerPaymentMethod = {
  "name": "CustomerPaymentMethod",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customer": "Customer",
    "id": "ID",
    "instrument": "CustomerPaymentInstrument",
    "revokedAt": "DateTime",
    "revokedReason": "CustomerPaymentMethodRevocationReason",
    "subscriptionContracts": "SubscriptionContractConnection"
  },
  "implementsNode": true
};
export default CustomerPaymentMethod;