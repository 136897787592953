
const SaleTax = {
  "name": "SaleTax",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "amount": "MoneyBag",
    "id": "ID",
    "taxLine": "TaxLine"
  },
  "implementsNode": false
};
export default SaleTax;