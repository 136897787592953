
const ExchangeV2Additions = {
  "name": "ExchangeV2Additions",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "lineItems": "ExchangeV2LineItem",
    "subtotalPriceSet": "MoneyBag",
    "taxLines": "TaxLine",
    "totalPriceSet": "MoneyBag"
  },
  "implementsNode": false
};
export default ExchangeV2Additions;