import React from 'react';
import { ReactComponent as CloseIcon } from "../../../assets/elegant_font/images/SVG/icon_close_alt2.svg";
import Modal from 'react-responsive-modal';
import { AvField, AvForm } from "availity-reactstrap-validation";

const labelStyles = {
  fontWeight: 700,
  paddingLeft: '2px',
  marginBottom: '6px'
};

const buttonStyles = {
  fontSize: '10px',
  minWidth: '86px',
  height: '30px',
  padding: '0px',
  marginTop: '14px'
};

const PersonFormModal = ({ isOpen, onCloseModal, personInfo, handleSubmit }) => {

  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      showCloseIcon={false}
      styles={{
        modal: {
          borderRadius: "4px",
          overflow: "hidden",
          width: '640px',
          padding: '30px'
        },
      }}
      center
    >
      <div className="d-flex justify-content-between">
        <h5
          style={{ color: "black", fontSize: "18px", paddingTop: '2px', marginBottom: '13px' }}>
          {personInfo.isNew ? "Add Admin" : "Edit Admin"}
        </h5>
        <a
          href="javascript:void(0)"
          className="text-primary"
          onClick={onCloseModal}
        ><CloseIcon width="24" height="24" /></a>
      </div>

      <AvForm onValidSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label style={labelStyles}>First Name</label>
              <AvField name="firstName" type="text" value={personInfo.firstName} className="form-control" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label style={labelStyles}>Last Name</label>
              <AvField name="lastName" type="text" value={personInfo.lastName} className="form-control" />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label style={labelStyles}>Email</label>
          <AvField name="email" type="text" value={personInfo.email} className="form-control" />
        </div>
        <div className="form-group">
          <label style={labelStyles}>Temporary Password</label>
          <AvField name="password" type="password" className="form-control" />
        </div>
        <button className="btn btn-outline-dark mr-3" style={buttonStyles} onClick={onCloseModal}> Cancel </button>
        <button className="btn btn-primary" style={buttonStyles}> Save </button>
      </AvForm>
    </Modal>

  )
};

export default PersonFormModal;