
const DeliveryCustomizationCreatePayload = {
  "name": "DeliveryCustomizationCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deliveryCustomization": "DeliveryCustomization",
    "userErrors": "DeliveryCustomizationError"
  },
  "implementsNode": false
};
export default DeliveryCustomizationCreatePayload;