
const CustomerSmsMarketingConsentInput = {
  "name": "CustomerSmsMarketingConsentInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "marketingOptInLevel": "CustomerMarketingOptInLevel",
    "marketingState": "CustomerSmsMarketingState",
    "consentUpdatedAt": "DateTime"
  }
};
export default CustomerSmsMarketingConsentInput;