
const CustomerPaymentMethodPaypalBillingAgreementCreatePayload = {
  "name": "CustomerPaymentMethodPaypalBillingAgreementCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customerPaymentMethod": "CustomerPaymentMethod",
    "userErrors": "CustomerPaymentMethodUserError"
  },
  "implementsNode": false
};
export default CustomerPaymentMethodPaypalBillingAgreementCreatePayload;