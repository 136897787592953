
const DiscountCodeBulkActivatePayload = {
  "name": "DiscountCodeBulkActivatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "job": "Job",
    "userErrors": "DiscountUserError"
  },
  "implementsNode": false
};
export default DiscountCodeBulkActivatePayload;