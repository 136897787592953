
const FileDeletePayload = {
  "name": "FileDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedFileIds": "ID",
    "userErrors": "FilesUserError"
  },
  "implementsNode": false
};
export default FileDeletePayload;