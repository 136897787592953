
const ProductFullSyncUserError = {
  "name": "ProductFullSyncUserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "ProductFullSyncUserErrorCode",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
export default ProductFullSyncUserError;