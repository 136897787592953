import React, { Component, Fragment } from "react";
import Breadcrumb from "../../components/common/bread-crumb";
import PersonCard from './components/person-card';
import PersonFormModal from './components/person-form-modal';
import { AuthContext } from '../../auth/auth';
import { Alert } from "reactstrap";
import Modal from 'react-responsive-modal';
import { XCircle } from "react-feather";
import { CustomDomain } from './components/custom-domain';
import { storefrontClient } from "../../index";
import * as storefrontQuerys from "../../querys/storefront-graphql";
import StripeSettings from "./components/stripe-settings";
import { toast } from "react-toastify";
import CustomerServiceEmail from "./components/customer-service-email";

class SettingPreferences extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      loadingAdmins: true,
      personInfo: {
        isNew: true,
        firstName: "",
        lastName: "",
        email: ""
      },
      alert: false,
      alertMessage: "",
      alertColor: "success",
      modalOpen: false
    };
  }

  componentDidMount = () => {
    //Use context to avoid re-fetching on every load.
    const { admins } = this.context;

    if (admins.length <= 0) {
      this.fetchAdmins();
    } else {
      this.setState({
        loadingAdmins: false
      });
    }

    window.analytics.page();
  }

  fetchAdmins = async () => {

    //Fetch customers with the admin tag set to the reseller's store subdomain.
    const fields = `
        customers (first: 3, query:"tag:admin==${this.context.store_subdomain}") {
            edges {
                cursor
                node {
                    id
                    lastName
                    firstName
                    email
                    phone
                    tags
                    image {
                      id
                      originalSrc
                    }
                }
            }
        }`

    const response = await fetch('/.netlify/functions/server/api/get-customers', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fields })
    });
    try {
      if (response.status === 500) throw new Error("Couldn't retrieve admins. Please try reloading this page.");

      if (!response.ok) throw response;

      const data = await response.json();

      if (data.customers) {

        this.context.setAdmins(data.customers.edges);

        this.setState(() => ({
          loadingAdmins: false
        }))
      }
    } catch (err) {
      //Show response error

      if (err.message) { 
        this.setState({
          alertMessage: err.message,
          alert: true,
          alertColor: "danger"
        });
      } else {
        this.setState({
          alertMessage: err.statusMessage,
          alert: true,
          alertColor: "danger"
        });
      }
    }
  }

  handleCloseModal = () => {
    this.setState({
      isOpen: false
    });
  }

  handleOpenModal = (personInfo) => {

    const { admins } = this.context;

    if (admins.length < 3) {

      this.setState({
        isOpen: true,
        personInfo: personInfo
      });

    } else { //Show error Alert
      this.setState({
        alert: true,
        alertMessage: "Error: You have already invited the maximum number of admins to your store.",
        alertColor: "danger"
      });
    }

  }

  handleSubmit = async (_ev, values) => {

    //Show alert of progress to avoid keeping the modal open for too long.
    this.setState({
      alert: true,
      alertMessage: `Adding new admin...`,
      alertColor: "secondary",
      isOpen: false
    });

    let { admins } = this.context;

    // Check if customer with that email already exists.
    const fields = `
    customers (first: 1, query:"email:'${values.email}' AND tag:'reseller'") {
        edges {
            cursor
            node {
                id
                lastName
                firstName
                email
                phone
                tags
                image {
                  id
                  originalSrc
                }
            }
        }
    }`;

    const response = await fetch('/.netlify/functions/server/api/get-customers', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fields })
    });
    let data = null;
    try {
      if (!response.ok) throw response;
      data = await response.json();
    } catch (err) {
      //Show response error
      if (err.message) { 
        this.setState({
          alertMessage: err.message,
          alert: true,
          alertColor: "danger"
        });
      } else {
        this.setState({
          alertMessage: err.statusMessage,
          alert: true,
          alertColor: "danger"
        });
      }
    }

    // If admin already exists as a customer.
    if (data.customers && data.customers.edges.length > 0) {
      const adminCustomer = data.customers.edges[0].node;

      // If customer not already added as an admin.
      if (admins.findIndex(a => a.node.id === adminCustomer.id) < 0) {

        const { store_subdomain, reseller_site_id, reseller_collection_id, reseller_collection_handle } = this.context;
        const adminId = adminCustomer.id.replace('gid://shopify/Customer/', '')

        //Update customer's tags to admin tags.
        const adminTagsResponse = await fetch('/.netlify/functions/server/api/add-admin-tags', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id: adminId, subdomain: store_subdomain, siteId: reseller_site_id, collectionId: reseller_collection_id, collectionHandle: reseller_collection_handle })
        });

        try {
          if (!adminTagsResponse.ok) throw new Error(`${adminTagsResponse.status}: Error while adding new admin. Please try again.`)
        } catch (err) {
          this.setState({
            alertMessage: err.message,
            alert: true,
            alertColor: "danger"
          })
        }
        adminCustomer.tags.push(`admin=${store_subdomain}`)

        admins.push({ node: adminCustomer })

        this.context.setAdmins(admins);

        this.setState({
          alertMessage: `Success: ${values.firstName} ${values.lastName} with email ${values.email} was added as an admin.`,
          alertColor: "success",
        })

      } else {
        this.setState({
          alertMessage: `Error: ${values.firstName} ${values.lastName} with email ${values.email} is already an admin.`,
          alertColor: "danger",
        });
      }
    }
    // Create new customer with admin tags and password.
    else {

      const password = values.password;

      delete values.password;

      const input = {
        ...values,
        tags: [
          `admin=${this.context.store_subdomain}`,
          `reseller`,
          `reseller_collection_handle=${this.context.reseller_collection_handle}`,
          `reseller_collection_id=${this.context.reseller_collection_id}`,
          `reseller_site_id=${this.context.reseller_site_id}`
        ]
      };

      const newAdmin = await fetch('/.netlify/functions/server/api/add-customer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ input })
      });

      let newAdminResponse = null;
      try {
        if (!newAdmin.ok) throw new Error(`${newAdmin.status}: Error while adding new admin. Please try again.`)
        newAdminResponse = await newAdmin.json();
      } catch (err) {
        this.setState({
          alertMessage: err.message,
          alert: true,
          alertColor: "danger"
        })
      }

      if (newAdminResponse.customer) {

        const resetPassword = await fetch('/.netlify/functions/server/api/reset-password', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: newAdminResponse.customer.id.replace("gid://shopify/Customer/", ""),
            password,
          })
        });

        if (resetPassword.status == 200) {

          const fetchActivationUrl = await fetch('/.netlify/functions/server/api/customer-generate-activation-url', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              customerId: btoa(newAdminResponse.customer.id)
            })
          });

          if (fetchActivationUrl.status === 200) {

            const activationResponse = await fetchActivationUrl.json();
            const accountActivationUrl = activationResponse.accountActivationUrl;

            storefrontClient.send(storefrontQuerys.customerActivateByUrlMutation(accountActivationUrl, password))
          }

          admins.push({ node: newAdminResponse.customer })

          this.context.setAdmins(admins);

          this.setState({
            alertMessage: `Success: ${values.firstName} ${values.lastName} with email ${values.email} was added as an admin.`,
            alertColor: "success"
          })
        } else {

          this.setState({
            alertMessage: `Error: ${values.firstName} ${values.lastName} with email ${values.email} was not added as an admin. Please check the fields are valid and try again.`,
            alertColor: "danger"
          })
        }
      } else {

        this.setState({
          alertMessage: `Error: ${values.firstName} ${values.lastName} with email ${values.email} was not added as an admin. Please check the fields are valid and try again.`,
          alertColor: "danger"
        })
      }
    }
  }

  toggleAlert = () => {
    this.setState({
      alert: !this.state.alert
    });
  }

  onOpenDeleteModal = (id) => {
    this.setState({
      modalOpen: true,
      removeAdminId: id
    });
  }

  onCloseDeleteModal = () => {
    this.setState({
      modalOpen: false
    });
  }

  removeAdmin = async () => {

    this.setState({
      alert: true,
      alertMessage: `Removing admin...`,
      alertColor: "secondary",
      modalOpen: false
    });

    const { removeAdminId } = this.state;

    const { admins } = this.context;

    let admin = admins.find(a => a.node.id === removeAdminId);

    // Selected admin exists in the context.
    if (admin) {

      let tags = admin.node.tags;

      let tagIndex = tags.findIndex(t => t === `admin=${this.context.store_subdomain}`);
      if (tagIndex > -1) {

        //Remove admin tag.
        tags.splice(tagIndex, 1);

        let data = null;
        const response = await fetch('/.netlify/functions/server/api/update-customer-tags', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ customerId: removeAdminId, tags })
        });

        try {
          if (!response.ok) throw response;

          data = await response.json();
        } catch (err) {
          if (err.message) { 
            this.setState({
              alertMessage: err.message,
              alert: true,
              alertColor: "danger"
            });
          } else {
            this.setState({
              alertMessage: err.statusMessage,
              alert: true,
              alertColor: "danger"
            });
          }
        }

        // If operation successful. 
        if (data.data.customerUpdate.customer) {
          const adminIndex = admins.findIndex(a => a.node.id === data.data.customerUpdate.customer.id);
          if (adminIndex > -1) {

            admins.splice(adminIndex, 1);

            this.context.setAdmins(admins);

            this.setState({
              alertMessage: `Success: admin removed correctly.`,
              alertColor: "success"
            })
          } else {
            this.setState({
              alertMessage: `Error: Admin not found. Please try again.`,
              alertColor: "danger"
            })
          }
        } else {
          this.setState({
            alertMessage: `Error: Could not remove admin. Please try again.`,
            alertColor: "danger"
          })
        }
      } else {
        this.setState({
          alertMessage: `Error: This admin has already been removed.`,
          alertColor: "danger"
        })
      }
    }
  }

  render() {
    const { loadingAdmins } = this.state

    const { admins } = this.context;

    return (
      <Fragment>
        <Breadcrumb mainTitle="Settings" parent="Settings" title="Preferences" />
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h5>Preferences</h5>
            </div>
            <div className="card-body settings-preferences">
              <div className="d-md-flex mb-2">
                <div style={{ width: '230px' }} className="left-side-bar mb-2">
                  <p className="mt-3 mb-2"><b>Admins</b></p>
                  <p className="text-secondary">
                    You can add up to 3 admins to your Ready.Set.Print. storefront. Admins can manage products, categories and much more.
                  </p>
                </div>
                <div className="offset-md-1 w-100">
                  <div className="row" style={{ marginBottom: this.state.alert ? '10px' : '40px' }}>
                    {loadingAdmins ? <p></p> :
                      admins.map((admin, index) => (
                        <div className="col-sm-4 mb-2" key={`div-${index}`}>
                          <PersonCard
                            isNew={false}
                            id={admin.node.id}
                            firstName={admin.node.firstName}
                            lastName={admin.node.lastName}
                            email={admin.node.email}
                            imageSrc={admin.node.image ? admin.node.image.originalSrc : null}
                            onDelete={this.onOpenDeleteModal}
                          />
                        </div>
                      ))
                    }
                    <div className="col-sm-4 mb-2">
                      <PersonCard
                        isNew={true}
                        handleOpenModal={this.handleOpenModal}
                      />
                    </div>
                  </div>
                  <Alert color={this.state.alertColor} isOpen={this.state.alert} toggle={this.toggleAlert.bind(this)}>
                    {this.state.alertMessage}
                  </Alert>
                </div>
              </div>
              <div className="d-md-flex">
                <div style={{ width: '230px' }} className="left-side-bar">
                  <p className="mt-3 mb-2"><b>Customer Service Email</b></p>
                  <p className="text-secondary mb-4">
                    Add the email address where you want to receive customer service requests. This will appear in your customer's order and shipping confirmation emails.
                  </p>
                </div>
                <div className="offset-md-1 w-100">
                  <CustomerServiceEmail />
                </div>
              </div>
              <div className="d-md-flex">
                <div style={{ width: '230px' }} className="left-side-bar">
                  <p className="mt-3 mb-2"><b>Direct Payouts</b></p>
                  <p className="text-secondary mb-4">
                    Eligible resellers can connect a Stripe account to receive daily payouts of orders placed on their reseller website (minus processing fees).
                  </p>
                </div>
                <div className="offset-md-1 w-100">
                  <StripeSettings />
                </div>
              </div>
              <div className="d-md-flex">
                <div style={{ width: '230px' }} className="left-side-bar">
                  <p className="mt-3 mb-2"><b>Custom Domains</b></p>
                  <p className="text-secondary mb-4">
                    Follow the instructions below to associate your own custom domain with your Ready.Set.Print. storefront.
                  </p>
                </div>
                <div className="offset-md-1 w-100 mt-3">
                  <CustomDomain />
                </div>
              </div>
            </div>
            <PersonFormModal
              isOpen={this.state.isOpen}
              onCloseModal={this.handleCloseModal}
              personInfo={this.state.personInfo}
              handleSubmit={this.handleSubmit}
            />
          </div>
        </div>

        <Modal
          open={this.state.modalOpen}
          showCloseIcon={false}
          onClose={this.onCloseDeleteModal}
          styles={{
            modal: {
              'borderRadius': "10px",
              'overflow': 'hidden'
            }
          }}
          center
        >
          <div className="container modal-lg modal-alert" >
            <div className="py-5 px-3">
              <div className="d-flex justify-content-between">
                <h5 style={{ color: "black", fontSize: "150%" }} ><b>Remove admin</b></h5>
                <div>
                  <a onClick={this.onCloseDeleteModal}> <XCircle size={24} color="black" /></a>
                </div>
              </div>
              <div className="text-secondary mt-1"
                style={{ fontSize: "130%", color: "#777777" }} >
                Are you sure you want to remove this admin?
                            </div>
              <div className="mt-4">
                <button type="button"
                  className="btn btn-outline-primary py-2 px-4 mr-3"
                  onClick={this.onCloseDeleteModal}
                >
                  Cancel
                                </button>
                <button type="button"
                  className="btn btn-danger py-2 px-4"
                  style={{ backgroundColor: "rgb(255,0,0) !important" }}
                  onClick={this.removeAdmin}>
                  Remove
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

SettingPreferences.contextType = AuthContext;

export default SettingPreferences;
