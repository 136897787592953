
const ReturnDeclineRequestPayload = {
  "name": "ReturnDeclineRequestPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "return": "Return",
    "userErrors": "ReturnUserError"
  },
  "implementsNode": false
};
export default ReturnDeclineRequestPayload;