
const ProductVariantsBulkCreatePayload = {
  "name": "ProductVariantsBulkCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "product": "Product",
    "productVariants": "ProductVariant",
    "userErrors": "ProductVariantsBulkCreateUserError"
  },
  "implementsNode": false
};
export default ProductVariantsBulkCreatePayload;