
const ShopifyPaymentsExtendedAuthorization = {
  "name": "ShopifyPaymentsExtendedAuthorization",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "extendedAuthorizationExpiresAt": "DateTime",
    "standardAuthorizationExpiresAt": "DateTime"
  },
  "implementsNode": false
};
export default ShopifyPaymentsExtendedAuthorization;