
const CustomerSegmentMembersQuery = {
  "name": "CustomerSegmentMembersQuery",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "currentCount": "Int",
    "done": "Boolean",
    "id": "ID"
  },
  "implementsNode": true
};
export default CustomerSegmentMembersQuery;