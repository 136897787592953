
const MetafieldDefinitionCreatePayload = {
  "name": "MetafieldDefinitionCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "createdDefinition": "MetafieldDefinition",
    "userErrors": "MetafieldDefinitionCreateUserError"
  },
  "implementsNode": false
};
export default MetafieldDefinitionCreatePayload;