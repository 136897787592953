
const MarketWebPresence = {
  "name": "MarketWebPresence",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "alternateLocales": "ShopLocale",
    "defaultLocale": "ShopLocale",
    "domain": "Domain",
    "id": "ID",
    "market": "Market",
    "rootUrls": "MarketWebPresenceRootUrl",
    "subfolderSuffix": "String"
  },
  "implementsNode": true
};
export default MarketWebPresence;