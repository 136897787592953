
const SellingPlanGroupRemoveProductsPayload = {
  "name": "SellingPlanGroupRemoveProductsPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "removedProductIds": "ID",
    "userErrors": "SellingPlanGroupUserError"
  },
  "implementsNode": false
};
export default SellingPlanGroupRemoveProductsPayload;