
const Video = {
  "name": "Video",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "alt": "String",
    "id": "ID",
    "mediaContentType": "MediaContentType",
    "presentation": "MediaPresentation",
    "previewImage": "Image",
    "sources": "VideoSource"
  },
  "implementsNode": true
};
export default Video;