
const CommentEventAttachment = {
  "name": "CommentEventAttachment",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "fileExtension": "String",
    "id": "ID",
    "image": "Image",
    "name": "String",
    "size": "Int",
    "url": "URL"
  },
  "implementsNode": false
};
export default CommentEventAttachment;