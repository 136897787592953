
const ProductSetOperation = {
  "name": "ProductSetOperation",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "product": "Product",
    "status": "ProductOperationStatus",
    "userErrors": "ProductSetUserError"
  },
  "implementsNode": true
};
export default ProductSetOperation;