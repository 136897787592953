
const DiscountCodeNode = {
  "name": "DiscountCodeNode",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "codeDiscount": "DiscountCode",
    "events": "EventConnection",
    "id": "ID",
    "metafield": "Metafield",
    "metafieldDefinitions": "MetafieldDefinitionConnection",
    "metafields": "MetafieldConnection",
    "privateMetafield": "PrivateMetafield",
    "privateMetafields": "PrivateMetafieldConnection"
  },
  "implementsNode": true
};
export default DiscountCodeNode;