
const WebhookSubscriptionInput = {
  "name": "WebhookSubscriptionInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "callbackUrl": "URL",
    "format": "WebhookSubscriptionFormat",
    "includeFields": "String",
    "metafieldNamespaces": "String"
  }
};
export default WebhookSubscriptionInput;