
const CheckoutBrandingTypographyStyle = {
  "name": "CheckoutBrandingTypographyStyle",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "font": "CheckoutBrandingTypographyFont",
    "kerning": "CheckoutBrandingTypographyKerning",
    "letterCase": "CheckoutBrandingTypographyLetterCase",
    "size": "CheckoutBrandingTypographySize",
    "weight": "CheckoutBrandingTypographyWeight"
  },
  "implementsNode": false
};
export default CheckoutBrandingTypographyStyle;