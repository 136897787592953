
const InventoryChangeInput = {
  "name": "InventoryChangeInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "delta": "Int",
    "inventoryItemId": "ID",
    "locationId": "ID",
    "ledgerDocumentUri": "String"
  }
};
export default InventoryChangeInput;