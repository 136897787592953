
const FilterValue = {
  "name": "FilterValue",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "count": "Int",
    "id": "String",
    "image": "MediaImage",
    "input": "JSON",
    "label": "String",
    "swatch": "Swatch"
  },
  "implementsNode": false
};
export default FilterValue;