import React from 'react';
import { Link } from 'react-router-dom';

const OrderItem = ({customAttributes, imgSrc, title, size, quantity, material, cut}) => {
  let thumbnail = null;
  
  if (customAttributes.length) {
    const filterThumbnail = customAttributes.filter(attribute => attribute.key === "thumbnail");
    if (filterThumbnail.length) {
      thumbnail = filterThumbnail[0].value;
    }
  }

  let proof = null;

  if (customAttributes.length) {
    const filterPrintReadyImage = customAttributes.filter(attribute => attribute.key == "print_ready_image");
    if (filterPrintReadyImage.length) {
      proof = filterPrintReadyImage[0].value;
    }
  }
  
  return(
    <div className="order-item d-flex">
      <img src={thumbnail ? thumbnail : imgSrc}
        style={{
          width: '100px',
          height: '100px',
          marginRight: '20px'
        }} 
        alt="No Content" />
      <div>
        <p className="text-primary"><Link to="#">{title}</Link></p>
        <p className="text-secondary">Size: {size}</p>
        <p className="text-secondary">Quantity: {quantity}</p>
        <p className="text-secondary">Material: {material}</p>
        <p className="text-secondary">Cut: {cut}</p>

        {proof && (
          <p><a href={proof}>View artwork</a></p>
        )}
      </div>
    </div>
  )
}

export default OrderItem;