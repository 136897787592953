
const PriceListCreatePayload = {
  "name": "PriceListCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "priceList": "PriceList",
    "userErrors": "PriceListUserError"
  },
  "implementsNode": false
};
export default PriceListCreatePayload;