
const MarketLocalization = {
  "name": "MarketLocalization",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "key": "String",
    "market": "Market",
    "outdated": "Boolean",
    "updatedAt": "DateTime",
    "value": "String"
  },
  "implementsNode": false
};
export default MarketLocalization;