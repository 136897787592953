
const CollectionAddProductsPayload = {
  "name": "CollectionAddProductsPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "collection": "Collection",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default CollectionAddProductsPayload;