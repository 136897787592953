
const ProductUpdatePayload = {
  "name": "ProductUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "product": "Product",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default ProductUpdatePayload;