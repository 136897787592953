
const MediaError = {
  "name": "MediaError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "MediaErrorCode",
    "details": "String",
    "message": "String"
  },
  "implementsNode": false
};
export default MediaError;