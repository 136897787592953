
const SubscriptionDraftDiscountUpdatePayload = {
  "name": "SubscriptionDraftDiscountUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "discountUpdated": "SubscriptionManualDiscount",
    "draft": "SubscriptionDraft",
    "userErrors": "SubscriptionDraftUserError"
  },
  "implementsNode": false
};
export default SubscriptionDraftDiscountUpdatePayload;