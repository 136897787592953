
const DeliveryCondition = {
  "name": "DeliveryCondition",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "conditionCriteria": "DeliveryConditionCriteria",
    "field": "DeliveryConditionField",
    "id": "ID",
    "operator": "DeliveryConditionOperator"
  },
  "implementsNode": true
};
export default DeliveryCondition;