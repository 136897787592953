
const ShopifyPaymentsDefaultChargeStatementDescriptor = {
  "name": "ShopifyPaymentsDefaultChargeStatementDescriptor",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "default": "String",
    "prefix": "String"
  },
  "implementsNode": false
};
export default ShopifyPaymentsDefaultChargeStatementDescriptor;