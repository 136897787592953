
const ParseErrorRange = {
  "name": "ParseErrorRange",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "end": "ErrorPosition",
    "start": "ErrorPosition"
  },
  "implementsNode": false
};
export default ParseErrorRange;