
const CustomerMergePreview = {
  "name": "CustomerMergePreview",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "alternateFields": "CustomerMergePreviewAlternateFields",
    "blockingFields": "CustomerMergePreviewBlockingFields",
    "customerMergeErrors": "CustomerMergeError",
    "defaultFields": "CustomerMergePreviewDefaultFields",
    "resultingCustomerId": "ID"
  },
  "implementsNode": false
};
export default CustomerMergePreview;