
const AppCatalog = {
  "name": "AppCatalog",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "apps": "AppConnection",
    "id": "ID",
    "operations": "ResourceOperation",
    "priceList": "PriceList",
    "publication": "Publication",
    "status": "CatalogStatus",
    "title": "String"
  },
  "implementsNode": true
};
export default AppCatalog;