
const UrlRedirectImportPreview = {
  "name": "UrlRedirectImportPreview",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "path": "String",
    "target": "String"
  },
  "implementsNode": false
};
export default UrlRedirectImportPreview;