
const MarketingActivityCreatePayload = {
  "name": "MarketingActivityCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "marketingActivity": "MarketingActivity",
    "redirectPath": "String",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default MarketingActivityCreatePayload;