
const DelegateAccessTokenCreateUserError = {
  "name": "DelegateAccessTokenCreateUserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "DelegateAccessTokenCreateUserErrorCode",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
export default DelegateAccessTokenCreateUserError;