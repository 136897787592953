
const AppConnection = {
  "name": "AppConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "AppEdge",
    "nodes": "App",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default AppConnection;