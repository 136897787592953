
const Media = {
  "name": "Media",
  "kind": "INTERFACE",
  "fieldBaseTypes": {
    "alt": "String",
    "id": "ID",
    "mediaContentType": "MediaContentType",
    "mediaErrors": "MediaError",
    "mediaWarnings": "MediaWarning",
    "preview": "MediaPreviewImage",
    "status": "MediaStatus"
  },
  "possibleTypes": ["ExternalVideo", "MediaImage", "Model3d", "Video"]
};
export default Media;