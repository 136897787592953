
const MetafieldStorefrontVisibilityCreatePayload = {
  "name": "MetafieldStorefrontVisibilityCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "metafieldStorefrontVisibility": "MetafieldStorefrontVisibility",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default MetafieldStorefrontVisibilityCreatePayload;