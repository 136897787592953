
const PriceRuleShippingLineEntitlements = {
  "name": "PriceRuleShippingLineEntitlements",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "countryCodes": "CountryCode",
    "includeRestOfWorld": "Boolean",
    "targetAllShippingLines": "Boolean"
  },
  "implementsNode": false
};
export default PriceRuleShippingLineEntitlements;