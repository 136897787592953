
const UrlRedirectEdge = {
  "name": "UrlRedirectEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "UrlRedirect"
  },
  "implementsNode": false
};
export default UrlRedirectEdge;