
const OrderLineItem = {
  "name": "OrderLineItem",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "currentQuantity": "Int",
    "customAttributes": "Attribute",
    "discountAllocations": "DiscountAllocation",
    "discountedTotalPrice": "MoneyV2",
    "originalTotalPrice": "MoneyV2",
    "quantity": "Int",
    "title": "String",
    "variant": "ProductVariant"
  },
  "implementsNode": false
};
export default OrderLineItem;