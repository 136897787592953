
const DeliveryCustomizationConnection = {
  "name": "DeliveryCustomizationConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "DeliveryCustomizationEdge",
    "nodes": "DeliveryCustomization",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default DeliveryCustomizationConnection;