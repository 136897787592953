
const CreateMediaInput = {
  "name": "CreateMediaInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "originalSource": "String",
    "alt": "String",
    "mediaContentType": "MediaContentType"
  }
};
export default CreateMediaInput;