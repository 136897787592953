
const InventoryMoveQuantitiesPayload = {
  "name": "InventoryMoveQuantitiesPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "inventoryAdjustmentGroup": "InventoryAdjustmentGroup",
    "userErrors": "InventoryMoveQuantitiesUserError"
  },
  "implementsNode": false
};
export default InventoryMoveQuantitiesPayload;