
const ReverseFulfillmentOrderDisposeInput = {
  "name": "ReverseFulfillmentOrderDisposeInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "reverseFulfillmentOrderLineItemId": "ID",
    "quantity": "Int",
    "locationId": "ID",
    "dispositionType": "ReverseFulfillmentOrderDispositionType"
  }
};
export default ReverseFulfillmentOrderDisposeInput;