
const PriceRuleCustomerSelectionInput = {
  "name": "PriceRuleCustomerSelectionInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "forAllCustomers": "Boolean",
    "segmentIds": "ID",
    "customerIdsToAdd": "ID",
    "customerIdsToRemove": "ID"
  }
};
export default PriceRuleCustomerSelectionInput;