
const TenderTransaction = {
  "name": "TenderTransaction",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "amount": "MoneyV2",
    "id": "ID",
    "paymentMethod": "String",
    "processedAt": "DateTime",
    "remoteReference": "String",
    "test": "Boolean",
    "transactionDetails": "TenderTransactionDetails",
    "user": "StaffMember"
  },
  "implementsNode": true
};
export default TenderTransaction;