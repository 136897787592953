
const CheckoutBrandingDesignSystemInput = {
  "name": "CheckoutBrandingDesignSystemInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "colors": "CheckoutBrandingColorsInput",
    "typography": "CheckoutBrandingTypographyInput",
    "cornerRadius": "CheckoutBrandingCornerRadiusVariablesInput"
  }
};
export default CheckoutBrandingDesignSystemInput;