
const ShopFeatures = {
  "name": "ShopFeatures",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "avalaraAvatax": "Boolean",
    "branding": "ShopBranding",
    "bundles": "BundlesFeature",
    "captcha": "Boolean",
    "captchaExternalDomains": "Boolean",
    "cartTransform": "CartTransformFeature",
    "deliveryProfiles": "Boolean",
    "dynamicRemarketing": "Boolean",
    "eligibleForSubscriptionMigration": "Boolean",
    "eligibleForSubscriptions": "Boolean",
    "giftCards": "Boolean",
    "harmonizedSystemCode": "Boolean",
    "internationalDomains": "Boolean",
    "internationalPriceOverrides": "Boolean",
    "internationalPriceRules": "Boolean",
    "legacySubscriptionGatewayEnabled": "Boolean",
    "liveView": "Boolean",
    "multiLocation": "Boolean",
    "onboardingVisual": "Boolean",
    "paypalExpressSubscriptionGatewayStatus": "PaypalExpressSubscriptionsGatewayStatus",
    "reports": "Boolean",
    "sellsSubscriptions": "Boolean",
    "shopifyPlus": "Boolean",
    "showMetrics": "Boolean",
    "storefront": "Boolean",
    "usingShopifyBalance": "Boolean"
  },
  "implementsNode": false
};
export default ShopFeatures;