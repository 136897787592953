import React, { Component } from 'react'

export class UserPanel extends Component {
    render() {
        return (
            <div>
                <div className="sidebar-user">
                    <h6 className="mt-3 f-14">{/* TODO: Update this field to a users first name from context */}</h6>
                    <p>{this.props.email}</p>
                </div>
            </div>
        )
    }
}
