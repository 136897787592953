
const ValidationCreateInput = {
  "name": "ValidationCreateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "functionId": "String",
    "enable": "Boolean",
    "blockOnFailure": "Boolean",
    "metafields": "MetafieldInput",
    "title": "String"
  }
};
export default ValidationCreateInput;