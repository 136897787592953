
import ARN from "./types/arn";
import AbandonedCheckout from "./types/abandoned-checkout";
import AbandonedCheckoutLineItem from "./types/abandoned-checkout-line-item";
import AbandonedCheckoutLineItemConnection from "./types/abandoned-checkout-line-item-connection";
import AbandonedCheckoutLineItemEdge from "./types/abandoned-checkout-line-item-edge";
import Abandonment from "./types/abandonment";
import AbandonmentAbandonmentType from "./types/abandonment-abandonment-type";
import AbandonmentDeliveryState from "./types/abandonment-delivery-state";
import AbandonmentEmailState from "./types/abandonment-email-state";
import AbandonmentEmailStateUpdatePayload from "./types/abandonment-email-state-update-payload";
import AbandonmentEmailStateUpdateUserError from "./types/abandonment-email-state-update-user-error";
import AbandonmentEmailStateUpdateUserErrorCode from "./types/abandonment-email-state-update-user-error-code";
import AbandonmentUpdateActivitiesDeliveryStatusesPayload from "./types/abandonment-update-activities-delivery-statuses-payload";
import AbandonmentUpdateActivitiesDeliveryStatusesUserError from "./types/abandonment-update-activities-delivery-statuses-user-error";
import AbandonmentUpdateActivitiesDeliveryStatusesUserErrorCode from "./types/abandonment-update-activities-delivery-statuses-user-error-code";
import AccessScope from "./types/access-scope";
import AddAllProductsOperation from "./types/add-all-products-operation";
import AdditionalFee from "./types/additional-fee";
import AdditionalFeeSale from "./types/additional-fee-sale";
import AdjustmentSale from "./types/adjustment-sale";
import AdjustmentsSortKeys from "./types/adjustments-sort-keys";
import AllDiscountItems from "./types/all-discount-items";
import ApiVersion from "./types/api-version";
import App from "./types/app";
import AppCatalog from "./types/app-catalog";
import AppConnection from "./types/app-connection";
import AppCredit from "./types/app-credit";
import AppCreditConnection from "./types/app-credit-connection";
import AppCreditEdge from "./types/app-credit-edge";
import AppDeveloperType from "./types/app-developer-type";
import AppDiscountType from "./types/app-discount-type";
import AppEdge from "./types/app-edge";
import AppFeedback from "./types/app-feedback";
import AppInstallation from "./types/app-installation";
import AppInstallationCategory from "./types/app-installation-category";
import AppInstallationConnection from "./types/app-installation-connection";
import AppInstallationEdge from "./types/app-installation-edge";
import AppInstallationPrivacy from "./types/app-installation-privacy";
import AppInstallationSortKeys from "./types/app-installation-sort-keys";
import AppPlanInput from "./types/app-plan-input";
import AppPlanV2 from "./types/app-plan-v-2";
import AppPricingDetails from "./types/app-pricing-details";
import AppPricingInterval from "./types/app-pricing-interval";
import AppPublicCategory from "./types/app-public-category";
import AppPurchase from "./types/app-purchase";
import AppPurchaseOneTime from "./types/app-purchase-one-time";
import AppPurchaseOneTimeConnection from "./types/app-purchase-one-time-connection";
import AppPurchaseOneTimeCreatePayload from "./types/app-purchase-one-time-create-payload";
import AppPurchaseOneTimeEdge from "./types/app-purchase-one-time-edge";
import AppPurchaseStatus from "./types/app-purchase-status";
import AppRecurringPricing from "./types/app-recurring-pricing";
import AppRecurringPricingInput from "./types/app-recurring-pricing-input";
import AppRevenueAttributionRecord from "./types/app-revenue-attribution-record";
import AppRevenueAttributionRecordConnection from "./types/app-revenue-attribution-record-connection";
import AppRevenueAttributionRecordEdge from "./types/app-revenue-attribution-record-edge";
import AppRevenueAttributionRecordSortKeys from "./types/app-revenue-attribution-record-sort-keys";
import AppRevenueAttributionType from "./types/app-revenue-attribution-type";
import AppSubscription from "./types/app-subscription";
import AppSubscriptionCancelPayload from "./types/app-subscription-cancel-payload";
import AppSubscriptionConnection from "./types/app-subscription-connection";
import AppSubscriptionCreatePayload from "./types/app-subscription-create-payload";
import AppSubscriptionDiscount from "./types/app-subscription-discount";
import AppSubscriptionDiscountAmount from "./types/app-subscription-discount-amount";
import AppSubscriptionDiscountInput from "./types/app-subscription-discount-input";
import AppSubscriptionDiscountPercentage from "./types/app-subscription-discount-percentage";
import AppSubscriptionDiscountValue from "./types/app-subscription-discount-value";
import AppSubscriptionDiscountValueInput from "./types/app-subscription-discount-value-input";
import AppSubscriptionEdge from "./types/app-subscription-edge";
import AppSubscriptionLineItem from "./types/app-subscription-line-item";
import AppSubscriptionLineItemInput from "./types/app-subscription-line-item-input";
import AppSubscriptionLineItemUpdatePayload from "./types/app-subscription-line-item-update-payload";
import AppSubscriptionReplacementBehavior from "./types/app-subscription-replacement-behavior";
import AppSubscriptionSortKeys from "./types/app-subscription-sort-keys";
import AppSubscriptionStatus from "./types/app-subscription-status";
import AppSubscriptionTrialExtendPayload from "./types/app-subscription-trial-extend-payload";
import AppSubscriptionTrialExtendUserError from "./types/app-subscription-trial-extend-user-error";
import AppSubscriptionTrialExtendUserErrorCode from "./types/app-subscription-trial-extend-user-error-code";
import AppTransactionSortKeys from "./types/app-transaction-sort-keys";
import AppUsagePricing from "./types/app-usage-pricing";
import AppUsagePricingInput from "./types/app-usage-pricing-input";
import AppUsageRecord from "./types/app-usage-record";
import AppUsageRecordConnection from "./types/app-usage-record-connection";
import AppUsageRecordCreatePayload from "./types/app-usage-record-create-payload";
import AppUsageRecordEdge from "./types/app-usage-record-edge";
import AppUsageRecordSortKeys from "./types/app-usage-record-sort-keys";
import Attribute from "./types/attribute";
import AttributeInput from "./types/attribute-input";
import AutomaticDiscountApplication from "./types/automatic-discount-application";
import AutomaticDiscountSortKeys from "./types/automatic-discount-sort-keys";
import AvailableChannelDefinitionsByChannel from "./types/available-channel-definitions-by-channel";
import BadgeType from "./types/badge-type";
import BalanceTransactionSortKeys from "./types/balance-transaction-sort-keys";
import BasePaymentDetails from "./types/base-payment-details";
import BasicEvent from "./types/basic-event";
import BillingAttemptUserError from "./types/billing-attempt-user-error";
import BillingAttemptUserErrorCode from "./types/billing-attempt-user-error-code";
import Boolean from "./types/boolean";
import BulkMutationErrorCode from "./types/bulk-mutation-error-code";
import BulkMutationUserError from "./types/bulk-mutation-user-error";
import BulkOperation from "./types/bulk-operation";
import BulkOperationCancelPayload from "./types/bulk-operation-cancel-payload";
import BulkOperationErrorCode from "./types/bulk-operation-error-code";
import BulkOperationRunMutationPayload from "./types/bulk-operation-run-mutation-payload";
import BulkOperationRunQueryPayload from "./types/bulk-operation-run-query-payload";
import BulkOperationStatus from "./types/bulk-operation-status";
import BulkOperationType from "./types/bulk-operation-type";
import BulkProductResourceFeedbackCreatePayload from "./types/bulk-product-resource-feedback-create-payload";
import BulkProductResourceFeedbackCreateUserError from "./types/bulk-product-resource-feedback-create-user-error";
import BulkProductResourceFeedbackCreateUserErrorCode from "./types/bulk-product-resource-feedback-create-user-error-code";
import BundlesFeature from "./types/bundles-feature";
import BusinessCustomerErrorCode from "./types/business-customer-error-code";
import BusinessCustomerUserError from "./types/business-customer-user-error";
import BuyerExperienceConfiguration from "./types/buyer-experience-configuration";
import BuyerExperienceConfigurationInput from "./types/buyer-experience-configuration-input";
import CalculatedAutomaticDiscountApplication from "./types/calculated-automatic-discount-application";
import CalculatedDiscountAllocation from "./types/calculated-discount-allocation";
import CalculatedDiscountApplication from "./types/calculated-discount-application";
import CalculatedDiscountApplicationConnection from "./types/calculated-discount-application-connection";
import CalculatedDiscountApplicationEdge from "./types/calculated-discount-application-edge";
import CalculatedDiscountCodeApplication from "./types/calculated-discount-code-application";
import CalculatedDraftOrder from "./types/calculated-draft-order";
import CalculatedDraftOrderLineItem from "./types/calculated-draft-order-line-item";
import CalculatedLineItem from "./types/calculated-line-item";
import CalculatedLineItemConnection from "./types/calculated-line-item-connection";
import CalculatedLineItemEdge from "./types/calculated-line-item-edge";
import CalculatedManualDiscountApplication from "./types/calculated-manual-discount-application";
import CalculatedOrder from "./types/calculated-order";
import CalculatedScriptDiscountApplication from "./types/calculated-script-discount-application";
import CalculatedShippingLine from "./types/calculated-shipping-line";
import CalculatedShippingLineStagedStatus from "./types/calculated-shipping-line-staged-status";
import CardPaymentDetails from "./types/card-payment-details";
import CartTransform from "./types/cart-transform";
import CartTransformConnection from "./types/cart-transform-connection";
import CartTransformCreatePayload from "./types/cart-transform-create-payload";
import CartTransformCreateUserError from "./types/cart-transform-create-user-error";
import CartTransformCreateUserErrorCode from "./types/cart-transform-create-user-error-code";
import CartTransformDeletePayload from "./types/cart-transform-delete-payload";
import CartTransformDeleteUserError from "./types/cart-transform-delete-user-error";
import CartTransformDeleteUserErrorCode from "./types/cart-transform-delete-user-error-code";
import CartTransformEdge from "./types/cart-transform-edge";
import CartTransformEligibleOperations from "./types/cart-transform-eligible-operations";
import CartTransformFeature from "./types/cart-transform-feature";
import CashTrackingAdjustment from "./types/cash-tracking-adjustment";
import CashTrackingAdjustmentConnection from "./types/cash-tracking-adjustment-connection";
import CashTrackingAdjustmentEdge from "./types/cash-tracking-adjustment-edge";
import CashTrackingSession from "./types/cash-tracking-session";
import CashTrackingSessionConnection from "./types/cash-tracking-session-connection";
import CashTrackingSessionEdge from "./types/cash-tracking-session-edge";
import CashTrackingSessionsSortKeys from "./types/cash-tracking-sessions-sort-keys";
import Catalog from "./types/catalog";
import CatalogConnection from "./types/catalog-connection";
import CatalogContextInput from "./types/catalog-context-input";
import CatalogContextUpdatePayload from "./types/catalog-context-update-payload";
import CatalogCreateInput from "./types/catalog-create-input";
import CatalogCreatePayload from "./types/catalog-create-payload";
import CatalogCsvOperation from "./types/catalog-csv-operation";
import CatalogDeletePayload from "./types/catalog-delete-payload";
import CatalogEdge from "./types/catalog-edge";
import CatalogSortKeys from "./types/catalog-sort-keys";
import CatalogStatus from "./types/catalog-status";
import CatalogType from "./types/catalog-type";
import CatalogUpdateInput from "./types/catalog-update-input";
import CatalogUpdatePayload from "./types/catalog-update-payload";
import CatalogUserError from "./types/catalog-user-error";
import CatalogUserErrorCode from "./types/catalog-user-error-code";
import Channel from "./types/channel";
import ChannelConnection from "./types/channel-connection";
import ChannelDefinition from "./types/channel-definition";
import ChannelEdge from "./types/channel-edge";
import ChannelInformation from "./types/channel-information";
import CheckoutBranding from "./types/checkout-branding";
import CheckoutBrandingBackground from "./types/checkout-branding-background";
import CheckoutBrandingBackgroundStyle from "./types/checkout-branding-background-style";
import CheckoutBrandingBorder from "./types/checkout-branding-border";
import CheckoutBrandingBorderStyle from "./types/checkout-branding-border-style";
import CheckoutBrandingBorderWidth from "./types/checkout-branding-border-width";
import CheckoutBrandingButton from "./types/checkout-branding-button";
import CheckoutBrandingButtonColorRoles from "./types/checkout-branding-button-color-roles";
import CheckoutBrandingButtonColorRolesInput from "./types/checkout-branding-button-color-roles-input";
import CheckoutBrandingButtonInput from "./types/checkout-branding-button-input";
import CheckoutBrandingBuyerJourney from "./types/checkout-branding-buyer-journey";
import CheckoutBrandingBuyerJourneyInput from "./types/checkout-branding-buyer-journey-input";
import CheckoutBrandingCartLink from "./types/checkout-branding-cart-link";
import CheckoutBrandingCartLinkContentType from "./types/checkout-branding-cart-link-content-type";
import CheckoutBrandingCartLinkInput from "./types/checkout-branding-cart-link-input";
import CheckoutBrandingCheckbox from "./types/checkout-branding-checkbox";
import CheckoutBrandingCheckboxInput from "./types/checkout-branding-checkbox-input";
import CheckoutBrandingChoiceList from "./types/checkout-branding-choice-list";
import CheckoutBrandingChoiceListGroup from "./types/checkout-branding-choice-list-group";
import CheckoutBrandingChoiceListGroupInput from "./types/checkout-branding-choice-list-group-input";
import CheckoutBrandingChoiceListInput from "./types/checkout-branding-choice-list-input";
import CheckoutBrandingColorGlobal from "./types/checkout-branding-color-global";
import CheckoutBrandingColorGlobalInput from "./types/checkout-branding-color-global-input";
import CheckoutBrandingColorRoles from "./types/checkout-branding-color-roles";
import CheckoutBrandingColorRolesInput from "./types/checkout-branding-color-roles-input";
import CheckoutBrandingColorScheme from "./types/checkout-branding-color-scheme";
import CheckoutBrandingColorSchemeInput from "./types/checkout-branding-color-scheme-input";
import CheckoutBrandingColorSchemeSelection from "./types/checkout-branding-color-scheme-selection";
import CheckoutBrandingColorSchemes from "./types/checkout-branding-color-schemes";
import CheckoutBrandingColorSchemesInput from "./types/checkout-branding-color-schemes-input";
import CheckoutBrandingColorSelection from "./types/checkout-branding-color-selection";
import CheckoutBrandingColors from "./types/checkout-branding-colors";
import CheckoutBrandingColorsInput from "./types/checkout-branding-colors-input";
import CheckoutBrandingControl from "./types/checkout-branding-control";
import CheckoutBrandingControlColorRoles from "./types/checkout-branding-control-color-roles";
import CheckoutBrandingControlColorRolesInput from "./types/checkout-branding-control-color-roles-input";
import CheckoutBrandingControlInput from "./types/checkout-branding-control-input";
import CheckoutBrandingCornerRadius from "./types/checkout-branding-corner-radius";
import CheckoutBrandingCornerRadiusVariables from "./types/checkout-branding-corner-radius-variables";
import CheckoutBrandingCornerRadiusVariablesInput from "./types/checkout-branding-corner-radius-variables-input";
import CheckoutBrandingCustomFont from "./types/checkout-branding-custom-font";
import CheckoutBrandingCustomFontGroupInput from "./types/checkout-branding-custom-font-group-input";
import CheckoutBrandingCustomFontInput from "./types/checkout-branding-custom-font-input";
import CheckoutBrandingCustomizations from "./types/checkout-branding-customizations";
import CheckoutBrandingCustomizationsInput from "./types/checkout-branding-customizations-input";
import CheckoutBrandingDesignSystem from "./types/checkout-branding-design-system";
import CheckoutBrandingDesignSystemInput from "./types/checkout-branding-design-system-input";
import CheckoutBrandingExpressCheckout from "./types/checkout-branding-express-checkout";
import CheckoutBrandingExpressCheckoutButton from "./types/checkout-branding-express-checkout-button";
import CheckoutBrandingExpressCheckoutButtonInput from "./types/checkout-branding-express-checkout-button-input";
import CheckoutBrandingExpressCheckoutInput from "./types/checkout-branding-express-checkout-input";
import CheckoutBrandingFont from "./types/checkout-branding-font";
import CheckoutBrandingFontGroup from "./types/checkout-branding-font-group";
import CheckoutBrandingFontGroupInput from "./types/checkout-branding-font-group-input";
import CheckoutBrandingFontLoadingStrategy from "./types/checkout-branding-font-loading-strategy";
import CheckoutBrandingFontSize from "./types/checkout-branding-font-size";
import CheckoutBrandingFontSizeInput from "./types/checkout-branding-font-size-input";
import CheckoutBrandingFooter from "./types/checkout-branding-footer";
import CheckoutBrandingFooterAlignment from "./types/checkout-branding-footer-alignment";
import CheckoutBrandingFooterContent from "./types/checkout-branding-footer-content";
import CheckoutBrandingFooterContentInput from "./types/checkout-branding-footer-content-input";
import CheckoutBrandingFooterInput from "./types/checkout-branding-footer-input";
import CheckoutBrandingFooterPosition from "./types/checkout-branding-footer-position";
import CheckoutBrandingGlobal from "./types/checkout-branding-global";
import CheckoutBrandingGlobalCornerRadius from "./types/checkout-branding-global-corner-radius";
import CheckoutBrandingGlobalInput from "./types/checkout-branding-global-input";
import CheckoutBrandingHeader from "./types/checkout-branding-header";
import CheckoutBrandingHeaderAlignment from "./types/checkout-branding-header-alignment";
import CheckoutBrandingHeaderCartLink from "./types/checkout-branding-header-cart-link";
import CheckoutBrandingHeaderCartLinkInput from "./types/checkout-branding-header-cart-link-input";
import CheckoutBrandingHeaderInput from "./types/checkout-branding-header-input";
import CheckoutBrandingHeaderPosition from "./types/checkout-branding-header-position";
import CheckoutBrandingHeadingLevel from "./types/checkout-branding-heading-level";
import CheckoutBrandingHeadingLevelInput from "./types/checkout-branding-heading-level-input";
import CheckoutBrandingImage from "./types/checkout-branding-image";
import CheckoutBrandingImageInput from "./types/checkout-branding-image-input";
import CheckoutBrandingInput from "./types/checkout-branding-input";
import CheckoutBrandingLabelPosition from "./types/checkout-branding-label-position";
import CheckoutBrandingLogo from "./types/checkout-branding-logo";
import CheckoutBrandingLogoInput from "./types/checkout-branding-logo-input";
import CheckoutBrandingMain from "./types/checkout-branding-main";
import CheckoutBrandingMainInput from "./types/checkout-branding-main-input";
import CheckoutBrandingMainSection from "./types/checkout-branding-main-section";
import CheckoutBrandingMainSectionInput from "./types/checkout-branding-main-section-input";
import CheckoutBrandingMerchandiseThumbnail from "./types/checkout-branding-merchandise-thumbnail";
import CheckoutBrandingMerchandiseThumbnailInput from "./types/checkout-branding-merchandise-thumbnail-input";
import CheckoutBrandingOrderSummary from "./types/checkout-branding-order-summary";
import CheckoutBrandingOrderSummaryInput from "./types/checkout-branding-order-summary-input";
import CheckoutBrandingOrderSummarySection from "./types/checkout-branding-order-summary-section";
import CheckoutBrandingOrderSummarySectionInput from "./types/checkout-branding-order-summary-section-input";
import CheckoutBrandingSelect from "./types/checkout-branding-select";
import CheckoutBrandingSelectInput from "./types/checkout-branding-select-input";
import CheckoutBrandingShadow from "./types/checkout-branding-shadow";
import CheckoutBrandingShopifyFont from "./types/checkout-branding-shopify-font";
import CheckoutBrandingShopifyFontGroupInput from "./types/checkout-branding-shopify-font-group-input";
import CheckoutBrandingSimpleBorder from "./types/checkout-branding-simple-border";
import CheckoutBrandingSpacing from "./types/checkout-branding-spacing";
import CheckoutBrandingSpacingKeyword from "./types/checkout-branding-spacing-keyword";
import CheckoutBrandingTextField from "./types/checkout-branding-text-field";
import CheckoutBrandingTextFieldInput from "./types/checkout-branding-text-field-input";
import CheckoutBrandingTypography from "./types/checkout-branding-typography";
import CheckoutBrandingTypographyFont from "./types/checkout-branding-typography-font";
import CheckoutBrandingTypographyInput from "./types/checkout-branding-typography-input";
import CheckoutBrandingTypographyKerning from "./types/checkout-branding-typography-kerning";
import CheckoutBrandingTypographyLetterCase from "./types/checkout-branding-typography-letter-case";
import CheckoutBrandingTypographySize from "./types/checkout-branding-typography-size";
import CheckoutBrandingTypographyStyle from "./types/checkout-branding-typography-style";
import CheckoutBrandingTypographyStyleGlobal from "./types/checkout-branding-typography-style-global";
import CheckoutBrandingTypographyStyleGlobalInput from "./types/checkout-branding-typography-style-global-input";
import CheckoutBrandingTypographyStyleInput from "./types/checkout-branding-typography-style-input";
import CheckoutBrandingTypographyWeight from "./types/checkout-branding-typography-weight";
import CheckoutBrandingUpsertPayload from "./types/checkout-branding-upsert-payload";
import CheckoutBrandingUpsertUserError from "./types/checkout-branding-upsert-user-error";
import CheckoutBrandingUpsertUserErrorCode from "./types/checkout-branding-upsert-user-error-code";
import CheckoutBrandingVisibility from "./types/checkout-branding-visibility";
import CheckoutProfile from "./types/checkout-profile";
import CheckoutProfileConnection from "./types/checkout-profile-connection";
import CheckoutProfileEdge from "./types/checkout-profile-edge";
import CheckoutProfileSortKeys from "./types/checkout-profile-sort-keys";
import CodeDiscountSortKeys from "./types/code-discount-sort-keys";
import Collection from "./types/collection";
import CollectionAddProductsPayload from "./types/collection-add-products-payload";
import CollectionAddProductsV2Payload from "./types/collection-add-products-v-2-payload";
import CollectionAddProductsV2UserError from "./types/collection-add-products-v-2-user-error";
import CollectionAddProductsV2UserErrorCode from "./types/collection-add-products-v-2-user-error-code";
import CollectionConnection from "./types/collection-connection";
import CollectionCreatePayload from "./types/collection-create-payload";
import CollectionDeleteInput from "./types/collection-delete-input";
import CollectionDeletePayload from "./types/collection-delete-payload";
import CollectionEdge from "./types/collection-edge";
import CollectionInput from "./types/collection-input";
import CollectionPublication from "./types/collection-publication";
import CollectionPublicationConnection from "./types/collection-publication-connection";
import CollectionPublicationEdge from "./types/collection-publication-edge";
import CollectionPublicationInput from "./types/collection-publication-input";
import CollectionPublishInput from "./types/collection-publish-input";
import CollectionPublishPayload from "./types/collection-publish-payload";
import CollectionRemoveProductsPayload from "./types/collection-remove-products-payload";
import CollectionReorderProductsPayload from "./types/collection-reorder-products-payload";
import CollectionRule from "./types/collection-rule";
import CollectionRuleColumn from "./types/collection-rule-column";
import CollectionRuleConditionObject from "./types/collection-rule-condition-object";
import CollectionRuleConditions from "./types/collection-rule-conditions";
import CollectionRuleConditionsRuleObject from "./types/collection-rule-conditions-rule-object";
import CollectionRuleInput from "./types/collection-rule-input";
import CollectionRuleMetafieldCondition from "./types/collection-rule-metafield-condition";
import CollectionRuleProductCategoryCondition from "./types/collection-rule-product-category-condition";
import CollectionRuleRelation from "./types/collection-rule-relation";
import CollectionRuleSet from "./types/collection-rule-set";
import CollectionRuleSetInput from "./types/collection-rule-set-input";
import CollectionRuleTextCondition from "./types/collection-rule-text-condition";
import CollectionSortKeys from "./types/collection-sort-keys";
import CollectionSortOrder from "./types/collection-sort-order";
import CollectionUnpublishInput from "./types/collection-unpublish-input";
import CollectionUnpublishPayload from "./types/collection-unpublish-payload";
import CollectionUpdatePayload from "./types/collection-update-payload";
import Color from "./types/color";
import CommentEvent from "./types/comment-event";
import CommentEventAttachment from "./types/comment-event-attachment";
import CommentEventEmbed from "./types/comment-event-embed";
import CommentEventSubject from "./types/comment-event-subject";
import CompaniesDeletePayload from "./types/companies-delete-payload";
import Company from "./types/company";
import CompanyAddress from "./types/company-address";
import CompanyAddressDeletePayload from "./types/company-address-delete-payload";
import CompanyAddressInput from "./types/company-address-input";
import CompanyAddressType from "./types/company-address-type";
import CompanyAssignCustomerAsContactPayload from "./types/company-assign-customer-as-contact-payload";
import CompanyAssignMainContactPayload from "./types/company-assign-main-contact-payload";
import CompanyConnection from "./types/company-connection";
import CompanyContact from "./types/company-contact";
import CompanyContactAssignRolePayload from "./types/company-contact-assign-role-payload";
import CompanyContactAssignRolesPayload from "./types/company-contact-assign-roles-payload";
import CompanyContactConnection from "./types/company-contact-connection";
import CompanyContactCreatePayload from "./types/company-contact-create-payload";
import CompanyContactDeletePayload from "./types/company-contact-delete-payload";
import CompanyContactEdge from "./types/company-contact-edge";
import CompanyContactInput from "./types/company-contact-input";
import CompanyContactRemoveFromCompanyPayload from "./types/company-contact-remove-from-company-payload";
import CompanyContactRevokeRolePayload from "./types/company-contact-revoke-role-payload";
import CompanyContactRevokeRolesPayload from "./types/company-contact-revoke-roles-payload";
import CompanyContactRole from "./types/company-contact-role";
import CompanyContactRoleAssign from "./types/company-contact-role-assign";
import CompanyContactRoleAssignment from "./types/company-contact-role-assignment";
import CompanyContactRoleAssignmentConnection from "./types/company-contact-role-assignment-connection";
import CompanyContactRoleAssignmentEdge from "./types/company-contact-role-assignment-edge";
import CompanyContactRoleAssignmentSortKeys from "./types/company-contact-role-assignment-sort-keys";
import CompanyContactRoleConnection from "./types/company-contact-role-connection";
import CompanyContactRoleEdge from "./types/company-contact-role-edge";
import CompanyContactRoleSortKeys from "./types/company-contact-role-sort-keys";
import CompanyContactSendWelcomeEmailPayload from "./types/company-contact-send-welcome-email-payload";
import CompanyContactSortKeys from "./types/company-contact-sort-keys";
import CompanyContactUpdatePayload from "./types/company-contact-update-payload";
import CompanyContactsDeletePayload from "./types/company-contacts-delete-payload";
import CompanyCreateInput from "./types/company-create-input";
import CompanyCreatePayload from "./types/company-create-payload";
import CompanyDeletePayload from "./types/company-delete-payload";
import CompanyEdge from "./types/company-edge";
import CompanyInput from "./types/company-input";
import CompanyLocation from "./types/company-location";
import CompanyLocationAssignAddressPayload from "./types/company-location-assign-address-payload";
import CompanyLocationAssignRolesPayload from "./types/company-location-assign-roles-payload";
import CompanyLocationAssignTaxExemptionsPayload from "./types/company-location-assign-tax-exemptions-payload";
import CompanyLocationCatalog from "./types/company-location-catalog";
import CompanyLocationConnection from "./types/company-location-connection";
import CompanyLocationCreatePayload from "./types/company-location-create-payload";
import CompanyLocationCreateTaxRegistrationPayload from "./types/company-location-create-tax-registration-payload";
import CompanyLocationDeletePayload from "./types/company-location-delete-payload";
import CompanyLocationEdge from "./types/company-location-edge";
import CompanyLocationInput from "./types/company-location-input";
import CompanyLocationRevokeRolesPayload from "./types/company-location-revoke-roles-payload";
import CompanyLocationRevokeTaxExemptionsPayload from "./types/company-location-revoke-tax-exemptions-payload";
import CompanyLocationRevokeTaxRegistrationPayload from "./types/company-location-revoke-tax-registration-payload";
import CompanyLocationRoleAssign from "./types/company-location-role-assign";
import CompanyLocationSortKeys from "./types/company-location-sort-keys";
import CompanyLocationUpdateInput from "./types/company-location-update-input";
import CompanyLocationUpdatePayload from "./types/company-location-update-payload";
import CompanyLocationsDeletePayload from "./types/company-locations-delete-payload";
import CompanyRevokeMainContactPayload from "./types/company-revoke-main-contact-payload";
import CompanySortKeys from "./types/company-sort-keys";
import CompanyUpdatePayload from "./types/company-update-payload";
import ContextualPricingContext from "./types/contextual-pricing-context";
import ContextualPublicationContext from "./types/contextual-publication-context";
import Count from "./types/count";
import CountPrecision from "./types/count-precision";
import CountriesInShippingZones from "./types/countries-in-shipping-zones";
import CountryCode from "./types/country-code";
import CountryHarmonizedSystemCode from "./types/country-harmonized-system-code";
import CountryHarmonizedSystemCodeConnection from "./types/country-harmonized-system-code-connection";
import CountryHarmonizedSystemCodeEdge from "./types/country-harmonized-system-code-edge";
import CountryHarmonizedSystemCodeInput from "./types/country-harmonized-system-code-input";
import CreateMediaInput from "./types/create-media-input";
import CropRegion from "./types/crop-region";
import CurrencyCode from "./types/currency-code";
import CurrencyFormats from "./types/currency-formats";
import CurrencySetting from "./types/currency-setting";
import CurrencySettingConnection from "./types/currency-setting-connection";
import CurrencySettingEdge from "./types/currency-setting-edge";
import CustomShippingPackageInput from "./types/custom-shipping-package-input";
import Customer from "./types/customer";
import CustomerAccountsV2 from "./types/customer-accounts-v-2";
import CustomerAccountsVersion from "./types/customer-accounts-version";
import CustomerAddTaxExemptionsPayload from "./types/customer-add-tax-exemptions-payload";
import CustomerCancelDataErasureErrorCode from "./types/customer-cancel-data-erasure-error-code";
import CustomerCancelDataErasurePayload from "./types/customer-cancel-data-erasure-payload";
import CustomerCancelDataErasureUserError from "./types/customer-cancel-data-erasure-user-error";
import CustomerConnection from "./types/customer-connection";
import CustomerConsentCollectedFrom from "./types/customer-consent-collected-from";
import CustomerCreatePayload from "./types/customer-create-payload";
import CustomerCreditCard from "./types/customer-credit-card";
import CustomerCreditCardBillingAddress from "./types/customer-credit-card-billing-address";
import CustomerDeleteInput from "./types/customer-delete-input";
import CustomerDeletePayload from "./types/customer-delete-payload";
import CustomerEdge from "./types/customer-edge";
import CustomerEmailAddress from "./types/customer-email-address";
import CustomerEmailAddressMarketingState from "./types/customer-email-address-marketing-state";
import CustomerEmailAddressOpenTrackingLevel from "./types/customer-email-address-open-tracking-level";
import CustomerEmailMarketingConsentInput from "./types/customer-email-marketing-consent-input";
import CustomerEmailMarketingConsentState from "./types/customer-email-marketing-consent-state";
import CustomerEmailMarketingConsentUpdateInput from "./types/customer-email-marketing-consent-update-input";
import CustomerEmailMarketingConsentUpdatePayload from "./types/customer-email-marketing-consent-update-payload";
import CustomerEmailMarketingConsentUpdateUserError from "./types/customer-email-marketing-consent-update-user-error";
import CustomerEmailMarketingConsentUpdateUserErrorCode from "./types/customer-email-marketing-consent-update-user-error-code";
import CustomerEmailMarketingState from "./types/customer-email-marketing-state";
import CustomerGenerateAccountActivationUrlPayload from "./types/customer-generate-account-activation-url-payload";
import CustomerInput from "./types/customer-input";
import CustomerJourney from "./types/customer-journey";
import CustomerJourneySummary from "./types/customer-journey-summary";
import CustomerMarketingOptInLevel from "./types/customer-marketing-opt-in-level";
import CustomerMergeError from "./types/customer-merge-error";
import CustomerMergeErrorCode from "./types/customer-merge-error-code";
import CustomerMergeErrorFieldType from "./types/customer-merge-error-field-type";
import CustomerMergeOverrideFields from "./types/customer-merge-override-fields";
import CustomerMergePayload from "./types/customer-merge-payload";
import CustomerMergePreview from "./types/customer-merge-preview";
import CustomerMergePreviewAlternateFields from "./types/customer-merge-preview-alternate-fields";
import CustomerMergePreviewBlockingFields from "./types/customer-merge-preview-blocking-fields";
import CustomerMergePreviewDefaultFields from "./types/customer-merge-preview-default-fields";
import CustomerMergeRequest from "./types/customer-merge-request";
import CustomerMergeRequestStatus from "./types/customer-merge-request-status";
import CustomerMergeUserError from "./types/customer-merge-user-error";
import CustomerMergeable from "./types/customer-mergeable";
import CustomerMoment from "./types/customer-moment";
import CustomerMomentConnection from "./types/customer-moment-connection";
import CustomerMomentEdge from "./types/customer-moment-edge";
import CustomerPaymentInstrument from "./types/customer-payment-instrument";
import CustomerPaymentInstrumentBillingAddress from "./types/customer-payment-instrument-billing-address";
import CustomerPaymentMethod from "./types/customer-payment-method";
import CustomerPaymentMethodConnection from "./types/customer-payment-method-connection";
import CustomerPaymentMethodCreateFromDuplicationDataPayload from "./types/customer-payment-method-create-from-duplication-data-payload";
import CustomerPaymentMethodCreateFromDuplicationDataUserError from "./types/customer-payment-method-create-from-duplication-data-user-error";
import CustomerPaymentMethodCreateFromDuplicationDataUserErrorCode from "./types/customer-payment-method-create-from-duplication-data-user-error-code";
import CustomerPaymentMethodCreditCardCreatePayload from "./types/customer-payment-method-credit-card-create-payload";
import CustomerPaymentMethodCreditCardUpdatePayload from "./types/customer-payment-method-credit-card-update-payload";
import CustomerPaymentMethodEdge from "./types/customer-payment-method-edge";
import CustomerPaymentMethodGetDuplicationDataPayload from "./types/customer-payment-method-get-duplication-data-payload";
import CustomerPaymentMethodGetDuplicationDataUserError from "./types/customer-payment-method-get-duplication-data-user-error";
import CustomerPaymentMethodGetDuplicationDataUserErrorCode from "./types/customer-payment-method-get-duplication-data-user-error-code";
import CustomerPaymentMethodGetUpdateUrlPayload from "./types/customer-payment-method-get-update-url-payload";
import CustomerPaymentMethodGetUpdateUrlUserError from "./types/customer-payment-method-get-update-url-user-error";
import CustomerPaymentMethodGetUpdateUrlUserErrorCode from "./types/customer-payment-method-get-update-url-user-error-code";
import CustomerPaymentMethodPaypalBillingAgreementCreatePayload from "./types/customer-payment-method-paypal-billing-agreement-create-payload";
import CustomerPaymentMethodPaypalBillingAgreementUpdatePayload from "./types/customer-payment-method-paypal-billing-agreement-update-payload";
import CustomerPaymentMethodRemoteCreatePayload from "./types/customer-payment-method-remote-create-payload";
import CustomerPaymentMethodRemoteCreditCardCreatePayload from "./types/customer-payment-method-remote-credit-card-create-payload";
import CustomerPaymentMethodRemoteInput from "./types/customer-payment-method-remote-input";
import CustomerPaymentMethodRemoteUserError from "./types/customer-payment-method-remote-user-error";
import CustomerPaymentMethodRemoteUserErrorCode from "./types/customer-payment-method-remote-user-error-code";
import CustomerPaymentMethodRevocationReason from "./types/customer-payment-method-revocation-reason";
import CustomerPaymentMethodRevokePayload from "./types/customer-payment-method-revoke-payload";
import CustomerPaymentMethodSendUpdateEmailPayload from "./types/customer-payment-method-send-update-email-payload";
import CustomerPaymentMethodUserError from "./types/customer-payment-method-user-error";
import CustomerPaymentMethodUserErrorCode from "./types/customer-payment-method-user-error-code";
import CustomerPaypalBillingAgreement from "./types/customer-paypal-billing-agreement";
import CustomerPhoneNumber from "./types/customer-phone-number";
import CustomerPredictedSpendTier from "./types/customer-predicted-spend-tier";
import CustomerProductSubscriberStatus from "./types/customer-product-subscriber-status";
import CustomerRemoveTaxExemptionsPayload from "./types/customer-remove-tax-exemptions-payload";
import CustomerReplaceTaxExemptionsPayload from "./types/customer-replace-tax-exemptions-payload";
import CustomerRequestDataErasureErrorCode from "./types/customer-request-data-erasure-error-code";
import CustomerRequestDataErasurePayload from "./types/customer-request-data-erasure-payload";
import CustomerRequestDataErasureUserError from "./types/customer-request-data-erasure-user-error";
import CustomerSavedSearchSortKeys from "./types/customer-saved-search-sort-keys";
import CustomerSegmentMember from "./types/customer-segment-member";
import CustomerSegmentMemberConnection from "./types/customer-segment-member-connection";
import CustomerSegmentMemberEdge from "./types/customer-segment-member-edge";
import CustomerSegmentMembersQuery from "./types/customer-segment-members-query";
import CustomerSegmentMembersQueryCreatePayload from "./types/customer-segment-members-query-create-payload";
import CustomerSegmentMembersQueryInput from "./types/customer-segment-members-query-input";
import CustomerSegmentMembersQueryUserError from "./types/customer-segment-members-query-user-error";
import CustomerSegmentMembersQueryUserErrorCode from "./types/customer-segment-members-query-user-error-code";
import CustomerShopPayAgreement from "./types/customer-shop-pay-agreement";
import CustomerSmsMarketingConsentError from "./types/customer-sms-marketing-consent-error";
import CustomerSmsMarketingConsentErrorCode from "./types/customer-sms-marketing-consent-error-code";
import CustomerSmsMarketingConsentInput from "./types/customer-sms-marketing-consent-input";
import CustomerSmsMarketingConsentState from "./types/customer-sms-marketing-consent-state";
import CustomerSmsMarketingConsentUpdateInput from "./types/customer-sms-marketing-consent-update-input";
import CustomerSmsMarketingConsentUpdatePayload from "./types/customer-sms-marketing-consent-update-payload";
import CustomerSmsMarketingState from "./types/customer-sms-marketing-state";
import CustomerSortKeys from "./types/customer-sort-keys";
import CustomerState from "./types/customer-state";
import CustomerStatistics from "./types/customer-statistics";
import CustomerUpdateDefaultAddressPayload from "./types/customer-update-default-address-payload";
import CustomerUpdatePayload from "./types/customer-update-payload";
import CustomerVisit from "./types/customer-visit";
import CustomerVisitProductInfo from "./types/customer-visit-product-info";
import CustomerVisitProductInfoConnection from "./types/customer-visit-product-info-connection";
import CustomerVisitProductInfoEdge from "./types/customer-visit-product-info-edge";
import Date from "./types/date";
import DateTime from "./types/date-time";
import DayOfTheWeek from "./types/day-of-the-week";
import Decimal from "./types/decimal";
import DelegateAccessToken from "./types/delegate-access-token";
import DelegateAccessTokenCreatePayload from "./types/delegate-access-token-create-payload";
import DelegateAccessTokenCreateUserError from "./types/delegate-access-token-create-user-error";
import DelegateAccessTokenCreateUserErrorCode from "./types/delegate-access-token-create-user-error-code";
import DelegateAccessTokenDestroyPayload from "./types/delegate-access-token-destroy-payload";
import DelegateAccessTokenDestroyUserError from "./types/delegate-access-token-destroy-user-error";
import DelegateAccessTokenDestroyUserErrorCode from "./types/delegate-access-token-destroy-user-error-code";
import DelegateAccessTokenInput from "./types/delegate-access-token-input";
import DeletionEvent from "./types/deletion-event";
import DeletionEventConnection from "./types/deletion-event-connection";
import DeletionEventEdge from "./types/deletion-event-edge";
import DeletionEventSortKeys from "./types/deletion-event-sort-keys";
import DeletionEventSubjectType from "./types/deletion-event-subject-type";
import DeliveryAvailableService from "./types/delivery-available-service";
import DeliveryBrandedPromise from "./types/delivery-branded-promise";
import DeliveryCarrierService from "./types/delivery-carrier-service";
import DeliveryCarrierServiceAndLocations from "./types/delivery-carrier-service-and-locations";
import DeliveryCondition from "./types/delivery-condition";
import DeliveryConditionCriteria from "./types/delivery-condition-criteria";
import DeliveryConditionField from "./types/delivery-condition-field";
import DeliveryConditionOperator from "./types/delivery-condition-operator";
import DeliveryCountry from "./types/delivery-country";
import DeliveryCountryAndZone from "./types/delivery-country-and-zone";
import DeliveryCountryCodeOrRestOfWorld from "./types/delivery-country-code-or-rest-of-world";
import DeliveryCountryCodesOrRestOfWorld from "./types/delivery-country-codes-or-rest-of-world";
import DeliveryCountryInput from "./types/delivery-country-input";
import DeliveryCustomization from "./types/delivery-customization";
import DeliveryCustomizationActivationPayload from "./types/delivery-customization-activation-payload";
import DeliveryCustomizationConnection from "./types/delivery-customization-connection";
import DeliveryCustomizationCreatePayload from "./types/delivery-customization-create-payload";
import DeliveryCustomizationDeletePayload from "./types/delivery-customization-delete-payload";
import DeliveryCustomizationEdge from "./types/delivery-customization-edge";
import DeliveryCustomizationError from "./types/delivery-customization-error";
import DeliveryCustomizationErrorCode from "./types/delivery-customization-error-code";
import DeliveryCustomizationInput from "./types/delivery-customization-input";
import DeliveryCustomizationUpdatePayload from "./types/delivery-customization-update-payload";
import DeliveryLegacyModeBlocked from "./types/delivery-legacy-mode-blocked";
import DeliveryLegacyModeBlockedReason from "./types/delivery-legacy-mode-blocked-reason";
import DeliveryLocalPickupSettings from "./types/delivery-local-pickup-settings";
import DeliveryLocalPickupTime from "./types/delivery-local-pickup-time";
import DeliveryLocationGroup from "./types/delivery-location-group";
import DeliveryLocationGroupZone from "./types/delivery-location-group-zone";
import DeliveryLocationGroupZoneConnection from "./types/delivery-location-group-zone-connection";
import DeliveryLocationGroupZoneEdge from "./types/delivery-location-group-zone-edge";
import DeliveryLocationGroupZoneInput from "./types/delivery-location-group-zone-input";
import DeliveryLocationLocalPickupEnableInput from "./types/delivery-location-local-pickup-enable-input";
import DeliveryLocationLocalPickupSettingsError from "./types/delivery-location-local-pickup-settings-error";
import DeliveryLocationLocalPickupSettingsErrorCode from "./types/delivery-location-local-pickup-settings-error-code";
import DeliveryMethod from "./types/delivery-method";
import DeliveryMethodAdditionalInformation from "./types/delivery-method-additional-information";
import DeliveryMethodDefinition from "./types/delivery-method-definition";
import DeliveryMethodDefinitionConnection from "./types/delivery-method-definition-connection";
import DeliveryMethodDefinitionCounts from "./types/delivery-method-definition-counts";
import DeliveryMethodDefinitionEdge from "./types/delivery-method-definition-edge";
import DeliveryMethodDefinitionInput from "./types/delivery-method-definition-input";
import DeliveryMethodDefinitionType from "./types/delivery-method-definition-type";
import DeliveryMethodType from "./types/delivery-method-type";
import DeliveryParticipant from "./types/delivery-participant";
import DeliveryParticipantInput from "./types/delivery-participant-input";
import DeliveryParticipantService from "./types/delivery-participant-service";
import DeliveryParticipantServiceInput from "./types/delivery-participant-service-input";
import DeliveryPriceConditionInput from "./types/delivery-price-condition-input";
import DeliveryProductVariantsCount from "./types/delivery-product-variants-count";
import DeliveryProfile from "./types/delivery-profile";
import DeliveryProfileConnection from "./types/delivery-profile-connection";
import DeliveryProfileEdge from "./types/delivery-profile-edge";
import DeliveryProfileInput from "./types/delivery-profile-input";
import DeliveryProfileItem from "./types/delivery-profile-item";
import DeliveryProfileItemConnection from "./types/delivery-profile-item-connection";
import DeliveryProfileItemEdge from "./types/delivery-profile-item-edge";
import DeliveryProfileLocationGroup from "./types/delivery-profile-location-group";
import DeliveryProfileLocationGroupInput from "./types/delivery-profile-location-group-input";
import DeliveryProvince from "./types/delivery-province";
import DeliveryProvinceInput from "./types/delivery-province-input";
import DeliveryRateDefinition from "./types/delivery-rate-definition";
import DeliveryRateDefinitionInput from "./types/delivery-rate-definition-input";
import DeliveryRateProvider from "./types/delivery-rate-provider";
import DeliverySetting from "./types/delivery-setting";
import DeliverySettingInput from "./types/delivery-setting-input";
import DeliverySettingUpdatePayload from "./types/delivery-setting-update-payload";
import DeliveryShippingOriginAssignPayload from "./types/delivery-shipping-origin-assign-payload";
import DeliveryUpdateConditionInput from "./types/delivery-update-condition-input";
import DeliveryWeightConditionInput from "./types/delivery-weight-condition-input";
import DeliveryZone from "./types/delivery-zone";
import DigitalWallet from "./types/digital-wallet";
import Discount from "./types/discount";
import DiscountAllocation from "./types/discount-allocation";
import DiscountAmount from "./types/discount-amount";
import DiscountAmountInput from "./types/discount-amount-input";
import DiscountApplication from "./types/discount-application";
import DiscountApplicationAllocationMethod from "./types/discount-application-allocation-method";
import DiscountApplicationConnection from "./types/discount-application-connection";
import DiscountApplicationEdge from "./types/discount-application-edge";
import DiscountApplicationLevel from "./types/discount-application-level";
import DiscountApplicationTargetSelection from "./types/discount-application-target-selection";
import DiscountApplicationTargetType from "./types/discount-application-target-type";
import DiscountAutomatic from "./types/discount-automatic";
import DiscountAutomaticActivatePayload from "./types/discount-automatic-activate-payload";
import DiscountAutomaticApp from "./types/discount-automatic-app";
import DiscountAutomaticAppCreatePayload from "./types/discount-automatic-app-create-payload";
import DiscountAutomaticAppInput from "./types/discount-automatic-app-input";
import DiscountAutomaticAppUpdatePayload from "./types/discount-automatic-app-update-payload";
import DiscountAutomaticBasic from "./types/discount-automatic-basic";
import DiscountAutomaticBasicCreatePayload from "./types/discount-automatic-basic-create-payload";
import DiscountAutomaticBasicInput from "./types/discount-automatic-basic-input";
import DiscountAutomaticBasicUpdatePayload from "./types/discount-automatic-basic-update-payload";
import DiscountAutomaticBulkDeletePayload from "./types/discount-automatic-bulk-delete-payload";
import DiscountAutomaticBxgy from "./types/discount-automatic-bxgy";
import DiscountAutomaticBxgyCreatePayload from "./types/discount-automatic-bxgy-create-payload";
import DiscountAutomaticBxgyInput from "./types/discount-automatic-bxgy-input";
import DiscountAutomaticBxgyUpdatePayload from "./types/discount-automatic-bxgy-update-payload";
import DiscountAutomaticConnection from "./types/discount-automatic-connection";
import DiscountAutomaticDeactivatePayload from "./types/discount-automatic-deactivate-payload";
import DiscountAutomaticDeletePayload from "./types/discount-automatic-delete-payload";
import DiscountAutomaticEdge from "./types/discount-automatic-edge";
import DiscountAutomaticFreeShipping from "./types/discount-automatic-free-shipping";
import DiscountAutomaticFreeShippingCreatePayload from "./types/discount-automatic-free-shipping-create-payload";
import DiscountAutomaticFreeShippingInput from "./types/discount-automatic-free-shipping-input";
import DiscountAutomaticFreeShippingUpdatePayload from "./types/discount-automatic-free-shipping-update-payload";
import DiscountAutomaticNode from "./types/discount-automatic-node";
import DiscountAutomaticNodeConnection from "./types/discount-automatic-node-connection";
import DiscountAutomaticNodeEdge from "./types/discount-automatic-node-edge";
import DiscountClass from "./types/discount-class";
import DiscountCode from "./types/discount-code";
import DiscountCodeActivatePayload from "./types/discount-code-activate-payload";
import DiscountCodeApp from "./types/discount-code-app";
import DiscountCodeAppCreatePayload from "./types/discount-code-app-create-payload";
import DiscountCodeAppInput from "./types/discount-code-app-input";
import DiscountCodeAppUpdatePayload from "./types/discount-code-app-update-payload";
import DiscountCodeApplication from "./types/discount-code-application";
import DiscountCodeBasic from "./types/discount-code-basic";
import DiscountCodeBasicCreatePayload from "./types/discount-code-basic-create-payload";
import DiscountCodeBasicInput from "./types/discount-code-basic-input";
import DiscountCodeBasicUpdatePayload from "./types/discount-code-basic-update-payload";
import DiscountCodeBulkActivatePayload from "./types/discount-code-bulk-activate-payload";
import DiscountCodeBulkDeactivatePayload from "./types/discount-code-bulk-deactivate-payload";
import DiscountCodeBulkDeletePayload from "./types/discount-code-bulk-delete-payload";
import DiscountCodeBxgy from "./types/discount-code-bxgy";
import DiscountCodeBxgyCreatePayload from "./types/discount-code-bxgy-create-payload";
import DiscountCodeBxgyInput from "./types/discount-code-bxgy-input";
import DiscountCodeBxgyUpdatePayload from "./types/discount-code-bxgy-update-payload";
import DiscountCodeDeactivatePayload from "./types/discount-code-deactivate-payload";
import DiscountCodeDeletePayload from "./types/discount-code-delete-payload";
import DiscountCodeFreeShipping from "./types/discount-code-free-shipping";
import DiscountCodeFreeShippingCreatePayload from "./types/discount-code-free-shipping-create-payload";
import DiscountCodeFreeShippingInput from "./types/discount-code-free-shipping-input";
import DiscountCodeFreeShippingUpdatePayload from "./types/discount-code-free-shipping-update-payload";
import DiscountCodeNode from "./types/discount-code-node";
import DiscountCodeNodeConnection from "./types/discount-code-node-connection";
import DiscountCodeNodeEdge from "./types/discount-code-node-edge";
import DiscountCodeRedeemCodeBulkDeletePayload from "./types/discount-code-redeem-code-bulk-delete-payload";
import DiscountCodeSortKeys from "./types/discount-code-sort-keys";
import DiscountCollections from "./types/discount-collections";
import DiscountCollectionsInput from "./types/discount-collections-input";
import DiscountCombinesWith from "./types/discount-combines-with";
import DiscountCombinesWithInput from "./types/discount-combines-with-input";
import DiscountCountries from "./types/discount-countries";
import DiscountCountriesInput from "./types/discount-countries-input";
import DiscountCountryAll from "./types/discount-country-all";
import DiscountCustomerAll from "./types/discount-customer-all";
import DiscountCustomerBuys from "./types/discount-customer-buys";
import DiscountCustomerBuysInput from "./types/discount-customer-buys-input";
import DiscountCustomerBuysValue from "./types/discount-customer-buys-value";
import DiscountCustomerBuysValueInput from "./types/discount-customer-buys-value-input";
import DiscountCustomerGets from "./types/discount-customer-gets";
import DiscountCustomerGetsInput from "./types/discount-customer-gets-input";
import DiscountCustomerGetsValue from "./types/discount-customer-gets-value";
import DiscountCustomerGetsValueInput from "./types/discount-customer-gets-value-input";
import DiscountCustomerSegments from "./types/discount-customer-segments";
import DiscountCustomerSegmentsInput from "./types/discount-customer-segments-input";
import DiscountCustomerSelection from "./types/discount-customer-selection";
import DiscountCustomerSelectionInput from "./types/discount-customer-selection-input";
import DiscountCustomers from "./types/discount-customers";
import DiscountCustomersInput from "./types/discount-customers-input";
import DiscountEffect from "./types/discount-effect";
import DiscountEffectInput from "./types/discount-effect-input";
import DiscountErrorCode from "./types/discount-error-code";
import DiscountItems from "./types/discount-items";
import DiscountItemsInput from "./types/discount-items-input";
import DiscountMinimumQuantity from "./types/discount-minimum-quantity";
import DiscountMinimumQuantityInput from "./types/discount-minimum-quantity-input";
import DiscountMinimumRequirement from "./types/discount-minimum-requirement";
import DiscountMinimumRequirementInput from "./types/discount-minimum-requirement-input";
import DiscountMinimumSubtotal from "./types/discount-minimum-subtotal";
import DiscountMinimumSubtotalInput from "./types/discount-minimum-subtotal-input";
import DiscountNode from "./types/discount-node";
import DiscountNodeConnection from "./types/discount-node-connection";
import DiscountNodeEdge from "./types/discount-node-edge";
import DiscountOnQuantity from "./types/discount-on-quantity";
import DiscountOnQuantityInput from "./types/discount-on-quantity-input";
import DiscountPercentage from "./types/discount-percentage";
import DiscountProducts from "./types/discount-products";
import DiscountProductsInput from "./types/discount-products-input";
import DiscountPurchaseAmount from "./types/discount-purchase-amount";
import DiscountQuantity from "./types/discount-quantity";
import DiscountRedeemCode from "./types/discount-redeem-code";
import DiscountRedeemCodeBulkAddPayload from "./types/discount-redeem-code-bulk-add-payload";
import DiscountRedeemCodeBulkCreation from "./types/discount-redeem-code-bulk-creation";
import DiscountRedeemCodeBulkCreationCode from "./types/discount-redeem-code-bulk-creation-code";
import DiscountRedeemCodeBulkCreationCodeConnection from "./types/discount-redeem-code-bulk-creation-code-connection";
import DiscountRedeemCodeBulkCreationCodeEdge from "./types/discount-redeem-code-bulk-creation-code-edge";
import DiscountRedeemCodeConnection from "./types/discount-redeem-code-connection";
import DiscountRedeemCodeEdge from "./types/discount-redeem-code-edge";
import DiscountRedeemCodeInput from "./types/discount-redeem-code-input";
import DiscountShareableUrl from "./types/discount-shareable-url";
import DiscountShareableUrlTargetType from "./types/discount-shareable-url-target-type";
import DiscountShippingDestinationSelection from "./types/discount-shipping-destination-selection";
import DiscountShippingDestinationSelectionInput from "./types/discount-shipping-destination-selection-input";
import DiscountSortKeys from "./types/discount-sort-keys";
import DiscountStatus from "./types/discount-status";
import DiscountTargetType from "./types/discount-target-type";
import DiscountType from "./types/discount-type";
import DiscountUserError from "./types/discount-user-error";
import DisplayableError from "./types/displayable-error";
import DisputeEvidenceUpdatePayload from "./types/dispute-evidence-update-payload";
import DisputeEvidenceUpdateUserError from "./types/dispute-evidence-update-user-error";
import DisputeEvidenceUpdateUserErrorCode from "./types/dispute-evidence-update-user-error-code";
import DisputeStatus from "./types/dispute-status";
import DisputeType from "./types/dispute-type";
import Domain from "./types/domain";
import DomainLocalization from "./types/domain-localization";
import DraftOrder from "./types/draft-order";
import DraftOrderAppliedDiscount from "./types/draft-order-applied-discount";
import DraftOrderAppliedDiscountInput from "./types/draft-order-applied-discount-input";
import DraftOrderAppliedDiscountType from "./types/draft-order-applied-discount-type";
import DraftOrderBulkAddTagsPayload from "./types/draft-order-bulk-add-tags-payload";
import DraftOrderBulkDeletePayload from "./types/draft-order-bulk-delete-payload";
import DraftOrderBulkRemoveTagsPayload from "./types/draft-order-bulk-remove-tags-payload";
import DraftOrderCalculatePayload from "./types/draft-order-calculate-payload";
import DraftOrderCompletePayload from "./types/draft-order-complete-payload";
import DraftOrderConnection from "./types/draft-order-connection";
import DraftOrderCreateFromOrderPayload from "./types/draft-order-create-from-order-payload";
import DraftOrderCreateMerchantCheckoutPayload from "./types/draft-order-create-merchant-checkout-payload";
import DraftOrderCreatePayload from "./types/draft-order-create-payload";
import DraftOrderDeleteInput from "./types/draft-order-delete-input";
import DraftOrderDeletePayload from "./types/draft-order-delete-payload";
import DraftOrderDuplicatePayload from "./types/draft-order-duplicate-payload";
import DraftOrderEdge from "./types/draft-order-edge";
import DraftOrderInput from "./types/draft-order-input";
import DraftOrderInvoicePreviewPayload from "./types/draft-order-invoice-preview-payload";
import DraftOrderInvoiceSendPayload from "./types/draft-order-invoice-send-payload";
import DraftOrderLineItem from "./types/draft-order-line-item";
import DraftOrderLineItemConnection from "./types/draft-order-line-item-connection";
import DraftOrderLineItemEdge from "./types/draft-order-line-item-edge";
import DraftOrderLineItemInput from "./types/draft-order-line-item-input";
import DraftOrderSortKeys from "./types/draft-order-sort-keys";
import DraftOrderStatus from "./types/draft-order-status";
import DraftOrderTag from "./types/draft-order-tag";
import DraftOrderUpdatePayload from "./types/draft-order-update-payload";
import Duty from "./types/duty";
import DutySale from "./types/duty-sale";
import EditableProperty from "./types/editable-property";
import EmailInput from "./types/email-input";
import ErrorPosition from "./types/error-position";
import ErrorsServerPixelUserError from "./types/errors-server-pixel-user-error";
import ErrorsServerPixelUserErrorCode from "./types/errors-server-pixel-user-error-code";
import ErrorsWebPixelUserError from "./types/errors-web-pixel-user-error";
import ErrorsWebPixelUserErrorCode from "./types/errors-web-pixel-user-error-code";
import Event from "./types/event";
import EventBridgeServerPixelUpdatePayload from "./types/event-bridge-server-pixel-update-payload";
import EventBridgeWebhookSubscriptionCreatePayload from "./types/event-bridge-webhook-subscription-create-payload";
import EventBridgeWebhookSubscriptionInput from "./types/event-bridge-webhook-subscription-input";
import EventBridgeWebhookSubscriptionUpdatePayload from "./types/event-bridge-webhook-subscription-update-payload";
import EventConnection from "./types/event-connection";
import EventEdge from "./types/event-edge";
import EventSortKeys from "./types/event-sort-keys";
import ExchangeLineItem from "./types/exchange-line-item";
import ExchangeLineItemConnection from "./types/exchange-line-item-connection";
import ExchangeLineItemEdge from "./types/exchange-line-item-edge";
import ExchangeV2 from "./types/exchange-v-2";
import ExchangeV2Additions from "./types/exchange-v-2-additions";
import ExchangeV2Connection from "./types/exchange-v-2-connection";
import ExchangeV2Edge from "./types/exchange-v-2-edge";
import ExchangeV2LineItem from "./types/exchange-v-2-line-item";
import ExchangeV2Returns from "./types/exchange-v-2-returns";
import ExternalVideo from "./types/external-video";
import FailedRequirement from "./types/failed-requirement";
import Fee from "./types/fee";
import FeeSale from "./types/fee-sale";
import File from "./types/file";
import FileAcknowledgeUpdateFailedPayload from "./types/file-acknowledge-update-failed-payload";
import FileConnection from "./types/file-connection";
import FileContentType from "./types/file-content-type";
import FileCreateInput from "./types/file-create-input";
import FileCreateInputDuplicateResolutionMode from "./types/file-create-input-duplicate-resolution-mode";
import FileCreatePayload from "./types/file-create-payload";
import FileDeletePayload from "./types/file-delete-payload";
import FileEdge from "./types/file-edge";
import FileError from "./types/file-error";
import FileErrorCode from "./types/file-error-code";
import FileSortKeys from "./types/file-sort-keys";
import FileStatus from "./types/file-status";
import FileUpdateInput from "./types/file-update-input";
import FileUpdatePayload from "./types/file-update-payload";
import FilesErrorCode from "./types/files-error-code";
import FilesUserError from "./types/files-user-error";
import FilterOption from "./types/filter-option";
import FinancialSummaryDiscountAllocation from "./types/financial-summary-discount-allocation";
import FinancialSummaryDiscountApplication from "./types/financial-summary-discount-application";
import Float from "./types/float";
import FlowGenerateSignaturePayload from "./types/flow-generate-signature-payload";
import FlowTriggerReceivePayload from "./types/flow-trigger-receive-payload";
import FormattedString from "./types/formatted-string";
import Fulfillment from "./types/fulfillment";
import FulfillmentCancelPayload from "./types/fulfillment-cancel-payload";
import FulfillmentConnection from "./types/fulfillment-connection";
import FulfillmentConstraintRule from "./types/fulfillment-constraint-rule";
import FulfillmentConstraintRuleCreatePayload from "./types/fulfillment-constraint-rule-create-payload";
import FulfillmentConstraintRuleCreateUserError from "./types/fulfillment-constraint-rule-create-user-error";
import FulfillmentConstraintRuleCreateUserErrorCode from "./types/fulfillment-constraint-rule-create-user-error-code";
import FulfillmentConstraintRuleDeletePayload from "./types/fulfillment-constraint-rule-delete-payload";
import FulfillmentConstraintRuleDeleteUserError from "./types/fulfillment-constraint-rule-delete-user-error";
import FulfillmentConstraintRuleDeleteUserErrorCode from "./types/fulfillment-constraint-rule-delete-user-error-code";
import FulfillmentCreateV2Payload from "./types/fulfillment-create-v-2-payload";
import FulfillmentDisplayStatus from "./types/fulfillment-display-status";
import FulfillmentEdge from "./types/fulfillment-edge";
import FulfillmentEvent from "./types/fulfillment-event";
import FulfillmentEventConnection from "./types/fulfillment-event-connection";
import FulfillmentEventCreatePayload from "./types/fulfillment-event-create-payload";
import FulfillmentEventEdge from "./types/fulfillment-event-edge";
import FulfillmentEventInput from "./types/fulfillment-event-input";
import FulfillmentEventSortKeys from "./types/fulfillment-event-sort-keys";
import FulfillmentEventStatus from "./types/fulfillment-event-status";
import FulfillmentHold from "./types/fulfillment-hold";
import FulfillmentHoldReason from "./types/fulfillment-hold-reason";
import FulfillmentLineItem from "./types/fulfillment-line-item";
import FulfillmentLineItemConnection from "./types/fulfillment-line-item-connection";
import FulfillmentLineItemEdge from "./types/fulfillment-line-item-edge";
import FulfillmentOrder from "./types/fulfillment-order";
import FulfillmentOrderAcceptCancellationRequestPayload from "./types/fulfillment-order-accept-cancellation-request-payload";
import FulfillmentOrderAcceptFulfillmentRequestPayload from "./types/fulfillment-order-accept-fulfillment-request-payload";
import FulfillmentOrderAction from "./types/fulfillment-order-action";
import FulfillmentOrderAssignedLocation from "./types/fulfillment-order-assigned-location";
import FulfillmentOrderAssignmentStatus from "./types/fulfillment-order-assignment-status";
import FulfillmentOrderCancelPayload from "./types/fulfillment-order-cancel-payload";
import FulfillmentOrderClosePayload from "./types/fulfillment-order-close-payload";
import FulfillmentOrderConnection from "./types/fulfillment-order-connection";
import FulfillmentOrderDestination from "./types/fulfillment-order-destination";
import FulfillmentOrderEdge from "./types/fulfillment-order-edge";
import FulfillmentOrderHoldInput from "./types/fulfillment-order-hold-input";
import FulfillmentOrderHoldPayload from "./types/fulfillment-order-hold-payload";
import FulfillmentOrderHoldUserError from "./types/fulfillment-order-hold-user-error";
import FulfillmentOrderHoldUserErrorCode from "./types/fulfillment-order-hold-user-error-code";
import FulfillmentOrderInternationalDuties from "./types/fulfillment-order-international-duties";
import FulfillmentOrderLineItem from "./types/fulfillment-order-line-item";
import FulfillmentOrderLineItemConnection from "./types/fulfillment-order-line-item-connection";
import FulfillmentOrderLineItemEdge from "./types/fulfillment-order-line-item-edge";
import FulfillmentOrderLineItemFinancialSummary from "./types/fulfillment-order-line-item-financial-summary";
import FulfillmentOrderLineItemInput from "./types/fulfillment-order-line-item-input";
import FulfillmentOrderLineItemWarning from "./types/fulfillment-order-line-item-warning";
import FulfillmentOrderLineItemsInput from "./types/fulfillment-order-line-items-input";
import FulfillmentOrderLineItemsPreparedForPickupInput from "./types/fulfillment-order-line-items-prepared-for-pickup-input";
import FulfillmentOrderLineItemsPreparedForPickupPayload from "./types/fulfillment-order-line-items-prepared-for-pickup-payload";
import FulfillmentOrderLineItemsPreparedForPickupUserError from "./types/fulfillment-order-line-items-prepared-for-pickup-user-error";
import FulfillmentOrderLineItemsPreparedForPickupUserErrorCode from "./types/fulfillment-order-line-items-prepared-for-pickup-user-error-code";
import FulfillmentOrderLocationForMove from "./types/fulfillment-order-location-for-move";
import FulfillmentOrderLocationForMoveConnection from "./types/fulfillment-order-location-for-move-connection";
import FulfillmentOrderLocationForMoveEdge from "./types/fulfillment-order-location-for-move-edge";
import FulfillmentOrderMerchantRequest from "./types/fulfillment-order-merchant-request";
import FulfillmentOrderMerchantRequestConnection from "./types/fulfillment-order-merchant-request-connection";
import FulfillmentOrderMerchantRequestEdge from "./types/fulfillment-order-merchant-request-edge";
import FulfillmentOrderMerchantRequestKind from "./types/fulfillment-order-merchant-request-kind";
import FulfillmentOrderMergeInput from "./types/fulfillment-order-merge-input";
import FulfillmentOrderMergeInputMergeIntent from "./types/fulfillment-order-merge-input-merge-intent";
import FulfillmentOrderMergePayload from "./types/fulfillment-order-merge-payload";
import FulfillmentOrderMergeResult from "./types/fulfillment-order-merge-result";
import FulfillmentOrderMergeUserError from "./types/fulfillment-order-merge-user-error";
import FulfillmentOrderMergeUserErrorCode from "./types/fulfillment-order-merge-user-error-code";
import FulfillmentOrderMovePayload from "./types/fulfillment-order-move-payload";
import FulfillmentOrderOpenPayload from "./types/fulfillment-order-open-payload";
import FulfillmentOrderRejectCancellationRequestPayload from "./types/fulfillment-order-reject-cancellation-request-payload";
import FulfillmentOrderRejectFulfillmentRequestPayload from "./types/fulfillment-order-reject-fulfillment-request-payload";
import FulfillmentOrderRejectionReason from "./types/fulfillment-order-rejection-reason";
import FulfillmentOrderReleaseHoldPayload from "./types/fulfillment-order-release-hold-payload";
import FulfillmentOrderReleaseHoldUserError from "./types/fulfillment-order-release-hold-user-error";
import FulfillmentOrderReleaseHoldUserErrorCode from "./types/fulfillment-order-release-hold-user-error-code";
import FulfillmentOrderRequestStatus from "./types/fulfillment-order-request-status";
import FulfillmentOrderReschedulePayload from "./types/fulfillment-order-reschedule-payload";
import FulfillmentOrderRescheduleUserError from "./types/fulfillment-order-reschedule-user-error";
import FulfillmentOrderRescheduleUserErrorCode from "./types/fulfillment-order-reschedule-user-error-code";
import FulfillmentOrderSortKeys from "./types/fulfillment-order-sort-keys";
import FulfillmentOrderSplitInput from "./types/fulfillment-order-split-input";
import FulfillmentOrderSplitPayload from "./types/fulfillment-order-split-payload";
import FulfillmentOrderSplitResult from "./types/fulfillment-order-split-result";
import FulfillmentOrderSplitUserError from "./types/fulfillment-order-split-user-error";
import FulfillmentOrderSplitUserErrorCode from "./types/fulfillment-order-split-user-error-code";
import FulfillmentOrderStatus from "./types/fulfillment-order-status";
import FulfillmentOrderSubmitCancellationRequestPayload from "./types/fulfillment-order-submit-cancellation-request-payload";
import FulfillmentOrderSubmitFulfillmentRequestPayload from "./types/fulfillment-order-submit-fulfillment-request-payload";
import FulfillmentOrderSupportedAction from "./types/fulfillment-order-supported-action";
import FulfillmentOrdersReleaseHoldsPayload from "./types/fulfillment-orders-release-holds-payload";
import FulfillmentOrdersReleaseHoldsUserError from "./types/fulfillment-orders-release-holds-user-error";
import FulfillmentOrdersReleaseHoldsUserErrorCode from "./types/fulfillment-orders-release-holds-user-error-code";
import FulfillmentOrdersSetFulfillmentDeadlinePayload from "./types/fulfillment-orders-set-fulfillment-deadline-payload";
import FulfillmentOrdersSetFulfillmentDeadlineUserError from "./types/fulfillment-orders-set-fulfillment-deadline-user-error";
import FulfillmentOrdersSetFulfillmentDeadlineUserErrorCode from "./types/fulfillment-orders-set-fulfillment-deadline-user-error-code";
import FulfillmentOriginAddress from "./types/fulfillment-origin-address";
import FulfillmentOriginAddressInput from "./types/fulfillment-origin-address-input";
import FulfillmentService from "./types/fulfillment-service";
import FulfillmentServiceCreatePayload from "./types/fulfillment-service-create-payload";
import FulfillmentServiceDeletePayload from "./types/fulfillment-service-delete-payload";
import FulfillmentServiceType from "./types/fulfillment-service-type";
import FulfillmentServiceUpdatePayload from "./types/fulfillment-service-update-payload";
import FulfillmentStatus from "./types/fulfillment-status";
import FulfillmentTrackingInfo from "./types/fulfillment-tracking-info";
import FulfillmentTrackingInfoUpdateV2Payload from "./types/fulfillment-tracking-info-update-v-2-payload";
import FulfillmentTrackingInput from "./types/fulfillment-tracking-input";
import FulfillmentV2Input from "./types/fulfillment-v-2-input";
import FunctionsAppBridge from "./types/functions-app-bridge";
import FunctionsErrorHistory from "./types/functions-error-history";
import GenericFile from "./types/generic-file";
import GiftCard from "./types/gift-card";
import GiftCardConnection from "./types/gift-card-connection";
import GiftCardCreateInput from "./types/gift-card-create-input";
import GiftCardCreatePayload from "./types/gift-card-create-payload";
import GiftCardDisablePayload from "./types/gift-card-disable-payload";
import GiftCardEdge from "./types/gift-card-edge";
import GiftCardErrorCode from "./types/gift-card-error-code";
import GiftCardSale from "./types/gift-card-sale";
import GiftCardSortKeys from "./types/gift-card-sort-keys";
import GiftCardUpdateInput from "./types/gift-card-update-input";
import GiftCardUpdatePayload from "./types/gift-card-update-payload";
import GiftCardUserError from "./types/gift-card-user-error";
import HTML from "./types/html";
import HasEvents from "./types/has-events";
import HasLocalizationExtensions from "./types/has-localization-extensions";
import HasMetafieldDefinitions from "./types/has-metafield-definitions";
import HasMetafields from "./types/has-metafields";
import HasPublishedTranslations from "./types/has-published-translations";
import ID from "./types/id";
import Image from "./types/image";
import ImageConnection from "./types/image-connection";
import ImageContentType from "./types/image-content-type";
import ImageEdge from "./types/image-edge";
import ImageInput from "./types/image-input";
import ImageTransformInput from "./types/image-transform-input";
import ImageUploadParameter from "./types/image-upload-parameter";
import IncomingRequestLineItemInput from "./types/incoming-request-line-item-input";
import Int from "./types/int";
import InventoryActivatePayload from "./types/inventory-activate-payload";
import InventoryAdjustQuantitiesInput from "./types/inventory-adjust-quantities-input";
import InventoryAdjustQuantitiesPayload from "./types/inventory-adjust-quantities-payload";
import InventoryAdjustQuantitiesUserError from "./types/inventory-adjust-quantities-user-error";
import InventoryAdjustQuantitiesUserErrorCode from "./types/inventory-adjust-quantities-user-error-code";
import InventoryAdjustmentGroup from "./types/inventory-adjustment-group";
import InventoryBulkToggleActivationInput from "./types/inventory-bulk-toggle-activation-input";
import InventoryBulkToggleActivationPayload from "./types/inventory-bulk-toggle-activation-payload";
import InventoryBulkToggleActivationUserError from "./types/inventory-bulk-toggle-activation-user-error";
import InventoryBulkToggleActivationUserErrorCode from "./types/inventory-bulk-toggle-activation-user-error-code";
import InventoryChange from "./types/inventory-change";
import InventoryChangeInput from "./types/inventory-change-input";
import InventoryDeactivatePayload from "./types/inventory-deactivate-payload";
import InventoryItem from "./types/inventory-item";
import InventoryItemConnection from "./types/inventory-item-connection";
import InventoryItemEdge from "./types/inventory-item-edge";
import InventoryItemInput from "./types/inventory-item-input";
import InventoryItemMeasurement from "./types/inventory-item-measurement";
import InventoryItemMeasurementInput from "./types/inventory-item-measurement-input";
import InventoryItemUpdateInput from "./types/inventory-item-update-input";
import InventoryItemUpdatePayload from "./types/inventory-item-update-payload";
import InventoryLevel from "./types/inventory-level";
import InventoryLevelConnection from "./types/inventory-level-connection";
import InventoryLevelEdge from "./types/inventory-level-edge";
import InventoryLevelInput from "./types/inventory-level-input";
import InventoryMoveQuantitiesInput from "./types/inventory-move-quantities-input";
import InventoryMoveQuantitiesPayload from "./types/inventory-move-quantities-payload";
import InventoryMoveQuantitiesUserError from "./types/inventory-move-quantities-user-error";
import InventoryMoveQuantitiesUserErrorCode from "./types/inventory-move-quantities-user-error-code";
import InventoryMoveQuantityChange from "./types/inventory-move-quantity-change";
import InventoryMoveQuantityTerminalInput from "./types/inventory-move-quantity-terminal-input";
import InventoryProperties from "./types/inventory-properties";
import InventoryQuantity from "./types/inventory-quantity";
import InventoryQuantityName from "./types/inventory-quantity-name";
import InventoryScheduledChange from "./types/inventory-scheduled-change";
import InventoryScheduledChangeConnection from "./types/inventory-scheduled-change-connection";
import InventoryScheduledChangeEdge from "./types/inventory-scheduled-change-edge";
import InventoryScheduledChangeInput from "./types/inventory-scheduled-change-input";
import InventoryScheduledChangeItemInput from "./types/inventory-scheduled-change-item-input";
import InventorySetOnHandQuantitiesInput from "./types/inventory-set-on-hand-quantities-input";
import InventorySetOnHandQuantitiesPayload from "./types/inventory-set-on-hand-quantities-payload";
import InventorySetOnHandQuantitiesUserError from "./types/inventory-set-on-hand-quantities-user-error";
import InventorySetOnHandQuantitiesUserErrorCode from "./types/inventory-set-on-hand-quantities-user-error-code";
import InventorySetQuantityInput from "./types/inventory-set-quantity-input";
import InventorySetScheduledChangesInput from "./types/inventory-set-scheduled-changes-input";
import InventorySetScheduledChangesPayload from "./types/inventory-set-scheduled-changes-payload";
import InventorySetScheduledChangesUserError from "./types/inventory-set-scheduled-changes-user-error";
import InventorySetScheduledChangesUserErrorCode from "./types/inventory-set-scheduled-changes-user-error-code";
import JSON from "./types/json";
import Job from "./types/job";
import JobResult from "./types/job-result";
import LanguageCode from "./types/language-code";
import LegacyInteroperability from "./types/legacy-interoperability";
import LengthUnit from "./types/length-unit";
import LimitedPendingOrderCount from "./types/limited-pending-order-count";
import LineItem from "./types/line-item";
import LineItemConnection from "./types/line-item-connection";
import LineItemEdge from "./types/line-item-edge";
import LineItemGroup from "./types/line-item-group";
import LineItemMutable from "./types/line-item-mutable";
import LineItemMutableConnection from "./types/line-item-mutable-connection";
import LineItemMutableEdge from "./types/line-item-mutable-edge";
import LineItemSellingPlan from "./types/line-item-selling-plan";
import Link from "./types/link";
import LinkedMetafield from "./types/linked-metafield";
import LinkedMetafieldCreateInput from "./types/linked-metafield-create-input";
import LinkedMetafieldUpdateInput from "./types/linked-metafield-update-input";
import Locale from "./types/locale";
import LocalizableContentType from "./types/localizable-content-type";
import LocalizationExtension from "./types/localization-extension";
import LocalizationExtensionConnection from "./types/localization-extension-connection";
import LocalizationExtensionEdge from "./types/localization-extension-edge";
import LocalizationExtensionInput from "./types/localization-extension-input";
import LocalizationExtensionKey from "./types/localization-extension-key";
import LocalizationExtensionPurpose from "./types/localization-extension-purpose";
import Location from "./types/location";
import LocationActivatePayload from "./types/location-activate-payload";
import LocationActivateUserError from "./types/location-activate-user-error";
import LocationActivateUserErrorCode from "./types/location-activate-user-error-code";
import LocationAddAddressInput from "./types/location-add-address-input";
import LocationAddInput from "./types/location-add-input";
import LocationAddPayload from "./types/location-add-payload";
import LocationAddUserError from "./types/location-add-user-error";
import LocationAddUserErrorCode from "./types/location-add-user-error-code";
import LocationAddress from "./types/location-address";
import LocationConnection from "./types/location-connection";
import LocationDeactivatePayload from "./types/location-deactivate-payload";
import LocationDeactivateUserError from "./types/location-deactivate-user-error";
import LocationDeactivateUserErrorCode from "./types/location-deactivate-user-error-code";
import LocationDeletePayload from "./types/location-delete-payload";
import LocationDeleteUserError from "./types/location-delete-user-error";
import LocationDeleteUserErrorCode from "./types/location-delete-user-error-code";
import LocationEdge from "./types/location-edge";
import LocationEditAddressInput from "./types/location-edit-address-input";
import LocationEditInput from "./types/location-edit-input";
import LocationEditPayload from "./types/location-edit-payload";
import LocationEditUserError from "./types/location-edit-user-error";
import LocationEditUserErrorCode from "./types/location-edit-user-error-code";
import LocationLocalPickupDisablePayload from "./types/location-local-pickup-disable-payload";
import LocationLocalPickupEnablePayload from "./types/location-local-pickup-enable-payload";
import LocationSortKeys from "./types/location-sort-keys";
import LocationSuggestedAddress from "./types/location-suggested-address";
import MailingAddress from "./types/mailing-address";
import MailingAddressConnection from "./types/mailing-address-connection";
import MailingAddressEdge from "./types/mailing-address-edge";
import MailingAddressInput from "./types/mailing-address-input";
import ManualDiscountApplication from "./types/manual-discount-application";
import Market from "./types/market";
import MarketCatalog from "./types/market-catalog";
import MarketCatalogConnection from "./types/market-catalog-connection";
import MarketCatalogEdge from "./types/market-catalog-edge";
import MarketConnection from "./types/market-connection";
import MarketCreateInput from "./types/market-create-input";
import MarketCreatePayload from "./types/market-create-payload";
import MarketCurrencySettings from "./types/market-currency-settings";
import MarketCurrencySettingsUpdateInput from "./types/market-currency-settings-update-input";
import MarketCurrencySettingsUpdatePayload from "./types/market-currency-settings-update-payload";
import MarketCurrencySettingsUserError from "./types/market-currency-settings-user-error";
import MarketCurrencySettingsUserErrorCode from "./types/market-currency-settings-user-error-code";
import MarketDeletePayload from "./types/market-delete-payload";
import MarketEdge from "./types/market-edge";
import MarketLocalizableContent from "./types/market-localizable-content";
import MarketLocalizableResource from "./types/market-localizable-resource";
import MarketLocalizableResourceConnection from "./types/market-localizable-resource-connection";
import MarketLocalizableResourceEdge from "./types/market-localizable-resource-edge";
import MarketLocalizableResourceType from "./types/market-localizable-resource-type";
import MarketLocalization from "./types/market-localization";
import MarketLocalizationRegisterInput from "./types/market-localization-register-input";
import MarketLocalizationsRegisterPayload from "./types/market-localizations-register-payload";
import MarketLocalizationsRemovePayload from "./types/market-localizations-remove-payload";
import MarketRegion from "./types/market-region";
import MarketRegionConnection from "./types/market-region-connection";
import MarketRegionCountry from "./types/market-region-country";
import MarketRegionCreateInput from "./types/market-region-create-input";
import MarketRegionDeletePayload from "./types/market-region-delete-payload";
import MarketRegionEdge from "./types/market-region-edge";
import MarketRegionsCreatePayload from "./types/market-regions-create-payload";
import MarketRegionsDeletePayload from "./types/market-regions-delete-payload";
import MarketUpdateInput from "./types/market-update-input";
import MarketUpdatePayload from "./types/market-update-payload";
import MarketUserError from "./types/market-user-error";
import MarketUserErrorCode from "./types/market-user-error-code";
import MarketWebPresence from "./types/market-web-presence";
import MarketWebPresenceConnection from "./types/market-web-presence-connection";
import MarketWebPresenceCreateInput from "./types/market-web-presence-create-input";
import MarketWebPresenceCreatePayload from "./types/market-web-presence-create-payload";
import MarketWebPresenceDeletePayload from "./types/market-web-presence-delete-payload";
import MarketWebPresenceEdge from "./types/market-web-presence-edge";
import MarketWebPresenceRootUrl from "./types/market-web-presence-root-url";
import MarketWebPresenceUpdateInput from "./types/market-web-presence-update-input";
import MarketWebPresenceUpdatePayload from "./types/market-web-presence-update-payload";
import MarketingActivitiesDeleteAllExternalPayload from "./types/marketing-activities-delete-all-external-payload";
import MarketingActivity from "./types/marketing-activity";
import MarketingActivityBudgetInput from "./types/marketing-activity-budget-input";
import MarketingActivityConnection from "./types/marketing-activity-connection";
import MarketingActivityCreateExternalInput from "./types/marketing-activity-create-external-input";
import MarketingActivityCreateExternalPayload from "./types/marketing-activity-create-external-payload";
import MarketingActivityCreateInput from "./types/marketing-activity-create-input";
import MarketingActivityCreatePayload from "./types/marketing-activity-create-payload";
import MarketingActivityDeleteExternalPayload from "./types/marketing-activity-delete-external-payload";
import MarketingActivityEdge from "./types/marketing-activity-edge";
import MarketingActivityExtensionAppErrorCode from "./types/marketing-activity-extension-app-error-code";
import MarketingActivityExtensionAppErrors from "./types/marketing-activity-extension-app-errors";
import MarketingActivityExternalStatus from "./types/marketing-activity-external-status";
import MarketingActivityHierarchyLevel from "./types/marketing-activity-hierarchy-level";
import MarketingActivitySortKeys from "./types/marketing-activity-sort-keys";
import MarketingActivityStatus from "./types/marketing-activity-status";
import MarketingActivityStatusBadgeType from "./types/marketing-activity-status-badge-type";
import MarketingActivityUpdateExternalInput from "./types/marketing-activity-update-external-input";
import MarketingActivityUpdateExternalPayload from "./types/marketing-activity-update-external-payload";
import MarketingActivityUpdateInput from "./types/marketing-activity-update-input";
import MarketingActivityUpdatePayload from "./types/marketing-activity-update-payload";
import MarketingActivityUpsertExternalInput from "./types/marketing-activity-upsert-external-input";
import MarketingActivityUpsertExternalPayload from "./types/marketing-activity-upsert-external-payload";
import MarketingActivityUserError from "./types/marketing-activity-user-error";
import MarketingActivityUserErrorCode from "./types/marketing-activity-user-error-code";
import MarketingBudget from "./types/marketing-budget";
import MarketingBudgetBudgetType from "./types/marketing-budget-budget-type";
import MarketingChannel from "./types/marketing-channel";
import MarketingEngagement from "./types/marketing-engagement";
import MarketingEngagementCreatePayload from "./types/marketing-engagement-create-payload";
import MarketingEngagementInput from "./types/marketing-engagement-input";
import MarketingEngagementsDeletePayload from "./types/marketing-engagements-delete-payload";
import MarketingEvent from "./types/marketing-event";
import MarketingEventConnection from "./types/marketing-event-connection";
import MarketingEventEdge from "./types/marketing-event-edge";
import MarketingEventSortKeys from "./types/marketing-event-sort-keys";
import MarketingTactic from "./types/marketing-tactic";
import Media from "./types/media";
import MediaConnection from "./types/media-connection";
import MediaContentType from "./types/media-content-type";
import MediaEdge from "./types/media-edge";
import MediaError from "./types/media-error";
import MediaErrorCode from "./types/media-error-code";
import MediaHost from "./types/media-host";
import MediaImage from "./types/media-image";
import MediaImageOriginalSource from "./types/media-image-original-source";
import MediaPreviewImage from "./types/media-preview-image";
import MediaPreviewImageStatus from "./types/media-preview-image-status";
import MediaStatus from "./types/media-status";
import MediaUserError from "./types/media-user-error";
import MediaUserErrorCode from "./types/media-user-error-code";
import MediaWarning from "./types/media-warning";
import MediaWarningCode from "./types/media-warning-code";
import MerchandiseDiscountClass from "./types/merchandise-discount-class";
import MerchantApprovalSignals from "./types/merchant-approval-signals";
import Metafield from "./types/metafield";
import MetafieldAccess from "./types/metafield-access";
import MetafieldAccessGrant from "./types/metafield-access-grant";
import MetafieldAccessGrantDeleteInput from "./types/metafield-access-grant-delete-input";
import MetafieldAccessGrantInput from "./types/metafield-access-grant-input";
import MetafieldAccessGrantOperationInput from "./types/metafield-access-grant-operation-input";
import MetafieldAccessInput from "./types/metafield-access-input";
import MetafieldAccessUpdateInput from "./types/metafield-access-update-input";
import MetafieldAdminAccess from "./types/metafield-admin-access";
import MetafieldConnection from "./types/metafield-connection";
import MetafieldDefinition from "./types/metafield-definition";
import MetafieldDefinitionConnection from "./types/metafield-definition-connection";
import MetafieldDefinitionCreatePayload from "./types/metafield-definition-create-payload";
import MetafieldDefinitionCreateUserError from "./types/metafield-definition-create-user-error";
import MetafieldDefinitionCreateUserErrorCode from "./types/metafield-definition-create-user-error-code";
import MetafieldDefinitionDeletePayload from "./types/metafield-definition-delete-payload";
import MetafieldDefinitionDeleteUserError from "./types/metafield-definition-delete-user-error";
import MetafieldDefinitionDeleteUserErrorCode from "./types/metafield-definition-delete-user-error-code";
import MetafieldDefinitionEdge from "./types/metafield-definition-edge";
import MetafieldDefinitionInput from "./types/metafield-definition-input";
import MetafieldDefinitionPinPayload from "./types/metafield-definition-pin-payload";
import MetafieldDefinitionPinUserError from "./types/metafield-definition-pin-user-error";
import MetafieldDefinitionPinUserErrorCode from "./types/metafield-definition-pin-user-error-code";
import MetafieldDefinitionPinnedStatus from "./types/metafield-definition-pinned-status";
import MetafieldDefinitionSortKeys from "./types/metafield-definition-sort-keys";
import MetafieldDefinitionSupportedValidation from "./types/metafield-definition-supported-validation";
import MetafieldDefinitionType from "./types/metafield-definition-type";
import MetafieldDefinitionUnpinPayload from "./types/metafield-definition-unpin-payload";
import MetafieldDefinitionUnpinUserError from "./types/metafield-definition-unpin-user-error";
import MetafieldDefinitionUnpinUserErrorCode from "./types/metafield-definition-unpin-user-error-code";
import MetafieldDefinitionUpdateInput from "./types/metafield-definition-update-input";
import MetafieldDefinitionUpdatePayload from "./types/metafield-definition-update-payload";
import MetafieldDefinitionUpdateUserError from "./types/metafield-definition-update-user-error";
import MetafieldDefinitionUpdateUserErrorCode from "./types/metafield-definition-update-user-error-code";
import MetafieldDefinitionValidation from "./types/metafield-definition-validation";
import MetafieldDefinitionValidationInput from "./types/metafield-definition-validation-input";
import MetafieldDefinitionValidationStatus from "./types/metafield-definition-validation-status";
import MetafieldDeleteInput from "./types/metafield-delete-input";
import MetafieldDeletePayload from "./types/metafield-delete-payload";
import MetafieldEdge from "./types/metafield-edge";
import MetafieldGrantAccessLevel from "./types/metafield-grant-access-level";
import MetafieldIdentifier from "./types/metafield-identifier";
import MetafieldIdentifierInput from "./types/metafield-identifier-input";
import MetafieldInput from "./types/metafield-input";
import MetafieldOwnerType from "./types/metafield-owner-type";
import MetafieldReference from "./types/metafield-reference";
import MetafieldReferenceConnection from "./types/metafield-reference-connection";
import MetafieldReferenceEdge from "./types/metafield-reference-edge";
import MetafieldReferencer from "./types/metafield-referencer";
import MetafieldRelation from "./types/metafield-relation";
import MetafieldRelationConnection from "./types/metafield-relation-connection";
import MetafieldRelationEdge from "./types/metafield-relation-edge";
import MetafieldStorefrontAccess from "./types/metafield-storefront-access";
import MetafieldStorefrontVisibility from "./types/metafield-storefront-visibility";
import MetafieldStorefrontVisibilityConnection from "./types/metafield-storefront-visibility-connection";
import MetafieldStorefrontVisibilityCreatePayload from "./types/metafield-storefront-visibility-create-payload";
import MetafieldStorefrontVisibilityDeletePayload from "./types/metafield-storefront-visibility-delete-payload";
import MetafieldStorefrontVisibilityEdge from "./types/metafield-storefront-visibility-edge";
import MetafieldStorefrontVisibilityInput from "./types/metafield-storefront-visibility-input";
import MetafieldValidationStatus from "./types/metafield-validation-status";
import MetafieldValueType from "./types/metafield-value-type";
import MetafieldsDeletePayload from "./types/metafields-delete-payload";
import MetafieldsSetInput from "./types/metafields-set-input";
import MetafieldsSetPayload from "./types/metafields-set-payload";
import MetafieldsSetUserError from "./types/metafields-set-user-error";
import MetafieldsSetUserErrorCode from "./types/metafields-set-user-error-code";
import Metaobject from "./types/metaobject";
import MetaobjectAccess from "./types/metaobject-access";
import MetaobjectAccessInput from "./types/metaobject-access-input";
import MetaobjectAdminAccess from "./types/metaobject-admin-access";
import MetaobjectBulkDeletePayload from "./types/metaobject-bulk-delete-payload";
import MetaobjectBulkDeleteWhereCondition from "./types/metaobject-bulk-delete-where-condition";
import MetaobjectCapabilities from "./types/metaobject-capabilities";
import MetaobjectCapabilitiesOnlineStore from "./types/metaobject-capabilities-online-store";
import MetaobjectCapabilitiesPublishable from "./types/metaobject-capabilities-publishable";
import MetaobjectCapabilitiesRenderable from "./types/metaobject-capabilities-renderable";
import MetaobjectCapabilitiesTranslatable from "./types/metaobject-capabilities-translatable";
import MetaobjectCapabilityCreateInput from "./types/metaobject-capability-create-input";
import MetaobjectCapabilityData from "./types/metaobject-capability-data";
import MetaobjectCapabilityDataInput from "./types/metaobject-capability-data-input";
import MetaobjectCapabilityDataOnlineStore from "./types/metaobject-capability-data-online-store";
import MetaobjectCapabilityDataOnlineStoreInput from "./types/metaobject-capability-data-online-store-input";
import MetaobjectCapabilityDataPublishable from "./types/metaobject-capability-data-publishable";
import MetaobjectCapabilityDataPublishableInput from "./types/metaobject-capability-data-publishable-input";
import MetaobjectCapabilityDefinitionDataOnlineStore from "./types/metaobject-capability-definition-data-online-store";
import MetaobjectCapabilityDefinitionDataOnlineStoreInput from "./types/metaobject-capability-definition-data-online-store-input";
import MetaobjectCapabilityDefinitionDataRenderable from "./types/metaobject-capability-definition-data-renderable";
import MetaobjectCapabilityDefinitionDataRenderableInput from "./types/metaobject-capability-definition-data-renderable-input";
import MetaobjectCapabilityOnlineStoreInput from "./types/metaobject-capability-online-store-input";
import MetaobjectCapabilityPublishableInput from "./types/metaobject-capability-publishable-input";
import MetaobjectCapabilityRenderableInput from "./types/metaobject-capability-renderable-input";
import MetaobjectCapabilityTranslatableInput from "./types/metaobject-capability-translatable-input";
import MetaobjectCapabilityUpdateInput from "./types/metaobject-capability-update-input";
import MetaobjectConnection from "./types/metaobject-connection";
import MetaobjectCreateInput from "./types/metaobject-create-input";
import MetaobjectCreatePayload from "./types/metaobject-create-payload";
import MetaobjectDefinition from "./types/metaobject-definition";
import MetaobjectDefinitionConnection from "./types/metaobject-definition-connection";
import MetaobjectDefinitionCreateInput from "./types/metaobject-definition-create-input";
import MetaobjectDefinitionCreatePayload from "./types/metaobject-definition-create-payload";
import MetaobjectDefinitionDeletePayload from "./types/metaobject-definition-delete-payload";
import MetaobjectDefinitionEdge from "./types/metaobject-definition-edge";
import MetaobjectDefinitionUpdateInput from "./types/metaobject-definition-update-input";
import MetaobjectDefinitionUpdatePayload from "./types/metaobject-definition-update-payload";
import MetaobjectDeletePayload from "./types/metaobject-delete-payload";
import MetaobjectEdge from "./types/metaobject-edge";
import MetaobjectField from "./types/metaobject-field";
import MetaobjectFieldDefinition from "./types/metaobject-field-definition";
import MetaobjectFieldDefinitionCreateInput from "./types/metaobject-field-definition-create-input";
import MetaobjectFieldDefinitionDeleteInput from "./types/metaobject-field-definition-delete-input";
import MetaobjectFieldDefinitionOperationInput from "./types/metaobject-field-definition-operation-input";
import MetaobjectFieldDefinitionUpdateInput from "./types/metaobject-field-definition-update-input";
import MetaobjectFieldInput from "./types/metaobject-field-input";
import MetaobjectHandleInput from "./types/metaobject-handle-input";
import MetaobjectStatus from "./types/metaobject-status";
import MetaobjectStorefrontAccess from "./types/metaobject-storefront-access";
import MetaobjectThumbnail from "./types/metaobject-thumbnail";
import MetaobjectUpdateInput from "./types/metaobject-update-input";
import MetaobjectUpdatePayload from "./types/metaobject-update-payload";
import MetaobjectUpsertInput from "./types/metaobject-upsert-input";
import MetaobjectUpsertPayload from "./types/metaobject-upsert-payload";
import MetaobjectUserError from "./types/metaobject-user-error";
import MetaobjectUserErrorCode from "./types/metaobject-user-error-code";
import MethodDefinitionSortKeys from "./types/method-definition-sort-keys";
import Model3d from "./types/model-3-d";
import Model3dBoundingBox from "./types/model-3-d-bounding-box";
import Model3dSource from "./types/model-3-d-source";
import Money from "./types/money";
import MoneyBag from "./types/money-bag";
import MoneyInput from "./types/money-input";
import MoneyV2 from "./types/money-v-2";
import MoveInput from "./types/move-input";
import Mutation from "./types/mutation";
import MutationsStagedUploadTargetGenerateUploadParameter from "./types/mutations-staged-upload-target-generate-upload-parameter";
import Navigable from "./types/navigable";
import NavigationItem from "./types/navigation-item";
import Node from "./types/node";
import ObjectDimensionsInput from "./types/object-dimensions-input";
import OnlineStoreArticle from "./types/online-store-article";
import OnlineStoreBlog from "./types/online-store-blog";
import OnlineStorePage from "./types/online-store-page";
import OnlineStorePreviewable from "./types/online-store-previewable";
import OptionCreateInput from "./types/option-create-input";
import OptionReorderInput from "./types/option-reorder-input";
import OptionSetInput from "./types/option-set-input";
import OptionUpdateInput from "./types/option-update-input";
import OptionValueCreateInput from "./types/option-value-create-input";
import OptionValueReorderInput from "./types/option-value-reorder-input";
import OptionValueSetInput from "./types/option-value-set-input";
import OptionValueUpdateInput from "./types/option-value-update-input";
import Order from "./types/order";
import OrderActionType from "./types/order-action-type";
import OrderAgreement from "./types/order-agreement";
import OrderApp from "./types/order-app";
import OrderCancelPayload from "./types/order-cancel-payload";
import OrderCancelReason from "./types/order-cancel-reason";
import OrderCancelUserError from "./types/order-cancel-user-error";
import OrderCancelUserErrorCode from "./types/order-cancel-user-error-code";
import OrderCancellation from "./types/order-cancellation";
import OrderCaptureInput from "./types/order-capture-input";
import OrderCapturePayload from "./types/order-capture-payload";
import OrderCloseInput from "./types/order-close-input";
import OrderClosePayload from "./types/order-close-payload";
import OrderConnection from "./types/order-connection";
import OrderCreateMandatePaymentPayload from "./types/order-create-mandate-payment-payload";
import OrderCreateMandatePaymentUserError from "./types/order-create-mandate-payment-user-error";
import OrderCreateMandatePaymentUserErrorCode from "./types/order-create-mandate-payment-user-error-code";
import OrderDisplayFinancialStatus from "./types/order-display-financial-status";
import OrderDisplayFulfillmentStatus from "./types/order-display-fulfillment-status";
import OrderDisputeSummary from "./types/order-dispute-summary";
import OrderEdge from "./types/order-edge";
import OrderEditAddCustomItemPayload from "./types/order-edit-add-custom-item-payload";
import OrderEditAddLineItemDiscountPayload from "./types/order-edit-add-line-item-discount-payload";
import OrderEditAddShippingLineInput from "./types/order-edit-add-shipping-line-input";
import OrderEditAddShippingLinePayload from "./types/order-edit-add-shipping-line-payload";
import OrderEditAddShippingLineUserError from "./types/order-edit-add-shipping-line-user-error";
import OrderEditAddShippingLineUserErrorCode from "./types/order-edit-add-shipping-line-user-error-code";
import OrderEditAddVariantPayload from "./types/order-edit-add-variant-payload";
import OrderEditAgreement from "./types/order-edit-agreement";
import OrderEditAppliedDiscountInput from "./types/order-edit-applied-discount-input";
import OrderEditBeginPayload from "./types/order-edit-begin-payload";
import OrderEditCommitPayload from "./types/order-edit-commit-payload";
import OrderEditRemoveDiscountPayload from "./types/order-edit-remove-discount-payload";
import OrderEditRemoveDiscountUserError from "./types/order-edit-remove-discount-user-error";
import OrderEditRemoveDiscountUserErrorCode from "./types/order-edit-remove-discount-user-error-code";
import OrderEditRemoveLineItemDiscountPayload from "./types/order-edit-remove-line-item-discount-payload";
import OrderEditRemoveShippingLinePayload from "./types/order-edit-remove-shipping-line-payload";
import OrderEditRemoveShippingLineUserError from "./types/order-edit-remove-shipping-line-user-error";
import OrderEditRemoveShippingLineUserErrorCode from "./types/order-edit-remove-shipping-line-user-error-code";
import OrderEditSetQuantityPayload from "./types/order-edit-set-quantity-payload";
import OrderEditUpdateDiscountPayload from "./types/order-edit-update-discount-payload";
import OrderEditUpdateDiscountUserError from "./types/order-edit-update-discount-user-error";
import OrderEditUpdateDiscountUserErrorCode from "./types/order-edit-update-discount-user-error-code";
import OrderEditUpdateShippingLineInput from "./types/order-edit-update-shipping-line-input";
import OrderEditUpdateShippingLinePayload from "./types/order-edit-update-shipping-line-payload";
import OrderEditUpdateShippingLineUserError from "./types/order-edit-update-shipping-line-user-error";
import OrderEditUpdateShippingLineUserErrorCode from "./types/order-edit-update-shipping-line-user-error-code";
import OrderInput from "./types/order-input";
import OrderInvoiceSendPayload from "./types/order-invoice-send-payload";
import OrderInvoiceSendUserError from "./types/order-invoice-send-user-error";
import OrderInvoiceSendUserErrorCode from "./types/order-invoice-send-user-error-code";
import OrderMarkAsPaidInput from "./types/order-mark-as-paid-input";
import OrderMarkAsPaidPayload from "./types/order-mark-as-paid-payload";
import OrderOpenInput from "./types/order-open-input";
import OrderOpenPayload from "./types/order-open-payload";
import OrderPaymentCollectionDetails from "./types/order-payment-collection-details";
import OrderPaymentStatus from "./types/order-payment-status";
import OrderPaymentStatusResult from "./types/order-payment-status-result";
import OrderReturnStatus from "./types/order-return-status";
import OrderRisk from "./types/order-risk";
import OrderRiskAssessment from "./types/order-risk-assessment";
import OrderRiskAssessmentCreateInput from "./types/order-risk-assessment-create-input";
import OrderRiskAssessmentCreatePayload from "./types/order-risk-assessment-create-payload";
import OrderRiskAssessmentCreateUserError from "./types/order-risk-assessment-create-user-error";
import OrderRiskAssessmentCreateUserErrorCode from "./types/order-risk-assessment-create-user-error-code";
import OrderRiskAssessmentFactInput from "./types/order-risk-assessment-fact-input";
import OrderRiskLevel from "./types/order-risk-level";
import OrderRiskRecommendationResult from "./types/order-risk-recommendation-result";
import OrderRiskSummary from "./types/order-risk-summary";
import OrderSortKeys from "./types/order-sort-keys";
import OrderStagedChange from "./types/order-staged-change";
import OrderStagedChangeAddCustomItem from "./types/order-staged-change-add-custom-item";
import OrderStagedChangeAddLineItemDiscount from "./types/order-staged-change-add-line-item-discount";
import OrderStagedChangeAddShippingLine from "./types/order-staged-change-add-shipping-line";
import OrderStagedChangeAddVariant from "./types/order-staged-change-add-variant";
import OrderStagedChangeConnection from "./types/order-staged-change-connection";
import OrderStagedChangeDecrementItem from "./types/order-staged-change-decrement-item";
import OrderStagedChangeEdge from "./types/order-staged-change-edge";
import OrderStagedChangeIncrementItem from "./types/order-staged-change-increment-item";
import OrderStagedChangeRemoveShippingLine from "./types/order-staged-change-remove-shipping-line";
import OrderTransaction from "./types/order-transaction";
import OrderTransactionConnection from "./types/order-transaction-connection";
import OrderTransactionEdge from "./types/order-transaction-edge";
import OrderTransactionErrorCode from "./types/order-transaction-error-code";
import OrderTransactionInput from "./types/order-transaction-input";
import OrderTransactionKind from "./types/order-transaction-kind";
import OrderTransactionStatus from "./types/order-transaction-status";
import OrderUpdatePayload from "./types/order-update-payload";
import PageInfo from "./types/page-info";
import ParseError from "./types/parse-error";
import ParseErrorCode from "./types/parse-error-code";
import ParseErrorRange from "./types/parse-error-range";
import PaymentCustomization from "./types/payment-customization";
import PaymentCustomizationActivationPayload from "./types/payment-customization-activation-payload";
import PaymentCustomizationConnection from "./types/payment-customization-connection";
import PaymentCustomizationCreatePayload from "./types/payment-customization-create-payload";
import PaymentCustomizationDeletePayload from "./types/payment-customization-delete-payload";
import PaymentCustomizationEdge from "./types/payment-customization-edge";
import PaymentCustomizationError from "./types/payment-customization-error";
import PaymentCustomizationErrorCode from "./types/payment-customization-error-code";
import PaymentCustomizationInput from "./types/payment-customization-input";
import PaymentCustomizationUpdatePayload from "./types/payment-customization-update-payload";
import PaymentDetails from "./types/payment-details";
import PaymentInstrument from "./types/payment-instrument";
import PaymentMandate from "./types/payment-mandate";
import PaymentMethods from "./types/payment-methods";
import PaymentReminderSendPayload from "./types/payment-reminder-send-payload";
import PaymentReminderSendUserError from "./types/payment-reminder-send-user-error";
import PaymentReminderSendUserErrorCode from "./types/payment-reminder-send-user-error-code";
import PaymentSchedule from "./types/payment-schedule";
import PaymentScheduleConnection from "./types/payment-schedule-connection";
import PaymentScheduleEdge from "./types/payment-schedule-edge";
import PaymentScheduleInput from "./types/payment-schedule-input";
import PaymentSettings from "./types/payment-settings";
import PaymentTerms from "./types/payment-terms";
import PaymentTermsCreateInput from "./types/payment-terms-create-input";
import PaymentTermsCreatePayload from "./types/payment-terms-create-payload";
import PaymentTermsCreateUserError from "./types/payment-terms-create-user-error";
import PaymentTermsCreateUserErrorCode from "./types/payment-terms-create-user-error-code";
import PaymentTermsDeleteInput from "./types/payment-terms-delete-input";
import PaymentTermsDeletePayload from "./types/payment-terms-delete-payload";
import PaymentTermsDeleteUserError from "./types/payment-terms-delete-user-error";
import PaymentTermsDeleteUserErrorCode from "./types/payment-terms-delete-user-error-code";
import PaymentTermsInput from "./types/payment-terms-input";
import PaymentTermsTemplate from "./types/payment-terms-template";
import PaymentTermsType from "./types/payment-terms-type";
import PaymentTermsUpdateInput from "./types/payment-terms-update-input";
import PaymentTermsUpdatePayload from "./types/payment-terms-update-payload";
import PaymentTermsUpdateUserError from "./types/payment-terms-update-user-error";
import PaymentTermsUpdateUserErrorCode from "./types/payment-terms-update-user-error-code";
import PaypalExpressSubscriptionsGatewayStatus from "./types/paypal-express-subscriptions-gateway-status";
import PolarisVizDataPoint from "./types/polaris-viz-data-point";
import PolarisVizDataSeries from "./types/polaris-viz-data-series";
import PolarisVizResponse from "./types/polaris-viz-response";
import PreparedFulfillmentOrderLineItemsInput from "./types/prepared-fulfillment-order-line-items-input";
import PriceCalculationType from "./types/price-calculation-type";
import PriceInput from "./types/price-input";
import PriceList from "./types/price-list";
import PriceListAdjustment from "./types/price-list-adjustment";
import PriceListAdjustmentInput from "./types/price-list-adjustment-input";
import PriceListAdjustmentSettings from "./types/price-list-adjustment-settings";
import PriceListAdjustmentSettingsInput from "./types/price-list-adjustment-settings-input";
import PriceListAdjustmentType from "./types/price-list-adjustment-type";
import PriceListCompareAtMode from "./types/price-list-compare-at-mode";
import PriceListConnection from "./types/price-list-connection";
import PriceListCreateInput from "./types/price-list-create-input";
import PriceListCreatePayload from "./types/price-list-create-payload";
import PriceListDeletePayload from "./types/price-list-delete-payload";
import PriceListEdge from "./types/price-list-edge";
import PriceListFixedPricesAddPayload from "./types/price-list-fixed-prices-add-payload";
import PriceListFixedPricesByProductBulkUpdateUserError from "./types/price-list-fixed-prices-by-product-bulk-update-user-error";
import PriceListFixedPricesByProductBulkUpdateUserErrorCode from "./types/price-list-fixed-prices-by-product-bulk-update-user-error-code";
import PriceListFixedPricesByProductUpdatePayload from "./types/price-list-fixed-prices-by-product-update-payload";
import PriceListFixedPricesDeletePayload from "./types/price-list-fixed-prices-delete-payload";
import PriceListFixedPricesUpdatePayload from "./types/price-list-fixed-prices-update-payload";
import PriceListParent from "./types/price-list-parent";
import PriceListParentCreateInput from "./types/price-list-parent-create-input";
import PriceListParentUpdateInput from "./types/price-list-parent-update-input";
import PriceListPrice from "./types/price-list-price";
import PriceListPriceConnection from "./types/price-list-price-connection";
import PriceListPriceEdge from "./types/price-list-price-edge";
import PriceListPriceInput from "./types/price-list-price-input";
import PriceListPriceOriginType from "./types/price-list-price-origin-type";
import PriceListPriceUserError from "./types/price-list-price-user-error";
import PriceListPriceUserErrorCode from "./types/price-list-price-user-error-code";
import PriceListProductPriceInput from "./types/price-list-product-price-input";
import PriceListSortKeys from "./types/price-list-sort-keys";
import PriceListUpdateInput from "./types/price-list-update-input";
import PriceListUpdatePayload from "./types/price-list-update-payload";
import PriceListUserError from "./types/price-list-user-error";
import PriceListUserErrorCode from "./types/price-list-user-error-code";
import PriceRule from "./types/price-rule";
import PriceRuleActivatePayload from "./types/price-rule-activate-payload";
import PriceRuleAllocationMethod from "./types/price-rule-allocation-method";
import PriceRuleConnection from "./types/price-rule-connection";
import PriceRuleCreatePayload from "./types/price-rule-create-payload";
import PriceRuleCustomerSelection from "./types/price-rule-customer-selection";
import PriceRuleCustomerSelectionInput from "./types/price-rule-customer-selection-input";
import PriceRuleDeactivatePayload from "./types/price-rule-deactivate-payload";
import PriceRuleDeletePayload from "./types/price-rule-delete-payload";
import PriceRuleDiscountCode from "./types/price-rule-discount-code";
import PriceRuleDiscountCodeConnection from "./types/price-rule-discount-code-connection";
import PriceRuleDiscountCodeCreatePayload from "./types/price-rule-discount-code-create-payload";
import PriceRuleDiscountCodeEdge from "./types/price-rule-discount-code-edge";
import PriceRuleDiscountCodeInput from "./types/price-rule-discount-code-input";
import PriceRuleDiscountCodeUpdatePayload from "./types/price-rule-discount-code-update-payload";
import PriceRuleEdge from "./types/price-rule-edge";
import PriceRuleEntitlementToPrerequisiteQuantityRatio from "./types/price-rule-entitlement-to-prerequisite-quantity-ratio";
import PriceRuleEntitlementToPrerequisiteQuantityRatioInput from "./types/price-rule-entitlement-to-prerequisite-quantity-ratio-input";
import PriceRuleErrorCode from "./types/price-rule-error-code";
import PriceRuleFeature from "./types/price-rule-feature";
import PriceRuleFixedAmountValue from "./types/price-rule-fixed-amount-value";
import PriceRuleInput from "./types/price-rule-input";
import PriceRuleItemEntitlements from "./types/price-rule-item-entitlements";
import PriceRuleItemEntitlementsInput from "./types/price-rule-item-entitlements-input";
import PriceRuleItemPrerequisitesInput from "./types/price-rule-item-prerequisites-input";
import PriceRuleLineItemPrerequisites from "./types/price-rule-line-item-prerequisites";
import PriceRuleMoneyRange from "./types/price-rule-money-range";
import PriceRuleMoneyRangeInput from "./types/price-rule-money-range-input";
import PriceRulePercentValue from "./types/price-rule-percent-value";
import PriceRulePrerequisiteToEntitlementQuantityRatio from "./types/price-rule-prerequisite-to-entitlement-quantity-ratio";
import PriceRulePrerequisiteToEntitlementQuantityRatioInput from "./types/price-rule-prerequisite-to-entitlement-quantity-ratio-input";
import PriceRuleQuantityRange from "./types/price-rule-quantity-range";
import PriceRuleQuantityRangeInput from "./types/price-rule-quantity-range-input";
import PriceRuleShareableUrl from "./types/price-rule-shareable-url";
import PriceRuleShareableUrlTargetType from "./types/price-rule-shareable-url-target-type";
import PriceRuleShippingEntitlementsInput from "./types/price-rule-shipping-entitlements-input";
import PriceRuleShippingLineEntitlements from "./types/price-rule-shipping-line-entitlements";
import PriceRuleSortKeys from "./types/price-rule-sort-keys";
import PriceRuleStatus from "./types/price-rule-status";
import PriceRuleTarget from "./types/price-rule-target";
import PriceRuleTrait from "./types/price-rule-trait";
import PriceRuleUpdatePayload from "./types/price-rule-update-payload";
import PriceRuleUserError from "./types/price-rule-user-error";
import PriceRuleValidityPeriod from "./types/price-rule-validity-period";
import PriceRuleValidityPeriodInput from "./types/price-rule-validity-period-input";
import PriceRuleValue from "./types/price-rule-value";
import PriceRuleValueInput from "./types/price-rule-value-input";
import PricingPercentageValue from "./types/pricing-percentage-value";
import PricingValue from "./types/pricing-value";
import PrivateMetafield from "./types/private-metafield";
import PrivateMetafieldConnection from "./types/private-metafield-connection";
import PrivateMetafieldDeleteInput from "./types/private-metafield-delete-input";
import PrivateMetafieldDeletePayload from "./types/private-metafield-delete-payload";
import PrivateMetafieldEdge from "./types/private-metafield-edge";
import PrivateMetafieldInput from "./types/private-metafield-input";
import PrivateMetafieldUpsertPayload from "./types/private-metafield-upsert-payload";
import PrivateMetafieldValueInput from "./types/private-metafield-value-input";
import PrivateMetafieldValueType from "./types/private-metafield-value-type";
import Product from "./types/product";
import ProductAppendImagesInput from "./types/product-append-images-input";
import ProductAppendImagesPayload from "./types/product-append-images-payload";
import ProductCategory from "./types/product-category";
import ProductCategoryInput from "./types/product-category-input";
import ProductChangeStatusPayload from "./types/product-change-status-payload";
import ProductChangeStatusUserError from "./types/product-change-status-user-error";
import ProductChangeStatusUserErrorCode from "./types/product-change-status-user-error-code";
import ProductClaimOwnershipInput from "./types/product-claim-ownership-input";
import ProductCollectionSortKeys from "./types/product-collection-sort-keys";
import ProductCompareAtPriceRange from "./types/product-compare-at-price-range";
import ProductConnection from "./types/product-connection";
import ProductContextualPricing from "./types/product-contextual-pricing";
import ProductCreateMediaPayload from "./types/product-create-media-payload";
import ProductCreatePayload from "./types/product-create-payload";
import ProductDeleteAsyncPayload from "./types/product-delete-async-payload";
import ProductDeleteImagesPayload from "./types/product-delete-images-payload";
import ProductDeleteInput from "./types/product-delete-input";
import ProductDeleteMediaPayload from "./types/product-delete-media-payload";
import ProductDeletePayload from "./types/product-delete-payload";
import ProductDeleteUserError from "./types/product-delete-user-error";
import ProductDeleteUserErrorCode from "./types/product-delete-user-error-code";
import ProductDuplicateAsyncInput from "./types/product-duplicate-async-input";
import ProductDuplicateAsyncV2Payload from "./types/product-duplicate-async-v-2-payload";
import ProductDuplicateJob from "./types/product-duplicate-job";
import ProductDuplicatePayload from "./types/product-duplicate-payload";
import ProductDuplicateUserError from "./types/product-duplicate-user-error";
import ProductDuplicateUserErrorCode from "./types/product-duplicate-user-error-code";
import ProductEdge from "./types/product-edge";
import ProductFeed from "./types/product-feed";
import ProductFeedConnection from "./types/product-feed-connection";
import ProductFeedCreatePayload from "./types/product-feed-create-payload";
import ProductFeedCreateUserError from "./types/product-feed-create-user-error";
import ProductFeedCreateUserErrorCode from "./types/product-feed-create-user-error-code";
import ProductFeedDeletePayload from "./types/product-feed-delete-payload";
import ProductFeedDeleteUserError from "./types/product-feed-delete-user-error";
import ProductFeedDeleteUserErrorCode from "./types/product-feed-delete-user-error-code";
import ProductFeedEdge from "./types/product-feed-edge";
import ProductFeedInput from "./types/product-feed-input";
import ProductFeedStatus from "./types/product-feed-status";
import ProductFullSyncPayload from "./types/product-full-sync-payload";
import ProductFullSyncUserError from "./types/product-full-sync-user-error";
import ProductFullSyncUserErrorCode from "./types/product-full-sync-user-error-code";
import ProductImageSortKeys from "./types/product-image-sort-keys";
import ProductImageUpdatePayload from "./types/product-image-update-payload";
import ProductInput from "./types/product-input";
import ProductJoinSellingPlanGroupsPayload from "./types/product-join-selling-plan-groups-payload";
import ProductLeaveSellingPlanGroupsPayload from "./types/product-leave-selling-plan-groups-payload";
import ProductMediaSortKeys from "./types/product-media-sort-keys";
import ProductOperation from "./types/product-operation";
import ProductOperationStatus from "./types/product-operation-status";
import ProductOption from "./types/product-option";
import ProductOptionDeleteStrategy from "./types/product-option-delete-strategy";
import ProductOptionUpdatePayload from "./types/product-option-update-payload";
import ProductOptionUpdateUserError from "./types/product-option-update-user-error";
import ProductOptionUpdateUserErrorCode from "./types/product-option-update-user-error-code";
import ProductOptionUpdateVariantStrategy from "./types/product-option-update-variant-strategy";
import ProductOptionValue from "./types/product-option-value";
import ProductOptionValueSwatch from "./types/product-option-value-swatch";
import ProductOptionsCreatePayload from "./types/product-options-create-payload";
import ProductOptionsCreateUserError from "./types/product-options-create-user-error";
import ProductOptionsCreateUserErrorCode from "./types/product-options-create-user-error-code";
import ProductOptionsDeletePayload from "./types/product-options-delete-payload";
import ProductOptionsDeleteUserError from "./types/product-options-delete-user-error";
import ProductOptionsDeleteUserErrorCode from "./types/product-options-delete-user-error-code";
import ProductOptionsReorderPayload from "./types/product-options-reorder-payload";
import ProductOptionsReorderUserError from "./types/product-options-reorder-user-error";
import ProductOptionsReorderUserErrorCode from "./types/product-options-reorder-user-error-code";
import ProductPriceRange from "./types/product-price-range";
import ProductPriceRangeV2 from "./types/product-price-range-v-2";
import ProductPublication from "./types/product-publication";
import ProductPublicationConnection from "./types/product-publication-connection";
import ProductPublicationEdge from "./types/product-publication-edge";
import ProductPublicationInput from "./types/product-publication-input";
import ProductPublishInput from "./types/product-publish-input";
import ProductPublishPayload from "./types/product-publish-payload";
import ProductReorderImagesPayload from "./types/product-reorder-images-payload";
import ProductReorderMediaPayload from "./types/product-reorder-media-payload";
import ProductResourceFeedback from "./types/product-resource-feedback";
import ProductResourceFeedbackInput from "./types/product-resource-feedback-input";
import ProductSale from "./types/product-sale";
import ProductSetInput from "./types/product-set-input";
import ProductSetOperation from "./types/product-set-operation";
import ProductSetPayload from "./types/product-set-payload";
import ProductSetUserError from "./types/product-set-user-error";
import ProductSetUserErrorCode from "./types/product-set-user-error-code";
import ProductSortKeys from "./types/product-sort-keys";
import ProductStatus from "./types/product-status";
import ProductTaxonomyNode from "./types/product-taxonomy-node";
import ProductUnpublishInput from "./types/product-unpublish-input";
import ProductUnpublishPayload from "./types/product-unpublish-payload";
import ProductUpdateMediaPayload from "./types/product-update-media-payload";
import ProductUpdatePayload from "./types/product-update-payload";
import ProductVariant from "./types/product-variant";
import ProductVariantAppendMediaInput from "./types/product-variant-append-media-input";
import ProductVariantAppendMediaPayload from "./types/product-variant-append-media-payload";
import ProductVariantComponent from "./types/product-variant-component";
import ProductVariantComponentConnection from "./types/product-variant-component-connection";
import ProductVariantComponentEdge from "./types/product-variant-component-edge";
import ProductVariantConnection from "./types/product-variant-connection";
import ProductVariantContextualPricing from "./types/product-variant-contextual-pricing";
import ProductVariantCreatePayload from "./types/product-variant-create-payload";
import ProductVariantDeletePayload from "./types/product-variant-delete-payload";
import ProductVariantDetachMediaInput from "./types/product-variant-detach-media-input";
import ProductVariantDetachMediaPayload from "./types/product-variant-detach-media-payload";
import ProductVariantEdge from "./types/product-variant-edge";
import ProductVariantGroupRelationshipInput from "./types/product-variant-group-relationship-input";
import ProductVariantInput from "./types/product-variant-input";
import ProductVariantInventoryManagement from "./types/product-variant-inventory-management";
import ProductVariantInventoryPolicy from "./types/product-variant-inventory-policy";
import ProductVariantJoinSellingPlanGroupsPayload from "./types/product-variant-join-selling-plan-groups-payload";
import ProductVariantLeaveSellingPlanGroupsPayload from "./types/product-variant-leave-selling-plan-groups-payload";
import ProductVariantPositionInput from "./types/product-variant-position-input";
import ProductVariantPricePair from "./types/product-variant-price-pair";
import ProductVariantPricePairConnection from "./types/product-variant-price-pair-connection";
import ProductVariantPricePairEdge from "./types/product-variant-price-pair-edge";
import ProductVariantRelationshipBulkUpdatePayload from "./types/product-variant-relationship-bulk-update-payload";
import ProductVariantRelationshipBulkUpdateUserError from "./types/product-variant-relationship-bulk-update-user-error";
import ProductVariantRelationshipBulkUpdateUserErrorCode from "./types/product-variant-relationship-bulk-update-user-error-code";
import ProductVariantRelationshipUpdateInput from "./types/product-variant-relationship-update-input";
import ProductVariantSetInput from "./types/product-variant-set-input";
import ProductVariantSortKeys from "./types/product-variant-sort-keys";
import ProductVariantUpdatePayload from "./types/product-variant-update-payload";
import ProductVariantsBulkCreatePayload from "./types/product-variants-bulk-create-payload";
import ProductVariantsBulkCreateStrategy from "./types/product-variants-bulk-create-strategy";
import ProductVariantsBulkCreateUserError from "./types/product-variants-bulk-create-user-error";
import ProductVariantsBulkCreateUserErrorCode from "./types/product-variants-bulk-create-user-error-code";
import ProductVariantsBulkDeletePayload from "./types/product-variants-bulk-delete-payload";
import ProductVariantsBulkDeleteUserError from "./types/product-variants-bulk-delete-user-error";
import ProductVariantsBulkDeleteUserErrorCode from "./types/product-variants-bulk-delete-user-error-code";
import ProductVariantsBulkInput from "./types/product-variants-bulk-input";
import ProductVariantsBulkReorderPayload from "./types/product-variants-bulk-reorder-payload";
import ProductVariantsBulkReorderUserError from "./types/product-variants-bulk-reorder-user-error";
import ProductVariantsBulkReorderUserErrorCode from "./types/product-variants-bulk-reorder-user-error-code";
import ProductVariantsBulkUpdatePayload from "./types/product-variants-bulk-update-payload";
import ProductVariantsBulkUpdateUserError from "./types/product-variants-bulk-update-user-error";
import ProductVariantsBulkUpdateUserErrorCode from "./types/product-variants-bulk-update-user-error-code";
import ProfileItemSortKeys from "./types/profile-item-sort-keys";
import PubSubServerPixelUpdatePayload from "./types/pub-sub-server-pixel-update-payload";
import PubSubWebhookSubscriptionCreatePayload from "./types/pub-sub-webhook-subscription-create-payload";
import PubSubWebhookSubscriptionCreateUserError from "./types/pub-sub-webhook-subscription-create-user-error";
import PubSubWebhookSubscriptionCreateUserErrorCode from "./types/pub-sub-webhook-subscription-create-user-error-code";
import PubSubWebhookSubscriptionInput from "./types/pub-sub-webhook-subscription-input";
import PubSubWebhookSubscriptionUpdatePayload from "./types/pub-sub-webhook-subscription-update-payload";
import PubSubWebhookSubscriptionUpdateUserError from "./types/pub-sub-webhook-subscription-update-user-error";
import PubSubWebhookSubscriptionUpdateUserErrorCode from "./types/pub-sub-webhook-subscription-update-user-error-code";
import Publication from "./types/publication";
import PublicationConnection from "./types/publication-connection";
import PublicationCreateInput from "./types/publication-create-input";
import PublicationCreateInputPublicationDefaultState from "./types/publication-create-input-publication-default-state";
import PublicationCreatePayload from "./types/publication-create-payload";
import PublicationDeletePayload from "./types/publication-delete-payload";
import PublicationEdge from "./types/publication-edge";
import PublicationInput from "./types/publication-input";
import PublicationOperation from "./types/publication-operation";
import PublicationResourceOperation from "./types/publication-resource-operation";
import PublicationUpdateInput from "./types/publication-update-input";
import PublicationUpdatePayload from "./types/publication-update-payload";
import PublicationUserError from "./types/publication-user-error";
import PublicationUserErrorCode from "./types/publication-user-error-code";
import Publishable from "./types/publishable";
import PublishablePublishPayload from "./types/publishable-publish-payload";
import PublishablePublishToCurrentChannelPayload from "./types/publishable-publish-to-current-channel-payload";
import PublishableUnpublishPayload from "./types/publishable-unpublish-payload";
import PublishableUnpublishToCurrentChannelPayload from "./types/publishable-unpublish-to-current-channel-payload";
import PurchasingCompany from "./types/purchasing-company";
import PurchasingCompanyInput from "./types/purchasing-company-input";
import PurchasingEntity from "./types/purchasing-entity";
import PurchasingEntityInput from "./types/purchasing-entity-input";
import QuantityPriceBreak from "./types/quantity-price-break";
import QuantityPriceBreakConnection from "./types/quantity-price-break-connection";
import QuantityPriceBreakEdge from "./types/quantity-price-break-edge";
import QuantityPriceBreakInput from "./types/quantity-price-break-input";
import QuantityPriceBreakSortKeys from "./types/quantity-price-break-sort-keys";
import QuantityPricingByVariantUpdateInput from "./types/quantity-pricing-by-variant-update-input";
import QuantityPricingByVariantUpdatePayload from "./types/quantity-pricing-by-variant-update-payload";
import QuantityPricingByVariantUserError from "./types/quantity-pricing-by-variant-user-error";
import QuantityPricingByVariantUserErrorCode from "./types/quantity-pricing-by-variant-user-error-code";
import QuantityRule from "./types/quantity-rule";
import QuantityRuleConnection from "./types/quantity-rule-connection";
import QuantityRuleEdge from "./types/quantity-rule-edge";
import QuantityRuleInput from "./types/quantity-rule-input";
import QuantityRuleOriginType from "./types/quantity-rule-origin-type";
import QuantityRuleUserError from "./types/quantity-rule-user-error";
import QuantityRuleUserErrorCode from "./types/quantity-rule-user-error-code";
import QuantityRulesAddPayload from "./types/quantity-rules-add-payload";
import QuantityRulesDeletePayload from "./types/quantity-rules-delete-payload";
import QueryRoot from "./types/query-root";
import Refund from "./types/refund";
import RefundAgreement from "./types/refund-agreement";
import RefundConnection from "./types/refund-connection";
import RefundCreatePayload from "./types/refund-create-payload";
import RefundDuty from "./types/refund-duty";
import RefundDutyInput from "./types/refund-duty-input";
import RefundDutyRefundType from "./types/refund-duty-refund-type";
import RefundEdge from "./types/refund-edge";
import RefundInput from "./types/refund-input";
import RefundLineItem from "./types/refund-line-item";
import RefundLineItemConnection from "./types/refund-line-item-connection";
import RefundLineItemEdge from "./types/refund-line-item-edge";
import RefundLineItemInput from "./types/refund-line-item-input";
import RefundLineItemRestockType from "./types/refund-line-item-restock-type";
import RefundShippingInput from "./types/refund-shipping-input";
import RefundShippingLine from "./types/refund-shipping-line";
import RefundShippingLineConnection from "./types/refund-shipping-line-connection";
import RefundShippingLineEdge from "./types/refund-shipping-line-edge";
import RemoteAuthorizeNetCustomerPaymentProfileInput from "./types/remote-authorize-net-customer-payment-profile-input";
import RemoteBraintreePaymentMethodInput from "./types/remote-braintree-payment-method-input";
import RemoteStripePaymentMethodInput from "./types/remote-stripe-payment-method-input";
import ResourceAlert from "./types/resource-alert";
import ResourceAlertAction from "./types/resource-alert-action";
import ResourceAlertIcon from "./types/resource-alert-icon";
import ResourceAlertSeverity from "./types/resource-alert-severity";
import ResourceFeedback from "./types/resource-feedback";
import ResourceFeedbackCreateInput from "./types/resource-feedback-create-input";
import ResourceFeedbackState from "./types/resource-feedback-state";
import ResourceOperation from "./types/resource-operation";
import ResourceOperationStatus from "./types/resource-operation-status";
import ResourcePublication from "./types/resource-publication";
import ResourcePublicationConnection from "./types/resource-publication-connection";
import ResourcePublicationEdge from "./types/resource-publication-edge";
import ResourcePublicationV2 from "./types/resource-publication-v-2";
import ResourcePublicationV2Connection from "./types/resource-publication-v-2-connection";
import ResourcePublicationV2Edge from "./types/resource-publication-v-2-edge";
import RestockingFee from "./types/restocking-fee";
import Return from "./types/return";
import ReturnAgreement from "./types/return-agreement";
import ReturnApproveRequestInput from "./types/return-approve-request-input";
import ReturnApproveRequestPayload from "./types/return-approve-request-payload";
import ReturnCancelPayload from "./types/return-cancel-payload";
import ReturnClosePayload from "./types/return-close-payload";
import ReturnConnection from "./types/return-connection";
import ReturnCreatePayload from "./types/return-create-payload";
import ReturnDecline from "./types/return-decline";
import ReturnDeclineReason from "./types/return-decline-reason";
import ReturnDeclineRequestInput from "./types/return-decline-request-input";
import ReturnDeclineRequestPayload from "./types/return-decline-request-payload";
import ReturnEdge from "./types/return-edge";
import ReturnErrorCode from "./types/return-error-code";
import ReturnInput from "./types/return-input";
import ReturnLineItem from "./types/return-line-item";
import ReturnLineItemConnection from "./types/return-line-item-connection";
import ReturnLineItemEdge from "./types/return-line-item-edge";
import ReturnLineItemInput from "./types/return-line-item-input";
import ReturnReason from "./types/return-reason";
import ReturnRefundInput from "./types/return-refund-input";
import ReturnRefundLineItemInput from "./types/return-refund-line-item-input";
import ReturnRefundOrderTransactionInput from "./types/return-refund-order-transaction-input";
import ReturnRefundPayload from "./types/return-refund-payload";
import ReturnReopenPayload from "./types/return-reopen-payload";
import ReturnRequestInput from "./types/return-request-input";
import ReturnRequestLineItemInput from "./types/return-request-line-item-input";
import ReturnRequestPayload from "./types/return-request-payload";
import ReturnShippingFee from "./types/return-shipping-fee";
import ReturnStatus from "./types/return-status";
import ReturnUserError from "./types/return-user-error";
import ReturnableFulfillment from "./types/returnable-fulfillment";
import ReturnableFulfillmentConnection from "./types/returnable-fulfillment-connection";
import ReturnableFulfillmentEdge from "./types/returnable-fulfillment-edge";
import ReturnableFulfillmentLineItem from "./types/returnable-fulfillment-line-item";
import ReturnableFulfillmentLineItemConnection from "./types/returnable-fulfillment-line-item-connection";
import ReturnableFulfillmentLineItemEdge from "./types/returnable-fulfillment-line-item-edge";
import ReverseDelivery from "./types/reverse-delivery";
import ReverseDeliveryConnection from "./types/reverse-delivery-connection";
import ReverseDeliveryCreateWithShippingPayload from "./types/reverse-delivery-create-with-shipping-payload";
import ReverseDeliveryDeliverable from "./types/reverse-delivery-deliverable";
import ReverseDeliveryDisposeInput from "./types/reverse-delivery-dispose-input";
import ReverseDeliveryDisposePayload from "./types/reverse-delivery-dispose-payload";
import ReverseDeliveryEdge from "./types/reverse-delivery-edge";
import ReverseDeliveryLabelInput from "./types/reverse-delivery-label-input";
import ReverseDeliveryLabelV2 from "./types/reverse-delivery-label-v-2";
import ReverseDeliveryLineItem from "./types/reverse-delivery-line-item";
import ReverseDeliveryLineItemConnection from "./types/reverse-delivery-line-item-connection";
import ReverseDeliveryLineItemEdge from "./types/reverse-delivery-line-item-edge";
import ReverseDeliveryLineItemInput from "./types/reverse-delivery-line-item-input";
import ReverseDeliveryShippingDeliverable from "./types/reverse-delivery-shipping-deliverable";
import ReverseDeliveryShippingUpdatePayload from "./types/reverse-delivery-shipping-update-payload";
import ReverseDeliveryTrackingInput from "./types/reverse-delivery-tracking-input";
import ReverseDeliveryTrackingV2 from "./types/reverse-delivery-tracking-v-2";
import ReverseFulfillmentOrder from "./types/reverse-fulfillment-order";
import ReverseFulfillmentOrderConnection from "./types/reverse-fulfillment-order-connection";
import ReverseFulfillmentOrderDisposeInput from "./types/reverse-fulfillment-order-dispose-input";
import ReverseFulfillmentOrderDisposePayload from "./types/reverse-fulfillment-order-dispose-payload";
import ReverseFulfillmentOrderDisposition from "./types/reverse-fulfillment-order-disposition";
import ReverseFulfillmentOrderDispositionType from "./types/reverse-fulfillment-order-disposition-type";
import ReverseFulfillmentOrderEdge from "./types/reverse-fulfillment-order-edge";
import ReverseFulfillmentOrderLineItem from "./types/reverse-fulfillment-order-line-item";
import ReverseFulfillmentOrderLineItemConnection from "./types/reverse-fulfillment-order-line-item-connection";
import ReverseFulfillmentOrderLineItemEdge from "./types/reverse-fulfillment-order-line-item-edge";
import ReverseFulfillmentOrderStatus from "./types/reverse-fulfillment-order-status";
import ReverseFulfillmentOrderThirdPartyConfirmation from "./types/reverse-fulfillment-order-third-party-confirmation";
import ReverseFulfillmentOrderThirdPartyConfirmationStatus from "./types/reverse-fulfillment-order-third-party-confirmation-status";
import RiskAssessmentResult from "./types/risk-assessment-result";
import RiskFact from "./types/risk-fact";
import RiskFactSentiment from "./types/risk-fact-sentiment";
import RowCount from "./types/row-count";
import SEO from "./types/seo";
import SEOInput from "./types/seo-input";
import Sale from "./types/sale";
import SaleActionType from "./types/sale-action-type";
import SaleAdditionalFee from "./types/sale-additional-fee";
import SaleConnection from "./types/sale-connection";
import SaleEdge from "./types/sale-edge";
import SaleLineType from "./types/sale-line-type";
import SaleTax from "./types/sale-tax";
import SalesAgreement from "./types/sales-agreement";
import SalesAgreementConnection from "./types/sales-agreement-connection";
import SalesAgreementEdge from "./types/sales-agreement-edge";
import SavedSearch from "./types/saved-search";
import SavedSearchConnection from "./types/saved-search-connection";
import SavedSearchCreateInput from "./types/saved-search-create-input";
import SavedSearchCreatePayload from "./types/saved-search-create-payload";
import SavedSearchDeleteInput from "./types/saved-search-delete-input";
import SavedSearchDeletePayload from "./types/saved-search-delete-payload";
import SavedSearchEdge from "./types/saved-search-edge";
import SavedSearchUpdateInput from "./types/saved-search-update-input";
import SavedSearchUpdatePayload from "./types/saved-search-update-payload";
import ScheduledChangeSortKeys from "./types/scheduled-change-sort-keys";
import ScriptDiscountApplication from "./types/script-discount-application";
import ScriptTag from "./types/script-tag";
import ScriptTagConnection from "./types/script-tag-connection";
import ScriptTagCreatePayload from "./types/script-tag-create-payload";
import ScriptTagDeletePayload from "./types/script-tag-delete-payload";
import ScriptTagDisplayScope from "./types/script-tag-display-scope";
import ScriptTagEdge from "./types/script-tag-edge";
import ScriptTagInput from "./types/script-tag-input";
import ScriptTagUpdatePayload from "./types/script-tag-update-payload";
import SearchFilter from "./types/search-filter";
import SearchFilterOptions from "./types/search-filter-options";
import SearchResult from "./types/search-result";
import SearchResultConnection from "./types/search-result-connection";
import SearchResultEdge from "./types/search-result-edge";
import SearchResultType from "./types/search-result-type";
import Segment from "./types/segment";
import SegmentAssociationFilter from "./types/segment-association-filter";
import SegmentAttributeStatistics from "./types/segment-attribute-statistics";
import SegmentBooleanFilter from "./types/segment-boolean-filter";
import SegmentConnection from "./types/segment-connection";
import SegmentCreatePayload from "./types/segment-create-payload";
import SegmentDateFilter from "./types/segment-date-filter";
import SegmentDeletePayload from "./types/segment-delete-payload";
import SegmentEdge from "./types/segment-edge";
import SegmentEnumFilter from "./types/segment-enum-filter";
import SegmentEventFilter from "./types/segment-event-filter";
import SegmentEventFilterParameter from "./types/segment-event-filter-parameter";
import SegmentFilter from "./types/segment-filter";
import SegmentFilterConnection from "./types/segment-filter-connection";
import SegmentFilterEdge from "./types/segment-filter-edge";
import SegmentFloatFilter from "./types/segment-float-filter";
import SegmentIntegerFilter from "./types/segment-integer-filter";
import SegmentMembership from "./types/segment-membership";
import SegmentMembershipResponse from "./types/segment-membership-response";
import SegmentMigration from "./types/segment-migration";
import SegmentMigrationConnection from "./types/segment-migration-connection";
import SegmentMigrationEdge from "./types/segment-migration-edge";
import SegmentSortKeys from "./types/segment-sort-keys";
import SegmentStatistics from "./types/segment-statistics";
import SegmentStringFilter from "./types/segment-string-filter";
import SegmentUpdatePayload from "./types/segment-update-payload";
import SegmentValue from "./types/segment-value";
import SegmentValueConnection from "./types/segment-value-connection";
import SegmentValueEdge from "./types/segment-value-edge";
import SelectedOption from "./types/selected-option";
import SellingPlan from "./types/selling-plan";
import SellingPlanAnchor from "./types/selling-plan-anchor";
import SellingPlanAnchorInput from "./types/selling-plan-anchor-input";
import SellingPlanAnchorType from "./types/selling-plan-anchor-type";
import SellingPlanBillingPolicy from "./types/selling-plan-billing-policy";
import SellingPlanBillingPolicyInput from "./types/selling-plan-billing-policy-input";
import SellingPlanCategory from "./types/selling-plan-category";
import SellingPlanCheckoutCharge from "./types/selling-plan-checkout-charge";
import SellingPlanCheckoutChargeInput from "./types/selling-plan-checkout-charge-input";
import SellingPlanCheckoutChargePercentageValue from "./types/selling-plan-checkout-charge-percentage-value";
import SellingPlanCheckoutChargeType from "./types/selling-plan-checkout-charge-type";
import SellingPlanCheckoutChargeValue from "./types/selling-plan-checkout-charge-value";
import SellingPlanCheckoutChargeValueInput from "./types/selling-plan-checkout-charge-value-input";
import SellingPlanConnection from "./types/selling-plan-connection";
import SellingPlanDeliveryPolicy from "./types/selling-plan-delivery-policy";
import SellingPlanDeliveryPolicyInput from "./types/selling-plan-delivery-policy-input";
import SellingPlanEdge from "./types/selling-plan-edge";
import SellingPlanFixedBillingPolicy from "./types/selling-plan-fixed-billing-policy";
import SellingPlanFixedBillingPolicyInput from "./types/selling-plan-fixed-billing-policy-input";
import SellingPlanFixedDeliveryPolicy from "./types/selling-plan-fixed-delivery-policy";
import SellingPlanFixedDeliveryPolicyInput from "./types/selling-plan-fixed-delivery-policy-input";
import SellingPlanFixedDeliveryPolicyIntent from "./types/selling-plan-fixed-delivery-policy-intent";
import SellingPlanFixedDeliveryPolicyPreAnchorBehavior from "./types/selling-plan-fixed-delivery-policy-pre-anchor-behavior";
import SellingPlanFixedPricingPolicy from "./types/selling-plan-fixed-pricing-policy";
import SellingPlanFixedPricingPolicyInput from "./types/selling-plan-fixed-pricing-policy-input";
import SellingPlanFulfillmentTrigger from "./types/selling-plan-fulfillment-trigger";
import SellingPlanGroup from "./types/selling-plan-group";
import SellingPlanGroupAddProductVariantsPayload from "./types/selling-plan-group-add-product-variants-payload";
import SellingPlanGroupAddProductsPayload from "./types/selling-plan-group-add-products-payload";
import SellingPlanGroupConnection from "./types/selling-plan-group-connection";
import SellingPlanGroupCreatePayload from "./types/selling-plan-group-create-payload";
import SellingPlanGroupDeletePayload from "./types/selling-plan-group-delete-payload";
import SellingPlanGroupEdge from "./types/selling-plan-group-edge";
import SellingPlanGroupInput from "./types/selling-plan-group-input";
import SellingPlanGroupRemoveProductVariantsPayload from "./types/selling-plan-group-remove-product-variants-payload";
import SellingPlanGroupRemoveProductsPayload from "./types/selling-plan-group-remove-products-payload";
import SellingPlanGroupResourceInput from "./types/selling-plan-group-resource-input";
import SellingPlanGroupSortKeys from "./types/selling-plan-group-sort-keys";
import SellingPlanGroupUpdatePayload from "./types/selling-plan-group-update-payload";
import SellingPlanGroupUserError from "./types/selling-plan-group-user-error";
import SellingPlanGroupUserErrorCode from "./types/selling-plan-group-user-error-code";
import SellingPlanInput from "./types/selling-plan-input";
import SellingPlanInterval from "./types/selling-plan-interval";
import SellingPlanInventoryPolicy from "./types/selling-plan-inventory-policy";
import SellingPlanInventoryPolicyInput from "./types/selling-plan-inventory-policy-input";
import SellingPlanPricingPolicy from "./types/selling-plan-pricing-policy";
import SellingPlanPricingPolicyAdjustmentType from "./types/selling-plan-pricing-policy-adjustment-type";
import SellingPlanPricingPolicyAdjustmentValue from "./types/selling-plan-pricing-policy-adjustment-value";
import SellingPlanPricingPolicyBase from "./types/selling-plan-pricing-policy-base";
import SellingPlanPricingPolicyInput from "./types/selling-plan-pricing-policy-input";
import SellingPlanPricingPolicyPercentageValue from "./types/selling-plan-pricing-policy-percentage-value";
import SellingPlanPricingPolicyValueInput from "./types/selling-plan-pricing-policy-value-input";
import SellingPlanRecurringBillingPolicy from "./types/selling-plan-recurring-billing-policy";
import SellingPlanRecurringBillingPolicyInput from "./types/selling-plan-recurring-billing-policy-input";
import SellingPlanRecurringDeliveryPolicy from "./types/selling-plan-recurring-delivery-policy";
import SellingPlanRecurringDeliveryPolicyInput from "./types/selling-plan-recurring-delivery-policy-input";
import SellingPlanRecurringDeliveryPolicyIntent from "./types/selling-plan-recurring-delivery-policy-intent";
import SellingPlanRecurringDeliveryPolicyPreAnchorBehavior from "./types/selling-plan-recurring-delivery-policy-pre-anchor-behavior";
import SellingPlanRecurringPricingPolicy from "./types/selling-plan-recurring-pricing-policy";
import SellingPlanRecurringPricingPolicyInput from "./types/selling-plan-recurring-pricing-policy-input";
import SellingPlanRemainingBalanceChargeTrigger from "./types/selling-plan-remaining-balance-charge-trigger";
import SellingPlanReserve from "./types/selling-plan-reserve";
import ServerPixel from "./types/server-pixel";
import ServerPixelCreatePayload from "./types/server-pixel-create-payload";
import ServerPixelDeletePayload from "./types/server-pixel-delete-payload";
import ServerPixelStatus from "./types/server-pixel-status";
import ShippingDiscountClass from "./types/shipping-discount-class";
import ShippingLabel from "./types/shipping-label";
import ShippingLine from "./types/shipping-line";
import ShippingLineConnection from "./types/shipping-line-connection";
import ShippingLineEdge from "./types/shipping-line-edge";
import ShippingLineInput from "./types/shipping-line-input";
import ShippingLineSale from "./types/shipping-line-sale";
import ShippingMethod from "./types/shipping-method";
import ShippingPackageDeletePayload from "./types/shipping-package-delete-payload";
import ShippingPackageMakeDefaultPayload from "./types/shipping-package-make-default-payload";
import ShippingPackageType from "./types/shipping-package-type";
import ShippingPackageUpdatePayload from "./types/shipping-package-update-payload";
import ShippingRate from "./types/shipping-rate";
import ShippingRefund from "./types/shipping-refund";
import ShippingRefundInput from "./types/shipping-refund-input";
import Shop from "./types/shop";
import ShopAddress from "./types/shop-address";
import ShopAlert from "./types/shop-alert";
import ShopAlertAction from "./types/shop-alert-action";
import ShopBillingPreferences from "./types/shop-billing-preferences";
import ShopBranding from "./types/shop-branding";
import ShopCustomerAccountsSetting from "./types/shop-customer-accounts-setting";
import ShopFeatures from "./types/shop-features";
import ShopLocale from "./types/shop-locale";
import ShopLocaleDisablePayload from "./types/shop-locale-disable-payload";
import ShopLocaleEnablePayload from "./types/shop-locale-enable-payload";
import ShopLocaleInput from "./types/shop-locale-input";
import ShopLocaleUpdatePayload from "./types/shop-locale-update-payload";
import ShopPayInstallmentsPaymentDetails from "./types/shop-pay-installments-payment-details";
import ShopPlan from "./types/shop-plan";
import ShopPolicy from "./types/shop-policy";
import ShopPolicyErrorCode from "./types/shop-policy-error-code";
import ShopPolicyInput from "./types/shop-policy-input";
import ShopPolicyType from "./types/shop-policy-type";
import ShopPolicyUpdatePayload from "./types/shop-policy-update-payload";
import ShopPolicyUserError from "./types/shop-policy-user-error";
import ShopResourceFeedbackCreatePayload from "./types/shop-resource-feedback-create-payload";
import ShopResourceFeedbackCreateUserError from "./types/shop-resource-feedback-create-user-error";
import ShopResourceFeedbackCreateUserErrorCode from "./types/shop-resource-feedback-create-user-error-code";
import ShopResourceLimits from "./types/shop-resource-limits";
import ShopTagSort from "./types/shop-tag-sort";
import ShopifyFunction from "./types/shopify-function";
import ShopifyFunctionConnection from "./types/shopify-function-connection";
import ShopifyFunctionEdge from "./types/shopify-function-edge";
import ShopifyPaymentsAccount from "./types/shopify-payments-account";
import ShopifyPaymentsAdjustmentOrder from "./types/shopify-payments-adjustment-order";
import ShopifyPaymentsBalanceTransaction from "./types/shopify-payments-balance-transaction";
import ShopifyPaymentsBalanceTransactionConnection from "./types/shopify-payments-balance-transaction-connection";
import ShopifyPaymentsBalanceTransactionEdge from "./types/shopify-payments-balance-transaction-edge";
import ShopifyPaymentsBankAccount from "./types/shopify-payments-bank-account";
import ShopifyPaymentsBankAccountConnection from "./types/shopify-payments-bank-account-connection";
import ShopifyPaymentsBankAccountEdge from "./types/shopify-payments-bank-account-edge";
import ShopifyPaymentsBankAccountStatus from "./types/shopify-payments-bank-account-status";
import ShopifyPaymentsChargeStatementDescriptor from "./types/shopify-payments-charge-statement-descriptor";
import ShopifyPaymentsDefaultChargeStatementDescriptor from "./types/shopify-payments-default-charge-statement-descriptor";
import ShopifyPaymentsDispute from "./types/shopify-payments-dispute";
import ShopifyPaymentsDisputeConnection from "./types/shopify-payments-dispute-connection";
import ShopifyPaymentsDisputeEdge from "./types/shopify-payments-dispute-edge";
import ShopifyPaymentsDisputeEvidence from "./types/shopify-payments-dispute-evidence";
import ShopifyPaymentsDisputeEvidenceFileType from "./types/shopify-payments-dispute-evidence-file-type";
import ShopifyPaymentsDisputeEvidenceUpdateInput from "./types/shopify-payments-dispute-evidence-update-input";
import ShopifyPaymentsDisputeFileUpload from "./types/shopify-payments-dispute-file-upload";
import ShopifyPaymentsDisputeFileUploadUpdateInput from "./types/shopify-payments-dispute-file-upload-update-input";
import ShopifyPaymentsDisputeFulfillment from "./types/shopify-payments-dispute-fulfillment";
import ShopifyPaymentsDisputeReason from "./types/shopify-payments-dispute-reason";
import ShopifyPaymentsDisputeReasonDetails from "./types/shopify-payments-dispute-reason-details";
import ShopifyPaymentsExtendedAuthorization from "./types/shopify-payments-extended-authorization";
import ShopifyPaymentsFraudSettings from "./types/shopify-payments-fraud-settings";
import ShopifyPaymentsJpChargeStatementDescriptor from "./types/shopify-payments-jp-charge-statement-descriptor";
import ShopifyPaymentsNotificationSettings from "./types/shopify-payments-notification-settings";
import ShopifyPaymentsPayout from "./types/shopify-payments-payout";
import ShopifyPaymentsPayoutConnection from "./types/shopify-payments-payout-connection";
import ShopifyPaymentsPayoutEdge from "./types/shopify-payments-payout-edge";
import ShopifyPaymentsPayoutInterval from "./types/shopify-payments-payout-interval";
import ShopifyPaymentsPayoutSchedule from "./types/shopify-payments-payout-schedule";
import ShopifyPaymentsPayoutStatus from "./types/shopify-payments-payout-status";
import ShopifyPaymentsPayoutSummary from "./types/shopify-payments-payout-summary";
import ShopifyPaymentsPayoutTransactionType from "./types/shopify-payments-payout-transaction-type";
import ShopifyPaymentsRefundSet from "./types/shopify-payments-refund-set";
import ShopifyPaymentsTransactionSet from "./types/shopify-payments-transaction-set";
import ShopifyPaymentsVerification from "./types/shopify-payments-verification";
import ShopifyPaymentsVerificationDocument from "./types/shopify-payments-verification-document";
import ShopifyPaymentsVerificationDocumentType from "./types/shopify-payments-verification-document-type";
import ShopifyPaymentsVerificationStatus from "./types/shopify-payments-verification-status";
import ShopifyPaymentsVerificationSubject from "./types/shopify-payments-verification-subject";
import ShopifyProtectEligibilityStatus from "./types/shopify-protect-eligibility-status";
import ShopifyProtectOrderEligibility from "./types/shopify-protect-order-eligibility";
import ShopifyProtectOrderSummary from "./types/shopify-protect-order-summary";
import ShopifyProtectStatus from "./types/shopify-protect-status";
import ShopifyqlResponse from "./types/shopifyql-response";
import StaffMember from "./types/staff-member";
import StaffMemberConnection from "./types/staff-member-connection";
import StaffMemberDefaultImage from "./types/staff-member-default-image";
import StaffMemberEdge from "./types/staff-member-edge";
import StaffMemberPermission from "./types/staff-member-permission";
import StaffMemberPrivateData from "./types/staff-member-private-data";
import StageImageInput from "./types/stage-image-input";
import StagedMediaUploadTarget from "./types/staged-media-upload-target";
import StagedUploadHttpMethodType from "./types/staged-upload-http-method-type";
import StagedUploadInput from "./types/staged-upload-input";
import StagedUploadParameter from "./types/staged-upload-parameter";
import StagedUploadTarget from "./types/staged-upload-target";
import StagedUploadTargetGenerateInput from "./types/staged-upload-target-generate-input";
import StagedUploadTargetGeneratePayload from "./types/staged-upload-target-generate-payload";
import StagedUploadTargetGenerateUploadResource from "./types/staged-upload-target-generate-upload-resource";
import StagedUploadTargetsGeneratePayload from "./types/staged-upload-targets-generate-payload";
import StagedUploadsCreatePayload from "./types/staged-uploads-create-payload";
import StandardMetafieldDefinitionEnablePayload from "./types/standard-metafield-definition-enable-payload";
import StandardMetafieldDefinitionEnableUserError from "./types/standard-metafield-definition-enable-user-error";
import StandardMetafieldDefinitionEnableUserErrorCode from "./types/standard-metafield-definition-enable-user-error-code";
import StandardMetafieldDefinitionTemplate from "./types/standard-metafield-definition-template";
import StandardMetafieldDefinitionTemplateConnection from "./types/standard-metafield-definition-template-connection";
import StandardMetafieldDefinitionTemplateEdge from "./types/standard-metafield-definition-template-edge";
import StandardMetaobjectDefinitionEnablePayload from "./types/standard-metaobject-definition-enable-payload";
import StandardizedProductType from "./types/standardized-product-type";
import StandardizedProductTypeInput from "./types/standardized-product-type-input";
import StorefrontAccessToken from "./types/storefront-access-token";
import StorefrontAccessTokenConnection from "./types/storefront-access-token-connection";
import StorefrontAccessTokenCreatePayload from "./types/storefront-access-token-create-payload";
import StorefrontAccessTokenDeleteInput from "./types/storefront-access-token-delete-input";
import StorefrontAccessTokenDeletePayload from "./types/storefront-access-token-delete-payload";
import StorefrontAccessTokenEdge from "./types/storefront-access-token-edge";
import StorefrontAccessTokenInput from "./types/storefront-access-token-input";
import StorefrontID from "./types/storefront-id";
import String from "./types/string";
import StringConnection from "./types/string-connection";
import StringEdge from "./types/string-edge";
import SubscriptionAppliedCodeDiscount from "./types/subscription-applied-code-discount";
import SubscriptionAtomicLineInput from "./types/subscription-atomic-line-input";
import SubscriptionAtomicManualDiscountInput from "./types/subscription-atomic-manual-discount-input";
import SubscriptionBillingAttempt from "./types/subscription-billing-attempt";
import SubscriptionBillingAttemptConnection from "./types/subscription-billing-attempt-connection";
import SubscriptionBillingAttemptCreatePayload from "./types/subscription-billing-attempt-create-payload";
import SubscriptionBillingAttemptEdge from "./types/subscription-billing-attempt-edge";
import SubscriptionBillingAttemptErrorCode from "./types/subscription-billing-attempt-error-code";
import SubscriptionBillingAttemptInput from "./types/subscription-billing-attempt-input";
import SubscriptionBillingAttemptsSortKeys from "./types/subscription-billing-attempts-sort-keys";
import SubscriptionBillingCycle from "./types/subscription-billing-cycle";
import SubscriptionBillingCycleBillingCycleStatus from "./types/subscription-billing-cycle-billing-cycle-status";
import SubscriptionBillingCycleConnection from "./types/subscription-billing-cycle-connection";
import SubscriptionBillingCycleContractDraftCommitPayload from "./types/subscription-billing-cycle-contract-draft-commit-payload";
import SubscriptionBillingCycleContractDraftConcatenatePayload from "./types/subscription-billing-cycle-contract-draft-concatenate-payload";
import SubscriptionBillingCycleContractEditPayload from "./types/subscription-billing-cycle-contract-edit-payload";
import SubscriptionBillingCycleEdge from "./types/subscription-billing-cycle-edge";
import SubscriptionBillingCycleEditDeletePayload from "./types/subscription-billing-cycle-edit-delete-payload";
import SubscriptionBillingCycleEditedContract from "./types/subscription-billing-cycle-edited-contract";
import SubscriptionBillingCycleEditsDeletePayload from "./types/subscription-billing-cycle-edits-delete-payload";
import SubscriptionBillingCycleErrorCode from "./types/subscription-billing-cycle-error-code";
import SubscriptionBillingCycleInput from "./types/subscription-billing-cycle-input";
import SubscriptionBillingCycleScheduleEditInput from "./types/subscription-billing-cycle-schedule-edit-input";
import SubscriptionBillingCycleScheduleEditInputScheduleEditReason from "./types/subscription-billing-cycle-schedule-edit-input-schedule-edit-reason";
import SubscriptionBillingCycleScheduleEditPayload from "./types/subscription-billing-cycle-schedule-edit-payload";
import SubscriptionBillingCycleSelector from "./types/subscription-billing-cycle-selector";
import SubscriptionBillingCycleSkipPayload from "./types/subscription-billing-cycle-skip-payload";
import SubscriptionBillingCycleSkipUserError from "./types/subscription-billing-cycle-skip-user-error";
import SubscriptionBillingCycleSkipUserErrorCode from "./types/subscription-billing-cycle-skip-user-error-code";
import SubscriptionBillingCycleUnskipPayload from "./types/subscription-billing-cycle-unskip-payload";
import SubscriptionBillingCycleUnskipUserError from "./types/subscription-billing-cycle-unskip-user-error";
import SubscriptionBillingCycleUnskipUserErrorCode from "./types/subscription-billing-cycle-unskip-user-error-code";
import SubscriptionBillingCycleUserError from "./types/subscription-billing-cycle-user-error";
import SubscriptionBillingCyclesDateRangeSelector from "./types/subscription-billing-cycles-date-range-selector";
import SubscriptionBillingCyclesIndexRangeSelector from "./types/subscription-billing-cycles-index-range-selector";
import SubscriptionBillingCyclesSortKeys from "./types/subscription-billing-cycles-sort-keys";
import SubscriptionBillingCyclesTargetSelection from "./types/subscription-billing-cycles-target-selection";
import SubscriptionBillingPolicy from "./types/subscription-billing-policy";
import SubscriptionBillingPolicyInput from "./types/subscription-billing-policy-input";
import SubscriptionContract from "./types/subscription-contract";
import SubscriptionContractActivatePayload from "./types/subscription-contract-activate-payload";
import SubscriptionContractAtomicCreateInput from "./types/subscription-contract-atomic-create-input";
import SubscriptionContractAtomicCreatePayload from "./types/subscription-contract-atomic-create-payload";
import SubscriptionContractBase from "./types/subscription-contract-base";
import SubscriptionContractCancelPayload from "./types/subscription-contract-cancel-payload";
import SubscriptionContractConnection from "./types/subscription-contract-connection";
import SubscriptionContractCreateInput from "./types/subscription-contract-create-input";
import SubscriptionContractCreatePayload from "./types/subscription-contract-create-payload";
import SubscriptionContractEdge from "./types/subscription-contract-edge";
import SubscriptionContractErrorCode from "./types/subscription-contract-error-code";
import SubscriptionContractExpirePayload from "./types/subscription-contract-expire-payload";
import SubscriptionContractFailPayload from "./types/subscription-contract-fail-payload";
import SubscriptionContractLastPaymentStatus from "./types/subscription-contract-last-payment-status";
import SubscriptionContractPausePayload from "./types/subscription-contract-pause-payload";
import SubscriptionContractProductChangeInput from "./types/subscription-contract-product-change-input";
import SubscriptionContractProductChangePayload from "./types/subscription-contract-product-change-payload";
import SubscriptionContractSetNextBillingDatePayload from "./types/subscription-contract-set-next-billing-date-payload";
import SubscriptionContractStatusUpdateErrorCode from "./types/subscription-contract-status-update-error-code";
import SubscriptionContractStatusUpdateUserError from "./types/subscription-contract-status-update-user-error";
import SubscriptionContractSubscriptionStatus from "./types/subscription-contract-subscription-status";
import SubscriptionContractUpdatePayload from "./types/subscription-contract-update-payload";
import SubscriptionContractUserError from "./types/subscription-contract-user-error";
import SubscriptionCyclePriceAdjustment from "./types/subscription-cycle-price-adjustment";
import SubscriptionDeliveryMethod from "./types/subscription-delivery-method";
import SubscriptionDeliveryMethodInput from "./types/subscription-delivery-method-input";
import SubscriptionDeliveryMethodLocalDelivery from "./types/subscription-delivery-method-local-delivery";
import SubscriptionDeliveryMethodLocalDeliveryInput from "./types/subscription-delivery-method-local-delivery-input";
import SubscriptionDeliveryMethodLocalDeliveryOption from "./types/subscription-delivery-method-local-delivery-option";
import SubscriptionDeliveryMethodLocalDeliveryOptionInput from "./types/subscription-delivery-method-local-delivery-option-input";
import SubscriptionDeliveryMethodPickup from "./types/subscription-delivery-method-pickup";
import SubscriptionDeliveryMethodPickupInput from "./types/subscription-delivery-method-pickup-input";
import SubscriptionDeliveryMethodPickupOption from "./types/subscription-delivery-method-pickup-option";
import SubscriptionDeliveryMethodPickupOptionInput from "./types/subscription-delivery-method-pickup-option-input";
import SubscriptionDeliveryMethodShipping from "./types/subscription-delivery-method-shipping";
import SubscriptionDeliveryMethodShippingInput from "./types/subscription-delivery-method-shipping-input";
import SubscriptionDeliveryMethodShippingOption from "./types/subscription-delivery-method-shipping-option";
import SubscriptionDeliveryMethodShippingOptionInput from "./types/subscription-delivery-method-shipping-option-input";
import SubscriptionDeliveryOption from "./types/subscription-delivery-option";
import SubscriptionDeliveryOptionResult from "./types/subscription-delivery-option-result";
import SubscriptionDeliveryOptionResultFailure from "./types/subscription-delivery-option-result-failure";
import SubscriptionDeliveryOptionResultSuccess from "./types/subscription-delivery-option-result-success";
import SubscriptionDeliveryPolicy from "./types/subscription-delivery-policy";
import SubscriptionDeliveryPolicyInput from "./types/subscription-delivery-policy-input";
import SubscriptionDiscount from "./types/subscription-discount";
import SubscriptionDiscountAllocation from "./types/subscription-discount-allocation";
import SubscriptionDiscountConnection from "./types/subscription-discount-connection";
import SubscriptionDiscountEdge from "./types/subscription-discount-edge";
import SubscriptionDiscountEntitledLines from "./types/subscription-discount-entitled-lines";
import SubscriptionDiscountFixedAmountValue from "./types/subscription-discount-fixed-amount-value";
import SubscriptionDiscountPercentageValue from "./types/subscription-discount-percentage-value";
import SubscriptionDiscountRejectionReason from "./types/subscription-discount-rejection-reason";
import SubscriptionDiscountValue from "./types/subscription-discount-value";
import SubscriptionDraft from "./types/subscription-draft";
import SubscriptionDraftCommitPayload from "./types/subscription-draft-commit-payload";
import SubscriptionDraftDiscountAddPayload from "./types/subscription-draft-discount-add-payload";
import SubscriptionDraftDiscountCodeApplyPayload from "./types/subscription-draft-discount-code-apply-payload";
import SubscriptionDraftDiscountRemovePayload from "./types/subscription-draft-discount-remove-payload";
import SubscriptionDraftDiscountUpdatePayload from "./types/subscription-draft-discount-update-payload";
import SubscriptionDraftErrorCode from "./types/subscription-draft-error-code";
import SubscriptionDraftFreeShippingDiscountAddPayload from "./types/subscription-draft-free-shipping-discount-add-payload";
import SubscriptionDraftFreeShippingDiscountUpdatePayload from "./types/subscription-draft-free-shipping-discount-update-payload";
import SubscriptionDraftInput from "./types/subscription-draft-input";
import SubscriptionDraftLineAddPayload from "./types/subscription-draft-line-add-payload";
import SubscriptionDraftLineRemovePayload from "./types/subscription-draft-line-remove-payload";
import SubscriptionDraftLineUpdatePayload from "./types/subscription-draft-line-update-payload";
import SubscriptionDraftUpdatePayload from "./types/subscription-draft-update-payload";
import SubscriptionDraftUserError from "./types/subscription-draft-user-error";
import SubscriptionFreeShippingDiscountInput from "./types/subscription-free-shipping-discount-input";
import SubscriptionLine from "./types/subscription-line";
import SubscriptionLineConnection from "./types/subscription-line-connection";
import SubscriptionLineEdge from "./types/subscription-line-edge";
import SubscriptionLineInput from "./types/subscription-line-input";
import SubscriptionLineUpdateInput from "./types/subscription-line-update-input";
import SubscriptionLocalDeliveryOption from "./types/subscription-local-delivery-option";
import SubscriptionMailingAddress from "./types/subscription-mailing-address";
import SubscriptionManualDiscount from "./types/subscription-manual-discount";
import SubscriptionManualDiscountConnection from "./types/subscription-manual-discount-connection";
import SubscriptionManualDiscountEdge from "./types/subscription-manual-discount-edge";
import SubscriptionManualDiscountEntitledLinesInput from "./types/subscription-manual-discount-entitled-lines-input";
import SubscriptionManualDiscountFixedAmountInput from "./types/subscription-manual-discount-fixed-amount-input";
import SubscriptionManualDiscountInput from "./types/subscription-manual-discount-input";
import SubscriptionManualDiscountLinesInput from "./types/subscription-manual-discount-lines-input";
import SubscriptionManualDiscountValueInput from "./types/subscription-manual-discount-value-input";
import SubscriptionPickupOption from "./types/subscription-pickup-option";
import SubscriptionPricingPolicy from "./types/subscription-pricing-policy";
import SubscriptionPricingPolicyCycleDiscountsInput from "./types/subscription-pricing-policy-cycle-discounts-input";
import SubscriptionPricingPolicyInput from "./types/subscription-pricing-policy-input";
import SubscriptionShippingOption from "./types/subscription-shipping-option";
import SubscriptionShippingOptionResult from "./types/subscription-shipping-option-result";
import SubscriptionShippingOptionResultFailure from "./types/subscription-shipping-option-result-failure";
import SubscriptionShippingOptionResultSuccess from "./types/subscription-shipping-option-result-success";
import SuggestedOrderTransaction from "./types/suggested-order-transaction";
import SuggestedOrderTransactionKind from "./types/suggested-order-transaction-kind";
import SuggestedRefund from "./types/suggested-refund";
import SuggestedReturnRefund from "./types/suggested-return-refund";
import TableData from "./types/table-data";
import TableDataColumn from "./types/table-data-column";
import TableResponse from "./types/table-response";
import TagsAddPayload from "./types/tags-add-payload";
import TagsRemovePayload from "./types/tags-remove-payload";
import TaxAppConfiguration from "./types/tax-app-configuration";
import TaxAppConfigurePayload from "./types/tax-app-configure-payload";
import TaxAppConfigureUserError from "./types/tax-app-configure-user-error";
import TaxAppConfigureUserErrorCode from "./types/tax-app-configure-user-error-code";
import TaxExemption from "./types/tax-exemption";
import TaxLine from "./types/tax-line";
import TaxPartnerState from "./types/tax-partner-state";
import Taxonomy from "./types/taxonomy";
import TaxonomyAttribute from "./types/taxonomy-attribute";
import TaxonomyCategory from "./types/taxonomy-category";
import TaxonomyCategoryAttribute from "./types/taxonomy-category-attribute";
import TaxonomyCategoryAttributeConnection from "./types/taxonomy-category-attribute-connection";
import TaxonomyCategoryAttributeEdge from "./types/taxonomy-category-attribute-edge";
import TaxonomyCategoryConnection from "./types/taxonomy-category-connection";
import TaxonomyCategoryEdge from "./types/taxonomy-category-edge";
import TaxonomyChoiceListAttribute from "./types/taxonomy-choice-list-attribute";
import TaxonomyMeasurementAttribute from "./types/taxonomy-measurement-attribute";
import TaxonomyValue from "./types/taxonomy-value";
import TaxonomyValueConnection from "./types/taxonomy-value-connection";
import TaxonomyValueEdge from "./types/taxonomy-value-edge";
import TenderTransaction from "./types/tender-transaction";
import TenderTransactionConnection from "./types/tender-transaction-connection";
import TenderTransactionCreditCardDetails from "./types/tender-transaction-credit-card-details";
import TenderTransactionDetails from "./types/tender-transaction-details";
import TenderTransactionEdge from "./types/tender-transaction-edge";
import TipSale from "./types/tip-sale";
import TransactionFee from "./types/transaction-fee";
import TransactionVoidPayload from "./types/transaction-void-payload";
import TransactionVoidUserError from "./types/transaction-void-user-error";
import TransactionVoidUserErrorCode from "./types/transaction-void-user-error-code";
import TranslatableContent from "./types/translatable-content";
import TranslatableResource from "./types/translatable-resource";
import TranslatableResourceConnection from "./types/translatable-resource-connection";
import TranslatableResourceEdge from "./types/translatable-resource-edge";
import TranslatableResourceType from "./types/translatable-resource-type";
import Translation from "./types/translation";
import TranslationErrorCode from "./types/translation-error-code";
import TranslationInput from "./types/translation-input";
import TranslationUserError from "./types/translation-user-error";
import TranslationsRegisterPayload from "./types/translations-register-payload";
import TranslationsRemovePayload from "./types/translations-remove-payload";
import TypedAttribute from "./types/typed-attribute";
import URL from "./types/url";
import UTMInput from "./types/utm-input";
import UTMParameters from "./types/utm-parameters";
import UnitSystem from "./types/unit-system";
import UnknownSale from "./types/unknown-sale";
import UnsignedInt64 from "./types/unsigned-int-64";
import UpdateMediaInput from "./types/update-media-input";
import UrlRedirect from "./types/url-redirect";
import UrlRedirectBulkDeleteAllPayload from "./types/url-redirect-bulk-delete-all-payload";
import UrlRedirectBulkDeleteByIdsPayload from "./types/url-redirect-bulk-delete-by-ids-payload";
import UrlRedirectBulkDeleteByIdsUserError from "./types/url-redirect-bulk-delete-by-ids-user-error";
import UrlRedirectBulkDeleteByIdsUserErrorCode from "./types/url-redirect-bulk-delete-by-ids-user-error-code";
import UrlRedirectBulkDeleteBySavedSearchPayload from "./types/url-redirect-bulk-delete-by-saved-search-payload";
import UrlRedirectBulkDeleteBySavedSearchUserError from "./types/url-redirect-bulk-delete-by-saved-search-user-error";
import UrlRedirectBulkDeleteBySavedSearchUserErrorCode from "./types/url-redirect-bulk-delete-by-saved-search-user-error-code";
import UrlRedirectBulkDeleteBySearchPayload from "./types/url-redirect-bulk-delete-by-search-payload";
import UrlRedirectBulkDeleteBySearchUserError from "./types/url-redirect-bulk-delete-by-search-user-error";
import UrlRedirectBulkDeleteBySearchUserErrorCode from "./types/url-redirect-bulk-delete-by-search-user-error-code";
import UrlRedirectConnection from "./types/url-redirect-connection";
import UrlRedirectCreatePayload from "./types/url-redirect-create-payload";
import UrlRedirectDeletePayload from "./types/url-redirect-delete-payload";
import UrlRedirectEdge from "./types/url-redirect-edge";
import UrlRedirectErrorCode from "./types/url-redirect-error-code";
import UrlRedirectImport from "./types/url-redirect-import";
import UrlRedirectImportCreatePayload from "./types/url-redirect-import-create-payload";
import UrlRedirectImportErrorCode from "./types/url-redirect-import-error-code";
import UrlRedirectImportPreview from "./types/url-redirect-import-preview";
import UrlRedirectImportSubmitPayload from "./types/url-redirect-import-submit-payload";
import UrlRedirectImportUserError from "./types/url-redirect-import-user-error";
import UrlRedirectInput from "./types/url-redirect-input";
import UrlRedirectSortKeys from "./types/url-redirect-sort-keys";
import UrlRedirectUpdatePayload from "./types/url-redirect-update-payload";
import UrlRedirectUserError from "./types/url-redirect-user-error";
import UserError from "./types/user-error";
import UtcOffset from "./types/utc-offset";
import Validation from "./types/validation";
import ValidationConnection from "./types/validation-connection";
import ValidationCreateInput from "./types/validation-create-input";
import ValidationCreatePayload from "./types/validation-create-payload";
import ValidationDeletePayload from "./types/validation-delete-payload";
import ValidationEdge from "./types/validation-edge";
import ValidationSortKeys from "./types/validation-sort-keys";
import ValidationUpdateInput from "./types/validation-update-input";
import ValidationUpdatePayload from "./types/validation-update-payload";
import ValidationUserError from "./types/validation-user-error";
import ValidationUserErrorCode from "./types/validation-user-error-code";
import VariantOptionValueInput from "./types/variant-option-value-input";
import VaultCreditCard from "./types/vault-credit-card";
import VaultPaypalBillingAgreement from "./types/vault-paypal-billing-agreement";
import Vector3 from "./types/vector-3";
import Video from "./types/video";
import VideoSource from "./types/video-source";
import VisualizationType from "./types/visualization-type";
import WebPixel from "./types/web-pixel";
import WebPixelCreatePayload from "./types/web-pixel-create-payload";
import WebPixelDeletePayload from "./types/web-pixel-delete-payload";
import WebPixelInput from "./types/web-pixel-input";
import WebPixelUpdatePayload from "./types/web-pixel-update-payload";
import WebhookEventBridgeEndpoint from "./types/webhook-event-bridge-endpoint";
import WebhookHttpEndpoint from "./types/webhook-http-endpoint";
import WebhookPubSubEndpoint from "./types/webhook-pub-sub-endpoint";
import WebhookSubscription from "./types/webhook-subscription";
import WebhookSubscriptionConnection from "./types/webhook-subscription-connection";
import WebhookSubscriptionCreatePayload from "./types/webhook-subscription-create-payload";
import WebhookSubscriptionDeletePayload from "./types/webhook-subscription-delete-payload";
import WebhookSubscriptionEdge from "./types/webhook-subscription-edge";
import WebhookSubscriptionEndpoint from "./types/webhook-subscription-endpoint";
import WebhookSubscriptionFormat from "./types/webhook-subscription-format";
import WebhookSubscriptionInput from "./types/webhook-subscription-input";
import WebhookSubscriptionSortKeys from "./types/webhook-subscription-sort-keys";
import WebhookSubscriptionTopic from "./types/webhook-subscription-topic";
import WebhookSubscriptionUpdatePayload from "./types/webhook-subscription-update-payload";
import Weight from "./types/weight";
import WeightInput from "./types/weight-input";
import WeightUnit from "./types/weight-unit";
import __Directive from "./types/directive";
import __DirectiveLocation from "./types/directive-location";
import __EnumValue from "./types/enum-value";
import __Field from "./types/field";
import __InputValue from "./types/input-value";
import __Schema from "./types/schema";
import __Type from "./types/type";
import __TypeKind from "./types/type-kind";
import deliveryProfileCreatePayload from "./types/delivery-profile-create-payload";
import deliveryProfileRemovePayload from "./types/delivery-profile-remove-payload";
import deliveryProfileUpdatePayload from "./types/delivery-profile-update-payload";
const Types = {
  types: {}
};
Types.types["ARN"] = ARN;
Types.types["AbandonedCheckout"] = AbandonedCheckout;
Types.types["AbandonedCheckoutLineItem"] = AbandonedCheckoutLineItem;
Types.types["AbandonedCheckoutLineItemConnection"] = AbandonedCheckoutLineItemConnection;
Types.types["AbandonedCheckoutLineItemEdge"] = AbandonedCheckoutLineItemEdge;
Types.types["Abandonment"] = Abandonment;
Types.types["AbandonmentAbandonmentType"] = AbandonmentAbandonmentType;
Types.types["AbandonmentDeliveryState"] = AbandonmentDeliveryState;
Types.types["AbandonmentEmailState"] = AbandonmentEmailState;
Types.types["AbandonmentEmailStateUpdatePayload"] = AbandonmentEmailStateUpdatePayload;
Types.types["AbandonmentEmailStateUpdateUserError"] = AbandonmentEmailStateUpdateUserError;
Types.types["AbandonmentEmailStateUpdateUserErrorCode"] = AbandonmentEmailStateUpdateUserErrorCode;
Types.types["AbandonmentUpdateActivitiesDeliveryStatusesPayload"] = AbandonmentUpdateActivitiesDeliveryStatusesPayload;
Types.types["AbandonmentUpdateActivitiesDeliveryStatusesUserError"] = AbandonmentUpdateActivitiesDeliveryStatusesUserError;
Types.types["AbandonmentUpdateActivitiesDeliveryStatusesUserErrorCode"] = AbandonmentUpdateActivitiesDeliveryStatusesUserErrorCode;
Types.types["AccessScope"] = AccessScope;
Types.types["AddAllProductsOperation"] = AddAllProductsOperation;
Types.types["AdditionalFee"] = AdditionalFee;
Types.types["AdditionalFeeSale"] = AdditionalFeeSale;
Types.types["AdjustmentSale"] = AdjustmentSale;
Types.types["AdjustmentsSortKeys"] = AdjustmentsSortKeys;
Types.types["AllDiscountItems"] = AllDiscountItems;
Types.types["ApiVersion"] = ApiVersion;
Types.types["App"] = App;
Types.types["AppCatalog"] = AppCatalog;
Types.types["AppConnection"] = AppConnection;
Types.types["AppCredit"] = AppCredit;
Types.types["AppCreditConnection"] = AppCreditConnection;
Types.types["AppCreditEdge"] = AppCreditEdge;
Types.types["AppDeveloperType"] = AppDeveloperType;
Types.types["AppDiscountType"] = AppDiscountType;
Types.types["AppEdge"] = AppEdge;
Types.types["AppFeedback"] = AppFeedback;
Types.types["AppInstallation"] = AppInstallation;
Types.types["AppInstallationCategory"] = AppInstallationCategory;
Types.types["AppInstallationConnection"] = AppInstallationConnection;
Types.types["AppInstallationEdge"] = AppInstallationEdge;
Types.types["AppInstallationPrivacy"] = AppInstallationPrivacy;
Types.types["AppInstallationSortKeys"] = AppInstallationSortKeys;
Types.types["AppPlanInput"] = AppPlanInput;
Types.types["AppPlanV2"] = AppPlanV2;
Types.types["AppPricingDetails"] = AppPricingDetails;
Types.types["AppPricingInterval"] = AppPricingInterval;
Types.types["AppPublicCategory"] = AppPublicCategory;
Types.types["AppPurchase"] = AppPurchase;
Types.types["AppPurchaseOneTime"] = AppPurchaseOneTime;
Types.types["AppPurchaseOneTimeConnection"] = AppPurchaseOneTimeConnection;
Types.types["AppPurchaseOneTimeCreatePayload"] = AppPurchaseOneTimeCreatePayload;
Types.types["AppPurchaseOneTimeEdge"] = AppPurchaseOneTimeEdge;
Types.types["AppPurchaseStatus"] = AppPurchaseStatus;
Types.types["AppRecurringPricing"] = AppRecurringPricing;
Types.types["AppRecurringPricingInput"] = AppRecurringPricingInput;
Types.types["AppRevenueAttributionRecord"] = AppRevenueAttributionRecord;
Types.types["AppRevenueAttributionRecordConnection"] = AppRevenueAttributionRecordConnection;
Types.types["AppRevenueAttributionRecordEdge"] = AppRevenueAttributionRecordEdge;
Types.types["AppRevenueAttributionRecordSortKeys"] = AppRevenueAttributionRecordSortKeys;
Types.types["AppRevenueAttributionType"] = AppRevenueAttributionType;
Types.types["AppSubscription"] = AppSubscription;
Types.types["AppSubscriptionCancelPayload"] = AppSubscriptionCancelPayload;
Types.types["AppSubscriptionConnection"] = AppSubscriptionConnection;
Types.types["AppSubscriptionCreatePayload"] = AppSubscriptionCreatePayload;
Types.types["AppSubscriptionDiscount"] = AppSubscriptionDiscount;
Types.types["AppSubscriptionDiscountAmount"] = AppSubscriptionDiscountAmount;
Types.types["AppSubscriptionDiscountInput"] = AppSubscriptionDiscountInput;
Types.types["AppSubscriptionDiscountPercentage"] = AppSubscriptionDiscountPercentage;
Types.types["AppSubscriptionDiscountValue"] = AppSubscriptionDiscountValue;
Types.types["AppSubscriptionDiscountValueInput"] = AppSubscriptionDiscountValueInput;
Types.types["AppSubscriptionEdge"] = AppSubscriptionEdge;
Types.types["AppSubscriptionLineItem"] = AppSubscriptionLineItem;
Types.types["AppSubscriptionLineItemInput"] = AppSubscriptionLineItemInput;
Types.types["AppSubscriptionLineItemUpdatePayload"] = AppSubscriptionLineItemUpdatePayload;
Types.types["AppSubscriptionReplacementBehavior"] = AppSubscriptionReplacementBehavior;
Types.types["AppSubscriptionSortKeys"] = AppSubscriptionSortKeys;
Types.types["AppSubscriptionStatus"] = AppSubscriptionStatus;
Types.types["AppSubscriptionTrialExtendPayload"] = AppSubscriptionTrialExtendPayload;
Types.types["AppSubscriptionTrialExtendUserError"] = AppSubscriptionTrialExtendUserError;
Types.types["AppSubscriptionTrialExtendUserErrorCode"] = AppSubscriptionTrialExtendUserErrorCode;
Types.types["AppTransactionSortKeys"] = AppTransactionSortKeys;
Types.types["AppUsagePricing"] = AppUsagePricing;
Types.types["AppUsagePricingInput"] = AppUsagePricingInput;
Types.types["AppUsageRecord"] = AppUsageRecord;
Types.types["AppUsageRecordConnection"] = AppUsageRecordConnection;
Types.types["AppUsageRecordCreatePayload"] = AppUsageRecordCreatePayload;
Types.types["AppUsageRecordEdge"] = AppUsageRecordEdge;
Types.types["AppUsageRecordSortKeys"] = AppUsageRecordSortKeys;
Types.types["Attribute"] = Attribute;
Types.types["AttributeInput"] = AttributeInput;
Types.types["AutomaticDiscountApplication"] = AutomaticDiscountApplication;
Types.types["AutomaticDiscountSortKeys"] = AutomaticDiscountSortKeys;
Types.types["AvailableChannelDefinitionsByChannel"] = AvailableChannelDefinitionsByChannel;
Types.types["BadgeType"] = BadgeType;
Types.types["BalanceTransactionSortKeys"] = BalanceTransactionSortKeys;
Types.types["BasePaymentDetails"] = BasePaymentDetails;
Types.types["BasicEvent"] = BasicEvent;
Types.types["BillingAttemptUserError"] = BillingAttemptUserError;
Types.types["BillingAttemptUserErrorCode"] = BillingAttemptUserErrorCode;
Types.types["Boolean"] = Boolean;
Types.types["BulkMutationErrorCode"] = BulkMutationErrorCode;
Types.types["BulkMutationUserError"] = BulkMutationUserError;
Types.types["BulkOperation"] = BulkOperation;
Types.types["BulkOperationCancelPayload"] = BulkOperationCancelPayload;
Types.types["BulkOperationErrorCode"] = BulkOperationErrorCode;
Types.types["BulkOperationRunMutationPayload"] = BulkOperationRunMutationPayload;
Types.types["BulkOperationRunQueryPayload"] = BulkOperationRunQueryPayload;
Types.types["BulkOperationStatus"] = BulkOperationStatus;
Types.types["BulkOperationType"] = BulkOperationType;
Types.types["BulkProductResourceFeedbackCreatePayload"] = BulkProductResourceFeedbackCreatePayload;
Types.types["BulkProductResourceFeedbackCreateUserError"] = BulkProductResourceFeedbackCreateUserError;
Types.types["BulkProductResourceFeedbackCreateUserErrorCode"] = BulkProductResourceFeedbackCreateUserErrorCode;
Types.types["BundlesFeature"] = BundlesFeature;
Types.types["BusinessCustomerErrorCode"] = BusinessCustomerErrorCode;
Types.types["BusinessCustomerUserError"] = BusinessCustomerUserError;
Types.types["BuyerExperienceConfiguration"] = BuyerExperienceConfiguration;
Types.types["BuyerExperienceConfigurationInput"] = BuyerExperienceConfigurationInput;
Types.types["CalculatedAutomaticDiscountApplication"] = CalculatedAutomaticDiscountApplication;
Types.types["CalculatedDiscountAllocation"] = CalculatedDiscountAllocation;
Types.types["CalculatedDiscountApplication"] = CalculatedDiscountApplication;
Types.types["CalculatedDiscountApplicationConnection"] = CalculatedDiscountApplicationConnection;
Types.types["CalculatedDiscountApplicationEdge"] = CalculatedDiscountApplicationEdge;
Types.types["CalculatedDiscountCodeApplication"] = CalculatedDiscountCodeApplication;
Types.types["CalculatedDraftOrder"] = CalculatedDraftOrder;
Types.types["CalculatedDraftOrderLineItem"] = CalculatedDraftOrderLineItem;
Types.types["CalculatedLineItem"] = CalculatedLineItem;
Types.types["CalculatedLineItemConnection"] = CalculatedLineItemConnection;
Types.types["CalculatedLineItemEdge"] = CalculatedLineItemEdge;
Types.types["CalculatedManualDiscountApplication"] = CalculatedManualDiscountApplication;
Types.types["CalculatedOrder"] = CalculatedOrder;
Types.types["CalculatedScriptDiscountApplication"] = CalculatedScriptDiscountApplication;
Types.types["CalculatedShippingLine"] = CalculatedShippingLine;
Types.types["CalculatedShippingLineStagedStatus"] = CalculatedShippingLineStagedStatus;
Types.types["CardPaymentDetails"] = CardPaymentDetails;
Types.types["CartTransform"] = CartTransform;
Types.types["CartTransformConnection"] = CartTransformConnection;
Types.types["CartTransformCreatePayload"] = CartTransformCreatePayload;
Types.types["CartTransformCreateUserError"] = CartTransformCreateUserError;
Types.types["CartTransformCreateUserErrorCode"] = CartTransformCreateUserErrorCode;
Types.types["CartTransformDeletePayload"] = CartTransformDeletePayload;
Types.types["CartTransformDeleteUserError"] = CartTransformDeleteUserError;
Types.types["CartTransformDeleteUserErrorCode"] = CartTransformDeleteUserErrorCode;
Types.types["CartTransformEdge"] = CartTransformEdge;
Types.types["CartTransformEligibleOperations"] = CartTransformEligibleOperations;
Types.types["CartTransformFeature"] = CartTransformFeature;
Types.types["CashTrackingAdjustment"] = CashTrackingAdjustment;
Types.types["CashTrackingAdjustmentConnection"] = CashTrackingAdjustmentConnection;
Types.types["CashTrackingAdjustmentEdge"] = CashTrackingAdjustmentEdge;
Types.types["CashTrackingSession"] = CashTrackingSession;
Types.types["CashTrackingSessionConnection"] = CashTrackingSessionConnection;
Types.types["CashTrackingSessionEdge"] = CashTrackingSessionEdge;
Types.types["CashTrackingSessionsSortKeys"] = CashTrackingSessionsSortKeys;
Types.types["Catalog"] = Catalog;
Types.types["CatalogConnection"] = CatalogConnection;
Types.types["CatalogContextInput"] = CatalogContextInput;
Types.types["CatalogContextUpdatePayload"] = CatalogContextUpdatePayload;
Types.types["CatalogCreateInput"] = CatalogCreateInput;
Types.types["CatalogCreatePayload"] = CatalogCreatePayload;
Types.types["CatalogCsvOperation"] = CatalogCsvOperation;
Types.types["CatalogDeletePayload"] = CatalogDeletePayload;
Types.types["CatalogEdge"] = CatalogEdge;
Types.types["CatalogSortKeys"] = CatalogSortKeys;
Types.types["CatalogStatus"] = CatalogStatus;
Types.types["CatalogType"] = CatalogType;
Types.types["CatalogUpdateInput"] = CatalogUpdateInput;
Types.types["CatalogUpdatePayload"] = CatalogUpdatePayload;
Types.types["CatalogUserError"] = CatalogUserError;
Types.types["CatalogUserErrorCode"] = CatalogUserErrorCode;
Types.types["Channel"] = Channel;
Types.types["ChannelConnection"] = ChannelConnection;
Types.types["ChannelDefinition"] = ChannelDefinition;
Types.types["ChannelEdge"] = ChannelEdge;
Types.types["ChannelInformation"] = ChannelInformation;
Types.types["CheckoutBranding"] = CheckoutBranding;
Types.types["CheckoutBrandingBackground"] = CheckoutBrandingBackground;
Types.types["CheckoutBrandingBackgroundStyle"] = CheckoutBrandingBackgroundStyle;
Types.types["CheckoutBrandingBorder"] = CheckoutBrandingBorder;
Types.types["CheckoutBrandingBorderStyle"] = CheckoutBrandingBorderStyle;
Types.types["CheckoutBrandingBorderWidth"] = CheckoutBrandingBorderWidth;
Types.types["CheckoutBrandingButton"] = CheckoutBrandingButton;
Types.types["CheckoutBrandingButtonColorRoles"] = CheckoutBrandingButtonColorRoles;
Types.types["CheckoutBrandingButtonColorRolesInput"] = CheckoutBrandingButtonColorRolesInput;
Types.types["CheckoutBrandingButtonInput"] = CheckoutBrandingButtonInput;
Types.types["CheckoutBrandingBuyerJourney"] = CheckoutBrandingBuyerJourney;
Types.types["CheckoutBrandingBuyerJourneyInput"] = CheckoutBrandingBuyerJourneyInput;
Types.types["CheckoutBrandingCartLink"] = CheckoutBrandingCartLink;
Types.types["CheckoutBrandingCartLinkContentType"] = CheckoutBrandingCartLinkContentType;
Types.types["CheckoutBrandingCartLinkInput"] = CheckoutBrandingCartLinkInput;
Types.types["CheckoutBrandingCheckbox"] = CheckoutBrandingCheckbox;
Types.types["CheckoutBrandingCheckboxInput"] = CheckoutBrandingCheckboxInput;
Types.types["CheckoutBrandingChoiceList"] = CheckoutBrandingChoiceList;
Types.types["CheckoutBrandingChoiceListGroup"] = CheckoutBrandingChoiceListGroup;
Types.types["CheckoutBrandingChoiceListGroupInput"] = CheckoutBrandingChoiceListGroupInput;
Types.types["CheckoutBrandingChoiceListInput"] = CheckoutBrandingChoiceListInput;
Types.types["CheckoutBrandingColorGlobal"] = CheckoutBrandingColorGlobal;
Types.types["CheckoutBrandingColorGlobalInput"] = CheckoutBrandingColorGlobalInput;
Types.types["CheckoutBrandingColorRoles"] = CheckoutBrandingColorRoles;
Types.types["CheckoutBrandingColorRolesInput"] = CheckoutBrandingColorRolesInput;
Types.types["CheckoutBrandingColorScheme"] = CheckoutBrandingColorScheme;
Types.types["CheckoutBrandingColorSchemeInput"] = CheckoutBrandingColorSchemeInput;
Types.types["CheckoutBrandingColorSchemeSelection"] = CheckoutBrandingColorSchemeSelection;
Types.types["CheckoutBrandingColorSchemes"] = CheckoutBrandingColorSchemes;
Types.types["CheckoutBrandingColorSchemesInput"] = CheckoutBrandingColorSchemesInput;
Types.types["CheckoutBrandingColorSelection"] = CheckoutBrandingColorSelection;
Types.types["CheckoutBrandingColors"] = CheckoutBrandingColors;
Types.types["CheckoutBrandingColorsInput"] = CheckoutBrandingColorsInput;
Types.types["CheckoutBrandingControl"] = CheckoutBrandingControl;
Types.types["CheckoutBrandingControlColorRoles"] = CheckoutBrandingControlColorRoles;
Types.types["CheckoutBrandingControlColorRolesInput"] = CheckoutBrandingControlColorRolesInput;
Types.types["CheckoutBrandingControlInput"] = CheckoutBrandingControlInput;
Types.types["CheckoutBrandingCornerRadius"] = CheckoutBrandingCornerRadius;
Types.types["CheckoutBrandingCornerRadiusVariables"] = CheckoutBrandingCornerRadiusVariables;
Types.types["CheckoutBrandingCornerRadiusVariablesInput"] = CheckoutBrandingCornerRadiusVariablesInput;
Types.types["CheckoutBrandingCustomFont"] = CheckoutBrandingCustomFont;
Types.types["CheckoutBrandingCustomFontGroupInput"] = CheckoutBrandingCustomFontGroupInput;
Types.types["CheckoutBrandingCustomFontInput"] = CheckoutBrandingCustomFontInput;
Types.types["CheckoutBrandingCustomizations"] = CheckoutBrandingCustomizations;
Types.types["CheckoutBrandingCustomizationsInput"] = CheckoutBrandingCustomizationsInput;
Types.types["CheckoutBrandingDesignSystem"] = CheckoutBrandingDesignSystem;
Types.types["CheckoutBrandingDesignSystemInput"] = CheckoutBrandingDesignSystemInput;
Types.types["CheckoutBrandingExpressCheckout"] = CheckoutBrandingExpressCheckout;
Types.types["CheckoutBrandingExpressCheckoutButton"] = CheckoutBrandingExpressCheckoutButton;
Types.types["CheckoutBrandingExpressCheckoutButtonInput"] = CheckoutBrandingExpressCheckoutButtonInput;
Types.types["CheckoutBrandingExpressCheckoutInput"] = CheckoutBrandingExpressCheckoutInput;
Types.types["CheckoutBrandingFont"] = CheckoutBrandingFont;
Types.types["CheckoutBrandingFontGroup"] = CheckoutBrandingFontGroup;
Types.types["CheckoutBrandingFontGroupInput"] = CheckoutBrandingFontGroupInput;
Types.types["CheckoutBrandingFontLoadingStrategy"] = CheckoutBrandingFontLoadingStrategy;
Types.types["CheckoutBrandingFontSize"] = CheckoutBrandingFontSize;
Types.types["CheckoutBrandingFontSizeInput"] = CheckoutBrandingFontSizeInput;
Types.types["CheckoutBrandingFooter"] = CheckoutBrandingFooter;
Types.types["CheckoutBrandingFooterAlignment"] = CheckoutBrandingFooterAlignment;
Types.types["CheckoutBrandingFooterContent"] = CheckoutBrandingFooterContent;
Types.types["CheckoutBrandingFooterContentInput"] = CheckoutBrandingFooterContentInput;
Types.types["CheckoutBrandingFooterInput"] = CheckoutBrandingFooterInput;
Types.types["CheckoutBrandingFooterPosition"] = CheckoutBrandingFooterPosition;
Types.types["CheckoutBrandingGlobal"] = CheckoutBrandingGlobal;
Types.types["CheckoutBrandingGlobalCornerRadius"] = CheckoutBrandingGlobalCornerRadius;
Types.types["CheckoutBrandingGlobalInput"] = CheckoutBrandingGlobalInput;
Types.types["CheckoutBrandingHeader"] = CheckoutBrandingHeader;
Types.types["CheckoutBrandingHeaderAlignment"] = CheckoutBrandingHeaderAlignment;
Types.types["CheckoutBrandingHeaderCartLink"] = CheckoutBrandingHeaderCartLink;
Types.types["CheckoutBrandingHeaderCartLinkInput"] = CheckoutBrandingHeaderCartLinkInput;
Types.types["CheckoutBrandingHeaderInput"] = CheckoutBrandingHeaderInput;
Types.types["CheckoutBrandingHeaderPosition"] = CheckoutBrandingHeaderPosition;
Types.types["CheckoutBrandingHeadingLevel"] = CheckoutBrandingHeadingLevel;
Types.types["CheckoutBrandingHeadingLevelInput"] = CheckoutBrandingHeadingLevelInput;
Types.types["CheckoutBrandingImage"] = CheckoutBrandingImage;
Types.types["CheckoutBrandingImageInput"] = CheckoutBrandingImageInput;
Types.types["CheckoutBrandingInput"] = CheckoutBrandingInput;
Types.types["CheckoutBrandingLabelPosition"] = CheckoutBrandingLabelPosition;
Types.types["CheckoutBrandingLogo"] = CheckoutBrandingLogo;
Types.types["CheckoutBrandingLogoInput"] = CheckoutBrandingLogoInput;
Types.types["CheckoutBrandingMain"] = CheckoutBrandingMain;
Types.types["CheckoutBrandingMainInput"] = CheckoutBrandingMainInput;
Types.types["CheckoutBrandingMainSection"] = CheckoutBrandingMainSection;
Types.types["CheckoutBrandingMainSectionInput"] = CheckoutBrandingMainSectionInput;
Types.types["CheckoutBrandingMerchandiseThumbnail"] = CheckoutBrandingMerchandiseThumbnail;
Types.types["CheckoutBrandingMerchandiseThumbnailInput"] = CheckoutBrandingMerchandiseThumbnailInput;
Types.types["CheckoutBrandingOrderSummary"] = CheckoutBrandingOrderSummary;
Types.types["CheckoutBrandingOrderSummaryInput"] = CheckoutBrandingOrderSummaryInput;
Types.types["CheckoutBrandingOrderSummarySection"] = CheckoutBrandingOrderSummarySection;
Types.types["CheckoutBrandingOrderSummarySectionInput"] = CheckoutBrandingOrderSummarySectionInput;
Types.types["CheckoutBrandingSelect"] = CheckoutBrandingSelect;
Types.types["CheckoutBrandingSelectInput"] = CheckoutBrandingSelectInput;
Types.types["CheckoutBrandingShadow"] = CheckoutBrandingShadow;
Types.types["CheckoutBrandingShopifyFont"] = CheckoutBrandingShopifyFont;
Types.types["CheckoutBrandingShopifyFontGroupInput"] = CheckoutBrandingShopifyFontGroupInput;
Types.types["CheckoutBrandingSimpleBorder"] = CheckoutBrandingSimpleBorder;
Types.types["CheckoutBrandingSpacing"] = CheckoutBrandingSpacing;
Types.types["CheckoutBrandingSpacingKeyword"] = CheckoutBrandingSpacingKeyword;
Types.types["CheckoutBrandingTextField"] = CheckoutBrandingTextField;
Types.types["CheckoutBrandingTextFieldInput"] = CheckoutBrandingTextFieldInput;
Types.types["CheckoutBrandingTypography"] = CheckoutBrandingTypography;
Types.types["CheckoutBrandingTypographyFont"] = CheckoutBrandingTypographyFont;
Types.types["CheckoutBrandingTypographyInput"] = CheckoutBrandingTypographyInput;
Types.types["CheckoutBrandingTypographyKerning"] = CheckoutBrandingTypographyKerning;
Types.types["CheckoutBrandingTypographyLetterCase"] = CheckoutBrandingTypographyLetterCase;
Types.types["CheckoutBrandingTypographySize"] = CheckoutBrandingTypographySize;
Types.types["CheckoutBrandingTypographyStyle"] = CheckoutBrandingTypographyStyle;
Types.types["CheckoutBrandingTypographyStyleGlobal"] = CheckoutBrandingTypographyStyleGlobal;
Types.types["CheckoutBrandingTypographyStyleGlobalInput"] = CheckoutBrandingTypographyStyleGlobalInput;
Types.types["CheckoutBrandingTypographyStyleInput"] = CheckoutBrandingTypographyStyleInput;
Types.types["CheckoutBrandingTypographyWeight"] = CheckoutBrandingTypographyWeight;
Types.types["CheckoutBrandingUpsertPayload"] = CheckoutBrandingUpsertPayload;
Types.types["CheckoutBrandingUpsertUserError"] = CheckoutBrandingUpsertUserError;
Types.types["CheckoutBrandingUpsertUserErrorCode"] = CheckoutBrandingUpsertUserErrorCode;
Types.types["CheckoutBrandingVisibility"] = CheckoutBrandingVisibility;
Types.types["CheckoutProfile"] = CheckoutProfile;
Types.types["CheckoutProfileConnection"] = CheckoutProfileConnection;
Types.types["CheckoutProfileEdge"] = CheckoutProfileEdge;
Types.types["CheckoutProfileSortKeys"] = CheckoutProfileSortKeys;
Types.types["CodeDiscountSortKeys"] = CodeDiscountSortKeys;
Types.types["Collection"] = Collection;
Types.types["CollectionAddProductsPayload"] = CollectionAddProductsPayload;
Types.types["CollectionAddProductsV2Payload"] = CollectionAddProductsV2Payload;
Types.types["CollectionAddProductsV2UserError"] = CollectionAddProductsV2UserError;
Types.types["CollectionAddProductsV2UserErrorCode"] = CollectionAddProductsV2UserErrorCode;
Types.types["CollectionConnection"] = CollectionConnection;
Types.types["CollectionCreatePayload"] = CollectionCreatePayload;
Types.types["CollectionDeleteInput"] = CollectionDeleteInput;
Types.types["CollectionDeletePayload"] = CollectionDeletePayload;
Types.types["CollectionEdge"] = CollectionEdge;
Types.types["CollectionInput"] = CollectionInput;
Types.types["CollectionPublication"] = CollectionPublication;
Types.types["CollectionPublicationConnection"] = CollectionPublicationConnection;
Types.types["CollectionPublicationEdge"] = CollectionPublicationEdge;
Types.types["CollectionPublicationInput"] = CollectionPublicationInput;
Types.types["CollectionPublishInput"] = CollectionPublishInput;
Types.types["CollectionPublishPayload"] = CollectionPublishPayload;
Types.types["CollectionRemoveProductsPayload"] = CollectionRemoveProductsPayload;
Types.types["CollectionReorderProductsPayload"] = CollectionReorderProductsPayload;
Types.types["CollectionRule"] = CollectionRule;
Types.types["CollectionRuleColumn"] = CollectionRuleColumn;
Types.types["CollectionRuleConditionObject"] = CollectionRuleConditionObject;
Types.types["CollectionRuleConditions"] = CollectionRuleConditions;
Types.types["CollectionRuleConditionsRuleObject"] = CollectionRuleConditionsRuleObject;
Types.types["CollectionRuleInput"] = CollectionRuleInput;
Types.types["CollectionRuleMetafieldCondition"] = CollectionRuleMetafieldCondition;
Types.types["CollectionRuleProductCategoryCondition"] = CollectionRuleProductCategoryCondition;
Types.types["CollectionRuleRelation"] = CollectionRuleRelation;
Types.types["CollectionRuleSet"] = CollectionRuleSet;
Types.types["CollectionRuleSetInput"] = CollectionRuleSetInput;
Types.types["CollectionRuleTextCondition"] = CollectionRuleTextCondition;
Types.types["CollectionSortKeys"] = CollectionSortKeys;
Types.types["CollectionSortOrder"] = CollectionSortOrder;
Types.types["CollectionUnpublishInput"] = CollectionUnpublishInput;
Types.types["CollectionUnpublishPayload"] = CollectionUnpublishPayload;
Types.types["CollectionUpdatePayload"] = CollectionUpdatePayload;
Types.types["Color"] = Color;
Types.types["CommentEvent"] = CommentEvent;
Types.types["CommentEventAttachment"] = CommentEventAttachment;
Types.types["CommentEventEmbed"] = CommentEventEmbed;
Types.types["CommentEventSubject"] = CommentEventSubject;
Types.types["CompaniesDeletePayload"] = CompaniesDeletePayload;
Types.types["Company"] = Company;
Types.types["CompanyAddress"] = CompanyAddress;
Types.types["CompanyAddressDeletePayload"] = CompanyAddressDeletePayload;
Types.types["CompanyAddressInput"] = CompanyAddressInput;
Types.types["CompanyAddressType"] = CompanyAddressType;
Types.types["CompanyAssignCustomerAsContactPayload"] = CompanyAssignCustomerAsContactPayload;
Types.types["CompanyAssignMainContactPayload"] = CompanyAssignMainContactPayload;
Types.types["CompanyConnection"] = CompanyConnection;
Types.types["CompanyContact"] = CompanyContact;
Types.types["CompanyContactAssignRolePayload"] = CompanyContactAssignRolePayload;
Types.types["CompanyContactAssignRolesPayload"] = CompanyContactAssignRolesPayload;
Types.types["CompanyContactConnection"] = CompanyContactConnection;
Types.types["CompanyContactCreatePayload"] = CompanyContactCreatePayload;
Types.types["CompanyContactDeletePayload"] = CompanyContactDeletePayload;
Types.types["CompanyContactEdge"] = CompanyContactEdge;
Types.types["CompanyContactInput"] = CompanyContactInput;
Types.types["CompanyContactRemoveFromCompanyPayload"] = CompanyContactRemoveFromCompanyPayload;
Types.types["CompanyContactRevokeRolePayload"] = CompanyContactRevokeRolePayload;
Types.types["CompanyContactRevokeRolesPayload"] = CompanyContactRevokeRolesPayload;
Types.types["CompanyContactRole"] = CompanyContactRole;
Types.types["CompanyContactRoleAssign"] = CompanyContactRoleAssign;
Types.types["CompanyContactRoleAssignment"] = CompanyContactRoleAssignment;
Types.types["CompanyContactRoleAssignmentConnection"] = CompanyContactRoleAssignmentConnection;
Types.types["CompanyContactRoleAssignmentEdge"] = CompanyContactRoleAssignmentEdge;
Types.types["CompanyContactRoleAssignmentSortKeys"] = CompanyContactRoleAssignmentSortKeys;
Types.types["CompanyContactRoleConnection"] = CompanyContactRoleConnection;
Types.types["CompanyContactRoleEdge"] = CompanyContactRoleEdge;
Types.types["CompanyContactRoleSortKeys"] = CompanyContactRoleSortKeys;
Types.types["CompanyContactSendWelcomeEmailPayload"] = CompanyContactSendWelcomeEmailPayload;
Types.types["CompanyContactSortKeys"] = CompanyContactSortKeys;
Types.types["CompanyContactUpdatePayload"] = CompanyContactUpdatePayload;
Types.types["CompanyContactsDeletePayload"] = CompanyContactsDeletePayload;
Types.types["CompanyCreateInput"] = CompanyCreateInput;
Types.types["CompanyCreatePayload"] = CompanyCreatePayload;
Types.types["CompanyDeletePayload"] = CompanyDeletePayload;
Types.types["CompanyEdge"] = CompanyEdge;
Types.types["CompanyInput"] = CompanyInput;
Types.types["CompanyLocation"] = CompanyLocation;
Types.types["CompanyLocationAssignAddressPayload"] = CompanyLocationAssignAddressPayload;
Types.types["CompanyLocationAssignRolesPayload"] = CompanyLocationAssignRolesPayload;
Types.types["CompanyLocationAssignTaxExemptionsPayload"] = CompanyLocationAssignTaxExemptionsPayload;
Types.types["CompanyLocationCatalog"] = CompanyLocationCatalog;
Types.types["CompanyLocationConnection"] = CompanyLocationConnection;
Types.types["CompanyLocationCreatePayload"] = CompanyLocationCreatePayload;
Types.types["CompanyLocationCreateTaxRegistrationPayload"] = CompanyLocationCreateTaxRegistrationPayload;
Types.types["CompanyLocationDeletePayload"] = CompanyLocationDeletePayload;
Types.types["CompanyLocationEdge"] = CompanyLocationEdge;
Types.types["CompanyLocationInput"] = CompanyLocationInput;
Types.types["CompanyLocationRevokeRolesPayload"] = CompanyLocationRevokeRolesPayload;
Types.types["CompanyLocationRevokeTaxExemptionsPayload"] = CompanyLocationRevokeTaxExemptionsPayload;
Types.types["CompanyLocationRevokeTaxRegistrationPayload"] = CompanyLocationRevokeTaxRegistrationPayload;
Types.types["CompanyLocationRoleAssign"] = CompanyLocationRoleAssign;
Types.types["CompanyLocationSortKeys"] = CompanyLocationSortKeys;
Types.types["CompanyLocationUpdateInput"] = CompanyLocationUpdateInput;
Types.types["CompanyLocationUpdatePayload"] = CompanyLocationUpdatePayload;
Types.types["CompanyLocationsDeletePayload"] = CompanyLocationsDeletePayload;
Types.types["CompanyRevokeMainContactPayload"] = CompanyRevokeMainContactPayload;
Types.types["CompanySortKeys"] = CompanySortKeys;
Types.types["CompanyUpdatePayload"] = CompanyUpdatePayload;
Types.types["ContextualPricingContext"] = ContextualPricingContext;
Types.types["ContextualPublicationContext"] = ContextualPublicationContext;
Types.types["Count"] = Count;
Types.types["CountPrecision"] = CountPrecision;
Types.types["CountriesInShippingZones"] = CountriesInShippingZones;
Types.types["CountryCode"] = CountryCode;
Types.types["CountryHarmonizedSystemCode"] = CountryHarmonizedSystemCode;
Types.types["CountryHarmonizedSystemCodeConnection"] = CountryHarmonizedSystemCodeConnection;
Types.types["CountryHarmonizedSystemCodeEdge"] = CountryHarmonizedSystemCodeEdge;
Types.types["CountryHarmonizedSystemCodeInput"] = CountryHarmonizedSystemCodeInput;
Types.types["CreateMediaInput"] = CreateMediaInput;
Types.types["CropRegion"] = CropRegion;
Types.types["CurrencyCode"] = CurrencyCode;
Types.types["CurrencyFormats"] = CurrencyFormats;
Types.types["CurrencySetting"] = CurrencySetting;
Types.types["CurrencySettingConnection"] = CurrencySettingConnection;
Types.types["CurrencySettingEdge"] = CurrencySettingEdge;
Types.types["CustomShippingPackageInput"] = CustomShippingPackageInput;
Types.types["Customer"] = Customer;
Types.types["CustomerAccountsV2"] = CustomerAccountsV2;
Types.types["CustomerAccountsVersion"] = CustomerAccountsVersion;
Types.types["CustomerAddTaxExemptionsPayload"] = CustomerAddTaxExemptionsPayload;
Types.types["CustomerCancelDataErasureErrorCode"] = CustomerCancelDataErasureErrorCode;
Types.types["CustomerCancelDataErasurePayload"] = CustomerCancelDataErasurePayload;
Types.types["CustomerCancelDataErasureUserError"] = CustomerCancelDataErasureUserError;
Types.types["CustomerConnection"] = CustomerConnection;
Types.types["CustomerConsentCollectedFrom"] = CustomerConsentCollectedFrom;
Types.types["CustomerCreatePayload"] = CustomerCreatePayload;
Types.types["CustomerCreditCard"] = CustomerCreditCard;
Types.types["CustomerCreditCardBillingAddress"] = CustomerCreditCardBillingAddress;
Types.types["CustomerDeleteInput"] = CustomerDeleteInput;
Types.types["CustomerDeletePayload"] = CustomerDeletePayload;
Types.types["CustomerEdge"] = CustomerEdge;
Types.types["CustomerEmailAddress"] = CustomerEmailAddress;
Types.types["CustomerEmailAddressMarketingState"] = CustomerEmailAddressMarketingState;
Types.types["CustomerEmailAddressOpenTrackingLevel"] = CustomerEmailAddressOpenTrackingLevel;
Types.types["CustomerEmailMarketingConsentInput"] = CustomerEmailMarketingConsentInput;
Types.types["CustomerEmailMarketingConsentState"] = CustomerEmailMarketingConsentState;
Types.types["CustomerEmailMarketingConsentUpdateInput"] = CustomerEmailMarketingConsentUpdateInput;
Types.types["CustomerEmailMarketingConsentUpdatePayload"] = CustomerEmailMarketingConsentUpdatePayload;
Types.types["CustomerEmailMarketingConsentUpdateUserError"] = CustomerEmailMarketingConsentUpdateUserError;
Types.types["CustomerEmailMarketingConsentUpdateUserErrorCode"] = CustomerEmailMarketingConsentUpdateUserErrorCode;
Types.types["CustomerEmailMarketingState"] = CustomerEmailMarketingState;
Types.types["CustomerGenerateAccountActivationUrlPayload"] = CustomerGenerateAccountActivationUrlPayload;
Types.types["CustomerInput"] = CustomerInput;
Types.types["CustomerJourney"] = CustomerJourney;
Types.types["CustomerJourneySummary"] = CustomerJourneySummary;
Types.types["CustomerMarketingOptInLevel"] = CustomerMarketingOptInLevel;
Types.types["CustomerMergeError"] = CustomerMergeError;
Types.types["CustomerMergeErrorCode"] = CustomerMergeErrorCode;
Types.types["CustomerMergeErrorFieldType"] = CustomerMergeErrorFieldType;
Types.types["CustomerMergeOverrideFields"] = CustomerMergeOverrideFields;
Types.types["CustomerMergePayload"] = CustomerMergePayload;
Types.types["CustomerMergePreview"] = CustomerMergePreview;
Types.types["CustomerMergePreviewAlternateFields"] = CustomerMergePreviewAlternateFields;
Types.types["CustomerMergePreviewBlockingFields"] = CustomerMergePreviewBlockingFields;
Types.types["CustomerMergePreviewDefaultFields"] = CustomerMergePreviewDefaultFields;
Types.types["CustomerMergeRequest"] = CustomerMergeRequest;
Types.types["CustomerMergeRequestStatus"] = CustomerMergeRequestStatus;
Types.types["CustomerMergeUserError"] = CustomerMergeUserError;
Types.types["CustomerMergeable"] = CustomerMergeable;
Types.types["CustomerMoment"] = CustomerMoment;
Types.types["CustomerMomentConnection"] = CustomerMomentConnection;
Types.types["CustomerMomentEdge"] = CustomerMomentEdge;
Types.types["CustomerPaymentInstrument"] = CustomerPaymentInstrument;
Types.types["CustomerPaymentInstrumentBillingAddress"] = CustomerPaymentInstrumentBillingAddress;
Types.types["CustomerPaymentMethod"] = CustomerPaymentMethod;
Types.types["CustomerPaymentMethodConnection"] = CustomerPaymentMethodConnection;
Types.types["CustomerPaymentMethodCreateFromDuplicationDataPayload"] = CustomerPaymentMethodCreateFromDuplicationDataPayload;
Types.types["CustomerPaymentMethodCreateFromDuplicationDataUserError"] = CustomerPaymentMethodCreateFromDuplicationDataUserError;
Types.types["CustomerPaymentMethodCreateFromDuplicationDataUserErrorCode"] = CustomerPaymentMethodCreateFromDuplicationDataUserErrorCode;
Types.types["CustomerPaymentMethodCreditCardCreatePayload"] = CustomerPaymentMethodCreditCardCreatePayload;
Types.types["CustomerPaymentMethodCreditCardUpdatePayload"] = CustomerPaymentMethodCreditCardUpdatePayload;
Types.types["CustomerPaymentMethodEdge"] = CustomerPaymentMethodEdge;
Types.types["CustomerPaymentMethodGetDuplicationDataPayload"] = CustomerPaymentMethodGetDuplicationDataPayload;
Types.types["CustomerPaymentMethodGetDuplicationDataUserError"] = CustomerPaymentMethodGetDuplicationDataUserError;
Types.types["CustomerPaymentMethodGetDuplicationDataUserErrorCode"] = CustomerPaymentMethodGetDuplicationDataUserErrorCode;
Types.types["CustomerPaymentMethodGetUpdateUrlPayload"] = CustomerPaymentMethodGetUpdateUrlPayload;
Types.types["CustomerPaymentMethodGetUpdateUrlUserError"] = CustomerPaymentMethodGetUpdateUrlUserError;
Types.types["CustomerPaymentMethodGetUpdateUrlUserErrorCode"] = CustomerPaymentMethodGetUpdateUrlUserErrorCode;
Types.types["CustomerPaymentMethodPaypalBillingAgreementCreatePayload"] = CustomerPaymentMethodPaypalBillingAgreementCreatePayload;
Types.types["CustomerPaymentMethodPaypalBillingAgreementUpdatePayload"] = CustomerPaymentMethodPaypalBillingAgreementUpdatePayload;
Types.types["CustomerPaymentMethodRemoteCreatePayload"] = CustomerPaymentMethodRemoteCreatePayload;
Types.types["CustomerPaymentMethodRemoteCreditCardCreatePayload"] = CustomerPaymentMethodRemoteCreditCardCreatePayload;
Types.types["CustomerPaymentMethodRemoteInput"] = CustomerPaymentMethodRemoteInput;
Types.types["CustomerPaymentMethodRemoteUserError"] = CustomerPaymentMethodRemoteUserError;
Types.types["CustomerPaymentMethodRemoteUserErrorCode"] = CustomerPaymentMethodRemoteUserErrorCode;
Types.types["CustomerPaymentMethodRevocationReason"] = CustomerPaymentMethodRevocationReason;
Types.types["CustomerPaymentMethodRevokePayload"] = CustomerPaymentMethodRevokePayload;
Types.types["CustomerPaymentMethodSendUpdateEmailPayload"] = CustomerPaymentMethodSendUpdateEmailPayload;
Types.types["CustomerPaymentMethodUserError"] = CustomerPaymentMethodUserError;
Types.types["CustomerPaymentMethodUserErrorCode"] = CustomerPaymentMethodUserErrorCode;
Types.types["CustomerPaypalBillingAgreement"] = CustomerPaypalBillingAgreement;
Types.types["CustomerPhoneNumber"] = CustomerPhoneNumber;
Types.types["CustomerPredictedSpendTier"] = CustomerPredictedSpendTier;
Types.types["CustomerProductSubscriberStatus"] = CustomerProductSubscriberStatus;
Types.types["CustomerRemoveTaxExemptionsPayload"] = CustomerRemoveTaxExemptionsPayload;
Types.types["CustomerReplaceTaxExemptionsPayload"] = CustomerReplaceTaxExemptionsPayload;
Types.types["CustomerRequestDataErasureErrorCode"] = CustomerRequestDataErasureErrorCode;
Types.types["CustomerRequestDataErasurePayload"] = CustomerRequestDataErasurePayload;
Types.types["CustomerRequestDataErasureUserError"] = CustomerRequestDataErasureUserError;
Types.types["CustomerSavedSearchSortKeys"] = CustomerSavedSearchSortKeys;
Types.types["CustomerSegmentMember"] = CustomerSegmentMember;
Types.types["CustomerSegmentMemberConnection"] = CustomerSegmentMemberConnection;
Types.types["CustomerSegmentMemberEdge"] = CustomerSegmentMemberEdge;
Types.types["CustomerSegmentMembersQuery"] = CustomerSegmentMembersQuery;
Types.types["CustomerSegmentMembersQueryCreatePayload"] = CustomerSegmentMembersQueryCreatePayload;
Types.types["CustomerSegmentMembersQueryInput"] = CustomerSegmentMembersQueryInput;
Types.types["CustomerSegmentMembersQueryUserError"] = CustomerSegmentMembersQueryUserError;
Types.types["CustomerSegmentMembersQueryUserErrorCode"] = CustomerSegmentMembersQueryUserErrorCode;
Types.types["CustomerShopPayAgreement"] = CustomerShopPayAgreement;
Types.types["CustomerSmsMarketingConsentError"] = CustomerSmsMarketingConsentError;
Types.types["CustomerSmsMarketingConsentErrorCode"] = CustomerSmsMarketingConsentErrorCode;
Types.types["CustomerSmsMarketingConsentInput"] = CustomerSmsMarketingConsentInput;
Types.types["CustomerSmsMarketingConsentState"] = CustomerSmsMarketingConsentState;
Types.types["CustomerSmsMarketingConsentUpdateInput"] = CustomerSmsMarketingConsentUpdateInput;
Types.types["CustomerSmsMarketingConsentUpdatePayload"] = CustomerSmsMarketingConsentUpdatePayload;
Types.types["CustomerSmsMarketingState"] = CustomerSmsMarketingState;
Types.types["CustomerSortKeys"] = CustomerSortKeys;
Types.types["CustomerState"] = CustomerState;
Types.types["CustomerStatistics"] = CustomerStatistics;
Types.types["CustomerUpdateDefaultAddressPayload"] = CustomerUpdateDefaultAddressPayload;
Types.types["CustomerUpdatePayload"] = CustomerUpdatePayload;
Types.types["CustomerVisit"] = CustomerVisit;
Types.types["CustomerVisitProductInfo"] = CustomerVisitProductInfo;
Types.types["CustomerVisitProductInfoConnection"] = CustomerVisitProductInfoConnection;
Types.types["CustomerVisitProductInfoEdge"] = CustomerVisitProductInfoEdge;
Types.types["Date"] = Date;
Types.types["DateTime"] = DateTime;
Types.types["DayOfTheWeek"] = DayOfTheWeek;
Types.types["Decimal"] = Decimal;
Types.types["DelegateAccessToken"] = DelegateAccessToken;
Types.types["DelegateAccessTokenCreatePayload"] = DelegateAccessTokenCreatePayload;
Types.types["DelegateAccessTokenCreateUserError"] = DelegateAccessTokenCreateUserError;
Types.types["DelegateAccessTokenCreateUserErrorCode"] = DelegateAccessTokenCreateUserErrorCode;
Types.types["DelegateAccessTokenDestroyPayload"] = DelegateAccessTokenDestroyPayload;
Types.types["DelegateAccessTokenDestroyUserError"] = DelegateAccessTokenDestroyUserError;
Types.types["DelegateAccessTokenDestroyUserErrorCode"] = DelegateAccessTokenDestroyUserErrorCode;
Types.types["DelegateAccessTokenInput"] = DelegateAccessTokenInput;
Types.types["DeletionEvent"] = DeletionEvent;
Types.types["DeletionEventConnection"] = DeletionEventConnection;
Types.types["DeletionEventEdge"] = DeletionEventEdge;
Types.types["DeletionEventSortKeys"] = DeletionEventSortKeys;
Types.types["DeletionEventSubjectType"] = DeletionEventSubjectType;
Types.types["DeliveryAvailableService"] = DeliveryAvailableService;
Types.types["DeliveryBrandedPromise"] = DeliveryBrandedPromise;
Types.types["DeliveryCarrierService"] = DeliveryCarrierService;
Types.types["DeliveryCarrierServiceAndLocations"] = DeliveryCarrierServiceAndLocations;
Types.types["DeliveryCondition"] = DeliveryCondition;
Types.types["DeliveryConditionCriteria"] = DeliveryConditionCriteria;
Types.types["DeliveryConditionField"] = DeliveryConditionField;
Types.types["DeliveryConditionOperator"] = DeliveryConditionOperator;
Types.types["DeliveryCountry"] = DeliveryCountry;
Types.types["DeliveryCountryAndZone"] = DeliveryCountryAndZone;
Types.types["DeliveryCountryCodeOrRestOfWorld"] = DeliveryCountryCodeOrRestOfWorld;
Types.types["DeliveryCountryCodesOrRestOfWorld"] = DeliveryCountryCodesOrRestOfWorld;
Types.types["DeliveryCountryInput"] = DeliveryCountryInput;
Types.types["DeliveryCustomization"] = DeliveryCustomization;
Types.types["DeliveryCustomizationActivationPayload"] = DeliveryCustomizationActivationPayload;
Types.types["DeliveryCustomizationConnection"] = DeliveryCustomizationConnection;
Types.types["DeliveryCustomizationCreatePayload"] = DeliveryCustomizationCreatePayload;
Types.types["DeliveryCustomizationDeletePayload"] = DeliveryCustomizationDeletePayload;
Types.types["DeliveryCustomizationEdge"] = DeliveryCustomizationEdge;
Types.types["DeliveryCustomizationError"] = DeliveryCustomizationError;
Types.types["DeliveryCustomizationErrorCode"] = DeliveryCustomizationErrorCode;
Types.types["DeliveryCustomizationInput"] = DeliveryCustomizationInput;
Types.types["DeliveryCustomizationUpdatePayload"] = DeliveryCustomizationUpdatePayload;
Types.types["DeliveryLegacyModeBlocked"] = DeliveryLegacyModeBlocked;
Types.types["DeliveryLegacyModeBlockedReason"] = DeliveryLegacyModeBlockedReason;
Types.types["DeliveryLocalPickupSettings"] = DeliveryLocalPickupSettings;
Types.types["DeliveryLocalPickupTime"] = DeliveryLocalPickupTime;
Types.types["DeliveryLocationGroup"] = DeliveryLocationGroup;
Types.types["DeliveryLocationGroupZone"] = DeliveryLocationGroupZone;
Types.types["DeliveryLocationGroupZoneConnection"] = DeliveryLocationGroupZoneConnection;
Types.types["DeliveryLocationGroupZoneEdge"] = DeliveryLocationGroupZoneEdge;
Types.types["DeliveryLocationGroupZoneInput"] = DeliveryLocationGroupZoneInput;
Types.types["DeliveryLocationLocalPickupEnableInput"] = DeliveryLocationLocalPickupEnableInput;
Types.types["DeliveryLocationLocalPickupSettingsError"] = DeliveryLocationLocalPickupSettingsError;
Types.types["DeliveryLocationLocalPickupSettingsErrorCode"] = DeliveryLocationLocalPickupSettingsErrorCode;
Types.types["DeliveryMethod"] = DeliveryMethod;
Types.types["DeliveryMethodAdditionalInformation"] = DeliveryMethodAdditionalInformation;
Types.types["DeliveryMethodDefinition"] = DeliveryMethodDefinition;
Types.types["DeliveryMethodDefinitionConnection"] = DeliveryMethodDefinitionConnection;
Types.types["DeliveryMethodDefinitionCounts"] = DeliveryMethodDefinitionCounts;
Types.types["DeliveryMethodDefinitionEdge"] = DeliveryMethodDefinitionEdge;
Types.types["DeliveryMethodDefinitionInput"] = DeliveryMethodDefinitionInput;
Types.types["DeliveryMethodDefinitionType"] = DeliveryMethodDefinitionType;
Types.types["DeliveryMethodType"] = DeliveryMethodType;
Types.types["DeliveryParticipant"] = DeliveryParticipant;
Types.types["DeliveryParticipantInput"] = DeliveryParticipantInput;
Types.types["DeliveryParticipantService"] = DeliveryParticipantService;
Types.types["DeliveryParticipantServiceInput"] = DeliveryParticipantServiceInput;
Types.types["DeliveryPriceConditionInput"] = DeliveryPriceConditionInput;
Types.types["DeliveryProductVariantsCount"] = DeliveryProductVariantsCount;
Types.types["DeliveryProfile"] = DeliveryProfile;
Types.types["DeliveryProfileConnection"] = DeliveryProfileConnection;
Types.types["DeliveryProfileEdge"] = DeliveryProfileEdge;
Types.types["DeliveryProfileInput"] = DeliveryProfileInput;
Types.types["DeliveryProfileItem"] = DeliveryProfileItem;
Types.types["DeliveryProfileItemConnection"] = DeliveryProfileItemConnection;
Types.types["DeliveryProfileItemEdge"] = DeliveryProfileItemEdge;
Types.types["DeliveryProfileLocationGroup"] = DeliveryProfileLocationGroup;
Types.types["DeliveryProfileLocationGroupInput"] = DeliveryProfileLocationGroupInput;
Types.types["DeliveryProvince"] = DeliveryProvince;
Types.types["DeliveryProvinceInput"] = DeliveryProvinceInput;
Types.types["DeliveryRateDefinition"] = DeliveryRateDefinition;
Types.types["DeliveryRateDefinitionInput"] = DeliveryRateDefinitionInput;
Types.types["DeliveryRateProvider"] = DeliveryRateProvider;
Types.types["DeliverySetting"] = DeliverySetting;
Types.types["DeliverySettingInput"] = DeliverySettingInput;
Types.types["DeliverySettingUpdatePayload"] = DeliverySettingUpdatePayload;
Types.types["DeliveryShippingOriginAssignPayload"] = DeliveryShippingOriginAssignPayload;
Types.types["DeliveryUpdateConditionInput"] = DeliveryUpdateConditionInput;
Types.types["DeliveryWeightConditionInput"] = DeliveryWeightConditionInput;
Types.types["DeliveryZone"] = DeliveryZone;
Types.types["DigitalWallet"] = DigitalWallet;
Types.types["Discount"] = Discount;
Types.types["DiscountAllocation"] = DiscountAllocation;
Types.types["DiscountAmount"] = DiscountAmount;
Types.types["DiscountAmountInput"] = DiscountAmountInput;
Types.types["DiscountApplication"] = DiscountApplication;
Types.types["DiscountApplicationAllocationMethod"] = DiscountApplicationAllocationMethod;
Types.types["DiscountApplicationConnection"] = DiscountApplicationConnection;
Types.types["DiscountApplicationEdge"] = DiscountApplicationEdge;
Types.types["DiscountApplicationLevel"] = DiscountApplicationLevel;
Types.types["DiscountApplicationTargetSelection"] = DiscountApplicationTargetSelection;
Types.types["DiscountApplicationTargetType"] = DiscountApplicationTargetType;
Types.types["DiscountAutomatic"] = DiscountAutomatic;
Types.types["DiscountAutomaticActivatePayload"] = DiscountAutomaticActivatePayload;
Types.types["DiscountAutomaticApp"] = DiscountAutomaticApp;
Types.types["DiscountAutomaticAppCreatePayload"] = DiscountAutomaticAppCreatePayload;
Types.types["DiscountAutomaticAppInput"] = DiscountAutomaticAppInput;
Types.types["DiscountAutomaticAppUpdatePayload"] = DiscountAutomaticAppUpdatePayload;
Types.types["DiscountAutomaticBasic"] = DiscountAutomaticBasic;
Types.types["DiscountAutomaticBasicCreatePayload"] = DiscountAutomaticBasicCreatePayload;
Types.types["DiscountAutomaticBasicInput"] = DiscountAutomaticBasicInput;
Types.types["DiscountAutomaticBasicUpdatePayload"] = DiscountAutomaticBasicUpdatePayload;
Types.types["DiscountAutomaticBulkDeletePayload"] = DiscountAutomaticBulkDeletePayload;
Types.types["DiscountAutomaticBxgy"] = DiscountAutomaticBxgy;
Types.types["DiscountAutomaticBxgyCreatePayload"] = DiscountAutomaticBxgyCreatePayload;
Types.types["DiscountAutomaticBxgyInput"] = DiscountAutomaticBxgyInput;
Types.types["DiscountAutomaticBxgyUpdatePayload"] = DiscountAutomaticBxgyUpdatePayload;
Types.types["DiscountAutomaticConnection"] = DiscountAutomaticConnection;
Types.types["DiscountAutomaticDeactivatePayload"] = DiscountAutomaticDeactivatePayload;
Types.types["DiscountAutomaticDeletePayload"] = DiscountAutomaticDeletePayload;
Types.types["DiscountAutomaticEdge"] = DiscountAutomaticEdge;
Types.types["DiscountAutomaticFreeShipping"] = DiscountAutomaticFreeShipping;
Types.types["DiscountAutomaticFreeShippingCreatePayload"] = DiscountAutomaticFreeShippingCreatePayload;
Types.types["DiscountAutomaticFreeShippingInput"] = DiscountAutomaticFreeShippingInput;
Types.types["DiscountAutomaticFreeShippingUpdatePayload"] = DiscountAutomaticFreeShippingUpdatePayload;
Types.types["DiscountAutomaticNode"] = DiscountAutomaticNode;
Types.types["DiscountAutomaticNodeConnection"] = DiscountAutomaticNodeConnection;
Types.types["DiscountAutomaticNodeEdge"] = DiscountAutomaticNodeEdge;
Types.types["DiscountClass"] = DiscountClass;
Types.types["DiscountCode"] = DiscountCode;
Types.types["DiscountCodeActivatePayload"] = DiscountCodeActivatePayload;
Types.types["DiscountCodeApp"] = DiscountCodeApp;
Types.types["DiscountCodeAppCreatePayload"] = DiscountCodeAppCreatePayload;
Types.types["DiscountCodeAppInput"] = DiscountCodeAppInput;
Types.types["DiscountCodeAppUpdatePayload"] = DiscountCodeAppUpdatePayload;
Types.types["DiscountCodeApplication"] = DiscountCodeApplication;
Types.types["DiscountCodeBasic"] = DiscountCodeBasic;
Types.types["DiscountCodeBasicCreatePayload"] = DiscountCodeBasicCreatePayload;
Types.types["DiscountCodeBasicInput"] = DiscountCodeBasicInput;
Types.types["DiscountCodeBasicUpdatePayload"] = DiscountCodeBasicUpdatePayload;
Types.types["DiscountCodeBulkActivatePayload"] = DiscountCodeBulkActivatePayload;
Types.types["DiscountCodeBulkDeactivatePayload"] = DiscountCodeBulkDeactivatePayload;
Types.types["DiscountCodeBulkDeletePayload"] = DiscountCodeBulkDeletePayload;
Types.types["DiscountCodeBxgy"] = DiscountCodeBxgy;
Types.types["DiscountCodeBxgyCreatePayload"] = DiscountCodeBxgyCreatePayload;
Types.types["DiscountCodeBxgyInput"] = DiscountCodeBxgyInput;
Types.types["DiscountCodeBxgyUpdatePayload"] = DiscountCodeBxgyUpdatePayload;
Types.types["DiscountCodeDeactivatePayload"] = DiscountCodeDeactivatePayload;
Types.types["DiscountCodeDeletePayload"] = DiscountCodeDeletePayload;
Types.types["DiscountCodeFreeShipping"] = DiscountCodeFreeShipping;
Types.types["DiscountCodeFreeShippingCreatePayload"] = DiscountCodeFreeShippingCreatePayload;
Types.types["DiscountCodeFreeShippingInput"] = DiscountCodeFreeShippingInput;
Types.types["DiscountCodeFreeShippingUpdatePayload"] = DiscountCodeFreeShippingUpdatePayload;
Types.types["DiscountCodeNode"] = DiscountCodeNode;
Types.types["DiscountCodeNodeConnection"] = DiscountCodeNodeConnection;
Types.types["DiscountCodeNodeEdge"] = DiscountCodeNodeEdge;
Types.types["DiscountCodeRedeemCodeBulkDeletePayload"] = DiscountCodeRedeemCodeBulkDeletePayload;
Types.types["DiscountCodeSortKeys"] = DiscountCodeSortKeys;
Types.types["DiscountCollections"] = DiscountCollections;
Types.types["DiscountCollectionsInput"] = DiscountCollectionsInput;
Types.types["DiscountCombinesWith"] = DiscountCombinesWith;
Types.types["DiscountCombinesWithInput"] = DiscountCombinesWithInput;
Types.types["DiscountCountries"] = DiscountCountries;
Types.types["DiscountCountriesInput"] = DiscountCountriesInput;
Types.types["DiscountCountryAll"] = DiscountCountryAll;
Types.types["DiscountCustomerAll"] = DiscountCustomerAll;
Types.types["DiscountCustomerBuys"] = DiscountCustomerBuys;
Types.types["DiscountCustomerBuysInput"] = DiscountCustomerBuysInput;
Types.types["DiscountCustomerBuysValue"] = DiscountCustomerBuysValue;
Types.types["DiscountCustomerBuysValueInput"] = DiscountCustomerBuysValueInput;
Types.types["DiscountCustomerGets"] = DiscountCustomerGets;
Types.types["DiscountCustomerGetsInput"] = DiscountCustomerGetsInput;
Types.types["DiscountCustomerGetsValue"] = DiscountCustomerGetsValue;
Types.types["DiscountCustomerGetsValueInput"] = DiscountCustomerGetsValueInput;
Types.types["DiscountCustomerSegments"] = DiscountCustomerSegments;
Types.types["DiscountCustomerSegmentsInput"] = DiscountCustomerSegmentsInput;
Types.types["DiscountCustomerSelection"] = DiscountCustomerSelection;
Types.types["DiscountCustomerSelectionInput"] = DiscountCustomerSelectionInput;
Types.types["DiscountCustomers"] = DiscountCustomers;
Types.types["DiscountCustomersInput"] = DiscountCustomersInput;
Types.types["DiscountEffect"] = DiscountEffect;
Types.types["DiscountEffectInput"] = DiscountEffectInput;
Types.types["DiscountErrorCode"] = DiscountErrorCode;
Types.types["DiscountItems"] = DiscountItems;
Types.types["DiscountItemsInput"] = DiscountItemsInput;
Types.types["DiscountMinimumQuantity"] = DiscountMinimumQuantity;
Types.types["DiscountMinimumQuantityInput"] = DiscountMinimumQuantityInput;
Types.types["DiscountMinimumRequirement"] = DiscountMinimumRequirement;
Types.types["DiscountMinimumRequirementInput"] = DiscountMinimumRequirementInput;
Types.types["DiscountMinimumSubtotal"] = DiscountMinimumSubtotal;
Types.types["DiscountMinimumSubtotalInput"] = DiscountMinimumSubtotalInput;
Types.types["DiscountNode"] = DiscountNode;
Types.types["DiscountNodeConnection"] = DiscountNodeConnection;
Types.types["DiscountNodeEdge"] = DiscountNodeEdge;
Types.types["DiscountOnQuantity"] = DiscountOnQuantity;
Types.types["DiscountOnQuantityInput"] = DiscountOnQuantityInput;
Types.types["DiscountPercentage"] = DiscountPercentage;
Types.types["DiscountProducts"] = DiscountProducts;
Types.types["DiscountProductsInput"] = DiscountProductsInput;
Types.types["DiscountPurchaseAmount"] = DiscountPurchaseAmount;
Types.types["DiscountQuantity"] = DiscountQuantity;
Types.types["DiscountRedeemCode"] = DiscountRedeemCode;
Types.types["DiscountRedeemCodeBulkAddPayload"] = DiscountRedeemCodeBulkAddPayload;
Types.types["DiscountRedeemCodeBulkCreation"] = DiscountRedeemCodeBulkCreation;
Types.types["DiscountRedeemCodeBulkCreationCode"] = DiscountRedeemCodeBulkCreationCode;
Types.types["DiscountRedeemCodeBulkCreationCodeConnection"] = DiscountRedeemCodeBulkCreationCodeConnection;
Types.types["DiscountRedeemCodeBulkCreationCodeEdge"] = DiscountRedeemCodeBulkCreationCodeEdge;
Types.types["DiscountRedeemCodeConnection"] = DiscountRedeemCodeConnection;
Types.types["DiscountRedeemCodeEdge"] = DiscountRedeemCodeEdge;
Types.types["DiscountRedeemCodeInput"] = DiscountRedeemCodeInput;
Types.types["DiscountShareableUrl"] = DiscountShareableUrl;
Types.types["DiscountShareableUrlTargetType"] = DiscountShareableUrlTargetType;
Types.types["DiscountShippingDestinationSelection"] = DiscountShippingDestinationSelection;
Types.types["DiscountShippingDestinationSelectionInput"] = DiscountShippingDestinationSelectionInput;
Types.types["DiscountSortKeys"] = DiscountSortKeys;
Types.types["DiscountStatus"] = DiscountStatus;
Types.types["DiscountTargetType"] = DiscountTargetType;
Types.types["DiscountType"] = DiscountType;
Types.types["DiscountUserError"] = DiscountUserError;
Types.types["DisplayableError"] = DisplayableError;
Types.types["DisputeEvidenceUpdatePayload"] = DisputeEvidenceUpdatePayload;
Types.types["DisputeEvidenceUpdateUserError"] = DisputeEvidenceUpdateUserError;
Types.types["DisputeEvidenceUpdateUserErrorCode"] = DisputeEvidenceUpdateUserErrorCode;
Types.types["DisputeStatus"] = DisputeStatus;
Types.types["DisputeType"] = DisputeType;
Types.types["Domain"] = Domain;
Types.types["DomainLocalization"] = DomainLocalization;
Types.types["DraftOrder"] = DraftOrder;
Types.types["DraftOrderAppliedDiscount"] = DraftOrderAppliedDiscount;
Types.types["DraftOrderAppliedDiscountInput"] = DraftOrderAppliedDiscountInput;
Types.types["DraftOrderAppliedDiscountType"] = DraftOrderAppliedDiscountType;
Types.types["DraftOrderBulkAddTagsPayload"] = DraftOrderBulkAddTagsPayload;
Types.types["DraftOrderBulkDeletePayload"] = DraftOrderBulkDeletePayload;
Types.types["DraftOrderBulkRemoveTagsPayload"] = DraftOrderBulkRemoveTagsPayload;
Types.types["DraftOrderCalculatePayload"] = DraftOrderCalculatePayload;
Types.types["DraftOrderCompletePayload"] = DraftOrderCompletePayload;
Types.types["DraftOrderConnection"] = DraftOrderConnection;
Types.types["DraftOrderCreateFromOrderPayload"] = DraftOrderCreateFromOrderPayload;
Types.types["DraftOrderCreateMerchantCheckoutPayload"] = DraftOrderCreateMerchantCheckoutPayload;
Types.types["DraftOrderCreatePayload"] = DraftOrderCreatePayload;
Types.types["DraftOrderDeleteInput"] = DraftOrderDeleteInput;
Types.types["DraftOrderDeletePayload"] = DraftOrderDeletePayload;
Types.types["DraftOrderDuplicatePayload"] = DraftOrderDuplicatePayload;
Types.types["DraftOrderEdge"] = DraftOrderEdge;
Types.types["DraftOrderInput"] = DraftOrderInput;
Types.types["DraftOrderInvoicePreviewPayload"] = DraftOrderInvoicePreviewPayload;
Types.types["DraftOrderInvoiceSendPayload"] = DraftOrderInvoiceSendPayload;
Types.types["DraftOrderLineItem"] = DraftOrderLineItem;
Types.types["DraftOrderLineItemConnection"] = DraftOrderLineItemConnection;
Types.types["DraftOrderLineItemEdge"] = DraftOrderLineItemEdge;
Types.types["DraftOrderLineItemInput"] = DraftOrderLineItemInput;
Types.types["DraftOrderSortKeys"] = DraftOrderSortKeys;
Types.types["DraftOrderStatus"] = DraftOrderStatus;
Types.types["DraftOrderTag"] = DraftOrderTag;
Types.types["DraftOrderUpdatePayload"] = DraftOrderUpdatePayload;
Types.types["Duty"] = Duty;
Types.types["DutySale"] = DutySale;
Types.types["EditableProperty"] = EditableProperty;
Types.types["EmailInput"] = EmailInput;
Types.types["ErrorPosition"] = ErrorPosition;
Types.types["ErrorsServerPixelUserError"] = ErrorsServerPixelUserError;
Types.types["ErrorsServerPixelUserErrorCode"] = ErrorsServerPixelUserErrorCode;
Types.types["ErrorsWebPixelUserError"] = ErrorsWebPixelUserError;
Types.types["ErrorsWebPixelUserErrorCode"] = ErrorsWebPixelUserErrorCode;
Types.types["Event"] = Event;
Types.types["EventBridgeServerPixelUpdatePayload"] = EventBridgeServerPixelUpdatePayload;
Types.types["EventBridgeWebhookSubscriptionCreatePayload"] = EventBridgeWebhookSubscriptionCreatePayload;
Types.types["EventBridgeWebhookSubscriptionInput"] = EventBridgeWebhookSubscriptionInput;
Types.types["EventBridgeWebhookSubscriptionUpdatePayload"] = EventBridgeWebhookSubscriptionUpdatePayload;
Types.types["EventConnection"] = EventConnection;
Types.types["EventEdge"] = EventEdge;
Types.types["EventSortKeys"] = EventSortKeys;
Types.types["ExchangeLineItem"] = ExchangeLineItem;
Types.types["ExchangeLineItemConnection"] = ExchangeLineItemConnection;
Types.types["ExchangeLineItemEdge"] = ExchangeLineItemEdge;
Types.types["ExchangeV2"] = ExchangeV2;
Types.types["ExchangeV2Additions"] = ExchangeV2Additions;
Types.types["ExchangeV2Connection"] = ExchangeV2Connection;
Types.types["ExchangeV2Edge"] = ExchangeV2Edge;
Types.types["ExchangeV2LineItem"] = ExchangeV2LineItem;
Types.types["ExchangeV2Returns"] = ExchangeV2Returns;
Types.types["ExternalVideo"] = ExternalVideo;
Types.types["FailedRequirement"] = FailedRequirement;
Types.types["Fee"] = Fee;
Types.types["FeeSale"] = FeeSale;
Types.types["File"] = File;
Types.types["FileAcknowledgeUpdateFailedPayload"] = FileAcknowledgeUpdateFailedPayload;
Types.types["FileConnection"] = FileConnection;
Types.types["FileContentType"] = FileContentType;
Types.types["FileCreateInput"] = FileCreateInput;
Types.types["FileCreateInputDuplicateResolutionMode"] = FileCreateInputDuplicateResolutionMode;
Types.types["FileCreatePayload"] = FileCreatePayload;
Types.types["FileDeletePayload"] = FileDeletePayload;
Types.types["FileEdge"] = FileEdge;
Types.types["FileError"] = FileError;
Types.types["FileErrorCode"] = FileErrorCode;
Types.types["FileSortKeys"] = FileSortKeys;
Types.types["FileStatus"] = FileStatus;
Types.types["FileUpdateInput"] = FileUpdateInput;
Types.types["FileUpdatePayload"] = FileUpdatePayload;
Types.types["FilesErrorCode"] = FilesErrorCode;
Types.types["FilesUserError"] = FilesUserError;
Types.types["FilterOption"] = FilterOption;
Types.types["FinancialSummaryDiscountAllocation"] = FinancialSummaryDiscountAllocation;
Types.types["FinancialSummaryDiscountApplication"] = FinancialSummaryDiscountApplication;
Types.types["Float"] = Float;
Types.types["FlowGenerateSignaturePayload"] = FlowGenerateSignaturePayload;
Types.types["FlowTriggerReceivePayload"] = FlowTriggerReceivePayload;
Types.types["FormattedString"] = FormattedString;
Types.types["Fulfillment"] = Fulfillment;
Types.types["FulfillmentCancelPayload"] = FulfillmentCancelPayload;
Types.types["FulfillmentConnection"] = FulfillmentConnection;
Types.types["FulfillmentConstraintRule"] = FulfillmentConstraintRule;
Types.types["FulfillmentConstraintRuleCreatePayload"] = FulfillmentConstraintRuleCreatePayload;
Types.types["FulfillmentConstraintRuleCreateUserError"] = FulfillmentConstraintRuleCreateUserError;
Types.types["FulfillmentConstraintRuleCreateUserErrorCode"] = FulfillmentConstraintRuleCreateUserErrorCode;
Types.types["FulfillmentConstraintRuleDeletePayload"] = FulfillmentConstraintRuleDeletePayload;
Types.types["FulfillmentConstraintRuleDeleteUserError"] = FulfillmentConstraintRuleDeleteUserError;
Types.types["FulfillmentConstraintRuleDeleteUserErrorCode"] = FulfillmentConstraintRuleDeleteUserErrorCode;
Types.types["FulfillmentCreateV2Payload"] = FulfillmentCreateV2Payload;
Types.types["FulfillmentDisplayStatus"] = FulfillmentDisplayStatus;
Types.types["FulfillmentEdge"] = FulfillmentEdge;
Types.types["FulfillmentEvent"] = FulfillmentEvent;
Types.types["FulfillmentEventConnection"] = FulfillmentEventConnection;
Types.types["FulfillmentEventCreatePayload"] = FulfillmentEventCreatePayload;
Types.types["FulfillmentEventEdge"] = FulfillmentEventEdge;
Types.types["FulfillmentEventInput"] = FulfillmentEventInput;
Types.types["FulfillmentEventSortKeys"] = FulfillmentEventSortKeys;
Types.types["FulfillmentEventStatus"] = FulfillmentEventStatus;
Types.types["FulfillmentHold"] = FulfillmentHold;
Types.types["FulfillmentHoldReason"] = FulfillmentHoldReason;
Types.types["FulfillmentLineItem"] = FulfillmentLineItem;
Types.types["FulfillmentLineItemConnection"] = FulfillmentLineItemConnection;
Types.types["FulfillmentLineItemEdge"] = FulfillmentLineItemEdge;
Types.types["FulfillmentOrder"] = FulfillmentOrder;
Types.types["FulfillmentOrderAcceptCancellationRequestPayload"] = FulfillmentOrderAcceptCancellationRequestPayload;
Types.types["FulfillmentOrderAcceptFulfillmentRequestPayload"] = FulfillmentOrderAcceptFulfillmentRequestPayload;
Types.types["FulfillmentOrderAction"] = FulfillmentOrderAction;
Types.types["FulfillmentOrderAssignedLocation"] = FulfillmentOrderAssignedLocation;
Types.types["FulfillmentOrderAssignmentStatus"] = FulfillmentOrderAssignmentStatus;
Types.types["FulfillmentOrderCancelPayload"] = FulfillmentOrderCancelPayload;
Types.types["FulfillmentOrderClosePayload"] = FulfillmentOrderClosePayload;
Types.types["FulfillmentOrderConnection"] = FulfillmentOrderConnection;
Types.types["FulfillmentOrderDestination"] = FulfillmentOrderDestination;
Types.types["FulfillmentOrderEdge"] = FulfillmentOrderEdge;
Types.types["FulfillmentOrderHoldInput"] = FulfillmentOrderHoldInput;
Types.types["FulfillmentOrderHoldPayload"] = FulfillmentOrderHoldPayload;
Types.types["FulfillmentOrderHoldUserError"] = FulfillmentOrderHoldUserError;
Types.types["FulfillmentOrderHoldUserErrorCode"] = FulfillmentOrderHoldUserErrorCode;
Types.types["FulfillmentOrderInternationalDuties"] = FulfillmentOrderInternationalDuties;
Types.types["FulfillmentOrderLineItem"] = FulfillmentOrderLineItem;
Types.types["FulfillmentOrderLineItemConnection"] = FulfillmentOrderLineItemConnection;
Types.types["FulfillmentOrderLineItemEdge"] = FulfillmentOrderLineItemEdge;
Types.types["FulfillmentOrderLineItemFinancialSummary"] = FulfillmentOrderLineItemFinancialSummary;
Types.types["FulfillmentOrderLineItemInput"] = FulfillmentOrderLineItemInput;
Types.types["FulfillmentOrderLineItemWarning"] = FulfillmentOrderLineItemWarning;
Types.types["FulfillmentOrderLineItemsInput"] = FulfillmentOrderLineItemsInput;
Types.types["FulfillmentOrderLineItemsPreparedForPickupInput"] = FulfillmentOrderLineItemsPreparedForPickupInput;
Types.types["FulfillmentOrderLineItemsPreparedForPickupPayload"] = FulfillmentOrderLineItemsPreparedForPickupPayload;
Types.types["FulfillmentOrderLineItemsPreparedForPickupUserError"] = FulfillmentOrderLineItemsPreparedForPickupUserError;
Types.types["FulfillmentOrderLineItemsPreparedForPickupUserErrorCode"] = FulfillmentOrderLineItemsPreparedForPickupUserErrorCode;
Types.types["FulfillmentOrderLocationForMove"] = FulfillmentOrderLocationForMove;
Types.types["FulfillmentOrderLocationForMoveConnection"] = FulfillmentOrderLocationForMoveConnection;
Types.types["FulfillmentOrderLocationForMoveEdge"] = FulfillmentOrderLocationForMoveEdge;
Types.types["FulfillmentOrderMerchantRequest"] = FulfillmentOrderMerchantRequest;
Types.types["FulfillmentOrderMerchantRequestConnection"] = FulfillmentOrderMerchantRequestConnection;
Types.types["FulfillmentOrderMerchantRequestEdge"] = FulfillmentOrderMerchantRequestEdge;
Types.types["FulfillmentOrderMerchantRequestKind"] = FulfillmentOrderMerchantRequestKind;
Types.types["FulfillmentOrderMergeInput"] = FulfillmentOrderMergeInput;
Types.types["FulfillmentOrderMergeInputMergeIntent"] = FulfillmentOrderMergeInputMergeIntent;
Types.types["FulfillmentOrderMergePayload"] = FulfillmentOrderMergePayload;
Types.types["FulfillmentOrderMergeResult"] = FulfillmentOrderMergeResult;
Types.types["FulfillmentOrderMergeUserError"] = FulfillmentOrderMergeUserError;
Types.types["FulfillmentOrderMergeUserErrorCode"] = FulfillmentOrderMergeUserErrorCode;
Types.types["FulfillmentOrderMovePayload"] = FulfillmentOrderMovePayload;
Types.types["FulfillmentOrderOpenPayload"] = FulfillmentOrderOpenPayload;
Types.types["FulfillmentOrderRejectCancellationRequestPayload"] = FulfillmentOrderRejectCancellationRequestPayload;
Types.types["FulfillmentOrderRejectFulfillmentRequestPayload"] = FulfillmentOrderRejectFulfillmentRequestPayload;
Types.types["FulfillmentOrderRejectionReason"] = FulfillmentOrderRejectionReason;
Types.types["FulfillmentOrderReleaseHoldPayload"] = FulfillmentOrderReleaseHoldPayload;
Types.types["FulfillmentOrderReleaseHoldUserError"] = FulfillmentOrderReleaseHoldUserError;
Types.types["FulfillmentOrderReleaseHoldUserErrorCode"] = FulfillmentOrderReleaseHoldUserErrorCode;
Types.types["FulfillmentOrderRequestStatus"] = FulfillmentOrderRequestStatus;
Types.types["FulfillmentOrderReschedulePayload"] = FulfillmentOrderReschedulePayload;
Types.types["FulfillmentOrderRescheduleUserError"] = FulfillmentOrderRescheduleUserError;
Types.types["FulfillmentOrderRescheduleUserErrorCode"] = FulfillmentOrderRescheduleUserErrorCode;
Types.types["FulfillmentOrderSortKeys"] = FulfillmentOrderSortKeys;
Types.types["FulfillmentOrderSplitInput"] = FulfillmentOrderSplitInput;
Types.types["FulfillmentOrderSplitPayload"] = FulfillmentOrderSplitPayload;
Types.types["FulfillmentOrderSplitResult"] = FulfillmentOrderSplitResult;
Types.types["FulfillmentOrderSplitUserError"] = FulfillmentOrderSplitUserError;
Types.types["FulfillmentOrderSplitUserErrorCode"] = FulfillmentOrderSplitUserErrorCode;
Types.types["FulfillmentOrderStatus"] = FulfillmentOrderStatus;
Types.types["FulfillmentOrderSubmitCancellationRequestPayload"] = FulfillmentOrderSubmitCancellationRequestPayload;
Types.types["FulfillmentOrderSubmitFulfillmentRequestPayload"] = FulfillmentOrderSubmitFulfillmentRequestPayload;
Types.types["FulfillmentOrderSupportedAction"] = FulfillmentOrderSupportedAction;
Types.types["FulfillmentOrdersReleaseHoldsPayload"] = FulfillmentOrdersReleaseHoldsPayload;
Types.types["FulfillmentOrdersReleaseHoldsUserError"] = FulfillmentOrdersReleaseHoldsUserError;
Types.types["FulfillmentOrdersReleaseHoldsUserErrorCode"] = FulfillmentOrdersReleaseHoldsUserErrorCode;
Types.types["FulfillmentOrdersSetFulfillmentDeadlinePayload"] = FulfillmentOrdersSetFulfillmentDeadlinePayload;
Types.types["FulfillmentOrdersSetFulfillmentDeadlineUserError"] = FulfillmentOrdersSetFulfillmentDeadlineUserError;
Types.types["FulfillmentOrdersSetFulfillmentDeadlineUserErrorCode"] = FulfillmentOrdersSetFulfillmentDeadlineUserErrorCode;
Types.types["FulfillmentOriginAddress"] = FulfillmentOriginAddress;
Types.types["FulfillmentOriginAddressInput"] = FulfillmentOriginAddressInput;
Types.types["FulfillmentService"] = FulfillmentService;
Types.types["FulfillmentServiceCreatePayload"] = FulfillmentServiceCreatePayload;
Types.types["FulfillmentServiceDeletePayload"] = FulfillmentServiceDeletePayload;
Types.types["FulfillmentServiceType"] = FulfillmentServiceType;
Types.types["FulfillmentServiceUpdatePayload"] = FulfillmentServiceUpdatePayload;
Types.types["FulfillmentStatus"] = FulfillmentStatus;
Types.types["FulfillmentTrackingInfo"] = FulfillmentTrackingInfo;
Types.types["FulfillmentTrackingInfoUpdateV2Payload"] = FulfillmentTrackingInfoUpdateV2Payload;
Types.types["FulfillmentTrackingInput"] = FulfillmentTrackingInput;
Types.types["FulfillmentV2Input"] = FulfillmentV2Input;
Types.types["FunctionsAppBridge"] = FunctionsAppBridge;
Types.types["FunctionsErrorHistory"] = FunctionsErrorHistory;
Types.types["GenericFile"] = GenericFile;
Types.types["GiftCard"] = GiftCard;
Types.types["GiftCardConnection"] = GiftCardConnection;
Types.types["GiftCardCreateInput"] = GiftCardCreateInput;
Types.types["GiftCardCreatePayload"] = GiftCardCreatePayload;
Types.types["GiftCardDisablePayload"] = GiftCardDisablePayload;
Types.types["GiftCardEdge"] = GiftCardEdge;
Types.types["GiftCardErrorCode"] = GiftCardErrorCode;
Types.types["GiftCardSale"] = GiftCardSale;
Types.types["GiftCardSortKeys"] = GiftCardSortKeys;
Types.types["GiftCardUpdateInput"] = GiftCardUpdateInput;
Types.types["GiftCardUpdatePayload"] = GiftCardUpdatePayload;
Types.types["GiftCardUserError"] = GiftCardUserError;
Types.types["HTML"] = HTML;
Types.types["HasEvents"] = HasEvents;
Types.types["HasLocalizationExtensions"] = HasLocalizationExtensions;
Types.types["HasMetafieldDefinitions"] = HasMetafieldDefinitions;
Types.types["HasMetafields"] = HasMetafields;
Types.types["HasPublishedTranslations"] = HasPublishedTranslations;
Types.types["ID"] = ID;
Types.types["Image"] = Image;
Types.types["ImageConnection"] = ImageConnection;
Types.types["ImageContentType"] = ImageContentType;
Types.types["ImageEdge"] = ImageEdge;
Types.types["ImageInput"] = ImageInput;
Types.types["ImageTransformInput"] = ImageTransformInput;
Types.types["ImageUploadParameter"] = ImageUploadParameter;
Types.types["IncomingRequestLineItemInput"] = IncomingRequestLineItemInput;
Types.types["Int"] = Int;
Types.types["InventoryActivatePayload"] = InventoryActivatePayload;
Types.types["InventoryAdjustQuantitiesInput"] = InventoryAdjustQuantitiesInput;
Types.types["InventoryAdjustQuantitiesPayload"] = InventoryAdjustQuantitiesPayload;
Types.types["InventoryAdjustQuantitiesUserError"] = InventoryAdjustQuantitiesUserError;
Types.types["InventoryAdjustQuantitiesUserErrorCode"] = InventoryAdjustQuantitiesUserErrorCode;
Types.types["InventoryAdjustmentGroup"] = InventoryAdjustmentGroup;
Types.types["InventoryBulkToggleActivationInput"] = InventoryBulkToggleActivationInput;
Types.types["InventoryBulkToggleActivationPayload"] = InventoryBulkToggleActivationPayload;
Types.types["InventoryBulkToggleActivationUserError"] = InventoryBulkToggleActivationUserError;
Types.types["InventoryBulkToggleActivationUserErrorCode"] = InventoryBulkToggleActivationUserErrorCode;
Types.types["InventoryChange"] = InventoryChange;
Types.types["InventoryChangeInput"] = InventoryChangeInput;
Types.types["InventoryDeactivatePayload"] = InventoryDeactivatePayload;
Types.types["InventoryItem"] = InventoryItem;
Types.types["InventoryItemConnection"] = InventoryItemConnection;
Types.types["InventoryItemEdge"] = InventoryItemEdge;
Types.types["InventoryItemInput"] = InventoryItemInput;
Types.types["InventoryItemMeasurement"] = InventoryItemMeasurement;
Types.types["InventoryItemMeasurementInput"] = InventoryItemMeasurementInput;
Types.types["InventoryItemUpdateInput"] = InventoryItemUpdateInput;
Types.types["InventoryItemUpdatePayload"] = InventoryItemUpdatePayload;
Types.types["InventoryLevel"] = InventoryLevel;
Types.types["InventoryLevelConnection"] = InventoryLevelConnection;
Types.types["InventoryLevelEdge"] = InventoryLevelEdge;
Types.types["InventoryLevelInput"] = InventoryLevelInput;
Types.types["InventoryMoveQuantitiesInput"] = InventoryMoveQuantitiesInput;
Types.types["InventoryMoveQuantitiesPayload"] = InventoryMoveQuantitiesPayload;
Types.types["InventoryMoveQuantitiesUserError"] = InventoryMoveQuantitiesUserError;
Types.types["InventoryMoveQuantitiesUserErrorCode"] = InventoryMoveQuantitiesUserErrorCode;
Types.types["InventoryMoveQuantityChange"] = InventoryMoveQuantityChange;
Types.types["InventoryMoveQuantityTerminalInput"] = InventoryMoveQuantityTerminalInput;
Types.types["InventoryProperties"] = InventoryProperties;
Types.types["InventoryQuantity"] = InventoryQuantity;
Types.types["InventoryQuantityName"] = InventoryQuantityName;
Types.types["InventoryScheduledChange"] = InventoryScheduledChange;
Types.types["InventoryScheduledChangeConnection"] = InventoryScheduledChangeConnection;
Types.types["InventoryScheduledChangeEdge"] = InventoryScheduledChangeEdge;
Types.types["InventoryScheduledChangeInput"] = InventoryScheduledChangeInput;
Types.types["InventoryScheduledChangeItemInput"] = InventoryScheduledChangeItemInput;
Types.types["InventorySetOnHandQuantitiesInput"] = InventorySetOnHandQuantitiesInput;
Types.types["InventorySetOnHandQuantitiesPayload"] = InventorySetOnHandQuantitiesPayload;
Types.types["InventorySetOnHandQuantitiesUserError"] = InventorySetOnHandQuantitiesUserError;
Types.types["InventorySetOnHandQuantitiesUserErrorCode"] = InventorySetOnHandQuantitiesUserErrorCode;
Types.types["InventorySetQuantityInput"] = InventorySetQuantityInput;
Types.types["InventorySetScheduledChangesInput"] = InventorySetScheduledChangesInput;
Types.types["InventorySetScheduledChangesPayload"] = InventorySetScheduledChangesPayload;
Types.types["InventorySetScheduledChangesUserError"] = InventorySetScheduledChangesUserError;
Types.types["InventorySetScheduledChangesUserErrorCode"] = InventorySetScheduledChangesUserErrorCode;
Types.types["JSON"] = JSON;
Types.types["Job"] = Job;
Types.types["JobResult"] = JobResult;
Types.types["LanguageCode"] = LanguageCode;
Types.types["LegacyInteroperability"] = LegacyInteroperability;
Types.types["LengthUnit"] = LengthUnit;
Types.types["LimitedPendingOrderCount"] = LimitedPendingOrderCount;
Types.types["LineItem"] = LineItem;
Types.types["LineItemConnection"] = LineItemConnection;
Types.types["LineItemEdge"] = LineItemEdge;
Types.types["LineItemGroup"] = LineItemGroup;
Types.types["LineItemMutable"] = LineItemMutable;
Types.types["LineItemMutableConnection"] = LineItemMutableConnection;
Types.types["LineItemMutableEdge"] = LineItemMutableEdge;
Types.types["LineItemSellingPlan"] = LineItemSellingPlan;
Types.types["Link"] = Link;
Types.types["LinkedMetafield"] = LinkedMetafield;
Types.types["LinkedMetafieldCreateInput"] = LinkedMetafieldCreateInput;
Types.types["LinkedMetafieldUpdateInput"] = LinkedMetafieldUpdateInput;
Types.types["Locale"] = Locale;
Types.types["LocalizableContentType"] = LocalizableContentType;
Types.types["LocalizationExtension"] = LocalizationExtension;
Types.types["LocalizationExtensionConnection"] = LocalizationExtensionConnection;
Types.types["LocalizationExtensionEdge"] = LocalizationExtensionEdge;
Types.types["LocalizationExtensionInput"] = LocalizationExtensionInput;
Types.types["LocalizationExtensionKey"] = LocalizationExtensionKey;
Types.types["LocalizationExtensionPurpose"] = LocalizationExtensionPurpose;
Types.types["Location"] = Location;
Types.types["LocationActivatePayload"] = LocationActivatePayload;
Types.types["LocationActivateUserError"] = LocationActivateUserError;
Types.types["LocationActivateUserErrorCode"] = LocationActivateUserErrorCode;
Types.types["LocationAddAddressInput"] = LocationAddAddressInput;
Types.types["LocationAddInput"] = LocationAddInput;
Types.types["LocationAddPayload"] = LocationAddPayload;
Types.types["LocationAddUserError"] = LocationAddUserError;
Types.types["LocationAddUserErrorCode"] = LocationAddUserErrorCode;
Types.types["LocationAddress"] = LocationAddress;
Types.types["LocationConnection"] = LocationConnection;
Types.types["LocationDeactivatePayload"] = LocationDeactivatePayload;
Types.types["LocationDeactivateUserError"] = LocationDeactivateUserError;
Types.types["LocationDeactivateUserErrorCode"] = LocationDeactivateUserErrorCode;
Types.types["LocationDeletePayload"] = LocationDeletePayload;
Types.types["LocationDeleteUserError"] = LocationDeleteUserError;
Types.types["LocationDeleteUserErrorCode"] = LocationDeleteUserErrorCode;
Types.types["LocationEdge"] = LocationEdge;
Types.types["LocationEditAddressInput"] = LocationEditAddressInput;
Types.types["LocationEditInput"] = LocationEditInput;
Types.types["LocationEditPayload"] = LocationEditPayload;
Types.types["LocationEditUserError"] = LocationEditUserError;
Types.types["LocationEditUserErrorCode"] = LocationEditUserErrorCode;
Types.types["LocationLocalPickupDisablePayload"] = LocationLocalPickupDisablePayload;
Types.types["LocationLocalPickupEnablePayload"] = LocationLocalPickupEnablePayload;
Types.types["LocationSortKeys"] = LocationSortKeys;
Types.types["LocationSuggestedAddress"] = LocationSuggestedAddress;
Types.types["MailingAddress"] = MailingAddress;
Types.types["MailingAddressConnection"] = MailingAddressConnection;
Types.types["MailingAddressEdge"] = MailingAddressEdge;
Types.types["MailingAddressInput"] = MailingAddressInput;
Types.types["ManualDiscountApplication"] = ManualDiscountApplication;
Types.types["Market"] = Market;
Types.types["MarketCatalog"] = MarketCatalog;
Types.types["MarketCatalogConnection"] = MarketCatalogConnection;
Types.types["MarketCatalogEdge"] = MarketCatalogEdge;
Types.types["MarketConnection"] = MarketConnection;
Types.types["MarketCreateInput"] = MarketCreateInput;
Types.types["MarketCreatePayload"] = MarketCreatePayload;
Types.types["MarketCurrencySettings"] = MarketCurrencySettings;
Types.types["MarketCurrencySettingsUpdateInput"] = MarketCurrencySettingsUpdateInput;
Types.types["MarketCurrencySettingsUpdatePayload"] = MarketCurrencySettingsUpdatePayload;
Types.types["MarketCurrencySettingsUserError"] = MarketCurrencySettingsUserError;
Types.types["MarketCurrencySettingsUserErrorCode"] = MarketCurrencySettingsUserErrorCode;
Types.types["MarketDeletePayload"] = MarketDeletePayload;
Types.types["MarketEdge"] = MarketEdge;
Types.types["MarketLocalizableContent"] = MarketLocalizableContent;
Types.types["MarketLocalizableResource"] = MarketLocalizableResource;
Types.types["MarketLocalizableResourceConnection"] = MarketLocalizableResourceConnection;
Types.types["MarketLocalizableResourceEdge"] = MarketLocalizableResourceEdge;
Types.types["MarketLocalizableResourceType"] = MarketLocalizableResourceType;
Types.types["MarketLocalization"] = MarketLocalization;
Types.types["MarketLocalizationRegisterInput"] = MarketLocalizationRegisterInput;
Types.types["MarketLocalizationsRegisterPayload"] = MarketLocalizationsRegisterPayload;
Types.types["MarketLocalizationsRemovePayload"] = MarketLocalizationsRemovePayload;
Types.types["MarketRegion"] = MarketRegion;
Types.types["MarketRegionConnection"] = MarketRegionConnection;
Types.types["MarketRegionCountry"] = MarketRegionCountry;
Types.types["MarketRegionCreateInput"] = MarketRegionCreateInput;
Types.types["MarketRegionDeletePayload"] = MarketRegionDeletePayload;
Types.types["MarketRegionEdge"] = MarketRegionEdge;
Types.types["MarketRegionsCreatePayload"] = MarketRegionsCreatePayload;
Types.types["MarketRegionsDeletePayload"] = MarketRegionsDeletePayload;
Types.types["MarketUpdateInput"] = MarketUpdateInput;
Types.types["MarketUpdatePayload"] = MarketUpdatePayload;
Types.types["MarketUserError"] = MarketUserError;
Types.types["MarketUserErrorCode"] = MarketUserErrorCode;
Types.types["MarketWebPresence"] = MarketWebPresence;
Types.types["MarketWebPresenceConnection"] = MarketWebPresenceConnection;
Types.types["MarketWebPresenceCreateInput"] = MarketWebPresenceCreateInput;
Types.types["MarketWebPresenceCreatePayload"] = MarketWebPresenceCreatePayload;
Types.types["MarketWebPresenceDeletePayload"] = MarketWebPresenceDeletePayload;
Types.types["MarketWebPresenceEdge"] = MarketWebPresenceEdge;
Types.types["MarketWebPresenceRootUrl"] = MarketWebPresenceRootUrl;
Types.types["MarketWebPresenceUpdateInput"] = MarketWebPresenceUpdateInput;
Types.types["MarketWebPresenceUpdatePayload"] = MarketWebPresenceUpdatePayload;
Types.types["MarketingActivitiesDeleteAllExternalPayload"] = MarketingActivitiesDeleteAllExternalPayload;
Types.types["MarketingActivity"] = MarketingActivity;
Types.types["MarketingActivityBudgetInput"] = MarketingActivityBudgetInput;
Types.types["MarketingActivityConnection"] = MarketingActivityConnection;
Types.types["MarketingActivityCreateExternalInput"] = MarketingActivityCreateExternalInput;
Types.types["MarketingActivityCreateExternalPayload"] = MarketingActivityCreateExternalPayload;
Types.types["MarketingActivityCreateInput"] = MarketingActivityCreateInput;
Types.types["MarketingActivityCreatePayload"] = MarketingActivityCreatePayload;
Types.types["MarketingActivityDeleteExternalPayload"] = MarketingActivityDeleteExternalPayload;
Types.types["MarketingActivityEdge"] = MarketingActivityEdge;
Types.types["MarketingActivityExtensionAppErrorCode"] = MarketingActivityExtensionAppErrorCode;
Types.types["MarketingActivityExtensionAppErrors"] = MarketingActivityExtensionAppErrors;
Types.types["MarketingActivityExternalStatus"] = MarketingActivityExternalStatus;
Types.types["MarketingActivityHierarchyLevel"] = MarketingActivityHierarchyLevel;
Types.types["MarketingActivitySortKeys"] = MarketingActivitySortKeys;
Types.types["MarketingActivityStatus"] = MarketingActivityStatus;
Types.types["MarketingActivityStatusBadgeType"] = MarketingActivityStatusBadgeType;
Types.types["MarketingActivityUpdateExternalInput"] = MarketingActivityUpdateExternalInput;
Types.types["MarketingActivityUpdateExternalPayload"] = MarketingActivityUpdateExternalPayload;
Types.types["MarketingActivityUpdateInput"] = MarketingActivityUpdateInput;
Types.types["MarketingActivityUpdatePayload"] = MarketingActivityUpdatePayload;
Types.types["MarketingActivityUpsertExternalInput"] = MarketingActivityUpsertExternalInput;
Types.types["MarketingActivityUpsertExternalPayload"] = MarketingActivityUpsertExternalPayload;
Types.types["MarketingActivityUserError"] = MarketingActivityUserError;
Types.types["MarketingActivityUserErrorCode"] = MarketingActivityUserErrorCode;
Types.types["MarketingBudget"] = MarketingBudget;
Types.types["MarketingBudgetBudgetType"] = MarketingBudgetBudgetType;
Types.types["MarketingChannel"] = MarketingChannel;
Types.types["MarketingEngagement"] = MarketingEngagement;
Types.types["MarketingEngagementCreatePayload"] = MarketingEngagementCreatePayload;
Types.types["MarketingEngagementInput"] = MarketingEngagementInput;
Types.types["MarketingEngagementsDeletePayload"] = MarketingEngagementsDeletePayload;
Types.types["MarketingEvent"] = MarketingEvent;
Types.types["MarketingEventConnection"] = MarketingEventConnection;
Types.types["MarketingEventEdge"] = MarketingEventEdge;
Types.types["MarketingEventSortKeys"] = MarketingEventSortKeys;
Types.types["MarketingTactic"] = MarketingTactic;
Types.types["Media"] = Media;
Types.types["MediaConnection"] = MediaConnection;
Types.types["MediaContentType"] = MediaContentType;
Types.types["MediaEdge"] = MediaEdge;
Types.types["MediaError"] = MediaError;
Types.types["MediaErrorCode"] = MediaErrorCode;
Types.types["MediaHost"] = MediaHost;
Types.types["MediaImage"] = MediaImage;
Types.types["MediaImageOriginalSource"] = MediaImageOriginalSource;
Types.types["MediaPreviewImage"] = MediaPreviewImage;
Types.types["MediaPreviewImageStatus"] = MediaPreviewImageStatus;
Types.types["MediaStatus"] = MediaStatus;
Types.types["MediaUserError"] = MediaUserError;
Types.types["MediaUserErrorCode"] = MediaUserErrorCode;
Types.types["MediaWarning"] = MediaWarning;
Types.types["MediaWarningCode"] = MediaWarningCode;
Types.types["MerchandiseDiscountClass"] = MerchandiseDiscountClass;
Types.types["MerchantApprovalSignals"] = MerchantApprovalSignals;
Types.types["Metafield"] = Metafield;
Types.types["MetafieldAccess"] = MetafieldAccess;
Types.types["MetafieldAccessGrant"] = MetafieldAccessGrant;
Types.types["MetafieldAccessGrantDeleteInput"] = MetafieldAccessGrantDeleteInput;
Types.types["MetafieldAccessGrantInput"] = MetafieldAccessGrantInput;
Types.types["MetafieldAccessGrantOperationInput"] = MetafieldAccessGrantOperationInput;
Types.types["MetafieldAccessInput"] = MetafieldAccessInput;
Types.types["MetafieldAccessUpdateInput"] = MetafieldAccessUpdateInput;
Types.types["MetafieldAdminAccess"] = MetafieldAdminAccess;
Types.types["MetafieldConnection"] = MetafieldConnection;
Types.types["MetafieldDefinition"] = MetafieldDefinition;
Types.types["MetafieldDefinitionConnection"] = MetafieldDefinitionConnection;
Types.types["MetafieldDefinitionCreatePayload"] = MetafieldDefinitionCreatePayload;
Types.types["MetafieldDefinitionCreateUserError"] = MetafieldDefinitionCreateUserError;
Types.types["MetafieldDefinitionCreateUserErrorCode"] = MetafieldDefinitionCreateUserErrorCode;
Types.types["MetafieldDefinitionDeletePayload"] = MetafieldDefinitionDeletePayload;
Types.types["MetafieldDefinitionDeleteUserError"] = MetafieldDefinitionDeleteUserError;
Types.types["MetafieldDefinitionDeleteUserErrorCode"] = MetafieldDefinitionDeleteUserErrorCode;
Types.types["MetafieldDefinitionEdge"] = MetafieldDefinitionEdge;
Types.types["MetafieldDefinitionInput"] = MetafieldDefinitionInput;
Types.types["MetafieldDefinitionPinPayload"] = MetafieldDefinitionPinPayload;
Types.types["MetafieldDefinitionPinUserError"] = MetafieldDefinitionPinUserError;
Types.types["MetafieldDefinitionPinUserErrorCode"] = MetafieldDefinitionPinUserErrorCode;
Types.types["MetafieldDefinitionPinnedStatus"] = MetafieldDefinitionPinnedStatus;
Types.types["MetafieldDefinitionSortKeys"] = MetafieldDefinitionSortKeys;
Types.types["MetafieldDefinitionSupportedValidation"] = MetafieldDefinitionSupportedValidation;
Types.types["MetafieldDefinitionType"] = MetafieldDefinitionType;
Types.types["MetafieldDefinitionUnpinPayload"] = MetafieldDefinitionUnpinPayload;
Types.types["MetafieldDefinitionUnpinUserError"] = MetafieldDefinitionUnpinUserError;
Types.types["MetafieldDefinitionUnpinUserErrorCode"] = MetafieldDefinitionUnpinUserErrorCode;
Types.types["MetafieldDefinitionUpdateInput"] = MetafieldDefinitionUpdateInput;
Types.types["MetafieldDefinitionUpdatePayload"] = MetafieldDefinitionUpdatePayload;
Types.types["MetafieldDefinitionUpdateUserError"] = MetafieldDefinitionUpdateUserError;
Types.types["MetafieldDefinitionUpdateUserErrorCode"] = MetafieldDefinitionUpdateUserErrorCode;
Types.types["MetafieldDefinitionValidation"] = MetafieldDefinitionValidation;
Types.types["MetafieldDefinitionValidationInput"] = MetafieldDefinitionValidationInput;
Types.types["MetafieldDefinitionValidationStatus"] = MetafieldDefinitionValidationStatus;
Types.types["MetafieldDeleteInput"] = MetafieldDeleteInput;
Types.types["MetafieldDeletePayload"] = MetafieldDeletePayload;
Types.types["MetafieldEdge"] = MetafieldEdge;
Types.types["MetafieldGrantAccessLevel"] = MetafieldGrantAccessLevel;
Types.types["MetafieldIdentifier"] = MetafieldIdentifier;
Types.types["MetafieldIdentifierInput"] = MetafieldIdentifierInput;
Types.types["MetafieldInput"] = MetafieldInput;
Types.types["MetafieldOwnerType"] = MetafieldOwnerType;
Types.types["MetafieldReference"] = MetafieldReference;
Types.types["MetafieldReferenceConnection"] = MetafieldReferenceConnection;
Types.types["MetafieldReferenceEdge"] = MetafieldReferenceEdge;
Types.types["MetafieldReferencer"] = MetafieldReferencer;
Types.types["MetafieldRelation"] = MetafieldRelation;
Types.types["MetafieldRelationConnection"] = MetafieldRelationConnection;
Types.types["MetafieldRelationEdge"] = MetafieldRelationEdge;
Types.types["MetafieldStorefrontAccess"] = MetafieldStorefrontAccess;
Types.types["MetafieldStorefrontVisibility"] = MetafieldStorefrontVisibility;
Types.types["MetafieldStorefrontVisibilityConnection"] = MetafieldStorefrontVisibilityConnection;
Types.types["MetafieldStorefrontVisibilityCreatePayload"] = MetafieldStorefrontVisibilityCreatePayload;
Types.types["MetafieldStorefrontVisibilityDeletePayload"] = MetafieldStorefrontVisibilityDeletePayload;
Types.types["MetafieldStorefrontVisibilityEdge"] = MetafieldStorefrontVisibilityEdge;
Types.types["MetafieldStorefrontVisibilityInput"] = MetafieldStorefrontVisibilityInput;
Types.types["MetafieldValidationStatus"] = MetafieldValidationStatus;
Types.types["MetafieldValueType"] = MetafieldValueType;
Types.types["MetafieldsDeletePayload"] = MetafieldsDeletePayload;
Types.types["MetafieldsSetInput"] = MetafieldsSetInput;
Types.types["MetafieldsSetPayload"] = MetafieldsSetPayload;
Types.types["MetafieldsSetUserError"] = MetafieldsSetUserError;
Types.types["MetafieldsSetUserErrorCode"] = MetafieldsSetUserErrorCode;
Types.types["Metaobject"] = Metaobject;
Types.types["MetaobjectAccess"] = MetaobjectAccess;
Types.types["MetaobjectAccessInput"] = MetaobjectAccessInput;
Types.types["MetaobjectAdminAccess"] = MetaobjectAdminAccess;
Types.types["MetaobjectBulkDeletePayload"] = MetaobjectBulkDeletePayload;
Types.types["MetaobjectBulkDeleteWhereCondition"] = MetaobjectBulkDeleteWhereCondition;
Types.types["MetaobjectCapabilities"] = MetaobjectCapabilities;
Types.types["MetaobjectCapabilitiesOnlineStore"] = MetaobjectCapabilitiesOnlineStore;
Types.types["MetaobjectCapabilitiesPublishable"] = MetaobjectCapabilitiesPublishable;
Types.types["MetaobjectCapabilitiesRenderable"] = MetaobjectCapabilitiesRenderable;
Types.types["MetaobjectCapabilitiesTranslatable"] = MetaobjectCapabilitiesTranslatable;
Types.types["MetaobjectCapabilityCreateInput"] = MetaobjectCapabilityCreateInput;
Types.types["MetaobjectCapabilityData"] = MetaobjectCapabilityData;
Types.types["MetaobjectCapabilityDataInput"] = MetaobjectCapabilityDataInput;
Types.types["MetaobjectCapabilityDataOnlineStore"] = MetaobjectCapabilityDataOnlineStore;
Types.types["MetaobjectCapabilityDataOnlineStoreInput"] = MetaobjectCapabilityDataOnlineStoreInput;
Types.types["MetaobjectCapabilityDataPublishable"] = MetaobjectCapabilityDataPublishable;
Types.types["MetaobjectCapabilityDataPublishableInput"] = MetaobjectCapabilityDataPublishableInput;
Types.types["MetaobjectCapabilityDefinitionDataOnlineStore"] = MetaobjectCapabilityDefinitionDataOnlineStore;
Types.types["MetaobjectCapabilityDefinitionDataOnlineStoreInput"] = MetaobjectCapabilityDefinitionDataOnlineStoreInput;
Types.types["MetaobjectCapabilityDefinitionDataRenderable"] = MetaobjectCapabilityDefinitionDataRenderable;
Types.types["MetaobjectCapabilityDefinitionDataRenderableInput"] = MetaobjectCapabilityDefinitionDataRenderableInput;
Types.types["MetaobjectCapabilityOnlineStoreInput"] = MetaobjectCapabilityOnlineStoreInput;
Types.types["MetaobjectCapabilityPublishableInput"] = MetaobjectCapabilityPublishableInput;
Types.types["MetaobjectCapabilityRenderableInput"] = MetaobjectCapabilityRenderableInput;
Types.types["MetaobjectCapabilityTranslatableInput"] = MetaobjectCapabilityTranslatableInput;
Types.types["MetaobjectCapabilityUpdateInput"] = MetaobjectCapabilityUpdateInput;
Types.types["MetaobjectConnection"] = MetaobjectConnection;
Types.types["MetaobjectCreateInput"] = MetaobjectCreateInput;
Types.types["MetaobjectCreatePayload"] = MetaobjectCreatePayload;
Types.types["MetaobjectDefinition"] = MetaobjectDefinition;
Types.types["MetaobjectDefinitionConnection"] = MetaobjectDefinitionConnection;
Types.types["MetaobjectDefinitionCreateInput"] = MetaobjectDefinitionCreateInput;
Types.types["MetaobjectDefinitionCreatePayload"] = MetaobjectDefinitionCreatePayload;
Types.types["MetaobjectDefinitionDeletePayload"] = MetaobjectDefinitionDeletePayload;
Types.types["MetaobjectDefinitionEdge"] = MetaobjectDefinitionEdge;
Types.types["MetaobjectDefinitionUpdateInput"] = MetaobjectDefinitionUpdateInput;
Types.types["MetaobjectDefinitionUpdatePayload"] = MetaobjectDefinitionUpdatePayload;
Types.types["MetaobjectDeletePayload"] = MetaobjectDeletePayload;
Types.types["MetaobjectEdge"] = MetaobjectEdge;
Types.types["MetaobjectField"] = MetaobjectField;
Types.types["MetaobjectFieldDefinition"] = MetaobjectFieldDefinition;
Types.types["MetaobjectFieldDefinitionCreateInput"] = MetaobjectFieldDefinitionCreateInput;
Types.types["MetaobjectFieldDefinitionDeleteInput"] = MetaobjectFieldDefinitionDeleteInput;
Types.types["MetaobjectFieldDefinitionOperationInput"] = MetaobjectFieldDefinitionOperationInput;
Types.types["MetaobjectFieldDefinitionUpdateInput"] = MetaobjectFieldDefinitionUpdateInput;
Types.types["MetaobjectFieldInput"] = MetaobjectFieldInput;
Types.types["MetaobjectHandleInput"] = MetaobjectHandleInput;
Types.types["MetaobjectStatus"] = MetaobjectStatus;
Types.types["MetaobjectStorefrontAccess"] = MetaobjectStorefrontAccess;
Types.types["MetaobjectThumbnail"] = MetaobjectThumbnail;
Types.types["MetaobjectUpdateInput"] = MetaobjectUpdateInput;
Types.types["MetaobjectUpdatePayload"] = MetaobjectUpdatePayload;
Types.types["MetaobjectUpsertInput"] = MetaobjectUpsertInput;
Types.types["MetaobjectUpsertPayload"] = MetaobjectUpsertPayload;
Types.types["MetaobjectUserError"] = MetaobjectUserError;
Types.types["MetaobjectUserErrorCode"] = MetaobjectUserErrorCode;
Types.types["MethodDefinitionSortKeys"] = MethodDefinitionSortKeys;
Types.types["Model3d"] = Model3d;
Types.types["Model3dBoundingBox"] = Model3dBoundingBox;
Types.types["Model3dSource"] = Model3dSource;
Types.types["Money"] = Money;
Types.types["MoneyBag"] = MoneyBag;
Types.types["MoneyInput"] = MoneyInput;
Types.types["MoneyV2"] = MoneyV2;
Types.types["MoveInput"] = MoveInput;
Types.types["Mutation"] = Mutation;
Types.types["MutationsStagedUploadTargetGenerateUploadParameter"] = MutationsStagedUploadTargetGenerateUploadParameter;
Types.types["Navigable"] = Navigable;
Types.types["NavigationItem"] = NavigationItem;
Types.types["Node"] = Node;
Types.types["ObjectDimensionsInput"] = ObjectDimensionsInput;
Types.types["OnlineStoreArticle"] = OnlineStoreArticle;
Types.types["OnlineStoreBlog"] = OnlineStoreBlog;
Types.types["OnlineStorePage"] = OnlineStorePage;
Types.types["OnlineStorePreviewable"] = OnlineStorePreviewable;
Types.types["OptionCreateInput"] = OptionCreateInput;
Types.types["OptionReorderInput"] = OptionReorderInput;
Types.types["OptionSetInput"] = OptionSetInput;
Types.types["OptionUpdateInput"] = OptionUpdateInput;
Types.types["OptionValueCreateInput"] = OptionValueCreateInput;
Types.types["OptionValueReorderInput"] = OptionValueReorderInput;
Types.types["OptionValueSetInput"] = OptionValueSetInput;
Types.types["OptionValueUpdateInput"] = OptionValueUpdateInput;
Types.types["Order"] = Order;
Types.types["OrderActionType"] = OrderActionType;
Types.types["OrderAgreement"] = OrderAgreement;
Types.types["OrderApp"] = OrderApp;
Types.types["OrderCancelPayload"] = OrderCancelPayload;
Types.types["OrderCancelReason"] = OrderCancelReason;
Types.types["OrderCancelUserError"] = OrderCancelUserError;
Types.types["OrderCancelUserErrorCode"] = OrderCancelUserErrorCode;
Types.types["OrderCancellation"] = OrderCancellation;
Types.types["OrderCaptureInput"] = OrderCaptureInput;
Types.types["OrderCapturePayload"] = OrderCapturePayload;
Types.types["OrderCloseInput"] = OrderCloseInput;
Types.types["OrderClosePayload"] = OrderClosePayload;
Types.types["OrderConnection"] = OrderConnection;
Types.types["OrderCreateMandatePaymentPayload"] = OrderCreateMandatePaymentPayload;
Types.types["OrderCreateMandatePaymentUserError"] = OrderCreateMandatePaymentUserError;
Types.types["OrderCreateMandatePaymentUserErrorCode"] = OrderCreateMandatePaymentUserErrorCode;
Types.types["OrderDisplayFinancialStatus"] = OrderDisplayFinancialStatus;
Types.types["OrderDisplayFulfillmentStatus"] = OrderDisplayFulfillmentStatus;
Types.types["OrderDisputeSummary"] = OrderDisputeSummary;
Types.types["OrderEdge"] = OrderEdge;
Types.types["OrderEditAddCustomItemPayload"] = OrderEditAddCustomItemPayload;
Types.types["OrderEditAddLineItemDiscountPayload"] = OrderEditAddLineItemDiscountPayload;
Types.types["OrderEditAddShippingLineInput"] = OrderEditAddShippingLineInput;
Types.types["OrderEditAddShippingLinePayload"] = OrderEditAddShippingLinePayload;
Types.types["OrderEditAddShippingLineUserError"] = OrderEditAddShippingLineUserError;
Types.types["OrderEditAddShippingLineUserErrorCode"] = OrderEditAddShippingLineUserErrorCode;
Types.types["OrderEditAddVariantPayload"] = OrderEditAddVariantPayload;
Types.types["OrderEditAgreement"] = OrderEditAgreement;
Types.types["OrderEditAppliedDiscountInput"] = OrderEditAppliedDiscountInput;
Types.types["OrderEditBeginPayload"] = OrderEditBeginPayload;
Types.types["OrderEditCommitPayload"] = OrderEditCommitPayload;
Types.types["OrderEditRemoveDiscountPayload"] = OrderEditRemoveDiscountPayload;
Types.types["OrderEditRemoveDiscountUserError"] = OrderEditRemoveDiscountUserError;
Types.types["OrderEditRemoveDiscountUserErrorCode"] = OrderEditRemoveDiscountUserErrorCode;
Types.types["OrderEditRemoveLineItemDiscountPayload"] = OrderEditRemoveLineItemDiscountPayload;
Types.types["OrderEditRemoveShippingLinePayload"] = OrderEditRemoveShippingLinePayload;
Types.types["OrderEditRemoveShippingLineUserError"] = OrderEditRemoveShippingLineUserError;
Types.types["OrderEditRemoveShippingLineUserErrorCode"] = OrderEditRemoveShippingLineUserErrorCode;
Types.types["OrderEditSetQuantityPayload"] = OrderEditSetQuantityPayload;
Types.types["OrderEditUpdateDiscountPayload"] = OrderEditUpdateDiscountPayload;
Types.types["OrderEditUpdateDiscountUserError"] = OrderEditUpdateDiscountUserError;
Types.types["OrderEditUpdateDiscountUserErrorCode"] = OrderEditUpdateDiscountUserErrorCode;
Types.types["OrderEditUpdateShippingLineInput"] = OrderEditUpdateShippingLineInput;
Types.types["OrderEditUpdateShippingLinePayload"] = OrderEditUpdateShippingLinePayload;
Types.types["OrderEditUpdateShippingLineUserError"] = OrderEditUpdateShippingLineUserError;
Types.types["OrderEditUpdateShippingLineUserErrorCode"] = OrderEditUpdateShippingLineUserErrorCode;
Types.types["OrderInput"] = OrderInput;
Types.types["OrderInvoiceSendPayload"] = OrderInvoiceSendPayload;
Types.types["OrderInvoiceSendUserError"] = OrderInvoiceSendUserError;
Types.types["OrderInvoiceSendUserErrorCode"] = OrderInvoiceSendUserErrorCode;
Types.types["OrderMarkAsPaidInput"] = OrderMarkAsPaidInput;
Types.types["OrderMarkAsPaidPayload"] = OrderMarkAsPaidPayload;
Types.types["OrderOpenInput"] = OrderOpenInput;
Types.types["OrderOpenPayload"] = OrderOpenPayload;
Types.types["OrderPaymentCollectionDetails"] = OrderPaymentCollectionDetails;
Types.types["OrderPaymentStatus"] = OrderPaymentStatus;
Types.types["OrderPaymentStatusResult"] = OrderPaymentStatusResult;
Types.types["OrderReturnStatus"] = OrderReturnStatus;
Types.types["OrderRisk"] = OrderRisk;
Types.types["OrderRiskAssessment"] = OrderRiskAssessment;
Types.types["OrderRiskAssessmentCreateInput"] = OrderRiskAssessmentCreateInput;
Types.types["OrderRiskAssessmentCreatePayload"] = OrderRiskAssessmentCreatePayload;
Types.types["OrderRiskAssessmentCreateUserError"] = OrderRiskAssessmentCreateUserError;
Types.types["OrderRiskAssessmentCreateUserErrorCode"] = OrderRiskAssessmentCreateUserErrorCode;
Types.types["OrderRiskAssessmentFactInput"] = OrderRiskAssessmentFactInput;
Types.types["OrderRiskLevel"] = OrderRiskLevel;
Types.types["OrderRiskRecommendationResult"] = OrderRiskRecommendationResult;
Types.types["OrderRiskSummary"] = OrderRiskSummary;
Types.types["OrderSortKeys"] = OrderSortKeys;
Types.types["OrderStagedChange"] = OrderStagedChange;
Types.types["OrderStagedChangeAddCustomItem"] = OrderStagedChangeAddCustomItem;
Types.types["OrderStagedChangeAddLineItemDiscount"] = OrderStagedChangeAddLineItemDiscount;
Types.types["OrderStagedChangeAddShippingLine"] = OrderStagedChangeAddShippingLine;
Types.types["OrderStagedChangeAddVariant"] = OrderStagedChangeAddVariant;
Types.types["OrderStagedChangeConnection"] = OrderStagedChangeConnection;
Types.types["OrderStagedChangeDecrementItem"] = OrderStagedChangeDecrementItem;
Types.types["OrderStagedChangeEdge"] = OrderStagedChangeEdge;
Types.types["OrderStagedChangeIncrementItem"] = OrderStagedChangeIncrementItem;
Types.types["OrderStagedChangeRemoveShippingLine"] = OrderStagedChangeRemoveShippingLine;
Types.types["OrderTransaction"] = OrderTransaction;
Types.types["OrderTransactionConnection"] = OrderTransactionConnection;
Types.types["OrderTransactionEdge"] = OrderTransactionEdge;
Types.types["OrderTransactionErrorCode"] = OrderTransactionErrorCode;
Types.types["OrderTransactionInput"] = OrderTransactionInput;
Types.types["OrderTransactionKind"] = OrderTransactionKind;
Types.types["OrderTransactionStatus"] = OrderTransactionStatus;
Types.types["OrderUpdatePayload"] = OrderUpdatePayload;
Types.types["PageInfo"] = PageInfo;
Types.types["ParseError"] = ParseError;
Types.types["ParseErrorCode"] = ParseErrorCode;
Types.types["ParseErrorRange"] = ParseErrorRange;
Types.types["PaymentCustomization"] = PaymentCustomization;
Types.types["PaymentCustomizationActivationPayload"] = PaymentCustomizationActivationPayload;
Types.types["PaymentCustomizationConnection"] = PaymentCustomizationConnection;
Types.types["PaymentCustomizationCreatePayload"] = PaymentCustomizationCreatePayload;
Types.types["PaymentCustomizationDeletePayload"] = PaymentCustomizationDeletePayload;
Types.types["PaymentCustomizationEdge"] = PaymentCustomizationEdge;
Types.types["PaymentCustomizationError"] = PaymentCustomizationError;
Types.types["PaymentCustomizationErrorCode"] = PaymentCustomizationErrorCode;
Types.types["PaymentCustomizationInput"] = PaymentCustomizationInput;
Types.types["PaymentCustomizationUpdatePayload"] = PaymentCustomizationUpdatePayload;
Types.types["PaymentDetails"] = PaymentDetails;
Types.types["PaymentInstrument"] = PaymentInstrument;
Types.types["PaymentMandate"] = PaymentMandate;
Types.types["PaymentMethods"] = PaymentMethods;
Types.types["PaymentReminderSendPayload"] = PaymentReminderSendPayload;
Types.types["PaymentReminderSendUserError"] = PaymentReminderSendUserError;
Types.types["PaymentReminderSendUserErrorCode"] = PaymentReminderSendUserErrorCode;
Types.types["PaymentSchedule"] = PaymentSchedule;
Types.types["PaymentScheduleConnection"] = PaymentScheduleConnection;
Types.types["PaymentScheduleEdge"] = PaymentScheduleEdge;
Types.types["PaymentScheduleInput"] = PaymentScheduleInput;
Types.types["PaymentSettings"] = PaymentSettings;
Types.types["PaymentTerms"] = PaymentTerms;
Types.types["PaymentTermsCreateInput"] = PaymentTermsCreateInput;
Types.types["PaymentTermsCreatePayload"] = PaymentTermsCreatePayload;
Types.types["PaymentTermsCreateUserError"] = PaymentTermsCreateUserError;
Types.types["PaymentTermsCreateUserErrorCode"] = PaymentTermsCreateUserErrorCode;
Types.types["PaymentTermsDeleteInput"] = PaymentTermsDeleteInput;
Types.types["PaymentTermsDeletePayload"] = PaymentTermsDeletePayload;
Types.types["PaymentTermsDeleteUserError"] = PaymentTermsDeleteUserError;
Types.types["PaymentTermsDeleteUserErrorCode"] = PaymentTermsDeleteUserErrorCode;
Types.types["PaymentTermsInput"] = PaymentTermsInput;
Types.types["PaymentTermsTemplate"] = PaymentTermsTemplate;
Types.types["PaymentTermsType"] = PaymentTermsType;
Types.types["PaymentTermsUpdateInput"] = PaymentTermsUpdateInput;
Types.types["PaymentTermsUpdatePayload"] = PaymentTermsUpdatePayload;
Types.types["PaymentTermsUpdateUserError"] = PaymentTermsUpdateUserError;
Types.types["PaymentTermsUpdateUserErrorCode"] = PaymentTermsUpdateUserErrorCode;
Types.types["PaypalExpressSubscriptionsGatewayStatus"] = PaypalExpressSubscriptionsGatewayStatus;
Types.types["PolarisVizDataPoint"] = PolarisVizDataPoint;
Types.types["PolarisVizDataSeries"] = PolarisVizDataSeries;
Types.types["PolarisVizResponse"] = PolarisVizResponse;
Types.types["PreparedFulfillmentOrderLineItemsInput"] = PreparedFulfillmentOrderLineItemsInput;
Types.types["PriceCalculationType"] = PriceCalculationType;
Types.types["PriceInput"] = PriceInput;
Types.types["PriceList"] = PriceList;
Types.types["PriceListAdjustment"] = PriceListAdjustment;
Types.types["PriceListAdjustmentInput"] = PriceListAdjustmentInput;
Types.types["PriceListAdjustmentSettings"] = PriceListAdjustmentSettings;
Types.types["PriceListAdjustmentSettingsInput"] = PriceListAdjustmentSettingsInput;
Types.types["PriceListAdjustmentType"] = PriceListAdjustmentType;
Types.types["PriceListCompareAtMode"] = PriceListCompareAtMode;
Types.types["PriceListConnection"] = PriceListConnection;
Types.types["PriceListCreateInput"] = PriceListCreateInput;
Types.types["PriceListCreatePayload"] = PriceListCreatePayload;
Types.types["PriceListDeletePayload"] = PriceListDeletePayload;
Types.types["PriceListEdge"] = PriceListEdge;
Types.types["PriceListFixedPricesAddPayload"] = PriceListFixedPricesAddPayload;
Types.types["PriceListFixedPricesByProductBulkUpdateUserError"] = PriceListFixedPricesByProductBulkUpdateUserError;
Types.types["PriceListFixedPricesByProductBulkUpdateUserErrorCode"] = PriceListFixedPricesByProductBulkUpdateUserErrorCode;
Types.types["PriceListFixedPricesByProductUpdatePayload"] = PriceListFixedPricesByProductUpdatePayload;
Types.types["PriceListFixedPricesDeletePayload"] = PriceListFixedPricesDeletePayload;
Types.types["PriceListFixedPricesUpdatePayload"] = PriceListFixedPricesUpdatePayload;
Types.types["PriceListParent"] = PriceListParent;
Types.types["PriceListParentCreateInput"] = PriceListParentCreateInput;
Types.types["PriceListParentUpdateInput"] = PriceListParentUpdateInput;
Types.types["PriceListPrice"] = PriceListPrice;
Types.types["PriceListPriceConnection"] = PriceListPriceConnection;
Types.types["PriceListPriceEdge"] = PriceListPriceEdge;
Types.types["PriceListPriceInput"] = PriceListPriceInput;
Types.types["PriceListPriceOriginType"] = PriceListPriceOriginType;
Types.types["PriceListPriceUserError"] = PriceListPriceUserError;
Types.types["PriceListPriceUserErrorCode"] = PriceListPriceUserErrorCode;
Types.types["PriceListProductPriceInput"] = PriceListProductPriceInput;
Types.types["PriceListSortKeys"] = PriceListSortKeys;
Types.types["PriceListUpdateInput"] = PriceListUpdateInput;
Types.types["PriceListUpdatePayload"] = PriceListUpdatePayload;
Types.types["PriceListUserError"] = PriceListUserError;
Types.types["PriceListUserErrorCode"] = PriceListUserErrorCode;
Types.types["PriceRule"] = PriceRule;
Types.types["PriceRuleActivatePayload"] = PriceRuleActivatePayload;
Types.types["PriceRuleAllocationMethod"] = PriceRuleAllocationMethod;
Types.types["PriceRuleConnection"] = PriceRuleConnection;
Types.types["PriceRuleCreatePayload"] = PriceRuleCreatePayload;
Types.types["PriceRuleCustomerSelection"] = PriceRuleCustomerSelection;
Types.types["PriceRuleCustomerSelectionInput"] = PriceRuleCustomerSelectionInput;
Types.types["PriceRuleDeactivatePayload"] = PriceRuleDeactivatePayload;
Types.types["PriceRuleDeletePayload"] = PriceRuleDeletePayload;
Types.types["PriceRuleDiscountCode"] = PriceRuleDiscountCode;
Types.types["PriceRuleDiscountCodeConnection"] = PriceRuleDiscountCodeConnection;
Types.types["PriceRuleDiscountCodeCreatePayload"] = PriceRuleDiscountCodeCreatePayload;
Types.types["PriceRuleDiscountCodeEdge"] = PriceRuleDiscountCodeEdge;
Types.types["PriceRuleDiscountCodeInput"] = PriceRuleDiscountCodeInput;
Types.types["PriceRuleDiscountCodeUpdatePayload"] = PriceRuleDiscountCodeUpdatePayload;
Types.types["PriceRuleEdge"] = PriceRuleEdge;
Types.types["PriceRuleEntitlementToPrerequisiteQuantityRatio"] = PriceRuleEntitlementToPrerequisiteQuantityRatio;
Types.types["PriceRuleEntitlementToPrerequisiteQuantityRatioInput"] = PriceRuleEntitlementToPrerequisiteQuantityRatioInput;
Types.types["PriceRuleErrorCode"] = PriceRuleErrorCode;
Types.types["PriceRuleFeature"] = PriceRuleFeature;
Types.types["PriceRuleFixedAmountValue"] = PriceRuleFixedAmountValue;
Types.types["PriceRuleInput"] = PriceRuleInput;
Types.types["PriceRuleItemEntitlements"] = PriceRuleItemEntitlements;
Types.types["PriceRuleItemEntitlementsInput"] = PriceRuleItemEntitlementsInput;
Types.types["PriceRuleItemPrerequisitesInput"] = PriceRuleItemPrerequisitesInput;
Types.types["PriceRuleLineItemPrerequisites"] = PriceRuleLineItemPrerequisites;
Types.types["PriceRuleMoneyRange"] = PriceRuleMoneyRange;
Types.types["PriceRuleMoneyRangeInput"] = PriceRuleMoneyRangeInput;
Types.types["PriceRulePercentValue"] = PriceRulePercentValue;
Types.types["PriceRulePrerequisiteToEntitlementQuantityRatio"] = PriceRulePrerequisiteToEntitlementQuantityRatio;
Types.types["PriceRulePrerequisiteToEntitlementQuantityRatioInput"] = PriceRulePrerequisiteToEntitlementQuantityRatioInput;
Types.types["PriceRuleQuantityRange"] = PriceRuleQuantityRange;
Types.types["PriceRuleQuantityRangeInput"] = PriceRuleQuantityRangeInput;
Types.types["PriceRuleShareableUrl"] = PriceRuleShareableUrl;
Types.types["PriceRuleShareableUrlTargetType"] = PriceRuleShareableUrlTargetType;
Types.types["PriceRuleShippingEntitlementsInput"] = PriceRuleShippingEntitlementsInput;
Types.types["PriceRuleShippingLineEntitlements"] = PriceRuleShippingLineEntitlements;
Types.types["PriceRuleSortKeys"] = PriceRuleSortKeys;
Types.types["PriceRuleStatus"] = PriceRuleStatus;
Types.types["PriceRuleTarget"] = PriceRuleTarget;
Types.types["PriceRuleTrait"] = PriceRuleTrait;
Types.types["PriceRuleUpdatePayload"] = PriceRuleUpdatePayload;
Types.types["PriceRuleUserError"] = PriceRuleUserError;
Types.types["PriceRuleValidityPeriod"] = PriceRuleValidityPeriod;
Types.types["PriceRuleValidityPeriodInput"] = PriceRuleValidityPeriodInput;
Types.types["PriceRuleValue"] = PriceRuleValue;
Types.types["PriceRuleValueInput"] = PriceRuleValueInput;
Types.types["PricingPercentageValue"] = PricingPercentageValue;
Types.types["PricingValue"] = PricingValue;
Types.types["PrivateMetafield"] = PrivateMetafield;
Types.types["PrivateMetafieldConnection"] = PrivateMetafieldConnection;
Types.types["PrivateMetafieldDeleteInput"] = PrivateMetafieldDeleteInput;
Types.types["PrivateMetafieldDeletePayload"] = PrivateMetafieldDeletePayload;
Types.types["PrivateMetafieldEdge"] = PrivateMetafieldEdge;
Types.types["PrivateMetafieldInput"] = PrivateMetafieldInput;
Types.types["PrivateMetafieldUpsertPayload"] = PrivateMetafieldUpsertPayload;
Types.types["PrivateMetafieldValueInput"] = PrivateMetafieldValueInput;
Types.types["PrivateMetafieldValueType"] = PrivateMetafieldValueType;
Types.types["Product"] = Product;
Types.types["ProductAppendImagesInput"] = ProductAppendImagesInput;
Types.types["ProductAppendImagesPayload"] = ProductAppendImagesPayload;
Types.types["ProductCategory"] = ProductCategory;
Types.types["ProductCategoryInput"] = ProductCategoryInput;
Types.types["ProductChangeStatusPayload"] = ProductChangeStatusPayload;
Types.types["ProductChangeStatusUserError"] = ProductChangeStatusUserError;
Types.types["ProductChangeStatusUserErrorCode"] = ProductChangeStatusUserErrorCode;
Types.types["ProductClaimOwnershipInput"] = ProductClaimOwnershipInput;
Types.types["ProductCollectionSortKeys"] = ProductCollectionSortKeys;
Types.types["ProductCompareAtPriceRange"] = ProductCompareAtPriceRange;
Types.types["ProductConnection"] = ProductConnection;
Types.types["ProductContextualPricing"] = ProductContextualPricing;
Types.types["ProductCreateMediaPayload"] = ProductCreateMediaPayload;
Types.types["ProductCreatePayload"] = ProductCreatePayload;
Types.types["ProductDeleteAsyncPayload"] = ProductDeleteAsyncPayload;
Types.types["ProductDeleteImagesPayload"] = ProductDeleteImagesPayload;
Types.types["ProductDeleteInput"] = ProductDeleteInput;
Types.types["ProductDeleteMediaPayload"] = ProductDeleteMediaPayload;
Types.types["ProductDeletePayload"] = ProductDeletePayload;
Types.types["ProductDeleteUserError"] = ProductDeleteUserError;
Types.types["ProductDeleteUserErrorCode"] = ProductDeleteUserErrorCode;
Types.types["ProductDuplicateAsyncInput"] = ProductDuplicateAsyncInput;
Types.types["ProductDuplicateAsyncV2Payload"] = ProductDuplicateAsyncV2Payload;
Types.types["ProductDuplicateJob"] = ProductDuplicateJob;
Types.types["ProductDuplicatePayload"] = ProductDuplicatePayload;
Types.types["ProductDuplicateUserError"] = ProductDuplicateUserError;
Types.types["ProductDuplicateUserErrorCode"] = ProductDuplicateUserErrorCode;
Types.types["ProductEdge"] = ProductEdge;
Types.types["ProductFeed"] = ProductFeed;
Types.types["ProductFeedConnection"] = ProductFeedConnection;
Types.types["ProductFeedCreatePayload"] = ProductFeedCreatePayload;
Types.types["ProductFeedCreateUserError"] = ProductFeedCreateUserError;
Types.types["ProductFeedCreateUserErrorCode"] = ProductFeedCreateUserErrorCode;
Types.types["ProductFeedDeletePayload"] = ProductFeedDeletePayload;
Types.types["ProductFeedDeleteUserError"] = ProductFeedDeleteUserError;
Types.types["ProductFeedDeleteUserErrorCode"] = ProductFeedDeleteUserErrorCode;
Types.types["ProductFeedEdge"] = ProductFeedEdge;
Types.types["ProductFeedInput"] = ProductFeedInput;
Types.types["ProductFeedStatus"] = ProductFeedStatus;
Types.types["ProductFullSyncPayload"] = ProductFullSyncPayload;
Types.types["ProductFullSyncUserError"] = ProductFullSyncUserError;
Types.types["ProductFullSyncUserErrorCode"] = ProductFullSyncUserErrorCode;
Types.types["ProductImageSortKeys"] = ProductImageSortKeys;
Types.types["ProductImageUpdatePayload"] = ProductImageUpdatePayload;
Types.types["ProductInput"] = ProductInput;
Types.types["ProductJoinSellingPlanGroupsPayload"] = ProductJoinSellingPlanGroupsPayload;
Types.types["ProductLeaveSellingPlanGroupsPayload"] = ProductLeaveSellingPlanGroupsPayload;
Types.types["ProductMediaSortKeys"] = ProductMediaSortKeys;
Types.types["ProductOperation"] = ProductOperation;
Types.types["ProductOperationStatus"] = ProductOperationStatus;
Types.types["ProductOption"] = ProductOption;
Types.types["ProductOptionDeleteStrategy"] = ProductOptionDeleteStrategy;
Types.types["ProductOptionUpdatePayload"] = ProductOptionUpdatePayload;
Types.types["ProductOptionUpdateUserError"] = ProductOptionUpdateUserError;
Types.types["ProductOptionUpdateUserErrorCode"] = ProductOptionUpdateUserErrorCode;
Types.types["ProductOptionUpdateVariantStrategy"] = ProductOptionUpdateVariantStrategy;
Types.types["ProductOptionValue"] = ProductOptionValue;
Types.types["ProductOptionValueSwatch"] = ProductOptionValueSwatch;
Types.types["ProductOptionsCreatePayload"] = ProductOptionsCreatePayload;
Types.types["ProductOptionsCreateUserError"] = ProductOptionsCreateUserError;
Types.types["ProductOptionsCreateUserErrorCode"] = ProductOptionsCreateUserErrorCode;
Types.types["ProductOptionsDeletePayload"] = ProductOptionsDeletePayload;
Types.types["ProductOptionsDeleteUserError"] = ProductOptionsDeleteUserError;
Types.types["ProductOptionsDeleteUserErrorCode"] = ProductOptionsDeleteUserErrorCode;
Types.types["ProductOptionsReorderPayload"] = ProductOptionsReorderPayload;
Types.types["ProductOptionsReorderUserError"] = ProductOptionsReorderUserError;
Types.types["ProductOptionsReorderUserErrorCode"] = ProductOptionsReorderUserErrorCode;
Types.types["ProductPriceRange"] = ProductPriceRange;
Types.types["ProductPriceRangeV2"] = ProductPriceRangeV2;
Types.types["ProductPublication"] = ProductPublication;
Types.types["ProductPublicationConnection"] = ProductPublicationConnection;
Types.types["ProductPublicationEdge"] = ProductPublicationEdge;
Types.types["ProductPublicationInput"] = ProductPublicationInput;
Types.types["ProductPublishInput"] = ProductPublishInput;
Types.types["ProductPublishPayload"] = ProductPublishPayload;
Types.types["ProductReorderImagesPayload"] = ProductReorderImagesPayload;
Types.types["ProductReorderMediaPayload"] = ProductReorderMediaPayload;
Types.types["ProductResourceFeedback"] = ProductResourceFeedback;
Types.types["ProductResourceFeedbackInput"] = ProductResourceFeedbackInput;
Types.types["ProductSale"] = ProductSale;
Types.types["ProductSetInput"] = ProductSetInput;
Types.types["ProductSetOperation"] = ProductSetOperation;
Types.types["ProductSetPayload"] = ProductSetPayload;
Types.types["ProductSetUserError"] = ProductSetUserError;
Types.types["ProductSetUserErrorCode"] = ProductSetUserErrorCode;
Types.types["ProductSortKeys"] = ProductSortKeys;
Types.types["ProductStatus"] = ProductStatus;
Types.types["ProductTaxonomyNode"] = ProductTaxonomyNode;
Types.types["ProductUnpublishInput"] = ProductUnpublishInput;
Types.types["ProductUnpublishPayload"] = ProductUnpublishPayload;
Types.types["ProductUpdateMediaPayload"] = ProductUpdateMediaPayload;
Types.types["ProductUpdatePayload"] = ProductUpdatePayload;
Types.types["ProductVariant"] = ProductVariant;
Types.types["ProductVariantAppendMediaInput"] = ProductVariantAppendMediaInput;
Types.types["ProductVariantAppendMediaPayload"] = ProductVariantAppendMediaPayload;
Types.types["ProductVariantComponent"] = ProductVariantComponent;
Types.types["ProductVariantComponentConnection"] = ProductVariantComponentConnection;
Types.types["ProductVariantComponentEdge"] = ProductVariantComponentEdge;
Types.types["ProductVariantConnection"] = ProductVariantConnection;
Types.types["ProductVariantContextualPricing"] = ProductVariantContextualPricing;
Types.types["ProductVariantCreatePayload"] = ProductVariantCreatePayload;
Types.types["ProductVariantDeletePayload"] = ProductVariantDeletePayload;
Types.types["ProductVariantDetachMediaInput"] = ProductVariantDetachMediaInput;
Types.types["ProductVariantDetachMediaPayload"] = ProductVariantDetachMediaPayload;
Types.types["ProductVariantEdge"] = ProductVariantEdge;
Types.types["ProductVariantGroupRelationshipInput"] = ProductVariantGroupRelationshipInput;
Types.types["ProductVariantInput"] = ProductVariantInput;
Types.types["ProductVariantInventoryManagement"] = ProductVariantInventoryManagement;
Types.types["ProductVariantInventoryPolicy"] = ProductVariantInventoryPolicy;
Types.types["ProductVariantJoinSellingPlanGroupsPayload"] = ProductVariantJoinSellingPlanGroupsPayload;
Types.types["ProductVariantLeaveSellingPlanGroupsPayload"] = ProductVariantLeaveSellingPlanGroupsPayload;
Types.types["ProductVariantPositionInput"] = ProductVariantPositionInput;
Types.types["ProductVariantPricePair"] = ProductVariantPricePair;
Types.types["ProductVariantPricePairConnection"] = ProductVariantPricePairConnection;
Types.types["ProductVariantPricePairEdge"] = ProductVariantPricePairEdge;
Types.types["ProductVariantRelationshipBulkUpdatePayload"] = ProductVariantRelationshipBulkUpdatePayload;
Types.types["ProductVariantRelationshipBulkUpdateUserError"] = ProductVariantRelationshipBulkUpdateUserError;
Types.types["ProductVariantRelationshipBulkUpdateUserErrorCode"] = ProductVariantRelationshipBulkUpdateUserErrorCode;
Types.types["ProductVariantRelationshipUpdateInput"] = ProductVariantRelationshipUpdateInput;
Types.types["ProductVariantSetInput"] = ProductVariantSetInput;
Types.types["ProductVariantSortKeys"] = ProductVariantSortKeys;
Types.types["ProductVariantUpdatePayload"] = ProductVariantUpdatePayload;
Types.types["ProductVariantsBulkCreatePayload"] = ProductVariantsBulkCreatePayload;
Types.types["ProductVariantsBulkCreateStrategy"] = ProductVariantsBulkCreateStrategy;
Types.types["ProductVariantsBulkCreateUserError"] = ProductVariantsBulkCreateUserError;
Types.types["ProductVariantsBulkCreateUserErrorCode"] = ProductVariantsBulkCreateUserErrorCode;
Types.types["ProductVariantsBulkDeletePayload"] = ProductVariantsBulkDeletePayload;
Types.types["ProductVariantsBulkDeleteUserError"] = ProductVariantsBulkDeleteUserError;
Types.types["ProductVariantsBulkDeleteUserErrorCode"] = ProductVariantsBulkDeleteUserErrorCode;
Types.types["ProductVariantsBulkInput"] = ProductVariantsBulkInput;
Types.types["ProductVariantsBulkReorderPayload"] = ProductVariantsBulkReorderPayload;
Types.types["ProductVariantsBulkReorderUserError"] = ProductVariantsBulkReorderUserError;
Types.types["ProductVariantsBulkReorderUserErrorCode"] = ProductVariantsBulkReorderUserErrorCode;
Types.types["ProductVariantsBulkUpdatePayload"] = ProductVariantsBulkUpdatePayload;
Types.types["ProductVariantsBulkUpdateUserError"] = ProductVariantsBulkUpdateUserError;
Types.types["ProductVariantsBulkUpdateUserErrorCode"] = ProductVariantsBulkUpdateUserErrorCode;
Types.types["ProfileItemSortKeys"] = ProfileItemSortKeys;
Types.types["PubSubServerPixelUpdatePayload"] = PubSubServerPixelUpdatePayload;
Types.types["PubSubWebhookSubscriptionCreatePayload"] = PubSubWebhookSubscriptionCreatePayload;
Types.types["PubSubWebhookSubscriptionCreateUserError"] = PubSubWebhookSubscriptionCreateUserError;
Types.types["PubSubWebhookSubscriptionCreateUserErrorCode"] = PubSubWebhookSubscriptionCreateUserErrorCode;
Types.types["PubSubWebhookSubscriptionInput"] = PubSubWebhookSubscriptionInput;
Types.types["PubSubWebhookSubscriptionUpdatePayload"] = PubSubWebhookSubscriptionUpdatePayload;
Types.types["PubSubWebhookSubscriptionUpdateUserError"] = PubSubWebhookSubscriptionUpdateUserError;
Types.types["PubSubWebhookSubscriptionUpdateUserErrorCode"] = PubSubWebhookSubscriptionUpdateUserErrorCode;
Types.types["Publication"] = Publication;
Types.types["PublicationConnection"] = PublicationConnection;
Types.types["PublicationCreateInput"] = PublicationCreateInput;
Types.types["PublicationCreateInputPublicationDefaultState"] = PublicationCreateInputPublicationDefaultState;
Types.types["PublicationCreatePayload"] = PublicationCreatePayload;
Types.types["PublicationDeletePayload"] = PublicationDeletePayload;
Types.types["PublicationEdge"] = PublicationEdge;
Types.types["PublicationInput"] = PublicationInput;
Types.types["PublicationOperation"] = PublicationOperation;
Types.types["PublicationResourceOperation"] = PublicationResourceOperation;
Types.types["PublicationUpdateInput"] = PublicationUpdateInput;
Types.types["PublicationUpdatePayload"] = PublicationUpdatePayload;
Types.types["PublicationUserError"] = PublicationUserError;
Types.types["PublicationUserErrorCode"] = PublicationUserErrorCode;
Types.types["Publishable"] = Publishable;
Types.types["PublishablePublishPayload"] = PublishablePublishPayload;
Types.types["PublishablePublishToCurrentChannelPayload"] = PublishablePublishToCurrentChannelPayload;
Types.types["PublishableUnpublishPayload"] = PublishableUnpublishPayload;
Types.types["PublishableUnpublishToCurrentChannelPayload"] = PublishableUnpublishToCurrentChannelPayload;
Types.types["PurchasingCompany"] = PurchasingCompany;
Types.types["PurchasingCompanyInput"] = PurchasingCompanyInput;
Types.types["PurchasingEntity"] = PurchasingEntity;
Types.types["PurchasingEntityInput"] = PurchasingEntityInput;
Types.types["QuantityPriceBreak"] = QuantityPriceBreak;
Types.types["QuantityPriceBreakConnection"] = QuantityPriceBreakConnection;
Types.types["QuantityPriceBreakEdge"] = QuantityPriceBreakEdge;
Types.types["QuantityPriceBreakInput"] = QuantityPriceBreakInput;
Types.types["QuantityPriceBreakSortKeys"] = QuantityPriceBreakSortKeys;
Types.types["QuantityPricingByVariantUpdateInput"] = QuantityPricingByVariantUpdateInput;
Types.types["QuantityPricingByVariantUpdatePayload"] = QuantityPricingByVariantUpdatePayload;
Types.types["QuantityPricingByVariantUserError"] = QuantityPricingByVariantUserError;
Types.types["QuantityPricingByVariantUserErrorCode"] = QuantityPricingByVariantUserErrorCode;
Types.types["QuantityRule"] = QuantityRule;
Types.types["QuantityRuleConnection"] = QuantityRuleConnection;
Types.types["QuantityRuleEdge"] = QuantityRuleEdge;
Types.types["QuantityRuleInput"] = QuantityRuleInput;
Types.types["QuantityRuleOriginType"] = QuantityRuleOriginType;
Types.types["QuantityRuleUserError"] = QuantityRuleUserError;
Types.types["QuantityRuleUserErrorCode"] = QuantityRuleUserErrorCode;
Types.types["QuantityRulesAddPayload"] = QuantityRulesAddPayload;
Types.types["QuantityRulesDeletePayload"] = QuantityRulesDeletePayload;
Types.types["QueryRoot"] = QueryRoot;
Types.types["Refund"] = Refund;
Types.types["RefundAgreement"] = RefundAgreement;
Types.types["RefundConnection"] = RefundConnection;
Types.types["RefundCreatePayload"] = RefundCreatePayload;
Types.types["RefundDuty"] = RefundDuty;
Types.types["RefundDutyInput"] = RefundDutyInput;
Types.types["RefundDutyRefundType"] = RefundDutyRefundType;
Types.types["RefundEdge"] = RefundEdge;
Types.types["RefundInput"] = RefundInput;
Types.types["RefundLineItem"] = RefundLineItem;
Types.types["RefundLineItemConnection"] = RefundLineItemConnection;
Types.types["RefundLineItemEdge"] = RefundLineItemEdge;
Types.types["RefundLineItemInput"] = RefundLineItemInput;
Types.types["RefundLineItemRestockType"] = RefundLineItemRestockType;
Types.types["RefundShippingInput"] = RefundShippingInput;
Types.types["RefundShippingLine"] = RefundShippingLine;
Types.types["RefundShippingLineConnection"] = RefundShippingLineConnection;
Types.types["RefundShippingLineEdge"] = RefundShippingLineEdge;
Types.types["RemoteAuthorizeNetCustomerPaymentProfileInput"] = RemoteAuthorizeNetCustomerPaymentProfileInput;
Types.types["RemoteBraintreePaymentMethodInput"] = RemoteBraintreePaymentMethodInput;
Types.types["RemoteStripePaymentMethodInput"] = RemoteStripePaymentMethodInput;
Types.types["ResourceAlert"] = ResourceAlert;
Types.types["ResourceAlertAction"] = ResourceAlertAction;
Types.types["ResourceAlertIcon"] = ResourceAlertIcon;
Types.types["ResourceAlertSeverity"] = ResourceAlertSeverity;
Types.types["ResourceFeedback"] = ResourceFeedback;
Types.types["ResourceFeedbackCreateInput"] = ResourceFeedbackCreateInput;
Types.types["ResourceFeedbackState"] = ResourceFeedbackState;
Types.types["ResourceOperation"] = ResourceOperation;
Types.types["ResourceOperationStatus"] = ResourceOperationStatus;
Types.types["ResourcePublication"] = ResourcePublication;
Types.types["ResourcePublicationConnection"] = ResourcePublicationConnection;
Types.types["ResourcePublicationEdge"] = ResourcePublicationEdge;
Types.types["ResourcePublicationV2"] = ResourcePublicationV2;
Types.types["ResourcePublicationV2Connection"] = ResourcePublicationV2Connection;
Types.types["ResourcePublicationV2Edge"] = ResourcePublicationV2Edge;
Types.types["RestockingFee"] = RestockingFee;
Types.types["Return"] = Return;
Types.types["ReturnAgreement"] = ReturnAgreement;
Types.types["ReturnApproveRequestInput"] = ReturnApproveRequestInput;
Types.types["ReturnApproveRequestPayload"] = ReturnApproveRequestPayload;
Types.types["ReturnCancelPayload"] = ReturnCancelPayload;
Types.types["ReturnClosePayload"] = ReturnClosePayload;
Types.types["ReturnConnection"] = ReturnConnection;
Types.types["ReturnCreatePayload"] = ReturnCreatePayload;
Types.types["ReturnDecline"] = ReturnDecline;
Types.types["ReturnDeclineReason"] = ReturnDeclineReason;
Types.types["ReturnDeclineRequestInput"] = ReturnDeclineRequestInput;
Types.types["ReturnDeclineRequestPayload"] = ReturnDeclineRequestPayload;
Types.types["ReturnEdge"] = ReturnEdge;
Types.types["ReturnErrorCode"] = ReturnErrorCode;
Types.types["ReturnInput"] = ReturnInput;
Types.types["ReturnLineItem"] = ReturnLineItem;
Types.types["ReturnLineItemConnection"] = ReturnLineItemConnection;
Types.types["ReturnLineItemEdge"] = ReturnLineItemEdge;
Types.types["ReturnLineItemInput"] = ReturnLineItemInput;
Types.types["ReturnReason"] = ReturnReason;
Types.types["ReturnRefundInput"] = ReturnRefundInput;
Types.types["ReturnRefundLineItemInput"] = ReturnRefundLineItemInput;
Types.types["ReturnRefundOrderTransactionInput"] = ReturnRefundOrderTransactionInput;
Types.types["ReturnRefundPayload"] = ReturnRefundPayload;
Types.types["ReturnReopenPayload"] = ReturnReopenPayload;
Types.types["ReturnRequestInput"] = ReturnRequestInput;
Types.types["ReturnRequestLineItemInput"] = ReturnRequestLineItemInput;
Types.types["ReturnRequestPayload"] = ReturnRequestPayload;
Types.types["ReturnShippingFee"] = ReturnShippingFee;
Types.types["ReturnStatus"] = ReturnStatus;
Types.types["ReturnUserError"] = ReturnUserError;
Types.types["ReturnableFulfillment"] = ReturnableFulfillment;
Types.types["ReturnableFulfillmentConnection"] = ReturnableFulfillmentConnection;
Types.types["ReturnableFulfillmentEdge"] = ReturnableFulfillmentEdge;
Types.types["ReturnableFulfillmentLineItem"] = ReturnableFulfillmentLineItem;
Types.types["ReturnableFulfillmentLineItemConnection"] = ReturnableFulfillmentLineItemConnection;
Types.types["ReturnableFulfillmentLineItemEdge"] = ReturnableFulfillmentLineItemEdge;
Types.types["ReverseDelivery"] = ReverseDelivery;
Types.types["ReverseDeliveryConnection"] = ReverseDeliveryConnection;
Types.types["ReverseDeliveryCreateWithShippingPayload"] = ReverseDeliveryCreateWithShippingPayload;
Types.types["ReverseDeliveryDeliverable"] = ReverseDeliveryDeliverable;
Types.types["ReverseDeliveryDisposeInput"] = ReverseDeliveryDisposeInput;
Types.types["ReverseDeliveryDisposePayload"] = ReverseDeliveryDisposePayload;
Types.types["ReverseDeliveryEdge"] = ReverseDeliveryEdge;
Types.types["ReverseDeliveryLabelInput"] = ReverseDeliveryLabelInput;
Types.types["ReverseDeliveryLabelV2"] = ReverseDeliveryLabelV2;
Types.types["ReverseDeliveryLineItem"] = ReverseDeliveryLineItem;
Types.types["ReverseDeliveryLineItemConnection"] = ReverseDeliveryLineItemConnection;
Types.types["ReverseDeliveryLineItemEdge"] = ReverseDeliveryLineItemEdge;
Types.types["ReverseDeliveryLineItemInput"] = ReverseDeliveryLineItemInput;
Types.types["ReverseDeliveryShippingDeliverable"] = ReverseDeliveryShippingDeliverable;
Types.types["ReverseDeliveryShippingUpdatePayload"] = ReverseDeliveryShippingUpdatePayload;
Types.types["ReverseDeliveryTrackingInput"] = ReverseDeliveryTrackingInput;
Types.types["ReverseDeliveryTrackingV2"] = ReverseDeliveryTrackingV2;
Types.types["ReverseFulfillmentOrder"] = ReverseFulfillmentOrder;
Types.types["ReverseFulfillmentOrderConnection"] = ReverseFulfillmentOrderConnection;
Types.types["ReverseFulfillmentOrderDisposeInput"] = ReverseFulfillmentOrderDisposeInput;
Types.types["ReverseFulfillmentOrderDisposePayload"] = ReverseFulfillmentOrderDisposePayload;
Types.types["ReverseFulfillmentOrderDisposition"] = ReverseFulfillmentOrderDisposition;
Types.types["ReverseFulfillmentOrderDispositionType"] = ReverseFulfillmentOrderDispositionType;
Types.types["ReverseFulfillmentOrderEdge"] = ReverseFulfillmentOrderEdge;
Types.types["ReverseFulfillmentOrderLineItem"] = ReverseFulfillmentOrderLineItem;
Types.types["ReverseFulfillmentOrderLineItemConnection"] = ReverseFulfillmentOrderLineItemConnection;
Types.types["ReverseFulfillmentOrderLineItemEdge"] = ReverseFulfillmentOrderLineItemEdge;
Types.types["ReverseFulfillmentOrderStatus"] = ReverseFulfillmentOrderStatus;
Types.types["ReverseFulfillmentOrderThirdPartyConfirmation"] = ReverseFulfillmentOrderThirdPartyConfirmation;
Types.types["ReverseFulfillmentOrderThirdPartyConfirmationStatus"] = ReverseFulfillmentOrderThirdPartyConfirmationStatus;
Types.types["RiskAssessmentResult"] = RiskAssessmentResult;
Types.types["RiskFact"] = RiskFact;
Types.types["RiskFactSentiment"] = RiskFactSentiment;
Types.types["RowCount"] = RowCount;
Types.types["SEO"] = SEO;
Types.types["SEOInput"] = SEOInput;
Types.types["Sale"] = Sale;
Types.types["SaleActionType"] = SaleActionType;
Types.types["SaleAdditionalFee"] = SaleAdditionalFee;
Types.types["SaleConnection"] = SaleConnection;
Types.types["SaleEdge"] = SaleEdge;
Types.types["SaleLineType"] = SaleLineType;
Types.types["SaleTax"] = SaleTax;
Types.types["SalesAgreement"] = SalesAgreement;
Types.types["SalesAgreementConnection"] = SalesAgreementConnection;
Types.types["SalesAgreementEdge"] = SalesAgreementEdge;
Types.types["SavedSearch"] = SavedSearch;
Types.types["SavedSearchConnection"] = SavedSearchConnection;
Types.types["SavedSearchCreateInput"] = SavedSearchCreateInput;
Types.types["SavedSearchCreatePayload"] = SavedSearchCreatePayload;
Types.types["SavedSearchDeleteInput"] = SavedSearchDeleteInput;
Types.types["SavedSearchDeletePayload"] = SavedSearchDeletePayload;
Types.types["SavedSearchEdge"] = SavedSearchEdge;
Types.types["SavedSearchUpdateInput"] = SavedSearchUpdateInput;
Types.types["SavedSearchUpdatePayload"] = SavedSearchUpdatePayload;
Types.types["ScheduledChangeSortKeys"] = ScheduledChangeSortKeys;
Types.types["ScriptDiscountApplication"] = ScriptDiscountApplication;
Types.types["ScriptTag"] = ScriptTag;
Types.types["ScriptTagConnection"] = ScriptTagConnection;
Types.types["ScriptTagCreatePayload"] = ScriptTagCreatePayload;
Types.types["ScriptTagDeletePayload"] = ScriptTagDeletePayload;
Types.types["ScriptTagDisplayScope"] = ScriptTagDisplayScope;
Types.types["ScriptTagEdge"] = ScriptTagEdge;
Types.types["ScriptTagInput"] = ScriptTagInput;
Types.types["ScriptTagUpdatePayload"] = ScriptTagUpdatePayload;
Types.types["SearchFilter"] = SearchFilter;
Types.types["SearchFilterOptions"] = SearchFilterOptions;
Types.types["SearchResult"] = SearchResult;
Types.types["SearchResultConnection"] = SearchResultConnection;
Types.types["SearchResultEdge"] = SearchResultEdge;
Types.types["SearchResultType"] = SearchResultType;
Types.types["Segment"] = Segment;
Types.types["SegmentAssociationFilter"] = SegmentAssociationFilter;
Types.types["SegmentAttributeStatistics"] = SegmentAttributeStatistics;
Types.types["SegmentBooleanFilter"] = SegmentBooleanFilter;
Types.types["SegmentConnection"] = SegmentConnection;
Types.types["SegmentCreatePayload"] = SegmentCreatePayload;
Types.types["SegmentDateFilter"] = SegmentDateFilter;
Types.types["SegmentDeletePayload"] = SegmentDeletePayload;
Types.types["SegmentEdge"] = SegmentEdge;
Types.types["SegmentEnumFilter"] = SegmentEnumFilter;
Types.types["SegmentEventFilter"] = SegmentEventFilter;
Types.types["SegmentEventFilterParameter"] = SegmentEventFilterParameter;
Types.types["SegmentFilter"] = SegmentFilter;
Types.types["SegmentFilterConnection"] = SegmentFilterConnection;
Types.types["SegmentFilterEdge"] = SegmentFilterEdge;
Types.types["SegmentFloatFilter"] = SegmentFloatFilter;
Types.types["SegmentIntegerFilter"] = SegmentIntegerFilter;
Types.types["SegmentMembership"] = SegmentMembership;
Types.types["SegmentMembershipResponse"] = SegmentMembershipResponse;
Types.types["SegmentMigration"] = SegmentMigration;
Types.types["SegmentMigrationConnection"] = SegmentMigrationConnection;
Types.types["SegmentMigrationEdge"] = SegmentMigrationEdge;
Types.types["SegmentSortKeys"] = SegmentSortKeys;
Types.types["SegmentStatistics"] = SegmentStatistics;
Types.types["SegmentStringFilter"] = SegmentStringFilter;
Types.types["SegmentUpdatePayload"] = SegmentUpdatePayload;
Types.types["SegmentValue"] = SegmentValue;
Types.types["SegmentValueConnection"] = SegmentValueConnection;
Types.types["SegmentValueEdge"] = SegmentValueEdge;
Types.types["SelectedOption"] = SelectedOption;
Types.types["SellingPlan"] = SellingPlan;
Types.types["SellingPlanAnchor"] = SellingPlanAnchor;
Types.types["SellingPlanAnchorInput"] = SellingPlanAnchorInput;
Types.types["SellingPlanAnchorType"] = SellingPlanAnchorType;
Types.types["SellingPlanBillingPolicy"] = SellingPlanBillingPolicy;
Types.types["SellingPlanBillingPolicyInput"] = SellingPlanBillingPolicyInput;
Types.types["SellingPlanCategory"] = SellingPlanCategory;
Types.types["SellingPlanCheckoutCharge"] = SellingPlanCheckoutCharge;
Types.types["SellingPlanCheckoutChargeInput"] = SellingPlanCheckoutChargeInput;
Types.types["SellingPlanCheckoutChargePercentageValue"] = SellingPlanCheckoutChargePercentageValue;
Types.types["SellingPlanCheckoutChargeType"] = SellingPlanCheckoutChargeType;
Types.types["SellingPlanCheckoutChargeValue"] = SellingPlanCheckoutChargeValue;
Types.types["SellingPlanCheckoutChargeValueInput"] = SellingPlanCheckoutChargeValueInput;
Types.types["SellingPlanConnection"] = SellingPlanConnection;
Types.types["SellingPlanDeliveryPolicy"] = SellingPlanDeliveryPolicy;
Types.types["SellingPlanDeliveryPolicyInput"] = SellingPlanDeliveryPolicyInput;
Types.types["SellingPlanEdge"] = SellingPlanEdge;
Types.types["SellingPlanFixedBillingPolicy"] = SellingPlanFixedBillingPolicy;
Types.types["SellingPlanFixedBillingPolicyInput"] = SellingPlanFixedBillingPolicyInput;
Types.types["SellingPlanFixedDeliveryPolicy"] = SellingPlanFixedDeliveryPolicy;
Types.types["SellingPlanFixedDeliveryPolicyInput"] = SellingPlanFixedDeliveryPolicyInput;
Types.types["SellingPlanFixedDeliveryPolicyIntent"] = SellingPlanFixedDeliveryPolicyIntent;
Types.types["SellingPlanFixedDeliveryPolicyPreAnchorBehavior"] = SellingPlanFixedDeliveryPolicyPreAnchorBehavior;
Types.types["SellingPlanFixedPricingPolicy"] = SellingPlanFixedPricingPolicy;
Types.types["SellingPlanFixedPricingPolicyInput"] = SellingPlanFixedPricingPolicyInput;
Types.types["SellingPlanFulfillmentTrigger"] = SellingPlanFulfillmentTrigger;
Types.types["SellingPlanGroup"] = SellingPlanGroup;
Types.types["SellingPlanGroupAddProductVariantsPayload"] = SellingPlanGroupAddProductVariantsPayload;
Types.types["SellingPlanGroupAddProductsPayload"] = SellingPlanGroupAddProductsPayload;
Types.types["SellingPlanGroupConnection"] = SellingPlanGroupConnection;
Types.types["SellingPlanGroupCreatePayload"] = SellingPlanGroupCreatePayload;
Types.types["SellingPlanGroupDeletePayload"] = SellingPlanGroupDeletePayload;
Types.types["SellingPlanGroupEdge"] = SellingPlanGroupEdge;
Types.types["SellingPlanGroupInput"] = SellingPlanGroupInput;
Types.types["SellingPlanGroupRemoveProductVariantsPayload"] = SellingPlanGroupRemoveProductVariantsPayload;
Types.types["SellingPlanGroupRemoveProductsPayload"] = SellingPlanGroupRemoveProductsPayload;
Types.types["SellingPlanGroupResourceInput"] = SellingPlanGroupResourceInput;
Types.types["SellingPlanGroupSortKeys"] = SellingPlanGroupSortKeys;
Types.types["SellingPlanGroupUpdatePayload"] = SellingPlanGroupUpdatePayload;
Types.types["SellingPlanGroupUserError"] = SellingPlanGroupUserError;
Types.types["SellingPlanGroupUserErrorCode"] = SellingPlanGroupUserErrorCode;
Types.types["SellingPlanInput"] = SellingPlanInput;
Types.types["SellingPlanInterval"] = SellingPlanInterval;
Types.types["SellingPlanInventoryPolicy"] = SellingPlanInventoryPolicy;
Types.types["SellingPlanInventoryPolicyInput"] = SellingPlanInventoryPolicyInput;
Types.types["SellingPlanPricingPolicy"] = SellingPlanPricingPolicy;
Types.types["SellingPlanPricingPolicyAdjustmentType"] = SellingPlanPricingPolicyAdjustmentType;
Types.types["SellingPlanPricingPolicyAdjustmentValue"] = SellingPlanPricingPolicyAdjustmentValue;
Types.types["SellingPlanPricingPolicyBase"] = SellingPlanPricingPolicyBase;
Types.types["SellingPlanPricingPolicyInput"] = SellingPlanPricingPolicyInput;
Types.types["SellingPlanPricingPolicyPercentageValue"] = SellingPlanPricingPolicyPercentageValue;
Types.types["SellingPlanPricingPolicyValueInput"] = SellingPlanPricingPolicyValueInput;
Types.types["SellingPlanRecurringBillingPolicy"] = SellingPlanRecurringBillingPolicy;
Types.types["SellingPlanRecurringBillingPolicyInput"] = SellingPlanRecurringBillingPolicyInput;
Types.types["SellingPlanRecurringDeliveryPolicy"] = SellingPlanRecurringDeliveryPolicy;
Types.types["SellingPlanRecurringDeliveryPolicyInput"] = SellingPlanRecurringDeliveryPolicyInput;
Types.types["SellingPlanRecurringDeliveryPolicyIntent"] = SellingPlanRecurringDeliveryPolicyIntent;
Types.types["SellingPlanRecurringDeliveryPolicyPreAnchorBehavior"] = SellingPlanRecurringDeliveryPolicyPreAnchorBehavior;
Types.types["SellingPlanRecurringPricingPolicy"] = SellingPlanRecurringPricingPolicy;
Types.types["SellingPlanRecurringPricingPolicyInput"] = SellingPlanRecurringPricingPolicyInput;
Types.types["SellingPlanRemainingBalanceChargeTrigger"] = SellingPlanRemainingBalanceChargeTrigger;
Types.types["SellingPlanReserve"] = SellingPlanReserve;
Types.types["ServerPixel"] = ServerPixel;
Types.types["ServerPixelCreatePayload"] = ServerPixelCreatePayload;
Types.types["ServerPixelDeletePayload"] = ServerPixelDeletePayload;
Types.types["ServerPixelStatus"] = ServerPixelStatus;
Types.types["ShippingDiscountClass"] = ShippingDiscountClass;
Types.types["ShippingLabel"] = ShippingLabel;
Types.types["ShippingLine"] = ShippingLine;
Types.types["ShippingLineConnection"] = ShippingLineConnection;
Types.types["ShippingLineEdge"] = ShippingLineEdge;
Types.types["ShippingLineInput"] = ShippingLineInput;
Types.types["ShippingLineSale"] = ShippingLineSale;
Types.types["ShippingMethod"] = ShippingMethod;
Types.types["ShippingPackageDeletePayload"] = ShippingPackageDeletePayload;
Types.types["ShippingPackageMakeDefaultPayload"] = ShippingPackageMakeDefaultPayload;
Types.types["ShippingPackageType"] = ShippingPackageType;
Types.types["ShippingPackageUpdatePayload"] = ShippingPackageUpdatePayload;
Types.types["ShippingRate"] = ShippingRate;
Types.types["ShippingRefund"] = ShippingRefund;
Types.types["ShippingRefundInput"] = ShippingRefundInput;
Types.types["Shop"] = Shop;
Types.types["ShopAddress"] = ShopAddress;
Types.types["ShopAlert"] = ShopAlert;
Types.types["ShopAlertAction"] = ShopAlertAction;
Types.types["ShopBillingPreferences"] = ShopBillingPreferences;
Types.types["ShopBranding"] = ShopBranding;
Types.types["ShopCustomerAccountsSetting"] = ShopCustomerAccountsSetting;
Types.types["ShopFeatures"] = ShopFeatures;
Types.types["ShopLocale"] = ShopLocale;
Types.types["ShopLocaleDisablePayload"] = ShopLocaleDisablePayload;
Types.types["ShopLocaleEnablePayload"] = ShopLocaleEnablePayload;
Types.types["ShopLocaleInput"] = ShopLocaleInput;
Types.types["ShopLocaleUpdatePayload"] = ShopLocaleUpdatePayload;
Types.types["ShopPayInstallmentsPaymentDetails"] = ShopPayInstallmentsPaymentDetails;
Types.types["ShopPlan"] = ShopPlan;
Types.types["ShopPolicy"] = ShopPolicy;
Types.types["ShopPolicyErrorCode"] = ShopPolicyErrorCode;
Types.types["ShopPolicyInput"] = ShopPolicyInput;
Types.types["ShopPolicyType"] = ShopPolicyType;
Types.types["ShopPolicyUpdatePayload"] = ShopPolicyUpdatePayload;
Types.types["ShopPolicyUserError"] = ShopPolicyUserError;
Types.types["ShopResourceFeedbackCreatePayload"] = ShopResourceFeedbackCreatePayload;
Types.types["ShopResourceFeedbackCreateUserError"] = ShopResourceFeedbackCreateUserError;
Types.types["ShopResourceFeedbackCreateUserErrorCode"] = ShopResourceFeedbackCreateUserErrorCode;
Types.types["ShopResourceLimits"] = ShopResourceLimits;
Types.types["ShopTagSort"] = ShopTagSort;
Types.types["ShopifyFunction"] = ShopifyFunction;
Types.types["ShopifyFunctionConnection"] = ShopifyFunctionConnection;
Types.types["ShopifyFunctionEdge"] = ShopifyFunctionEdge;
Types.types["ShopifyPaymentsAccount"] = ShopifyPaymentsAccount;
Types.types["ShopifyPaymentsAdjustmentOrder"] = ShopifyPaymentsAdjustmentOrder;
Types.types["ShopifyPaymentsBalanceTransaction"] = ShopifyPaymentsBalanceTransaction;
Types.types["ShopifyPaymentsBalanceTransactionConnection"] = ShopifyPaymentsBalanceTransactionConnection;
Types.types["ShopifyPaymentsBalanceTransactionEdge"] = ShopifyPaymentsBalanceTransactionEdge;
Types.types["ShopifyPaymentsBankAccount"] = ShopifyPaymentsBankAccount;
Types.types["ShopifyPaymentsBankAccountConnection"] = ShopifyPaymentsBankAccountConnection;
Types.types["ShopifyPaymentsBankAccountEdge"] = ShopifyPaymentsBankAccountEdge;
Types.types["ShopifyPaymentsBankAccountStatus"] = ShopifyPaymentsBankAccountStatus;
Types.types["ShopifyPaymentsChargeStatementDescriptor"] = ShopifyPaymentsChargeStatementDescriptor;
Types.types["ShopifyPaymentsDefaultChargeStatementDescriptor"] = ShopifyPaymentsDefaultChargeStatementDescriptor;
Types.types["ShopifyPaymentsDispute"] = ShopifyPaymentsDispute;
Types.types["ShopifyPaymentsDisputeConnection"] = ShopifyPaymentsDisputeConnection;
Types.types["ShopifyPaymentsDisputeEdge"] = ShopifyPaymentsDisputeEdge;
Types.types["ShopifyPaymentsDisputeEvidence"] = ShopifyPaymentsDisputeEvidence;
Types.types["ShopifyPaymentsDisputeEvidenceFileType"] = ShopifyPaymentsDisputeEvidenceFileType;
Types.types["ShopifyPaymentsDisputeEvidenceUpdateInput"] = ShopifyPaymentsDisputeEvidenceUpdateInput;
Types.types["ShopifyPaymentsDisputeFileUpload"] = ShopifyPaymentsDisputeFileUpload;
Types.types["ShopifyPaymentsDisputeFileUploadUpdateInput"] = ShopifyPaymentsDisputeFileUploadUpdateInput;
Types.types["ShopifyPaymentsDisputeFulfillment"] = ShopifyPaymentsDisputeFulfillment;
Types.types["ShopifyPaymentsDisputeReason"] = ShopifyPaymentsDisputeReason;
Types.types["ShopifyPaymentsDisputeReasonDetails"] = ShopifyPaymentsDisputeReasonDetails;
Types.types["ShopifyPaymentsExtendedAuthorization"] = ShopifyPaymentsExtendedAuthorization;
Types.types["ShopifyPaymentsFraudSettings"] = ShopifyPaymentsFraudSettings;
Types.types["ShopifyPaymentsJpChargeStatementDescriptor"] = ShopifyPaymentsJpChargeStatementDescriptor;
Types.types["ShopifyPaymentsNotificationSettings"] = ShopifyPaymentsNotificationSettings;
Types.types["ShopifyPaymentsPayout"] = ShopifyPaymentsPayout;
Types.types["ShopifyPaymentsPayoutConnection"] = ShopifyPaymentsPayoutConnection;
Types.types["ShopifyPaymentsPayoutEdge"] = ShopifyPaymentsPayoutEdge;
Types.types["ShopifyPaymentsPayoutInterval"] = ShopifyPaymentsPayoutInterval;
Types.types["ShopifyPaymentsPayoutSchedule"] = ShopifyPaymentsPayoutSchedule;
Types.types["ShopifyPaymentsPayoutStatus"] = ShopifyPaymentsPayoutStatus;
Types.types["ShopifyPaymentsPayoutSummary"] = ShopifyPaymentsPayoutSummary;
Types.types["ShopifyPaymentsPayoutTransactionType"] = ShopifyPaymentsPayoutTransactionType;
Types.types["ShopifyPaymentsRefundSet"] = ShopifyPaymentsRefundSet;
Types.types["ShopifyPaymentsTransactionSet"] = ShopifyPaymentsTransactionSet;
Types.types["ShopifyPaymentsVerification"] = ShopifyPaymentsVerification;
Types.types["ShopifyPaymentsVerificationDocument"] = ShopifyPaymentsVerificationDocument;
Types.types["ShopifyPaymentsVerificationDocumentType"] = ShopifyPaymentsVerificationDocumentType;
Types.types["ShopifyPaymentsVerificationStatus"] = ShopifyPaymentsVerificationStatus;
Types.types["ShopifyPaymentsVerificationSubject"] = ShopifyPaymentsVerificationSubject;
Types.types["ShopifyProtectEligibilityStatus"] = ShopifyProtectEligibilityStatus;
Types.types["ShopifyProtectOrderEligibility"] = ShopifyProtectOrderEligibility;
Types.types["ShopifyProtectOrderSummary"] = ShopifyProtectOrderSummary;
Types.types["ShopifyProtectStatus"] = ShopifyProtectStatus;
Types.types["ShopifyqlResponse"] = ShopifyqlResponse;
Types.types["StaffMember"] = StaffMember;
Types.types["StaffMemberConnection"] = StaffMemberConnection;
Types.types["StaffMemberDefaultImage"] = StaffMemberDefaultImage;
Types.types["StaffMemberEdge"] = StaffMemberEdge;
Types.types["StaffMemberPermission"] = StaffMemberPermission;
Types.types["StaffMemberPrivateData"] = StaffMemberPrivateData;
Types.types["StageImageInput"] = StageImageInput;
Types.types["StagedMediaUploadTarget"] = StagedMediaUploadTarget;
Types.types["StagedUploadHttpMethodType"] = StagedUploadHttpMethodType;
Types.types["StagedUploadInput"] = StagedUploadInput;
Types.types["StagedUploadParameter"] = StagedUploadParameter;
Types.types["StagedUploadTarget"] = StagedUploadTarget;
Types.types["StagedUploadTargetGenerateInput"] = StagedUploadTargetGenerateInput;
Types.types["StagedUploadTargetGeneratePayload"] = StagedUploadTargetGeneratePayload;
Types.types["StagedUploadTargetGenerateUploadResource"] = StagedUploadTargetGenerateUploadResource;
Types.types["StagedUploadTargetsGeneratePayload"] = StagedUploadTargetsGeneratePayload;
Types.types["StagedUploadsCreatePayload"] = StagedUploadsCreatePayload;
Types.types["StandardMetafieldDefinitionEnablePayload"] = StandardMetafieldDefinitionEnablePayload;
Types.types["StandardMetafieldDefinitionEnableUserError"] = StandardMetafieldDefinitionEnableUserError;
Types.types["StandardMetafieldDefinitionEnableUserErrorCode"] = StandardMetafieldDefinitionEnableUserErrorCode;
Types.types["StandardMetafieldDefinitionTemplate"] = StandardMetafieldDefinitionTemplate;
Types.types["StandardMetafieldDefinitionTemplateConnection"] = StandardMetafieldDefinitionTemplateConnection;
Types.types["StandardMetafieldDefinitionTemplateEdge"] = StandardMetafieldDefinitionTemplateEdge;
Types.types["StandardMetaobjectDefinitionEnablePayload"] = StandardMetaobjectDefinitionEnablePayload;
Types.types["StandardizedProductType"] = StandardizedProductType;
Types.types["StandardizedProductTypeInput"] = StandardizedProductTypeInput;
Types.types["StorefrontAccessToken"] = StorefrontAccessToken;
Types.types["StorefrontAccessTokenConnection"] = StorefrontAccessTokenConnection;
Types.types["StorefrontAccessTokenCreatePayload"] = StorefrontAccessTokenCreatePayload;
Types.types["StorefrontAccessTokenDeleteInput"] = StorefrontAccessTokenDeleteInput;
Types.types["StorefrontAccessTokenDeletePayload"] = StorefrontAccessTokenDeletePayload;
Types.types["StorefrontAccessTokenEdge"] = StorefrontAccessTokenEdge;
Types.types["StorefrontAccessTokenInput"] = StorefrontAccessTokenInput;
Types.types["StorefrontID"] = StorefrontID;
Types.types["String"] = String;
Types.types["StringConnection"] = StringConnection;
Types.types["StringEdge"] = StringEdge;
Types.types["SubscriptionAppliedCodeDiscount"] = SubscriptionAppliedCodeDiscount;
Types.types["SubscriptionAtomicLineInput"] = SubscriptionAtomicLineInput;
Types.types["SubscriptionAtomicManualDiscountInput"] = SubscriptionAtomicManualDiscountInput;
Types.types["SubscriptionBillingAttempt"] = SubscriptionBillingAttempt;
Types.types["SubscriptionBillingAttemptConnection"] = SubscriptionBillingAttemptConnection;
Types.types["SubscriptionBillingAttemptCreatePayload"] = SubscriptionBillingAttemptCreatePayload;
Types.types["SubscriptionBillingAttemptEdge"] = SubscriptionBillingAttemptEdge;
Types.types["SubscriptionBillingAttemptErrorCode"] = SubscriptionBillingAttemptErrorCode;
Types.types["SubscriptionBillingAttemptInput"] = SubscriptionBillingAttemptInput;
Types.types["SubscriptionBillingAttemptsSortKeys"] = SubscriptionBillingAttemptsSortKeys;
Types.types["SubscriptionBillingCycle"] = SubscriptionBillingCycle;
Types.types["SubscriptionBillingCycleBillingCycleStatus"] = SubscriptionBillingCycleBillingCycleStatus;
Types.types["SubscriptionBillingCycleConnection"] = SubscriptionBillingCycleConnection;
Types.types["SubscriptionBillingCycleContractDraftCommitPayload"] = SubscriptionBillingCycleContractDraftCommitPayload;
Types.types["SubscriptionBillingCycleContractDraftConcatenatePayload"] = SubscriptionBillingCycleContractDraftConcatenatePayload;
Types.types["SubscriptionBillingCycleContractEditPayload"] = SubscriptionBillingCycleContractEditPayload;
Types.types["SubscriptionBillingCycleEdge"] = SubscriptionBillingCycleEdge;
Types.types["SubscriptionBillingCycleEditDeletePayload"] = SubscriptionBillingCycleEditDeletePayload;
Types.types["SubscriptionBillingCycleEditedContract"] = SubscriptionBillingCycleEditedContract;
Types.types["SubscriptionBillingCycleEditsDeletePayload"] = SubscriptionBillingCycleEditsDeletePayload;
Types.types["SubscriptionBillingCycleErrorCode"] = SubscriptionBillingCycleErrorCode;
Types.types["SubscriptionBillingCycleInput"] = SubscriptionBillingCycleInput;
Types.types["SubscriptionBillingCycleScheduleEditInput"] = SubscriptionBillingCycleScheduleEditInput;
Types.types["SubscriptionBillingCycleScheduleEditInputScheduleEditReason"] = SubscriptionBillingCycleScheduleEditInputScheduleEditReason;
Types.types["SubscriptionBillingCycleScheduleEditPayload"] = SubscriptionBillingCycleScheduleEditPayload;
Types.types["SubscriptionBillingCycleSelector"] = SubscriptionBillingCycleSelector;
Types.types["SubscriptionBillingCycleSkipPayload"] = SubscriptionBillingCycleSkipPayload;
Types.types["SubscriptionBillingCycleSkipUserError"] = SubscriptionBillingCycleSkipUserError;
Types.types["SubscriptionBillingCycleSkipUserErrorCode"] = SubscriptionBillingCycleSkipUserErrorCode;
Types.types["SubscriptionBillingCycleUnskipPayload"] = SubscriptionBillingCycleUnskipPayload;
Types.types["SubscriptionBillingCycleUnskipUserError"] = SubscriptionBillingCycleUnskipUserError;
Types.types["SubscriptionBillingCycleUnskipUserErrorCode"] = SubscriptionBillingCycleUnskipUserErrorCode;
Types.types["SubscriptionBillingCycleUserError"] = SubscriptionBillingCycleUserError;
Types.types["SubscriptionBillingCyclesDateRangeSelector"] = SubscriptionBillingCyclesDateRangeSelector;
Types.types["SubscriptionBillingCyclesIndexRangeSelector"] = SubscriptionBillingCyclesIndexRangeSelector;
Types.types["SubscriptionBillingCyclesSortKeys"] = SubscriptionBillingCyclesSortKeys;
Types.types["SubscriptionBillingCyclesTargetSelection"] = SubscriptionBillingCyclesTargetSelection;
Types.types["SubscriptionBillingPolicy"] = SubscriptionBillingPolicy;
Types.types["SubscriptionBillingPolicyInput"] = SubscriptionBillingPolicyInput;
Types.types["SubscriptionContract"] = SubscriptionContract;
Types.types["SubscriptionContractActivatePayload"] = SubscriptionContractActivatePayload;
Types.types["SubscriptionContractAtomicCreateInput"] = SubscriptionContractAtomicCreateInput;
Types.types["SubscriptionContractAtomicCreatePayload"] = SubscriptionContractAtomicCreatePayload;
Types.types["SubscriptionContractBase"] = SubscriptionContractBase;
Types.types["SubscriptionContractCancelPayload"] = SubscriptionContractCancelPayload;
Types.types["SubscriptionContractConnection"] = SubscriptionContractConnection;
Types.types["SubscriptionContractCreateInput"] = SubscriptionContractCreateInput;
Types.types["SubscriptionContractCreatePayload"] = SubscriptionContractCreatePayload;
Types.types["SubscriptionContractEdge"] = SubscriptionContractEdge;
Types.types["SubscriptionContractErrorCode"] = SubscriptionContractErrorCode;
Types.types["SubscriptionContractExpirePayload"] = SubscriptionContractExpirePayload;
Types.types["SubscriptionContractFailPayload"] = SubscriptionContractFailPayload;
Types.types["SubscriptionContractLastPaymentStatus"] = SubscriptionContractLastPaymentStatus;
Types.types["SubscriptionContractPausePayload"] = SubscriptionContractPausePayload;
Types.types["SubscriptionContractProductChangeInput"] = SubscriptionContractProductChangeInput;
Types.types["SubscriptionContractProductChangePayload"] = SubscriptionContractProductChangePayload;
Types.types["SubscriptionContractSetNextBillingDatePayload"] = SubscriptionContractSetNextBillingDatePayload;
Types.types["SubscriptionContractStatusUpdateErrorCode"] = SubscriptionContractStatusUpdateErrorCode;
Types.types["SubscriptionContractStatusUpdateUserError"] = SubscriptionContractStatusUpdateUserError;
Types.types["SubscriptionContractSubscriptionStatus"] = SubscriptionContractSubscriptionStatus;
Types.types["SubscriptionContractUpdatePayload"] = SubscriptionContractUpdatePayload;
Types.types["SubscriptionContractUserError"] = SubscriptionContractUserError;
Types.types["SubscriptionCyclePriceAdjustment"] = SubscriptionCyclePriceAdjustment;
Types.types["SubscriptionDeliveryMethod"] = SubscriptionDeliveryMethod;
Types.types["SubscriptionDeliveryMethodInput"] = SubscriptionDeliveryMethodInput;
Types.types["SubscriptionDeliveryMethodLocalDelivery"] = SubscriptionDeliveryMethodLocalDelivery;
Types.types["SubscriptionDeliveryMethodLocalDeliveryInput"] = SubscriptionDeliveryMethodLocalDeliveryInput;
Types.types["SubscriptionDeliveryMethodLocalDeliveryOption"] = SubscriptionDeliveryMethodLocalDeliveryOption;
Types.types["SubscriptionDeliveryMethodLocalDeliveryOptionInput"] = SubscriptionDeliveryMethodLocalDeliveryOptionInput;
Types.types["SubscriptionDeliveryMethodPickup"] = SubscriptionDeliveryMethodPickup;
Types.types["SubscriptionDeliveryMethodPickupInput"] = SubscriptionDeliveryMethodPickupInput;
Types.types["SubscriptionDeliveryMethodPickupOption"] = SubscriptionDeliveryMethodPickupOption;
Types.types["SubscriptionDeliveryMethodPickupOptionInput"] = SubscriptionDeliveryMethodPickupOptionInput;
Types.types["SubscriptionDeliveryMethodShipping"] = SubscriptionDeliveryMethodShipping;
Types.types["SubscriptionDeliveryMethodShippingInput"] = SubscriptionDeliveryMethodShippingInput;
Types.types["SubscriptionDeliveryMethodShippingOption"] = SubscriptionDeliveryMethodShippingOption;
Types.types["SubscriptionDeliveryMethodShippingOptionInput"] = SubscriptionDeliveryMethodShippingOptionInput;
Types.types["SubscriptionDeliveryOption"] = SubscriptionDeliveryOption;
Types.types["SubscriptionDeliveryOptionResult"] = SubscriptionDeliveryOptionResult;
Types.types["SubscriptionDeliveryOptionResultFailure"] = SubscriptionDeliveryOptionResultFailure;
Types.types["SubscriptionDeliveryOptionResultSuccess"] = SubscriptionDeliveryOptionResultSuccess;
Types.types["SubscriptionDeliveryPolicy"] = SubscriptionDeliveryPolicy;
Types.types["SubscriptionDeliveryPolicyInput"] = SubscriptionDeliveryPolicyInput;
Types.types["SubscriptionDiscount"] = SubscriptionDiscount;
Types.types["SubscriptionDiscountAllocation"] = SubscriptionDiscountAllocation;
Types.types["SubscriptionDiscountConnection"] = SubscriptionDiscountConnection;
Types.types["SubscriptionDiscountEdge"] = SubscriptionDiscountEdge;
Types.types["SubscriptionDiscountEntitledLines"] = SubscriptionDiscountEntitledLines;
Types.types["SubscriptionDiscountFixedAmountValue"] = SubscriptionDiscountFixedAmountValue;
Types.types["SubscriptionDiscountPercentageValue"] = SubscriptionDiscountPercentageValue;
Types.types["SubscriptionDiscountRejectionReason"] = SubscriptionDiscountRejectionReason;
Types.types["SubscriptionDiscountValue"] = SubscriptionDiscountValue;
Types.types["SubscriptionDraft"] = SubscriptionDraft;
Types.types["SubscriptionDraftCommitPayload"] = SubscriptionDraftCommitPayload;
Types.types["SubscriptionDraftDiscountAddPayload"] = SubscriptionDraftDiscountAddPayload;
Types.types["SubscriptionDraftDiscountCodeApplyPayload"] = SubscriptionDraftDiscountCodeApplyPayload;
Types.types["SubscriptionDraftDiscountRemovePayload"] = SubscriptionDraftDiscountRemovePayload;
Types.types["SubscriptionDraftDiscountUpdatePayload"] = SubscriptionDraftDiscountUpdatePayload;
Types.types["SubscriptionDraftErrorCode"] = SubscriptionDraftErrorCode;
Types.types["SubscriptionDraftFreeShippingDiscountAddPayload"] = SubscriptionDraftFreeShippingDiscountAddPayload;
Types.types["SubscriptionDraftFreeShippingDiscountUpdatePayload"] = SubscriptionDraftFreeShippingDiscountUpdatePayload;
Types.types["SubscriptionDraftInput"] = SubscriptionDraftInput;
Types.types["SubscriptionDraftLineAddPayload"] = SubscriptionDraftLineAddPayload;
Types.types["SubscriptionDraftLineRemovePayload"] = SubscriptionDraftLineRemovePayload;
Types.types["SubscriptionDraftLineUpdatePayload"] = SubscriptionDraftLineUpdatePayload;
Types.types["SubscriptionDraftUpdatePayload"] = SubscriptionDraftUpdatePayload;
Types.types["SubscriptionDraftUserError"] = SubscriptionDraftUserError;
Types.types["SubscriptionFreeShippingDiscountInput"] = SubscriptionFreeShippingDiscountInput;
Types.types["SubscriptionLine"] = SubscriptionLine;
Types.types["SubscriptionLineConnection"] = SubscriptionLineConnection;
Types.types["SubscriptionLineEdge"] = SubscriptionLineEdge;
Types.types["SubscriptionLineInput"] = SubscriptionLineInput;
Types.types["SubscriptionLineUpdateInput"] = SubscriptionLineUpdateInput;
Types.types["SubscriptionLocalDeliveryOption"] = SubscriptionLocalDeliveryOption;
Types.types["SubscriptionMailingAddress"] = SubscriptionMailingAddress;
Types.types["SubscriptionManualDiscount"] = SubscriptionManualDiscount;
Types.types["SubscriptionManualDiscountConnection"] = SubscriptionManualDiscountConnection;
Types.types["SubscriptionManualDiscountEdge"] = SubscriptionManualDiscountEdge;
Types.types["SubscriptionManualDiscountEntitledLinesInput"] = SubscriptionManualDiscountEntitledLinesInput;
Types.types["SubscriptionManualDiscountFixedAmountInput"] = SubscriptionManualDiscountFixedAmountInput;
Types.types["SubscriptionManualDiscountInput"] = SubscriptionManualDiscountInput;
Types.types["SubscriptionManualDiscountLinesInput"] = SubscriptionManualDiscountLinesInput;
Types.types["SubscriptionManualDiscountValueInput"] = SubscriptionManualDiscountValueInput;
Types.types["SubscriptionPickupOption"] = SubscriptionPickupOption;
Types.types["SubscriptionPricingPolicy"] = SubscriptionPricingPolicy;
Types.types["SubscriptionPricingPolicyCycleDiscountsInput"] = SubscriptionPricingPolicyCycleDiscountsInput;
Types.types["SubscriptionPricingPolicyInput"] = SubscriptionPricingPolicyInput;
Types.types["SubscriptionShippingOption"] = SubscriptionShippingOption;
Types.types["SubscriptionShippingOptionResult"] = SubscriptionShippingOptionResult;
Types.types["SubscriptionShippingOptionResultFailure"] = SubscriptionShippingOptionResultFailure;
Types.types["SubscriptionShippingOptionResultSuccess"] = SubscriptionShippingOptionResultSuccess;
Types.types["SuggestedOrderTransaction"] = SuggestedOrderTransaction;
Types.types["SuggestedOrderTransactionKind"] = SuggestedOrderTransactionKind;
Types.types["SuggestedRefund"] = SuggestedRefund;
Types.types["SuggestedReturnRefund"] = SuggestedReturnRefund;
Types.types["TableData"] = TableData;
Types.types["TableDataColumn"] = TableDataColumn;
Types.types["TableResponse"] = TableResponse;
Types.types["TagsAddPayload"] = TagsAddPayload;
Types.types["TagsRemovePayload"] = TagsRemovePayload;
Types.types["TaxAppConfiguration"] = TaxAppConfiguration;
Types.types["TaxAppConfigurePayload"] = TaxAppConfigurePayload;
Types.types["TaxAppConfigureUserError"] = TaxAppConfigureUserError;
Types.types["TaxAppConfigureUserErrorCode"] = TaxAppConfigureUserErrorCode;
Types.types["TaxExemption"] = TaxExemption;
Types.types["TaxLine"] = TaxLine;
Types.types["TaxPartnerState"] = TaxPartnerState;
Types.types["Taxonomy"] = Taxonomy;
Types.types["TaxonomyAttribute"] = TaxonomyAttribute;
Types.types["TaxonomyCategory"] = TaxonomyCategory;
Types.types["TaxonomyCategoryAttribute"] = TaxonomyCategoryAttribute;
Types.types["TaxonomyCategoryAttributeConnection"] = TaxonomyCategoryAttributeConnection;
Types.types["TaxonomyCategoryAttributeEdge"] = TaxonomyCategoryAttributeEdge;
Types.types["TaxonomyCategoryConnection"] = TaxonomyCategoryConnection;
Types.types["TaxonomyCategoryEdge"] = TaxonomyCategoryEdge;
Types.types["TaxonomyChoiceListAttribute"] = TaxonomyChoiceListAttribute;
Types.types["TaxonomyMeasurementAttribute"] = TaxonomyMeasurementAttribute;
Types.types["TaxonomyValue"] = TaxonomyValue;
Types.types["TaxonomyValueConnection"] = TaxonomyValueConnection;
Types.types["TaxonomyValueEdge"] = TaxonomyValueEdge;
Types.types["TenderTransaction"] = TenderTransaction;
Types.types["TenderTransactionConnection"] = TenderTransactionConnection;
Types.types["TenderTransactionCreditCardDetails"] = TenderTransactionCreditCardDetails;
Types.types["TenderTransactionDetails"] = TenderTransactionDetails;
Types.types["TenderTransactionEdge"] = TenderTransactionEdge;
Types.types["TipSale"] = TipSale;
Types.types["TransactionFee"] = TransactionFee;
Types.types["TransactionVoidPayload"] = TransactionVoidPayload;
Types.types["TransactionVoidUserError"] = TransactionVoidUserError;
Types.types["TransactionVoidUserErrorCode"] = TransactionVoidUserErrorCode;
Types.types["TranslatableContent"] = TranslatableContent;
Types.types["TranslatableResource"] = TranslatableResource;
Types.types["TranslatableResourceConnection"] = TranslatableResourceConnection;
Types.types["TranslatableResourceEdge"] = TranslatableResourceEdge;
Types.types["TranslatableResourceType"] = TranslatableResourceType;
Types.types["Translation"] = Translation;
Types.types["TranslationErrorCode"] = TranslationErrorCode;
Types.types["TranslationInput"] = TranslationInput;
Types.types["TranslationUserError"] = TranslationUserError;
Types.types["TranslationsRegisterPayload"] = TranslationsRegisterPayload;
Types.types["TranslationsRemovePayload"] = TranslationsRemovePayload;
Types.types["TypedAttribute"] = TypedAttribute;
Types.types["URL"] = URL;
Types.types["UTMInput"] = UTMInput;
Types.types["UTMParameters"] = UTMParameters;
Types.types["UnitSystem"] = UnitSystem;
Types.types["UnknownSale"] = UnknownSale;
Types.types["UnsignedInt64"] = UnsignedInt64;
Types.types["UpdateMediaInput"] = UpdateMediaInput;
Types.types["UrlRedirect"] = UrlRedirect;
Types.types["UrlRedirectBulkDeleteAllPayload"] = UrlRedirectBulkDeleteAllPayload;
Types.types["UrlRedirectBulkDeleteByIdsPayload"] = UrlRedirectBulkDeleteByIdsPayload;
Types.types["UrlRedirectBulkDeleteByIdsUserError"] = UrlRedirectBulkDeleteByIdsUserError;
Types.types["UrlRedirectBulkDeleteByIdsUserErrorCode"] = UrlRedirectBulkDeleteByIdsUserErrorCode;
Types.types["UrlRedirectBulkDeleteBySavedSearchPayload"] = UrlRedirectBulkDeleteBySavedSearchPayload;
Types.types["UrlRedirectBulkDeleteBySavedSearchUserError"] = UrlRedirectBulkDeleteBySavedSearchUserError;
Types.types["UrlRedirectBulkDeleteBySavedSearchUserErrorCode"] = UrlRedirectBulkDeleteBySavedSearchUserErrorCode;
Types.types["UrlRedirectBulkDeleteBySearchPayload"] = UrlRedirectBulkDeleteBySearchPayload;
Types.types["UrlRedirectBulkDeleteBySearchUserError"] = UrlRedirectBulkDeleteBySearchUserError;
Types.types["UrlRedirectBulkDeleteBySearchUserErrorCode"] = UrlRedirectBulkDeleteBySearchUserErrorCode;
Types.types["UrlRedirectConnection"] = UrlRedirectConnection;
Types.types["UrlRedirectCreatePayload"] = UrlRedirectCreatePayload;
Types.types["UrlRedirectDeletePayload"] = UrlRedirectDeletePayload;
Types.types["UrlRedirectEdge"] = UrlRedirectEdge;
Types.types["UrlRedirectErrorCode"] = UrlRedirectErrorCode;
Types.types["UrlRedirectImport"] = UrlRedirectImport;
Types.types["UrlRedirectImportCreatePayload"] = UrlRedirectImportCreatePayload;
Types.types["UrlRedirectImportErrorCode"] = UrlRedirectImportErrorCode;
Types.types["UrlRedirectImportPreview"] = UrlRedirectImportPreview;
Types.types["UrlRedirectImportSubmitPayload"] = UrlRedirectImportSubmitPayload;
Types.types["UrlRedirectImportUserError"] = UrlRedirectImportUserError;
Types.types["UrlRedirectInput"] = UrlRedirectInput;
Types.types["UrlRedirectSortKeys"] = UrlRedirectSortKeys;
Types.types["UrlRedirectUpdatePayload"] = UrlRedirectUpdatePayload;
Types.types["UrlRedirectUserError"] = UrlRedirectUserError;
Types.types["UserError"] = UserError;
Types.types["UtcOffset"] = UtcOffset;
Types.types["Validation"] = Validation;
Types.types["ValidationConnection"] = ValidationConnection;
Types.types["ValidationCreateInput"] = ValidationCreateInput;
Types.types["ValidationCreatePayload"] = ValidationCreatePayload;
Types.types["ValidationDeletePayload"] = ValidationDeletePayload;
Types.types["ValidationEdge"] = ValidationEdge;
Types.types["ValidationSortKeys"] = ValidationSortKeys;
Types.types["ValidationUpdateInput"] = ValidationUpdateInput;
Types.types["ValidationUpdatePayload"] = ValidationUpdatePayload;
Types.types["ValidationUserError"] = ValidationUserError;
Types.types["ValidationUserErrorCode"] = ValidationUserErrorCode;
Types.types["VariantOptionValueInput"] = VariantOptionValueInput;
Types.types["VaultCreditCard"] = VaultCreditCard;
Types.types["VaultPaypalBillingAgreement"] = VaultPaypalBillingAgreement;
Types.types["Vector3"] = Vector3;
Types.types["Video"] = Video;
Types.types["VideoSource"] = VideoSource;
Types.types["VisualizationType"] = VisualizationType;
Types.types["WebPixel"] = WebPixel;
Types.types["WebPixelCreatePayload"] = WebPixelCreatePayload;
Types.types["WebPixelDeletePayload"] = WebPixelDeletePayload;
Types.types["WebPixelInput"] = WebPixelInput;
Types.types["WebPixelUpdatePayload"] = WebPixelUpdatePayload;
Types.types["WebhookEventBridgeEndpoint"] = WebhookEventBridgeEndpoint;
Types.types["WebhookHttpEndpoint"] = WebhookHttpEndpoint;
Types.types["WebhookPubSubEndpoint"] = WebhookPubSubEndpoint;
Types.types["WebhookSubscription"] = WebhookSubscription;
Types.types["WebhookSubscriptionConnection"] = WebhookSubscriptionConnection;
Types.types["WebhookSubscriptionCreatePayload"] = WebhookSubscriptionCreatePayload;
Types.types["WebhookSubscriptionDeletePayload"] = WebhookSubscriptionDeletePayload;
Types.types["WebhookSubscriptionEdge"] = WebhookSubscriptionEdge;
Types.types["WebhookSubscriptionEndpoint"] = WebhookSubscriptionEndpoint;
Types.types["WebhookSubscriptionFormat"] = WebhookSubscriptionFormat;
Types.types["WebhookSubscriptionInput"] = WebhookSubscriptionInput;
Types.types["WebhookSubscriptionSortKeys"] = WebhookSubscriptionSortKeys;
Types.types["WebhookSubscriptionTopic"] = WebhookSubscriptionTopic;
Types.types["WebhookSubscriptionUpdatePayload"] = WebhookSubscriptionUpdatePayload;
Types.types["Weight"] = Weight;
Types.types["WeightInput"] = WeightInput;
Types.types["WeightUnit"] = WeightUnit;
Types.types["__Directive"] = __Directive;
Types.types["__DirectiveLocation"] = __DirectiveLocation;
Types.types["__EnumValue"] = __EnumValue;
Types.types["__Field"] = __Field;
Types.types["__InputValue"] = __InputValue;
Types.types["__Schema"] = __Schema;
Types.types["__Type"] = __Type;
Types.types["__TypeKind"] = __TypeKind;
Types.types["deliveryProfileCreatePayload"] = deliveryProfileCreatePayload;
Types.types["deliveryProfileRemovePayload"] = deliveryProfileRemovePayload;
Types.types["deliveryProfileUpdatePayload"] = deliveryProfileUpdatePayload;
Types.queryType = "QueryRoot";
Types.mutationType = "Mutation";
Types.subscriptionType = null;

function recursivelyFreezeObject(structure) {
  Object.getOwnPropertyNames(structure).forEach(key => {
    const value = structure[key];
    if (value && typeof value === 'object') {
      recursivelyFreezeObject(value);
    }
  });
  Object.freeze(structure);
  return structure;
}

export default recursivelyFreezeObject(Types);