
const FulfillmentService = {
  "name": "FulfillmentService",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "callbackUrl": "URL",
    "fulfillmentOrdersOptIn": "Boolean",
    "handle": "String",
    "id": "ID",
    "inventoryManagement": "Boolean",
    "location": "Location",
    "permitsSkuSharing": "Boolean",
    "productBased": "Boolean",
    "serviceName": "String",
    "shippingMethods": "ShippingMethod",
    "type": "FulfillmentServiceType"
  },
  "implementsNode": false
};
export default FulfillmentService;