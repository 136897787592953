
const OrderRiskSummary = {
  "name": "OrderRiskSummary",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "assessments": "OrderRiskAssessment",
    "recommendation": "OrderRiskRecommendationResult"
  },
  "implementsNode": false
};
export default OrderRiskSummary;