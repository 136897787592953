
const QuantityRulesDeletePayload = {
  "name": "QuantityRulesDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedQuantityRulesVariantIds": "ID",
    "userErrors": "QuantityRuleUserError"
  },
  "implementsNode": false
};
export default QuantityRulesDeletePayload;