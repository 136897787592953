
const DiscountCodeBxgyInput = {
  "name": "DiscountCodeBxgyInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "combinesWith": "DiscountCombinesWithInput",
    "title": "String",
    "startsAt": "DateTime",
    "endsAt": "DateTime",
    "customerBuys": "DiscountCustomerBuysInput",
    "customerGets": "DiscountCustomerGetsInput",
    "customerSelection": "DiscountCustomerSelectionInput",
    "code": "String",
    "usageLimit": "Int",
    "usesPerOrderLimit": "Int",
    "appliesOncePerCustomer": "Boolean"
  }
};
export default DiscountCodeBxgyInput;