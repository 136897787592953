
const ScriptTag = {
  "name": "ScriptTag",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cache": "Boolean",
    "createdAt": "DateTime",
    "displayScope": "ScriptTagDisplayScope",
    "id": "ID",
    "legacyResourceId": "UnsignedInt64",
    "src": "URL",
    "updatedAt": "DateTime"
  },
  "implementsNode": true
};
export default ScriptTag;