
const RefundLineItemInput = {
  "name": "RefundLineItemInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "lineItemId": "ID",
    "quantity": "Int",
    "restockType": "RefundLineItemRestockType",
    "locationId": "ID"
  }
};
export default RefundLineItemInput;