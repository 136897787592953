
const BrandColors = {
  "name": "BrandColors",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "primary": "BrandColorGroup",
    "secondary": "BrandColorGroup"
  },
  "implementsNode": false
};
export default BrandColors;