
const CustomerInput = {
  "name": "CustomerInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "addresses": "MailingAddressInput",
    "email": "String",
    "firstName": "String",
    "id": "ID",
    "lastName": "String",
    "locale": "String",
    "metafields": "MetafieldInput",
    "note": "String",
    "phone": "String",
    "tags": "String",
    "emailMarketingConsent": "CustomerEmailMarketingConsentInput",
    "smsMarketingConsent": "CustomerSmsMarketingConsentInput",
    "taxExempt": "Boolean",
    "taxExemptions": "TaxExemption"
  }
};
export default CustomerInput;