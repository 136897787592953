
const CustomerEmailMarketingConsentInput = {
  "name": "CustomerEmailMarketingConsentInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "marketingOptInLevel": "CustomerMarketingOptInLevel",
    "marketingState": "CustomerEmailMarketingState",
    "consentUpdatedAt": "DateTime"
  }
};
export default CustomerEmailMarketingConsentInput;