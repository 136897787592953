
const DiscountCombinesWithInput = {
  "name": "DiscountCombinesWithInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "productDiscounts": "Boolean",
    "orderDiscounts": "Boolean",
    "shippingDiscounts": "Boolean"
  }
};
export default DiscountCombinesWithInput;