
const OrderStagedChangeDecrementItem = {
  "name": "OrderStagedChangeDecrementItem",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "delta": "Int",
    "lineItem": "LineItem",
    "restock": "Boolean"
  },
  "implementsNode": false
};
export default OrderStagedChangeDecrementItem;