
const CheckoutBrandingCornerRadiusVariables = {
  "name": "CheckoutBrandingCornerRadiusVariables",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "base": "Int",
    "large": "Int",
    "small": "Int"
  },
  "implementsNode": false
};
export default CheckoutBrandingCornerRadiusVariables;