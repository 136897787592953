
const SubscriptionBillingCycleEditedContract = {
  "name": "SubscriptionBillingCycleEditedContract",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "app": "App",
    "appAdminUrl": "URL",
    "billingCycles": "SubscriptionBillingCycleConnection",
    "createdAt": "DateTime",
    "currencyCode": "CurrencyCode",
    "customAttributes": "Attribute",
    "customer": "Customer",
    "customerPaymentMethod": "CustomerPaymentMethod",
    "deliveryMethod": "SubscriptionDeliveryMethod",
    "deliveryPrice": "MoneyV2",
    "discounts": "SubscriptionManualDiscountConnection",
    "lineCount": "Int",
    "lines": "SubscriptionLineConnection",
    "linesCount": "Count",
    "note": "String",
    "orders": "OrderConnection",
    "updatedAt": "DateTime"
  },
  "implementsNode": false
};
export default SubscriptionBillingCycleEditedContract;