
const PaymentCustomizationActivationPayload = {
  "name": "PaymentCustomizationActivationPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "ids": "String",
    "userErrors": "PaymentCustomizationError"
  },
  "implementsNode": false
};
export default PaymentCustomizationActivationPayload;