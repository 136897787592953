import React, { Component, Fragment } from 'react'
import Notification from './notification';
import { AlignLeft, Bell, MoreHorizontal } from 'react-feather';
import { AuthContext } from "../../../auth/auth";
import { getNetlifySiteSettings } from '../../../querys/netlify-rest';
import { getCookieByName } from '../../navigation/utils';

//images
// import logo from 'assets/images/dashboard/multikart-logo.png'

export class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebar: true,
            rightSidebar: true,
            navMenus: false
        }
    }

    componentDidMount = () => {
        const { siteState, reseller_site_id, siteSetupStatus } = this.context;
        if (siteState !== "current") {
            this.checkSiteState(reseller_site_id);
        };
        if (siteSetupStatus === "BUILDING") {
            this.checkSiteSetupStatus();
        }
    }
    componentDidUpdate = async () => {
        const { siteState, reseller_site_id, siteSetupStatus } = this.context;
        if (siteState !== "current") {
            this.checkSiteState(reseller_site_id);
        };

        if (siteSetupStatus === "BUILDING") {
            this.checkSiteSetupStatus();
        }
    }

    checkSiteSetupStatus = async () => {
        const cookie = getCookieByName("siteSetup");
        if (cookie) {
            setTimeout(() => {
                this.checkSiteSetupStatus();
            }, 30000);
        }
         else {
            this.context.setSiteSetupStatus("BUILT");
        }
    }

    checkSiteState = (siteId) => {
        getNetlifySiteSettings(siteId)
            .then(res => {
                const site = res.data;
                if (site && site.published_deploy !== null) {
                    this.context.setSiteState("current");
                } else {
                    //Checks sites every 5 min
                    setTimeout(() => { this.checkSiteState(siteId) }, 300000); 
                };
            });
    }

    toggle() {
        this.setState(prevState => ({
            navMenus: !prevState.navMenus
        }));
    }
    showRightSidebar = () => {
        if (this.state.rightSidebar) {
            this.setState({ rightSidebar: false })
            document.querySelector(".right-sidebar").classList.add('show');
        } else {
            this.setState({ rightSidebar: true })
            document.querySelector(".right-sidebar").classList.remove('show');
        }
    }
    goFull = () => {
        if ((document.fullScreenElement && document.fullScreenElement !== null) ||
            (!document.mozFullScreen && !document.webkitIsFullScreen)) {
            if (document.documentElement.requestFullScreen) {
                document.documentElement.requestFullScreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullScreen) {
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }
    openCloseSidebar = () => {
        if (!document.querySelector(".page-main-header").classList.value.includes("open")) {
            document.querySelector(".page-main-header").classList.add('open');
            document.querySelector(".page-sidebar").classList.add('open');
        } else {
            this.setState({ sidebar: true })
            document.querySelector(".page-main-header").classList.remove('open');
            document.querySelector(".page-sidebar").classList.remove('open');
        }
    }
    render() {

        const { siteState } = this.context;

        return (
            <Fragment>
                {/* open */}
                <div className="page-main-header ">
                    <div className="main-header-right row">
                        <div className="main-header-left d-lg-none" >
                            <div className="logo-wrapper">
                                <a href="index.html">
                                    {/* <img className="blur-up lazyloaded" src={logo} alt="" /> */}
                                </a>
                            </div>
                        </div>
                        <div className="mobile-sidebar">
                            <div className="media-body text-right switch-sm">
                                <label className="switch">
                                    <a onClick={this.openCloseSidebar}>
                                        <AlignLeft />
                                    </a>
                                </label>
                            </div>
                        </div>
                        <div className="nav-right col">
                            <ul className={"nav-menus " + (this.state.navMenus ? 'open' : '')}>
                                <li>
                                    <a href={"https://" + this.context.store_subdomain + ".readysetprint.com"} target="_blank" >
                                        <button disabled={siteState !== "current"} className="btn btn-primary"> {siteState !== "current" ? "Building store" : "View Store"}</button>
                                    </a>
                                </li>
                                <li className="onhover-dropdown">
                                    <Bell />
                                    <Notification />
                                </li>
                            </ul>
                            <div className="d-lg-none mobile-toggle pull-right" onClick={() => this.toggle()}><MoreHorizontal /></div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

Header.contextType = AuthContext;

export default Header
