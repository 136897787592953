
const DeliveryProfileInput = {
  "name": "DeliveryProfileInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "name": "String",
    "profileLocationGroups": "DeliveryProfileLocationGroupInput",
    "locationGroupsToCreate": "DeliveryProfileLocationGroupInput",
    "locationGroupsToUpdate": "DeliveryProfileLocationGroupInput",
    "locationGroupsToDelete": "ID",
    "variantsToAssociate": "ID",
    "variantsToDissociate": "ID",
    "zonesToDelete": "ID",
    "methodDefinitionsToDelete": "ID",
    "conditionsToDelete": "ID",
    "sellingPlanGroupsToAssociate": "ID",
    "sellingPlanGroupsToDissociate": "ID"
  }
};
export default DeliveryProfileInput;