
const CashTrackingAdjustmentConnection = {
  "name": "CashTrackingAdjustmentConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "CashTrackingAdjustmentEdge",
    "nodes": "CashTrackingAdjustment",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default CashTrackingAdjustmentConnection;