
const CheckoutBrandingButton = {
  "name": "CheckoutBrandingButton",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "background": "CheckoutBrandingBackgroundStyle",
    "blockPadding": "CheckoutBrandingSpacing",
    "border": "CheckoutBrandingSimpleBorder",
    "cornerRadius": "CheckoutBrandingCornerRadius",
    "inlinePadding": "CheckoutBrandingSpacing",
    "typography": "CheckoutBrandingTypographyStyle"
  },
  "implementsNode": false
};
export default CheckoutBrandingButton;