
const DeliveryLegacyModeBlocked = {
  "name": "DeliveryLegacyModeBlocked",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "blocked": "Boolean",
    "reasons": "DeliveryLegacyModeBlockedReason"
  },
  "implementsNode": false
};
export default DeliveryLegacyModeBlocked;