
const SubscriptionDraftInput = {
  "name": "SubscriptionDraftInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "status": "SubscriptionContractSubscriptionStatus",
    "paymentMethodId": "ID",
    "nextBillingDate": "DateTime",
    "billingPolicy": "SubscriptionBillingPolicyInput",
    "deliveryPolicy": "SubscriptionDeliveryPolicyInput",
    "deliveryPrice": "Decimal",
    "deliveryMethod": "SubscriptionDeliveryMethodInput",
    "note": "String",
    "customAttributes": "AttributeInput"
  }
};
export default SubscriptionDraftInput;