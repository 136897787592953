
const CheckoutBrandingOrderSummary = {
  "name": "CheckoutBrandingOrderSummary",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "backgroundImage": "CheckoutBrandingImage",
    "colorScheme": "CheckoutBrandingColorSchemeSelection",
    "section": "CheckoutBrandingOrderSummarySection"
  },
  "implementsNode": false
};
export default CheckoutBrandingOrderSummary;