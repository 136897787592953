
const MetaobjectUpdateInput = {
  "name": "MetaobjectUpdateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "handle": "String",
    "fields": "MetaobjectFieldInput",
    "capabilities": "MetaobjectCapabilityDataInput",
    "redirectNewHandle": "Boolean"
  }
};
export default MetaobjectUpdateInput;