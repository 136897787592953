
const MetafieldRelation = {
  "name": "MetafieldRelation",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "key": "String",
    "name": "String",
    "namespace": "String",
    "referencer": "MetafieldReferencer",
    "target": "MetafieldReference"
  },
  "implementsNode": false
};
export default MetafieldRelation;