
const ReverseDeliveryTrackingV2 = {
  "name": "ReverseDeliveryTrackingV2",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "carrierName": "String",
    "number": "String",
    "url": "URL"
  },
  "implementsNode": false
};
export default ReverseDeliveryTrackingV2;