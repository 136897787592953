
const OrderStagedChangeIncrementItem = {
  "name": "OrderStagedChangeIncrementItem",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "delta": "Int",
    "lineItem": "LineItem"
  },
  "implementsNode": false
};
export default OrderStagedChangeIncrementItem;