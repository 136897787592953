
const Fulfillment = {
  "name": "Fulfillment",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "createdAt": "DateTime",
    "deliveredAt": "DateTime",
    "displayStatus": "FulfillmentDisplayStatus",
    "estimatedDeliveryAt": "DateTime",
    "events": "FulfillmentEventConnection",
    "fulfillmentLineItems": "FulfillmentLineItemConnection",
    "fulfillmentOrders": "FulfillmentOrderConnection",
    "id": "ID",
    "inTransitAt": "DateTime",
    "legacyResourceId": "UnsignedInt64",
    "location": "Location",
    "name": "String",
    "order": "Order",
    "originAddress": "FulfillmentOriginAddress",
    "requiresShipping": "Boolean",
    "service": "FulfillmentService",
    "shippingLabel": "ShippingLabel",
    "status": "FulfillmentStatus",
    "totalQuantity": "Int",
    "trackingInfo": "FulfillmentTrackingInfo",
    "updatedAt": "DateTime"
  },
  "implementsNode": true
};
export default Fulfillment;