
const MarketingActivityConnection = {
  "name": "MarketingActivityConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "MarketingActivityEdge",
    "nodes": "MarketingActivity",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default MarketingActivityConnection;