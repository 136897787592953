
const ProductVariantRelationshipUpdateInput = {
  "name": "ProductVariantRelationshipUpdateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "parentProductVariantId": "ID",
    "parentProductId": "ID",
    "productVariantRelationshipsToCreate": "ProductVariantGroupRelationshipInput",
    "productVariantRelationshipsToUpdate": "ProductVariantGroupRelationshipInput",
    "productVariantRelationshipsToRemove": "ID",
    "removeAllProductVariantRelationships": "Boolean",
    "priceInput": "PriceInput"
  }
};
export default ProductVariantRelationshipUpdateInput;