
const PaymentTermsTemplate = {
  "name": "PaymentTermsTemplate",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "description": "String",
    "dueInDays": "Int",
    "id": "ID",
    "name": "String",
    "paymentTermsType": "PaymentTermsType",
    "translatedName": "String"
  },
  "implementsNode": true
};
export default PaymentTermsTemplate;