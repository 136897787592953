
const AppSubscriptionLineItem = {
  "name": "AppSubscriptionLineItem",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "plan": "AppPlanV2",
    "usageRecords": "AppUsageRecordConnection"
  },
  "implementsNode": false
};
export default AppSubscriptionLineItem;