
const MarketingActivityUpdateExternalInput = {
  "name": "MarketingActivityUpdateExternalInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "title": "String",
    "budget": "MarketingActivityBudgetInput",
    "adSpend": "MoneyInput",
    "remoteUrl": "URL",
    "remotePreviewImageUrl": "URL",
    "tactic": "MarketingTactic",
    "marketingChannelType": "MarketingChannel",
    "referringDomain": "String",
    "scheduledStart": "DateTime",
    "scheduledEnd": "DateTime",
    "start": "DateTime",
    "end": "DateTime",
    "status": "MarketingActivityExternalStatus"
  }
};
export default MarketingActivityUpdateExternalInput;