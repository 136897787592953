
const ShopPolicyWithDefault = {
  "name": "ShopPolicyWithDefault",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "body": "String",
    "handle": "String",
    "id": "ID",
    "title": "String",
    "url": "URL"
  },
  "implementsNode": false
};
export default ShopPolicyWithDefault;