
const SubscriptionAppliedCodeDiscount = {
  "name": "SubscriptionAppliedCodeDiscount",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "redeemCode": "String",
    "rejectionReason": "SubscriptionDiscountRejectionReason"
  },
  "implementsNode": false
};
export default SubscriptionAppliedCodeDiscount;