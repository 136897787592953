
const DiscountProductsInput = {
  "name": "DiscountProductsInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "productsToAdd": "ID",
    "productsToRemove": "ID",
    "productVariantsToAdd": "ID",
    "productVariantsToRemove": "ID"
  }
};
export default DiscountProductsInput;