
const CheckoutBrandingColorSchemesInput = {
  "name": "CheckoutBrandingColorSchemesInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "scheme1": "CheckoutBrandingColorSchemeInput",
    "scheme2": "CheckoutBrandingColorSchemeInput",
    "scheme3": "CheckoutBrandingColorSchemeInput",
    "scheme4": "CheckoutBrandingColorSchemeInput"
  }
};
export default CheckoutBrandingColorSchemesInput;