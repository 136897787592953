
const StoreAvailabilityEdge = {
  "name": "StoreAvailabilityEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "StoreAvailability"
  },
  "implementsNode": false
};
export default StoreAvailabilityEdge;