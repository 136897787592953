
const AppSubscriptionTrialExtendPayload = {
  "name": "AppSubscriptionTrialExtendPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "appSubscription": "AppSubscription",
    "userErrors": "AppSubscriptionTrialExtendUserError"
  },
  "implementsNode": false
};
export default AppSubscriptionTrialExtendPayload;