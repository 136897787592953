import React, { useState, useEffect } from 'react';
import { ReactComponent as CloseIcon } from "../../assets/elegant_font/images/SVG/icon_close_alt2.svg";
import Modal from 'react-responsive-modal';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import GeneralInput from '../common/general-input';
import { Alert } from "reactstrap";
import LinksDropdown from '../common/links-dropdown';
import FormHandleInput from '../common/form-handle-input';
import { DEFAULT_MENU_LINK_OPTIONS } from '../utils/constant';

const buttonStyles = {
    fontSize: '10px',
    minWidth: '86px',
    height: '30px',
    padding: '0px',
    marginTop: '14px'
};

const MenuEditModal = ({ isOpen, onCloseModal, item, isNew, onSaveModal, alertColor, alert, toggle, alertMessage }) => {

    const [link, setLink] = useState("");
    const [path, setPath] = useState("");

    const handleSubmit = (_event, values) => {

        if (values.handle) {
            values = {
                ...values,
                link: path + values.handle
            };
        }
        onSaveModal(values);
    }

    const onLinkTypeSelect = (option) => {
        setLink(option.link);
        setPath(option.path);
    }

    useEffect(() => {
        if (item.handle) {
            const paths = DEFAULT_MENU_LINK_OPTIONS.map(o => o.path);
            const regex = new RegExp(paths.join("|"));
            const matches = item.link.match(regex);
            if (matches && matches.length > 0) {
                const path = matches[0];
                setPath(path);
            }
            const l = item.link.replace(new RegExp(`/${item.handle}/*$`), "/");
            setLink(l);
        }
    }, [item]);

    return (
        <Modal
            open={isOpen}
            onClose={onCloseModal}
            showCloseIcon={false}
            styles={{
                modal: {
                    borderRadius: "4px",
                    overflow: "hidden",
                    width: '640px',
                    padding: '30px'
                },
            }}
            center
        >
            <div className="d-flex justify-content-between">
                <h5
                    style={{ color: "black", fontSize: "18px", paddingTop: '2px', marginBottom: '13px' }}>
                    {isNew ? "Add Menu Item" : "Edit Menu Item"}
                </h5>
                <a
                    href="javascript:void(0)"
                    className="text-primary"
                    onClick={onCloseModal}
                ><CloseIcon width="24" height="24" /></a>
            </div>

            <AvForm
                className="needs-validation add-product-form"
                onValidSubmit={handleSubmit}
                model={item}
            >
                <GeneralInput
                    label="Title"
                    name="title"
                    placeholder="Title"
                />
                <div className="form-group">
                    <label className="mb-2">Link type</label>
                    <div className="form-field">
                        <LinksDropdown name="linkType" selectedValue={item.linkType} onChange={onLinkTypeSelect} />
                    </div>
                </div>
                <FormHandleInput labelText="Link" name="handle" url={link} />
                <button type="button" className="btn btn-outline-dark mr-3" style={buttonStyles} onClick={onCloseModal}> Cancel </button>
                <button type="submit" className="btn btn-primary" style={buttonStyles}> Save </button><br></br>
                <br></br>
                <Alert color={alertColor} isOpen={alert} toggle={toggle} > {alertMessage} </Alert>
            </AvForm>
        </Modal>
    )
}

export default MenuEditModal;