
const ShippingLineConnection = {
  "name": "ShippingLineConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "ShippingLineEdge",
    "nodes": "ShippingLine",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default ShippingLineConnection;