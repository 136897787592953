
const AbandonedCheckoutLineItemEdge = {
  "name": "AbandonedCheckoutLineItemEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "AbandonedCheckoutLineItem"
  },
  "implementsNode": false
};
export default AbandonedCheckoutLineItemEdge;