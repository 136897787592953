
const DiscountAutomaticBxgyInput = {
  "name": "DiscountAutomaticBxgyInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "combinesWith": "DiscountCombinesWithInput",
    "startsAt": "DateTime",
    "endsAt": "DateTime",
    "title": "String",
    "usesPerOrderLimit": "UnsignedInt64",
    "customerBuys": "DiscountCustomerBuysInput",
    "customerGets": "DiscountCustomerGetsInput"
  }
};
export default DiscountAutomaticBxgyInput;