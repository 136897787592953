
const EventBridgeWebhookSubscriptionInput = {
  "name": "EventBridgeWebhookSubscriptionInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "arn": "ARN",
    "format": "WebhookSubscriptionFormat",
    "includeFields": "String",
    "metafieldNamespaces": "String"
  }
};
export default EventBridgeWebhookSubscriptionInput;