
const CustomerEdge = {
  "name": "CustomerEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "Customer"
  },
  "implementsNode": false
};
export default CustomerEdge;