
const DelegateAccessTokenDestroyPayload = {
  "name": "DelegateAccessTokenDestroyPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "shop": "Shop",
    "status": "Boolean",
    "userErrors": "DelegateAccessTokenDestroyUserError"
  },
  "implementsNode": false
};
export default DelegateAccessTokenDestroyPayload;