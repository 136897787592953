
const PolarisVizDataSeries = {
  "name": "PolarisVizDataSeries",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "data": "PolarisVizDataPoint",
    "isComparison": "Boolean",
    "name": "String"
  },
  "implementsNode": false
};
export default PolarisVizDataSeries;