
const CartBuyerIdentityInput = {
  "name": "CartBuyerIdentityInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "email": "String",
    "phone": "String",
    "companyLocationId": "ID",
    "countryCode": "CountryCode",
    "customerAccessToken": "String",
    "deliveryAddressPreferences": "DeliveryAddressInput",
    "walletPreferences": "String"
  }
};
export default CartBuyerIdentityInput;