
const MarketLocalizableContent = {
  "name": "MarketLocalizableContent",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "digest": "String",
    "key": "String",
    "value": "String"
  },
  "implementsNode": false
};
export default MarketLocalizableContent;