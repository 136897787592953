
const ProductChangeStatusPayload = {
  "name": "ProductChangeStatusPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "product": "Product",
    "userErrors": "ProductChangeStatusUserError"
  },
  "implementsNode": false
};
export default ProductChangeStatusPayload;