import React, { Component, Fragment } from "react";
import Breadcrumb from '../../components/common/bread-crumb';
import HomePageMessage from "./components/customize-message";
import HomeProductGrid from "./components/customize-product-grid";
import HomePageImageText from "./components/customize-image-text";
import HomeCollectionGrid from "./components/customize-collection-grid";
import CustomizePromoBar from "./components/customize-promo-bar";
import CustomizeCompanyLogo from "./components/customize-company-logo";
import { AvForm } from "availity-reactstrap-validation";


export class CustomizeHomePage extends Component {
  componentDidMount() {
    window.analytics.page();
  }
  
  render() {
    return (
      <Fragment>
        <Breadcrumb mainTitle="Homepage Layout" title="Home Page" parent="Customize" />
        <div className="container-fluid">
          <AvForm
            className="needs-validation form"
            onValidSubmit={this.handleValidSubmit}
            onInvalidSubmit={this.handleInvalidSubmit}
          >
            <CustomizeCompanyLogo />
            <HomePageMessage />
            <HomePageImageText />
            <CustomizePromoBar />
            <HomeCollectionGrid />
            <HomeProductGrid />
          </AvForm>
        </div>
      </Fragment>
    );
  }
}

export default CustomizeHomePage;
