
const BundlesFeature = {
  "name": "BundlesFeature",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "eligibleForBundles": "Boolean",
    "ineligibilityReason": "String",
    "sellsBundles": "Boolean"
  },
  "implementsNode": false
};
export default BundlesFeature;