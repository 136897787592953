
const EventBridgeWebhookSubscriptionUpdatePayload = {
  "name": "EventBridgeWebhookSubscriptionUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "userErrors": "UserError",
    "webhookSubscription": "WebhookSubscription"
  },
  "implementsNode": false
};
export default EventBridgeWebhookSubscriptionUpdatePayload;