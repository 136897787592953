
const OrderTransactionEdge = {
  "name": "OrderTransactionEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "OrderTransaction"
  },
  "implementsNode": false
};
export default OrderTransactionEdge;