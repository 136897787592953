
const MetafieldReferenceConnection = {
  "name": "MetafieldReferenceConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "MetafieldReferenceEdge",
    "nodes": "MetafieldReference",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default MetafieldReferenceConnection;