
const FulfillmentServiceUpdatePayload = {
  "name": "FulfillmentServiceUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "fulfillmentService": "FulfillmentService",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default FulfillmentServiceUpdatePayload;