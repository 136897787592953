
const Swatch = {
  "name": "Swatch",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "color": "Color",
    "image": "MediaImage"
  },
  "implementsNode": false
};
export default Swatch;