
const FulfillmentOrderLineItemFinancialSummary = {
  "name": "FulfillmentOrderLineItemFinancialSummary",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "approximateDiscountedUnitPriceSet": "MoneyBag",
    "discountAllocations": "FinancialSummaryDiscountAllocation",
    "originalUnitPriceSet": "MoneyBag",
    "quantity": "Int"
  },
  "implementsNode": false
};
export default FulfillmentOrderLineItemFinancialSummary;