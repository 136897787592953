
const DeliveryCustomizationInput = {
  "name": "DeliveryCustomizationInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "functionId": "String",
    "title": "String",
    "enabled": "Boolean",
    "metafields": "MetafieldInput"
  }
};
export default DeliveryCustomizationInput;