
const ReverseFulfillmentOrderLineItemConnection = {
  "name": "ReverseFulfillmentOrderLineItemConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "ReverseFulfillmentOrderLineItemEdge",
    "nodes": "ReverseFulfillmentOrderLineItem",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default ReverseFulfillmentOrderLineItemConnection;