
const MarketingEngagementsDeletePayload = {
  "name": "MarketingEngagementsDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "result": "String",
    "userErrors": "MarketingActivityUserError"
  },
  "implementsNode": false
};
export default MarketingEngagementsDeletePayload;