
const UpdateMediaInput = {
  "name": "UpdateMediaInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "id": "ID",
    "previewImageSource": "String",
    "alt": "String"
  }
};
export default UpdateMediaInput;