
const CollectionDeletePayload = {
  "name": "CollectionDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedCollectionId": "ID",
    "shop": "Shop",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default CollectionDeletePayload;