
const EditableProperty = {
  "name": "EditableProperty",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "locked": "Boolean",
    "reason": "FormattedString"
  },
  "implementsNode": false
};
export default EditableProperty;