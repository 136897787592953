
const CheckoutLineItemInput = {
  "name": "CheckoutLineItemInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "customAttributes": "AttributeInput",
    "quantity": "Int",
    "variantId": "ID"
  }
};
export default CheckoutLineItemInput;