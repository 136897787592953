
const CustomerPaymentMethodGetDuplicationDataPayload = {
  "name": "CustomerPaymentMethodGetDuplicationDataPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "encryptedDuplicationData": "String",
    "userErrors": "CustomerPaymentMethodGetDuplicationDataUserError"
  },
  "implementsNode": false
};
export default CustomerPaymentMethodGetDuplicationDataPayload;