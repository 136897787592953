
const PurchasingCompany = {
  "name": "PurchasingCompany",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "company": "Company",
    "contact": "CompanyContact",
    "location": "CompanyLocation"
  },
  "implementsNode": false
};
export default PurchasingCompany;