
const CheckoutBrandingControl = {
  "name": "CheckoutBrandingControl",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "border": "CheckoutBrandingSimpleBorder",
    "color": "CheckoutBrandingColorSelection",
    "cornerRadius": "CheckoutBrandingCornerRadius",
    "labelPosition": "CheckoutBrandingLabelPosition"
  },
  "implementsNode": false
};
export default CheckoutBrandingControl;