
const MetaobjectField = {
  "name": "MetaobjectField",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "key": "String",
    "reference": "MetafieldReference",
    "references": "MetafieldReferenceConnection",
    "type": "String",
    "value": "String"
  },
  "implementsNode": false
};
export default MetaobjectField;