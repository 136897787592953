import { DEFAULT_MENUS } from "../utils/constant"

export const decodeSnippet = (snippet, name) => {
    const content = snippet.replace(`<script>window.${name}=`, '').replace('<\/script>', '')
    return JSON.parse(content)
}

export const encodeSnippet = (content, name) => {
    let snippet = `\u003Cscript\u003Ewindow.${name}=${JSON.stringify(content)}\u003C/script\u003E`
    return snippet
}

export const updateDefaultMenuWithSubdomain = (store_subdomain) => {

    let footerMenu2Index = DEFAULT_MENUS.findIndex(menu => menu.id === "footer-menu2");
    let footerMenu2 = DEFAULT_MENUS[footerMenu2Index];
    footerMenu2.items = footerMenu2.items.map(item => {
        item.handle = `${store_subdomain}-${item.handle}`;
        item.link = `/pages/${item.handle}`;
        return item;
    });
    DEFAULT_MENUS[footerMenu2Index] = footerMenu2;

    return DEFAULT_MENUS;
}

export const getCookieByName = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
}

export const createCookie = (name, value, minutes) => {
    if (minutes) {
        var date = new Date();
        date.setTime(date.getTime() + (minutes * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
    } else {
        var expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

export const deleteCookie = (name) => {
    document.cookie = name + '=; Max-Age=-99999999;';
}

export const isBase64 = (str) => {
    if (str === '' || str.trim() === '') { return false; }
    try {
        return btoa(atob(str)) == str;
    } catch (err) {
        return false;
    }
}