
const OrderEditAddShippingLinePayload = {
  "name": "OrderEditAddShippingLinePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "calculatedOrder": "CalculatedOrder",
    "calculatedShippingLine": "CalculatedShippingLine",
    "userErrors": "OrderEditAddShippingLineUserError"
  },
  "implementsNode": false
};
export default OrderEditAddShippingLinePayload;