
const SubscriptionDeliveryMethodShippingOption = {
  "name": "SubscriptionDeliveryMethodShippingOption",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "carrierService": "DeliveryCarrierService",
    "code": "String",
    "description": "String",
    "presentmentTitle": "String",
    "title": "String"
  },
  "implementsNode": false
};
export default SubscriptionDeliveryMethodShippingOption;