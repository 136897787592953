
const LineItemMutableConnection = {
  "name": "LineItemMutableConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "LineItemMutableEdge",
    "nodes": "LineItemMutable",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default LineItemMutableConnection;