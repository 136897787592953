
const StaffMember = {
  "name": "StaffMember",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "active": "Boolean",
    "avatar": "Image",
    "email": "String",
    "exists": "Boolean",
    "firstName": "String",
    "id": "ID",
    "initials": "String",
    "isShopOwner": "Boolean",
    "lastName": "String",
    "locale": "String",
    "name": "String",
    "phone": "String",
    "privateData": "StaffMemberPrivateData"
  },
  "implementsNode": true
};
export default StaffMember;