
const CustomerPhoneNumber = {
  "name": "CustomerPhoneNumber",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "marketingState": "CustomerSmsMarketingState",
    "phoneNumber": "String"
  },
  "implementsNode": false
};
export default CustomerPhoneNumber;