
const CheckoutBrandingHeader = {
  "name": "CheckoutBrandingHeader",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "alignment": "CheckoutBrandingHeaderAlignment",
    "banner": "CheckoutBrandingImage",
    "cartLink": "CheckoutBrandingHeaderCartLink",
    "colorScheme": "CheckoutBrandingColorSchemeSelection",
    "logo": "CheckoutBrandingLogo",
    "padding": "CheckoutBrandingSpacingKeyword",
    "position": "CheckoutBrandingHeaderPosition"
  },
  "implementsNode": false
};
export default CheckoutBrandingHeader;