
const MarketCreatePayload = {
  "name": "MarketCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "market": "Market",
    "userErrors": "MarketUserError"
  },
  "implementsNode": false
};
export default MarketCreatePayload;