
const CheckoutBrandingTypographyInput = {
  "name": "CheckoutBrandingTypographyInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "size": "CheckoutBrandingFontSizeInput",
    "primary": "CheckoutBrandingFontGroupInput",
    "secondary": "CheckoutBrandingFontGroupInput"
  }
};
export default CheckoutBrandingTypographyInput;