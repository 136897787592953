
const RefundEdge = {
  "name": "RefundEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "Refund"
  },
  "implementsNode": false
};
export default RefundEdge;