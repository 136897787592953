
const DeliverySetting = {
  "name": "DeliverySetting",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "legacyModeBlocked": "DeliveryLegacyModeBlocked",
    "legacyModeProfiles": "Boolean"
  },
  "implementsNode": false
};
export default DeliverySetting;