import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/bread-crumb';
import GeneralInput from '../../components/common/general-input';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getNetlifySnippetByTitle, addNetlifySnippet, updateNetlifySnippet } from '../../querys/netlify-rest';
import { encodeSnippet, decodeSnippet } from '../utils/snippets'
import { AuthContext } from "../../auth/auth";
import Loader from '../loader/loader';
import MenuList from './menu-list';
import MenuItems from './menu-items';
import FormHandleInput from '../common/form-handle-input';
import { updateDefaultMenuWithSubdomain } from './utils';
import { DEFAULT_MENUS } from '../utils/constant';

export class NavigationFooter extends Component {

    constructor(props) {
        super(props)
        this.state = {
            footer: {},
            loading: true,
            menus: [],
            snippet: {
                value: null,
                netlifySnippetId: null,
                netlifySnippetContent: null,
            },
            copyRightPlaceholder: `${new Date().getFullYear()} Your Company. All rights reserved.`
        }
    }

    componentDidMount = () => {
        this.fetchMenuSnippet();
        this.getFooterSnippet().then(response => {
            //Snippet exists
            if (response.id && response.general) {
                const footer = decodeSnippet(response.general, "Footer")
                this.setState({ footer });
            }
            this.setState({ loading: false })
        });
        window.analytics.page();
    }

    getFooterSnippet = () => {
        return getNetlifySnippetByTitle(this.context.reseller_site_id, "Footer")
            .then(response => {
                return response
            })
    }

    handleValidSubmit = (_event, values) => {

        this.setState({ loading: true })

        let { footer } = this.state
        footer = {
            ...footer,
            ...values
        }

        this.setState(() => ({
            footer
        }))

        // Update existing snippet
        this.getFooterSnippet().then(response => {
            if (response.id && response.general) {
                updateNetlifySnippet(this.context.reseller_site_id, "Footer", encodeSnippet(footer, "Footer"), response.id).then(() => {
                    this.setState({
                        footer,
                        loading: false
                    })
                })
            } else {
                addNetlifySnippet(this.context.reseller_site_id, "Footer", encodeSnippet(footer, "Footer")).then(response => {
                    if (response.status === 201) {
                        this.setState({ footer, loading: false });
                    }
                })
            }
        });
    }


    fetchMenuSnippet = () => {

        getNetlifySnippetByTitle(this.context.reseller_site_id, "Menu")
            .then((response) => {
                if (response.id && response.general) {
                    // Update the state with the Netlify snippet data
                    //Decode snippet
                    const menus = decodeSnippet(response.general, "Menu")

                    this.setState({
                        snippet: {
                            value: response.general,
                            netlifySnippetId: response.id,
                            netlifySnippetContent: response.general,
                        },
                        menus
                    });
                }
            });
    }

    render() {

        if (this.state.loading === true) return <Loader />

        const { footer } = this.state

        return (
            <Fragment>
                <Breadcrumb title="Footer" parent="Navigation" />
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <h5>Footer</h5>
                        </div>
                        <div className="card-body">
                            <AvForm
                                className="needs-validation add-product-form"
                                onValidSubmit={this.handleValidSubmit}
                                onInvalidSubmit={this.handleInvalidSubmit}
                                model={footer}
                            >
                                <div className="row">
                                    <div className="col-sm-12 col-xl-3 col-md-4 mb-3">
                                        <span className="text-secondary">
                                            Customize your footer content by adding a brief about section, contact info, social media links and legal text.
                                        </span>
                                    </div>
                                    <div className="col-sm-12 offset-x1-1 offset-md-1 col-xl-7 col-md-6">
                                        <div className="form-group">
                                            <label className="mb-2">Company Description</label>
                                            <div className="form-field">
                                                <AvField
                                                    className="form-control mb-0"
                                                    name="companyDescription"
                                                    type="textarea"
                                                    placeholder="Short description of your company can go here."
                                                    rows={3}
                                                    required
                                                />
                                            </div>
                                            <div className="valid-feedback">Invalid</div>
                                        </div>
                                        <div className="mb-3 mt-4" style={{ fontSize: "13px" }} ><b>Social Links </b></div>
                                        <div className="form-group">
                                            <FormHandleInput labelText="Facebook" url={`https://facebook.com/`} name="facebook" />
                                        </div>
                                        <div className="form-group">
                                            <FormHandleInput labelText="Twitter" url={`https://twitter.com/`} name="twitter" />
                                        </div>
                                        <div className="form-group">
                                            <FormHandleInput labelText="Instagram" url={`https://instagram.com/`} name="instagram" />
                                        </div>
                                        <div className="form-group">
                                            <FormHandleInput labelText="LinkedIn" url={`https://linkedin.com/`} name="linkedIn" />
                                        </div>
                                        <div className="form-group">
                                            <FormHandleInput labelText="Pinterest" url={`https://www.pinterest.com/`} name="pinterest" />
                                        </div>
                                        <div className="form-group">
                                            <FormHandleInput labelText="YouTube" url={`https://www.youtube.com/`} name="youtube" />
                                        </div>
                                        <GeneralInput
                                            label="Company Name"
                                            name="legalCopy"
                                            placeholder={this.state.copyRightPlaceholder}
                                        />
                                        <div className="text-center text-md-left">
                                            <button className="btn btn-primary btn-custom mr-3" type="submit">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </AvForm>
                        </div>
                    </div>
                    {this.context.siteSetupStatus === "BUILDING" ? <p>We are activating your menus. Please wait.</p> : <div className="card">
                        <div className="card-header">
                            <h5>Footer Menu</h5>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-12 col-xl-3 col-md-4 mb-3">
                                    <p className="text-secondary mb-1">
                                        Your store supports up to 2 footer menus.
                                    </p>
                                </div>
                                <div className="col-sm-12 offset-x1-1 offset-md-1 col-xl-7 col-md-6">
                                    <MenuItems menus={this.state.menus.filter(m => m.id === "footer-menu1" || m.id === "footer-menu2")} snippet={this.state.snippet} />
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </Fragment>
        )
    }
}

NavigationFooter.contextType = AuthContext

export default NavigationFooter;
