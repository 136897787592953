
const ProductOptionsCreateUserError = {
  "name": "ProductOptionsCreateUserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "ProductOptionsCreateUserErrorCode",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
export default ProductOptionsCreateUserError;