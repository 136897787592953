
const GiftCard = {
  "name": "GiftCard",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "balance": "MoneyV2",
    "createdAt": "DateTime",
    "customer": "Customer",
    "disabledAt": "DateTime",
    "enabled": "Boolean",
    "expiresOn": "Date",
    "id": "ID",
    "initialValue": "MoneyV2",
    "lastCharacters": "String",
    "maskedCode": "String",
    "note": "String",
    "order": "Order"
  },
  "implementsNode": true
};
export default GiftCard;