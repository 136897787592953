
const TableData = {
  "name": "TableData",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "columns": "TableDataColumn",
    "rowData": "String",
    "unformattedData": "JSON"
  },
  "implementsNode": false
};
export default TableData;