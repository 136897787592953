
const SellingPlan = {
  "name": "SellingPlan",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "billingPolicy": "SellingPlanBillingPolicy",
    "category": "SellingPlanCategory",
    "createdAt": "DateTime",
    "deliveryPolicy": "SellingPlanDeliveryPolicy",
    "description": "String",
    "id": "ID",
    "inventoryPolicy": "SellingPlanInventoryPolicy",
    "name": "String",
    "options": "String",
    "position": "Int",
    "pricingPolicies": "SellingPlanPricingPolicy",
    "translations": "Translation"
  },
  "implementsNode": true
};
export default SellingPlan;