
const MarketRegionsDeletePayload = {
  "name": "MarketRegionsDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedIds": "ID",
    "userErrors": "MarketUserError"
  },
  "implementsNode": false
};
export default MarketRegionsDeletePayload;