
const CustomerVisit = {
  "name": "CustomerVisit",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "landingPage": "URL",
    "landingPageHtml": "HTML",
    "marketingEvent": "MarketingEvent",
    "occurredAt": "DateTime",
    "referralCode": "String",
    "referralInfoHtml": "FormattedString",
    "referrerUrl": "URL",
    "source": "String",
    "sourceDescription": "String",
    "sourceType": "MarketingTactic",
    "utmParameters": "UTMParameters"
  },
  "implementsNode": true
};
export default CustomerVisit;