
const FulfillmentOrderMovePayload = {
  "name": "FulfillmentOrderMovePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "movedFulfillmentOrder": "FulfillmentOrder",
    "originalFulfillmentOrder": "FulfillmentOrder",
    "remainingFulfillmentOrder": "FulfillmentOrder",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default FulfillmentOrderMovePayload;