
const ShopifyPaymentsTransactionSet = {
  "name": "ShopifyPaymentsTransactionSet",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "extendedAuthorizationSet": "ShopifyPaymentsExtendedAuthorization",
    "refundSet": "ShopifyPaymentsRefundSet"
  },
  "implementsNode": false
};
export default ShopifyPaymentsTransactionSet;