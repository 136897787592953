
const ReverseDelivery = {
  "name": "ReverseDelivery",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deliverable": "ReverseDeliveryDeliverable",
    "id": "ID",
    "reverseDeliveryLineItems": "ReverseDeliveryLineItemConnection",
    "reverseFulfillmentOrder": "ReverseFulfillmentOrder"
  },
  "implementsNode": true
};
export default ReverseDelivery;