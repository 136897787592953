
const CardPaymentDetails = {
  "name": "CardPaymentDetails",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "avsResultCode": "String",
    "bin": "String",
    "company": "String",
    "cvvResultCode": "String",
    "expirationMonth": "Int",
    "expirationYear": "Int",
    "name": "String",
    "number": "String",
    "paymentMethodName": "String",
    "wallet": "DigitalWallet"
  },
  "implementsNode": false
};
export default CardPaymentDetails;