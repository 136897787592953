
const StoreAvailability = {
  "name": "StoreAvailability",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "available": "Boolean",
    "location": "Location",
    "pickUpTime": "String",
    "quantityAvailable": "Int"
  },
  "implementsNode": false
};
export default StoreAvailability;