
const MarketCatalogConnection = {
  "name": "MarketCatalogConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "MarketCatalogEdge",
    "nodes": "MarketCatalog",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default MarketCatalogConnection;