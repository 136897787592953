
const PurchasingCompanyInput = {
  "name": "PurchasingCompanyInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "companyId": "ID",
    "companyContactId": "ID",
    "companyLocationId": "ID"
  }
};
export default PurchasingCompanyInput;