
const FulfillmentOrderConnection = {
  "name": "FulfillmentOrderConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "FulfillmentOrderEdge",
    "nodes": "FulfillmentOrder",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default FulfillmentOrderConnection;