
const ExchangeLineItem = {
  "name": "ExchangeLineItem",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "lineItem": "LineItem"
  },
  "implementsNode": true
};
export default ExchangeLineItem;