
const MarketLocalizationsRegisterPayload = {
  "name": "MarketLocalizationsRegisterPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "marketLocalizations": "MarketLocalization",
    "userErrors": "TranslationUserError"
  },
  "implementsNode": false
};
export default MarketLocalizationsRegisterPayload;