
const AppRecurringPricingInput = {
  "name": "AppRecurringPricingInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "interval": "AppPricingInterval",
    "price": "MoneyInput",
    "discount": "AppSubscriptionDiscountInput"
  }
};
export default AppRecurringPricingInput;