
const DeliveryZone = {
  "name": "DeliveryZone",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "countries": "DeliveryCountry",
    "id": "ID",
    "name": "String"
  },
  "implementsNode": true
};
export default DeliveryZone;