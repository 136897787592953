
const CheckoutBrandingFontGroup = {
  "name": "CheckoutBrandingFontGroup",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "base": "CheckoutBrandingFont",
    "bold": "CheckoutBrandingFont",
    "loadingStrategy": "CheckoutBrandingFontLoadingStrategy",
    "name": "String"
  },
  "implementsNode": false
};
export default CheckoutBrandingFontGroup;