
const OnlineStorePage = {
  "name": "OnlineStorePage",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "defaultCursor": "String",
    "id": "ID",
    "translations": "Translation"
  },
  "implementsNode": true
};
export default OnlineStorePage;