
const SellingPlanRecurringDeliveryPolicyInput = {
  "name": "SellingPlanRecurringDeliveryPolicyInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "interval": "SellingPlanInterval",
    "intervalCount": "Int",
    "anchors": "SellingPlanAnchorInput",
    "cutoff": "Int",
    "intent": "SellingPlanRecurringDeliveryPolicyIntent",
    "preAnchorBehavior": "SellingPlanRecurringDeliveryPolicyPreAnchorBehavior"
  }
};
export default SellingPlanRecurringDeliveryPolicyInput;