import React, { Component } from 'react';
import ColorSelector from './color-selector';
import { generateTitleCSS, generateBodyCSS, generateButtonCSS, generateButtonTextCSS, generateHomepageHeroCSS } from './css-templates';
import { Alert, Popover, PopoverBody } from "reactstrap";
import { getNetlifySnippetByTitle, addNetlifySnippet, updateNetlifySnippet } from '../../../querys/netlify-rest';
import { AuthContext } from "../../../auth/auth";
import { XCircle } from 'react-feather';
import styled from 'styled-components';
import { SketchPicker } from 'react-color';

const snippetTitle = "Custom Color CSS";
const CloseDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
`

class ColorSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title_color: "",
            body_color: "",
            homepage_hero_color: "",
            button_color: "",
            button_text_color: "",
            alert: false,
            alertMessage: "",
            alertColor: "success",
            netlifySnippetId: "",
            netlifySnippetContent: "",
            picker: {
                open: false,
                target: "title_color",
                color: null,
                id: null
            },
            pickerOpen: false,
            pickerRef: null,
            pickerColor: null,
            pickerId: null
        };
        this.fetchNetlifySnippet = this.fetchNetlifySnippet.bind(this);
        this.extractColorFields = this.extractColorFields.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.fetchNetlifySnippet();
    }

    fetchNetlifySnippet() {
        // Check for existing Netlify snippet
        getNetlifySnippetByTitle(this.context.reseller_site_id, snippetTitle)
            .then((response) => {
                if (response.id && response.general) {
                    // Update the state with the Netlify snippet data
                    this.setState({
                        netlifySnippetId: response.id,
                        netlifySnippetContent: response.general
                    });
                    // Extract the color fields from the snippet and pre-populate the color inputs
                    let fieldNames = ["title_color", "body_color", "homepage_hero_color", "button_color", "button_text_color"];
                    for (let field in fieldNames) {
                        this.extractColorFields(fieldNames[field], response.general);
                    }
                } else {
                    console.log(response);
                }
            });
    }

    // This method extracts a color field's value out of the generated CSS and sets it to state
    // Requires the CSS template to include /* field_name=${color} */
    extractColorFields(fieldName, snippetCSS) {
        if (snippetCSS.indexOf(fieldName + "=") > -1) {
            let colorValue = snippetCSS.split(fieldName + "=")[1].split("*/")[0].trim();
            console.log(colorValue);
            this.setState({ [fieldName]: colorValue });
        }
    }

    handleSave(e) {
        e.preventDefault();
        // Build the CSS to inject as a Netlify Snippet 
        let allCSS = [];
        if (this.state.title_color) {
            let titleCSS = generateTitleCSS(this.state.title_color);
            allCSS.push(titleCSS);
        }
        if (this.state.body_color) {
            let bodyCSS = generateBodyCSS(this.state.body_color);
            allCSS.push(bodyCSS);
        }
        if (this.state.homepage_hero_color) {
            let homepageHeroCSS = generateHomepageHeroCSS(this.state.homepage_hero_color);
            allCSS.push(homepageHeroCSS);
        }
        if (this.state.button_color) {
            let buttonCSS = generateButtonCSS(this.state.button_color);
            allCSS.push(buttonCSS);
        }
        if (this.state.button_text_color) {
            let buttonTextCSS = generateButtonTextCSS(this.state.button_text_color);
            allCSS.push(buttonTextCSS);
        }
        console.log(allCSS.join(" "));
        let snippetContent = '<style type="text/css">' + allCSS.join(" ") + '</style>';
        // Inject the CSS as a Netlify Snippet
        if (this.state.netlifySnippetId) {
            // Update Netlify Snippet
            updateNetlifySnippet(this.context.reseller_site_id, snippetTitle, snippetContent, this.state.netlifySnippetId).then((response) => {
                if (response.status === 200) {
                    this.setState({
                        alert: true,
                        alertMessage: "Success: Your custom colors have been updated on your site.",
                        alertColor: "success"
                    });
                    // Fetch the Snippet ID and update state
                    this.fetchNetlifySnippet();
                } else {
                    console.log(response);
                    this.setState({
                        alert: true,
                        alertMessage: response.message,
                        alertColor: "danger"
                    });
                }
            });
        } else {
            // Add Netlify Snippet
            addNetlifySnippet(this.context.reseller_site_id, snippetTitle, snippetContent)
                .then((response) => {
                    if (response.status === 201) {
                        this.setState({
                            alert: true,
                            alertMessage: "Success: Your custom colors have been applied to your site.",
                            alertColor: "success"
                        });
                        // Fetch the Snippet ID and update state
                        this.fetchNetlifySnippet();
                    } else {
                        console.log(response);
                        this.setState({
                            alert: true,
                            alertMessage: response.message,
                            alertColor: "danger"
                        });
                    }
                });
        }

    }

    // Push inputs to state for easy access
    handleChange(id, color) {
        this.setState({ [id]: color });
    }

    toggle() {
        this.setState({
            alert: !this.state.alert
        });
    }

    togglePicker = () => {
        this.setState((state) => ({
            picker: {
                ...state.picker,
                open: !state.picker.open
            }
        }))
    }

    onOpenPicker = (target, color, id) => {
        this.setState({
            picker: {
                target,
                color,
                id,
                open: true
            }
        })
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-sm-12 col-lg-6 pt-4">
                        <h5 className="mb-4"> Color </h5>
                        <ColorSelector
                            color={this.state.title_color}
                            label="Titles"
                            id="title_color"
                            placeholder="#000000"
                            handleChange={this.handleChange.bind(this)}
                            onOpenPicker={this.onOpenPicker}
                        />
                        <ColorSelector
                            color={this.state.body_color}
                            label="Body text"
                            id="body_color"
                            placeholder="#000000"
                            handleChange={this.handleChange.bind(this)}
                            onOpenPicker={this.onOpenPicker}
                        />
                        <ColorSelector
                            color={this.state.homepage_hero_color}
                            label="Homepage Hero Text"
                            id="homepage_hero_color"
                            placeholder="#000000"
                            handleChange={this.handleChange.bind(this)}
                            onOpenPicker={this.onOpenPicker}
                        />
                    </div>
                    <div className="col-sm-12 col-lg-6 pt-4">
                        <h5 className="mb-4">Buttons</h5>
                        <ColorSelector
                            color={this.state.button_color}
                            label="Primary buttons & active text links"
                            id="button_color"
                            placeholder="#0091FF"
                            handleChange={this.handleChange.bind(this)}
                            onOpenPicker={this.onOpenPicker}
                        />
                        <ColorSelector
                            color={this.state.button_text_color}
                            label="Primary button text"
                            id="button_text_color"
                            placeholder="#777777"
                            handleChange={this.handleChange.bind(this)}
                            onOpenPicker={this.onOpenPicker}
                        />
                    </div>
                    <Popover placement="left" hideArrow={true} isOpen={this.state.picker.open} target={this.state.picker.target} toggle={this.togglePicker}>
                        <PopoverBody>
                            <CloseDiv>
                                <XCircle size={24} color="black" onClick={this.togglePicker} />
                            </CloseDiv>
                            <SketchPicker color={this.state[this.state.picker.id]} onChange={(color) => this.handleChange(this.state.picker.id, color.hex)} disableAlpha={true} />
                        </PopoverBody>
                    </Popover>
                    <div className="col-sm-12 mt-4">
                        <button className="btn btn-primary" onClick={this.handleSave} >Save</button>
                        <br></br>
                        <br></br>
                        <Alert color={this.state.alertColor} isOpen={this.state.alert} toggle={this.toggle.bind(this)} > {this.state.alertMessage} </Alert>
                    </div>
                </div>
            </>
        )
    }
}

ColorSettings.contextType = AuthContext;

export default ColorSettings;