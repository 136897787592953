
const UTMInput = {
  "name": "UTMInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "campaign": "String",
    "source": "String",
    "medium": "String"
  }
};
export default UTMInput;