
const DraftOrderInvoicePreviewPayload = {
  "name": "DraftOrderInvoicePreviewPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "previewHtml": "HTML",
    "previewSubject": "HTML",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default DraftOrderInvoicePreviewPayload;