
const CustomerEmailAddress = {
  "name": "CustomerEmailAddress",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "emailAddress": "String",
    "marketingState": "CustomerEmailAddressMarketingState",
    "marketingUnsubscribeUrl": "URL",
    "openTrackingLevel": "CustomerEmailAddressOpenTrackingLevel",
    "openTrackingUrl": "URL"
  },
  "implementsNode": false
};
export default CustomerEmailAddress;