import React, { Component } from 'react';
import AvField from 'availity-reactstrap-validation/lib/AvField';

class FormHandleInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            labelText: props.labelText,
            url: props.url,
            required: props.required,
            name: props.name
        }
    }

    componentDidMount = () => {
        const { labelText, url, required, name } = this.props;
        this.setState({
            labelText,
            url,
            required,
            name
        });
    }

    componentDidUpdate = (_prevProps) => {
        if (this.props !== _prevProps) {
            this.setState({
                ...this.props
            });
        }

    }

    render() {

        const { labelText, url, required, name } = this.state;

        return <div className="form-group d-flex flex-column handle-input" style={{ alignItems: "start", margin: 0 }}>
            {labelText && <label className="mb-2">{labelText}</label>}
            <div className="form-field d-inline-flex form-control align-items-center" style={{ paddingTop: 0, paddingBottom: 0, paddingRight: 0 }}>
                <div style={{ color: "#939191" }} className="mb-0">{url}</div>
                <div style={{ flex: "1" }}>
                    <AvField
                        className="form-control mb-0"
                        name={name}
                        type="text"
                        required={required}
                        style={{ border: "none" }}
                    />
                </div>
            </div>
        </div>
    }

}

export default FormHandleInput;