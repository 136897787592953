
const LocationActivatePayload = {
  "name": "LocationActivatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "location": "Location",
    "locationActivateUserErrors": "LocationActivateUserError"
  },
  "implementsNode": false
};
export default LocationActivatePayload;