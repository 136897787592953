
const ExternalVideo = {
  "name": "ExternalVideo",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "alt": "String",
    "embedUrl": "URL",
    "embeddedUrl": "URL",
    "host": "MediaHost",
    "id": "ID",
    "mediaContentType": "MediaContentType",
    "originUrl": "URL",
    "presentation": "MediaPresentation",
    "previewImage": "Image"
  },
  "implementsNode": true
};
export default ExternalVideo;