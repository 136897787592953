
const CheckoutBrandingColorScheme = {
  "name": "CheckoutBrandingColorScheme",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "base": "CheckoutBrandingColorRoles",
    "control": "CheckoutBrandingControlColorRoles",
    "primaryButton": "CheckoutBrandingButtonColorRoles",
    "secondaryButton": "CheckoutBrandingButtonColorRoles"
  },
  "implementsNode": false
};
export default CheckoutBrandingColorScheme;