
const DelegateAccessToken = {
  "name": "DelegateAccessToken",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "accessScopes": "String",
    "accessToken": "String",
    "createdAt": "DateTime"
  },
  "implementsNode": false
};
export default DelegateAccessToken;