
const CalculatedOrder = {
  "name": "CalculatedOrder",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "addedDiscountApplications": "CalculatedDiscountApplicationConnection",
    "addedLineItems": "CalculatedLineItemConnection",
    "cartDiscountAmountSet": "MoneyBag",
    "committed": "Boolean",
    "id": "ID",
    "lineItems": "CalculatedLineItemConnection",
    "notificationPreviewHtml": "HTML",
    "notificationPreviewTitle": "String",
    "order": "Order",
    "originalOrder": "Order",
    "shippingLines": "CalculatedShippingLine",
    "stagedChanges": "OrderStagedChangeConnection",
    "subtotalLineItemsQuantity": "Int",
    "subtotalPriceSet": "MoneyBag",
    "taxLines": "TaxLine",
    "totalOutstandingSet": "MoneyBag",
    "totalPriceSet": "MoneyBag"
  },
  "implementsNode": true
};
export default CalculatedOrder;