
const Job = {
  "name": "Job",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "done": "Boolean",
    "id": "ID",
    "query": "QueryRoot"
  },
  "implementsNode": false
};
export default Job;