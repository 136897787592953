
const CustomerEmailMarketingConsentState = {
  "name": "CustomerEmailMarketingConsentState",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "consentUpdatedAt": "DateTime",
    "marketingOptInLevel": "CustomerMarketingOptInLevel",
    "marketingState": "CustomerEmailMarketingState"
  },
  "implementsNode": false
};
export default CustomerEmailMarketingConsentState;