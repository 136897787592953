
const CompanyAddress = {
  "name": "CompanyAddress",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "address1": "String",
    "address2": "String",
    "city": "String",
    "companyName": "String",
    "country": "String",
    "countryCode": "CountryCode",
    "createdAt": "DateTime",
    "firstName": "String",
    "formattedAddress": "String",
    "formattedArea": "String",
    "id": "ID",
    "lastName": "String",
    "phone": "String",
    "province": "String",
    "recipient": "String",
    "updatedAt": "DateTime",
    "zip": "String",
    "zoneCode": "String"
  },
  "implementsNode": true
};
export default CompanyAddress;