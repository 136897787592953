
const ReturnRefundPayload = {
  "name": "ReturnRefundPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "refund": "Refund",
    "userErrors": "ReturnUserError"
  },
  "implementsNode": false
};
export default ReturnRefundPayload;