
const OrderRisk = {
  "name": "OrderRisk",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "display": "Boolean",
    "level": "OrderRiskLevel",
    "message": "String"
  },
  "implementsNode": false
};
export default OrderRisk;