
const CustomerReplaceTaxExemptionsPayload = {
  "name": "CustomerReplaceTaxExemptionsPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customer": "Customer",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default CustomerReplaceTaxExemptionsPayload;