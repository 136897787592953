
const LocationDeactivateUserError = {
  "name": "LocationDeactivateUserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "LocationDeactivateUserErrorCode",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
export default LocationDeactivateUserError;