
const SubscriptionDiscountAllocation = {
  "name": "SubscriptionDiscountAllocation",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "amount": "MoneyV2",
    "discount": "SubscriptionDiscount"
  },
  "implementsNode": false
};
export default SubscriptionDiscountAllocation;