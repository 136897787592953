
const WebhookPubSubEndpoint = {
  "name": "WebhookPubSubEndpoint",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "pubSubProject": "String",
    "pubSubTopic": "String"
  },
  "implementsNode": false
};
export default WebhookPubSubEndpoint;