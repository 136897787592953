
const MetafieldReferenceEdge = {
  "name": "MetafieldReferenceEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "MetafieldReference"
  },
  "implementsNode": false
};
export default MetafieldReferenceEdge;