
const ServerPixelDeletePayload = {
  "name": "ServerPixelDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedServerPixelId": "ID",
    "userErrors": "ErrorsServerPixelUserError"
  },
  "implementsNode": false
};
export default ServerPixelDeletePayload;