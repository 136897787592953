
const WebPixelDeletePayload = {
  "name": "WebPixelDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedWebPixelId": "ID",
    "userErrors": "ErrorsWebPixelUserError"
  },
  "implementsNode": false
};
export default WebPixelDeletePayload;