
const CartTransformEligibleOperations = {
  "name": "CartTransformEligibleOperations",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "expandOperation": "Boolean",
    "mergeOperation": "Boolean",
    "updateOperation": "Boolean"
  },
  "implementsNode": false
};
export default CartTransformEligibleOperations;