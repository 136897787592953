
const CartLineEstimatedCost = {
  "name": "CartLineEstimatedCost",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "amount": "MoneyV2",
    "compareAtAmount": "MoneyV2",
    "subtotalAmount": "MoneyV2",
    "totalAmount": "MoneyV2"
  },
  "implementsNode": false
};
export default CartLineEstimatedCost;