
const EventEdge = {
  "name": "EventEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "Event"
  },
  "implementsNode": false
};
export default EventEdge;