
const DeliveryLocationLocalPickupEnableInput = {
  "name": "DeliveryLocationLocalPickupEnableInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "locationId": "ID",
    "pickupTime": "DeliveryLocalPickupTime",
    "instructions": "String"
  }
};
export default DeliveryLocationLocalPickupEnableInput;