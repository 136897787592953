
const CurrencySetting = {
  "name": "CurrencySetting",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "currencyCode": "CurrencyCode",
    "currencyName": "String",
    "enabled": "Boolean",
    "rateUpdatedAt": "DateTime"
  },
  "implementsNode": false
};
export default CurrencySetting;