import React, { Component } from "react";
import { AvField } from "availity-reactstrap-validation";
import { getNetlifySnippetByTitle, addNetlifySnippet, updateNetlifySnippet } from '../../../querys/netlify-rest';
import { AuthContext } from '../../../auth/auth'
import { Alert } from "reactstrap";
import { fetchCollectionsFromSnippet } from "../../utils/collection"
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import Loader from "../../loader/loader";

const snippetTitle = "Category Grid"

class CustomizeCollectionGrid extends Component {
  // eslint-disable-next-line no-useless-constructor


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      collections: [],
      snippetId: null,
      collectionGrid: {
        headline: "",
        headlinePlaceholder: "Popular Categories",
        collections: [
          {
            id: "collection-1",
            categoryId: ""
          },
          {
            id: "collection-2",
            categoryId: ""
          },
          {
            id: "collection-3",
            categoryId: ""
          },
          {
            id: "collection-4",
            categoryId: ""
          },
          {
            id: "collection-5",
            categoryId: ""
          },
          {
            id: "collection-6",
            categoryId: ""
          }
        ]
      },
      alert: false,
      alertMessage: "",
      alertColor: ""
    }
  }

  componentDidMount = async () => {

    const fields = `
        id
        title
        description
        handle
    `;

    fetchCollectionsFromSnippet(this.context.reseller_site_id, fields).then(response => {
      const collections = response;
      this.setState({ collections });

      this.fetchNetlifySnippet();

    })
      .catch(error => {
        this.setState({
          loading: false,
          alert: true,
          alertMessage: "Error fetching category list: " + error,
          alertColor: "danger"
        })
      })
  }



  fetchNetlifySnippet = () => {
    getNetlifySnippetByTitle(this.context.reseller_site_id, snippetTitle)
      .then((response) => {
        if (response.id && response.general) {
          const collectionGrid = response.general.replace("<script>window.categoryGrid=", "").replace("</script>", "");
          this.setState({
            snippetId: response.id,
            collectionGrid: JSON.parse(collectionGrid),
            loading: false
          });
        } else {
          this.setState({
            loading: false
          });
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
          alert: true,
          alertMessage: "Error fetching category grid: " + error,
          alertColor: "danger"
        })
      })
  }

  handleHeadlineChange = (ev) => {
    let { collectionGrid } = this.state

    const value = ev.target.value

    collectionGrid.headline = value

    this.setState({ collectionGrid })

  }

  handleCollectionChange = (ev, collectionId) => {

    const { collectionGrid } = this.state

    const itemIndex = collectionGrid.collections.findIndex(c => c.id === collectionId)

    if (itemIndex > -1) {
      collectionGrid.collections[itemIndex].categoryId = ev.target.value
    }

    this.setState({ collectionGrid })

  }

  handleSave = (_event, values) => {

    this.setState({
      alert: true,
      alertMessage: "Updating category grid...",
      alertColor: "secondary"
    });

    const collectionGrid = {
      headline: values.headline,
      headlinePlaceholder: "Popular Categories",
      collections: [
        {
          id: "collection-1",
          categoryId: values["collection-1"]
        },
        {
          id: "collection-2",
          categoryId: values["collection-2"]
        },
        {
          id: "collection-3",
          categoryId: values["collection-3"]
        },
        {
          id: "collection-4",
          categoryId: values["collection-4"]
        },
        {
          id: "collection-5",
          categoryId: values["collection-5"]
        },
        {
          id: "collection-6",
          categoryId: values["collection-6"]
        }
      ]
    }

    const { snippetId } = this.state

    const content = `\u003Cscript\u003Ewindow.categoryGrid=${JSON.stringify(collectionGrid)}\u003C/script\u003E`;
    if (snippetId) {

      updateNetlifySnippet(this.context.reseller_site_id, snippetTitle, content, snippetId).then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.setState({
            alert: true,
            alertMessage: "Success: Your Category Grid was updated on your site.",
            alertColor: "success",
            collectionGrid
          });
          // Fetch the Snippet ID and update state
          this.fetchNetlifySnippet();
        } else {
          this.setState({
            alert: true,
            alertMessage: response.message,
            alertColor: "danger"
          });
        }
      })

    } else {
      addNetlifySnippet(this.context.reseller_site_id, snippetTitle, content).then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.setState({
            alert: true,
            alertMessage: "Success: Your Category Grid was updated on your site.",
            alertColor: "success",
            collectionGrid
          });
          // Fetch the Snippet ID and update state
          this.fetchNetlifySnippet();
        } else {
          this.setState({
            alert: true,
            alertMessage: response.message,
            alertColor: "danger"
          });
        }
      })
    }

  }

  toggle() {
    this.setState({
      alert: !this.state.alert
    });
  }

  render() {

    if (this.state.loading) return <Loader />

    const { collections, collectionGrid } = this.state

    return (
      <div className="card">
        <div className="card-header">
          <h5>Category Grid</h5>
        </div>
        <div className="card-body pt-0">
          <div className="row">
            <div className="col-sm-12 col-md-3 col-xl-2 text-secondary mt-4">
              The Category Grid displays up to 6 categories for easy
              navigation on the home page. You can set these categories here.
              If no categories are selected, then this component will not
              display on the homepage.
            </div>
            <AvForm
              model={collectionGrid}
              onValidSubmit={this.handleSave}
              className="col-sm-12 col-md-6 col-xl-5 offset-xl-1 mt-4"
            >
              <div className="row">
                <div className="col-md-12">
                  <AvField
                    className="form-control my-1"
                    id="validationCustom-category-grid"
                    type="input"
                    required=""
                    placeholder={collectionGrid.headlinePlaceholder}
                    name="headline"
                    onChange={this.handleHeadlineChange}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12">
                  {
                    collectionGrid.collections.map((c, i) => {
                      if (collections.findIndex(co => btoa(co.id) === c.categoryId) < 0) c.categoryId = "";
                      return <AvField key={`collection-${i + 1}`} type="select" name={`collection-${i + 1}`} onChange={(ev) => this.handleCollectionChange(ev, c.id)} value={c.categoryId}>
                        <option value=""> Select a category </option>
                        {collections.map(collection => <option key={collection.id} value={btoa(collection.id)}>{collection.title}</option>)}
                      </AvField>
                    })
                  }
                  <button className="btn btn-primary mt-3 mb-2" type="submit">
                    Save
                  </button>
                  <br></br>
                  <br></br>
                  <Alert color={this.state.alertColor} isOpen={this.state.alert} toggle={this.toggle.bind(this)} > {this.state.alertMessage} </Alert>
                </div>
              </div>
            </AvForm >
          </div>
        </div>
      </div >
    );
  }
}

CustomizeCollectionGrid.contextType = AuthContext

export default CustomizeCollectionGrid;
