
const CalculatedShippingLine = {
  "name": "CalculatedShippingLine",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "price": "MoneyBag",
    "stagedStatus": "CalculatedShippingLineStagedStatus",
    "title": "String"
  },
  "implementsNode": false
};
export default CalculatedShippingLine;