
const MarketConnection = {
  "name": "MarketConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "MarketEdge",
    "nodes": "Market",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default MarketConnection;