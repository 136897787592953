
const MetaobjectThumbnail = {
  "name": "MetaobjectThumbnail",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "file": "File",
    "hex": "String"
  },
  "implementsNode": false
};
export default MetaobjectThumbnail;