
const WebPixelCreatePayload = {
  "name": "WebPixelCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "userErrors": "ErrorsWebPixelUserError",
    "webPixel": "WebPixel"
  },
  "implementsNode": false
};
export default WebPixelCreatePayload;