
const ProductOptionValue = {
  "name": "ProductOptionValue",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "hasVariants": "Boolean",
    "id": "ID",
    "linkedMetafieldValue": "String",
    "name": "String",
    "swatch": "ProductOptionValueSwatch",
    "translations": "Translation"
  },
  "implementsNode": true
};
export default ProductOptionValue;