
const MarketCreateInput = {
  "name": "MarketCreateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "name": "String",
    "handle": "String",
    "enabled": "Boolean",
    "regions": "MarketRegionCreateInput"
  }
};
export default MarketCreateInput;