
const SubscriptionAtomicManualDiscountInput = {
  "name": "SubscriptionAtomicManualDiscountInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "title": "String",
    "value": "SubscriptionManualDiscountValueInput",
    "recurringCycleLimit": "Int"
  }
};
export default SubscriptionAtomicManualDiscountInput;