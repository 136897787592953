
import ApiVersion from "./types/api-version";
import ApplePayWalletContentInput from "./types/apple-pay-wallet-content-input";
import ApplePayWalletHeaderInput from "./types/apple-pay-wallet-header-input";
import AppliedGiftCard from "./types/applied-gift-card";
import Article from "./types/article";
import ArticleAuthor from "./types/article-author";
import ArticleConnection from "./types/article-connection";
import ArticleEdge from "./types/article-edge";
import ArticleSortKeys from "./types/article-sort-keys";
import Attribute from "./types/attribute";
import AttributeInput from "./types/attribute-input";
import AutomaticDiscountApplication from "./types/automatic-discount-application";
import AvailableShippingRates from "./types/available-shipping-rates";
import BaseCartLine from "./types/base-cart-line";
import BaseCartLineConnection from "./types/base-cart-line-connection";
import BaseCartLineEdge from "./types/base-cart-line-edge";
import Blog from "./types/blog";
import BlogConnection from "./types/blog-connection";
import BlogEdge from "./types/blog-edge";
import BlogSortKeys from "./types/blog-sort-keys";
import Boolean from "./types/boolean";
import Brand from "./types/brand";
import BrandColorGroup from "./types/brand-color-group";
import BrandColors from "./types/brand-colors";
import BuyerInput from "./types/buyer-input";
import CardBrand from "./types/card-brand";
import Cart from "./types/cart";
import CartAttributesUpdatePayload from "./types/cart-attributes-update-payload";
import CartAutomaticDiscountAllocation from "./types/cart-automatic-discount-allocation";
import CartBuyerIdentity from "./types/cart-buyer-identity";
import CartBuyerIdentityInput from "./types/cart-buyer-identity-input";
import CartBuyerIdentityUpdatePayload from "./types/cart-buyer-identity-update-payload";
import CartCardSource from "./types/cart-card-source";
import CartCodeDiscountAllocation from "./types/cart-code-discount-allocation";
import CartCompletionAction from "./types/cart-completion-action";
import CartCompletionActionRequired from "./types/cart-completion-action-required";
import CartCompletionAttemptResult from "./types/cart-completion-attempt-result";
import CartCompletionFailed from "./types/cart-completion-failed";
import CartCompletionProcessing from "./types/cart-completion-processing";
import CartCompletionSuccess from "./types/cart-completion-success";
import CartCost from "./types/cart-cost";
import CartCreatePayload from "./types/cart-create-payload";
import CartCustomDiscountAllocation from "./types/cart-custom-discount-allocation";
import CartDeliveryGroup from "./types/cart-delivery-group";
import CartDeliveryGroupConnection from "./types/cart-delivery-group-connection";
import CartDeliveryGroupEdge from "./types/cart-delivery-group-edge";
import CartDeliveryGroupType from "./types/cart-delivery-group-type";
import CartDeliveryOption from "./types/cart-delivery-option";
import CartDirectPaymentMethodInput from "./types/cart-direct-payment-method-input";
import CartDiscountAllocation from "./types/cart-discount-allocation";
import CartDiscountCode from "./types/cart-discount-code";
import CartDiscountCodesUpdatePayload from "./types/cart-discount-codes-update-payload";
import CartErrorCode from "./types/cart-error-code";
import CartEstimatedCost from "./types/cart-estimated-cost";
import CartFreePaymentMethodInput from "./types/cart-free-payment-method-input";
import CartInput from "./types/cart-input";
import CartInputMetafieldInput from "./types/cart-input-metafield-input";
import CartLine from "./types/cart-line";
import CartLineCost from "./types/cart-line-cost";
import CartLineEstimatedCost from "./types/cart-line-estimated-cost";
import CartLineInput from "./types/cart-line-input";
import CartLineUpdateInput from "./types/cart-line-update-input";
import CartLinesAddPayload from "./types/cart-lines-add-payload";
import CartLinesRemovePayload from "./types/cart-lines-remove-payload";
import CartLinesUpdatePayload from "./types/cart-lines-update-payload";
import CartMetafieldDeleteInput from "./types/cart-metafield-delete-input";
import CartMetafieldDeletePayload from "./types/cart-metafield-delete-payload";
import CartMetafieldsSetInput from "./types/cart-metafields-set-input";
import CartMetafieldsSetPayload from "./types/cart-metafields-set-payload";
import CartNoteUpdatePayload from "./types/cart-note-update-payload";
import CartPaymentInput from "./types/cart-payment-input";
import CartPaymentUpdatePayload from "./types/cart-payment-update-payload";
import CartSelectedDeliveryOptionInput from "./types/cart-selected-delivery-option-input";
import CartSelectedDeliveryOptionsUpdatePayload from "./types/cart-selected-delivery-options-update-payload";
import CartSubmitForCompletionPayload from "./types/cart-submit-for-completion-payload";
import CartSubmitForCompletionResult from "./types/cart-submit-for-completion-result";
import CartUserError from "./types/cart-user-error";
import CartWalletPaymentMethodInput from "./types/cart-wallet-payment-method-input";
import Checkout from "./types/checkout";
import CheckoutAttributesUpdateV2Input from "./types/checkout-attributes-update-v-2-input";
import CheckoutAttributesUpdateV2Payload from "./types/checkout-attributes-update-v-2-payload";
import CheckoutBuyerIdentity from "./types/checkout-buyer-identity";
import CheckoutBuyerIdentityInput from "./types/checkout-buyer-identity-input";
import CheckoutCompleteFreePayload from "./types/checkout-complete-free-payload";
import CheckoutCompleteWithCreditCardV2Payload from "./types/checkout-complete-with-credit-card-v-2-payload";
import CheckoutCompleteWithTokenizedPaymentV3Payload from "./types/checkout-complete-with-tokenized-payment-v-3-payload";
import CheckoutCreateInput from "./types/checkout-create-input";
import CheckoutCreatePayload from "./types/checkout-create-payload";
import CheckoutCustomerAssociateV2Payload from "./types/checkout-customer-associate-v-2-payload";
import CheckoutCustomerDisassociateV2Payload from "./types/checkout-customer-disassociate-v-2-payload";
import CheckoutDiscountCodeApplyV2Payload from "./types/checkout-discount-code-apply-v-2-payload";
import CheckoutDiscountCodeRemovePayload from "./types/checkout-discount-code-remove-payload";
import CheckoutEmailUpdateV2Payload from "./types/checkout-email-update-v-2-payload";
import CheckoutErrorCode from "./types/checkout-error-code";
import CheckoutGiftCardRemoveV2Payload from "./types/checkout-gift-card-remove-v-2-payload";
import CheckoutGiftCardsAppendPayload from "./types/checkout-gift-cards-append-payload";
import CheckoutLineItem from "./types/checkout-line-item";
import CheckoutLineItemConnection from "./types/checkout-line-item-connection";
import CheckoutLineItemEdge from "./types/checkout-line-item-edge";
import CheckoutLineItemInput from "./types/checkout-line-item-input";
import CheckoutLineItemUpdateInput from "./types/checkout-line-item-update-input";
import CheckoutLineItemsAddPayload from "./types/checkout-line-items-add-payload";
import CheckoutLineItemsRemovePayload from "./types/checkout-line-items-remove-payload";
import CheckoutLineItemsReplacePayload from "./types/checkout-line-items-replace-payload";
import CheckoutLineItemsUpdatePayload from "./types/checkout-line-items-update-payload";
import CheckoutShippingAddressUpdateV2Payload from "./types/checkout-shipping-address-update-v-2-payload";
import CheckoutShippingLineUpdatePayload from "./types/checkout-shipping-line-update-payload";
import CheckoutUserError from "./types/checkout-user-error";
import Collection from "./types/collection";
import CollectionConnection from "./types/collection-connection";
import CollectionEdge from "./types/collection-edge";
import CollectionSortKeys from "./types/collection-sort-keys";
import Color from "./types/color";
import Comment from "./types/comment";
import CommentAuthor from "./types/comment-author";
import CommentConnection from "./types/comment-connection";
import CommentEdge from "./types/comment-edge";
import Company from "./types/company";
import CompanyContact from "./types/company-contact";
import CompanyLocation from "./types/company-location";
import CompletePaymentChallenge from "./types/complete-payment-challenge";
import CompletionError from "./types/completion-error";
import CompletionErrorCode from "./types/completion-error-code";
import ComponentizableCartLine from "./types/componentizable-cart-line";
import Country from "./types/country";
import CountryCode from "./types/country-code";
import CreditCard from "./types/credit-card";
import CreditCardPaymentInputV2 from "./types/credit-card-payment-input-v-2";
import CropRegion from "./types/crop-region";
import Currency from "./types/currency";
import CurrencyCode from "./types/currency-code";
import Customer from "./types/customer";
import CustomerAccessToken from "./types/customer-access-token";
import CustomerAccessTokenCreateInput from "./types/customer-access-token-create-input";
import CustomerAccessTokenCreatePayload from "./types/customer-access-token-create-payload";
import CustomerAccessTokenCreateWithMultipassPayload from "./types/customer-access-token-create-with-multipass-payload";
import CustomerAccessTokenDeletePayload from "./types/customer-access-token-delete-payload";
import CustomerAccessTokenRenewPayload from "./types/customer-access-token-renew-payload";
import CustomerActivateByUrlPayload from "./types/customer-activate-by-url-payload";
import CustomerActivateInput from "./types/customer-activate-input";
import CustomerActivatePayload from "./types/customer-activate-payload";
import CustomerAddressCreatePayload from "./types/customer-address-create-payload";
import CustomerAddressDeletePayload from "./types/customer-address-delete-payload";
import CustomerAddressUpdatePayload from "./types/customer-address-update-payload";
import CustomerCreateInput from "./types/customer-create-input";
import CustomerCreatePayload from "./types/customer-create-payload";
import CustomerDefaultAddressUpdatePayload from "./types/customer-default-address-update-payload";
import CustomerErrorCode from "./types/customer-error-code";
import CustomerRecoverPayload from "./types/customer-recover-payload";
import CustomerResetByUrlPayload from "./types/customer-reset-by-url-payload";
import CustomerResetInput from "./types/customer-reset-input";
import CustomerResetPayload from "./types/customer-reset-payload";
import CustomerUpdateInput from "./types/customer-update-input";
import CustomerUpdatePayload from "./types/customer-update-payload";
import CustomerUserError from "./types/customer-user-error";
import DateTime from "./types/date-time";
import Decimal from "./types/decimal";
import DeliveryAddress from "./types/delivery-address";
import DeliveryAddressInput from "./types/delivery-address-input";
import DeliveryAddressValidationStrategy from "./types/delivery-address-validation-strategy";
import DeliveryMethodType from "./types/delivery-method-type";
import DigitalWallet from "./types/digital-wallet";
import DiscountAllocation from "./types/discount-allocation";
import DiscountApplication from "./types/discount-application";
import DiscountApplicationAllocationMethod from "./types/discount-application-allocation-method";
import DiscountApplicationConnection from "./types/discount-application-connection";
import DiscountApplicationEdge from "./types/discount-application-edge";
import DiscountApplicationTargetSelection from "./types/discount-application-target-selection";
import DiscountApplicationTargetType from "./types/discount-application-target-type";
import DiscountCodeApplication from "./types/discount-code-application";
import DisplayableError from "./types/displayable-error";
import Domain from "./types/domain";
import ExternalVideo from "./types/external-video";
import Filter from "./types/filter";
import FilterPresentation from "./types/filter-presentation";
import FilterType from "./types/filter-type";
import FilterValue from "./types/filter-value";
import Float from "./types/float";
import Fulfillment from "./types/fulfillment";
import FulfillmentLineItem from "./types/fulfillment-line-item";
import FulfillmentLineItemConnection from "./types/fulfillment-line-item-connection";
import FulfillmentLineItemEdge from "./types/fulfillment-line-item-edge";
import FulfillmentTrackingInfo from "./types/fulfillment-tracking-info";
import GenericFile from "./types/generic-file";
import GeoCoordinateInput from "./types/geo-coordinate-input";
import HTML from "./types/html";
import HasMetafields from "./types/has-metafields";
import HasMetafieldsIdentifier from "./types/has-metafields-identifier";
import ID from "./types/id";
import Image from "./types/image";
import ImageConnection from "./types/image-connection";
import ImageContentType from "./types/image-content-type";
import ImageEdge from "./types/image-edge";
import ImageTransformInput from "./types/image-transform-input";
import InContextAnnotation from "./types/in-context-annotation";
import InContextAnnotationType from "./types/in-context-annotation-type";
import Int from "./types/int";
import JSON from "./types/json";
import Language from "./types/language";
import LanguageCode from "./types/language-code";
import Localization from "./types/localization";
import Location from "./types/location";
import LocationAddress from "./types/location-address";
import LocationConnection from "./types/location-connection";
import LocationEdge from "./types/location-edge";
import LocationSortKeys from "./types/location-sort-keys";
import MailingAddress from "./types/mailing-address";
import MailingAddressConnection from "./types/mailing-address-connection";
import MailingAddressEdge from "./types/mailing-address-edge";
import MailingAddressInput from "./types/mailing-address-input";
import ManualDiscountApplication from "./types/manual-discount-application";
import Market from "./types/market";
import Media from "./types/media";
import MediaConnection from "./types/media-connection";
import MediaContentType from "./types/media-content-type";
import MediaEdge from "./types/media-edge";
import MediaHost from "./types/media-host";
import MediaImage from "./types/media-image";
import MediaPresentation from "./types/media-presentation";
import MediaPresentationFormat from "./types/media-presentation-format";
import Menu from "./types/menu";
import MenuItem from "./types/menu-item";
import MenuItemResource from "./types/menu-item-resource";
import MenuItemType from "./types/menu-item-type";
import Merchandise from "./types/merchandise";
import Metafield from "./types/metafield";
import MetafieldDeleteErrorCode from "./types/metafield-delete-error-code";
import MetafieldDeleteUserError from "./types/metafield-delete-user-error";
import MetafieldFilter from "./types/metafield-filter";
import MetafieldParentResource from "./types/metafield-parent-resource";
import MetafieldReference from "./types/metafield-reference";
import MetafieldReferenceConnection from "./types/metafield-reference-connection";
import MetafieldReferenceEdge from "./types/metafield-reference-edge";
import MetafieldsSetUserError from "./types/metafields-set-user-error";
import MetafieldsSetUserErrorCode from "./types/metafields-set-user-error-code";
import Metaobject from "./types/metaobject";
import MetaobjectConnection from "./types/metaobject-connection";
import MetaobjectEdge from "./types/metaobject-edge";
import MetaobjectField from "./types/metaobject-field";
import MetaobjectHandleInput from "./types/metaobject-handle-input";
import MetaobjectSEO from "./types/metaobject-seo";
import Model3d from "./types/model-3-d";
import Model3dSource from "./types/model-3-d-source";
import MoneyInput from "./types/money-input";
import MoneyV2 from "./types/money-v-2";
import Mutation from "./types/mutation";
import Node from "./types/node";
import OnlineStorePublishable from "./types/online-store-publishable";
import Order from "./types/order";
import OrderCancelReason from "./types/order-cancel-reason";
import OrderConnection from "./types/order-connection";
import OrderEdge from "./types/order-edge";
import OrderFinancialStatus from "./types/order-financial-status";
import OrderFulfillmentStatus from "./types/order-fulfillment-status";
import OrderLineItem from "./types/order-line-item";
import OrderLineItemConnection from "./types/order-line-item-connection";
import OrderLineItemEdge from "./types/order-line-item-edge";
import OrderSortKeys from "./types/order-sort-keys";
import Page from "./types/page";
import PageConnection from "./types/page-connection";
import PageEdge from "./types/page-edge";
import PageInfo from "./types/page-info";
import PageSortKeys from "./types/page-sort-keys";
import Payment from "./types/payment";
import PaymentSettings from "./types/payment-settings";
import PaymentTokenType from "./types/payment-token-type";
import PredictiveSearchLimitScope from "./types/predictive-search-limit-scope";
import PredictiveSearchResult from "./types/predictive-search-result";
import PredictiveSearchType from "./types/predictive-search-type";
import PriceRangeFilter from "./types/price-range-filter";
import PricingPercentageValue from "./types/pricing-percentage-value";
import PricingValue from "./types/pricing-value";
import Product from "./types/product";
import ProductCollectionSortKeys from "./types/product-collection-sort-keys";
import ProductConnection from "./types/product-connection";
import ProductEdge from "./types/product-edge";
import ProductFilter from "./types/product-filter";
import ProductImageSortKeys from "./types/product-image-sort-keys";
import ProductMediaSortKeys from "./types/product-media-sort-keys";
import ProductOption from "./types/product-option";
import ProductPriceRange from "./types/product-price-range";
import ProductRecommendationIntent from "./types/product-recommendation-intent";
import ProductSortKeys from "./types/product-sort-keys";
import ProductVariant from "./types/product-variant";
import ProductVariantConnection from "./types/product-variant-connection";
import ProductVariantEdge from "./types/product-variant-edge";
import ProductVariantSortKeys from "./types/product-variant-sort-keys";
import PurchasingCompany from "./types/purchasing-company";
import QuantityPriceBreak from "./types/quantity-price-break";
import QuantityPriceBreakConnection from "./types/quantity-price-break-connection";
import QuantityPriceBreakEdge from "./types/quantity-price-break-edge";
import QuantityRule from "./types/quantity-rule";
import QueryRoot from "./types/query-root";
import SEO from "./types/seo";
import ScriptDiscountApplication from "./types/script-discount-application";
import SearchPrefixQueryType from "./types/search-prefix-query-type";
import SearchQuerySuggestion from "./types/search-query-suggestion";
import SearchResultItem from "./types/search-result-item";
import SearchResultItemConnection from "./types/search-result-item-connection";
import SearchResultItemEdge from "./types/search-result-item-edge";
import SearchSortKeys from "./types/search-sort-keys";
import SearchType from "./types/search-type";
import SearchUnavailableProductsType from "./types/search-unavailable-products-type";
import SearchableField from "./types/searchable-field";
import SelectedOption from "./types/selected-option";
import SelectedOptionInput from "./types/selected-option-input";
import SellingPlan from "./types/selling-plan";
import SellingPlanAllocation from "./types/selling-plan-allocation";
import SellingPlanAllocationConnection from "./types/selling-plan-allocation-connection";
import SellingPlanAllocationEdge from "./types/selling-plan-allocation-edge";
import SellingPlanAllocationPriceAdjustment from "./types/selling-plan-allocation-price-adjustment";
import SellingPlanCheckoutCharge from "./types/selling-plan-checkout-charge";
import SellingPlanCheckoutChargePercentageValue from "./types/selling-plan-checkout-charge-percentage-value";
import SellingPlanCheckoutChargeType from "./types/selling-plan-checkout-charge-type";
import SellingPlanCheckoutChargeValue from "./types/selling-plan-checkout-charge-value";
import SellingPlanConnection from "./types/selling-plan-connection";
import SellingPlanEdge from "./types/selling-plan-edge";
import SellingPlanFixedAmountPriceAdjustment from "./types/selling-plan-fixed-amount-price-adjustment";
import SellingPlanFixedPriceAdjustment from "./types/selling-plan-fixed-price-adjustment";
import SellingPlanGroup from "./types/selling-plan-group";
import SellingPlanGroupConnection from "./types/selling-plan-group-connection";
import SellingPlanGroupEdge from "./types/selling-plan-group-edge";
import SellingPlanGroupOption from "./types/selling-plan-group-option";
import SellingPlanOption from "./types/selling-plan-option";
import SellingPlanPercentagePriceAdjustment from "./types/selling-plan-percentage-price-adjustment";
import SellingPlanPriceAdjustment from "./types/selling-plan-price-adjustment";
import SellingPlanPriceAdjustmentValue from "./types/selling-plan-price-adjustment-value";
import ShippingRate from "./types/shipping-rate";
import Shop from "./types/shop";
import ShopPayWalletContentInput from "./types/shop-pay-wallet-content-input";
import ShopPolicy from "./types/shop-policy";
import ShopPolicyWithDefault from "./types/shop-policy-with-default";
import StoreAvailability from "./types/store-availability";
import StoreAvailabilityConnection from "./types/store-availability-connection";
import StoreAvailabilityEdge from "./types/store-availability-edge";
import String from "./types/string";
import StringConnection from "./types/string-connection";
import StringEdge from "./types/string-edge";
import SubmissionError from "./types/submission-error";
import SubmissionErrorCode from "./types/submission-error-code";
import SubmitAlreadyAccepted from "./types/submit-already-accepted";
import SubmitFailed from "./types/submit-failed";
import SubmitSuccess from "./types/submit-success";
import SubmitThrottled from "./types/submit-throttled";
import Swatch from "./types/swatch";
import TokenizedPaymentInputV3 from "./types/tokenized-payment-input-v-3";
import Trackable from "./types/trackable";
import Transaction from "./types/transaction";
import TransactionKind from "./types/transaction-kind";
import TransactionStatus from "./types/transaction-status";
import URL from "./types/url";
import UnitPriceMeasurement from "./types/unit-price-measurement";
import UnitPriceMeasurementMeasuredType from "./types/unit-price-measurement-measured-type";
import UnitPriceMeasurementMeasuredUnit from "./types/unit-price-measurement-measured-unit";
import UnitSystem from "./types/unit-system";
import UnsignedInt64 from "./types/unsigned-int-64";
import UrlRedirect from "./types/url-redirect";
import UrlRedirectConnection from "./types/url-redirect-connection";
import UrlRedirectEdge from "./types/url-redirect-edge";
import UserError from "./types/user-error";
import VariantOptionFilter from "./types/variant-option-filter";
import Video from "./types/video";
import VideoSource from "./types/video-source";
import WeightUnit from "./types/weight-unit";
import __Directive from "./types/directive";
import __DirectiveLocation from "./types/directive-location";
import __EnumValue from "./types/enum-value";
import __Field from "./types/field";
import __InputValue from "./types/input-value";
import __Schema from "./types/schema";
import __Type from "./types/type";
import __TypeKind from "./types/type-kind";
const Types = {
  types: {}
};
Types.types["ApiVersion"] = ApiVersion;
Types.types["ApplePayWalletContentInput"] = ApplePayWalletContentInput;
Types.types["ApplePayWalletHeaderInput"] = ApplePayWalletHeaderInput;
Types.types["AppliedGiftCard"] = AppliedGiftCard;
Types.types["Article"] = Article;
Types.types["ArticleAuthor"] = ArticleAuthor;
Types.types["ArticleConnection"] = ArticleConnection;
Types.types["ArticleEdge"] = ArticleEdge;
Types.types["ArticleSortKeys"] = ArticleSortKeys;
Types.types["Attribute"] = Attribute;
Types.types["AttributeInput"] = AttributeInput;
Types.types["AutomaticDiscountApplication"] = AutomaticDiscountApplication;
Types.types["AvailableShippingRates"] = AvailableShippingRates;
Types.types["BaseCartLine"] = BaseCartLine;
Types.types["BaseCartLineConnection"] = BaseCartLineConnection;
Types.types["BaseCartLineEdge"] = BaseCartLineEdge;
Types.types["Blog"] = Blog;
Types.types["BlogConnection"] = BlogConnection;
Types.types["BlogEdge"] = BlogEdge;
Types.types["BlogSortKeys"] = BlogSortKeys;
Types.types["Boolean"] = Boolean;
Types.types["Brand"] = Brand;
Types.types["BrandColorGroup"] = BrandColorGroup;
Types.types["BrandColors"] = BrandColors;
Types.types["BuyerInput"] = BuyerInput;
Types.types["CardBrand"] = CardBrand;
Types.types["Cart"] = Cart;
Types.types["CartAttributesUpdatePayload"] = CartAttributesUpdatePayload;
Types.types["CartAutomaticDiscountAllocation"] = CartAutomaticDiscountAllocation;
Types.types["CartBuyerIdentity"] = CartBuyerIdentity;
Types.types["CartBuyerIdentityInput"] = CartBuyerIdentityInput;
Types.types["CartBuyerIdentityUpdatePayload"] = CartBuyerIdentityUpdatePayload;
Types.types["CartCardSource"] = CartCardSource;
Types.types["CartCodeDiscountAllocation"] = CartCodeDiscountAllocation;
Types.types["CartCompletionAction"] = CartCompletionAction;
Types.types["CartCompletionActionRequired"] = CartCompletionActionRequired;
Types.types["CartCompletionAttemptResult"] = CartCompletionAttemptResult;
Types.types["CartCompletionFailed"] = CartCompletionFailed;
Types.types["CartCompletionProcessing"] = CartCompletionProcessing;
Types.types["CartCompletionSuccess"] = CartCompletionSuccess;
Types.types["CartCost"] = CartCost;
Types.types["CartCreatePayload"] = CartCreatePayload;
Types.types["CartCustomDiscountAllocation"] = CartCustomDiscountAllocation;
Types.types["CartDeliveryGroup"] = CartDeliveryGroup;
Types.types["CartDeliveryGroupConnection"] = CartDeliveryGroupConnection;
Types.types["CartDeliveryGroupEdge"] = CartDeliveryGroupEdge;
Types.types["CartDeliveryGroupType"] = CartDeliveryGroupType;
Types.types["CartDeliveryOption"] = CartDeliveryOption;
Types.types["CartDirectPaymentMethodInput"] = CartDirectPaymentMethodInput;
Types.types["CartDiscountAllocation"] = CartDiscountAllocation;
Types.types["CartDiscountCode"] = CartDiscountCode;
Types.types["CartDiscountCodesUpdatePayload"] = CartDiscountCodesUpdatePayload;
Types.types["CartErrorCode"] = CartErrorCode;
Types.types["CartEstimatedCost"] = CartEstimatedCost;
Types.types["CartFreePaymentMethodInput"] = CartFreePaymentMethodInput;
Types.types["CartInput"] = CartInput;
Types.types["CartInputMetafieldInput"] = CartInputMetafieldInput;
Types.types["CartLine"] = CartLine;
Types.types["CartLineCost"] = CartLineCost;
Types.types["CartLineEstimatedCost"] = CartLineEstimatedCost;
Types.types["CartLineInput"] = CartLineInput;
Types.types["CartLineUpdateInput"] = CartLineUpdateInput;
Types.types["CartLinesAddPayload"] = CartLinesAddPayload;
Types.types["CartLinesRemovePayload"] = CartLinesRemovePayload;
Types.types["CartLinesUpdatePayload"] = CartLinesUpdatePayload;
Types.types["CartMetafieldDeleteInput"] = CartMetafieldDeleteInput;
Types.types["CartMetafieldDeletePayload"] = CartMetafieldDeletePayload;
Types.types["CartMetafieldsSetInput"] = CartMetafieldsSetInput;
Types.types["CartMetafieldsSetPayload"] = CartMetafieldsSetPayload;
Types.types["CartNoteUpdatePayload"] = CartNoteUpdatePayload;
Types.types["CartPaymentInput"] = CartPaymentInput;
Types.types["CartPaymentUpdatePayload"] = CartPaymentUpdatePayload;
Types.types["CartSelectedDeliveryOptionInput"] = CartSelectedDeliveryOptionInput;
Types.types["CartSelectedDeliveryOptionsUpdatePayload"] = CartSelectedDeliveryOptionsUpdatePayload;
Types.types["CartSubmitForCompletionPayload"] = CartSubmitForCompletionPayload;
Types.types["CartSubmitForCompletionResult"] = CartSubmitForCompletionResult;
Types.types["CartUserError"] = CartUserError;
Types.types["CartWalletPaymentMethodInput"] = CartWalletPaymentMethodInput;
Types.types["Checkout"] = Checkout;
Types.types["CheckoutAttributesUpdateV2Input"] = CheckoutAttributesUpdateV2Input;
Types.types["CheckoutAttributesUpdateV2Payload"] = CheckoutAttributesUpdateV2Payload;
Types.types["CheckoutBuyerIdentity"] = CheckoutBuyerIdentity;
Types.types["CheckoutBuyerIdentityInput"] = CheckoutBuyerIdentityInput;
Types.types["CheckoutCompleteFreePayload"] = CheckoutCompleteFreePayload;
Types.types["CheckoutCompleteWithCreditCardV2Payload"] = CheckoutCompleteWithCreditCardV2Payload;
Types.types["CheckoutCompleteWithTokenizedPaymentV3Payload"] = CheckoutCompleteWithTokenizedPaymentV3Payload;
Types.types["CheckoutCreateInput"] = CheckoutCreateInput;
Types.types["CheckoutCreatePayload"] = CheckoutCreatePayload;
Types.types["CheckoutCustomerAssociateV2Payload"] = CheckoutCustomerAssociateV2Payload;
Types.types["CheckoutCustomerDisassociateV2Payload"] = CheckoutCustomerDisassociateV2Payload;
Types.types["CheckoutDiscountCodeApplyV2Payload"] = CheckoutDiscountCodeApplyV2Payload;
Types.types["CheckoutDiscountCodeRemovePayload"] = CheckoutDiscountCodeRemovePayload;
Types.types["CheckoutEmailUpdateV2Payload"] = CheckoutEmailUpdateV2Payload;
Types.types["CheckoutErrorCode"] = CheckoutErrorCode;
Types.types["CheckoutGiftCardRemoveV2Payload"] = CheckoutGiftCardRemoveV2Payload;
Types.types["CheckoutGiftCardsAppendPayload"] = CheckoutGiftCardsAppendPayload;
Types.types["CheckoutLineItem"] = CheckoutLineItem;
Types.types["CheckoutLineItemConnection"] = CheckoutLineItemConnection;
Types.types["CheckoutLineItemEdge"] = CheckoutLineItemEdge;
Types.types["CheckoutLineItemInput"] = CheckoutLineItemInput;
Types.types["CheckoutLineItemUpdateInput"] = CheckoutLineItemUpdateInput;
Types.types["CheckoutLineItemsAddPayload"] = CheckoutLineItemsAddPayload;
Types.types["CheckoutLineItemsRemovePayload"] = CheckoutLineItemsRemovePayload;
Types.types["CheckoutLineItemsReplacePayload"] = CheckoutLineItemsReplacePayload;
Types.types["CheckoutLineItemsUpdatePayload"] = CheckoutLineItemsUpdatePayload;
Types.types["CheckoutShippingAddressUpdateV2Payload"] = CheckoutShippingAddressUpdateV2Payload;
Types.types["CheckoutShippingLineUpdatePayload"] = CheckoutShippingLineUpdatePayload;
Types.types["CheckoutUserError"] = CheckoutUserError;
Types.types["Collection"] = Collection;
Types.types["CollectionConnection"] = CollectionConnection;
Types.types["CollectionEdge"] = CollectionEdge;
Types.types["CollectionSortKeys"] = CollectionSortKeys;
Types.types["Color"] = Color;
Types.types["Comment"] = Comment;
Types.types["CommentAuthor"] = CommentAuthor;
Types.types["CommentConnection"] = CommentConnection;
Types.types["CommentEdge"] = CommentEdge;
Types.types["Company"] = Company;
Types.types["CompanyContact"] = CompanyContact;
Types.types["CompanyLocation"] = CompanyLocation;
Types.types["CompletePaymentChallenge"] = CompletePaymentChallenge;
Types.types["CompletionError"] = CompletionError;
Types.types["CompletionErrorCode"] = CompletionErrorCode;
Types.types["ComponentizableCartLine"] = ComponentizableCartLine;
Types.types["Country"] = Country;
Types.types["CountryCode"] = CountryCode;
Types.types["CreditCard"] = CreditCard;
Types.types["CreditCardPaymentInputV2"] = CreditCardPaymentInputV2;
Types.types["CropRegion"] = CropRegion;
Types.types["Currency"] = Currency;
Types.types["CurrencyCode"] = CurrencyCode;
Types.types["Customer"] = Customer;
Types.types["CustomerAccessToken"] = CustomerAccessToken;
Types.types["CustomerAccessTokenCreateInput"] = CustomerAccessTokenCreateInput;
Types.types["CustomerAccessTokenCreatePayload"] = CustomerAccessTokenCreatePayload;
Types.types["CustomerAccessTokenCreateWithMultipassPayload"] = CustomerAccessTokenCreateWithMultipassPayload;
Types.types["CustomerAccessTokenDeletePayload"] = CustomerAccessTokenDeletePayload;
Types.types["CustomerAccessTokenRenewPayload"] = CustomerAccessTokenRenewPayload;
Types.types["CustomerActivateByUrlPayload"] = CustomerActivateByUrlPayload;
Types.types["CustomerActivateInput"] = CustomerActivateInput;
Types.types["CustomerActivatePayload"] = CustomerActivatePayload;
Types.types["CustomerAddressCreatePayload"] = CustomerAddressCreatePayload;
Types.types["CustomerAddressDeletePayload"] = CustomerAddressDeletePayload;
Types.types["CustomerAddressUpdatePayload"] = CustomerAddressUpdatePayload;
Types.types["CustomerCreateInput"] = CustomerCreateInput;
Types.types["CustomerCreatePayload"] = CustomerCreatePayload;
Types.types["CustomerDefaultAddressUpdatePayload"] = CustomerDefaultAddressUpdatePayload;
Types.types["CustomerErrorCode"] = CustomerErrorCode;
Types.types["CustomerRecoverPayload"] = CustomerRecoverPayload;
Types.types["CustomerResetByUrlPayload"] = CustomerResetByUrlPayload;
Types.types["CustomerResetInput"] = CustomerResetInput;
Types.types["CustomerResetPayload"] = CustomerResetPayload;
Types.types["CustomerUpdateInput"] = CustomerUpdateInput;
Types.types["CustomerUpdatePayload"] = CustomerUpdatePayload;
Types.types["CustomerUserError"] = CustomerUserError;
Types.types["DateTime"] = DateTime;
Types.types["Decimal"] = Decimal;
Types.types["DeliveryAddress"] = DeliveryAddress;
Types.types["DeliveryAddressInput"] = DeliveryAddressInput;
Types.types["DeliveryAddressValidationStrategy"] = DeliveryAddressValidationStrategy;
Types.types["DeliveryMethodType"] = DeliveryMethodType;
Types.types["DigitalWallet"] = DigitalWallet;
Types.types["DiscountAllocation"] = DiscountAllocation;
Types.types["DiscountApplication"] = DiscountApplication;
Types.types["DiscountApplicationAllocationMethod"] = DiscountApplicationAllocationMethod;
Types.types["DiscountApplicationConnection"] = DiscountApplicationConnection;
Types.types["DiscountApplicationEdge"] = DiscountApplicationEdge;
Types.types["DiscountApplicationTargetSelection"] = DiscountApplicationTargetSelection;
Types.types["DiscountApplicationTargetType"] = DiscountApplicationTargetType;
Types.types["DiscountCodeApplication"] = DiscountCodeApplication;
Types.types["DisplayableError"] = DisplayableError;
Types.types["Domain"] = Domain;
Types.types["ExternalVideo"] = ExternalVideo;
Types.types["Filter"] = Filter;
Types.types["FilterPresentation"] = FilterPresentation;
Types.types["FilterType"] = FilterType;
Types.types["FilterValue"] = FilterValue;
Types.types["Float"] = Float;
Types.types["Fulfillment"] = Fulfillment;
Types.types["FulfillmentLineItem"] = FulfillmentLineItem;
Types.types["FulfillmentLineItemConnection"] = FulfillmentLineItemConnection;
Types.types["FulfillmentLineItemEdge"] = FulfillmentLineItemEdge;
Types.types["FulfillmentTrackingInfo"] = FulfillmentTrackingInfo;
Types.types["GenericFile"] = GenericFile;
Types.types["GeoCoordinateInput"] = GeoCoordinateInput;
Types.types["HTML"] = HTML;
Types.types["HasMetafields"] = HasMetafields;
Types.types["HasMetafieldsIdentifier"] = HasMetafieldsIdentifier;
Types.types["ID"] = ID;
Types.types["Image"] = Image;
Types.types["ImageConnection"] = ImageConnection;
Types.types["ImageContentType"] = ImageContentType;
Types.types["ImageEdge"] = ImageEdge;
Types.types["ImageTransformInput"] = ImageTransformInput;
Types.types["InContextAnnotation"] = InContextAnnotation;
Types.types["InContextAnnotationType"] = InContextAnnotationType;
Types.types["Int"] = Int;
Types.types["JSON"] = JSON;
Types.types["Language"] = Language;
Types.types["LanguageCode"] = LanguageCode;
Types.types["Localization"] = Localization;
Types.types["Location"] = Location;
Types.types["LocationAddress"] = LocationAddress;
Types.types["LocationConnection"] = LocationConnection;
Types.types["LocationEdge"] = LocationEdge;
Types.types["LocationSortKeys"] = LocationSortKeys;
Types.types["MailingAddress"] = MailingAddress;
Types.types["MailingAddressConnection"] = MailingAddressConnection;
Types.types["MailingAddressEdge"] = MailingAddressEdge;
Types.types["MailingAddressInput"] = MailingAddressInput;
Types.types["ManualDiscountApplication"] = ManualDiscountApplication;
Types.types["Market"] = Market;
Types.types["Media"] = Media;
Types.types["MediaConnection"] = MediaConnection;
Types.types["MediaContentType"] = MediaContentType;
Types.types["MediaEdge"] = MediaEdge;
Types.types["MediaHost"] = MediaHost;
Types.types["MediaImage"] = MediaImage;
Types.types["MediaPresentation"] = MediaPresentation;
Types.types["MediaPresentationFormat"] = MediaPresentationFormat;
Types.types["Menu"] = Menu;
Types.types["MenuItem"] = MenuItem;
Types.types["MenuItemResource"] = MenuItemResource;
Types.types["MenuItemType"] = MenuItemType;
Types.types["Merchandise"] = Merchandise;
Types.types["Metafield"] = Metafield;
Types.types["MetafieldDeleteErrorCode"] = MetafieldDeleteErrorCode;
Types.types["MetafieldDeleteUserError"] = MetafieldDeleteUserError;
Types.types["MetafieldFilter"] = MetafieldFilter;
Types.types["MetafieldParentResource"] = MetafieldParentResource;
Types.types["MetafieldReference"] = MetafieldReference;
Types.types["MetafieldReferenceConnection"] = MetafieldReferenceConnection;
Types.types["MetafieldReferenceEdge"] = MetafieldReferenceEdge;
Types.types["MetafieldsSetUserError"] = MetafieldsSetUserError;
Types.types["MetafieldsSetUserErrorCode"] = MetafieldsSetUserErrorCode;
Types.types["Metaobject"] = Metaobject;
Types.types["MetaobjectConnection"] = MetaobjectConnection;
Types.types["MetaobjectEdge"] = MetaobjectEdge;
Types.types["MetaobjectField"] = MetaobjectField;
Types.types["MetaobjectHandleInput"] = MetaobjectHandleInput;
Types.types["MetaobjectSEO"] = MetaobjectSEO;
Types.types["Model3d"] = Model3d;
Types.types["Model3dSource"] = Model3dSource;
Types.types["MoneyInput"] = MoneyInput;
Types.types["MoneyV2"] = MoneyV2;
Types.types["Mutation"] = Mutation;
Types.types["Node"] = Node;
Types.types["OnlineStorePublishable"] = OnlineStorePublishable;
Types.types["Order"] = Order;
Types.types["OrderCancelReason"] = OrderCancelReason;
Types.types["OrderConnection"] = OrderConnection;
Types.types["OrderEdge"] = OrderEdge;
Types.types["OrderFinancialStatus"] = OrderFinancialStatus;
Types.types["OrderFulfillmentStatus"] = OrderFulfillmentStatus;
Types.types["OrderLineItem"] = OrderLineItem;
Types.types["OrderLineItemConnection"] = OrderLineItemConnection;
Types.types["OrderLineItemEdge"] = OrderLineItemEdge;
Types.types["OrderSortKeys"] = OrderSortKeys;
Types.types["Page"] = Page;
Types.types["PageConnection"] = PageConnection;
Types.types["PageEdge"] = PageEdge;
Types.types["PageInfo"] = PageInfo;
Types.types["PageSortKeys"] = PageSortKeys;
Types.types["Payment"] = Payment;
Types.types["PaymentSettings"] = PaymentSettings;
Types.types["PaymentTokenType"] = PaymentTokenType;
Types.types["PredictiveSearchLimitScope"] = PredictiveSearchLimitScope;
Types.types["PredictiveSearchResult"] = PredictiveSearchResult;
Types.types["PredictiveSearchType"] = PredictiveSearchType;
Types.types["PriceRangeFilter"] = PriceRangeFilter;
Types.types["PricingPercentageValue"] = PricingPercentageValue;
Types.types["PricingValue"] = PricingValue;
Types.types["Product"] = Product;
Types.types["ProductCollectionSortKeys"] = ProductCollectionSortKeys;
Types.types["ProductConnection"] = ProductConnection;
Types.types["ProductEdge"] = ProductEdge;
Types.types["ProductFilter"] = ProductFilter;
Types.types["ProductImageSortKeys"] = ProductImageSortKeys;
Types.types["ProductMediaSortKeys"] = ProductMediaSortKeys;
Types.types["ProductOption"] = ProductOption;
Types.types["ProductPriceRange"] = ProductPriceRange;
Types.types["ProductRecommendationIntent"] = ProductRecommendationIntent;
Types.types["ProductSortKeys"] = ProductSortKeys;
Types.types["ProductVariant"] = ProductVariant;
Types.types["ProductVariantConnection"] = ProductVariantConnection;
Types.types["ProductVariantEdge"] = ProductVariantEdge;
Types.types["ProductVariantSortKeys"] = ProductVariantSortKeys;
Types.types["PurchasingCompany"] = PurchasingCompany;
Types.types["QuantityPriceBreak"] = QuantityPriceBreak;
Types.types["QuantityPriceBreakConnection"] = QuantityPriceBreakConnection;
Types.types["QuantityPriceBreakEdge"] = QuantityPriceBreakEdge;
Types.types["QuantityRule"] = QuantityRule;
Types.types["QueryRoot"] = QueryRoot;
Types.types["SEO"] = SEO;
Types.types["ScriptDiscountApplication"] = ScriptDiscountApplication;
Types.types["SearchPrefixQueryType"] = SearchPrefixQueryType;
Types.types["SearchQuerySuggestion"] = SearchQuerySuggestion;
Types.types["SearchResultItem"] = SearchResultItem;
Types.types["SearchResultItemConnection"] = SearchResultItemConnection;
Types.types["SearchResultItemEdge"] = SearchResultItemEdge;
Types.types["SearchSortKeys"] = SearchSortKeys;
Types.types["SearchType"] = SearchType;
Types.types["SearchUnavailableProductsType"] = SearchUnavailableProductsType;
Types.types["SearchableField"] = SearchableField;
Types.types["SelectedOption"] = SelectedOption;
Types.types["SelectedOptionInput"] = SelectedOptionInput;
Types.types["SellingPlan"] = SellingPlan;
Types.types["SellingPlanAllocation"] = SellingPlanAllocation;
Types.types["SellingPlanAllocationConnection"] = SellingPlanAllocationConnection;
Types.types["SellingPlanAllocationEdge"] = SellingPlanAllocationEdge;
Types.types["SellingPlanAllocationPriceAdjustment"] = SellingPlanAllocationPriceAdjustment;
Types.types["SellingPlanCheckoutCharge"] = SellingPlanCheckoutCharge;
Types.types["SellingPlanCheckoutChargePercentageValue"] = SellingPlanCheckoutChargePercentageValue;
Types.types["SellingPlanCheckoutChargeType"] = SellingPlanCheckoutChargeType;
Types.types["SellingPlanCheckoutChargeValue"] = SellingPlanCheckoutChargeValue;
Types.types["SellingPlanConnection"] = SellingPlanConnection;
Types.types["SellingPlanEdge"] = SellingPlanEdge;
Types.types["SellingPlanFixedAmountPriceAdjustment"] = SellingPlanFixedAmountPriceAdjustment;
Types.types["SellingPlanFixedPriceAdjustment"] = SellingPlanFixedPriceAdjustment;
Types.types["SellingPlanGroup"] = SellingPlanGroup;
Types.types["SellingPlanGroupConnection"] = SellingPlanGroupConnection;
Types.types["SellingPlanGroupEdge"] = SellingPlanGroupEdge;
Types.types["SellingPlanGroupOption"] = SellingPlanGroupOption;
Types.types["SellingPlanOption"] = SellingPlanOption;
Types.types["SellingPlanPercentagePriceAdjustment"] = SellingPlanPercentagePriceAdjustment;
Types.types["SellingPlanPriceAdjustment"] = SellingPlanPriceAdjustment;
Types.types["SellingPlanPriceAdjustmentValue"] = SellingPlanPriceAdjustmentValue;
Types.types["ShippingRate"] = ShippingRate;
Types.types["Shop"] = Shop;
Types.types["ShopPayWalletContentInput"] = ShopPayWalletContentInput;
Types.types["ShopPolicy"] = ShopPolicy;
Types.types["ShopPolicyWithDefault"] = ShopPolicyWithDefault;
Types.types["StoreAvailability"] = StoreAvailability;
Types.types["StoreAvailabilityConnection"] = StoreAvailabilityConnection;
Types.types["StoreAvailabilityEdge"] = StoreAvailabilityEdge;
Types.types["String"] = String;
Types.types["StringConnection"] = StringConnection;
Types.types["StringEdge"] = StringEdge;
Types.types["SubmissionError"] = SubmissionError;
Types.types["SubmissionErrorCode"] = SubmissionErrorCode;
Types.types["SubmitAlreadyAccepted"] = SubmitAlreadyAccepted;
Types.types["SubmitFailed"] = SubmitFailed;
Types.types["SubmitSuccess"] = SubmitSuccess;
Types.types["SubmitThrottled"] = SubmitThrottled;
Types.types["Swatch"] = Swatch;
Types.types["TokenizedPaymentInputV3"] = TokenizedPaymentInputV3;
Types.types["Trackable"] = Trackable;
Types.types["Transaction"] = Transaction;
Types.types["TransactionKind"] = TransactionKind;
Types.types["TransactionStatus"] = TransactionStatus;
Types.types["URL"] = URL;
Types.types["UnitPriceMeasurement"] = UnitPriceMeasurement;
Types.types["UnitPriceMeasurementMeasuredType"] = UnitPriceMeasurementMeasuredType;
Types.types["UnitPriceMeasurementMeasuredUnit"] = UnitPriceMeasurementMeasuredUnit;
Types.types["UnitSystem"] = UnitSystem;
Types.types["UnsignedInt64"] = UnsignedInt64;
Types.types["UrlRedirect"] = UrlRedirect;
Types.types["UrlRedirectConnection"] = UrlRedirectConnection;
Types.types["UrlRedirectEdge"] = UrlRedirectEdge;
Types.types["UserError"] = UserError;
Types.types["VariantOptionFilter"] = VariantOptionFilter;
Types.types["Video"] = Video;
Types.types["VideoSource"] = VideoSource;
Types.types["WeightUnit"] = WeightUnit;
Types.types["__Directive"] = __Directive;
Types.types["__DirectiveLocation"] = __DirectiveLocation;
Types.types["__EnumValue"] = __EnumValue;
Types.types["__Field"] = __Field;
Types.types["__InputValue"] = __InputValue;
Types.types["__Schema"] = __Schema;
Types.types["__Type"] = __Type;
Types.types["__TypeKind"] = __TypeKind;
Types.queryType = "QueryRoot";
Types.mutationType = "Mutation";
Types.subscriptionType = null;

function recursivelyFreezeObject(structure) {
  Object.getOwnPropertyNames(structure).forEach(key => {
    const value = structure[key];
    if (value && typeof value === 'object') {
      recursivelyFreezeObject(value);
    }
  });
  Object.freeze(structure);
  return structure;
}

export default recursivelyFreezeObject(Types);