
const ProductConnection = {
  "name": "ProductConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "ProductEdge",
    "nodes": "Product",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default ProductConnection;