
const PaymentCustomizationCreatePayload = {
  "name": "PaymentCustomizationCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "paymentCustomization": "PaymentCustomization",
    "userErrors": "PaymentCustomizationError"
  },
  "implementsNode": false
};
export default PaymentCustomizationCreatePayload;