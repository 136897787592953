
const ReturnLineItem = {
  "name": "ReturnLineItem",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customerNote": "String",
    "fulfillmentLineItem": "FulfillmentLineItem",
    "id": "ID",
    "quantity": "Int",
    "refundableQuantity": "Int",
    "refundedQuantity": "Int",
    "restockingFee": "RestockingFee",
    "returnReason": "ReturnReason",
    "returnReasonNote": "String",
    "totalWeight": "Weight",
    "withCodeDiscountedTotalPriceSet": "MoneyBag"
  },
  "implementsNode": true
};
export default ReturnLineItem;