
const CartLine = {
  "name": "CartLine",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "attribute": "Attribute",
    "attributes": "Attribute",
    "cost": "CartLineCost",
    "discountAllocations": "CartDiscountAllocation",
    "estimatedCost": "CartLineEstimatedCost",
    "id": "ID",
    "merchandise": "Merchandise",
    "quantity": "Int",
    "sellingPlanAllocation": "SellingPlanAllocation"
  },
  "implementsNode": true
};
export default CartLine;