
const CustomerDeletePayload = {
  "name": "CustomerDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedCustomerId": "ID",
    "shop": "Shop",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default CustomerDeletePayload;