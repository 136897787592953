
const CommentEventSubject = {
  "name": "CommentEventSubject",
  "kind": "INTERFACE",
  "fieldBaseTypes": {
    "hasTimelineComment": "Boolean",
    "id": "ID"
  },
  "possibleTypes": ["Company", "CompanyLocation", "Customer", "DraftOrder", "Order", "PriceRule"]
};
export default CommentEventSubject;