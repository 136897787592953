import React, { useState } from "react";
import Modal from "react-responsive-modal";

export const EmbedCode = ({
  type,
  resourceId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newHTML, setHTML] = useState(``);
  const [newScript, setScript] = useState(``);
  
  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const generateMarkup = (params) => {
    const divId = getRandomInt(100000000000, 999999999999);
    const buyButtonDedicatedToken = "bc1f3643396e0e12cb20e7270123f277";
    const generatedHTML = `<div id='${params.type}-component-${divId}'></div>`;
    const generatedScript = `
      <script type="text/javascript">
        /*<![CDATA[*/
          (function () {
            var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
            if (window.ShopifyBuy) {
              if (window.ShopifyBuy.UI) {
                ShopifyBuyInit();
              } else {
                loadScript();
              }
            } else {
              loadScript();
            }
            function loadScript() {
              var script = document.createElement('script');
              script.async = true;
              script.src = scriptURL;
              (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
              script.onload = ShopifyBuyInit;
            }
            function ShopifyBuyInit() {
              var client = ShopifyBuy.buildClient({
                domain: 'checkout.readysetprint.com',
                storefrontAccessToken: '${buyButtonDedicatedToken}',
              });
              ShopifyBuy.UI.onReady(client).then(function (ui) {
                ui.createComponent('${params.type}', {
                  id: '${params.resourceId.split("/")[4]}',
                  node: document.getElementById('${params.type}-component-${divId}'),
                  moneyFormat: '%24%7B%7Bamount%7D%7D',
                  options: {
                    "product": {
                      "styles": {
                        "product": {
                          "@media (min-width: 601px)": {
                            "max-width": "calc(25% - 20px)",
                            "margin-left": "20px",
                            ${params.type === "product" ?
                              `"margin-bottom": "50px"` :
                              `"margin-bottom": "50px", "width": "calc(25% - 20px)"`
                            }
                          },
                          "text-align": "left",
                          ${params.type === "product" ?
                            `"carousel-button": {"display": "none"}` :
                            `"img": {
                              "height": "calc(100% - 15px)",
                              "position": "absolute",
                              "left": "0",
                              "right": "0",
                              "top": "0"
                            },
                             "imgWrapper": {
                              "padding-top": "calc(75% + 15px)",
                              "position": "relative",
                              "height": "0"
                            }`
                          }
                        },
                        "button": {
                          ":hover": {
                            "background-color": "#000000"
                          },
                          "background-color": "#000000",
                          ":focus": {
                            "background-color": "#000000"
                          }
                        }
                      },
                      "contents": {
                        ${params.type === "product" ?
                          '"img": false, "imgWithCarousel": true,' :
                          ''
                        }
                        "button": false,
                        "buttonWithQuantity": true
                      },
                      "text": {
                        "button": "Add to cart"
                      }
                    },
                    "productSet": {
                      "styles": {
                        "products": {
                          "@media (min-width: 601px)": {
                            "margin-left": "-20px"
                          }
                        }
                      }
                    },
                    "modalProduct": {
                      "contents": {
                        "img": false,
                        "imgWithCarousel": true,
                        "button": false,
                        "buttonWithQuantity": true
                      },
                      "styles": {
                        "product": {
                          "@media (min-width: 601px)": {
                            "max-width": "100%",
                            "margin-left": "0px",
                            "margin-bottom": "0px"
                          }
                        },
                        "button": {
                          ":hover": {
                            "background-color": "#000000"
                          },
                          "background-color": "#000000",
                          ":focus": {
                            "background-color": "#000000"
                          }
                        }
                      },
                      "text": {
                        "button": "Add to cart"
                      }
                    },
                    "cart": {
                      "styles": {
                        "button": {
                          ":hover": {
                            "background-color": "#000000"
                          },
                          "background-color": "#000000",
                          ":focus": {
                            "background-color": "#000000"
                          }
                        }
                      },
                      "text": {
                        "total": "Subtotal",
                        "button": "Checkout"
                      }
                    },
                    "toggle": {
                      "styles": {
                        "toggle": {
                          "background-color": "#000000",
                          ":hover": {
                            "background-color": "#000000"
                          },
                          ":focus": {
                            "background-color": "#000000"
                          }
                        }
                      }
                    }
                  },
                });
              });
            }
          })();
        /*]]>*/
      </script>
    `;

    setHTML(generatedHTML);
    setScript(generatedScript);
    setIsOpen(true);
  }

  const onCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Modal open={isOpen} onClose={onCloseModal} styles={{
        modal: {
          padding: "20px",
          borderRadius: "4px"
        }
      }}>
        <h5>Buy Button Embed Code</h5>
        <b>Paste this code wherever a buy button is needed.</b>
        <div className="jumbotron p-20">
          {newHTML}
        </div>
        <br />
        <b>Paste this code just before the closing body tag.</b>
        <div className="jumbotron p-20">
          {newScript}
        </div>
      </Modal>
      <button
        type="button"
        className="btn btn-custom-link embed-code"
        onClick={() => generateMarkup({ type, resourceId })}
      >
        <b>Embed Code</b>
      </button>
    </>
  );
};