
const PriceListPriceConnection = {
  "name": "PriceListPriceConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "PriceListPriceEdge",
    "nodes": "PriceListPrice",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default PriceListPriceConnection;