
const ProductTaxonomyNode = {
  "name": "ProductTaxonomyNode",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "fullName": "String",
    "id": "ID",
    "isLeaf": "Boolean",
    "isRoot": "Boolean",
    "name": "String"
  },
  "implementsNode": true
};
export default ProductTaxonomyNode;