
const PriceRuleQuantityRangeInput = {
  "name": "PriceRuleQuantityRangeInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "lessThan": "Int",
    "lessThanOrEqualTo": "Int",
    "greaterThan": "Int",
    "greaterThanOrEqualTo": "Int"
  }
};
export default PriceRuleQuantityRangeInput;