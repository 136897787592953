
const Vector3 = {
  "name": "Vector3",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "x": "Float",
    "y": "Float",
    "z": "Float"
  },
  "implementsNode": false
};
export default Vector3;