
const ProductDeleteMediaPayload = {
  "name": "ProductDeleteMediaPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedMediaIds": "ID",
    "deletedProductImageIds": "ID",
    "mediaUserErrors": "MediaUserError",
    "product": "Product",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default ProductDeleteMediaPayload;