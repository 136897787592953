
const CountriesInShippingZones = {
  "name": "CountriesInShippingZones",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "countryCodes": "CountryCode",
    "includeRestOfWorld": "Boolean"
  },
  "implementsNode": false
};
export default CountriesInShippingZones;