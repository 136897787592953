
const CartDiscountCode = {
  "name": "CartDiscountCode",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "applicable": "Boolean",
    "code": "String"
  },
  "implementsNode": false
};
export default CartDiscountCode;