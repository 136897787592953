
const DiscountAutomaticAppUpdatePayload = {
  "name": "DiscountAutomaticAppUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "automaticAppDiscount": "DiscountAutomaticApp",
    "userErrors": "DiscountUserError"
  },
  "implementsNode": false
};
export default DiscountAutomaticAppUpdatePayload;