
const PriceListParent = {
  "name": "PriceListParent",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "adjustment": "PriceListAdjustment",
    "settings": "PriceListAdjustmentSettings"
  },
  "implementsNode": false
};
export default PriceListParent;