
const AppUsageRecordEdge = {
  "name": "AppUsageRecordEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "AppUsageRecord"
  },
  "implementsNode": false
};
export default AppUsageRecordEdge;