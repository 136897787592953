
const CustomerMergeError = {
  "name": "CustomerMergeError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "errorFields": "CustomerMergeErrorFieldType",
    "message": "String"
  },
  "implementsNode": false
};
export default CustomerMergeError;