
const FulfillmentCancelPayload = {
  "name": "FulfillmentCancelPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "fulfillment": "Fulfillment",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default FulfillmentCancelPayload;