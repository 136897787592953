
const TenderTransactionCreditCardDetails = {
  "name": "TenderTransactionCreditCardDetails",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "creditCardCompany": "String",
    "creditCardNumber": "String"
  },
  "implementsNode": false
};
export default TenderTransactionCreditCardDetails;