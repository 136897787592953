
const TaxAppConfigurePayload = {
  "name": "TaxAppConfigurePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "taxAppConfiguration": "TaxAppConfiguration",
    "userErrors": "TaxAppConfigureUserError"
  },
  "implementsNode": false
};
export default TaxAppConfigurePayload;