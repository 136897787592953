
const InventoryMoveQuantityChange = {
  "name": "InventoryMoveQuantityChange",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "inventoryItemId": "ID",
    "quantity": "Int",
    "from": "InventoryMoveQuantityTerminalInput",
    "to": "InventoryMoveQuantityTerminalInput"
  }
};
export default InventoryMoveQuantityChange;