
const CompanyLocation = {
  "name": "CompanyLocation",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "billingAddress": "CompanyAddress",
    "buyerExperienceConfiguration": "BuyerExperienceConfiguration",
    "catalogs": "CatalogConnection",
    "catalogsCount": "Count",
    "company": "Company",
    "createdAt": "DateTime",
    "currency": "CurrencyCode",
    "defaultCursor": "String",
    "draftOrders": "DraftOrderConnection",
    "events": "EventConnection",
    "externalId": "String",
    "hasTimelineComment": "Boolean",
    "id": "ID",
    "inCatalog": "Boolean",
    "locale": "String",
    "market": "Market",
    "metafield": "Metafield",
    "metafieldDefinitions": "MetafieldDefinitionConnection",
    "metafields": "MetafieldConnection",
    "name": "String",
    "note": "String",
    "orderCount": "Int",
    "orders": "OrderConnection",
    "ordersCount": "Count",
    "phone": "String",
    "privateMetafield": "PrivateMetafield",
    "privateMetafields": "PrivateMetafieldConnection",
    "roleAssignments": "CompanyContactRoleAssignmentConnection",
    "shippingAddress": "CompanyAddress",
    "taxExemptions": "TaxExemption",
    "taxRegistrationId": "String",
    "totalSpent": "MoneyV2",
    "updatedAt": "DateTime"
  },
  "implementsNode": true
};
export default CompanyLocation;