
const StagedUploadInput = {
  "name": "StagedUploadInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "resource": "StagedUploadTargetGenerateUploadResource",
    "filename": "String",
    "mimeType": "String",
    "httpMethod": "StagedUploadHttpMethodType",
    "fileSize": "UnsignedInt64"
  }
};
export default StagedUploadInput;