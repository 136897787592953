
const DiscountAutomaticBasic = {
  "name": "DiscountAutomaticBasic",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "asyncUsageCount": "Int",
    "combinesWith": "DiscountCombinesWith",
    "createdAt": "DateTime",
    "customerGets": "DiscountCustomerGets",
    "discountClass": "MerchandiseDiscountClass",
    "endsAt": "DateTime",
    "minimumRequirement": "DiscountMinimumRequirement",
    "recurringCycleLimit": "Int",
    "shortSummary": "String",
    "startsAt": "DateTime",
    "status": "DiscountStatus",
    "summary": "String",
    "title": "String",
    "updatedAt": "DateTime",
    "usageCount": "Int"
  },
  "implementsNode": false
};
export default DiscountAutomaticBasic;