
const FulfillmentOrderOpenPayload = {
  "name": "FulfillmentOrderOpenPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "fulfillmentOrder": "FulfillmentOrder",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default FulfillmentOrderOpenPayload;