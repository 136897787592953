
const DiscountCountriesInput = {
  "name": "DiscountCountriesInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "add": "CountryCode",
    "remove": "CountryCode",
    "includeRestOfWorld": "Boolean"
  }
};
export default DiscountCountriesInput;