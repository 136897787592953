
const CustomerPaymentMethodCreditCardCreatePayload = {
  "name": "CustomerPaymentMethodCreditCardCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customerPaymentMethod": "CustomerPaymentMethod",
    "processing": "Boolean",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default CustomerPaymentMethodCreditCardCreatePayload;