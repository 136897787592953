
const SavedSearchCreateInput = {
  "name": "SavedSearchCreateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "resourceType": "SearchResultType",
    "name": "String",
    "query": "String"
  }
};
export default SavedSearchCreateInput;