
const UTMParameters = {
  "name": "UTMParameters",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "campaign": "String",
    "content": "String",
    "medium": "String",
    "source": "String",
    "term": "String"
  },
  "implementsNode": false
};
export default UTMParameters;