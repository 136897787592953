
const FulfillmentOrdersSetFulfillmentDeadlinePayload = {
  "name": "FulfillmentOrdersSetFulfillmentDeadlinePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "success": "Boolean",
    "userErrors": "FulfillmentOrdersSetFulfillmentDeadlineUserError"
  },
  "implementsNode": false
};
export default FulfillmentOrdersSetFulfillmentDeadlinePayload;