
const ResourcePublicationV2 = {
  "name": "ResourcePublicationV2",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "isPublished": "Boolean",
    "publication": "Publication",
    "publishDate": "DateTime",
    "publishable": "Publishable"
  },
  "implementsNode": false
};
export default ResourcePublicationV2;