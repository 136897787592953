
const SubscriptionBillingCycleUnskipPayload = {
  "name": "SubscriptionBillingCycleUnskipPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "billingCycle": "SubscriptionBillingCycle",
    "userErrors": "SubscriptionBillingCycleUnskipUserError"
  },
  "implementsNode": false
};
export default SubscriptionBillingCycleUnskipPayload;