
const DeliveryProfileItem = {
  "name": "DeliveryProfileItem",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "product": "Product",
    "variants": "ProductVariantConnection"
  },
  "implementsNode": true
};
export default DeliveryProfileItem;