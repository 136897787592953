
const CustomerShopPayAgreement = {
  "name": "CustomerShopPayAgreement",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "billingAddress": "CustomerCreditCardBillingAddress",
    "expiresSoon": "Boolean",
    "expiryMonth": "Int",
    "expiryYear": "Int",
    "inactive": "Boolean",
    "isRevocable": "Boolean",
    "lastDigits": "String",
    "maskedNumber": "String",
    "name": "String"
  },
  "implementsNode": false
};
export default CustomerShopPayAgreement;