
const SubscriptionShippingOption = {
  "name": "SubscriptionShippingOption",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "carrierService": "DeliveryCarrierService",
    "code": "String",
    "description": "String",
    "phoneRequired": "Boolean",
    "presentmentTitle": "String",
    "price": "MoneyV2",
    "title": "String"
  },
  "implementsNode": false
};
export default SubscriptionShippingOption;