
const CustomerSegmentMembersQueryCreatePayload = {
  "name": "CustomerSegmentMembersQueryCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customerSegmentMembersQuery": "CustomerSegmentMembersQuery",
    "userErrors": "CustomerSegmentMembersQueryUserError"
  },
  "implementsNode": false
};
export default CustomerSegmentMembersQueryCreatePayload;