
const FulfillmentCreateV2Payload = {
  "name": "FulfillmentCreateV2Payload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "fulfillment": "Fulfillment",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default FulfillmentCreateV2Payload;