
const FunctionsAppBridge = {
  "name": "FunctionsAppBridge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "createPath": "String",
    "detailsPath": "String"
  },
  "implementsNode": false
};
export default FunctionsAppBridge;