
const InventorySetOnHandQuantitiesInput = {
  "name": "InventorySetOnHandQuantitiesInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "reason": "String",
    "referenceDocumentUri": "String",
    "setQuantities": "InventorySetQuantityInput"
  }
};
export default InventorySetOnHandQuantitiesInput;