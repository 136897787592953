
const MarketLocalizationsRemovePayload = {
  "name": "MarketLocalizationsRemovePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "marketLocalizations": "MarketLocalization",
    "userErrors": "TranslationUserError"
  },
  "implementsNode": false
};
export default MarketLocalizationsRemovePayload;