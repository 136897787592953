
const ResourceFeedbackCreateInput = {
  "name": "ResourceFeedbackCreateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "feedbackGeneratedAt": "DateTime",
    "messages": "String",
    "state": "ResourceFeedbackState"
  }
};
export default ResourceFeedbackCreateInput;