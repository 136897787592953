
const Return = {
  "name": "Return",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "decline": "ReturnDecline",
    "exchangeLineItems": "ExchangeLineItemConnection",
    "id": "ID",
    "name": "String",
    "order": "Order",
    "refunds": "RefundConnection",
    "returnLineItems": "ReturnLineItemConnection",
    "returnShippingFees": "ReturnShippingFee",
    "reverseFulfillmentOrders": "ReverseFulfillmentOrderConnection",
    "status": "ReturnStatus",
    "suggestedRefund": "SuggestedReturnRefund",
    "totalQuantity": "Int"
  },
  "implementsNode": true
};
export default Return;