
const DiscountRedeemCodeBulkCreationCode = {
  "name": "DiscountRedeemCodeBulkCreationCode",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "String",
    "discountRedeemCode": "DiscountRedeemCode",
    "errors": "DiscountUserError"
  },
  "implementsNode": false
};
export default DiscountRedeemCodeBulkCreationCode;