
const CheckoutBrandingMainSectionInput = {
  "name": "CheckoutBrandingMainSectionInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "colorScheme": "CheckoutBrandingColorSchemeSelection",
    "background": "CheckoutBrandingBackground",
    "cornerRadius": "CheckoutBrandingCornerRadius",
    "border": "CheckoutBrandingSimpleBorder",
    "borderStyle": "CheckoutBrandingBorderStyle",
    "borderWidth": "CheckoutBrandingBorderWidth",
    "shadow": "CheckoutBrandingShadow",
    "padding": "CheckoutBrandingSpacingKeyword"
  }
};
export default CheckoutBrandingMainSectionInput;