
const CustomerMergePreviewAlternateFields = {
  "name": "CustomerMergePreviewAlternateFields",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "defaultAddress": "MailingAddress",
    "email": "CustomerEmailAddress",
    "firstName": "String",
    "lastName": "String",
    "phoneNumber": "CustomerPhoneNumber"
  },
  "implementsNode": false
};
export default CustomerMergePreviewAlternateFields;