
const PriceListPriceInput = {
  "name": "PriceListPriceInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "variantId": "ID",
    "price": "MoneyInput",
    "compareAtPrice": "MoneyInput"
  }
};
export default PriceListPriceInput;