
const WebPixelUpdatePayload = {
  "name": "WebPixelUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "userErrors": "ErrorsWebPixelUserError",
    "webPixel": "WebPixel"
  },
  "implementsNode": false
};
export default WebPixelUpdatePayload;