
const ShippingRate = {
  "name": "ShippingRate",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "handle": "String",
    "price": "MoneyV2",
    "title": "String"
  },
  "implementsNode": false
};
export default ShippingRate;