
const ShopifyPaymentsVerification = {
  "name": "ShopifyPaymentsVerification",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "status": "ShopifyPaymentsVerificationStatus",
    "subject": "ShopifyPaymentsVerificationSubject"
  },
  "implementsNode": true
};
export default ShopifyPaymentsVerification;