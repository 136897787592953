
const ShopifyqlResponse = {
  "name": "ShopifyqlResponse",
  "kind": "INTERFACE",
  "fieldBaseTypes": {
    "parseErrors": "ParseError",
    "tableData": "TableData"
  },
  "possibleTypes": ["PolarisVizResponse", "TableResponse"]
};
export default ShopifyqlResponse;