
const OnlineStoreBlog = {
  "name": "OnlineStoreBlog",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "translations": "Translation"
  },
  "implementsNode": true
};
export default OnlineStoreBlog;