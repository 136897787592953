
const SubscriptionBillingCycleScheduleEditPayload = {
  "name": "SubscriptionBillingCycleScheduleEditPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "billingCycle": "SubscriptionBillingCycle",
    "userErrors": "SubscriptionBillingCycleUserError"
  },
  "implementsNode": false
};
export default SubscriptionBillingCycleScheduleEditPayload;