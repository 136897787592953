
const PriceListAdjustment = {
  "name": "PriceListAdjustment",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "type": "PriceListAdjustmentType",
    "value": "Float"
  },
  "implementsNode": false
};
export default PriceListAdjustment;