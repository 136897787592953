
const DraftOrderLineItemConnection = {
  "name": "DraftOrderLineItemConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "DraftOrderLineItemEdge",
    "nodes": "DraftOrderLineItem",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default DraftOrderLineItemConnection;