
const InventorySetOnHandQuantitiesPayload = {
  "name": "InventorySetOnHandQuantitiesPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "inventoryAdjustmentGroup": "InventoryAdjustmentGroup",
    "userErrors": "InventorySetOnHandQuantitiesUserError"
  },
  "implementsNode": false
};
export default InventorySetOnHandQuantitiesPayload;