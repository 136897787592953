
const FulfillmentOrderHoldPayload = {
  "name": "FulfillmentOrderHoldPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "fulfillmentOrder": "FulfillmentOrder",
    "remainingFulfillmentOrder": "FulfillmentOrder",
    "userErrors": "FulfillmentOrderHoldUserError"
  },
  "implementsNode": false
};
export default FulfillmentOrderHoldPayload;