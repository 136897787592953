
const ExternalVideo = {
  "name": "ExternalVideo",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "alt": "String",
    "embedUrl": "URL",
    "embeddedUrl": "URL",
    "host": "MediaHost",
    "id": "ID",
    "mediaContentType": "MediaContentType",
    "mediaErrors": "MediaError",
    "mediaWarnings": "MediaWarning",
    "originUrl": "URL",
    "preview": "MediaPreviewImage",
    "status": "MediaStatus"
  },
  "implementsNode": true
};
export default ExternalVideo;