
const RefundInput = {
  "name": "RefundInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "currency": "CurrencyCode",
    "orderId": "ID",
    "note": "String",
    "notify": "Boolean",
    "shipping": "ShippingRefundInput",
    "refundLineItems": "RefundLineItemInput",
    "refundDuties": "RefundDutyInput",
    "transactions": "OrderTransactionInput"
  }
};
export default RefundInput;