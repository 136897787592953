
const CollectionInput = {
  "name": "CollectionInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "descriptionHtml": "String",
    "handle": "String",
    "id": "ID",
    "image": "ImageInput",
    "products": "ID",
    "ruleSet": "CollectionRuleSetInput",
    "templateSuffix": "String",
    "sortOrder": "CollectionSortOrder",
    "title": "String",
    "metafields": "MetafieldInput",
    "seo": "SEOInput",
    "redirectNewHandle": "Boolean"
  }
};
export default CollectionInput;