
const PubSubWebhookSubscriptionInput = {
  "name": "PubSubWebhookSubscriptionInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "pubSubProject": "String",
    "pubSubTopic": "String",
    "format": "WebhookSubscriptionFormat",
    "includeFields": "String",
    "metafieldNamespaces": "String"
  }
};
export default PubSubWebhookSubscriptionInput;