
const PageConnection = {
  "name": "PageConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "PageEdge",
    "nodes": "Page",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default PageConnection;