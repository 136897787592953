
const MarketingActivityUpdateInput = {
  "name": "MarketingActivityUpdateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "id": "ID",
    "marketingRecommendationId": "ID",
    "title": "String",
    "budget": "MarketingActivityBudgetInput",
    "status": "MarketingActivityStatus",
    "targetStatus": "MarketingActivityStatus",
    "formData": "String",
    "utm": "UTMInput",
    "marketedResources": "ID",
    "errors": "JSON"
  }
};
export default MarketingActivityUpdateInput;