
const SubscriptionDiscountEntitledLines = {
  "name": "SubscriptionDiscountEntitledLines",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "all": "Boolean",
    "lines": "SubscriptionLineConnection"
  },
  "implementsNode": false
};
export default SubscriptionDiscountEntitledLines;