
const LocationDeactivatePayload = {
  "name": "LocationDeactivatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "location": "Location",
    "locationDeactivateUserErrors": "LocationDeactivateUserError"
  },
  "implementsNode": false
};
export default LocationDeactivatePayload;