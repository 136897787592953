
const CheckoutBrandingLogo = {
  "name": "CheckoutBrandingLogo",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "image": "Image",
    "maxWidth": "Int",
    "visibility": "CheckoutBrandingVisibility"
  },
  "implementsNode": false
};
export default CheckoutBrandingLogo;