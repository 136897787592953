
const SubscriptionContractFailPayload = {
  "name": "SubscriptionContractFailPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "contract": "SubscriptionContract",
    "userErrors": "SubscriptionContractStatusUpdateUserError"
  },
  "implementsNode": false
};
export default SubscriptionContractFailPayload;