
const ProductUpdateMediaPayload = {
  "name": "ProductUpdateMediaPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "media": "Media",
    "mediaUserErrors": "MediaUserError",
    "product": "Product",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default ProductUpdateMediaPayload;