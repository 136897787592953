
const PriceRuleEntitlementToPrerequisiteQuantityRatio = {
  "name": "PriceRuleEntitlementToPrerequisiteQuantityRatio",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "entitlementQuantity": "Int",
    "prerequisiteQuantity": "Int"
  },
  "implementsNode": false
};
export default PriceRuleEntitlementToPrerequisiteQuantityRatio;