
const MediaPresentation = {
  "name": "MediaPresentation",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "asJson": "JSON",
    "id": "ID"
  },
  "implementsNode": true
};
export default MediaPresentation;