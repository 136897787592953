
const ResourceAlert = {
  "name": "ResourceAlert",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "actions": "ResourceAlertAction",
    "content": "HTML",
    "dismissibleHandle": "String",
    "icon": "ResourceAlertIcon",
    "severity": "ResourceAlertSeverity",
    "title": "String"
  },
  "implementsNode": false
};
export default ResourceAlert;