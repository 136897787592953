
const TaxonomyCategoryAttributeEdge = {
  "name": "TaxonomyCategoryAttributeEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "TaxonomyCategoryAttribute"
  },
  "implementsNode": false
};
export default TaxonomyCategoryAttributeEdge;