
const TransactionFee = {
  "name": "TransactionFee",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "amount": "MoneyV2",
    "flatFee": "MoneyV2",
    "flatFeeName": "String",
    "id": "ID",
    "rate": "Decimal",
    "rateName": "String",
    "taxAmount": "MoneyV2",
    "type": "String"
  },
  "implementsNode": true
};
export default TransactionFee;