
const LocalizationExtensionEdge = {
  "name": "LocalizationExtensionEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "LocalizationExtension"
  },
  "implementsNode": false
};
export default LocalizationExtensionEdge;