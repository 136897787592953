
const OrderRiskAssessment = {
  "name": "OrderRiskAssessment",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "facts": "RiskFact",
    "provider": "App",
    "riskLevel": "RiskAssessmentResult"
  },
  "implementsNode": false
};
export default OrderRiskAssessment;