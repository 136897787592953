
const Domain = {
  "name": "Domain",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "host": "String",
    "id": "ID",
    "localization": "DomainLocalization",
    "marketWebPresence": "MarketWebPresence",
    "sslEnabled": "Boolean",
    "url": "URL"
  },
  "implementsNode": true
};
export default Domain;