
const DiscountCustomerGetsValueInput = {
  "name": "DiscountCustomerGetsValueInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "discountOnQuantity": "DiscountOnQuantityInput",
    "percentage": "Float",
    "discountAmount": "DiscountAmountInput"
  }
};
export default DiscountCustomerGetsValueInput;