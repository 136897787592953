
const ReverseDeliveryLabelV2 = {
  "name": "ReverseDeliveryLabelV2",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "createdAt": "DateTime",
    "publicFileUrl": "URL",
    "updatedAt": "DateTime"
  },
  "implementsNode": false
};
export default ReverseDeliveryLabelV2;