
const CartEstimatedCost = {
  "name": "CartEstimatedCost",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkoutChargeAmount": "MoneyV2",
    "subtotalAmount": "MoneyV2",
    "totalAmount": "MoneyV2",
    "totalDutyAmount": "MoneyV2",
    "totalTaxAmount": "MoneyV2"
  },
  "implementsNode": false
};
export default CartEstimatedCost;