
const MetaobjectFieldDefinitionOperationInput = {
  "name": "MetaobjectFieldDefinitionOperationInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "create": "MetaobjectFieldDefinitionCreateInput",
    "update": "MetaobjectFieldDefinitionUpdateInput",
    "delete": "MetaobjectFieldDefinitionDeleteInput"
  }
};
export default MetaobjectFieldDefinitionOperationInput;