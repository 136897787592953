
const ReverseDeliveryCreateWithShippingPayload = {
  "name": "ReverseDeliveryCreateWithShippingPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "reverseDelivery": "ReverseDelivery",
    "userErrors": "ReturnUserError"
  },
  "implementsNode": false
};
export default ReverseDeliveryCreateWithShippingPayload;