
const GiftCardCreateInput = {
  "name": "GiftCardCreateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "initialValue": "Decimal",
    "code": "String",
    "customerId": "ID",
    "expiresOn": "Date",
    "note": "String",
    "templateSuffix": "String"
  }
};
export default GiftCardCreateInput;