
const FulfillmentOrderLocationForMoveConnection = {
  "name": "FulfillmentOrderLocationForMoveConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "FulfillmentOrderLocationForMoveEdge",
    "nodes": "FulfillmentOrderLocationForMove",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default FulfillmentOrderLocationForMoveConnection;