
const DeliveryAddressInput = {
  "name": "DeliveryAddressInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "deliveryAddress": "MailingAddressInput",
    "deliveryAddressValidationStrategy": "DeliveryAddressValidationStrategy",
    "customerAddressId": "ID"
  }
};
export default DeliveryAddressInput;