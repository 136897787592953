
const DiscountAutomaticNode = {
  "name": "DiscountAutomaticNode",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "automaticDiscount": "DiscountAutomatic",
    "events": "EventConnection",
    "id": "ID",
    "metafield": "Metafield",
    "metafieldDefinitions": "MetafieldDefinitionConnection",
    "metafields": "MetafieldConnection",
    "privateMetafield": "PrivateMetafield",
    "privateMetafields": "PrivateMetafieldConnection"
  },
  "implementsNode": true
};
export default DiscountAutomaticNode;