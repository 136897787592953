
const InventoryScheduledChange = {
  "name": "InventoryScheduledChange",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "expectedAt": "DateTime",
    "fromName": "String",
    "inventoryLevel": "InventoryLevel",
    "ledgerDocumentUri": "URL",
    "quantity": "Int",
    "toName": "String"
  },
  "implementsNode": false
};
export default InventoryScheduledChange;