
const Location = {
  "name": "Location",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "activatable": "Boolean",
    "address": "LocationAddress",
    "addressVerified": "Boolean",
    "createdAt": "DateTime",
    "deactivatable": "Boolean",
    "deactivatedAt": "String",
    "deletable": "Boolean",
    "fulfillmentService": "FulfillmentService",
    "fulfillsOnlineOrders": "Boolean",
    "hasActiveInventory": "Boolean",
    "hasUnfulfilledOrders": "Boolean",
    "id": "ID",
    "inventoryLevel": "InventoryLevel",
    "inventoryLevels": "InventoryLevelConnection",
    "isActive": "Boolean",
    "isFulfillmentService": "Boolean",
    "isPrimary": "Boolean",
    "legacyResourceId": "UnsignedInt64",
    "localPickupSettingsV2": "DeliveryLocalPickupSettings",
    "metafield": "Metafield",
    "metafieldDefinitions": "MetafieldDefinitionConnection",
    "metafields": "MetafieldConnection",
    "name": "String",
    "privateMetafield": "PrivateMetafield",
    "privateMetafields": "PrivateMetafieldConnection",
    "shipsInventory": "Boolean",
    "suggestedAddresses": "LocationSuggestedAddress",
    "updatedAt": "DateTime"
  },
  "implementsNode": true
};
export default Location;