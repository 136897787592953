
const SubscriptionContractProductChangePayload = {
  "name": "SubscriptionContractProductChangePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "contract": "SubscriptionContract",
    "lineUpdated": "SubscriptionLine",
    "userErrors": "SubscriptionDraftUserError"
  },
  "implementsNode": false
};
export default SubscriptionContractProductChangePayload;