
const SearchQuerySuggestion = {
  "name": "SearchQuerySuggestion",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "styledText": "String",
    "text": "String",
    "trackingParameters": "String"
  },
  "implementsNode": false
};
export default SearchQuerySuggestion;