
const SubscriptionDeliveryMethodLocalDelivery = {
  "name": "SubscriptionDeliveryMethodLocalDelivery",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "address": "SubscriptionMailingAddress",
    "localDeliveryOption": "SubscriptionDeliveryMethodLocalDeliveryOption"
  },
  "implementsNode": false
};
export default SubscriptionDeliveryMethodLocalDelivery;