
const FulfillmentOrderMergePayload = {
  "name": "FulfillmentOrderMergePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "fulfillmentOrderMerges": "FulfillmentOrderMergeResult",
    "userErrors": "FulfillmentOrderMergeUserError"
  },
  "implementsNode": false
};
export default FulfillmentOrderMergePayload;