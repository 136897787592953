
const InventoryItemUpdateInput = {
  "name": "InventoryItemUpdateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "cost": "Decimal",
    "tracked": "Boolean",
    "countryCodeOfOrigin": "CountryCode",
    "provinceCodeOfOrigin": "String",
    "harmonizedSystemCode": "String",
    "countryHarmonizedSystemCodes": "CountryHarmonizedSystemCodeInput"
  }
};
export default InventoryItemUpdateInput;