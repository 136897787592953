import React, { Component } from "react";
import { AuthContext } from "../../../auth/auth";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { getNetlifySnippetByTitle, updateNetlifySnippet, addNetlifySnippet } from "../../../querys/netlify-rest";
import { Alert } from 'reactstrap';
import { toast } from "react-toastify";

const snippetTitle = "StripeUserId";

class StripeSettings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isEligible: false,
            netlifySnippetId: null,
            netlifySnippetContent: null,
            connected: false,
            accountId: "",
            alert: false,
            alertMessage: "",
            alertColor: ""
        }
    }

    componentDidMount = async () => {

        let url = this.props.location.search;
        let params = queryString.parse(url);

        if (params && params.code) {

            const authorizationCode = params.code;

            const clientStripeActivation = await fetch('/.netlify/functions/server/api/stripe-authorize-client', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    authorizationCode
                })
            });

            const data = await clientStripeActivation.json();

            if (data && !data.error) {
                var connected_account_id = data.stripe_user_id;
                const snippetContent = `<script>window.stripe_user_id="${connected_account_id}";</script>`;

                this.createOrUpdateNetlifySnippet(snippetContent);

                this.setState({
                    connected: true,
                    accountId: connected_account_id
                });
            } else {
                this.setState({
                    alert: true,
                    alertColor: "danger",
                    alertMessage: `Error: ${data.error}`
                });
            }
        } else {

            const snippet = await this.fetchNetlifySnippet();

            if (snippet) {
                this.setState({
                    isEligible: true,
                    connected: true,
                });

            } else {

                const shopifyAdminId = localStorage.getItem("whitelabelAdminUserId") || sessionStorage.getItem("whitelabelAdminUserId");

                const currentTags = await fetch('/.netlify/functions/server/api/get-customer', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        customerId: shopifyAdminId,
                        fields: `id tags`,
                    }),
                })
                    .then(response => {
                        if (response.status === 200) {
                            return response.json();
                        }
                        throw response;
                    })
                    .then(json => {
                        console.log(json);
                        return json.data.customer.tags;
                    })
                    .catch((err) => {
                        if (err.message) {
                            toast.error(`${err.message}`);
                            return err.message;
                        } else {
                            toast.error(`${err.status}: ${err.statusText}`);
                            return err.statusText;
                        }
                    });

                if (typeof currentTags !== "string") {
                    currentTags.forEach((tag) => {
                        const stripeTagIndex = tag.indexOf("direct_payout_eligible");
                        if (stripeTagIndex !== -1) {
                            this.setState({
                                isEligible: true,
                            });
                        }
                    });
                }
            }
        }
    }

    fetchNetlifySnippet = async () => {
        return getNetlifySnippetByTitle(this.context.reseller_site_id, snippetTitle)
            .then((response) => {
                if (response.id && response.general) {
                    // Update the state with the Netlify snippet data
                    this.decodeSnippet(response.general);
                    this.setState({
                        netlifySnippetId: response.id
                    });
                    const { history } = this.props;
                    history.push({ pathname: "/settings/preferences", query: {} });
                    return response;
                } else {
                    return null;
                }
            });
    }

    createOrUpdateNetlifySnippet = async (snippetContent) => {

        const snippet = await this.fetchNetlifySnippet();

        if (snippet) {
            // Update Netlify Snippet
            updateNetlifySnippet(this.context.reseller_site_id, snippetTitle, snippetContent, snippet.id);
        } else {
            // Add Netlify Snippet
            addNetlifySnippet(this.context.reseller_site_id, snippetTitle, snippetContent)
                .then((response) => {
                    if (response.status === 201) {
                        this.fetchNetlifySnippet();
                    } else {
                        console.log(response);
                    }
                });
        }
    }

    decodeSnippet = (snippetContent) => {

        snippetContent = snippetContent.replace("<script>window.stripe_user_id=\"", "").replace("\";</script>", "");

        this.setState({
            accountId: snippetContent
        });
    }

    toggleAlert = () => {
        this.setState({
            alert: !this.state.alert
        });
    }

    render() {

        const { isEligible, connected, accountId } = this.state;

        return <div className="domain-statement mb-4">
            {connected ? <p className="mb-4">You've successfully connected your Stripe account <b>{accountId}</b> for direct payouts!</p> : isEligible ? <p className="mb-4">You are eligible for direct payouts. Connect a Stripe account to begin receiving daily payouts.</p> : <p className="mb-4">You are not currently eligible for direct payouts.</p>}
            {(isEligible || connected) &&
                <a href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${`${window.location.href}`}`}>
                    <button disabled={connected} class="btn btn-primary">{connected ? "Stripe account connected" : "Connect Stripe Account"}</button>
                </a>}
            <div className="mt-3">
                <Alert color={this.state.alertColor} isOpen={this.state.alert} toggle={this.toggleAlert.bind(this)}>
                    {this.state.alertMessage}
                </Alert>
            </div>
        </div>
    }
}

StripeSettings.contextType = AuthContext;

export default withRouter(StripeSettings);