
const LocationAddAddressInput = {
  "name": "LocationAddAddressInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "address1": "String",
    "address2": "String",
    "city": "String",
    "phone": "String",
    "zip": "String",
    "countryCode": "CountryCode",
    "provinceCode": "String"
  }
};
export default LocationAddAddressInput;