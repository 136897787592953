
const ShopifyPaymentsChargeStatementDescriptor = {
  "name": "ShopifyPaymentsChargeStatementDescriptor",
  "kind": "INTERFACE",
  "fieldBaseTypes": {
    "default": "String",
    "prefix": "String"
  },
  "possibleTypes": ["ShopifyPaymentsDefaultChargeStatementDescriptor", "ShopifyPaymentsJpChargeStatementDescriptor"]
};
export default ShopifyPaymentsChargeStatementDescriptor;