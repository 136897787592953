
const CollectionRuleSet = {
  "name": "CollectionRuleSet",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "appliedDisjunctively": "Boolean",
    "rules": "CollectionRule"
  },
  "implementsNode": false
};
export default CollectionRuleSet;