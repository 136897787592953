
const DiscountAutomaticBxgy = {
  "name": "DiscountAutomaticBxgy",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "asyncUsageCount": "Int",
    "combinesWith": "DiscountCombinesWith",
    "createdAt": "DateTime",
    "customerBuys": "DiscountCustomerBuys",
    "customerGets": "DiscountCustomerGets",
    "discountClass": "MerchandiseDiscountClass",
    "endsAt": "DateTime",
    "events": "EventConnection",
    "id": "ID",
    "startsAt": "DateTime",
    "status": "DiscountStatus",
    "summary": "String",
    "title": "String",
    "updatedAt": "DateTime",
    "usageCount": "Int",
    "usesPerOrderLimit": "Int"
  },
  "implementsNode": true
};
export default DiscountAutomaticBxgy;