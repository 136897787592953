
const CustomShippingPackageInput = {
  "name": "CustomShippingPackageInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "weight": "WeightInput",
    "dimensions": "ObjectDimensionsInput",
    "default": "Boolean",
    "name": "String",
    "type": "ShippingPackageType"
  }
};
export default CustomShippingPackageInput;