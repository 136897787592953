
const ProductVariant = {
  "name": "ProductVariant",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "availableForSale": "Boolean",
    "barcode": "String",
    "compareAtPrice": "MoneyV2",
    "compareAtPriceV2": "MoneyV2",
    "currentlyNotInStock": "Boolean",
    "id": "ID",
    "image": "Image",
    "metafield": "Metafield",
    "metafields": "Metafield",
    "price": "MoneyV2",
    "priceV2": "MoneyV2",
    "product": "Product",
    "quantityAvailable": "Int",
    "quantityPriceBreaks": "QuantityPriceBreakConnection",
    "quantityRule": "QuantityRule",
    "requiresShipping": "Boolean",
    "selectedOptions": "SelectedOption",
    "sellingPlanAllocations": "SellingPlanAllocationConnection",
    "sku": "String",
    "storeAvailability": "StoreAvailabilityConnection",
    "taxable": "Boolean",
    "title": "String",
    "unitPrice": "MoneyV2",
    "unitPriceMeasurement": "UnitPriceMeasurement",
    "weight": "Float",
    "weightUnit": "WeightUnit"
  },
  "implementsNode": true
};
export default ProductVariant;