
const StagedMediaUploadTarget = {
  "name": "StagedMediaUploadTarget",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "parameters": "StagedUploadParameter",
    "resourceUrl": "URL",
    "url": "URL"
  },
  "implementsNode": false
};
export default StagedMediaUploadTarget;