
const SubscriptionDeliveryMethodShippingOptionInput = {
  "name": "SubscriptionDeliveryMethodShippingOptionInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "title": "String",
    "presentmentTitle": "String",
    "description": "String",
    "code": "String",
    "carrierServiceId": "ID"
  }
};
export default SubscriptionDeliveryMethodShippingOptionInput;