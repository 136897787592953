import axios from 'axios';
const cloudName = 'stomp-stickers-staging';
const unsignedUploadPreset = 'reseller_logo';
const header = { headers: { 'Content-Type': 'multipart/form-data' } };

// *********** Upload file to Cloudinary ******************** //
export const uploadFileToCloudinary = (file, heightPixels, resellerId) => {
    const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`;

    let fd = new FormData();
    fd.append('upload_preset', unsignedUploadPreset);
    fd.append('tags', 'browser_upload'); // Optional - add tag for image admin in Cloudinary
    fd.append('folder', resellerId);
    fd.append('file', file);


    const request =  axios.post(url, fd, header).then((response) => {
        // File uploaded successfully

        // https://res.cloudinary.com/cloudName/image/upload/v1483481128/public_id.jpg
        const secureUrl = response.data.secure_url;
        // Create a thumbnail of the uploaded image, with 150px width
        let tokens = secureUrl.split('/');
        tokens.splice(-3, 0, `h_${heightPixels}`);
        const imgSrc = tokens.join('/');
        return imgSrc;
    })
    .catch(err => err);

    return request;
  }