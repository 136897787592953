
const ProductOptionsReorderUserError = {
  "name": "ProductOptionsReorderUserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "ProductOptionsReorderUserErrorCode",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
export default ProductOptionsReorderUserError;