
const ShopifyPaymentsFraudSettings = {
  "name": "ShopifyPaymentsFraudSettings",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "declineChargeOnAvsFailure": "Boolean",
    "declineChargeOnCvcFailure": "Boolean"
  },
  "implementsNode": false
};
export default ShopifyPaymentsFraudSettings;