
const LocationAddInput = {
  "name": "LocationAddInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "name": "String",
    "address": "LocationAddAddressInput",
    "fulfillsOnlineOrders": "Boolean",
    "metafields": "MetafieldInput"
  }
};
export default LocationAddInput;