
const FulfillmentServiceCreatePayload = {
  "name": "FulfillmentServiceCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "fulfillmentService": "FulfillmentService",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default FulfillmentServiceCreatePayload;