
const ProductReorderMediaPayload = {
  "name": "ProductReorderMediaPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "job": "Job",
    "mediaUserErrors": "MediaUserError",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default ProductReorderMediaPayload;