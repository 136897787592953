
const SubscriptionDraftLineAddPayload = {
  "name": "SubscriptionDraftLineAddPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "draft": "SubscriptionDraft",
    "lineAdded": "SubscriptionLine",
    "userErrors": "SubscriptionDraftUserError"
  },
  "implementsNode": false
};
export default SubscriptionDraftLineAddPayload;