
const ApplePayWalletContentInput = {
  "name": "ApplePayWalletContentInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "billingAddress": "MailingAddressInput",
    "data": "String",
    "header": "ApplePayWalletHeaderInput",
    "lastDigits": "String",
    "signature": "String",
    "version": "String"
  }
};
export default ApplePayWalletContentInput;