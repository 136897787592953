
const AddAllProductsOperation = {
  "name": "AddAllProductsOperation",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "processedRowCount": "Int",
    "rowCount": "RowCount",
    "status": "ResourceOperationStatus"
  },
  "implementsNode": true
};
export default AddAllProductsOperation;