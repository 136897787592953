
const CustomerMergeable = {
  "name": "CustomerMergeable",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "errorFields": "CustomerMergeErrorFieldType",
    "isMergeable": "Boolean",
    "mergeInProgress": "CustomerMergeRequest",
    "reason": "String"
  },
  "implementsNode": false
};
export default CustomerMergeable;