
const CompanyLocationAssignAddressPayload = {
  "name": "CompanyLocationAssignAddressPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "addresses": "CompanyAddress",
    "userErrors": "BusinessCustomerUserError"
  },
  "implementsNode": false
};
export default CompanyLocationAssignAddressPayload;