
const CartLineInput = {
  "name": "CartLineInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "attributes": "AttributeInput",
    "quantity": "Int",
    "merchandiseId": "ID",
    "sellingPlanId": "ID"
  }
};
export default CartLineInput;