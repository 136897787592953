
const OrderTransaction = {
  "name": "OrderTransaction",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "accountNumber": "String",
    "amount": "Money",
    "amountSet": "MoneyBag",
    "amountV2": "MoneyV2",
    "authorizationCode": "String",
    "authorizationExpiresAt": "DateTime",
    "createdAt": "DateTime",
    "errorCode": "OrderTransactionErrorCode",
    "fees": "TransactionFee",
    "formattedGateway": "String",
    "gateway": "String",
    "id": "ID",
    "kind": "OrderTransactionKind",
    "manuallyCapturable": "Boolean",
    "maximumRefundable": "Money",
    "maximumRefundableV2": "MoneyV2",
    "multiCapturable": "Boolean",
    "order": "Order",
    "parentTransaction": "OrderTransaction",
    "paymentDetails": "PaymentDetails",
    "paymentIcon": "Image",
    "paymentId": "String",
    "paymentMethod": "PaymentMethods",
    "processedAt": "DateTime",
    "receiptJson": "JSON",
    "settlementCurrency": "CurrencyCode",
    "settlementCurrencyRate": "Decimal",
    "shopifyPaymentsSet": "ShopifyPaymentsTransactionSet",
    "status": "OrderTransactionStatus",
    "test": "Boolean",
    "totalUnsettled": "Money",
    "totalUnsettledSet": "MoneyBag",
    "totalUnsettledV2": "MoneyV2",
    "user": "StaffMember"
  },
  "implementsNode": true
};
export default OrderTransaction;