
const SubscriptionLineInput = {
  "name": "SubscriptionLineInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "productVariantId": "ID",
    "quantity": "Int",
    "currentPrice": "Decimal",
    "customAttributes": "AttributeInput",
    "sellingPlanId": "ID",
    "sellingPlanName": "String",
    "pricingPolicy": "SubscriptionPricingPolicyInput"
  }
};
export default SubscriptionLineInput;