
const CartSubmitForCompletionPayload = {
  "name": "CartSubmitForCompletionPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "result": "CartSubmitForCompletionResult",
    "userErrors": "CartUserError"
  },
  "implementsNode": false
};
export default CartSubmitForCompletionPayload;