
const Currency = {
  "name": "Currency",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "isoCode": "CurrencyCode",
    "name": "String",
    "symbol": "String"
  },
  "implementsNode": false
};
export default Currency;