
const SubscriptionDeliveryMethodPickupOption = {
  "name": "SubscriptionDeliveryMethodPickupOption",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "String",
    "description": "String",
    "location": "Location",
    "presentmentTitle": "String",
    "title": "String"
  },
  "implementsNode": false
};
export default SubscriptionDeliveryMethodPickupOption;