
const CartInput = {
  "name": "CartInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "attributes": "AttributeInput",
    "lines": "CartLineInput",
    "discountCodes": "String",
    "note": "String",
    "buyerIdentity": "CartBuyerIdentityInput",
    "metafields": "CartInputMetafieldInput"
  }
};
export default CartInput;