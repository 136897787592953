
const CompanyInput = {
  "name": "CompanyInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "name": "String",
    "note": "String",
    "externalId": "String",
    "customerSince": "DateTime"
  }
};
export default CompanyInput;