
const PrivateMetafieldDeletePayload = {
  "name": "PrivateMetafieldDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedPrivateMetafieldId": "ID",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default PrivateMetafieldDeletePayload;