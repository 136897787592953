import React, { Component, Fragment } from 'react';
import Modal from 'react-responsive-modal';
import Breadcrumb from '../common/bread-crumb';
import GeneralInput from '../../components/common/general-input';
import { AvForm } from "availity-reactstrap-validation";
import ReactDragListView from 'react-drag-listview'
import { Trash2, XCircle, Plus } from 'react-feather';
import { ReactComponent as DraggableHandle } from '../../assets/svgs/draggable-handle.svg'
import { getNetlifySnippetByTitle, updateNetlifySnippet } from '../../querys/netlify-rest';
import { encodeSnippet, decodeSnippet } from '../utils/snippets'
import MenuEditModal from './menu-item-edit-modal'
import { AuthContext } from "../../auth/auth";
import Loader from '../loader/loader';
import { withRouter } from "react-router-dom";
import cuid from 'cuid';
import { DEFAULT_MENUS } from "../utils/constant";

export class MenuEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalDeleteOpen: false,
            menu: { items: [] },
            loading: true,
            netlifySnippetId: null,
            currentMenuItem: {},
            modalEditOpen: false,
            isNew: true,
            alert: false,
            alertMessage: "",
            alertColor: "success",
        };
    }

    componentDidMount = () => {
        if (this.props.location.state) {
            const { menu, netlifySnippetId } = this.props.location.state
            this.setState({
                menu,
                loading: false,
                netlifySnippetId
            })

            const { history } = this.props

            history.replace({
                ...this.props.location,
                state: undefined
            })

        } else if (this.props.match.params.handle) {
            //Fetch menu from snippet
            this.fetchNetlifySnippet()
                .then(res => {
                    if (res) {
                        const { menus, netlifySnippetId } = res
                        const menu = menus.find(mi => mi.handle === this.props.match.params.handle)

                        if (menu) {
                            this.setState({ menu, loading: false, netlifySnippetId })
                        } else {
                            this.props.history.push("/navigation/menus");
                        }
                    }
                })
        }

        window.analytics.page();
    }


    onCloseDeleteModal = () => {
        this.setState({
            modalDeleteOpen: false
        })
    }

    onOpenDeleteModal = (currentMenuItem) => {
        this.setState({
            modalDeleteOpen: true,
            currentMenuItem
        })
    }

    onCloseEditModal = () => {
        this.setState({
            modalEditOpen: false
        })
    }

    onSaveEditModal = (item) => {
        const { menu, currentMenuItem, isNew } = this.state

        let expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
        let relativeExpression = /^(\/*[a-zA-Z0-9!@#&=?$\-]+)+\/*$/gi;
        var regex = new RegExp(expression);
        var relativeRegex = new RegExp(relativeExpression);

        //Check if the CTA Button Link is not a relative URL
        if (regex.test(item.link) === true) {
            const conditions = ["http://", "https://"];
            //Checks if the URL is missing the protocol
            const hasProtocol = conditions.some(el => item.link.includes(el));

            if (!hasProtocol) {

                this.setState({
                    alert: true,
                    alertMessage: `Please make sure to include the protocol in front of the CTA Button Link (${conditions.map((el) => ` ${el}`)})`,
                    alertColor: "danger"
                });
                return;
            }
        } else if (relativeRegex.test(item.link) === false) {

            this.setState({
                alert: true,
                alertMessage: `Please make sure the relative URL of the CTA Button Link is valid.`,
                alertColor: "danger"
            });
            return;
        }

        if (isNew) {
            item.id = cuid();
            menu.items.push(item)
        } else {
            menu.items.splice(menu.items.findIndex(m => m.id === currentMenuItem.id), 1, { ...currentMenuItem, ...item })
        }

        this.setState({ modalEditOpen: false, alert: false })

    }

    onOpenEditModal = (currentMenuItem = {}, isNew = true) => {
        this.setState({
            currentMenuItem,
            isNew,
            modalEditOpen: true,
            alert: false
        })
    }

    deleteMenuItem = () => {
        let { menu, currentMenuItem } = this.state

        menu.items.splice(menu.items.findIndex(m => m.id === currentMenuItem.id), 1)

        this.setState({
            menu,
            modalDeleteOpen: false
        })

    }

    fetchNetlifySnippet = () => {
        return getNetlifySnippetByTitle(this.context.reseller_site_id, "Menu")
            .then((response) => {
                if (response.id && response.general) {
                    if (response.id && response.general) {
                        // Update the state with the Netlify snippet data
                        //Decode snippet
                        let menus = decodeSnippet(response.general, "Menu")
                        this.setState({
                            netlifySnippetId: response.id
                        })
                        return { menus, netlifySnippetId: response.id }

                    } else {
                        return null
                    }
                }
            });
    }

    handleValidSubmit = (_event, values) => {


        this.setState({ loading: true })

        let { menu } = this.state

        menu = {
            ...menu,
            ...values
        }

        this.setState({
            menu
        })

        // Update Menu in Snippet
        this.fetchNetlifySnippet()
            .then(res => {

                if (res) {
                    const { menus, netlifySnippetId } = res
                    menus.splice(menus.findIndex(m => m.id === menu.id), 1, menu)

                    if (netlifySnippetId) {
                        updateNetlifySnippet(this.context.reseller_site_id, "Menu", encodeSnippet(menus, "Menu"), netlifySnippetId)
                            .then(() => {
                                this.setState({ loading: false })
                            })
                    }
                }
            });
    }


    toggle = () => {
        this.setState({
            alert: !this.state.alert
        });
    }

    render() {

        const { loading } = this.state
        if (loading) return <Loader />

        const that = this;

        const dragProps = {
            onDragEnd(fromIndex, toIndex) {
                const data = that.state.menu.items;
                const item = data.splice(fromIndex, 1)[0];
                data.splice(toIndex, 0, item);
                that.setState((state) => ({
                    menu: {
                        ...state.menu,
                        items: data
                    }
                }));
            },
            nodeSelector: 'tr',
            handleSelector: 'a'
        };

        const { menu } = this.state
        const defaultMenuValues = DEFAULT_MENUS.find(m => m.id === menu.id);
        let maxItemsCount = 6;
        if (defaultMenuValues) {
            maxItemsCount = defaultMenuValues.maxItemsCount;
        }
        return (
            <Fragment>
                <Breadcrumb mainTitle="Navigation" title="Edit" parent="Navigation" />
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <h5>{`Edit ${menu.title}`}</h5>
                        </div>
                        <div className="card-body">
                            <AvForm
                                className="needs-validation add-product-form"
                                onValidSubmit={this.handleValidSubmit}
                                onInvalidSubmit={this.handleInvalidSubmit}
                                model={menu}
                            >
                                <div className="row">
                                    <div className="col-sm-12 col-xl-3 col-md-4 mb-3">
                                        <span className="text-secondary">
                                            {menu.description}
                                        </span>
                                    </div>
                                    <div className="col-sm-12 offset-x1-1 offset-md-1 col-xl-7 col-md-6">
                                        <GeneralInput
                                            label="Menu Title"
                                            name="title"
                                            placeholder="Primary Menu"
                                        />
                                        <label className="mb-2">Menu Items</label>

                                        <div className="table-responsive">
                                            <ReactDragListView {...dragProps}>
                                                <table className="table table-md border rounded">
                                                    <tbody>
                                                        {menu.items.map((item, index) => {
                                                            const cantBeDeleted = item.handle && (item.handle.includes("privacy-policy") || item.handle.includes("terms-and-conditions"));
                                                            return <tr key={index}>
                                                                <td>
                                                                    <a href="#" className="px-1"><DraggableHandle /></a>
                                                                    <span className="ml-2"><b>{item.title}</b></span>
                                                                </td>
                                                                <td width="1%" className="px-0">
                                                                    {!cantBeDeleted ?
                                                                        <button type="button" className="btn btn-outline-primary btn-custom-white px-3" onClick={() => this.onOpenEditModal(item, false)} >
                                                                            Edit
                                                                    </button> : <></>}
                                                                </td>
                                                                <td width="1%" className="px-3 text-secondary py-0">
                                                                    {!cantBeDeleted ? <Trash2 size="20" onClick={() => this.onOpenDeleteModal(item)} /> : <></>}
                                                                </td>
                                                            </tr>
                                                        }
                                                        )}
                                                        {menu.items.length < maxItemsCount &&
                                                            < tr style={{ backgroundColor: "rgb(249,249,249)" }}>
                                                                <td colSpan={3} className="text-primary py-3">
                                                                    <a href="javascript:void(0);" onClick={this.onOpenEditModal}>
                                                                        <div style={{ width: '14px', height: '14px', border: '1px solid', borderRadius: '50%', float: 'left', marginRight: '7px', letterSpacing: '1.5px', marginTop: '1px' }}>
                                                                            <Plus size={12} />
                                                                        </div>
                                                                        <div> <b>ADD MENU ITEM</b> </div>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </ReactDragListView>
                                        </div>
                                        <div className="text-center text-md-left mt-4">
                                            <button className="btn btn-primary btn-custom mr-3">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </AvForm>
                        </div>
                    </div>
                </div>

                <Modal
                    open={this.state.modalDeleteOpen}
                    onClose={this.onCloseDeleteModal}
                    showCloseIcon={false}
                    styles={{
                        modal: {
                            borderRadius: "10px",
                            overflow: 'hidden'
                        }
                    }}
                    center
                >
                    <div className="container modal-lg modal-alert" >
                        <div className="py-5 px-3">
                            <div className="d-flex justify-content-between">
                                <h5 style={{ color: "black", fontSize: "150%" }} ><b>Delete Menu</b></h5>
                                <div>
                                    <a onClick={this.onCloseDeleteModal}> <XCircle size={24} color="black" /></a>
                                </div>
                            </div>
                            <div className="text-secondary mt-1"
                                style={{ fontSize: "130%", color: "#777777" }} >
                                Are you sure you want delete this menu item?
                            </div>
                            <div className="mt-4">
                                <button type="button"
                                    className="btn btn-outline-primary py-2 px-4 mr-3"
                                    onClick={this.onCloseDeleteModal}
                                >Cancel</button>
                                <button type="button" className="btn btn-danger py-2 px-4" style={{ backgroundColor: "rgb(255,0,0) !important" }} onClick={this.deleteMenuItem}> Delete </button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <MenuEditModal
                    item={this.state.currentMenuItem}
                    isOpen={this.state.modalEditOpen}
                    isNew={this.state.isNew}
                    onCloseModal={this.onCloseEditModal}
                    onSaveModal={this.onSaveEditModal}
                    alert={this.state.alert}
                    alertColor={this.state.alertColor}
                    alertMessage={this.state.alertMessage}
                    toggle={this.toggle}
                />
            </Fragment >
        )
    }
}

MenuEdit.contextType = AuthContext

export default withRouter(MenuEdit);
