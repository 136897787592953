
const LineItemGroup = {
  "name": "LineItemGroup",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "quantity": "Int",
    "title": "String",
    "variantId": "ID",
    "variantSku": "String"
  },
  "implementsNode": false
};
export default LineItemGroup;