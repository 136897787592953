
const CompanyLocationCatalog = {
  "name": "CompanyLocationCatalog",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "companyLocations": "CompanyLocationConnection",
    "companyLocationsCount": "Count",
    "id": "ID",
    "operations": "ResourceOperation",
    "priceList": "PriceList",
    "publication": "Publication",
    "status": "CatalogStatus",
    "title": "String"
  },
  "implementsNode": true
};
export default CompanyLocationCatalog;