
const RefundLineItem = {
  "name": "RefundLineItem",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "lineItem": "LineItem",
    "location": "Location",
    "price": "Money",
    "priceSet": "MoneyBag",
    "quantity": "Int",
    "restockType": "RefundLineItemRestockType",
    "restocked": "Boolean",
    "subtotal": "Money",
    "subtotalSet": "MoneyBag",
    "totalTax": "Money",
    "totalTaxSet": "MoneyBag"
  },
  "implementsNode": false
};
export default RefundLineItem;