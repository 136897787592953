
const MetafieldDefinitionDeletePayload = {
  "name": "MetafieldDefinitionDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedDefinitionId": "ID",
    "userErrors": "MetafieldDefinitionDeleteUserError"
  },
  "implementsNode": false
};
export default MetafieldDefinitionDeletePayload;