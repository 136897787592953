import React, { Component } from "react";
import { AvField } from "availity-reactstrap-validation";
import { getNetlifySnippetByTitle, addNetlifySnippet, updateNetlifySnippet } from '../../../querys/netlify-rest';
import { AuthContext } from '../../../auth/auth'
import { Alert } from "reactstrap";
import { fetchCollectionsFromSnippet } from "../../utils/collection";
import Loader from "../../loader/loader";

const snippetTitle = "Product Grid"

class CustomizeProductGrid extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      selectedOption: 12,
      productGrid: {
        count: 12,
        headline: "",
        headlinePlaceholder: "Popular Products",
        categoryHandle: "",
        categoryId: ""
      },
      snippetId: null,
      collections: [],
      alert: false,
      alertMessage: "",
      alertColor: ""
    }
  }

  componentDidMount = () => {
    const fields = `
        id
        title
        description
        handle
    `;

    fetchCollectionsFromSnippet(this.context.reseller_site_id, fields).then(response => {
      const collections = response;
      this.setState({ collections });

      this.fetchNetlifySnippet();

    })
      .catch(error => {
        this.setState({
          loading: false,
          alert: true,
          alertMessage: "Error fetching category list: " + error,
          alertColor: "danger"
        })
      })
  }

  fetchNetlifySnippet = () => {
    getNetlifySnippetByTitle(this.context.reseller_site_id, snippetTitle)
      .then((response) => {
        if (response.id && response.general) {
          const productGrid = response.general.replace("<script>window.productGrid=", "").replace("</script>", "");
          this.setState({
            snippetId: response.id,
            productGrid: JSON.parse(productGrid),
            loading: false
          });
        } else {
          this.setState({
            loading: false
          });
        }
      })
      .catch(error => {
        this.setState({
          alert: true,
          alertMessage: "Error fetching product grid: " + error,
          alertColor: "danger"
        })
      })
  }

  handleHeadlineChange = (ev) => {
    let { productGrid } = this.state

    const value = ev.target.value

    productGrid.headline = value

    this.setState({ productGrid })

  }

  handleCollectionChange = (ev) => {

    let { productGrid } = this.state

    productGrid.categoryId = ev.target.value;

    this.setState({ productGrid })

  }

  handleOptionChange = (changeEvent) => {

    const selectedOption = parseInt(changeEvent.target.value)

    let { productGrid } = this.state

    productGrid.count = selectedOption

    this.setState({ productGrid })

  }

  handleSave = () => {

    this.setState({
      alert: true,
      alertMessage: "Updating product grid...",
      alertColor: "secondary"
    });

    const { productGrid } = this.state

    const content = `\u003Cscript\u003Ewindow.productGrid=${JSON.stringify(productGrid)}\u003C/script\u003E`;
    getNetlifySnippetByTitle(this.context.reseller_site_id, snippetTitle)
      .then((response) => {
        if (response.id && response.general) {
          const snippetId = response.id;

          updateNetlifySnippet(this.context.reseller_site_id, snippetTitle, content, snippetId).then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.setState({
                alert: true,
                alertMessage: "Success: Your Product Grid was updated on your site.",
                alertColor: "success"
              });
              // Fetch the Snippet ID and update state
              this.fetchNetlifySnippet();
            } else {
              this.setState({
                alert: true,
                alertMessage: response.message,
                alertColor: "danger"
              });
            }
          })

        } else {
          addNetlifySnippet(this.context.reseller_site_id, snippetTitle, content).then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.setState({
                alert: true,
                alertMessage: "Success: Your Product Grid was updated on your site.",
                alertColor: "success"
              });
              // Fetch the Snippet ID and update state
              this.fetchNetlifySnippet();
            } else {
              this.setState({
                alert: true,
                alertMessage: response.message,
                alertColor: "danger"
              });
            }
          })
        }

      })

  }

  toggle() {
    this.setState({
      alert: !this.state.alert
    });
  }


  render() {

    if (this.state.loading) return <Loader />

    const { collections, productGrid } = this.state

    return (
      <div className="card">
        <div className="card-header">
          <h5>Product Grid</h5>
        </div>
        <div className="card-body pt-0">
          <div className="row">
            <div className="col-sm-12 col-md-3 col-xl-2 text-secondary mt-4">
              The product grid displays products in increments of 4. You can choose which products to display here.
            </div>
            <div className="col-sm-12 col-md-6 col-xl-5 offset-xl-1 mt-4">
              <div >
                <input
                  className="checkbox_animated"
                  id="chk-ani-1"
                  type="checkbox"
                  value="4"
                  checked={productGrid.count === 4}
                  onChange={this.handleOptionChange}
                />
                  4
                  <input
                  className="checkbox_animated ml-4"
                  id="chk-ani-2"
                  type="checkbox"
                  value="8"
                  checked={productGrid.count === 8}
                  onChange={this.handleOptionChange}
                />
                  8
                  <input
                  className="checkbox_animated ml-4"
                  id="chk-ani-3"
                  type="checkbox"
                  value="12"
                  checked={productGrid.count === 12}
                  onChange={this.handleOptionChange}
                />
                  12
                  <input
                  className="checkbox_animated ml-4"
                  id="chk-ani-4"
                  type="checkbox"
                  value="16"
                  checked={productGrid.count === 16}
                  onChange={this.handleOptionChange}
                />
                  16
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <input
                    className="form-control my-1"
                    id="validationCustom-product-grid"
                    type="input"
                    required=""
                    placeholder={productGrid.headlinePlaceholder}
                    value={productGrid.headline}
                    onChange={this.handleHeadlineChange}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <AvField type="select" name="select" onChange={this.handleCollectionChange} value={productGrid.categoryId}>
                    <option value="">Select a Category</option>
                    {collections.map(c => <option key={c.id} value={btoa(c.id)}>{c.title}</option>)}
                  </AvField>
                </div>
              </div>
              <button className="btn btn-primary mt-3" onClick={this.handleSave}>
                Save
              </button>
              <br></br>
              <br></br>
              <Alert color={this.state.alertColor} isOpen={this.state.alert} toggle={this.toggle.bind(this)} > {this.state.alertMessage} </Alert>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

CustomizeProductGrid.contextType = AuthContext

export default CustomizeProductGrid;
