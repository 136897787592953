
const UrlRedirectUpdatePayload = {
  "name": "UrlRedirectUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "urlRedirect": "UrlRedirect",
    "userErrors": "UrlRedirectUserError"
  },
  "implementsNode": false
};
export default UrlRedirectUpdatePayload;