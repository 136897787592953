
const ProductVariantDeletePayload = {
  "name": "ProductVariantDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedProductVariantId": "ID",
    "product": "Product",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default ProductVariantDeletePayload;