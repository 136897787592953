
const UrlRedirectImport = {
  "name": "UrlRedirectImport",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "count": "Int",
    "createdCount": "Int",
    "failedCount": "Int",
    "finished": "Boolean",
    "finishedAt": "DateTime",
    "id": "ID",
    "previewRedirects": "UrlRedirectImportPreview",
    "updatedCount": "Int"
  },
  "implementsNode": true
};
export default UrlRedirectImport;