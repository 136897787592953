
const MetaobjectDefinitionConnection = {
  "name": "MetaobjectDefinitionConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "MetaobjectDefinitionEdge",
    "nodes": "MetaobjectDefinition",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default MetaobjectDefinitionConnection;