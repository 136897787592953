
const SellingPlanGroupUserError = {
  "name": "SellingPlanGroupUserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "SellingPlanGroupUserErrorCode",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
export default SellingPlanGroupUserError;