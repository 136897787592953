
const PriceRuleDeletePayload = {
  "name": "PriceRuleDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedPriceRuleId": "ID",
    "priceRuleUserErrors": "PriceRuleUserError",
    "shop": "Shop",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default PriceRuleDeletePayload;