
const InventoryQuantity = {
  "name": "InventoryQuantity",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "name": "String",
    "quantity": "Int",
    "updatedAt": "DateTime"
  },
  "implementsNode": true
};
export default InventoryQuantity;