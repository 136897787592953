
const PriceRuleDiscountCode = {
  "name": "PriceRuleDiscountCode",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "app": "App",
    "code": "String",
    "id": "ID",
    "usageCount": "Int"
  },
  "implementsNode": true
};
export default PriceRuleDiscountCode;