
const LinkedMetafield = {
  "name": "LinkedMetafield",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "key": "String",
    "namespace": "String"
  },
  "implementsNode": false
};
export default LinkedMetafield;