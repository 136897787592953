
const CustomerSegmentMembersQueryInput = {
  "name": "CustomerSegmentMembersQueryInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "segmentId": "ID",
    "query": "String",
    "reverse": "Boolean",
    "sortKey": "String"
  }
};
export default CustomerSegmentMembersQueryInput;