import React, { Component } from "react";
import Dropzone from 'react-dropzone';

class FileDropzone extends Component {

    render() {

        const { handleDrop, fileName, fileTypes } = this.props;

        return <Dropzone onDrop={acceptedFiles => handleDrop(acceptedFiles)} accept={fileTypes}>
            {({ getRootProps, getInputProps }) => (
                <form
                    className="dropzone digits dz-clickable mb-4 "
                    id="singleFileUpload"
                    action="/upload.php"
                    style={{
                        border: "2px dashed #777777",
                        backgroundColor: "#F7F7F7"
                    }}
                    {...getRootProps()}
                >
                    <div className="dz-message needsclick text-center" style={{ display: "flex", flexDirection: "column" }}>
                        <p>Drag files or click to browse</p>
                        <input {...getInputProps()} />
                        <div
                            className="border border-1 p-1 b-r-10 mx-auto d-flex justify-content-left align-items-center"
                            style={{ minWidth: "300px" }}
                        >
                            <button
                                type="button"
                                className="btn btn-primary btn-sm btn-delete b-r-4 text-uppercase"
                            >
                                Choose File
                    </button>
                            <p className="pl-3">{fileName}</p>
                        </div>
                    </div>
                </form>
            )}
        </Dropzone>
    }
}

export default FileDropzone;