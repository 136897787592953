
const BulkOperation = {
  "name": "BulkOperation",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "completedAt": "DateTime",
    "createdAt": "DateTime",
    "errorCode": "BulkOperationErrorCode",
    "fileSize": "UnsignedInt64",
    "id": "ID",
    "objectCount": "UnsignedInt64",
    "partialDataUrl": "URL",
    "query": "String",
    "rootObjectCount": "UnsignedInt64",
    "status": "BulkOperationStatus",
    "type": "BulkOperationType",
    "url": "URL"
  },
  "implementsNode": true
};
export default BulkOperation;