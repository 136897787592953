
const DeliveryProfile = {
  "name": "DeliveryProfile",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "activeMethodDefinitionsCount": "Int",
    "default": "Boolean",
    "id": "ID",
    "legacyMode": "Boolean",
    "locationsWithoutRatesCount": "Int",
    "name": "String",
    "originLocationCount": "Int",
    "productVariantsCount": "Count",
    "productVariantsCountV2": "DeliveryProductVariantsCount",
    "profileItems": "DeliveryProfileItemConnection",
    "profileLocationGroups": "DeliveryProfileLocationGroup",
    "sellingPlanGroups": "SellingPlanGroupConnection",
    "unassignedLocations": "Location",
    "unassignedLocationsPaginated": "LocationConnection",
    "zoneCountryCount": "Int"
  },
  "implementsNode": true
};
export default DeliveryProfile;