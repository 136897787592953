
const CompanyAddressDeletePayload = {
  "name": "CompanyAddressDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedAddressId": "ID",
    "userErrors": "BusinessCustomerUserError"
  },
  "implementsNode": false
};
export default CompanyAddressDeletePayload;