
const ShopifyPaymentsDisputeEvidence = {
  "name": "ShopifyPaymentsDisputeEvidence",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "accessActivityLog": "String",
    "billingAddress": "MailingAddress",
    "cancellationPolicyDisclosure": "String",
    "cancellationPolicyFile": "ShopifyPaymentsDisputeFileUpload",
    "cancellationRebuttal": "String",
    "customerCommunicationFile": "ShopifyPaymentsDisputeFileUpload",
    "customerEmailAddress": "String",
    "customerFirstName": "String",
    "customerLastName": "String",
    "customerPurchaseIp": "String",
    "dispute": "ShopifyPaymentsDispute",
    "disputeFileUploads": "ShopifyPaymentsDisputeFileUpload",
    "fulfillments": "ShopifyPaymentsDisputeFulfillment",
    "id": "ID",
    "productDescription": "String",
    "refundPolicyDisclosure": "String",
    "refundPolicyFile": "ShopifyPaymentsDisputeFileUpload",
    "refundRefusalExplanation": "String",
    "serviceDocumentationFile": "ShopifyPaymentsDisputeFileUpload",
    "shippingAddress": "MailingAddress",
    "shippingDocumentationFile": "ShopifyPaymentsDisputeFileUpload",
    "submitted": "Boolean",
    "uncategorizedFile": "ShopifyPaymentsDisputeFileUpload",
    "uncategorizedText": "String"
  },
  "implementsNode": true
};
export default ShopifyPaymentsDisputeEvidence;