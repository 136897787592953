
const BuyerExperienceConfigurationInput = {
  "name": "BuyerExperienceConfigurationInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "checkoutToDraft": "Boolean",
    "paymentTermsTemplateId": "ID",
    "editableShippingAddress": "Boolean"
  }
};
export default BuyerExperienceConfigurationInput;