
const SubscriptionDraft = {
  "name": "SubscriptionDraft",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "billingCycle": "SubscriptionBillingCycle",
    "billingPolicy": "SubscriptionBillingPolicy",
    "concatenatedBillingCycles": "SubscriptionBillingCycleConnection",
    "currencyCode": "CurrencyCode",
    "customAttributes": "Attribute",
    "customer": "Customer",
    "customerPaymentMethod": "CustomerPaymentMethod",
    "deliveryMethod": "SubscriptionDeliveryMethod",
    "deliveryOptions": "SubscriptionDeliveryOptionResult",
    "deliveryPolicy": "SubscriptionDeliveryPolicy",
    "deliveryPrice": "MoneyV2",
    "discounts": "SubscriptionDiscountConnection",
    "discountsAdded": "SubscriptionDiscountConnection",
    "discountsRemoved": "SubscriptionDiscountConnection",
    "discountsUpdated": "SubscriptionDiscountConnection",
    "id": "ID",
    "lines": "SubscriptionLineConnection",
    "linesAdded": "SubscriptionLineConnection",
    "linesRemoved": "SubscriptionLineConnection",
    "nextBillingDate": "DateTime",
    "note": "String",
    "originalContract": "SubscriptionContract",
    "shippingOptions": "SubscriptionShippingOptionResult",
    "status": "SubscriptionContractSubscriptionStatus"
  },
  "implementsNode": true
};
export default SubscriptionDraft;