
const CompanyLocationUpdateInput = {
  "name": "CompanyLocationUpdateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "name": "String",
    "phone": "String",
    "locale": "String",
    "externalId": "String",
    "note": "String",
    "buyerExperienceConfiguration": "BuyerExperienceConfigurationInput"
  }
};
export default CompanyLocationUpdateInput;