
const CartMetafieldsSetPayload = {
  "name": "CartMetafieldsSetPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "metafields": "Metafield",
    "userErrors": "MetafieldsSetUserError"
  },
  "implementsNode": false
};
export default CartMetafieldsSetPayload;