
const ProductJoinSellingPlanGroupsPayload = {
  "name": "ProductJoinSellingPlanGroupsPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "product": "Product",
    "userErrors": "SellingPlanGroupUserError"
  },
  "implementsNode": false
};
export default ProductJoinSellingPlanGroupsPayload;