
const DraftOrderUpdatePayload = {
  "name": "DraftOrderUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "draftOrder": "DraftOrder",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default DraftOrderUpdatePayload;