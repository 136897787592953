
const DiscountCustomerGets = {
  "name": "DiscountCustomerGets",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "appliesOnOneTimePurchase": "Boolean",
    "appliesOnSubscription": "Boolean",
    "items": "DiscountItems",
    "value": "DiscountCustomerGetsValue"
  },
  "implementsNode": false
};
export default DiscountCustomerGets;