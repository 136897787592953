
const DiscountAutomaticAppInput = {
  "name": "DiscountAutomaticAppInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "combinesWith": "DiscountCombinesWithInput",
    "functionId": "String",
    "title": "String",
    "startsAt": "DateTime",
    "endsAt": "DateTime",
    "metafields": "MetafieldInput"
  }
};
export default DiscountAutomaticAppInput;