
const DraftOrderBulkAddTagsPayload = {
  "name": "DraftOrderBulkAddTagsPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "job": "Job",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default DraftOrderBulkAddTagsPayload;