
const OrderRiskAssessmentCreateInput = {
  "name": "OrderRiskAssessmentCreateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "orderId": "ID",
    "riskLevel": "RiskAssessmentResult",
    "facts": "OrderRiskAssessmentFactInput"
  }
};
export default OrderRiskAssessmentCreateInput;