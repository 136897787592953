
const DiscountCodeApp = {
  "name": "DiscountCodeApp",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "appDiscountType": "AppDiscountType",
    "appliesOncePerCustomer": "Boolean",
    "asyncUsageCount": "Int",
    "codes": "DiscountRedeemCodeConnection",
    "codesCount": "Count",
    "combinesWith": "DiscountCombinesWith",
    "createdAt": "DateTime",
    "customerSelection": "DiscountCustomerSelection",
    "discountClass": "DiscountClass",
    "discountId": "ID",
    "endsAt": "DateTime",
    "errorHistory": "FunctionsErrorHistory",
    "hasTimelineComment": "Boolean",
    "recurringCycleLimit": "Int",
    "shareableUrls": "DiscountShareableUrl",
    "startsAt": "DateTime",
    "status": "DiscountStatus",
    "title": "String",
    "totalSales": "MoneyV2",
    "updatedAt": "DateTime",
    "usageLimit": "Int"
  },
  "implementsNode": false
};
export default DiscountCodeApp;