
const CartCompletionSuccess = {
  "name": "CartCompletionSuccess",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "completedAt": "DateTime",
    "id": "String",
    "orderId": "ID",
    "orderUrl": "URL"
  },
  "implementsNode": false
};
export default CartCompletionSuccess;