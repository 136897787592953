
const SellingPlanFixedBillingPolicy = {
  "name": "SellingPlanFixedBillingPolicy",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkoutCharge": "SellingPlanCheckoutCharge",
    "remainingBalanceChargeExactTime": "DateTime",
    "remainingBalanceChargeTimeAfterCheckout": "String",
    "remainingBalanceChargeTrigger": "SellingPlanRemainingBalanceChargeTrigger"
  },
  "implementsNode": false
};
export default SellingPlanFixedBillingPolicy;