
const PriceRuleItemPrerequisitesInput = {
  "name": "PriceRuleItemPrerequisitesInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "productIds": "ID",
    "productVariantIds": "ID",
    "collectionIds": "ID"
  }
};
export default PriceRuleItemPrerequisitesInput;