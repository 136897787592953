
const SavedSearchUpdateInput = {
  "name": "SavedSearchUpdateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "id": "ID",
    "name": "String",
    "query": "String"
  }
};
export default SavedSearchUpdateInput;