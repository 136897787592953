import React from 'react';
import styled from 'styled-components';


class ColorSelector extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pickerOpen: false
        }
    }

    toggle = () => {
        this.setState((state) => ({
            pickerOpen: !state.pickerOpen
        }));
    }

    render() {
        const { color, label, id, placeholder, onOpenPicker } = this.props;
        return (
            <>
                <div className="color-selector form-group d-flex flex-wrap-reserve pt-0 mb-0">
                    <button className="color-box mr-2" type="button" id={`btn-${id}`} style={{
                        backgroundColor: `${color}`,
                        borderStyle: "solid"
                    }} onClick={() => onOpenPicker(`btn-${id}`, color, id)}>  </button>

                    <input id={id} type="text" placeholder={placeholder} className="form-control mr-2" value={color} onChange={(ev) => this.props.handleChange(id, ev.target.value)} />
                    <label htmlFor="color" className="pt-1" > {label} </label>
                </div>

            </>
        )
    }
};

export default ColorSelector;