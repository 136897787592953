
const FulfillmentOrderMerchantRequest = {
  "name": "FulfillmentOrderMerchantRequest",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "fulfillmentOrder": "FulfillmentOrder",
    "id": "ID",
    "kind": "FulfillmentOrderMerchantRequestKind",
    "message": "String",
    "requestOptions": "JSON",
    "responseData": "JSON",
    "sentAt": "DateTime"
  },
  "implementsNode": true
};
export default FulfillmentOrderMerchantRequest;