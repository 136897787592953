
const MetafieldsSetPayload = {
  "name": "MetafieldsSetPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "metafields": "Metafield",
    "userErrors": "MetafieldsSetUserError"
  },
  "implementsNode": false
};
export default MetafieldsSetPayload;