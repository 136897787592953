
const ScriptTagInput = {
  "name": "ScriptTagInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "src": "URL",
    "displayScope": "ScriptTagDisplayScope",
    "cache": "Boolean"
  }
};
export default ScriptTagInput;