
const CustomerMomentConnection = {
  "name": "CustomerMomentConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "CustomerMomentEdge",
    "nodes": "CustomerMoment",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default CustomerMomentConnection;