
const CustomerMergeRequest = {
  "name": "CustomerMergeRequest",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customerMergeErrors": "CustomerMergeError",
    "jobId": "ID",
    "resultingCustomerId": "ID",
    "status": "CustomerMergeRequestStatus"
  },
  "implementsNode": false
};
export default CustomerMergeRequest;