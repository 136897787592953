
const CollectionRemoveProductsPayload = {
  "name": "CollectionRemoveProductsPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "job": "Job",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default CollectionRemoveProductsPayload;