
const InventoryItem = {
  "name": "InventoryItem",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "countryCodeOfOrigin": "CountryCode",
    "countryHarmonizedSystemCodes": "CountryHarmonizedSystemCodeConnection",
    "createdAt": "DateTime",
    "duplicateSkuCount": "Int",
    "harmonizedSystemCode": "String",
    "id": "ID",
    "inventoryHistoryUrl": "URL",
    "inventoryLevel": "InventoryLevel",
    "inventoryLevels": "InventoryLevelConnection",
    "legacyResourceId": "UnsignedInt64",
    "locationsCount": "Count",
    "measurement": "InventoryItemMeasurement",
    "provinceCodeOfOrigin": "String",
    "requiresShipping": "Boolean",
    "sku": "String",
    "tracked": "Boolean",
    "trackedEditable": "EditableProperty",
    "unitCost": "MoneyV2",
    "updatedAt": "DateTime",
    "variant": "ProductVariant"
  },
  "implementsNode": true
};
export default InventoryItem;