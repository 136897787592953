
const GiftCardUpdateInput = {
  "name": "GiftCardUpdateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "note": "String",
    "expiresOn": "Date",
    "customerId": "ID",
    "templateSuffix": "String"
  }
};
export default GiftCardUpdateInput;