
const MarketLocalizationRegisterInput = {
  "name": "MarketLocalizationRegisterInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "marketId": "ID",
    "key": "String",
    "value": "String",
    "marketLocalizableContentDigest": "String"
  }
};
export default MarketLocalizationRegisterInput;