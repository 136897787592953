
const OrderInvoiceSendPayload = {
  "name": "OrderInvoiceSendPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "order": "Order",
    "userErrors": "OrderInvoiceSendUserError"
  },
  "implementsNode": false
};
export default OrderInvoiceSendPayload;