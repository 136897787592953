
const RefundShippingLineConnection = {
  "name": "RefundShippingLineConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "RefundShippingLineEdge",
    "nodes": "RefundShippingLine",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default RefundShippingLineConnection;