
const DiscountAmount = {
  "name": "DiscountAmount",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "amount": "MoneyV2",
    "appliesOnEachItem": "Boolean"
  },
  "implementsNode": false
};
export default DiscountAmount;