
const LimitedPendingOrderCount = {
  "name": "LimitedPendingOrderCount",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "atMax": "Boolean",
    "count": "Int"
  },
  "implementsNode": false
};
export default LimitedPendingOrderCount;