
const AppPurchaseOneTimeCreatePayload = {
  "name": "AppPurchaseOneTimeCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "appPurchaseOneTime": "AppPurchaseOneTime",
    "confirmationUrl": "URL",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default AppPurchaseOneTimeCreatePayload;