
const OrderStagedChangeAddVariant = {
  "name": "OrderStagedChangeAddVariant",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "quantity": "Int",
    "variant": "ProductVariant"
  },
  "implementsNode": false
};
export default OrderStagedChangeAddVariant;