
const ProductCreatePayload = {
  "name": "ProductCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "product": "Product",
    "shop": "Shop",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default ProductCreatePayload;