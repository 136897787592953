
const SellingPlanRecurringDeliveryPolicy = {
  "name": "SellingPlanRecurringDeliveryPolicy",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "anchors": "SellingPlanAnchor",
    "createdAt": "DateTime",
    "cutoff": "Int",
    "intent": "SellingPlanRecurringDeliveryPolicyIntent",
    "interval": "SellingPlanInterval",
    "intervalCount": "Int",
    "preAnchorBehavior": "SellingPlanRecurringDeliveryPolicyPreAnchorBehavior"
  },
  "implementsNode": false
};
export default SellingPlanRecurringDeliveryPolicy;