
const PriceListDeletePayload = {
  "name": "PriceListDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedId": "ID",
    "userErrors": "PriceListUserError"
  },
  "implementsNode": false
};
export default PriceListDeletePayload;