
const MarketLocalizableResourceEdge = {
  "name": "MarketLocalizableResourceEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "MarketLocalizableResource"
  },
  "implementsNode": false
};
export default MarketLocalizableResourceEdge;