
const ExchangeLineItemConnection = {
  "name": "ExchangeLineItemConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "ExchangeLineItemEdge",
    "nodes": "ExchangeLineItem",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default ExchangeLineItemConnection;