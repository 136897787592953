
const DiscountOnQuantity = {
  "name": "DiscountOnQuantity",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "effect": "DiscountEffect",
    "quantity": "DiscountQuantity"
  },
  "implementsNode": false
};
export default DiscountOnQuantity;