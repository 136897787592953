
const ShopifyPaymentsDisputeEvidenceUpdateInput = {
  "name": "ShopifyPaymentsDisputeEvidenceUpdateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "customerEmailAddress": "String",
    "customerLastName": "String",
    "customerFirstName": "String",
    "shippingAddress": "MailingAddressInput",
    "uncategorizedText": "String",
    "accessActivityLog": "String",
    "cancellationPolicyDisclosure": "String",
    "cancellationRebuttal": "String",
    "refundPolicyDisclosure": "String",
    "refundRefusalExplanation": "String",
    "cancellationPolicyFile": "ShopifyPaymentsDisputeFileUploadUpdateInput",
    "customerCommunicationFile": "ShopifyPaymentsDisputeFileUploadUpdateInput",
    "refundPolicyFile": "ShopifyPaymentsDisputeFileUploadUpdateInput",
    "shippingDocumentationFile": "ShopifyPaymentsDisputeFileUploadUpdateInput",
    "uncategorizedFile": "ShopifyPaymentsDisputeFileUploadUpdateInput",
    "serviceDocumentationFile": "ShopifyPaymentsDisputeFileUploadUpdateInput",
    "submitEvidence": "Boolean"
  }
};
export default ShopifyPaymentsDisputeEvidenceUpdateInput;