
const BulkProductResourceFeedbackCreatePayload = {
  "name": "BulkProductResourceFeedbackCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "feedback": "ProductResourceFeedback",
    "userErrors": "BulkProductResourceFeedbackCreateUserError"
  },
  "implementsNode": false
};
export default BulkProductResourceFeedbackCreatePayload;