
const Company = {
  "name": "Company",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "createdAt": "DateTime",
    "externalId": "String",
    "id": "ID",
    "metafield": "Metafield",
    "metafields": "Metafield",
    "name": "String",
    "updatedAt": "DateTime"
  },
  "implementsNode": true
};
export default Company;