
const DeliveryParticipantInput = {
  "name": "DeliveryParticipantInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "id": "ID",
    "carrierServiceId": "ID",
    "fixedFee": "MoneyInput",
    "percentageOfRateFee": "Float",
    "participantServices": "DeliveryParticipantServiceInput",
    "adaptToNewServices": "Boolean"
  }
};
export default DeliveryParticipantInput;