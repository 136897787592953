
const ExchangeV2Edge = {
  "name": "ExchangeV2Edge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "ExchangeV2"
  },
  "implementsNode": false
};
export default ExchangeV2Edge;