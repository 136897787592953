
const SubscriptionBillingCycleContractDraftCommitPayload = {
  "name": "SubscriptionBillingCycleContractDraftCommitPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "contract": "SubscriptionBillingCycleEditedContract",
    "userErrors": "SubscriptionDraftUserError"
  },
  "implementsNode": false
};
export default SubscriptionBillingCycleContractDraftCommitPayload;