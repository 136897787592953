
const CheckoutBrandingFontSize = {
  "name": "CheckoutBrandingFontSize",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "base": "Float",
    "ratio": "Float"
  },
  "implementsNode": false
};
export default CheckoutBrandingFontSize;