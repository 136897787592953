
const OrderTransactionInput = {
  "name": "OrderTransactionInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "amount": "Money",
    "gateway": "String",
    "kind": "OrderTransactionKind",
    "orderId": "ID",
    "parentId": "ID"
  }
};
export default OrderTransactionInput;