
const Order = {
  "name": "Order",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "billingAddress": "MailingAddress",
    "cancelReason": "OrderCancelReason",
    "canceledAt": "DateTime",
    "currencyCode": "CurrencyCode",
    "currentSubtotalPrice": "MoneyV2",
    "currentTotalDuties": "MoneyV2",
    "currentTotalPrice": "MoneyV2",
    "currentTotalTax": "MoneyV2",
    "customAttributes": "Attribute",
    "customerLocale": "String",
    "customerUrl": "URL",
    "discountApplications": "DiscountApplicationConnection",
    "edited": "Boolean",
    "email": "String",
    "financialStatus": "OrderFinancialStatus",
    "fulfillmentStatus": "OrderFulfillmentStatus",
    "id": "ID",
    "lineItems": "OrderLineItemConnection",
    "metafield": "Metafield",
    "metafields": "Metafield",
    "name": "String",
    "orderNumber": "Int",
    "originalTotalDuties": "MoneyV2",
    "originalTotalPrice": "MoneyV2",
    "phone": "String",
    "processedAt": "DateTime",
    "shippingAddress": "MailingAddress",
    "shippingDiscountAllocations": "DiscountAllocation",
    "statusUrl": "URL",
    "subtotalPrice": "MoneyV2",
    "subtotalPriceV2": "MoneyV2",
    "successfulFulfillments": "Fulfillment",
    "totalPrice": "MoneyV2",
    "totalPriceV2": "MoneyV2",
    "totalRefunded": "MoneyV2",
    "totalRefundedV2": "MoneyV2",
    "totalShippingPrice": "MoneyV2",
    "totalShippingPriceV2": "MoneyV2",
    "totalTax": "MoneyV2",
    "totalTaxV2": "MoneyV2"
  },
  "implementsNode": true
};
export default Order;