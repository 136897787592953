
const RowCount = {
  "name": "RowCount",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "count": "Int",
    "exceedsMax": "Boolean"
  },
  "implementsNode": false
};
export default RowCount;