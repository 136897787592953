
const PriceListPrice = {
  "name": "PriceListPrice",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "compareAtPrice": "MoneyV2",
    "originType": "PriceListPriceOriginType",
    "price": "MoneyV2",
    "quantityPriceBreaks": "QuantityPriceBreakConnection",
    "variant": "ProductVariant"
  },
  "implementsNode": false
};
export default PriceListPrice;