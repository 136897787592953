
const ShopifyPaymentsAccount = {
  "name": "ShopifyPaymentsAccount",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "activated": "Boolean",
    "balance": "MoneyV2",
    "balanceTransactions": "ShopifyPaymentsBalanceTransactionConnection",
    "bankAccounts": "ShopifyPaymentsBankAccountConnection",
    "chargeStatementDescriptor": "String",
    "chargeStatementDescriptors": "ShopifyPaymentsChargeStatementDescriptor",
    "country": "String",
    "defaultCurrency": "CurrencyCode",
    "disputes": "ShopifyPaymentsDisputeConnection",
    "fraudSettings": "ShopifyPaymentsFraudSettings",
    "id": "ID",
    "notificationSettings": "ShopifyPaymentsNotificationSettings",
    "onboardable": "Boolean",
    "payoutSchedule": "ShopifyPaymentsPayoutSchedule",
    "payoutStatementDescriptor": "String",
    "payouts": "ShopifyPaymentsPayoutConnection",
    "permittedVerificationDocuments": "ShopifyPaymentsVerificationDocument",
    "verifications": "ShopifyPaymentsVerification"
  },
  "implementsNode": true
};
export default ShopifyPaymentsAccount;