
const App = {
  "name": "App",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "apiKey": "String",
    "appStoreAppUrl": "URL",
    "appStoreDeveloperUrl": "URL",
    "availableAccessScopes": "AccessScope",
    "banner": "Image",
    "description": "String",
    "developerName": "String",
    "developerType": "AppDeveloperType",
    "developerUrl": "URL",
    "embedded": "Boolean",
    "failedRequirements": "FailedRequirement",
    "features": "String",
    "feedback": "AppFeedback",
    "handle": "String",
    "icon": "Image",
    "id": "ID",
    "installUrl": "URL",
    "installation": "AppInstallation",
    "isPostPurchaseAppInUse": "Boolean",
    "launchUrl": "URL",
    "navigationItems": "NavigationItem",
    "previouslyInstalled": "Boolean",
    "pricingDetails": "String",
    "pricingDetailsSummary": "String",
    "privacyPolicyUrl": "URL",
    "publicCategory": "AppPublicCategory",
    "published": "Boolean",
    "requestedAccessScopes": "AccessScope",
    "screenshots": "Image",
    "shopifyDeveloped": "Boolean",
    "title": "String",
    "uninstallMessage": "String",
    "uninstallUrl": "URL",
    "webhookApiVersion": "String"
  },
  "implementsNode": true
};
export default App;