
const Country = {
  "name": "Country",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "availableLanguages": "Language",
    "currency": "Currency",
    "isoCode": "CountryCode",
    "market": "Market",
    "name": "String",
    "unitSystem": "UnitSystem"
  },
  "implementsNode": false
};
export default Country;