
const FulfillmentConstraintRuleDeletePayload = {
  "name": "FulfillmentConstraintRuleDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "success": "Boolean",
    "userErrors": "FulfillmentConstraintRuleDeleteUserError"
  },
  "implementsNode": false
};
export default FulfillmentConstraintRuleDeletePayload;