
const UrlRedirectImportSubmitPayload = {
  "name": "UrlRedirectImportSubmitPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "job": "Job",
    "userErrors": "UrlRedirectImportUserError"
  },
  "implementsNode": false
};
export default UrlRedirectImportSubmitPayload;