
const PubSubWebhookSubscriptionCreatePayload = {
  "name": "PubSubWebhookSubscriptionCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "userErrors": "PubSubWebhookSubscriptionCreateUserError",
    "webhookSubscription": "WebhookSubscription"
  },
  "implementsNode": false
};
export default PubSubWebhookSubscriptionCreatePayload;