
const RefundConnection = {
  "name": "RefundConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "RefundEdge",
    "nodes": "Refund",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default RefundConnection;