
const MarketRegionDeletePayload = {
  "name": "MarketRegionDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedId": "ID",
    "market": "Market",
    "userErrors": "MarketUserError"
  },
  "implementsNode": false
};
export default MarketRegionDeletePayload;