export const titleFonts = [
    {
        name: "Roboto Bold",
        googleFont: `<link href="https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap" rel="stylesheet">`,
        code: "font-family: 'Roboto', sans-serif !important;"
    },
    {
        name: "Merriweather Bold",
        googleFont: `<link href="https://fonts.googleapis.com/css2?family=Merriweather:wght@700&display=swap" rel="stylesheet">`,
        code: "font-family: 'Merriweather', serif !important;"
    },
    {
        name: "Ubuntu Bold",
        googleFont: `<link href="https://fonts.googleapis.com/css2?family=Ubuntu:wght@700&display=swap" rel="stylesheet">`,
        code: "font-family: 'Ubuntu', sans-serif !important;"
    },
    {
        name: "Playfair Display Bold",
        googleFont: `<link href="https://fonts.googleapis.com/css2?family=layfair+Display:wght@700&display=swap" rel="stylesheet">`,
        code: "font-family: 'Playfair Display', serif !important;"
    },
    {
        name: "Work Sans Bold",
        googleFont: `<link href="https://fonts.googleapis.com/css2?family=Work+Sans:wght@700&display=swap" rel="stylesheet">`,
        code: "font-family: 'Work Sans', sans-serif !important;"
    },
    {
        name: "Bitter Bold",
        googleFont: `<link href="https://fonts.googleapis.com/css2?family=Bitter:wght@700&display=swap" rel="stylesheet">`,
        code: "font-family: 'Bitter', serif !important;"
    },
    {
        name: "Arvo Bold",
        googleFont: `<link href="https://fonts.googleapis.com/css2?family=Arvo:wght@700&display=swap" rel="stylesheet">`,
        code: "font-family: 'Arvo', serif !important;"
    },
    {
        name: "Montserrat Bold",
        googleFont: `<link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap" rel="stylesheet">`,
        code: "font-family: 'Montserrat', sans-serif !important;"
    },
    {
        name: "Source Sans Pro Bold",
        googleFont: `<link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@700&display=swap" rel="stylesheet">`,
        code: "font-family: 'Source Sans Pro', sans-serif !important;"
    },
    {
        name: "Raleway Bold",
        googleFont: `<link href="https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap" rel="stylesheet">`,
        code: "font-family: 'Raleway', sans-serif !important;"
    },
    {
        name: "Nunito Bold",
        googleFont: `<link href="https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap" rel="stylesheet">`,
        code: "font-family: 'Nunito', sans-serif !important;"
    },
    {
        name: "Oleo Script Bold",
        googleFont: `<link href="https://fonts.googleapis.com/css2?family=Oleo+Script:wght@700&display=swap" rel="stylesheet">`,
        code: "font-family: 'Oleo Script', cursive !important;"
    },
    {
        name: "Overlock Bold",
        googleFont: `<link href="https://fonts.googleapis.com/css2?family=Overlock:wght@700&display=swap" rel="stylesheet">`,
        code: "font-family: 'Overlock', cursive !important;"
    },
    {
        name: "Lato",
        googleFont: `<link href="https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap" rel="stylesheet">`,
        code: "font-family: 'Lato', sans-serif !important;"
    },
    {
        name: "Open Sans",
        googleFont: `<link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap" rel="stylesheet">`,
        code: "font-family: 'Open Sans', sans-serif !important;"
    }
]

export const bodyFonts = [
    {
        name: "Roboto",
        googleFont: `<link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet">`,
        code: "font-family: 'Roboto', sans-serif !important;"
    }, 
    {
        name: "Ubuntu",
        googleFont: `<link href="https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap" rel="stylesheet">`,
        code: "font-family: 'Ubuntu', sans-serif !important;"
    }, 
    {
        name: "Work Sans",
        googleFont: `<link href="https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;700&display=swap" rel="stylesheet">`,
        code: "font-family: 'Work Sans', sans-serif !important;"
    }, 
    {
        name: "Montserrat",
        googleFont: `<link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">`,
        code: "font-family: 'Montserrat', sans-serif !important;"
    }, 
    {
        name: "Source Sans Pro",
        googleFont: `<link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap" rel="stylesheet">`,
        code: "font-family: 'Source Sans Pro', sans-serif !important;"
    }, 
    {
        name: "Raleway",
        googleFont: `<link href="https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap" rel="stylesheet">`,
        code: "font-family: 'Raleway', sans-serif !important;"
    }, 
    {
        name: "Nunito",
        googleFont: `<link href="https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap" rel="stylesheet">`,
        code: "font-family: 'Nunito', sans-serif !important;"
    }, 
    {
        name: "Lato",
        googleFont: `<link href="https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap" rel="stylesheet">`,
        code: "font-family: 'Lato', sans-serif !important;"
    }, 
    {
        name: "Open Sans",
        googleFont: `<link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap" rel="stylesheet">`,
        code: "font-family: 'Open Sans', sans-serif !important;"
    }

]