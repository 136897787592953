
const PriceRuleMoneyRange = {
  "name": "PriceRuleMoneyRange",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "greaterThan": "Money",
    "greaterThanOrEqualTo": "Money",
    "lessThan": "Money",
    "lessThanOrEqualTo": "Money"
  },
  "implementsNode": false
};
export default PriceRuleMoneyRange;