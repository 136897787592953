
const FulfillmentOrderDestination = {
  "name": "FulfillmentOrderDestination",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "address1": "String",
    "address2": "String",
    "city": "String",
    "company": "String",
    "countryCode": "CountryCode",
    "email": "String",
    "firstName": "String",
    "id": "ID",
    "lastName": "String",
    "phone": "String",
    "province": "String",
    "zip": "String"
  },
  "implementsNode": true
};
export default FulfillmentOrderDestination;