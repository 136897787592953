
const OrderEditRemoveDiscountPayload = {
  "name": "OrderEditRemoveDiscountPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "calculatedOrder": "CalculatedOrder",
    "userErrors": "OrderEditRemoveDiscountUserError"
  },
  "implementsNode": false
};
export default OrderEditRemoveDiscountPayload;