
const LocationAddPayload = {
  "name": "LocationAddPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "location": "Location",
    "userErrors": "LocationAddUserError"
  },
  "implementsNode": false
};
export default LocationAddPayload;