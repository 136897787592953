
const LocalizationExtension = {
  "name": "LocalizationExtension",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "countryCode": "CountryCode",
    "key": "LocalizationExtensionKey",
    "purpose": "LocalizationExtensionPurpose",
    "title": "String",
    "value": "String"
  },
  "implementsNode": false
};
export default LocalizationExtension;