
const ChannelInformation = {
  "name": "ChannelInformation",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "app": "App",
    "channelDefinition": "ChannelDefinition",
    "channelId": "ID",
    "id": "ID"
  },
  "implementsNode": true
};
export default ChannelInformation;