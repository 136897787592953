
const ReverseDeliveryLineItemConnection = {
  "name": "ReverseDeliveryLineItemConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "ReverseDeliveryLineItemEdge",
    "nodes": "ReverseDeliveryLineItem",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default ReverseDeliveryLineItemConnection;