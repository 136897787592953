
const DeliveryCustomizationActivationPayload = {
  "name": "DeliveryCustomizationActivationPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "ids": "String",
    "userErrors": "DeliveryCustomizationError"
  },
  "implementsNode": false
};
export default DeliveryCustomizationActivationPayload;