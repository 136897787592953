
const DeliveryLocationGroupZoneInput = {
  "name": "DeliveryLocationGroupZoneInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "id": "ID",
    "name": "String",
    "countries": "DeliveryCountryInput",
    "methodDefinitionsToCreate": "DeliveryMethodDefinitionInput",
    "methodDefinitionsToUpdate": "DeliveryMethodDefinitionInput"
  }
};
export default DeliveryLocationGroupZoneInput;