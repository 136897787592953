
const FulfillmentTrackingInput = {
  "name": "FulfillmentTrackingInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "number": "String",
    "url": "URL",
    "company": "String",
    "numbers": "String",
    "urls": "URL"
  }
};
export default FulfillmentTrackingInput;