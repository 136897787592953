
const ShopifyPaymentsPayoutSchedule = {
  "name": "ShopifyPaymentsPayoutSchedule",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "interval": "ShopifyPaymentsPayoutInterval",
    "monthlyAnchor": "Int",
    "weeklyAnchor": "DayOfTheWeek"
  },
  "implementsNode": false
};
export default ShopifyPaymentsPayoutSchedule;