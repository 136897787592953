
const CustomerVisitProductInfoEdge = {
  "name": "CustomerVisitProductInfoEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "CustomerVisitProductInfo"
  },
  "implementsNode": false
};
export default CustomerVisitProductInfoEdge;