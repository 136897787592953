
const ShopifyPaymentsDisputeFileUpload = {
  "name": "ShopifyPaymentsDisputeFileUpload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "disputeEvidenceType": "ShopifyPaymentsDisputeEvidenceFileType",
    "fileSize": "Int",
    "fileType": "String",
    "id": "ID",
    "originalFileName": "String",
    "url": "URL"
  },
  "implementsNode": true
};
export default ShopifyPaymentsDisputeFileUpload;