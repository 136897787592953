
const CollectionAddProductsV2Payload = {
  "name": "CollectionAddProductsV2Payload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "job": "Job",
    "userErrors": "CollectionAddProductsV2UserError"
  },
  "implementsNode": false
};
export default CollectionAddProductsV2Payload;