import React, { Component } from 'react';
import { ReactComponent as AddIcon } from "../../../assets/elegant_font/images/SVG/icon_plus_alt2.svg";
import { ReactComponent as CloseIcon } from "../../../assets/elegant_font/images/SVG/icon_minus_alt2.svg"
import Loader from '../../loader/loader';



export class ProductVariantForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            variant: {},
            options: [],
            variantSelected: false
        };
    }

    componentDidMount = () => {
        if (this.props.variant) {
            this.setState({
                variant: this.props.variant
            })
        }

        if (this.props.options) {
            this.setState({
                options: this.props.options
            })
        }

    }

    openRetailForm = () => {
        this.setState({ open: true });
    }

    closeRetailForm = () => {
        this.setState({ open: false });
    }

    render() {
        const { open, variant, options } = this.state;
        const { onVariantSkuChange, onVariantSelectionChange, selectedVariants, product, priceGrid, productMarkupValue } = this.props

        if (!priceGrid || Object.keys(variant).length === 0) return <Loader />

        const skuTag = product.tags.find(t => t.includes("r-sku="))
        let skuTagValue = ""
        if (skuTag) {
            const skuTagSplitValue = skuTag.split("=")[1]
            skuTagValue = skuTagSplitValue
        }

        const splitterBarcode = variant.sku.split("-");
        const barcode = splitterBarcode[splitterBarcode.length - 1];

        return (
            <div className="product-variant">
                <div className="d-flex justify-content-between variant-form">
                    <div className="form-group">
                        <input
                            className="checkbox_animated"
                            type="checkbox"
                            name="customizable"
                            onChange={(ev) => onVariantSelectionChange(ev.target.checked, variant)}
                            checked={variant.inventoryPolicy === "CONTINUE"} />
                    </div>
                    {
                        options.map((option, oIndex) => {
                            const selectedOption = variant.selectedOptions.find(v => v.name === option.name)
                            return (<div className="form-group" key={`${selectedOption}-${oIndex}-variant-options-`}>
                                <label>{option.name}</label>
                                <input disabled className="form-control" type="text" placeholder={option.name} required defaultValue={selectedOption.value} />
                            </div>)
                        })

                    }
                    <div className="form-group">
                        <label>Stouse Item #</label>
                        <input disabled className="form-control" type="text" placeholder="Stouse Item #" required defaultValue={variant.sku} />
                    </div>
                    <div className="form-group">
                        <label>Your SKU</label>
                        <input className="form-control" type="text" placeholder="Your SKU" required value={skuTagValue} onChange={(ev) => onVariantSkuChange(variant, ev.target.value)} />
                    </div>
                    <div className="form-group">
                        {open && <CloseIcon onClick={this.closeRetailForm} />}
                        {!open && <AddIcon onClick={this.openRetailForm} />}
                    </div>
                </div>
                {open && (<div className="retail-form d-flex justify-content-between retail-form">
                    <div className="retail-form-title">
                        <label> Retail: </label>
                        <label> Net: </label>
                    </div>
                    {priceGrid.quantity_breaks.map((qb, index) => {
                        let retailPrice = parseFloat((1 + (productMarkupValue / 100)) * priceGrid.msrp_markup_multiplier[index] * priceGrid[`net_${barcode}`][index]).toFixed(3);
                        retailPrice = this.props.isLotPricing && priceGrid[`quantity_breaks`][index] >= 1000 ? retailPrice * 2 : retailPrice;
                        let netPrice = parseFloat(priceGrid[`net_${barcode}`][index]).toFixed(3);
                        netPrice = this.props.isLotPricing && priceGrid[`quantity_breaks`][index] >= 1000 ? (netPrice * 2).toFixed(3) : netPrice;
                        return <div className="form-group" key={`quantity_breaks_${index}`}>
                            <label>{qb}</label>
                            <input className="form-control" type="text" placeholder={`${retailPrice}`} value={`${retailPrice}`} disabled />
                            <input className="form-control" type="text" placeholder={`${netPrice}`} disabled />
                        </div>
                    })}
                </div>)}
            </div>
        )
    }
}

export default ProductVariantForm