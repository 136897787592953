
const DiscountCodeDeletePayload = {
  "name": "DiscountCodeDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedCodeDiscountId": "ID",
    "userErrors": "DiscountUserError"
  },
  "implementsNode": false
};
export default DiscountCodeDeletePayload;