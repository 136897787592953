
const CustomerPaymentMethodGetUpdateUrlPayload = {
  "name": "CustomerPaymentMethodGetUpdateUrlPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "updatePaymentMethodUrl": "URL",
    "userErrors": "CustomerPaymentMethodGetUpdateUrlUserError"
  },
  "implementsNode": false
};
export default CustomerPaymentMethodGetUpdateUrlPayload;