
const CheckoutBrandingColorSchemeInput = {
  "name": "CheckoutBrandingColorSchemeInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "base": "CheckoutBrandingColorRolesInput",
    "control": "CheckoutBrandingControlColorRolesInput",
    "primaryButton": "CheckoutBrandingButtonColorRolesInput",
    "secondaryButton": "CheckoutBrandingButtonColorRolesInput"
  }
};
export default CheckoutBrandingColorSchemeInput;