
const MarketingActivityExtensionAppErrors = {
  "name": "MarketingActivityExtensionAppErrors",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "MarketingActivityExtensionAppErrorCode",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default MarketingActivityExtensionAppErrors;