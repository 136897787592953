
const ReturnableFulfillmentEdge = {
  "name": "ReturnableFulfillmentEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "ReturnableFulfillment"
  },
  "implementsNode": false
};
export default ReturnableFulfillmentEdge;