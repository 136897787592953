
const ShopifyPaymentsVerificationSubject = {
  "name": "ShopifyPaymentsVerificationSubject",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "familyName": "String",
    "givenName": "String"
  },
  "implementsNode": false
};
export default ShopifyPaymentsVerificationSubject;