
const ResourceOperation = {
  "name": "ResourceOperation",
  "kind": "INTERFACE",
  "fieldBaseTypes": {
    "id": "ID",
    "processedRowCount": "Int",
    "rowCount": "RowCount",
    "status": "ResourceOperationStatus"
  },
  "possibleTypes": ["AddAllProductsOperation", "CatalogCsvOperation", "PublicationResourceOperation"]
};
export default ResourceOperation;