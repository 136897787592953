
const CheckoutBrandingGlobal = {
  "name": "CheckoutBrandingGlobal",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cornerRadius": "CheckoutBrandingGlobalCornerRadius",
    "typography": "CheckoutBrandingTypographyStyleGlobal"
  },
  "implementsNode": false
};
export default CheckoutBrandingGlobal;