
const EmailInput = {
  "name": "EmailInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "subject": "String",
    "to": "String",
    "from": "String",
    "body": "String",
    "bcc": "String",
    "customMessage": "String"
  }
};
export default EmailInput;