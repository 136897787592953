
const CompanyLocationInput = {
  "name": "CompanyLocationInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "name": "String",
    "phone": "String",
    "locale": "String",
    "externalId": "String",
    "note": "String",
    "buyerExperienceConfiguration": "BuyerExperienceConfigurationInput",
    "billingAddress": "CompanyAddressInput",
    "shippingAddress": "CompanyAddressInput",
    "billingSameAsShipping": "Boolean",
    "taxRegistrationId": "String",
    "taxExemptions": "TaxExemption"
  }
};
export default CompanyLocationInput;