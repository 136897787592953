
const CashTrackingSession = {
  "name": "CashTrackingSession",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "adjustments": "CashTrackingAdjustmentConnection",
    "cashTrackingEnabled": "Boolean",
    "closingBalance": "MoneyV2",
    "closingNote": "String",
    "closingStaffMember": "StaffMember",
    "closingTime": "DateTime",
    "expectedBalance": "MoneyV2",
    "expectedClosingBalance": "MoneyV2",
    "expectedOpeningBalance": "MoneyV2",
    "id": "ID",
    "location": "Location",
    "netCashSales": "MoneyV2",
    "openingBalance": "MoneyV2",
    "openingNote": "String",
    "openingStaffMember": "StaffMember",
    "openingTime": "DateTime",
    "registerName": "String",
    "totalAdjustments": "MoneyV2",
    "totalCashRefunds": "MoneyV2",
    "totalCashSales": "MoneyV2",
    "totalDiscrepancy": "MoneyV2"
  },
  "implementsNode": true
};
export default CashTrackingSession;