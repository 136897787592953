
const SubscriptionManualDiscountInput = {
  "name": "SubscriptionManualDiscountInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "title": "String",
    "value": "SubscriptionManualDiscountValueInput",
    "recurringCycleLimit": "Int",
    "entitledLines": "SubscriptionManualDiscountEntitledLinesInput"
  }
};
export default SubscriptionManualDiscountInput;