
const FulfillmentOrderCancelPayload = {
  "name": "FulfillmentOrderCancelPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "fulfillmentOrder": "FulfillmentOrder",
    "replacementFulfillmentOrder": "FulfillmentOrder",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default FulfillmentOrderCancelPayload;