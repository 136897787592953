
const DeliveryParticipant = {
  "name": "DeliveryParticipant",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "adaptToNewServicesFlag": "Boolean",
    "carrierService": "DeliveryCarrierService",
    "fixedFee": "MoneyV2",
    "id": "ID",
    "participantServices": "DeliveryParticipantService",
    "percentageOfRateFee": "Float"
  },
  "implementsNode": true
};
export default DeliveryParticipant;