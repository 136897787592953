
const ProductVariantContextualPricing = {
  "name": "ProductVariantContextualPricing",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "compareAtPrice": "MoneyV2",
    "price": "MoneyV2",
    "quantityPriceBreaks": "QuantityPriceBreakConnection",
    "quantityRule": "QuantityRule"
  },
  "implementsNode": false
};
export default ProductVariantContextualPricing;