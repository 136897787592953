
const ProductImageUpdatePayload = {
  "name": "ProductImageUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "image": "Image",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default ProductImageUpdatePayload;