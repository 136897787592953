
const PrivateMetafieldConnection = {
  "name": "PrivateMetafieldConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "PrivateMetafieldEdge",
    "nodes": "PrivateMetafield",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default PrivateMetafieldConnection;