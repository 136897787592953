
const SellingPlanAnchorInput = {
  "name": "SellingPlanAnchorInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "type": "SellingPlanAnchorType",
    "day": "Int",
    "month": "Int",
    "cutoffDay": "Int"
  }
};
export default SellingPlanAnchorInput;