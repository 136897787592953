
const DiscountAutomaticBxgyUpdatePayload = {
  "name": "DiscountAutomaticBxgyUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "automaticDiscountNode": "DiscountAutomaticNode",
    "userErrors": "DiscountUserError"
  },
  "implementsNode": false
};
export default DiscountAutomaticBxgyUpdatePayload;