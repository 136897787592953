
const MetaobjectCapabilitiesRenderable = {
  "name": "MetaobjectCapabilitiesRenderable",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "data": "MetaobjectCapabilityDefinitionDataRenderable",
    "enabled": "Boolean"
  },
  "implementsNode": false
};
export default MetaobjectCapabilitiesRenderable;