
const ProductVariantsBulkDeletePayload = {
  "name": "ProductVariantsBulkDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "product": "Product",
    "userErrors": "ProductVariantsBulkDeleteUserError"
  },
  "implementsNode": false
};
export default ProductVariantsBulkDeletePayload;