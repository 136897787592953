
const Weight = {
  "name": "Weight",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "unit": "WeightUnit",
    "value": "Float"
  },
  "implementsNode": false
};
export default Weight;