
const CompanyContactRoleAssignment = {
  "name": "CompanyContactRoleAssignment",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "company": "Company",
    "companyContact": "CompanyContact",
    "companyLocation": "CompanyLocation",
    "createdAt": "DateTime",
    "id": "ID",
    "role": "CompanyContactRole",
    "updatedAt": "DateTime"
  },
  "implementsNode": true
};
export default CompanyContactRoleAssignment;