import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import Loader from '../loader/loader';

class NewProductsList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 0,
            isSelectAllChecked: false
        }
    }
    selectUnselectAll = (checked) => {
        this.setState({
            isSelectAllChecked: checked
        })
        this.props.activeProducts.forEach(product => {
            this.props.onProductCheck(product, checked);
        });
    }

    render() {
        let { modalOpen, onCloseModal, newProductsLoading, collectionProducts, onSaveModal, onProductCheck, activeProducts, checkedNewProducts } = this.props

        activeProducts = activeProducts.filter(p => collectionProducts.findIndex(cp => cp.node.id === p.node.id || btoa(cp.node.id) === p.node.id || cp.node.id === btoa(p.node.id)) === -1);

        const { currentPage } = this.state

        const from = currentPage * 10
        const to = from + 10

        let rows = []
        let visibleProducts = 0

        if (newProductsLoading) {
            rows.push(<tr key={`loader-${currentPage}`}><td colSpan="2"><Loader /></td></tr>);
        } else {
            for (let index = 0; index < activeProducts.length; index++) {
                const product = activeProducts[index]
                if (product) {
                    visibleProducts++;
                    const alreadyAdded = collectionProducts.findIndex(p => p.node.id === product.node.id) > -1;
                    const alreadyChecked = checkedNewProducts.findIndex(p => p.node.id === product.node.id) > -1;
                    rows.push(
                        <tr key={index} >
                            <td width="10%">
                                < input className="checkbox_animated" id="chk-ani-1" type="checkbox" disabled={alreadyAdded}
                                    onClick={(ev) => onProductCheck(product, ev.target.checked)} defaultChecked={alreadyAdded || alreadyChecked} checked={alreadyAdded || alreadyChecked}
                                />
                            </td>
                            <td className="text-primary d-flex align-items-center">
                                <img src={product.node.images && product.node.images.edges[0] ? product.node.images.edges[0].node.src : "https://via.placeholder.com/32"} style={{ width: '32px', height: '32px' }} alt="Placeholder" />
                                <span className="pl-3"> <b> {product.node.title} </b> </span>
                            </td>
                        </tr>
                    )
                }
            }
        }

        //Pagination
        const pageCount = Math.ceil(activeProducts.length / 10)
        let pages = []
        for (let p = 0; p < pageCount; p++) {
            pages.push(<span className="text-primary mx-2" key={`new-products-pagination-${p}`}>
                <a href="javascript:void(0)" onClick={() => this.setState({ currentPage: p })}>
                    {currentPage === p ? <b>{p + 1}</b> : p + 1}
                </a>
            </span>)
        }

        return (
            <Modal
                open={modalOpen}
                showCloseIcon={true}
                onClose={onCloseModal}
                styles={{
                    modal: {
                        borderRadius: "10px",
                        'overflow': 'hidden'
                    }
                }}
                center
            >
                <div className="container modal-lg modal-alert" >
                    <div className="py-5 px-3">
                        <div className="mt-1">
                            <h5>Add products</h5>
                            <label className="mb-2"> <b> All Products</b> </label>
                            <table className="table table-md border rounded scrollable">
                                <thead>
                                    <tr>
                                        <th scope="col" width="5%"><input className="checkbox_animated" id="chk-ani-1" type="checkbox" checked={this.state.isSelectAllChecked}
                                            onChange={(ev) => this.selectUnselectAll(ev.target.checked)}
                                        /></th>
                                        <th scope="col" width="95%">Products</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rows}
                                </tbody>
                            </table>
                            <div className="mt-4">
                                <button type="button"
                                    className="btn btn-outline-primary py-2 px-4 mr-3"
                                    onClick={onCloseModal}
                                >
                                    Cancel
                                </button>
                                <button type="button"
                                    className="btn btn-primary py-2 px-4"
                                    style={{ backgroundColor: "rgb(255,0,0) !important" }}
                                    onClick={onSaveModal}>
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >
        )
    }

}
export default NewProductsList;
