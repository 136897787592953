
const DraftOrderInvoiceSendPayload = {
  "name": "DraftOrderInvoiceSendPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "draftOrder": "DraftOrder",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default DraftOrderInvoiceSendPayload;