
const CustomerConnection = {
  "name": "CustomerConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "CustomerEdge",
    "nodes": "Customer",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default CustomerConnection;