
const PaymentSchedule = {
  "name": "PaymentSchedule",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "amount": "MoneyV2",
    "completedAt": "DateTime",
    "dueAt": "DateTime",
    "id": "ID",
    "issuedAt": "DateTime",
    "paymentTerms": "PaymentTerms"
  },
  "implementsNode": true
};
export default PaymentSchedule;