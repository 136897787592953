
const SellingPlanGroupAddProductsPayload = {
  "name": "SellingPlanGroupAddProductsPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "sellingPlanGroup": "SellingPlanGroup",
    "userErrors": "SellingPlanGroupUserError"
  },
  "implementsNode": false
};
export default SellingPlanGroupAddProductsPayload;