
const ExchangeV2Connection = {
  "name": "ExchangeV2Connection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "ExchangeV2Edge",
    "nodes": "ExchangeV2",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default ExchangeV2Connection;