
const SubscriptionContractPausePayload = {
  "name": "SubscriptionContractPausePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "contract": "SubscriptionContract",
    "userErrors": "SubscriptionContractStatusUpdateUserError"
  },
  "implementsNode": false
};
export default SubscriptionContractPausePayload;