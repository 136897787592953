
const QuantityRulesAddPayload = {
  "name": "QuantityRulesAddPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "quantityRules": "QuantityRule",
    "userErrors": "QuantityRuleUserError"
  },
  "implementsNode": false
};
export default QuantityRulesAddPayload;