import React, { Component, Fragment } from 'react';
import { withRouter, Link } from "react-router-dom";
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { User, Unlock } from 'react-feather';
import classnames from "classnames";
import { storefrontClient } from "../../index";
import * as storefrontQuerys from "../../querys/storefront-graphql";

import { AuthContext } from "../../auth/auth"

import { addNetlifyHook, createFormHook } from "../../querys/netlify-rest";

import { setupDefaultHomepageLayoutSnippets, createSnippet } from "../utils/snippets";
import { toast } from 'react-toastify';
import { createCookie, deleteCookie } from '../navigation/utils';
export class LoginTabset extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeShow: true,
			startDate: new Date(),
			email: '',
			password: '',
			subdomain: '',
			isSuperUser: false,
			superUserSelectedEmail: '',
			save: false,
			error: false,
			messaging: '',
			forgotPass: false,
		}
		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.handleSuperUserEmailChange = this.handleSuperUserEmailChange.bind(this);
		this.handlePassChange = this.handlePassChange.bind(this);
		this.handleSubdomainChange = this.handleSubdomainChange.bind(this);
		this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
		this.handleRemember = this.handleRemember.bind(this);
	}

	clickActive = (event) => {
		document.querySelector(".nav-link").classList.remove('show');
		event.target.classList.add('show');
		this.setState({
			email: '',
			password: '',
			subdomain: '',
			save: false
		})
	}

	handleEmailChange = (e) => {
		this.setState({
			email: e.target.value
		});
	}

	handlePassChange = (e) => {
		this.setState({
			password: e.target.value
		});
	}

	handleSubdomainChange = (e) => {
		const value = e.target.value.replace(/ /g, '').toLowerCase();
		this.setState({
			subdomain: value
		});
	}

	handleRemember = () => {
		this.setState({
			save: this.state.save ? false : true
		})
	}

	handleMessageChange = (message, error = false) => {
		this.setState({
			messaging: message,
			error: error,
		})
	}

	handleForget = () => {
		this.setState({
			forgotPass: true
		})
	}

	handleSuperUserEmailChange = (e) => {
		this.setState({
			superUserSelectedEmail: e.target.value
		})
	}

	submitForget = () => {
		storefrontClient.send(storefrontQuerys.recoverPasswordMutation(this.state.email))
			.then(({ data }) => {
				if (data.customerRecover.customerUserErrors.length) {
					this.handleMessageChange('There was an error resetting your password. Please check your email and try again', true);
				} else {
					this.setState({
						forgotPass: false
					})
					this.handleMessageChange('Check your email for a link to recover your password');
				}
			})
			.catch(() => {
				this.handleMessageChange('There was an error resetting your password. Please check your email and try again', true);
			})
	}

	handleRegistration = async (subdomain, email) => {
		// eslint-disable-next-line no-useless-escape
		const re = /[^a-zA-Z0-9\-]/;
		if (re.test(subdomain)) {
			this.handleMessageChange('Valid subdomains can only contain letters, numbers, or the character -', true);
			return null;
		} else {
			this.handleMessageChange(`Attempting to register ${subdomain}.readysetprint.com...`)
			const response = await fetch('/.netlify/functions/server/api/create-subdomain', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					subdomain: subdomain,
					email: email
				})
			})
			if (response.status === 422) {
				return false;
			} else {
				const body = await response.text();
				const updateResponse = await fetch('/.netlify/functions/server/api/update-netlify-build-settings', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						siteId: body
					})
				})
				if (updateResponse.status === 200) {
					return body;
				} else {
					return false;
				}
			}
		}
	}


	createDeployFailedNotification = async (site_id, email) => {
		const newHook = {
			site_id,
			type: "email",
			event: "deploy_failed",
			data: {
				email
			}
		};
		addNetlifyHook(newHook);
	}

	handleCollectionCreate = async (siteId, subdomain) => {

		const { publications } = this.context

		const response = await fetch('/.netlify/functions/server/api/create-base-collection', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				siteId: siteId,
				subdomain: subdomain,
				publications: publications
			})
		});
		try {
			if (!response.ok) throw response;
			const body = await response.json();
			return body;
		}
		catch (err) {
			//Show response error
			if (err.message) {
				toast.error(`${err.message}`);
			} else {
				toast.error(`${err.status}: ${err.statusText}`);
			}
		}
	}

	handleTagAdd = async (siteId, subdomain, collectionId, collectionHandle) => {
		this.handleMessageChange('Updating your user profile...');
		const rawId = localStorage.getItem('whitelabelAdminUserId') || sessionStorage.getItem('whitelabelAdminUserId');
		const userId = rawId.replace('gid://shopify/Customer/', '');
		const response = await fetch('/.netlify/functions/server/api/add-admin-tags', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				id: userId,
				subdomain: subdomain,
				siteId: siteId,
				collectionId: collectionId,
				collectionHandle: collectionHandle
			}),
		});
		try {
			if (!response.ok) throw response;
			const body = await response.text();
			return body;
		}
		catch (err) {
			//Show response error
			if (err.message) {
				toast.error(`${err.message}`);
			} else {
				toast.error(`${err.status}: ${err.statusText}`);
			}

		}
	}

	fetchDataForSuperUser = async () => {
		const response = await fetch('/.netlify/functions/server/api/get-superuser-data', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				email: this.state.superUserSelectedEmail
			}),
		});
		try {

			if (!response.ok) throw response;

			const body = await response.json();
			return body;
		}
		catch (err) {
			//Show response error
			if (err.message) {
				toast.error(`${err.message}`);
			} else {
				toast.error(`${err.status}: ${err.statusText}`);
			}

		}
	}

	handleSuperUserLogin = async () => {
		this.setState({
			isSuperUser: true
		})
		this.handleMessageChange('Superuser - Please enter the email for the store you are editing');
	}

	handleLoginSubmit = (e) => {
		if (this.state.forgotPass) {
			e.preventDefault();
			this.submitForget();
		} else {
			this.handleMessageChange('Authenticating...');
			let history = this.props.history;
			let loginFunction = this.props.loginUser;
			let userData = { 'email': this.state.email };
			userData['save'] = this.state.save;
			let subdomain = this.state.subdomain;
			e.preventDefault();
			storefrontClient.send(storefrontQuerys.getCustomerAccessTokenMutation(this.state.email, this.state.password))
				.then(({ data }) => {
					if (data.customerAccessTokenCreate.customerAccessToken) {
						userData['token'] = data.customerAccessTokenCreate.customerAccessToken.accessToken;
						storefrontClient.send(storefrontQuerys.getCustomerQuery(userData['token']))
							.then(async ({ data }) => {
								userData['id'] = data.customer.id;
								let isAdmin = data.customer.tags.some((tag) => {
									return tag.includes('admin');
								});
								let isReseller = data.customer.tags.some((tag) => {
									return tag.includes('reseller');
								});
								if (isAdmin || isReseller) {
									if (isAdmin) {
										data.customer.tags.forEach((tag) => {
											if (tag.includes('admin')) {
												userData['store_subdomain'] = tag.split('=')[1];
											}
											if (tag.includes('reseller_site_id')) {
												userData['reseller_site_id'] = tag.split('=')[1];
											}
											if (tag.includes('reseller_collection_id')) {
												userData['reseller_collection_id'] = tag.split('=')[1];
											}
											if (tag.includes('reseller_collection_handle')) {
												userData['reseller_collection_handle'] = tag.split('=')[1];
											}
											if (tag.includes('markup=')) {
												userData['global_markup'] = tag.split('=')[1];
											}
										});
										createFormHook(userData['reseller_site_id'], this.state.email);
									}

									if (subdomain && !isAdmin) {
										createCookie("siteSetup", true, 7);
										this.handleRegistration(subdomain, userData['email'])
										.then(async res => {
											if (res) {
													let stouseAccountNumber = null;
													if (isReseller) {
														stouseAccountNumber = data.customer.tags.find((tag) => tag.includes("StouseAccountNumber"));
														if (stouseAccountNumber) {
															stouseAccountNumber = stouseAccountNumber.split("=")[1];
															stouseAccountNumber = parseInt(stouseAccountNumber);
														}
													}
													const site_id = res;
													userData['store_subdomain'] = subdomain;
													userData['reseller_site_id'] = site_id;
													this.context.setSiteState("building");
													this.createDeployFailedNotification(site_id, "stouse@rangempls.com");
													this.handleCollectionCreate(site_id, subdomain)
														.then(async (res) => {
															const { id, handle } = res.collection;
															const cleanId = id.replace('gid://shopify/Collection/', '');
															const customerId = userData['id'].replace('gid://shopify/Customer/', '');
															userData['reseller_collection_id'] = cleanId;
															userData['reseller_collection_handle'] = handle;
															await loginFunction(userData);
															this.handleTagAdd(site_id, subdomain, cleanId, handle)
																.then(() => {
																	this.handleMessageChange(`${subdomain}.readysetprint.com has been successfully registered! Since this is your first login, you'll need to reset your password.`);
																	// Segment Tracking
																	window.analytics.track("Store Registered", {
																		category: "Store",
																		collectionId: cleanId,
																		email: userData['email'],
																		handle: handle,
																		label: subdomain,
																		siteId: site_id
																	});
																	window.analytics.identify(customerId, {
																		company: { name: userData['store_subdomain'] },
																		email: userData['email']
																	});
																  setTimeout(() => { history.push('/reset-password') }, 2000);
																})
																.catch(err => {
																	console.log(err);
																})
														})
														.catch(err => {
															console.log(err);
														})
													await setupDefaultHomepageLayoutSnippets(site_id);
													createFormHook(userData['reseller_site_id'], this.state.email);
													if (stouseAccountNumber) {
														await createSnippet(site_id, "StouseAccountNumber", stouseAccountNumber, "Stouse Account Number");
													}
													this.context.setSiteSetupStatus("BUILDING");
													fetch(`/.netlify/functions/site-setup-background`, {
														method: 'POST',
														headers: {
															'Content-Type': 'application/json',
														},
														body: JSON.stringify({ siteId: site_id, subdomain, resellerEmail: userData['email'] })
													})
												} else {
													if (res !== null) {
														deleteCookie("siteSetup");
														this.handleMessageChange('That domain has already been registered, please try another.', true);
													}
												}
											})
											.catch((err) => {
												deleteCookie("siteSetup");
												this.handleMessageChange('There was an error registering the subdomain. Please try again.', true);
											});
									} else {
										if (data.customer.tags.includes('admin=stouse-super-user')) {
											this.handleSuperUserLogin();
											document.getElementById('superuser-form').addEventListener('submit', (e) => {
												e.preventDefault();
												this.fetchDataForSuperUser()
													.then(async (res) => {
														let fetchedUser = res.customers[0];
														userData['save'] = false;
														userData['email'] = fetchedUser.email;
														userData['id'] = btoa(fetchedUser.id);
														fetchedUser.tags.split(', ').forEach((tag) => {
															if (tag.includes('admin')) {
																userData['store_subdomain'] = tag.split('=')[1];
															}
															if (tag.includes('reseller_site_id')) {
																userData['reseller_site_id'] = tag.split('=')[1];
															}
															if (tag.includes('reseller_collection_id')) {
																userData['reseller_collection_id'] = tag.split('=')[1];
															}
															if (tag.includes('reseller_collection_handle')) {
																userData['reseller_collection_handle'] = tag.split('=')[1];
															}
															if (tag.includes('markup=')) {
																userData['global_markup'] = tag.split('=')[1];
															}
														});
														await loginFunction(userData);
														this.handleMessageChange(`Sending you to the admin of ${userData['store_subdomain']}.readysetprint.com`);
														setTimeout(() => { history.push('/products') }, 2000);
													})
													.catch(err => {
														console.log(err);
														this.handleMessageChange('Email not found, please check the email and try again', true);
													})
											})
										} else if (subdomain) {
											await loginFunction(userData);
											this.handleMessageChange(`Looks like you already have a readysetprint domain, redirecting you to your admin...`);
											setTimeout(() => { history.push('/products') }, 2000);
										} else {
											if (isReseller && !isAdmin) {
												this.handleMessageChange(`It looks like you haven't registered your domain yet, please register before logging in`, true)
											} else {
												await loginFunction(userData);
												history.push('/products');
											}
										}
									}
								} else {
									this.handleMessageChange("You don't have a readysetprint domain, please contact an admin", true)
								}
							})
					} else {
						this.handleMessageChange("Unidentified User - Please make sure that your email address and password are correct and try again.", true);
					}
				})
		}
	}

	render() {
		const { forgotPass, isSuperUser } = this.state;
		return (
			<div>
				<Fragment>
					<Tabs>
						<TabList className="nav nav-tabs" >
							<Tab className="nav-link" onClick={(e) => this.clickActive(e)}><b><User size={16} /></b>Login</Tab>
							<Tab className="nav-link" onClick={(e) => this.clickActive(e)}><b><Unlock size={16} /></b>Register</Tab>
						</TabList>
						<div
							className={classnames({
								"status-banner": true,
								"status-active": this.state.messaging,
								"status-error": this.state.error,
							})}
						>
							{this.state.messaging}
						</div>
						<TabPanel>
							{!isSuperUser && (
								<form onSubmit={this.handleLoginSubmit} className="form-horizontal auth-form">

									<div className="form-group">
										<input
											id="email"
											required
											name="email"
											placeholder="Email"
											type="email"
											value={this.state.email}
											onChange={this.handleEmailChange}
											className={classnames({
												"form-control": true,
											})}
										/>
									</div>
									{!forgotPass && (
										<div className="form-group">
											<input
												id="password"
												name="password"
												placeholder="Password"
												type="password"
												value={this.state.password}
												onChange={this.handlePassChange}
												className="form-control"
												required
												autoComplete="on"
											/>
										</div>
									)}
									{!forgotPass && (
										<div className="form-terms">
											<div className="custom-control custom-checkbox">
												<label className="d-block">
													<input
														id="save"
														className="checkbox_animated"
														type="checkbox"
														value={this.state.save}
														onChange={this.handleRemember}
													/>
													Remember Me
													<span className="pull-right">
														<Link to="#" onClick={this.handleForget} className="btn btn-default forgot-pass">
															Forgot your password?
													</Link>
													</span>
												</label>
											</div>
										</div>
									)}
									<div className="form-button">
										<button className="btn btn-primary" type="submit">
											{forgotPass ? 'Submit' : 'Login'}
										</button>
									</div>
								</form>
							)}
							{isSuperUser && (
								<form id="superuser-form" className="form-horizontal auth-form">

									<div className="form-group">
										<input
											id="email"
											required
											name="email"
											placeholder="Email"
											type="email"
											value={this.state.superUserSelectedEmail}
											onChange={this.handleSuperUserEmailChange}
											className={classnames({
												"form-control": true,
											})}
										/>
									</div>
									<div className="form-button">
										<button className="btn btn-primary" type="submit">
											{'Submit'}
										</button>
									</div>
								</form>
							)}
						</TabPanel>
						<TabPanel>
							<form onSubmit={this.handleLoginSubmit} className="form-horizontal auth-form">
								<div className="form-group">
									<input
										required
										name="login[username]"
										type="email"
										className="form-control"
										placeholder="Email"
										id="exampleInputEmail12"
										onChange={this.handleEmailChange}
									/>
								</div>
								<div className="form-group">
									<input
										required
										name="login[password]"
										type="password"
										className="form-control"
										placeholder="Password"
										onChange={this.handlePassChange}
									/>
								</div>
								<div className="form-group subdomain-group">
									<label htmlFor="subdomain-field" className="subdomain-placeholder">.readysetprint.com</label>
									<input
										id="subdomain-field"
										required
										name="subdomain"
										type="text"
										className="form-control"
										placeholder="Subdomain"
										onChange={this.handleSubdomainChange}
									/>
								</div>
								<div className="form-terms">
									<div className="custom-control custom-checkbox mr-sm-2">
										<input type="checkbox" className="custom-control-input" id="customControlAutosizing" />
										<label className="d-block">
											<input
												className="checkbox_animated"
												id="chk-ani2"
												type="checkbox"
												onChange={this.handleRemember}
											/>
											Remember me
										</label>
									</div>
								</div>
								<div className="form-button">
									<button className="btn btn-primary" type="submit">Register</button>
								</div>
							</form>
						</TabPanel>
					</Tabs>
				</Fragment>
			</div>
		)
	}
}

LoginTabset.contextType = AuthContext;

export default withRouter(LoginTabset);
