
const SubscriptionDeliveryMethodInput = {
  "name": "SubscriptionDeliveryMethodInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "shipping": "SubscriptionDeliveryMethodShippingInput",
    "localDelivery": "SubscriptionDeliveryMethodLocalDeliveryInput",
    "pickup": "SubscriptionDeliveryMethodPickupInput"
  }
};
export default SubscriptionDeliveryMethodInput;