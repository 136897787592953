
const CheckoutBrandingColorGlobal = {
  "name": "CheckoutBrandingColorGlobal",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "accent": "String",
    "brand": "String",
    "critical": "String",
    "decorative": "String",
    "info": "String",
    "success": "String",
    "warning": "String"
  },
  "implementsNode": false
};
export default CheckoutBrandingColorGlobal;