
const ShopifyPaymentsPayout = {
  "name": "ShopifyPaymentsPayout",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "bankAccount": "ShopifyPaymentsBankAccount",
    "gross": "MoneyV2",
    "id": "ID",
    "issuedAt": "DateTime",
    "legacyResourceId": "UnsignedInt64",
    "net": "MoneyV2",
    "status": "ShopifyPaymentsPayoutStatus",
    "summary": "ShopifyPaymentsPayoutSummary",
    "transactionType": "ShopifyPaymentsPayoutTransactionType"
  },
  "implementsNode": true
};
export default ShopifyPaymentsPayout;