
const UrlRedirectConnection = {
  "name": "UrlRedirectConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "UrlRedirectEdge",
    "nodes": "UrlRedirect",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default UrlRedirectConnection;