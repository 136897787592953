
const CashTrackingSessionEdge = {
  "name": "CashTrackingSessionEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "CashTrackingSession"
  },
  "implementsNode": false
};
export default CashTrackingSessionEdge;