
const SellingPlanGroup = {
  "name": "SellingPlanGroup",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "appName": "String",
    "name": "String",
    "options": "SellingPlanGroupOption",
    "sellingPlans": "SellingPlanConnection"
  },
  "implementsNode": false
};
export default SellingPlanGroup;