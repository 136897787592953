
const MetafieldStorefrontVisibilityDeletePayload = {
  "name": "MetafieldStorefrontVisibilityDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedMetafieldStorefrontVisibilityId": "ID",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default MetafieldStorefrontVisibilityDeletePayload;