
const MetaobjectCapabilityDefinitionDataOnlineStore = {
  "name": "MetaobjectCapabilityDefinitionDataOnlineStore",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "canCreateRedirects": "Boolean",
    "urlHandle": "String"
  },
  "implementsNode": false
};
export default MetaobjectCapabilityDefinitionDataOnlineStore;