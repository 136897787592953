
const InventoryScheduledChangeItemInput = {
  "name": "InventoryScheduledChangeItemInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "inventoryItemId": "ID",
    "locationId": "ID",
    "ledgerDocumentUri": "URL",
    "scheduledChanges": "InventoryScheduledChangeInput"
  }
};
export default InventoryScheduledChangeItemInput;