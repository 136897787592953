
const CustomerPaymentMethodRemoteInput = {
  "name": "CustomerPaymentMethodRemoteInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "stripePaymentMethod": "RemoteStripePaymentMethodInput",
    "authorizeNetCustomerPaymentProfile": "RemoteAuthorizeNetCustomerPaymentProfileInput",
    "braintreePaymentMethod": "RemoteBraintreePaymentMethodInput"
  }
};
export default CustomerPaymentMethodRemoteInput;