
const FunctionsErrorHistory = {
  "name": "FunctionsErrorHistory",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "errorsFirstOccurredAt": "DateTime",
    "firstOccurredAt": "DateTime",
    "hasBeenSharedSinceLastError": "Boolean",
    "hasSharedRecentErrors": "Boolean"
  },
  "implementsNode": false
};
export default FunctionsErrorHistory;