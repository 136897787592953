
const SubscriptionDraftLineRemovePayload = {
  "name": "SubscriptionDraftLineRemovePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "discountsUpdated": "SubscriptionManualDiscount",
    "draft": "SubscriptionDraft",
    "lineRemoved": "SubscriptionLine",
    "userErrors": "SubscriptionDraftUserError"
  },
  "implementsNode": false
};
export default SubscriptionDraftLineRemovePayload;