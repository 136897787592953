
const AppEdge = {
  "name": "AppEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "App"
  },
  "implementsNode": false
};
export default AppEdge;