
const DeliveryProfileLocationGroupInput = {
  "name": "DeliveryProfileLocationGroupInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "id": "ID",
    "locations": "ID",
    "locationsToAdd": "ID",
    "locationsToRemove": "ID",
    "zonesToCreate": "DeliveryLocationGroupZoneInput",
    "zonesToUpdate": "DeliveryLocationGroupZoneInput"
  }
};
export default DeliveryProfileLocationGroupInput;