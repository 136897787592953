
const ProductFeed = {
  "name": "ProductFeed",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "country": "CountryCode",
    "id": "ID",
    "language": "LanguageCode",
    "status": "ProductFeedStatus"
  },
  "implementsNode": true
};
export default ProductFeed;