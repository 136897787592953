
const ReverseDeliveryLineItem = {
  "name": "ReverseDeliveryLineItem",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "dispositions": "ReverseFulfillmentOrderDisposition",
    "id": "ID",
    "quantity": "Int",
    "reverseFulfillmentOrderLineItem": "ReverseFulfillmentOrderLineItem"
  },
  "implementsNode": true
};
export default ReverseDeliveryLineItem;