
const PaymentTermsUpdatePayload = {
  "name": "PaymentTermsUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "paymentTerms": "PaymentTerms",
    "userErrors": "PaymentTermsUpdateUserError"
  },
  "implementsNode": false
};
export default PaymentTermsUpdatePayload;