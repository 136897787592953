
const ResourcePublication = {
  "name": "ResourcePublication",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "channel": "Channel",
    "isPublished": "Boolean",
    "publication": "Publication",
    "publishDate": "DateTime",
    "publishable": "Publishable"
  },
  "implementsNode": false
};
export default ResourcePublication;