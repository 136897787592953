
const SellingPlanAllocation = {
  "name": "SellingPlanAllocation",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkoutChargeAmount": "MoneyV2",
    "priceAdjustments": "SellingPlanAllocationPriceAdjustment",
    "remainingBalanceChargeAmount": "MoneyV2",
    "sellingPlan": "SellingPlan"
  },
  "implementsNode": false
};
export default SellingPlanAllocation;