
const LocationDeletePayload = {
  "name": "LocationDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedLocationId": "ID",
    "locationDeleteUserErrors": "LocationDeleteUserError"
  },
  "implementsNode": false
};
export default LocationDeletePayload;