
const DeliveryParticipantService = {
  "name": "DeliveryParticipantService",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "active": "Boolean",
    "name": "String"
  },
  "implementsNode": false
};
export default DeliveryParticipantService;