
const SegmentMigrationEdge = {
  "name": "SegmentMigrationEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "SegmentMigration"
  },
  "implementsNode": false
};
export default SegmentMigrationEdge;