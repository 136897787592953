
const FulfillmentOrderMerchantRequestConnection = {
  "name": "FulfillmentOrderMerchantRequestConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "FulfillmentOrderMerchantRequestEdge",
    "nodes": "FulfillmentOrderMerchantRequest",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default FulfillmentOrderMerchantRequestConnection;