
const FulfillmentLineItem = {
  "name": "FulfillmentLineItem",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "discountedTotal": "Money",
    "discountedTotalSet": "MoneyBag",
    "id": "ID",
    "lineItem": "LineItem",
    "originalTotal": "Money",
    "originalTotalSet": "MoneyBag",
    "quantity": "Int"
  },
  "implementsNode": true
};
export default FulfillmentLineItem;