
const MarketingActivityUpdatePayload = {
  "name": "MarketingActivityUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "marketingActivity": "MarketingActivity",
    "redirectPath": "String",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default MarketingActivityUpdatePayload;