
const SubscriptionManualDiscountConnection = {
  "name": "SubscriptionManualDiscountConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "SubscriptionManualDiscountEdge",
    "nodes": "SubscriptionManualDiscount",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default SubscriptionManualDiscountConnection;