
const OrderOpenPayload = {
  "name": "OrderOpenPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "order": "Order",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default OrderOpenPayload;