
const BulkOperationCancelPayload = {
  "name": "BulkOperationCancelPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "bulkOperation": "BulkOperation",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default BulkOperationCancelPayload;