
const ReturnInput = {
  "name": "ReturnInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "orderId": "ID",
    "returnLineItems": "ReturnLineItemInput",
    "notifyCustomer": "Boolean",
    "requestedAt": "DateTime"
  }
};
export default ReturnInput;