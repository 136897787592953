
const AppRevenueAttributionRecord = {
  "name": "AppRevenueAttributionRecord",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "amount": "MoneyV2",
    "capturedAt": "DateTime",
    "createdAt": "DateTime",
    "id": "ID",
    "idempotencyKey": "String",
    "test": "Boolean",
    "type": "AppRevenueAttributionType"
  },
  "implementsNode": true
};
export default AppRevenueAttributionRecord;