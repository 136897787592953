
const WebhookSubscription = {
  "name": "WebhookSubscription",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "apiVersion": "ApiVersion",
    "callbackUrl": "URL",
    "createdAt": "DateTime",
    "endpoint": "WebhookSubscriptionEndpoint",
    "format": "WebhookSubscriptionFormat",
    "id": "ID",
    "includeFields": "String",
    "legacyResourceId": "UnsignedInt64",
    "metafieldNamespaces": "String",
    "privateMetafieldNamespaces": "String",
    "subTopic": "String",
    "topic": "WebhookSubscriptionTopic",
    "updatedAt": "DateTime"
  },
  "implementsNode": true
};
export default WebhookSubscription;