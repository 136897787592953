
const FinancialSummaryDiscountApplication = {
  "name": "FinancialSummaryDiscountApplication",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "allocationMethod": "DiscountApplicationAllocationMethod",
    "targetSelection": "DiscountApplicationTargetSelection",
    "targetType": "DiscountApplicationTargetType"
  },
  "implementsNode": false
};
export default FinancialSummaryDiscountApplication;