
const PriceRuleCustomerSelection = {
  "name": "PriceRuleCustomerSelection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customers": "CustomerConnection",
    "forAllCustomers": "Boolean",
    "segments": "Segment"
  },
  "implementsNode": false
};
export default PriceRuleCustomerSelection;