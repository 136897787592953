
const CollectionConnection = {
  "name": "CollectionConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "CollectionEdge",
    "nodes": "Collection",
    "pageInfo": "PageInfo",
    "totalCount": "UnsignedInt64"
  },
  "implementsNode": false
};
export default CollectionConnection;