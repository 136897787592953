
const CompanyContactRoleConnection = {
  "name": "CompanyContactRoleConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "CompanyContactRoleEdge",
    "nodes": "CompanyContactRole",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default CompanyContactRoleConnection;