
const HasMetafields = {
  "name": "HasMetafields",
  "kind": "INTERFACE",
  "fieldBaseTypes": {
    "metafield": "Metafield",
    "metafields": "Metafield"
  },
  "possibleTypes": ["Article", "Blog", "Cart", "Collection", "Company", "CompanyLocation", "Customer", "Location", "Market", "Order", "Page", "Product", "ProductVariant", "Shop"]
};
export default HasMetafields;