
const DiscountAutomaticFreeShipping = {
  "name": "DiscountAutomaticFreeShipping",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "appliesOnOneTimePurchase": "Boolean",
    "appliesOnSubscription": "Boolean",
    "asyncUsageCount": "Int",
    "combinesWith": "DiscountCombinesWith",
    "createdAt": "DateTime",
    "destinationSelection": "DiscountShippingDestinationSelection",
    "discountClass": "ShippingDiscountClass",
    "endsAt": "DateTime",
    "hasTimelineComment": "Boolean",
    "maximumShippingPrice": "MoneyV2",
    "minimumRequirement": "DiscountMinimumRequirement",
    "recurringCycleLimit": "Int",
    "shortSummary": "String",
    "startsAt": "DateTime",
    "status": "DiscountStatus",
    "summary": "String",
    "title": "String",
    "totalSales": "MoneyV2",
    "updatedAt": "DateTime"
  },
  "implementsNode": false
};
export default DiscountAutomaticFreeShipping;