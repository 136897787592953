
const MetafieldDefinitionUpdatePayload = {
  "name": "MetafieldDefinitionUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "updatedDefinition": "MetafieldDefinition",
    "userErrors": "MetafieldDefinitionUpdateUserError",
    "validationJob": "Job"
  },
  "implementsNode": false
};
export default MetafieldDefinitionUpdatePayload;