
const CheckoutBrandingHeaderInput = {
  "name": "CheckoutBrandingHeaderInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "alignment": "CheckoutBrandingHeaderAlignment",
    "position": "CheckoutBrandingHeaderPosition",
    "logo": "CheckoutBrandingLogoInput",
    "banner": "CheckoutBrandingImageInput",
    "cartLink": "CheckoutBrandingHeaderCartLinkInput",
    "colorScheme": "CheckoutBrandingColorSchemeSelection",
    "padding": "CheckoutBrandingSpacingKeyword"
  }
};
export default CheckoutBrandingHeaderInput;