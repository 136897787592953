
const ProductVariantJoinSellingPlanGroupsPayload = {
  "name": "ProductVariantJoinSellingPlanGroupsPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "productVariant": "ProductVariant",
    "userErrors": "SellingPlanGroupUserError"
  },
  "implementsNode": false
};
export default ProductVariantJoinSellingPlanGroupsPayload;