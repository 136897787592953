
const SellingPlanPricingPolicyBase = {
  "name": "SellingPlanPricingPolicyBase",
  "kind": "INTERFACE",
  "fieldBaseTypes": {
    "adjustmentType": "SellingPlanPricingPolicyAdjustmentType",
    "adjustmentValue": "SellingPlanPricingPolicyAdjustmentValue"
  },
  "possibleTypes": ["SellingPlanFixedPricingPolicy", "SellingPlanRecurringPricingPolicy"]
};
export default SellingPlanPricingPolicyBase;