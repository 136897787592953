
const SegmentUpdatePayload = {
  "name": "SegmentUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "segment": "Segment",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default SegmentUpdatePayload;