
const InventorySetOnHandQuantitiesUserError = {
  "name": "InventorySetOnHandQuantitiesUserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "InventorySetOnHandQuantitiesUserErrorCode",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
export default InventorySetOnHandQuantitiesUserError;