
const Product = {
  "name": "Product",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "availablePublicationsCount": "Count",
    "bodyHtml": "String",
    "category": "TaxonomyCategory",
    "collections": "CollectionConnection",
    "compareAtPriceRange": "ProductCompareAtPriceRange",
    "contextualPricing": "ProductContextualPricing",
    "createdAt": "DateTime",
    "customProductType": "String",
    "defaultCursor": "String",
    "description": "String",
    "descriptionHtml": "HTML",
    "descriptionPlainSummary": "String",
    "featuredImage": "Image",
    "featuredMedia": "Media",
    "feedback": "ResourceFeedback",
    "giftCardTemplateSuffix": "String",
    "handle": "String",
    "hasOnlyDefaultVariant": "Boolean",
    "hasOutOfStockVariants": "Boolean",
    "hasVariantsThatRequiresComponents": "Boolean",
    "id": "ID",
    "images": "ImageConnection",
    "inCollection": "Boolean",
    "isGiftCard": "Boolean",
    "legacyResourceId": "UnsignedInt64",
    "media": "MediaConnection",
    "mediaCount": "Count",
    "metafield": "Metafield",
    "metafieldDefinitions": "MetafieldDefinitionConnection",
    "metafields": "MetafieldConnection",
    "onlineStorePreviewUrl": "URL",
    "onlineStoreUrl": "URL",
    "options": "ProductOption",
    "priceRange": "ProductPriceRange",
    "priceRangeV2": "ProductPriceRangeV2",
    "privateMetafield": "PrivateMetafield",
    "privateMetafields": "PrivateMetafieldConnection",
    "productCategory": "ProductCategory",
    "productPublications": "ProductPublicationConnection",
    "productType": "String",
    "publicationCount": "Int",
    "publications": "ProductPublicationConnection",
    "publishedAt": "DateTime",
    "publishedInContext": "Boolean",
    "publishedOnChannel": "Boolean",
    "publishedOnCurrentChannel": "Boolean",
    "publishedOnCurrentPublication": "Boolean",
    "publishedOnPublication": "Boolean",
    "requiresSellingPlan": "Boolean",
    "resourcePublicationOnCurrentPublication": "ResourcePublicationV2",
    "resourcePublications": "ResourcePublicationConnection",
    "resourcePublicationsCount": "Count",
    "resourcePublicationsV2": "ResourcePublicationV2Connection",
    "sellingPlanGroupCount": "Int",
    "sellingPlanGroups": "SellingPlanGroupConnection",
    "sellingPlanGroupsCount": "Count",
    "seo": "SEO",
    "standardizedProductType": "StandardizedProductType",
    "status": "ProductStatus",
    "storefrontId": "StorefrontID",
    "tags": "String",
    "templateSuffix": "String",
    "title": "String",
    "totalInventory": "Int",
    "totalVariants": "Int",
    "tracksInventory": "Boolean",
    "translations": "Translation",
    "unpublishedChannels": "ChannelConnection",
    "unpublishedPublications": "PublicationConnection",
    "updatedAt": "DateTime",
    "variants": "ProductVariantConnection",
    "variantsCount": "Count",
    "vendor": "String"
  },
  "implementsNode": true
};
export default Product;