
const DeliveryLocationGroup = {
  "name": "DeliveryLocationGroup",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "locations": "LocationConnection",
    "locationsCount": "Count"
  },
  "implementsNode": true
};
export default DeliveryLocationGroup;