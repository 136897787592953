
const FileCreateInput = {
  "name": "FileCreateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "filename": "String",
    "originalSource": "String",
    "contentType": "FileContentType",
    "alt": "String",
    "duplicateResolutionMode": "FileCreateInputDuplicateResolutionMode"
  }
};
export default FileCreateInput;