
const CompanyCreateInput = {
  "name": "CompanyCreateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "company": "CompanyInput",
    "companyContact": "CompanyContactInput",
    "companyLocation": "CompanyLocationInput"
  }
};
export default CompanyCreateInput;