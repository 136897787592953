
const TaxonomyValueConnection = {
  "name": "TaxonomyValueConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "TaxonomyValueEdge",
    "nodes": "TaxonomyValue",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default TaxonomyValueConnection;