
const CollectionRuleInput = {
  "name": "CollectionRuleInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "column": "CollectionRuleColumn",
    "relation": "CollectionRuleRelation",
    "condition": "String",
    "conditionObjectId": "ID"
  }
};
export default CollectionRuleInput;