
const DeliveryProfileConnection = {
  "name": "DeliveryProfileConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "DeliveryProfileEdge",
    "nodes": "DeliveryProfile",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default DeliveryProfileConnection;