
const PriceRuleValidityPeriod = {
  "name": "PriceRuleValidityPeriod",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "end": "DateTime",
    "start": "DateTime"
  },
  "implementsNode": false
};
export default PriceRuleValidityPeriod;