
const StageImageInput = {
  "name": "StageImageInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "resource": "StagedUploadTargetGenerateUploadResource",
    "filename": "String",
    "mimeType": "String",
    "httpMethod": "StagedUploadHttpMethodType"
  }
};
export default StageImageInput;