
const MarketingActivityUpsertExternalPayload = {
  "name": "MarketingActivityUpsertExternalPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "marketingActivity": "MarketingActivity",
    "userErrors": "MarketingActivityUserError"
  },
  "implementsNode": false
};
export default MarketingActivityUpsertExternalPayload;