
const FulfillmentOrderAssignedLocation = {
  "name": "FulfillmentOrderAssignedLocation",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "address1": "String",
    "address2": "String",
    "city": "String",
    "countryCode": "CountryCode",
    "location": "Location",
    "name": "String",
    "phone": "String",
    "province": "String",
    "zip": "String"
  },
  "implementsNode": false
};
export default FulfillmentOrderAssignedLocation;