
const SellingPlanGroupDeletePayload = {
  "name": "SellingPlanGroupDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedSellingPlanGroupId": "ID",
    "userErrors": "SellingPlanGroupUserError"
  },
  "implementsNode": false
};
export default SellingPlanGroupDeletePayload;