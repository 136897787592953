
const Sale = {
  "name": "Sale",
  "kind": "INTERFACE",
  "fieldBaseTypes": {
    "actionType": "SaleActionType",
    "id": "ID",
    "lineType": "SaleLineType",
    "quantity": "Int",
    "taxes": "SaleTax",
    "totalAmount": "MoneyBag",
    "totalDiscountAmountAfterTaxes": "MoneyBag",
    "totalDiscountAmountBeforeTaxes": "MoneyBag",
    "totalTaxAmount": "MoneyBag"
  },
  "possibleTypes": ["AdditionalFeeSale", "AdjustmentSale", "DutySale", "FeeSale", "GiftCardSale", "ProductSale", "ShippingLineSale", "TipSale", "UnknownSale"]
};
export default Sale;