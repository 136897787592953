
const PrivateMetafield = {
  "name": "PrivateMetafield",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "createdAt": "DateTime",
    "id": "ID",
    "key": "String",
    "namespace": "String",
    "updatedAt": "DateTime",
    "value": "String",
    "valueType": "PrivateMetafieldValueType"
  },
  "implementsNode": true
};
export default PrivateMetafield;