
const PriceRuleLineItemPrerequisites = {
  "name": "PriceRuleLineItemPrerequisites",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "collections": "CollectionConnection",
    "productVariants": "ProductVariantConnection",
    "products": "ProductConnection"
  },
  "implementsNode": false
};
export default PriceRuleLineItemPrerequisites;