
const SubscriptionDeliveryPolicyInput = {
  "name": "SubscriptionDeliveryPolicyInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "interval": "SellingPlanInterval",
    "intervalCount": "Int",
    "anchors": "SellingPlanAnchorInput"
  }
};
export default SubscriptionDeliveryPolicyInput;