
const PaymentTerms = {
  "name": "PaymentTerms",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "draftOrder": "DraftOrder",
    "dueInDays": "Int",
    "id": "ID",
    "order": "Order",
    "overdue": "Boolean",
    "paymentSchedules": "PaymentScheduleConnection",
    "paymentTermsName": "String",
    "paymentTermsType": "PaymentTermsType",
    "translatedName": "String"
  },
  "implementsNode": true
};
export default PaymentTerms;