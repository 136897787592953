
const PriceRuleDiscountCodeUpdatePayload = {
  "name": "PriceRuleDiscountCodeUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "priceRule": "PriceRule",
    "priceRuleDiscountCode": "PriceRuleDiscountCode",
    "priceRuleUserErrors": "PriceRuleUserError",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default PriceRuleDiscountCodeUpdatePayload;