
const FulfillmentConstraintRule = {
  "name": "FulfillmentConstraintRule",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "function": "ShopifyFunction",
    "id": "ID",
    "metafield": "Metafield",
    "metafields": "MetafieldConnection",
    "privateMetafield": "PrivateMetafield",
    "privateMetafields": "PrivateMetafieldConnection"
  },
  "implementsNode": true
};
export default FulfillmentConstraintRule;