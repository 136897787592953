
const MetafieldAccessUpdateInput = {
  "name": "MetafieldAccessUpdateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "admin": "MetafieldAdminAccess",
    "storefront": "MetafieldStorefrontAccess",
    "grants": "MetafieldAccessGrantOperationInput"
  }
};
export default MetafieldAccessUpdateInput;