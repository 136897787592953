
const SubscriptionContract = {
  "name": "SubscriptionContract",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "app": "App",
    "appAdminUrl": "URL",
    "billingAttempts": "SubscriptionBillingAttemptConnection",
    "billingPolicy": "SubscriptionBillingPolicy",
    "createdAt": "DateTime",
    "currencyCode": "CurrencyCode",
    "customAttributes": "Attribute",
    "customer": "Customer",
    "customerPaymentMethod": "CustomerPaymentMethod",
    "deliveryMethod": "SubscriptionDeliveryMethod",
    "deliveryPolicy": "SubscriptionDeliveryPolicy",
    "deliveryPrice": "MoneyV2",
    "discounts": "SubscriptionManualDiscountConnection",
    "id": "ID",
    "lastPaymentStatus": "SubscriptionContractLastPaymentStatus",
    "lineCount": "Int",
    "lines": "SubscriptionLineConnection",
    "linesCount": "Count",
    "nextBillingDate": "DateTime",
    "note": "String",
    "orders": "OrderConnection",
    "originOrder": "Order",
    "revisionId": "UnsignedInt64",
    "status": "SubscriptionContractSubscriptionStatus",
    "updatedAt": "DateTime"
  },
  "implementsNode": true
};
export default SubscriptionContract;