
const Customer = {
  "name": "Customer",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "addresses": "MailingAddress",
    "amountSpent": "MoneyV2",
    "canDelete": "Boolean",
    "companyContactProfiles": "CompanyContact",
    "createdAt": "DateTime",
    "defaultAddress": "MailingAddress",
    "displayName": "String",
    "email": "String",
    "emailMarketingConsent": "CustomerEmailMarketingConsentState",
    "events": "EventConnection",
    "firstName": "String",
    "hasTimelineComment": "Boolean",
    "id": "ID",
    "image": "Image",
    "lastName": "String",
    "lastOrder": "Order",
    "legacyResourceId": "UnsignedInt64",
    "lifetimeDuration": "String",
    "locale": "String",
    "market": "Market",
    "mergeable": "CustomerMergeable",
    "metafield": "Metafield",
    "metafieldDefinitions": "MetafieldDefinitionConnection",
    "metafields": "MetafieldConnection",
    "multipassIdentifier": "String",
    "note": "String",
    "numberOfOrders": "UnsignedInt64",
    "orders": "OrderConnection",
    "paymentMethods": "CustomerPaymentMethodConnection",
    "phone": "String",
    "privateMetafield": "PrivateMetafield",
    "privateMetafields": "PrivateMetafieldConnection",
    "productSubscriberStatus": "CustomerProductSubscriberStatus",
    "smsMarketingConsent": "CustomerSmsMarketingConsentState",
    "state": "CustomerState",
    "statistics": "CustomerStatistics",
    "subscriptionContracts": "SubscriptionContractConnection",
    "tags": "String",
    "taxExempt": "Boolean",
    "taxExemptions": "TaxExemption",
    "unsubscribeUrl": "URL",
    "updatedAt": "DateTime",
    "validEmailAddress": "Boolean",
    "verifiedEmail": "Boolean"
  },
  "implementsNode": true
};
export default Customer;