
const ShopifyPaymentsDispute = {
  "name": "ShopifyPaymentsDispute",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "amount": "MoneyV2",
    "evidenceDueBy": "Date",
    "evidenceSentOn": "Date",
    "finalizedOn": "Date",
    "id": "ID",
    "initiatedAt": "DateTime",
    "legacyResourceId": "UnsignedInt64",
    "order": "Order",
    "reasonDetails": "ShopifyPaymentsDisputeReasonDetails",
    "status": "DisputeStatus",
    "type": "DisputeType"
  },
  "implementsNode": true
};
export default ShopifyPaymentsDispute;