
const InventoryScheduledChangeInput = {
  "name": "InventoryScheduledChangeInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "expectedAt": "DateTime",
    "fromName": "String",
    "toName": "String"
  }
};
export default InventoryScheduledChangeInput;