
const RiskFact = {
  "name": "RiskFact",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "description": "String",
    "sentiment": "RiskFactSentiment"
  },
  "implementsNode": false
};
export default RiskFact;