
const SellingPlanInput = {
  "name": "SellingPlanInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "id": "ID",
    "name": "String",
    "description": "String",
    "billingPolicy": "SellingPlanBillingPolicyInput",
    "deliveryPolicy": "SellingPlanDeliveryPolicyInput",
    "inventoryPolicy": "SellingPlanInventoryPolicyInput",
    "pricingPolicies": "SellingPlanPricingPolicyInput",
    "options": "String",
    "position": "Int",
    "category": "SellingPlanCategory"
  }
};
export default SellingPlanInput;