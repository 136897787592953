
const CurrencyFormats = {
  "name": "CurrencyFormats",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "moneyFormat": "FormattedString",
    "moneyInEmailsFormat": "String",
    "moneyWithCurrencyFormat": "FormattedString",
    "moneyWithCurrencyInEmailsFormat": "String"
  },
  "implementsNode": false
};
export default CurrencyFormats;