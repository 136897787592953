
const ExchangeV2Returns = {
  "name": "ExchangeV2Returns",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "lineItems": "ExchangeV2LineItem",
    "orderDiscountAmountSet": "MoneyBag",
    "shippingRefundAmountSet": "MoneyBag",
    "subtotalPriceSet": "MoneyBag",
    "taxLines": "TaxLine",
    "tipRefundAmountSet": "MoneyBag",
    "totalPriceSet": "MoneyBag"
  },
  "implementsNode": false
};
export default ExchangeV2Returns;