
const CheckoutBrandingShopifyFont = {
  "name": "CheckoutBrandingShopifyFont",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "sources": "String",
    "weight": "Int"
  },
  "implementsNode": false
};
export default CheckoutBrandingShopifyFont;