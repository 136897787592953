
const ImageInput = {
  "name": "ImageInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "id": "ID",
    "altText": "String",
    "src": "String"
  }
};
export default ImageInput;