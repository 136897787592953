
const ProductResourceFeedback = {
  "name": "ProductResourceFeedback",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "feedbackGeneratedAt": "DateTime",
    "messages": "String",
    "productId": "ID",
    "productUpdatedAt": "DateTime",
    "state": "ResourceFeedbackState"
  },
  "implementsNode": false
};
export default ProductResourceFeedback;