
const Publication = {
  "name": "Publication",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "app": "App",
    "autoPublish": "Boolean",
    "catalog": "Catalog",
    "collectionPublicationsV3": "ResourcePublicationConnection",
    "collections": "CollectionConnection",
    "hasCollection": "Boolean",
    "id": "ID",
    "name": "String",
    "operation": "PublicationOperation",
    "productPublicationsV3": "ResourcePublicationConnection",
    "products": "ProductConnection",
    "supportsFuturePublishing": "Boolean"
  },
  "implementsNode": true
};
export default Publication;