
const JobResult = {
  "name": "JobResult",
  "kind": "INTERFACE",
  "fieldBaseTypes": {
    "done": "Boolean",
    "id": "ID"
  },
  "possibleTypes": ["CustomerSegmentMembersQuery"]
};
export default JobResult;