
const CheckoutBrandingButtonColorRoles = {
  "name": "CheckoutBrandingButtonColorRoles",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "accent": "String",
    "background": "String",
    "border": "String",
    "decorative": "String",
    "hover": "CheckoutBrandingColorRoles",
    "icon": "String",
    "text": "String"
  },
  "implementsNode": false
};
export default CheckoutBrandingButtonColorRoles;