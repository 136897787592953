
const QuantityPriceBreak = {
  "name": "QuantityPriceBreak",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "minimumQuantity": "Int",
    "price": "MoneyV2",
    "priceList": "PriceList",
    "variant": "ProductVariant"
  },
  "implementsNode": true
};
export default QuantityPriceBreak;