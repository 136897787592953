
const SubscriptionBillingAttempt = {
  "name": "SubscriptionBillingAttempt",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "completedAt": "DateTime",
    "createdAt": "DateTime",
    "errorCode": "SubscriptionBillingAttemptErrorCode",
    "errorMessage": "String",
    "id": "ID",
    "idempotencyKey": "String",
    "nextActionUrl": "URL",
    "order": "Order",
    "originTime": "DateTime",
    "ready": "Boolean",
    "subscriptionContract": "SubscriptionContract"
  },
  "implementsNode": true
};
export default SubscriptionBillingAttempt;