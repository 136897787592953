
const MoneyBag = {
  "name": "MoneyBag",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "presentmentMoney": "MoneyV2",
    "shopMoney": "MoneyV2"
  },
  "implementsNode": false
};
export default MoneyBag;