
const Brand = {
  "name": "Brand",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "colors": "BrandColors",
    "coverImage": "MediaImage",
    "logo": "MediaImage",
    "shortDescription": "String",
    "slogan": "String",
    "squareLogo": "MediaImage"
  },
  "implementsNode": false
};
export default Brand;