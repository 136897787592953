import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/bread-crumb';
import { AvField, AvForm } from "availity-reactstrap-validation";
import { getNetlifySnippetByTitle, addNetlifySnippet, updateNetlifySnippet, getNetlifySiteSettings } from '../../querys/netlify-rest';
import { Alert } from "reactstrap";
import { AuthContext } from "../../auth/auth";
import { CHECKOUT_URLS } from "../utils/constant";

export class CustomizeGoogleAnalytics extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleGaIdChange = this.handleGaIdChange.bind(this);
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.fetchNetlifyGaSnippet = this.fetchNetlifyGaSnippet.bind(this);
        this.state = {
            alert: false,
            alertMessage: "",
            alertColor: "success",
            value: "",
            gaIdValue: "",
            netlifySnippetId: "",
            netlifySnippetContent: "",
            gaIdNetlifySnippetId: "",
            gaIdNetlifySnippetContent: "",
            gaSnippetHead: "",
            gaSnippetCenter: "",
            gaSnippetTail: "",
        }
    }

    // since setting the GA snippet is necessary for the netlify fetches, run that first
    componentDidMount() {
        this.setGaSnippet()
        .then(() => {
          this.fetchNetlifyGaSnippet();
          this.fetchNetlifyGaIdSnippet();
          window.analytics.page();
        })
        .catch((err) => {
          console.log(`Unable to set GA snippet - ${err}`);
        })
    }

    async setGaSnippet() {
      const siteId = localStorage.getItem("whitelabelResellerSiteId") || sessionStorage.getItem("whitelabelResellerSiteId");
      const siteSettings = await getNetlifySiteSettings(siteId);
      let checkoutUrl = "";
      const customCheckoutUrlExists = siteSettings.data.custom_domain ? CHECKOUT_URLS.find(c => c.customSubdomain === siteSettings.data.custom_domain) : null;
      if (customCheckoutUrlExists) {
        checkoutUrl = customCheckoutUrlExists.checkoutUrl;
      } else {
        // the staging admin has an ENV variable flagging that it's sites are staging sites
        checkoutUrl = process.env.ENV === 'staging' ? 'white-label-staging.myshopify.com' : 'checkout.readysetprint.com';
      };
      const gaSnippetHead = `<script>
      (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
      ga('create', '`

      const gaSnippetCenter = `', 'auto', {'allowLinker': true});
      ga('require', 'linker');
      ga('linker:autoLink', ['${checkoutUrl}']);
      ga('send', 'pageview');
      window.gaId="`    

      const gaSnippetTail = `";
      </script>`;

      this.setState({
        gaSnippetHead: gaSnippetHead,
        gaSnippetCenter: gaSnippetCenter,
        gaSnippetTail: gaSnippetTail
      })
    }

    fetchNetlifyGaSnippet() {
        // Check for existing Netlify snippets
        getNetlifySnippetByTitle(this.context.reseller_site_id, "GA")
            .then((response) => {
                if (response.id && response.general) {
                    // Update the state with the Netlify snippet data
                    this.setState({
                        value: response.general,
                        netlifySnippetId: response.id,
                        netlifySnippetContent: response.general
                    });
                } else {
                    console.log(response);
                }
            });
    }

    fetchNetlifyGaIdSnippet() {
        // Check for existing Netlify snippets
        getNetlifySnippetByTitle(this.context.reseller_site_id, "GA ID")
            .then((response) => {
                if (response.id && response.general) {
                    // use the script pieces to parse the id from the snippet
                    const tail = response.general.replace(this.state.gaSnippetHead, "");
                    const gaIdValue = tail.substring(0, tail.indexOf(this.state.gaSnippetCenter));
                    // Update the state with the Netlify snippet data
                    this.setState({
                        gaIdValue: gaIdValue,
                        gaIdNetlifySnippetId: response.id,
                        gaIdNetlifySnippetContent: response.general
                    });
                } else {
                    console.log(response);
                }
            });
    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }
    handleGaIdChange(event) {
        this.setState({ gaIdValue: event.target.value });
    }

    toggle() {
        this.setState({
            alert: !this.state.alert
        });
    }

    updateGaIdSnippet(values) {
        const script = `${this.state.gaSnippetHead}${values["ga-id"]}${this.state.gaSnippetCenter}${values["ga-id"]}${this.state.gaSnippetTail}`;
        return getNetlifySnippetByTitle(this.context.reseller_site_id, "GA ID")
            .then((response) => {
                if (response.id && response.general) {
                    const netlifySnippetId = response.id;
                    // Update Netlify Snippet for GA
                    return updateNetlifySnippet(this.context.reseller_site_id, "GA ID", script, netlifySnippetId)
                        .then((response) => {
                            if (response.status === 200) {
                                return true;
                            } else {
                                console.log(response);
                                return response;
                            }
                        });
                } else {
                    // Add Netlify Snippet for GA
                    return addNetlifySnippet(this.context.reseller_site_id, "GA ID", script)
                        .then((response) => {
                            if (response.status === 201) {
                                return true;
                            } else {
                                return response;
                            }
                        });
                }
            });
    }

    handleValidSubmit(event, values) {

        getNetlifySnippetByTitle(this.context.reseller_site_id, "GA")
            .then((response) => {
                if (response.id && response.general) {
                    const netlifySnippetId = response.id;
                    // Update Netlify Snippet for GA
                    updateNetlifySnippet(this.context.reseller_site_id, "GA", values["ga-embed"], netlifySnippetId)
                        .then(async (response) => {
                            if (response.status === 200) {
                                const gaIdResponse = await this.updateGaIdSnippet(values);
                                if (gaIdResponse === true) {
                                    this.setState({
                                        alert: true,
                                        alertMessage: "Success: Your Google Analytics code was updated on your site.",
                                        alertColor: "success"
                                    });
                                } else {
                                    console.log(gaIdResponse);
                                    this.setState({
                                        alert: true,
                                        alertMessage: gaIdResponse.message,
                                        alertColor: "danger"
                                    });
                                }
                            } else {
                                console.log(response);
                                this.setState({
                                    alert: true,
                                    alertMessage: response.message,
                                    alertColor: "danger"
                                });
                            }
                        });
                } else {
                    // Add Netlify Snippet for GA
                    addNetlifySnippet(this.context.reseller_site_id, "GA", values["ga-embed"])
                        .then(async (response) => {
                            if (response.status === 201) {
                                const gaIdResponse = await this.updateGaIdSnippet(values);
                                if (gaIdResponse === true) {
                                    this.setState({
                                        alert: true,
                                        alertMessage: "Success: Your Google Analytics code was added to your site.",
                                        alertColor: "success"
                                    });
                                } else {
                                    console.log(gaIdResponse);
                                    this.setState({
                                        alert: true,
                                        alertMessage: gaIdResponse.message,
                                        alertColor: "danger"
                                    });
                                }
                            } else {
                                console.log(response);
                                this.setState({
                                    alert: true,
                                    alertMessage: response.message,
                                    alertColor: "danger"
                                });
                            }
                        });
                }
            });

    }

    render() {
        return (
            <Fragment>
                <Breadcrumb mainTitle="Google Analytics" parent="Customize" title="Google Analytics" />
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <h5>Google Analytics</h5>
                        </div>
                        <div className="card-body">
                            <AvForm
                                className="needs-validation"
                                onValidSubmit={this.handleValidSubmit}
                            >
                                <div className="row">
                                    <div className="col-sm-12 col-xl-3 col-md-4 mt-1 mb-3">
                                        Your Google Analytics ID<br />(Please note: E-commerce tracking must be enabled in Google Analytics)
                                    </div>
                                    <div className="col-sm-12 offset-md-1 col-md-7 offset-xl-1 col-xl-8" >
                                        <AvField
                                            type="text"
                                            name="ga-id"
                                            className="form-control ml-0"
                                            style={{ fontSize: '12px' }}
                                            placeholder="Paste your Google Analytics ID here"
                                            value={this.state.gaIdValue}
                                            onChange={this.handleGaIdChange}
                                            required
                                        />
                                    </div>
                                    <div className="col-sm-12 col-xl-3 col-md-4 mt-1 mb-3">
                                        Any additional scripts must start with <b>&lt;script&gt;</b> and end with <b>&lt;&#47;script&gt;</b>
                                    </div>
                                    <div className="col-sm-12 offset-md-1 col-md-7 offset-xl-1 col-xl-8" >
                                        <AvField
                                            type="textarea"
                                            name="ga-embed"
                                            className="form-control ml-0"
                                            style={{ fontSize: '12px' }}
                                            placeholder="Paste your Google Analytics code here"
                                            rows={7}
                                            value={this.state.value}
                                            onChange={this.handleChange}
                                            required
                                        />
                                        <button className="btn btn-primary btn-custom mt-3">
                                            Save
                                        </button>
                                        <br></br>
                                        <br></br>
                                        <Alert color={this.state.alertColor} isOpen={this.state.alert} toggle={this.toggle.bind(this)} > {this.state.alertMessage} </Alert>
                                    </div>
                                </div>
                            </AvForm>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

CustomizeGoogleAnalytics.contextType = AuthContext;

export default CustomizeGoogleAnalytics;
