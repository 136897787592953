export const generateTitleCSS = (color) => {
    return `
    /* Titles */
    /* title_color=${color} */
    h1, h2, h3, h4, h5, h6, .menu-item, .sub-heading, .info-price-main, .category-page h1 {
        color: ${color} !important;
    }`
}

export const generateBodyCSS = (color) => {
    return `
    /* Body Color */
    /* body_color=${color} */
    body, p, .logo-description, .pt-2 .footer-copyright, .info-detail, .info-tip, .main-item, .text-center h4, .nav-link, .nav-link .react-tabs__tab--selected {
        color: ${color} !important;
    }`
}

export const generateButtonCSS = (color) => {
    return `
    /* Primary Buttons & Active Text Links */
    /* button_color=${color} */
    .btn-primary, .cart-qty-cls, .top-header {
        background-color: ${color} !important;
        border-color: ${color} !important;
    }
    .btn-primary:hover, .btn-solid:hover, .btn:hover {
        border-color: ${color} !important;
        background-color: ${color} !important;
        filter: brightness(80%) !important;
    }
    .btn-solid {
        background-image: -webkit-linear-gradient(30deg, ${color} 50%, transparent 50%) !important;
        background-image: linear-gradient(30deg, ${color} 50%, transparent 50%) !important;
        border-color: ${color} !important;
    }
    a:hover, .footer-social i:hover {
        color: ${color} !important;
    }
    .footer-theme .sub-title li a:before, .loader-wrapper .loader {
        background-color: ${color} !important;
    }
    #product-canvas-loader > svg > circle, .loader > svg > circle {
      stroke: ${color} !important;
    }`
}

export const generateButtonTextCSS = (color) => {
    return `
    /* Primary Button Text */
    /* button_text_color=${color} */
    .btn-solid {
        color: ${color} !important;
    }
    .btn-primary, .top-header, .cart-qty-cls, .btn {
        color: ${color} !important;
    }
    .btn-primary:hover, .btn-solid:hover {
        color: ${color} !important;
    }`
}

export const generateHomepageHeroCSS = (color) => {
    return `
    /* Homepage hero */
    /* homepage_hero_color=${color} */
    .hero h4, .hero h1 {
        color: ${color} !important;
    }`
}