
const DiscountShareableUrl = {
  "name": "DiscountShareableUrl",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "targetItemImage": "Image",
    "targetType": "DiscountShareableUrlTargetType",
    "title": "String",
    "url": "URL"
  },
  "implementsNode": false
};
export default DiscountShareableUrl;