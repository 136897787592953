
const MetaobjectDefinition = {
  "name": "MetaobjectDefinition",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "access": "MetaobjectAccess",
    "capabilities": "MetaobjectCapabilities",
    "createdByApp": "App",
    "createdByStaff": "StaffMember",
    "description": "String",
    "displayNameKey": "String",
    "fieldDefinitions": "MetaobjectFieldDefinition",
    "hasThumbnailField": "Boolean",
    "id": "ID",
    "metaobjects": "MetaobjectConnection",
    "metaobjectsCount": "Int",
    "name": "String",
    "type": "String"
  },
  "implementsNode": true
};
export default MetaobjectDefinition;