
const AbandonedCheckoutLineItemConnection = {
  "name": "AbandonedCheckoutLineItemConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "AbandonedCheckoutLineItemEdge",
    "nodes": "AbandonedCheckoutLineItem",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default AbandonedCheckoutLineItemConnection;