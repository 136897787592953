
const DeliverySettingUpdatePayload = {
  "name": "DeliverySettingUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "setting": "DeliverySetting",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default DeliverySettingUpdatePayload;