
const AppRevenueAttributionRecordConnection = {
  "name": "AppRevenueAttributionRecordConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "AppRevenueAttributionRecordEdge",
    "nodes": "AppRevenueAttributionRecord",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default AppRevenueAttributionRecordConnection;