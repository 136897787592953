
const ProductSetPayload = {
  "name": "ProductSetPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "product": "Product",
    "productSetOperation": "ProductSetOperation",
    "userErrors": "ProductSetUserError"
  },
  "implementsNode": false
};
export default ProductSetPayload;