
const DomainLocalization = {
  "name": "DomainLocalization",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "alternateLocales": "String",
    "country": "String",
    "defaultLocale": "String"
  },
  "implementsNode": false
};
export default DomainLocalization;