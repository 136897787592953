
const AdditionalFee = {
  "name": "AdditionalFee",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "name": "String",
    "price": "MoneyBag",
    "taxLines": "TaxLine"
  },
  "implementsNode": true
};
export default AdditionalFee;