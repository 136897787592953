
const DeliveryAvailableService = {
  "name": "DeliveryAvailableService",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "countries": "DeliveryCountryCodesOrRestOfWorld",
    "name": "String"
  },
  "implementsNode": false
};
export default DeliveryAvailableService;