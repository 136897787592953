
const CollectionRule = {
  "name": "CollectionRule",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "column": "CollectionRuleColumn",
    "condition": "String",
    "conditionObject": "CollectionRuleConditionObject",
    "relation": "CollectionRuleRelation"
  },
  "implementsNode": false
};
export default CollectionRule;