
const Location = {
  "name": "Location",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "address": "LocationAddress",
    "id": "ID",
    "metafield": "Metafield",
    "metafields": "Metafield",
    "name": "String"
  },
  "implementsNode": true
};
export default Location;