
const CheckoutBrandingColorSchemes = {
  "name": "CheckoutBrandingColorSchemes",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "scheme1": "CheckoutBrandingColorScheme",
    "scheme2": "CheckoutBrandingColorScheme",
    "scheme3": "CheckoutBrandingColorScheme",
    "scheme4": "CheckoutBrandingColorScheme"
  },
  "implementsNode": false
};
export default CheckoutBrandingColorSchemes;