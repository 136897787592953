
const CustomerGenerateAccountActivationUrlPayload = {
  "name": "CustomerGenerateAccountActivationUrlPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "accountActivationUrl": "URL",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default CustomerGenerateAccountActivationUrlPayload;