
const Event = {
  "name": "Event",
  "kind": "INTERFACE",
  "fieldBaseTypes": {
    "appTitle": "String",
    "attributeToApp": "Boolean",
    "attributeToUser": "Boolean",
    "createdAt": "DateTime",
    "criticalAlert": "Boolean",
    "id": "ID",
    "message": "FormattedString"
  },
  "possibleTypes": ["BasicEvent", "CommentEvent"]
};
export default Event;