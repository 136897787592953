
const CheckoutCompleteWithCreditCardV2Payload = {
  "name": "CheckoutCompleteWithCreditCardV2Payload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkout": "Checkout",
    "checkoutUserErrors": "CheckoutUserError",
    "payment": "Payment",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default CheckoutCompleteWithCreditCardV2Payload;