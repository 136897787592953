
const SegmentAssociationFilter = {
  "name": "SegmentAssociationFilter",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "localizedName": "String",
    "multiValue": "Boolean",
    "queryName": "String"
  },
  "implementsNode": false
};
export default SegmentAssociationFilter;