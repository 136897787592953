
const RefundDuty = {
  "name": "RefundDuty",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "amountSet": "MoneyBag",
    "originalDuty": "Duty"
  },
  "implementsNode": false
};
export default RefundDuty;