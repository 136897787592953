
const CartCompletionActionRequired = {
  "name": "CartCompletionActionRequired",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "action": "CartCompletionAction",
    "id": "String"
  },
  "implementsNode": false
};
export default CartCompletionActionRequired;