
const ChannelDefinition = {
  "name": "ChannelDefinition",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "channelName": "String",
    "handle": "String",
    "id": "ID",
    "isMarketplace": "Boolean",
    "subChannelName": "String",
    "svgIcon": "String"
  },
  "implementsNode": true
};
export default ChannelDefinition;