
const SellingPlanFixedDeliveryPolicy = {
  "name": "SellingPlanFixedDeliveryPolicy",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "anchors": "SellingPlanAnchor",
    "cutoff": "Int",
    "fulfillmentExactTime": "DateTime",
    "fulfillmentTrigger": "SellingPlanFulfillmentTrigger",
    "intent": "SellingPlanFixedDeliveryPolicyIntent",
    "preAnchorBehavior": "SellingPlanFixedDeliveryPolicyPreAnchorBehavior"
  },
  "implementsNode": false
};
export default SellingPlanFixedDeliveryPolicy;