
const CompanyContactRevokeRolePayload = {
  "name": "CompanyContactRevokeRolePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "revokedCompanyContactRoleAssignmentId": "ID",
    "userErrors": "BusinessCustomerUserError"
  },
  "implementsNode": false
};
export default CompanyContactRevokeRolePayload;