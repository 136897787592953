
const CheckoutBrandingTypography = {
  "name": "CheckoutBrandingTypography",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "primary": "CheckoutBrandingFontGroup",
    "secondary": "CheckoutBrandingFontGroup",
    "size": "CheckoutBrandingFontSize"
  },
  "implementsNode": false
};
export default CheckoutBrandingTypography;