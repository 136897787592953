
const LocationEditPayload = {
  "name": "LocationEditPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "location": "Location",
    "userErrors": "LocationEditUserError"
  },
  "implementsNode": false
};
export default LocationEditPayload;