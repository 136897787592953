
const FulfillmentHold = {
  "name": "FulfillmentHold",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "heldBy": "String",
    "reason": "FulfillmentHoldReason",
    "reasonNotes": "String"
  },
  "implementsNode": false
};
export default FulfillmentHold;