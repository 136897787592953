
const ReverseFulfillmentOrderDisposition = {
  "name": "ReverseFulfillmentOrderDisposition",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "location": "Location",
    "quantity": "Int",
    "type": "ReverseFulfillmentOrderDispositionType"
  },
  "implementsNode": true
};
export default ReverseFulfillmentOrderDisposition;