
const MenuItem = {
  "name": "MenuItem",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "items": "MenuItem",
    "resource": "MenuItemResource",
    "resourceId": "ID",
    "tags": "String",
    "title": "String",
    "type": "MenuItemType",
    "url": "URL"
  },
  "implementsNode": true
};
export default MenuItem;