
const OrderStagedChangeAddShippingLine = {
  "name": "OrderStagedChangeAddShippingLine",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "phone": "String",
    "presentmentTitle": "String",
    "price": "MoneyV2",
    "title": "String"
  },
  "implementsNode": false
};
export default OrderStagedChangeAddShippingLine;