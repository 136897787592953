
const MetafieldAccess = {
  "name": "MetafieldAccess",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "admin": "MetafieldAdminAccess",
    "grants": "MetafieldAccessGrant",
    "storefront": "MetafieldStorefrontAccess"
  },
  "implementsNode": false
};
export default MetafieldAccess;