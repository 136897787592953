
const SubscriptionMailingAddress = {
  "name": "SubscriptionMailingAddress",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "address1": "String",
    "address2": "String",
    "city": "String",
    "company": "String",
    "country": "String",
    "countryCode": "CountryCode",
    "firstName": "String",
    "lastName": "String",
    "name": "String",
    "phone": "String",
    "province": "String",
    "provinceCode": "String",
    "zip": "String"
  },
  "implementsNode": false
};
export default SubscriptionMailingAddress;