
const SuggestedReturnRefund = {
  "name": "SuggestedReturnRefund",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "amount": "MoneyBag",
    "discountedSubtotal": "MoneyBag",
    "maximumRefundable": "MoneyBag",
    "refundDuties": "RefundDuty",
    "shipping": "ShippingRefund",
    "subtotal": "MoneyBag",
    "suggestedTransactions": "SuggestedOrderTransaction",
    "totalCartDiscountAmount": "MoneyBag",
    "totalDuties": "MoneyBag",
    "totalTax": "MoneyBag"
  },
  "implementsNode": false
};
export default SuggestedReturnRefund;