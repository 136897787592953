
const MetafieldAccessInput = {
  "name": "MetafieldAccessInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "admin": "MetafieldAdminAccess",
    "storefront": "MetafieldStorefrontAccess",
    "grants": "MetafieldAccessGrantInput"
  }
};
export default MetafieldAccessInput;