import React, { Component } from 'react';
import Select from 'react-select';
import { AuthContext } from "../../auth/auth";
import AvField from 'availity-reactstrap-validation/lib/AvField';
import { DEFAULT_MENU_LINK_OPTIONS } from '../utils/constant';

class LinksDropdown extends Component {

    constructor(props) {
        super(props);

        this.state = {
            options: DEFAULT_MENU_LINK_OPTIONS,
            selectedValue: "",
            linkUrl: "",
            linkDescription: ""
        };
    }

    componentDidMount = () => {

        if (!this.props.selectedValue) {
            this.onSelectOption({
                target: {
                    value: "page"
                }
            });
        } else {
            this.updateLinkDescription(this.props.selectedValue);
        }
    }

    updateLinkDescription = (type) => {

        let linkUrl = "";
        let linkDescription = "";

        switch (type) {
            case "page":
                linkDescription = "View all pages";
                linkUrl = "/pages/";
                break;
            case "category":
                linkDescription = "View all categories";
                linkUrl = "/categories/";
                break;
            case "product":
                linkDescription = "View all products";
                linkUrl = "/products/";
                break;
        }

        this.setState({
            linkUrl,
            linkDescription
        });
    }

    onSelectOption = (selectedOption) => {

        this.updateLinkDescription(selectedOption.target.value);

        const { store_subdomain, custom_subdomain } = this.context;
        const { options } = this.state;

        const path = options.find(o => o.value === selectedOption.target.value).path;

        const link = path ? custom_subdomain ? `https://${custom_subdomain}${path}` : `https://${store_subdomain}.readysetprint.com${path}` : "";

        this.props.onChange({
            ...selectedOption,
            path,
            link
        });
    }

    render() {

        const { options, linkUrl, linkDescription } = this.state;

        return <><AvField type="select" name={this.props.name} onChange={this.onSelectOption}>
            {options.map((option, index) => {
                return <option selected={index === 1 ? "selected" : ""} value={option.value} data-path={option.path}>{option.label}</option>
            })}
        </AvField>
            <a href={linkUrl} target="_blank">{linkDescription}</a>
        </>
    }

}

LinksDropdown.contextType = AuthContext;

export default LinksDropdown;