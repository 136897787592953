
const MetafieldInput = {
  "name": "MetafieldInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "id": "ID",
    "namespace": "String",
    "key": "String",
    "value": "String",
    "type": "String"
  }
};
export default MetafieldInput;