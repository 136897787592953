
const ProductDuplicateAsyncV2Payload = {
  "name": "ProductDuplicateAsyncV2Payload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "duplicatedProductId": "ID",
    "productDuplicateJobId": "ID",
    "userErrors": "ProductDuplicateUserError"
  },
  "implementsNode": false
};
export default ProductDuplicateAsyncV2Payload;