
const AppSubscriptionLineItemUpdatePayload = {
  "name": "AppSubscriptionLineItemUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "appSubscription": "AppSubscription",
    "confirmationUrl": "URL",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default AppSubscriptionLineItemUpdatePayload;