
const OrderInput = {
  "name": "OrderInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "id": "ID",
    "email": "String",
    "note": "String",
    "tags": "String",
    "shippingAddress": "MailingAddressInput",
    "customAttributes": "AttributeInput",
    "metafields": "MetafieldInput",
    "localizationExtensions": "LocalizationExtensionInput",
    "poNumber": "String"
  }
};
export default OrderInput;