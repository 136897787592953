
const CustomerAccountsV2 = {
  "name": "CustomerAccountsV2",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customerAccountsVersion": "CustomerAccountsVersion",
    "loginLinksVisibleOnStorefrontAndCheckout": "Boolean",
    "loginRequiredAtCheckout": "Boolean",
    "url": "URL"
  },
  "implementsNode": false
};
export default CustomerAccountsV2;