
const DeliveryMethodAdditionalInformation = {
  "name": "DeliveryMethodAdditionalInformation",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "instructions": "String",
    "phone": "String"
  },
  "implementsNode": false
};
export default DeliveryMethodAdditionalInformation;