import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/bread-crumb';
import CKEditors from "react-ckeditor-component";
import GeneralInput from '../../components/common/general-input';
import { AvForm } from "availity-reactstrap-validation";
import { AuthContext } from '../../auth/auth';
import { Link } from 'react-router-dom';
import Loader from '../loader/loader';
import { toast } from 'react-toastify';


export class PageEdit extends Component {

    constructor(props) {
        super(props)

        this.state = {
            page: {},
            editMode: false,
            pageBody: "",
            loading: true,
            pagePublished: false,
            displayedPageHandle: ""
        }
    }

    async componentDidMount() {

        let page = null
        const { pages } = this.context
        let editMode = false
        let displayedPageHandle = "";

        // Edition mode
        if (this.props.match.params.handle) {

            const { handle } = this.props.match.params

            editMode = true
            //fetch page if no context (comes with URL)
            const response = await fetch('/.netlify/functions/server/api/fetch-page-by-handle', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ handle })
            });
            try {
                if (!response.ok) throw response;

                page = await response.json();

                displayedPageHandle = this.pageHandleToUrl(page.handle);
            }
            catch (err) {
                //Show response error
                if (err.message) {
                    toast.error(`${err.message}`);
                } else {
                    toast.error(`${err.status}: ${err.statusText}`);
                }                
            }
        } else { //New page
            if (this.props.location.state) { // /new Sends base page information to be cloned

                page = { ...this.props.location.state.page }

                displayedPageHandle = this.pageHandleToUrl(page.handle);

            }
        }
        if (page) this.setState({
            page,
            editMode,
            loading: false,
            pageBody: page.body_html || page.body,
            pagePublished: !editMode ? true : page.published_at ? true : false,
            displayedPageHandle
        });
        
        window.analytics.page();
    }

    handleValidSubmit = (_event, values) => {

        this.setState({ loading: true })
        const { editMode } = this.state

        if (editMode) {
            this.updatePage({ values });
        } else {
            this.createPage({ values });
        }
    }

    isPagePublished = () => {
        const { page, pagePublished, editMode } = this.state

        let isPagePublished = null

        // On creating new page
        if (!editMode && pagePublished) {
            isPagePublished = new Date()
        }

        if (!page.published_at || !pagePublished) {
            if (pagePublished) {
                isPagePublished = new Date()
            }
        } else {
            isPagePublished = page.published_at
        }

        return isPagePublished
    }

    createPage = async ({ values }) => {

        const { updateContextPages, pages } = this.context
        const { pageBody, page } = this.state

        const newPage = {
            ...values,
            handle: `${this.context.store_subdomain}-${page.handle}`.toLowerCase(),
            body_html: pageBody,
            published_at: this.isPagePublished()
        }

        const response = await fetch('/.netlify/functions/server/api/create-page', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ page: newPage })
        });
        try {
            if (!response.ok) throw response;

            const body = await response.json();

            //update context
            if (pages.length > 0) updateContextPages(body.page.handle.replace(`${this.context.store_subdomain}-`, ""), body.page);

            this.props.history.push(`/pages/edit/${body.page.handle}`);
        }
        catch (err) {
            //Show response error
            if (err.message) {
                toast.error(`${err.message}`);
            } else {
                toast.error(`${err.status}: ${err.statusText}`);
            }            
        }
    }

    updatePage = async ({ values }) => {

        const { updateContextPages, pages } = this.context
        const { pageBody, page } = this.state

        const newPage = {
            id: page.id,
            title: values.title,
            body_html: pageBody,
            published_at: this.isPagePublished()
        }
        const response = await fetch('/.netlify/functions/server/api/update-page', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ page: newPage })
        });

        try {
            if (!response.ok) throw response;

            const body = await response.json();

            //Update context
            if (pages.length > 0) updateContextPages(body.page.handle, body.page)

            this.setState(() => ({
                page: {
                    ...newPage
                },
                loading: false
            }))
        }
        catch (err) {
            //Show response error
            if (err.message) {
                toast.error(`${err.message}`);
            } else {
                toast.error(`${err.status}: ${err.statusText}`);
            }

            this.setState({ loading: false });
        }

    }

    onCKEditorChange = (ev) => {
        var pageBody = ev.editor.getData()

        this.setState({ pageBody })
    }

    onPublishedChange = (ev) => {
        this.setState({ pagePublished: ev.target.checked })
    }

    onHideChange = (ev) => {
        this.setState({ pagePublished: !ev.target.checked })
    }

    pageHandleToUrl = (handle) => {
        return `https://${this.context.store_subdomain}.readysetprint.com/pages/${handle.toLowerCase().replace(`${this.context.store_subdomain.toLowerCase()}-`, "")}`;
    }

    urlToPageHandle = (url) => {
        return url.replace(`https://${this.context.store_subdomain}.readysetprint.com/pages/`, "");
    }

    render() {

        if (this.state.loading) {
            return <Loader />
        }

        const { page, pagePublished, pageBody } = this.state

        return (
            <Fragment>
                <Breadcrumb title="Create Page" parent="Pages" />
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <div className="top-buttons mb-4">
                                <div className="top-left">
                                    <Link className="btn-custom-link mb-3" to="/pages/">
                                        &lt; Pages
                                    </Link>
                                </div>
                                <div className="top-right"></div>
                            </div>
                            <h5>Add Page</h5>
                        </div>
                        <div className="card-body">
                            <AvForm
                                className="needs-validation add-product-form"
                                onValidSubmit={this.handleValidSubmit}
                                model={page}
                            >
                                <div className="form-group form-label-center row">
                                    <label className="col-xl-3 col-md-4"><span className="text-primary">*</span> Name</label>
                                    <div className="col-xl-8 col-md-7 p-0">
                                        <GeneralInput
                                            name="title"
                                            placeholder="Page Title"
                                        />
                                    </div>
                                </div>
                                <div className="form-group row editor-label">
                                    <label className="col-xl-3 col-md-4"><span className="text-primary">*</span> Description</label>
                                    <div className="col-xl-8 col-md-7 editor-space">
                                        <CKEditors
                                            activeclassName="p10"
                                            content={pageBody}
                                            name="body"
                                            events={{
                                                "change": this.onCKEditorChange.bind(this)
                                            }}
                                            config={{
                                                removeButtons: 'Image'
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row pt-3">
                                    <label className="col-xl-3 col-md-4">Status</label>
                                    <div className="col-xl-8 col-md-7 checkbox-space d-flex">
                                        <label className="d-block mr-5">
                                            <input className="checkbox_animated" id="chk-ani1" type="checkbox" checked={pagePublished ? true : false} onChange={this.onPublishedChange} />
                                            Publish
                                        </label>
                                        <label className="d-block">
                                            <input className="checkbox_animated" id="chk-ani11" type="checkbox" checked={pagePublished ? false : true} onChange={this.onHideChange} />
                                            Hide
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group form-label-center row">
                                    <label className="col-xl-3 col-md-4"></label>
                                    <div className="col-xl-8 col-md-7 p-0">
                                        <div className="mt-4">
                                            <div className="form-group">
                                                <label className="mb-2">URL Handle</label>
                                                <input type="text"
                                                    value={this.state.displayedPageHandle}
                                                    className="form-control mb-0"
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="text-center text-md-left">
                                            <button className="btn btn-primary mr-3" type="submit">
                                                Save
                                            </button>
                                            <Link className="btn btn-outline-primary" to={`/pages`}>
                                                Cancel
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </AvForm>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

PageEdit.contextType = AuthContext;

export default PageEdit;
