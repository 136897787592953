
const InventoryAdjustQuantitiesUserError = {
  "name": "InventoryAdjustQuantitiesUserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "InventoryAdjustQuantitiesUserErrorCode",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
export default InventoryAdjustQuantitiesUserError;