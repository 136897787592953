
const SearchResult = {
  "name": "SearchResult",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "description": "String",
    "image": "Image",
    "reference": "Node",
    "title": "String",
    "url": "URL"
  },
  "implementsNode": false
};
export default SearchResult;