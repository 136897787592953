
const StorefrontAccessTokenDeletePayload = {
  "name": "StorefrontAccessTokenDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedStorefrontAccessTokenId": "ID",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default StorefrontAccessTokenDeletePayload;