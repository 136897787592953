
const PriceList = {
  "name": "PriceList",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "catalog": "Catalog",
    "currency": "CurrencyCode",
    "fixedPricesCount": "Int",
    "id": "ID",
    "name": "String",
    "parent": "PriceListParent",
    "prices": "PriceListPriceConnection",
    "quantityRules": "QuantityRuleConnection"
  },
  "implementsNode": true
};
export default PriceList;