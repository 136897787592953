
const MetafieldsSetUserError = {
  "name": "MetafieldsSetUserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "MetafieldsSetUserErrorCode",
    "elementIndex": "Int",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
export default MetafieldsSetUserError;