
const CalculatedScriptDiscountApplication = {
  "name": "CalculatedScriptDiscountApplication",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "allocationMethod": "DiscountApplicationAllocationMethod",
    "appliedTo": "DiscountApplicationLevel",
    "description": "String",
    "id": "ID",
    "targetSelection": "DiscountApplicationTargetSelection",
    "targetType": "DiscountApplicationTargetType",
    "value": "PricingValue"
  },
  "implementsNode": false
};
export default CalculatedScriptDiscountApplication;