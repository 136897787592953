
const AvailableChannelDefinitionsByChannel = {
  "name": "AvailableChannelDefinitionsByChannel",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "channelDefinitions": "ChannelDefinition",
    "channelName": "String"
  },
  "implementsNode": false
};
export default AvailableChannelDefinitionsByChannel;