
const SalesAgreementEdge = {
  "name": "SalesAgreementEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "SalesAgreement"
  },
  "implementsNode": false
};
export default SalesAgreementEdge;