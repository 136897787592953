
const OrderCapturePayload = {
  "name": "OrderCapturePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "transaction": "OrderTransaction",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default OrderCapturePayload;