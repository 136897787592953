
const CompanyLocationAssignTaxExemptionsPayload = {
  "name": "CompanyLocationAssignTaxExemptionsPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "companyLocation": "CompanyLocation",
    "userErrors": "BusinessCustomerUserError"
  },
  "implementsNode": false
};
export default CompanyLocationAssignTaxExemptionsPayload;