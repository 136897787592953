import React, { Component } from 'react';

import one from '../../../assets/images/pro3/1.jpg'
import addIcon from '../../../assets/images/add-icon.png';
import Loader from "../../loader/loader";
import { ReactComponent as CloseIcon } from "../../../assets/elegant_font/images/SVG/icon_close_red.svg";

class ProductPhotoUpload extends Component {
    constructor(props) {
        super(props)
        this.state = {
            quantity: 1,
            file: '',
            images: { edges: [] },
            loading: false,
            loadingIndex: -1
        }
    }

    componentDidMount = () => {
        const { images } = this.props

        this.setState({ images })
    }

    IncrementItem = () => {
        this.setState(prevState => {
            if (prevState.quantity < 9) {
                return {
                    quantity: prevState.quantity + 1
                }
            } else {
                return null;
            }
        });
    }
    DecreaseItem = () => {
        this.setState(prevState => {
            if (prevState.quantity > 0) {
                return {
                    quantity: prevState.quantity - 1
                }
            } else {
                return null;
            }
        });
    }
    handleChange = (event) => {
        this.setState({ quantity: event.target.value });
    }

    //image upload
    _handleSubmit(e) {
        e.preventDefault();
    }

    async _handleImgChange(e, image, i) {
        this.setState({ loading: true, loadingIndex: i });
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        const { images } = this.state;
        let filename = file.name;
        if (file.size / 1024 <= 4000) {

            reader.onloadend = async () => {
                const result = reader.result;

                if (!image) {
                    image = { node: {} }
                }

                if (image.node && image.node.id) {
                    delete image.node.src;
                    image.node.attachment = result;

                    const index = images.edges.findIndex(i => i.node.id === image.node.id);

                    images.edges[index] = image;
                } else {
                    image.node.attachment = result;
                }
                image.node.filename = filename;
                image.node.position = i + 1;
                const response = await this.props.onImageChange(image.node);
                if (response) {
                    this.setState({ loading: false, loadingIndex: -1 });
                }
            }

            reader.readAsDataURL(file)
        } else {
            this.props.onImageChange(null, { error: { type: "size" } });
            this.setState({ loading: false, loadingIndex: -1 });
        }
    }

    async _handleNewImg(e) {
        this.setState({ loading: true, loadingIndex: this.props.images.edges.length });
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        if (file.size / 1024 <= 4000) {

            reader.onloadend = async () => {
                const result = reader.result;

                const newImage = {
                    node: {
                        attachment: result
                    }
                }

                const response = await this.props.onImageChange(newImage.node);
                if (response) {
                    this.setState({ loading: false, loadingIndex: -1 });

                    this.setState(() => ({
                        file: file,
                    }));
                }
            }

            reader.readAsDataURL(file);
        } else {
            this.props.onImageChange(null, { error: { type: "size" } });
            this.setState({ loading: false, loadingIndex: -1 });
        }
    }

    render() {

        const { images } = this.props

        return (
            <div className="add-product d-flex justify-content-center mb-3">
                <div className="pt-1 bigImg">
                    <span class="file-wrapper">
                        <input style={{
                            position: "absolute",
                            width: "325px",
                            height: "325px",
                            left: "0",
                            right: "0",
                            opacity: "0",
                            cursor: "pointer"
                        }} type="file" onChange={(e) => this._handleImgChange(e, images.edges[0], 0)} />
                        <div style={{
                            display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "325px",
                            maxHeight: "325px"
                        }}>
                            {this.state.loadingIndex === 0 ?
                                (
                                    <Loader size={325} />
                                )
                                : (
                                    <img src={images.edges.length > 0 ? images.edges[0].node.src ? images.edges[0].node.src : images.edges[0].node.attachment : one}
                                        alt=""
                                        className="image_zoom_1 blur-up lazyloaded"
                                        style={{ maxWidth: '325px', maxHeight: '325px' }}
                                    />
                                )}
                        </div>
                    </span>
                    < div style={{ display: "flex", justifyContent: "center" }}>
                        <span class="file-wrapper">
                            <input type="file" name="photo" id="photo" style={{
                                cursor: "pointer",
                                opacity: "0.01",
                                position: "absolute",
                                height: "100%",
                                right: 0,
                                top: 0
                            }} onChange={(e) => this._handleImgChange(e, images.edges[0], 0)} />
                            <span class="btn btn-custom-link" style={{
                                display: "inline-block"
                            }}>Edit</span>
                        </span>
                    </div>

                    <a id="result1" onClick={(e) => this._handleSubmit(e.target.id)}></a>
                </div>
                <div
                    className="block-file-upload"
                    style={{ marginTop: '5px', marginLeft: '25px' }}
                >
                    <ul className="file-upload-product">
                        {
                            images.edges.map((res, i) => {
                                if (i > 0) {
                                    return (
                                        <li key={i} style={{ position: "relative" }}>
                                            <div className="" >
                                                {this.state.loadingIndex === i ?
                                                    <Loader size={45}/> :
                                                    <>
                                                        <label for={`file-input-${i}`} className="label-file-wrapper" style={{
                                                            cursor: "pointer",
                                                            width: 50,
                                                            height: 50,
                                                            border: "1px solid #dddddd",
                                                            borderRadius: 4,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            margin: 0
                                                        }}>
                                                            <div className="pdp-edit-image" style={{
                                                                maxWidth: "45px", maxHeight: "45px",
                                                                background: `url("${res.node.src ? res.node.src : res.node.attachment}") no-repeat center`,
                                                                backgroundSize: "contain",
                                                                // backgroundRepeat: "no-repeat",
                                                                top: 0, bottom: 0, left: 0, right: 0,
                                                                position: "absolute",
                                                                margin: "auto",
                                                                // zIndex: 1
                                                            }} alt="Input file" />
                                                        </label>
                                                        <input className="upload" id={`file-input-${i}`} type="file" style={{
                                                            height: "100%",
                                                            width: "100%",
                                                            display: "none"
                                                        }} onChange={(e) => this._handleImgChange(e, res, i)} />
                                                    </>
                                                }
                                                <a id="result1" onClick={(e) => this._handleSubmit(e.target.id)}></a>
                                                {/* </span> */}
                                            </div>

                                            <CloseIcon onClick={() => this.props.deleteImage(res.node.id)} style={{
                                                position: "absolute",
                                                zIndex: 3,
                                                right: -8,
                                                top: -8,
                                                width: 15,
                                                height: 15,
                                                cursor: "pointer"
                                            }} />
                                        </li>
                                    )
                                }
                            })
                        }
                        {images.edges.length < 6 &&
                            <li key="new-image">
                                <div className="file-wrapper" style={{
                                    border: "1px solid #dddddd",
                                    borderRadius: 4,
                                    width: 50,
                                    height: 50,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <input className="upload" type="file" onChange={(e) => this._handleNewImg(e)} style={{
                                        cursor: "pointer",
                                        opacity: "0.01",
                                        position: "absolute",
                                        height: "100%",
                                        right: 0,
                                        top: 0
                                    }} />
                                    {this.state.loadingIndex === images.edges.length ? <Loader /> : <img src={addIcon} style={{ width: "30px", height: "30px" }} />}
                                    <a id="result1" onClick={(e) => this._handleSubmit(e.target.id)}></a>
                                </div>
                            </li>
                        }
                    </ul>
                </div>
            </div >
        )
    }
}

export default ProductPhotoUpload;
