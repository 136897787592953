
const ShopLocale = {
  "name": "ShopLocale",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "locale": "String",
    "marketWebPresences": "MarketWebPresence",
    "name": "String",
    "primary": "Boolean",
    "published": "Boolean"
  },
  "implementsNode": false
};
export default ShopLocale;