
const SubscriptionDiscountEdge = {
  "name": "SubscriptionDiscountEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "SubscriptionDiscount"
  },
  "implementsNode": false
};
export default SubscriptionDiscountEdge;