
const StagedUploadTargetGeneratePayload = {
  "name": "StagedUploadTargetGeneratePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "parameters": "MutationsStagedUploadTargetGenerateUploadParameter",
    "url": "String",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default StagedUploadTargetGeneratePayload;