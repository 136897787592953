
const DeliveryCountryAndZone = {
  "name": "DeliveryCountryAndZone",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "country": "DeliveryCountry",
    "zone": "String"
  },
  "implementsNode": false
};
export default DeliveryCountryAndZone;