
const ErrorPosition = {
  "name": "ErrorPosition",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "character": "Int",
    "line": "Int"
  },
  "implementsNode": false
};
export default ErrorPosition;