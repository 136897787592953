
const AppSubscriptionEdge = {
  "name": "AppSubscriptionEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "AppSubscription"
  },
  "implementsNode": false
};
export default AppSubscriptionEdge;