
const ValidationUpdateInput = {
  "name": "ValidationUpdateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "enable": "Boolean",
    "blockOnFailure": "Boolean",
    "metafields": "MetafieldInput",
    "title": "String"
  }
};
export default ValidationUpdateInput;