
const ProductVariantComponent = {
  "name": "ProductVariantComponent",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "productVariant": "ProductVariant",
    "quantity": "Int"
  },
  "implementsNode": true
};
export default ProductVariantComponent;