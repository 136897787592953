
const AppFeedback = {
  "name": "AppFeedback",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "app": "App",
    "link": "Link",
    "messages": "UserError"
  },
  "implementsNode": false
};
export default AppFeedback;