
const CartCustomDiscountAllocation = {
  "name": "CartCustomDiscountAllocation",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "discountedAmount": "MoneyV2",
    "title": "String"
  },
  "implementsNode": false
};
export default CartCustomDiscountAllocation;