
const FileError = {
  "name": "FileError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "FileErrorCode",
    "details": "String",
    "message": "String"
  },
  "implementsNode": false
};
export default FileError;