
const ProductOperation = {
  "name": "ProductOperation",
  "kind": "INTERFACE",
  "fieldBaseTypes": {
    "product": "Product",
    "status": "ProductOperationStatus"
  },
  "possibleTypes": ["ProductSetOperation"]
};
export default ProductOperation;