
const CustomerMergePreviewDefaultFields = {
  "name": "CustomerMergePreviewDefaultFields",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "addresses": "MailingAddressConnection",
    "defaultAddress": "MailingAddress",
    "discountNodeCount": "UnsignedInt64",
    "discountNodes": "DiscountNodeConnection",
    "displayName": "String",
    "draftOrderCount": "UnsignedInt64",
    "draftOrders": "DraftOrderConnection",
    "email": "CustomerEmailAddress",
    "firstName": "String",
    "giftCardCount": "UnsignedInt64",
    "giftCards": "GiftCardConnection",
    "lastName": "String",
    "metafieldCount": "UnsignedInt64",
    "note": "String",
    "orderCount": "UnsignedInt64",
    "orders": "OrderConnection",
    "phoneNumber": "CustomerPhoneNumber",
    "tags": "String"
  },
  "implementsNode": false
};
export default CustomerMergePreviewDefaultFields;