
const CheckoutBrandingUpsertPayload = {
  "name": "CheckoutBrandingUpsertPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkoutBranding": "CheckoutBranding",
    "userErrors": "CheckoutBrandingUpsertUserError"
  },
  "implementsNode": false
};
export default CheckoutBrandingUpsertPayload;