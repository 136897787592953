
const Order = {
  "name": "Order",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "additionalFees": "AdditionalFee",
    "agreements": "SalesAgreementConnection",
    "alerts": "ResourceAlert",
    "app": "OrderApp",
    "billingAddress": "MailingAddress",
    "billingAddressMatchesShippingAddress": "Boolean",
    "canMarkAsPaid": "Boolean",
    "canNotifyCustomer": "Boolean",
    "cancelReason": "OrderCancelReason",
    "cancellation": "OrderCancellation",
    "cancelledAt": "DateTime",
    "capturable": "Boolean",
    "cartDiscountAmount": "Money",
    "cartDiscountAmountSet": "MoneyBag",
    "channel": "Channel",
    "channelInformation": "ChannelInformation",
    "clientIp": "String",
    "closed": "Boolean",
    "closedAt": "DateTime",
    "confirmationNumber": "String",
    "confirmed": "Boolean",
    "createdAt": "DateTime",
    "currencyCode": "CurrencyCode",
    "currentCartDiscountAmountSet": "MoneyBag",
    "currentSubtotalLineItemsQuantity": "Int",
    "currentSubtotalPriceSet": "MoneyBag",
    "currentTaxLines": "TaxLine",
    "currentTotalAdditionalFeesSet": "MoneyBag",
    "currentTotalDiscountsSet": "MoneyBag",
    "currentTotalDutiesSet": "MoneyBag",
    "currentTotalPriceSet": "MoneyBag",
    "currentTotalTaxSet": "MoneyBag",
    "currentTotalWeight": "UnsignedInt64",
    "customAttributes": "Attribute",
    "customer": "Customer",
    "customerAcceptsMarketing": "Boolean",
    "customerJourney": "CustomerJourney",
    "customerJourneySummary": "CustomerJourneySummary",
    "customerLocale": "String",
    "discountApplications": "DiscountApplicationConnection",
    "discountCode": "String",
    "discountCodes": "String",
    "displayAddress": "MailingAddress",
    "displayFinancialStatus": "OrderDisplayFinancialStatus",
    "displayFulfillmentStatus": "OrderDisplayFulfillmentStatus",
    "disputes": "OrderDisputeSummary",
    "edited": "Boolean",
    "email": "String",
    "estimatedTaxes": "Boolean",
    "events": "EventConnection",
    "exchangeV2s": "ExchangeV2Connection",
    "fulfillable": "Boolean",
    "fulfillmentOrders": "FulfillmentOrderConnection",
    "fulfillments": "Fulfillment",
    "fullyPaid": "Boolean",
    "hasTimelineComment": "Boolean",
    "id": "ID",
    "landingPageDisplayText": "String",
    "landingPageUrl": "URL",
    "legacyResourceId": "UnsignedInt64",
    "lineItems": "LineItemConnection",
    "lineItemsMutable": "LineItemMutableConnection",
    "localizationExtensions": "LocalizationExtensionConnection",
    "location": "String",
    "merchantEditable": "Boolean",
    "merchantEditableErrors": "String",
    "merchantOfRecordApp": "OrderApp",
    "metafield": "Metafield",
    "metafieldDefinitions": "MetafieldDefinitionConnection",
    "metafields": "MetafieldConnection",
    "name": "String",
    "netPayment": "Money",
    "netPaymentSet": "MoneyBag",
    "nonFulfillableLineItems": "LineItemConnection",
    "note": "String",
    "originalTotalAdditionalFeesSet": "MoneyBag",
    "originalTotalDutiesSet": "MoneyBag",
    "originalTotalPriceSet": "MoneyBag",
    "paymentCollectionDetails": "OrderPaymentCollectionDetails",
    "paymentGatewayNames": "String",
    "paymentTerms": "PaymentTerms",
    "phone": "String",
    "physicalLocation": "Location",
    "poNumber": "String",
    "presentmentCurrencyCode": "CurrencyCode",
    "privateMetafield": "PrivateMetafield",
    "privateMetafields": "PrivateMetafieldConnection",
    "processedAt": "DateTime",
    "publication": "Publication",
    "purchasingEntity": "PurchasingEntity",
    "referralCode": "String",
    "referrerDisplayText": "String",
    "referrerUrl": "URL",
    "refundDiscrepancySet": "MoneyBag",
    "refundable": "Boolean",
    "refunds": "Refund",
    "registeredSourceUrl": "URL",
    "requiresShipping": "Boolean",
    "restockable": "Boolean",
    "returnStatus": "OrderReturnStatus",
    "returns": "ReturnConnection",
    "risk": "OrderRiskSummary",
    "riskLevel": "OrderRiskLevel",
    "risks": "OrderRisk",
    "shippingAddress": "MailingAddress",
    "shippingLine": "ShippingLine",
    "shippingLines": "ShippingLineConnection",
    "shopifyProtect": "ShopifyProtectOrderSummary",
    "sourceIdentifier": "String",
    "subtotalLineItemsQuantity": "Int",
    "subtotalPrice": "Money",
    "subtotalPriceSet": "MoneyBag",
    "suggestedRefund": "SuggestedRefund",
    "tags": "String",
    "taxExempt": "Boolean",
    "taxLines": "TaxLine",
    "taxesIncluded": "Boolean",
    "test": "Boolean",
    "totalCapturable": "Money",
    "totalCapturableSet": "MoneyBag",
    "totalDiscounts": "Money",
    "totalDiscountsSet": "MoneyBag",
    "totalOutstandingSet": "MoneyBag",
    "totalPrice": "Money",
    "totalPriceSet": "MoneyBag",
    "totalReceived": "Money",
    "totalReceivedSet": "MoneyBag",
    "totalRefunded": "Money",
    "totalRefundedSet": "MoneyBag",
    "totalRefundedShippingSet": "MoneyBag",
    "totalShippingPrice": "Money",
    "totalShippingPriceSet": "MoneyBag",
    "totalTax": "Money",
    "totalTaxSet": "MoneyBag",
    "totalTipReceived": "MoneyV2",
    "totalTipReceivedSet": "MoneyBag",
    "totalWeight": "UnsignedInt64",
    "transactions": "OrderTransaction",
    "unpaid": "Boolean",
    "updatedAt": "DateTime"
  },
  "implementsNode": true
};
export default Order;