import React, { Component, Fragment } from 'react'
import LoginTabset from './loginTabset';
import { AuthContext } from "../../auth/auth";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logoImg from  '../../assets/images/RSP_Logo_Dashboard.svg';

export class Login extends Component {
    componentDidMount() {
        if (this.context.loggedIn) {
            const { history } = this.props;
            history.push('/products');
        }
    }
    render() {
        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="authentication-box">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5 p-0 m-0 card-left">
                                    <div className="welcome-area text-center">
                                        <div>
                                            <img src={logoImg} className="image-logo" alt="Logo" />
                                        </div>
                                        <div>
                                            <div className="image-text">Ready.Set.Print.</div> 
                                        </div>
                                        <p>
                                            Customize your storefront, choose from hundreds of ready-to-go products, set your pricing and hit save. It's never been easier to customize and build your own web to print online store.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-7 pb-0 card card-right">
                                    <div className="card-body">
                                        <LoginTabset loginUser={this.context.loginUser}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

Login.contextType = AuthContext;

export default Login
