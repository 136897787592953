
const AbandonedCheckoutLineItem = {
  "name": "AbandonedCheckoutLineItem",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customAttributes": "Attribute",
    "discountedTotalPriceSet": "MoneyBag",
    "discountedTotalPriceWithCodeDiscount": "MoneyBag",
    "discountedUnitPriceSet": "MoneyBag",
    "discountedUnitPriceWithCodeDiscount": "MoneyBag",
    "id": "ID",
    "image": "Image",
    "originalTotalPriceSet": "MoneyBag",
    "originalUnitPriceSet": "MoneyBag",
    "product": "Product",
    "quantity": "Int",
    "sku": "String",
    "title": "String",
    "variant": "ProductVariant",
    "variantTitle": "String"
  },
  "implementsNode": true
};
export default AbandonedCheckoutLineItem;