
const SegmentMigration = {
  "name": "SegmentMigration",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "savedSearchId": "ID",
    "segmentId": "ID"
  },
  "implementsNode": false
};
export default SegmentMigration;