
const OrderRiskAssessmentCreatePayload = {
  "name": "OrderRiskAssessmentCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "orderRiskAssessment": "OrderRiskAssessment",
    "userErrors": "OrderRiskAssessmentCreateUserError"
  },
  "implementsNode": false
};
export default OrderRiskAssessmentCreatePayload;