
const SellingPlanGroupOption = {
  "name": "SellingPlanGroupOption",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "name": "String",
    "values": "String"
  },
  "implementsNode": false
};
export default SellingPlanGroupOption;