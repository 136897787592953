
const LocationAddUserError = {
  "name": "LocationAddUserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "LocationAddUserErrorCode",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
export default LocationAddUserError;