
const SubscriptionDeliveryMethodPickupOptionInput = {
  "name": "SubscriptionDeliveryMethodPickupOptionInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "title": "String",
    "presentmentTitle": "String",
    "description": "String",
    "code": "String",
    "locationId": "ID"
  }
};
export default SubscriptionDeliveryMethodPickupOptionInput;