
const Media = {
  "name": "Media",
  "kind": "INTERFACE",
  "fieldBaseTypes": {
    "alt": "String",
    "id": "ID",
    "mediaContentType": "MediaContentType",
    "presentation": "MediaPresentation",
    "previewImage": "Image"
  },
  "possibleTypes": ["ExternalVideo", "MediaImage", "Model3d", "Video"]
};
export default Media;