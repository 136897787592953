
const Collection = {
  "name": "Collection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "availablePublicationsCount": "Count",
    "description": "String",
    "descriptionHtml": "HTML",
    "feedback": "ResourceFeedback",
    "handle": "String",
    "hasProduct": "Boolean",
    "id": "ID",
    "image": "Image",
    "legacyResourceId": "UnsignedInt64",
    "metafield": "Metafield",
    "metafieldDefinitions": "MetafieldDefinitionConnection",
    "metafields": "MetafieldConnection",
    "privateMetafield": "PrivateMetafield",
    "privateMetafields": "PrivateMetafieldConnection",
    "products": "ProductConnection",
    "productsCount": "Count",
    "publicationCount": "Int",
    "publications": "CollectionPublicationConnection",
    "publishedOnChannel": "Boolean",
    "publishedOnCurrentChannel": "Boolean",
    "publishedOnCurrentPublication": "Boolean",
    "publishedOnPublication": "Boolean",
    "resourcePublications": "ResourcePublicationConnection",
    "resourcePublicationsCount": "Count",
    "resourcePublicationsV2": "ResourcePublicationV2Connection",
    "ruleSet": "CollectionRuleSet",
    "seo": "SEO",
    "sortOrder": "CollectionSortOrder",
    "storefrontId": "StorefrontID",
    "templateSuffix": "String",
    "title": "String",
    "translations": "Translation",
    "unpublishedChannels": "ChannelConnection",
    "unpublishedPublications": "PublicationConnection",
    "updatedAt": "DateTime"
  },
  "implementsNode": true
};
export default Collection;