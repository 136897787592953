
const DiscountRedeemCodeBulkCreation = {
  "name": "DiscountRedeemCodeBulkCreation",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "codes": "DiscountRedeemCodeBulkCreationCodeConnection",
    "codesCount": "Int",
    "createdAt": "DateTime",
    "discountCode": "DiscountCodeNode",
    "done": "Boolean",
    "failedCount": "Int",
    "id": "ID",
    "importedCount": "Int"
  },
  "implementsNode": true
};
export default DiscountRedeemCodeBulkCreation;