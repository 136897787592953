
const CompanyLocationRevokeRolesPayload = {
  "name": "CompanyLocationRevokeRolesPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "revokedRoleAssignmentIds": "ID",
    "userErrors": "BusinessCustomerUserError"
  },
  "implementsNode": false
};
export default CompanyLocationRevokeRolesPayload;