
const ProductVariantsBulkReorderPayload = {
  "name": "ProductVariantsBulkReorderPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "product": "Product",
    "userErrors": "ProductVariantsBulkReorderUserError"
  },
  "implementsNode": false
};
export default ProductVariantsBulkReorderPayload;