
const MetafieldStorefrontVisibility = {
  "name": "MetafieldStorefrontVisibility",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "createdAt": "DateTime",
    "id": "ID",
    "key": "String",
    "legacyResourceId": "UnsignedInt64",
    "namespace": "String",
    "ownerType": "MetafieldOwnerType",
    "updatedAt": "DateTime"
  },
  "implementsNode": true
};
export default MetafieldStorefrontVisibility;