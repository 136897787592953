import { storefrontToAdminId, adminToStorefrontId, validatePricingJson } from './index'
import fetch from "./fetchWithTimeout";
import { toast } from 'react-toastify';

var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

export const storefrontStructureToAdminStructure = (product, newProduct = false) => {

    let variants = []
    product.variants.edges.forEach(variant => {
        
        let newVariant = {
            sku: variant.node.sku,
            price: variant.node.price.amount,
            inventoryPolicy: newProduct ? 'CONTINUE' : variant.node.inventoryPolicy,
            weight: variant.node.weight,
            weight_unit: variant.node.weightUnit,
            selectedOptions: variant.node.selectedOptions,
            barcode:variant.node.barcode,
            title: variant.node.title
        }
        // variant.node.selectedOptions.forEach((option, index) => {
        //     newVariant[`option${index + 1}`] = option.value
        // })
        variants.push(newVariant)
    })
    let productId = product.id

    if (base64regex.test(product.id)) {
        productId = atob(product.id)
    }
    const adminProduct = {
        id: storefrontToAdminId(productId, 'Product'),
        body_html: product.descriptionHtml,
        handle: product.handle,
        images: product.images.edges.map(image => {
            const newImage = {}
            if (image.node.id) newImage.id = storefrontToAdminId(image.node.id, 'ProductImage')
            if (image.node.src) newImage.src = image.node.src
            if (image.node.attachment) {
                newImage.attachment = image.node.attachment.split(',')[1]
            }
            return newImage
        }),
        product_type: product.productType,
        title: product.title,
        variants,
        metafields_global_title_tag: typeof (product.seoTitle) === "string" ? product.seoTitle.substring(0, 72) : "",
        metafields_global_description_tag: typeof (product.seoDescription) === "string" ? product.seoDescription.substring(0, 320) : "",
        tags: product.tags,
        options: product.options,
        vendor: product.vendor

    }
    return adminProduct
}

export const createProduct = async (product, handle, context) => {
    product.handle = `${handle}-${product.handle}`
    product.tags.push(`Base_Product=${product.id}`)
    product.vendor = context.store_subdomain

    delete product.id

    product.images = product.images.map(i => { return { src: i.src } });

    let r = null;
    try {
        const response = await fetch('/.netlify/functions/server/api/add-product', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ product, subdomain: context.store_subdomain})
        });


        if (!response.ok) throw response;

        r = await response.json();
    } catch (err) {
        //Show response error
        if (err.message) {
            toast.error(`${err.message}`);
        } else {
            toast.error(`${err.status}: ${err.statusText}`);
        }
    }
    return r.product;
}

export const updateProductVariant = async (product, createdProduct, context) => {

    product.vendor = context.store_subdomain

    delete product.id

    let r = null;
    try {
        const response = await fetch('/.netlify/functions/server/api/update-product-variant', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ product, createdProduct, subdomain: context.store_subdomain })
        });


        if (!response.ok) throw response;

        r = await response.json();
    } catch (err) {
        //Show response error
        if (err.message) {
            toast.error(`${err.message}`);
        } else {
            toast.error(`${err.status}: ${err.statusText}`);
        }
    }

    return r
}

export const createProductVariants = async (product, createdProduct, context) => {

    product.vendor = context.store_subdomain

    delete product.id

    let r = null;
    const publications = typeof context.publications === "string" ? JSON.parse(context.publications) : context.publications;
    try {
        const response = await fetch('/.netlify/functions/server/api/add-product-variants', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ product, createdProduct, subdomain: context.store_subdomain })
        });


        if (!response.ok) throw response;

        r = createdProduct;
    } catch (err) {
        //Show response error
        if (err.message) {
            toast.error(`${err.message}`);
        } else {
            toast.error(`${err.status}: ${err.statusText}`);
        }
    }

    const productToPublish = {
        ...r,
        id: r.id
    }
    try {
        const publishResponse = await fetch('/.netlify/functions/server/api/publish-item', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ item: productToPublish, publications })
        });

        if (!publishResponse.ok) {
            throw publishResponse;
        }
    } catch (err) {
        //Show response error
        if (err.message) {
            toast.error(`${err.message}`);
        } else {
            toast.error(`${err.status}: ${err.statusText}`);
        }
    }

    return r
}

export const updateProduct = async (product) => {

    const adminFriendlyProduct = storefrontStructureToAdminStructure(product);

    const response = await fetch('/.netlify/functions/server/api/update-product', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ product: adminFriendlyProduct })
    });
    try {
        if (!response.ok) throw response;
        const updatedProduct = await response.json();

        return updatedProduct;
    }
    catch (err) {
        //Show response error
        if (err.message) {
            toast.error(`${err.message}`);
        } else {
            toast.error(`${err.status}: ${err.statusText}`);
        }

        return null;
    }

}


export const deleteProduct = async (productId) => {

    try {

        const response = await fetch('/.netlify/functions/server/api/delete-product', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ productId })
        });
        if (!response.ok) throw response;
        const deleted = await response.json()
        return deleted
    } catch (err) {
        //Show response error
        if (err.message) {
            toast.error(`${err.message}`);
        } else {
            toast.error(`${err.status}: ${err.statusText}`);
        }
    }
}

export const activateProduct = async (product, context) => {
    //clone product
    product.vendor = context.store_subdomain;
    console.log('activate product', product);
    const globalMarkup = context.globalMarkup;
    let markupTag = "";
    if (globalMarkup && globalMarkup !== "undefined") {
        markupTag = globalMarkup;
    } else if (localStorage.getItem('whitelabelGlobalMarkup') && localStorage.getItem('whitelabelGlobalMarkup') !== "undefined") {
        markupTag = localStorage.getItem('whitelabelGlobalMarkup');
    } else {
        markupTag = "0";
    }

    product.tags.push(`markup=${markupTag}`);
    const adminFriendlyProduct = storefrontStructureToAdminStructure(product, true)
    adminFriendlyProduct.metafields_global_title_tag = adminFriendlyProduct.title.substring(0, 72);
    adminFriendlyProduct.metafields_global_description_tag = product.description.substring(0, 320);
    const newProduct = await createProduct(adminFriendlyProduct, context.reseller_collection_handle, context);
    const updatedProductVariant = await updateProductVariant(adminFriendlyProduct, newProduct, context);
    const newProductVariants = await createProductVariants(adminFriendlyProduct, newProduct, context);
    if (context && newProduct) {

        let { products, activeProducts, setContextProducts } = context
        const productIndex = products.findIndex(p => p.node.id === product.id)
        product = {
            ...product,
            id: newProduct.id,
            handle: newProduct.handle
        }
        if (base64regex.test(product.id)) {
            product.id = atob(product.id);
        }
        if (productIndex > -1) {
            products.splice(productIndex, 1, { node: product })
            activeProducts.push({ node: product })
            setContextProducts({ activeProducts, products })
        }
    }

    return newProduct

}

export const deactivateProduct = async (product, context) => {

    let deletedProduct = null;
    try {
        deletedProduct = await deleteProduct(product.id)

    } catch (error) {
        console.log(`Error while deleting product: ${error}`)
        return error;
    }

    let { products, activeProducts, setContextProducts } = context

    let baseProductTag = product.tags.find(t => t.includes("Base_Product="))
    let baseProductId = "";
    if (baseProductTag) {
        baseProductId = baseProductTag.split("=")[1];
    }

    const fields = `id
        storefrontId
        title
        productType
        descriptionHtml
        description
        handle
        tags
        collections (first: 10) {
            edges {
                node {
                    id
                    title
                }
            }
        }
        metafields (first: 10) {
          edges {
            node {
              key
              value
            }
          }
        }
        variants (first: 100) {
            edges {
                node {
                    id
                    sku
                    inventoryPolicy
                    price
                    selectedOptions {
                        name
                        value
                    }
                }
            }
        }
        options {
            id
            name
        }
        images (first: 10) {
            edges {
                node {
                    id
                    src
                }
            }    
        }`
    const response = await fetch('/.netlify/functions/server/api/get-product-by-id', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: `gid://shopify/Product/${baseProductId}`, fields })
    });

    try {
        if (!response.ok) throw response;

        const data = await response.json();

        const productIndex = activeProducts.findIndex(p => p.node.id === product.id)
        if (productIndex > -1) {
            activeProducts.splice(productIndex, 1)
            if (data && data.data.product) {
                let pIndex = products.findIndex(p => p.node.id === product.id)

                if (pIndex > -1) products[pIndex].node = data.data.product
            }
            setContextProducts({ activeProducts, products })
        }
        return deletedProduct;
    }
    catch (err) {
        //Show response error
        if (err.message) {
            toast.error(`${err.message}`);
        } else {
            toast.error(`${err.status}: ${err.statusText}`);
        }

        return null;
    }
}

export const updateProductMarkup = async (product, markupInput) => {

    // 0. Validate params, default to a markup of 0 if invalid
    let markup = "0";
    const validMarkup = /^-?\d+$/;

    if (validMarkup.test(markupInput)) {
        markup = markupInput;
    }

    // 1. Update "markup=" product tag
    let priceGridHandle = "";
    let productTags = [];
    let hasMarkupTag = false;

    try {
        productTags = product.tags;

        if (productTags.length > 0) {
            productTags.forEach((tag, tagIndex) => {
                const markupIndex = tag.indexOf("markup=");
                if (markupIndex !== -1) {
                    productTags[tagIndex] = "markup=" + markup;
                    hasMarkupTag = true;
                }
                if (tag.indexOf("$=") !== -1) {
                    priceGridHandle = tag.split("$=")[1];
                }
            });
            if (!hasMarkupTag) {
                productTags.push("markup=" + markup);
            }
        } else {
            productTags.push("markup=" + markup);
        }

        const productTagsUpdated = await updateProductTags(storefrontToAdminId(product.id, "Product"), productTags);
        const priceGrid = await getPriceGrid(priceGridHandle);
        const { isJsonValid, errorMessage } = validatePricingJson(priceGrid, product.variants.edges);

        if (!isJsonValid) {
            if (process.env.REACT_APP_URL_PRICING_JSON) {
                toast.error(`Pricing JSON contains errors: \n${errorMessage}`, { className: "pricing-error" });
            } else {
                console.log(`Pricing JSON contains errors: \n${errorMessage}`);
            }
        }

        // 2. Update all variant prices based on the related price grid
        if (productTagsUpdated && priceGrid && priceGridHandle !== "") {
            const variants = product.variants.edges;

            let errorUpdatingVariant = false;

            if (variants) {
                for (const variant of variants) {
                    const splittedSku = variant.node.sku.split("-")
                    const variantBarcode = `net_${splittedSku[splittedSku.length - 1]}`;
                    const variantId = variant.node.id;

                    if (priceGrid[variantBarcode]) {
                        const newPrice = (priceGrid[variantBarcode][0] * ((markup / 100) + 1) * priceGrid["msrp_markup_multiplier"][0]).toFixed(2);

                        const variantPricesUpdated = await updateVariantPrice(variantId, newPrice);
                        variant.node.price = newPrice
                        if (!variantPricesUpdated) {
                            errorUpdatingVariant = true;
                        }
                    }
                }

                product = {
                    ...product,
                    variants: { edges: variants }
                }

                if (!errorUpdatingVariant) {
                    // Process completed successfully
                    return product;
                } else {
                    return null
                }
            }
        }
        throw new Error("updateProductMarkup failed");
    } catch (error) {
        console.error(error.message);
    }
}


const updateProductTags = async (productId, productTags) => {
    const updatedProductTags = await fetch('/.netlify/functions/server/api/update-product-tags', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            productId: productId,
            tags: productTags,
        }),
    })
        .then(response => {
            if (!response.ok) throw response;
            return response.json()
        })
        .then(json => {
            if (json.data.productUpdate && json.data.productUpdate.userErrors.length === 0) {
                return true;
            }
            throw new Error("Product tags failed to update");
        })
        .catch((err) => {
            //Show response error
            if (err.message) {
                toast.error(`${err.message}`);
            } else {
                toast.error(`${err.status}: ${err.statusText}`);
            }
        });
    return updatedProductTags;
}



const updateVariantPrice = async (variantId, newPrice) => {
    const variantPrice = await fetch('/.netlify/functions/server/api/update-variant-price', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            variantId: variantId,
            newPrice: newPrice,
        }),
    })
        .then(response => {
            if (!response.ok) throw response;
            return response.json()
        })
        .then(json => {
            if (json.data && !json.data.userErrors) {
                return true;
            }
            return false;
        })
        .catch((err) => {
            if (err.message) {
                toast.error(`${err.message}`);
            } else {
                toast.error(`${err.status}: ${err.statusText}`);
            }
        });
    return variantPrice;
}



export const getPriceGrid = async (handle) => {

    const url = process.env.REACT_APP_URL_PRICING_JSON ? process.env.REACT_APP_URL_PRICING_JSON : "https://designer.readysetprint.com/apps/shopify-json/pricing";
    const priceGrid = await fetch(`${url}/${handle}.json`)
        .then(response => response.json())
        .then(json => {
            return json;
        })
        .catch(() => {
            return false;
        });

    if (priceGrid) {
        return priceGrid;
    }
    throw new Error(`getPriceGrid failed.\nPlease check that JSON pricing grid exists with name ${handle}.json`);
}



export const getProductInfo = async (productId, fields) => { // Returns either false or the specified product info
    const productInfo = await fetch('/.netlify/functions/server/api/get-product', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            productId: productId,
            fields: fields,
        })
    })
        .then(response => {
            if (!response.ok) throw response;
            return response.json()
        })
        .then(json => {
            return json;
        })
        .catch((err) => {
            if (err.message) {
                toast.error(`${err.message}`);
            } else {
                toast.error(`${err.status}: ${err.statusText}`);
            }

            return false;
        });

    if (productInfo) {
        return productInfo;
    }
    throw new Error("getProductInfo failed");
}