
const InventoryItemUpdatePayload = {
  "name": "InventoryItemUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "inventoryItem": "InventoryItem",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default InventoryItemUpdatePayload;