
const DraftOrderLineItem = {
  "name": "DraftOrderLineItem",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "appliedDiscount": "DraftOrderAppliedDiscount",
    "custom": "Boolean",
    "customAttributes": "Attribute",
    "customAttributesV2": "TypedAttribute",
    "discountedTotal": "Money",
    "discountedTotalSet": "MoneyBag",
    "discountedUnitPrice": "Money",
    "discountedUnitPriceSet": "MoneyBag",
    "fulfillmentService": "FulfillmentService",
    "grams": "Int",
    "id": "ID",
    "image": "Image",
    "isGiftCard": "Boolean",
    "name": "String",
    "originalTotal": "Money",
    "originalTotalSet": "MoneyBag",
    "originalUnitPrice": "Money",
    "originalUnitPriceSet": "MoneyBag",
    "product": "Product",
    "quantity": "Int",
    "requiresShipping": "Boolean",
    "sku": "String",
    "taxLines": "TaxLine",
    "taxable": "Boolean",
    "title": "String",
    "totalDiscount": "Money",
    "totalDiscountSet": "MoneyBag",
    "variant": "ProductVariant",
    "variantTitle": "String",
    "vendor": "String",
    "weight": "Weight"
  },
  "implementsNode": true
};
export default DraftOrderLineItem;