
const PriceListCreateInput = {
  "name": "PriceListCreateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "name": "String",
    "currency": "CurrencyCode",
    "parent": "PriceListParentCreateInput",
    "catalogId": "ID"
  }
};
export default PriceListCreateInput;