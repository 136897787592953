
const CheckoutBrandingColorRolesInput = {
  "name": "CheckoutBrandingColorRolesInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "background": "String",
    "text": "String",
    "border": "String",
    "icon": "String",
    "accent": "String",
    "decorative": "String"
  }
};
export default CheckoutBrandingColorRolesInput;