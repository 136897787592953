
const MetafieldDefinitionType = {
  "name": "MetafieldDefinitionType",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "category": "String",
    "name": "String",
    "supportedValidations": "MetafieldDefinitionSupportedValidation",
    "supportsDefinitionMigrations": "Boolean",
    "valueType": "MetafieldValueType"
  },
  "implementsNode": false
};
export default MetafieldDefinitionType;