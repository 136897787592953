
const MarketCatalog = {
  "name": "MarketCatalog",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "markets": "MarketConnection",
    "operations": "ResourceOperation",
    "priceList": "PriceList",
    "publication": "Publication",
    "status": "CatalogStatus",
    "title": "String"
  },
  "implementsNode": true
};
export default MarketCatalog;