
const ProductOptionUpdatePayload = {
  "name": "ProductOptionUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "product": "Product",
    "userErrors": "ProductOptionUpdateUserError"
  },
  "implementsNode": false
};
export default ProductOptionUpdatePayload;