
const CartCost = {
  "name": "CartCost",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkoutChargeAmount": "MoneyV2",
    "subtotalAmount": "MoneyV2",
    "subtotalAmountEstimated": "Boolean",
    "totalAmount": "MoneyV2",
    "totalAmountEstimated": "Boolean",
    "totalDutyAmount": "MoneyV2",
    "totalDutyAmountEstimated": "Boolean",
    "totalTaxAmount": "MoneyV2",
    "totalTaxAmountEstimated": "Boolean"
  },
  "implementsNode": false
};
export default CartCost;