
const TaxLine = {
  "name": "TaxLine",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "channelLiable": "Boolean",
    "price": "Money",
    "priceSet": "MoneyBag",
    "rate": "Float",
    "ratePercentage": "Float",
    "title": "String"
  },
  "implementsNode": false
};
export default TaxLine;