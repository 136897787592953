
const ReturnAgreement = {
  "name": "ReturnAgreement",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "app": "App",
    "happenedAt": "DateTime",
    "id": "ID",
    "reason": "OrderActionType",
    "return": "Return",
    "sales": "SaleConnection",
    "user": "StaffMember"
  },
  "implementsNode": false
};
export default ReturnAgreement;