
const MetafieldFilter = {
  "name": "MetafieldFilter",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "namespace": "String",
    "key": "String",
    "value": "String"
  }
};
export default MetafieldFilter;