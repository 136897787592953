
const CustomerCancelDataErasurePayload = {
  "name": "CustomerCancelDataErasurePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customerId": "ID",
    "userErrors": "CustomerCancelDataErasureUserError"
  },
  "implementsNode": false
};
export default CustomerCancelDataErasurePayload;