
const FulfillmentOriginAddressInput = {
  "name": "FulfillmentOriginAddressInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "address1": "String",
    "address2": "String",
    "city": "String",
    "zip": "String",
    "provinceCode": "String",
    "countryCode": "String"
  }
};
export default FulfillmentOriginAddressInput;