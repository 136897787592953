
const SubscriptionBillingCycleScheduleEditInput = {
  "name": "SubscriptionBillingCycleScheduleEditInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "skip": "Boolean",
    "billingDate": "DateTime",
    "reason": "SubscriptionBillingCycleScheduleEditInputScheduleEditReason"
  }
};
export default SubscriptionBillingCycleScheduleEditInput;