
const CheckoutBrandingFooterInput = {
  "name": "CheckoutBrandingFooterInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "position": "CheckoutBrandingFooterPosition",
    "alignment": "CheckoutBrandingFooterAlignment",
    "content": "CheckoutBrandingFooterContentInput",
    "colorScheme": "CheckoutBrandingColorSchemeSelection",
    "padding": "CheckoutBrandingSpacingKeyword"
  }
};
export default CheckoutBrandingFooterInput;