
const DiscountCodeNodeConnection = {
  "name": "DiscountCodeNodeConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "DiscountCodeNodeEdge",
    "nodes": "DiscountCodeNode",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default DiscountCodeNodeConnection;