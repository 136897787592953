
const SearchResultConnection = {
  "name": "SearchResultConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "SearchResultEdge",
    "pageInfo": "PageInfo",
    "resultsAfterCount": "Int"
  },
  "implementsNode": false
};
export default SearchResultConnection;