
const SubscriptionBillingAttemptInput = {
  "name": "SubscriptionBillingAttemptInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "idempotencyKey": "String",
    "originTime": "DateTime",
    "billingCycleSelector": "SubscriptionBillingCycleSelector"
  }
};
export default SubscriptionBillingAttemptInput;