
const CheckoutBrandingButtonColorRolesInput = {
  "name": "CheckoutBrandingButtonColorRolesInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "background": "String",
    "text": "String",
    "border": "String",
    "icon": "String",
    "accent": "String",
    "decorative": "String",
    "hover": "CheckoutBrandingColorRolesInput"
  }
};
export default CheckoutBrandingButtonColorRolesInput;