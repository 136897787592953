
const SellingPlanGroupInput = {
  "name": "SellingPlanGroupInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "name": "String",
    "appId": "String",
    "merchantCode": "String",
    "description": "String",
    "sellingPlansToCreate": "SellingPlanInput",
    "sellingPlansToUpdate": "SellingPlanInput",
    "sellingPlansToDelete": "ID",
    "options": "String",
    "position": "Int"
  }
};
export default SellingPlanGroupInput;