
const CatalogConnection = {
  "name": "CatalogConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "CatalogEdge",
    "nodes": "Catalog",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default CatalogConnection;