
const MerchantApprovalSignals = {
  "name": "MerchantApprovalSignals",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "identityVerified": "Boolean",
    "verifiedByShopify": "Boolean",
    "verifiedByShopifyTier": "String"
  },
  "implementsNode": false
};
export default MerchantApprovalSignals;