
const ProductVariantRelationshipBulkUpdatePayload = {
  "name": "ProductVariantRelationshipBulkUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "parentProductVariants": "ProductVariant",
    "userErrors": "ProductVariantRelationshipBulkUpdateUserError"
  },
  "implementsNode": false
};
export default ProductVariantRelationshipBulkUpdatePayload;