
const DiscountCustomerGetsInput = {
  "name": "DiscountCustomerGetsInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "value": "DiscountCustomerGetsValueInput",
    "items": "DiscountItemsInput",
    "appliesOnOneTimePurchase": "Boolean",
    "appliesOnSubscription": "Boolean"
  }
};
export default DiscountCustomerGetsInput;