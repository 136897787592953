
const OrderPaymentStatus = {
  "name": "OrderPaymentStatus",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "errorMessage": "String",
    "paymentReferenceId": "String",
    "status": "OrderPaymentStatusResult",
    "transactions": "OrderTransaction",
    "translatedErrorMessage": "String"
  },
  "implementsNode": false
};
export default OrderPaymentStatus;