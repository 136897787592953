
const SubscriptionLineEdge = {
  "name": "SubscriptionLineEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "SubscriptionLine"
  },
  "implementsNode": false
};
export default SubscriptionLineEdge;