
const OrderCreateMandatePaymentUserError = {
  "name": "OrderCreateMandatePaymentUserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "OrderCreateMandatePaymentUserErrorCode",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
export default OrderCreateMandatePaymentUserError;