
const DraftOrderLineItemInput = {
  "name": "DraftOrderLineItemInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "appliedDiscount": "DraftOrderAppliedDiscountInput",
    "customAttributes": "AttributeInput",
    "originalUnitPrice": "Money",
    "quantity": "Int",
    "requiresShipping": "Boolean",
    "sku": "String",
    "taxable": "Boolean",
    "title": "String",
    "variantId": "ID",
    "weight": "WeightInput"
  }
};
export default DraftOrderLineItemInput;