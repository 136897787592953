export const storefrontToAdminId = (storefrontId, type) => {
    return storefrontId.replace(`gid://shopify/${type}/`, '')
}

export const adminToStorefrontId = (adminId, type) => {
    return btoa(`gid://shopify/${type}/${adminId}`)
}

export const validatePricingJson = (json, variants) => {
    let isJsonValid = true;
    let errorMessage = "";

    //Check if quantity_breaks element exists
    if (!json.quantity_breaks) {
        isJsonValid = false;
        errorMessage += "- quantity_breaks element is missing.";
    }
    //Check if msrp_markup_multiplier element exists
    if (!json.msrp_markup_multiplier) {
        isJsonValid = false;
        errorMessage += "\n- msrp_markup_multiplier element is missing.";
    }

    //Check that all net_[barcode] elements exists
    variants.forEach(variant => {
        const variantPrice = variant.node.price;
        const splittedSku = variant.node.sku.split("-");
        const variantBarcode = `net_${splittedSku[splittedSku.length - 1]}`;
        if (!json[variantBarcode]) {
            isJsonValid = false;
            errorMessage += `\n- ${variantBarcode} element is missing.`;
        } else {

            //Check that there are no elements in net_rows with higher pricing than the base variant
            const higherValueExists = json[variantBarcode].find(e => e > variantPrice);
            if (higherValueExists) {
                isJsonValid = false;
                errorMessage += `\n- There is an element in ${variantBarcode} with value ${higherValueExists} that is higher than the variant's based price ${variantPrice}`;
            }

            json[variantBarcode].forEach((barcode, index) => {

                const isInt = Number(barcode) === barcode && barcode % 1 === 0;
                const isFloat = Number(barcode) === barcode && barcode % 1 !== 0;

                if (!isInt && !isFloat) {
                    isJsonValid = false;
                    errorMessage += `\n- ${barcode} in position ${index + 1} of ${variantBarcode} is neither an integer or a float.`;
                }
            })
        }
    });

    //Check that all arrays are the same length
    let keyLength = -1;
    Object.keys(json).every(key => {
        if (keyLength !== -1) {
            if (json[key].length !== keyLength) {
                isJsonValid = false;
                errorMessage += `\n- Not all array elements have the same length`;
                return false;
            }
        }
        keyLength = json[key].length;
        return true;
    });

    //Check if quantity_breaks elements are integers
    if (json.quantity_breaks) {
        const quantity_breaks = json.quantity_breaks;
        quantity_breaks.every((quantity, index) => {
            const isInt = Number(quantity) === quantity && quantity % 1 === 0;
            if (!isInt) {
                isJsonValid = false;
                errorMessage += `\n- ${quantity} in position ${index + 1} of quantity_breaks is not an integer.`
                return false;
            }
            return true;
        });
    }

    //Check if msrp_markup_multiplier elements are integers or floats
    if (json.msrp_markup_multiplier) {
        const msrp_markup_multiplier = json.msrp_markup_multiplier;
        msrp_markup_multiplier.forEach((msrp, index) => {
            const isInt = Number(msrp) === msrp && msrp % 1 === 0;
            const isFloat = Number(msrp) === msrp && msrp % 1 !== 0;

            if (!isInt && !isFloat) {
                isJsonValid = false;
                errorMessage += `\n${msrp} in position ${index + 1} of msrp_markup_multiplier is neither an integer or a float.`
            }
        })
    }

    return {
        isJsonValid,
        errorMessage
    }
}

export const selectShopifyApiKey = (env, type = null) => {
    let envKeys = Object.keys(env);

    let keyName = "";

    switch (type) {
        case "product":
            keyName = "REACT_APP_SHOPIFY_ADMIN_PRODUCTS_ACCESS_TOKEN_";
            break;
        case "category":
            keyName = "REACT_APP_SHOPIFY_ADMIN_CATEGORIES_ACCESS_TOKEN_";
            break;

        default:
            keyName = "REACT_APP_SHOPIFY_ADMIN_ACCESS_TOKEN_";
            break;
    }

    envKeys = envKeys.filter(key => key.includes(keyName));

    let random = Math.floor(Math.random() * envKeys.length);
    
    let selectedKey = envKeys[random];
    
    return env[selectedKey];

}

export const generateShopifyApiHeader = (env, type = null) => {
    const envKey = selectShopifyApiKey(env, type);

    return env[envKey];

}