
const MetaobjectAccess = {
  "name": "MetaobjectAccess",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "admin": "MetaobjectAdminAccess",
    "storefront": "MetaobjectStorefrontAccess"
  },
  "implementsNode": false
};
export default MetaobjectAccess;