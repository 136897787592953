
const SegmentConnection = {
  "name": "SegmentConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "SegmentEdge",
    "nodes": "Segment",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default SegmentConnection;