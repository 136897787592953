
const SellingPlanRecurringPricingPolicy = {
  "name": "SellingPlanRecurringPricingPolicy",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "adjustmentType": "SellingPlanPricingPolicyAdjustmentType",
    "adjustmentValue": "SellingPlanPricingPolicyAdjustmentValue",
    "afterCycle": "Int",
    "createdAt": "DateTime"
  },
  "implementsNode": false
};
export default SellingPlanRecurringPricingPolicy;