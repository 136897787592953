
const TaxonomyMeasurementAttribute = {
  "name": "TaxonomyMeasurementAttribute",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "name": "String",
    "options": "Attribute"
  },
  "implementsNode": true
};
export default TaxonomyMeasurementAttribute;