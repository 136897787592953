
const CheckoutBrandingControlColorRoles = {
  "name": "CheckoutBrandingControlColorRoles",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "accent": "String",
    "background": "String",
    "border": "String",
    "decorative": "String",
    "icon": "String",
    "selected": "CheckoutBrandingColorRoles",
    "text": "String"
  },
  "implementsNode": false
};
export default CheckoutBrandingControlColorRoles;