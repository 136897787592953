
const ProductDeleteAsyncPayload = {
  "name": "ProductDeleteAsyncPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deleteProductId": "ID",
    "job": "Job",
    "userErrors": "ProductDeleteUserError"
  },
  "implementsNode": false
};
export default ProductDeleteAsyncPayload;