
const BaseCartLine = {
  "name": "BaseCartLine",
  "kind": "INTERFACE",
  "fieldBaseTypes": {
    "attribute": "Attribute",
    "attributes": "Attribute",
    "cost": "CartLineCost",
    "discountAllocations": "CartDiscountAllocation",
    "estimatedCost": "CartLineEstimatedCost",
    "id": "ID",
    "merchandise": "Merchandise",
    "quantity": "Int",
    "sellingPlanAllocation": "SellingPlanAllocation"
  },
  "possibleTypes": ["CartLine", "ComponentizableCartLine"]
};
export default BaseCartLine;