
const ReturnDecline = {
  "name": "ReturnDecline",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "note": "String",
    "reason": "ReturnDeclineReason"
  },
  "implementsNode": false
};
export default ReturnDecline;