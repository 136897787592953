
const CartDiscountCodesUpdatePayload = {
  "name": "CartDiscountCodesUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cart": "Cart",
    "userErrors": "CartUserError"
  },
  "implementsNode": false
};
export default CartDiscountCodesUpdatePayload;