
const NavigationItem = {
  "name": "NavigationItem",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "String",
    "title": "String",
    "url": "URL"
  },
  "implementsNode": false
};
export default NavigationItem;