
const CustomerSegmentMember = {
  "name": "CustomerSegmentMember",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "amountSpent": "MoneyV2",
    "defaultAddress": "MailingAddress",
    "defaultEmailAddress": "CustomerEmailAddress",
    "defaultPhoneNumber": "CustomerPhoneNumber",
    "displayName": "String",
    "firstName": "String",
    "id": "ID",
    "lastName": "String",
    "lastOrderId": "ID",
    "mergeable": "CustomerMergeable",
    "metafield": "Metafield",
    "metafields": "MetafieldConnection",
    "note": "String",
    "numberOfOrders": "UnsignedInt64",
    "privateMetafield": "PrivateMetafield",
    "privateMetafields": "PrivateMetafieldConnection"
  },
  "implementsNode": false
};
export default CustomerSegmentMember;