
const DiscountCodeBxgyCreatePayload = {
  "name": "DiscountCodeBxgyCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "codeDiscountNode": "DiscountCodeNode",
    "userErrors": "DiscountUserError"
  },
  "implementsNode": false
};
export default DiscountCodeBxgyCreatePayload;