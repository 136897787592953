
const __Type = {
  "name": "__Type",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "accessRestricted": "Boolean",
    "accessRestrictedReason": "String",
    "componentName": "String",
    "description": "String",
    "enumValues": "__EnumValue",
    "fields": "__Field",
    "inputFields": "__InputValue",
    "interfaces": "__Type",
    "isOneOf": "Boolean",
    "isPrivatelyDocumented": "Boolean",
    "isProtected": "Boolean",
    "kind": "__TypeKind",
    "name": "String",
    "ofType": "__Type",
    "possibleTypes": "__Type",
    "protectedSubject": "String",
    "requiredAccess": "String",
    "specifiedByURL": "String"
  },
  "implementsNode": false
};
export default __Type;