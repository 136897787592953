
const OrderCancelPayload = {
  "name": "OrderCancelPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "job": "Job",
    "orderCancelUserErrors": "OrderCancelUserError",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default OrderCancelPayload;