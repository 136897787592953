
const SellingPlanGroupConnection = {
  "name": "SellingPlanGroupConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "SellingPlanGroupEdge",
    "nodes": "SellingPlanGroup",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default SellingPlanGroupConnection;