
const InventoryBulkToggleActivationPayload = {
  "name": "InventoryBulkToggleActivationPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "inventoryItem": "InventoryItem",
    "inventoryLevels": "InventoryLevel",
    "userErrors": "InventoryBulkToggleActivationUserError"
  },
  "implementsNode": false
};
export default InventoryBulkToggleActivationPayload;