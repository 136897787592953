
const SellingPlanGroupAddProductVariantsPayload = {
  "name": "SellingPlanGroupAddProductVariantsPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "sellingPlanGroup": "SellingPlanGroup",
    "userErrors": "SellingPlanGroupUserError"
  },
  "implementsNode": false
};
export default SellingPlanGroupAddProductVariantsPayload;