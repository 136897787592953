
const MetafieldDefinitionUnpinPayload = {
  "name": "MetafieldDefinitionUnpinPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "unpinnedDefinition": "MetafieldDefinition",
    "userErrors": "MetafieldDefinitionUnpinUserError"
  },
  "implementsNode": false
};
export default MetafieldDefinitionUnpinPayload;