
const CartPaymentUpdatePayload = {
  "name": "CartPaymentUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cart": "Cart",
    "userErrors": "CartUserError"
  },
  "implementsNode": false
};
export default CartPaymentUpdatePayload;