
const DiscountCodeBasicInput = {
  "name": "DiscountCodeBasicInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "combinesWith": "DiscountCombinesWithInput",
    "title": "String",
    "startsAt": "DateTime",
    "endsAt": "DateTime",
    "usageLimit": "Int",
    "appliesOncePerCustomer": "Boolean",
    "minimumRequirement": "DiscountMinimumRequirementInput",
    "customerGets": "DiscountCustomerGetsInput",
    "customerSelection": "DiscountCustomerSelectionInput",
    "code": "String",
    "recurringCycleLimit": "Int"
  }
};
export default DiscountCodeBasicInput;