
const ShopPolicyUserError = {
  "name": "ShopPolicyUserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "ShopPolicyErrorCode",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
export default ShopPolicyUserError;