
const ProductDuplicateJob = {
  "name": "ProductDuplicateJob",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "done": "Boolean",
    "id": "ID"
  },
  "implementsNode": false
};
export default ProductDuplicateJob;