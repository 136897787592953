
const ProductOptionValueSwatch = {
  "name": "ProductOptionValueSwatch",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "color": "Color",
    "image": "MediaImage"
  },
  "implementsNode": false
};
export default ProductOptionValueSwatch;