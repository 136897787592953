
const FulfillmentOrderLineItemEdge = {
  "name": "FulfillmentOrderLineItemEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "FulfillmentOrderLineItem"
  },
  "implementsNode": false
};
export default FulfillmentOrderLineItemEdge;