
const ProductPriceRangeV2 = {
  "name": "ProductPriceRangeV2",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "maxVariantPrice": "MoneyV2",
    "minVariantPrice": "MoneyV2"
  },
  "implementsNode": false
};
export default ProductPriceRangeV2;