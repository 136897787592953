
const PublicationDeletePayload = {
  "name": "PublicationDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedId": "ID",
    "userErrors": "PublicationUserError"
  },
  "implementsNode": false
};
export default PublicationDeletePayload;