
const ShopifyPaymentsVerificationDocument = {
  "name": "ShopifyPaymentsVerificationDocument",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "backRequired": "Boolean",
    "frontRequired": "Boolean",
    "type": "ShopifyPaymentsVerificationDocumentType"
  },
  "implementsNode": false
};
export default ShopifyPaymentsVerificationDocument;