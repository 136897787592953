
const CheckoutBrandingTypographyStyleInput = {
  "name": "CheckoutBrandingTypographyStyleInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "font": "CheckoutBrandingTypographyFont",
    "size": "CheckoutBrandingTypographySize",
    "weight": "CheckoutBrandingTypographyWeight",
    "letterCase": "CheckoutBrandingTypographyLetterCase",
    "kerning": "CheckoutBrandingTypographyKerning"
  }
};
export default CheckoutBrandingTypographyStyleInput;