
const CartCompletionFailed = {
  "name": "CartCompletionFailed",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "errors": "CompletionError",
    "id": "String"
  },
  "implementsNode": false
};
export default CartCompletionFailed;