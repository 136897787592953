
const VariantOptionValueInput = {
  "name": "VariantOptionValueInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "id": "ID",
    "name": "String",
    "optionId": "ID",
    "optionName": "String"
  }
};
export default VariantOptionValueInput;