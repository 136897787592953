
const DiscountAutomaticDeletePayload = {
  "name": "DiscountAutomaticDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedAutomaticDiscountId": "ID",
    "userErrors": "DiscountUserError"
  },
  "implementsNode": false
};
export default DiscountAutomaticDeletePayload;