
const DeliveryMethodDefinitionCounts = {
  "name": "DeliveryMethodDefinitionCounts",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "participantDefinitionsCount": "Int",
    "rateDefinitionsCount": "Int"
  },
  "implementsNode": false
};
export default DeliveryMethodDefinitionCounts;