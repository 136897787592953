
const SubscriptionManualDiscount = {
  "name": "SubscriptionManualDiscount",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "entitledLines": "SubscriptionDiscountEntitledLines",
    "id": "ID",
    "recurringCycleLimit": "Int",
    "rejectionReason": "SubscriptionDiscountRejectionReason",
    "targetType": "DiscountTargetType",
    "title": "String",
    "type": "DiscountType",
    "usageCount": "Int",
    "value": "SubscriptionDiscountValue"
  },
  "implementsNode": false
};
export default SubscriptionManualDiscount;