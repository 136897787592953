import React, { Component, Fragment } from 'react';
import Breadcrumb from '../../components/common/bread-crumb';
import { Link, Prompt } from 'react-router-dom';
import { storefrontClient } from '../../index';
import * as storefrontQuerys from '../../querys/storefront-graphql';
// import { Modal } from 'react-bootstrap';
import classnames from 'classnames';
import { deactivateProduct, updateProductMarkup, activateProduct } from '../../components/utils/product'
import { AuthContext } from "../../auth/auth";
import { Dropdown } from 'react-bootstrap';
import { Alert, Progress } from "reactstrap";
import $ from "jquery";
import Modal from 'react-responsive-modal';
import { XCircle } from 'react-feather';
import Loader from '../loader/loader';

var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
class ProductList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            baseCollectionHandle: "ready-set-print", //Should come from context
            baseCollection: {},
            activeProduct: null,
            showActions: false,
            checkedProducts: [],
            loading: false,
            pageInfo: {},
            to: 20,
            activeProducts: [],
            alert: false,
            alertMessage: "",
            alertColor: "success",
            buyButtonHTML: ``,
            buyButtonScript: ``,
            markupModalOpen: false,
            products: {},
            updatingPage: false,
            isSelectAllChecked: false,
            updatingPageMessage: "",
            showProgress: false,
            progerss: 0,
            blockedProduct: null,
            modalDeleteOpen: false,
            productsToDelete: []
        };
        this.getCollectionData = this.getCollectionData.bind(this);
    }

    componentDidMount = () => {

        const { reseller_site_id, products, activeProducts, siteSetupStatus } = this.context

        this.setState({
            reseller_site_id
        })
        if (products.length <= 0 && activeProducts.length <= 0 && siteSetupStatus !== "BUILDING") {
            this.setState({
                loading: true
            }, () => {
                this.getCollectionData();
            });
        } else {
            this.setState({
                loading: false,
                products,
                activeProducts
            })
        }

        window.analytics.page();
    }

    componentDidUpdate = () => {
        if (this.state.updatingPage) {
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = undefined
        }

        const { products, activeProducts, siteSetupStatus } = this.context

        if (products.length <= 0 && activeProducts.length <= 0 && siteSetupStatus !== "BUILDING" && !this.state.loading) {
            this.setState({
                loading: true
            }, () => {
                this.getCollectionData();
            });
        }
    }

    setBuyButtonMarkup = (html, script) => {
        this.setState({
            buyButtonHTML: html,
            buyButtonScript: script
        });
    }

    onProductCheck = (product, checked) => {

        let { checkedProducts } = this.state

        let isAlreadyChecked = checkedProducts.findIndex(p => p.node.id === product.node.id) >= 0;

        if (checked) {
            if (!isAlreadyChecked) checkedProducts.push(product)
        } else {
            checkedProducts.splice(checkedProducts.findIndex(p => p.node.id === product.node.id), 1)
        }
        this.setState({
            checkedProducts
        })

    }

    toggleAlert = () => {
        this.setState({
            alert: !this.state.alert
        });
    }

    getCollectionData = async () => {
        let allProducts = {};
        let activeProducts = []
        let productList = []

        const { setContextProducts } = this.context

        storefrontClient.send(storefrontQuerys.getCollectionQuery(this.state.baseCollectionHandle)).then(({ data }) => {
            const baseCollection = data && data.collectionByHandle
            if (baseCollection) {
                const baseProducts = baseCollection.products.edges;
                for (let i = 0; i < baseProducts.length; i++) {
                    if (baseProducts[i].node.variants.edges.length > 0) {
                        if (baseProducts[i].node.variants.edges[0].node.sku) {
                            let sku = baseProducts[i].node.variants.edges[0].node.sku;
                            if (!allProducts[sku]) {
                                allProducts[sku] = baseProducts[i];
                                if (base64regex.test(baseProducts[i].node.id)) {
                                    baseProducts[i].node.id = atob(baseProducts[i].node.id)
                                }                       
                                productList.push(baseProducts[i])
                            }
                        }
                    }
                }
            }

            // Get all products in the reseller's custom products collection
            storefrontClient.send(storefrontQuerys.getCollectionQuery(this.context.reseller_collection_handle)).then(({ data }) => {
                const customCollection = data && data.collectionByHandle
                if (customCollection) {
                    const customProducts = customCollection.products.edges;
                    for (let i = 0; i < customProducts.length; i++) {
                        if (customProducts[i].node.variants.edges.length > 0) {
                            if (customProducts[i].node.variants.edges[0].node.sku) {
                                let sku = customProducts[i].node.variants.edges[0].node.sku;

                                // Replace any base product with its custom version if subcats match
                                if (sku in allProducts) {
                                    productList.splice(productList.findIndex(p => p.node.id === allProducts[sku].node.id), 1)
                                    delete allProducts[sku];
                                    if (base64regex.test(customProducts[i].node.id)) {
                                        customProducts[i].node.id = atob(customProducts[i].node.id);
                                    }                                    
                                    activeProducts.push(customProducts[i]);
                                } else if (customProducts[i].node.tags.includes("Custom Product")) {
                                    customProducts[i].additionalProduct = true;
                                    if (base64regex.test(customProducts[i].node.id)) {
                                        customProducts[i].node.id = atob(customProducts[i].node.id);
                                    }  
                                    activeProducts.push(customProducts[i]);
                                }
                            }
                        }
                    }
                }

                //Order active products by alphabetical order
                activeProducts.sort((a, b) => {
                    let titleA = a.node.title.toUpperCase();
                    let titleB = b.node.title.toUpperCase();

                    if (titleA < titleB) {
                        return -1;
                    }

                    if (titleA > titleB) {
                        return 1;
                    }

                    return 0;
                });

                setContextProducts({ products: activeProducts.concat(productList), activeProducts })

                this.setState({
                    baseCollection,
                    loading: false,
                    allProducts: activeProducts.concat(productList),
                    activeProducts,
                });
            });
        })

    }

    activateBulkProducts = async (checkedProducts) => {
        try {

            const { activeProducts } = this.context

            let inactiveCheckedProducts = [];
            inactiveCheckedProducts = checkedProducts.filter(c => {
                return activeProducts.findIndex(a => a.node.id === c.node.id) < 0
            });
            if (inactiveCheckedProducts.length > 0) {
                this.setState({
                    showProgress: inactiveCheckedProducts.length > 1 ? true : false,
                    updatingPage: true,
                    updatingPageMessage: 'Are you sure you want to leave this page? Your activation updates are still in process and your changes will not be saved.'
                });
                let activatedProducts = 0;
                for (let i = 0; i < inactiveCheckedProducts.length; i++) {
                    const product = inactiveCheckedProducts[i]
                    this.setState({
                        alert: true,
                        alertMessage: `Please wait... Activating product ${i + 1} of ${inactiveCheckedProducts.length}: "${product.node.title}"`,
                        alertColor: "secondary",
                        blockedProduct: product.node.id
                    })

                    const newProduct = await activateProduct(product.node, this.context);
                    const storeSubdomain = this.context.store_subdomain;

                    if (newProduct) {
                        this.setState({
                            progress: (i + 1) * 100 / inactiveCheckedProducts.length
                        });
                        activatedProducts++;
                        // Segment Tracking
                        window.analytics.track("Product Added", {
                            category: "Products",
                            label: storeSubdomain,
                            productId: product.node.id.replace('gid://shopify/Product/', ''),
                            productTitle: product.node.title
                        });
                    } else {
                        this.setState({
                            alert: true,
                            alertColor: "danger",
                            alertMessage: `Error: An error has occured while activating your product "${product.node.title}". Please try again.`,
                            updatingPage: false,
                            updatingPageMessage: "",
                            showProgress: false,
                            progress: 0,
                            blockedProduct: null
                        })
                    }
                }

                this.setState({
                    alert: true,
                    alertMessage: `Products activated: ${activatedProducts} of ${inactiveCheckedProducts.length}.`,
                    alertColor: "primary",
                    checkedProducts: [],
                    updatingPage: false,
                    updatingPageMessage: "",
                    showProgress: false,
                    progress: 0,
                    blockedProduct: null
                })
            }
        } catch (err) {
            this.setState({
                loading: false,
                checkedProducts: [],
                alert: true,
                alertColor: "danger",
                alertMessage: `Error: ${err.message}. An error has occured while cloning your product. Please try again.`,
                updatingPage: false,
                updatingPageMessage: "",
                showProgress: false,
                progress: 0,
                blockedProduct: null
            })
        }
    }

    deactivateBulkProducts = async () => {
        const { productsToDelete } = this.state;
        const { activeProducts } = this.context;

        let activeCheckedProducts = [];
        let deletedProducts = 0;
        activeCheckedProducts = productsToDelete.filter(c => {
            return activeProducts.findIndex(a => a.node.id === c.node.id) > -1;
        });
        if (activeCheckedProducts.length > 0) {
            this.setState({
                showProgress: activeCheckedProducts.length > 1 ? true : false,
                updatingPage: true,
                updatingPageMessage: 'Are you sure you want to leave this page? Your deletion updates are still in process and your changes will not be saved.',
                modalDeleteOpen: false
            });

            for (let i = 0; i < activeCheckedProducts.length; i++) {
                const product = activeCheckedProducts[i]

                try {
                    this.setState({
                        alert: true,
                        alertMessage: `Please wait... Deleting product ${i + 1} of ${activeCheckedProducts.length}: "${product.node.title}"`,
                        alertColor: "secondary",
                        blockedProduct: product.node.id
                    })
                    await deactivateProduct(product.node, this.context);
                    this.setState({
                        progress: (i + 1) * 100 / activeCheckedProducts.length
                    });
                    deletedProducts++;
                } catch (err) {
                    this.setState({
                        alert: true,
                        alertColor: "danger",
                        alertMessage: `Error: ${err}. An error has occured while deleting your product "${product.node.title}". Please try again.`,
                        updatingPage: false,
                        updatingPageMessage: "",
                        showProgress: false,
                        progress: 0,
                        blockedProduct: null
                    })
                }
            }
            this.setState({
                alert: true,
                alertMessage: `Products deleted: ${deletedProducts} of ${activeCheckedProducts.length}.`,
                alertColor: "primary",
                checkedProducts: [],
                productsToDelete: [],
                updatingPage: false,
                updatingPageMessage: "",
                showProgress: false,
                progress: 0,
                blockedProduct: null
            })
        }
    }

    updateMarkup = async () => {
        this.setState({
            alert: true,
            alertMessage: "Applying markup...",
            alertColor: "secondary",
            markupModalOpen: false,
            updatingPage: true,
            updatingPageMessage: 'Are you sure you want to leave this page? Your price updates are still in process and your changes will not be saved.'
        })
        const newMarkup = $('#globalMarkup').val()
        const globalMarkupUpdateResult = await this.context.setGlobalMarkup(newMarkup);

        const { activeProducts } = this.state

        let allProductsUpdated = true

        for (const product of activeProducts) {

            const result = await updateProductMarkup(product.node, newMarkup)
            allProductsUpdated = allProductsUpdated && result && result !== "undefined"

        }

        typeof globalMarkupUpdateResult !== "string" && allProductsUpdated ?
            this.setState({
                alert: true,
                alertMessage: "Success: Global product markup successfully applied.",
                alertColor: "success",
                updatingPage: false,
                updatingPageMessage: ""
            }) :
            this.setState({
                alert: true,
                alertMessage: "Error: " + globalMarkupUpdateResult,
                alertColor: "danger"
            });
    }

    showMarkupModal = () => {
        if ($('#globalMarkup').val() >= -10 && $('#globalMarkup').val() <= 30) {
            this.setState({
                markupModalOpen: true
            })
        } else {
            this.setState({
                alert: true,
                alertMessage: "Error: Invalid Markup value.",
                alertColor: "danger"
            });
        }
    }

    onCloseMarkupModal = () => {
        this.setState({ markupModalOpen: false })
    }

    selectUnselectAll = (checked) => {

        const { products } = this.context;

        for (let i = 0; i < products.length; i++) {
            const product = products[i];

            if (product) {

                this.onProductCheck(product, checked);
            }
        }

        this.setState({ isSelectAllChecked: checked });

    }

    onCloseDeleteModal = () => {
        this.setState({
            modalDeleteOpen: false
        })
    }

    render() {

        if (this.context.siteSetupStatus === "BUILDING") return <div>We are activating your products, please wait.</div>
        if (this.state.loading) return <Loader />
        const { checkedProducts, blockedProduct, modalDeleteOpen, productsToDelete } = this.state

        const { products, activeProducts, globalMarkup } = this.context

        let rows = []

        for (let i = 0; i < products.length; i++) {
            const product = products[i]

            if (product) {

                const isActive = activeProducts.findIndex(p => p.node.id === product.node.id) >= 0;
                const isChecked = checkedProducts.findIndex(p => p.node.id === product.node.id) >= 0;
                const sku = product.node.variants.edges[0].node.sku.split('-', 2)[0] + "-" + product.node.variants.edges[0].node.sku.split('-', 2)[1]

                rows.push(<tr key={product.node.id}
                    className={classnames({ "table-disable": !isActive })}
                    data-product-id={product.node.id}>
                    <td width="1%">
                        {!product.additionalProduct &&
                            <input className="checkbox_animated" id="chk-ani-1" type="checkbox" checked={isChecked}
                                onChange={(ev) => this.onProductCheck(product, ev.target.checked)}
                            />
                        }
                    </td>
                    <td style={{ fontSize: 14 }}>
                        <div className="d-flex align-items-center">
                            <div> <img src={product.node.images.edges[0] ? product.node.images.edges[0].node.src : "https://via.placeholder.com/32"} style={{ width: '32px', height: '32px' }} alt="Placeholder" /> </div>
                            <div className="pl-3"> <b> {product.node.title} </b> </div>
                        </div>
                    </td>
                    <td className="text-secondary">
                        {sku}
                    </td>
                    <td className="text-secondary">
                        {product.node.productType}
                    </td>
                    <td className="text-secondary">
                        <p>{isActive ? product.node.handle : ""}</p>
                    </td>
                    <td>
                        <span className={!isActive ? "text-custom-red" : "text-custom-green"}> {isActive ? "Active" : "Not Active"} </span>
                    </td>
                    <td width="1%">
                        {!product.additionalProduct &&
                            <button
                                disabled={blockedProduct === product.node.id}
                                className={classnames({
                                    "btn btn-outline-primary btn-custom-white": true,
                                    "btn-outline-danger": isActive
                                })}
                                style={{ width: '110px' }}
                                onClick={isActive ? () => this.setState({ modalDeleteOpen: true, productsToDelete: [product] }) : () => this.activateBulkProducts([product])}
                            >
                                {isActive ? "Delete" : "Activate"}
                            </button>
                        }
                    </td>
                    <td width="1%">
                        {isActive &&
                            <Link
                                className="btn btn-outline-primary btn-custom-white"
                                to={{
                                    pathname: `/products/${product.node.handle}`,
                                    state: { product: product.node, active: isActive, handle: product.node.handle }
                                }}
                            >
                                Edit
                            </Link>
                        }
                    </td>
                </tr>)
            }
        }

        return (
            <Fragment>
                <Breadcrumb mainTitle="Products" title="Products" parent="Products" />
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header pb-0">
                            <h5>Products</h5>
                            <p>Choose which products you would like featured on your site here. In order to display a product on your site, you must click the “Activate” button next to it. There is a bulk activate and bulk delete feature found at the top of the product list page, under the word “Actions.” There is also a bulk markup field, to change the markup of every active product all at once. This is found on the upper right side of the product list page. Deleting a product will effectively deactivate it. If you had uploaded specific images or copy to the product, you will no longer be able to access these. However, you can always reactivate the product and reconstruct it from scratch.</p>
                        </div>
                        <div className="card-body mt-1 pt-0">
                            <div>
                                <form className="pb-3" style={{ float: "right" }}>
                                    <div className="input-group">
                                        <label
                                            className="mr-2"
                                            style={{ fontSize: '12px', marginTop: '11px' }}
                                            id="saveGlobalMarkup"
                                        > <b>+/- MSRP on all active products</b></label>
                                        <input
                                            id="globalMarkup"
                                            className="form-control py-2"
                                            type="text" aria-label="Global Markup" aria-describedby="Global Markup"
                                            style={{
                                                borderRight: 'none',
                                                fontSize: '14px'
                                            }}
                                            placeholder="-10% to 30%"
                                            defaultValue={
                                                globalMarkup && globalMarkup !== "undefined" ? globalMarkup :
                                                    localStorage.getItem('whitelabelGlobalMarkup') && localStorage.getItem('whitelabelGlobalMarkup') !== "undefined" ? localStorage.getItem('whitelabelGlobalMarkup') :
                                                        0}
                                            onKeyPress={event => event.key === "Enter" ? event.preventDefault() : null}
                                        />
                                        <div className="input-group-append mr-2">
                                            <span className="input-group-text" style={{ background: 'none' }} id="basic-addon2">%</span>
                                        </div>
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary btn-custom-white"
                                            onClick={this.showMarkupModal}
                                        >
                                            Save
                                                </button>
                                    </div>
                                </form>
                            </div>
                            <div className="col-12 product-list-alert">
                                <Alert color={this.state.alertColor} isOpen={this.state.alert} toggle={this.toggleAlert.bind(this)}>
                                    {this.state.alertMessage}
                                </Alert>
                            </div>
                            {this.state.showProgress ?
                                <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 5 }}>
                                    <div style={{ width: "50%" }}>
                                        <Progress value={this.state.progress} />
                                    </div>
                                </div>
                                : <></>}
                            <div className="table-responsive">
                                <table className="table table-md border rounded">
                                    <thead>
                                        <tr>
                                            <th colSpan="2" className="text-secondary">
                                                <input className="checkbox_animated" id="chk-ani-1" type="checkbox" checked={this.state.isSelectAllChecked}
                                                    onChange={(ev) => this.selectUnselectAll(ev.target.checked)}
                                                />
                                                <span className="mr-2">{`${checkedProducts.length} of ${products.length} products selected`}</span>
                                                <Dropdown className="btn">
                                                    <Dropdown.Toggle variant="none" id="dropdown-basic" className="btn-outline-primary btn-outline-custom btn-custom-white">
                                                        <b>Actions</b>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item style={{ textTransform: "none" }} href="javascript:void(0)" onClick={() => this.activateBulkProducts(this.state.checkedProducts)}>Activate</Dropdown.Item>
                                                        <Dropdown.Item style={{ textTransform: "none" }} href="javascript:void(0)" onClick={() => {
                                                            if (this.state.checkedProducts.length > 0) {
                                                                this.setState({
                                                                    modalDeleteOpen: true,
                                                                    productsToDelete: this.state.checkedProducts
                                                                })
                                                            }
                                                        }}>Delete</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </th>
                                            <th scope="col" align="right">Item#</th>
                                            <th scope="col" align="right">Type</th>
                                            <th scope="col" align="right">Slug</th>
                                            <th scope="col" align="right">Status</th>
                                            <th align="right"></th>
                                            <th align="right"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div >
                <Modal
                    open={this.state.markupModalOpen}
                    onClose={this.onCloseMarkupModal}
                    showCloseIcon={false}
                    styles={{
                        modal: {
                            borderRadius: "10px",
                            overflow: 'hidden'
                        }
                    }}
                    center
                >
                    <div className="container modal-lg modal-alert" >
                        <div className="py-5 px-3">
                            <div className="d-flex justify-content-between">
                                <h5 style={{ color: "black", fontSize: "150%" }} ><b>Apply Markup</b></h5>
                                <div>
                                    <a onClick={this.onCloseMarkupModal}> <XCircle size={24} color="black" /></a>
                                </div>
                            </div>
                            <div className="text-secondary mt-1"
                                style={{ fontSize: "130%", color: "#777777" }} >
                                Are you sure you want to apply this markup? It will be applied to all your active products.
                                <br />
                                Depending on the number of products in your store, this operation could take a long time. Please be patient and allow the process to complete before navigating away from this page.
                            </div>
                            <div className="mt-4">
                                <button type="button"
                                    className="btn btn-outline-primary py-2 px-4 mr-3"
                                    onClick={this.onCloseMarkupModal}
                                >Cancel</button>
                                <button type="button" className="btn btn-danger py-2 px-4" style={{ backgroundColor: "rgb(255,0,0) !important" }}
                                    onClick={this.updateMarkup}>
                                    Apply
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    open={modalDeleteOpen}
                    showCloseIcon={false}
                    onClose={this.onCloseDeleteModal}
                    styles={{
                        modal: {
                            'borderRadius': "10px",
                            'overflow': 'hidden'
                        }
                    }}
                    center
                >
                    <div className="container modal-lg modal-alert" >
                        <div className="py-5 px-3">
                            <div className="d-flex justify-content-between">
                                <h5 style={{ color: "black", fontSize: "150%" }} ><b>{productsToDelete.length > 1 ? "Delete Products" : "Delete Product"}</b></h5>
                                <div>
                                    <a onClick={this.onCloseDeleteModal}> <XCircle size={24} color="black" /></a>
                                </div>
                            </div>
                            <div className="text-secondary mt-1"
                                style={{ fontSize: "130%", color: "#777777" }} >
                                {productsToDelete.length > 1 ? "Are you sure you want to delete these products?" : "Are you sure you want to delete this product?"}
                            </div>
                            <div className="mt-4">
                                <button type="button"
                                    className="btn btn-outline-primary py-2 px-4 mr-3"
                                    onClick={this.onCloseDeleteModal}
                                >
                                    Cancel
                                </button>
                                <button type="button"
                                    className="btn btn-danger py-2 px-4"
                                    style={{ backgroundColor: "rgb(255,0,0) !important" }}
                                    onClick={this.deactivateBulkProducts}>
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Prompt
                    when={this.state.updatingPage}
                    message={this.state.updatingPageMessage}
                />
            </Fragment >
        )
    }
}

ProductList.contextType = AuthContext;

export default ProductList;