
const MetafieldAccessGrantOperationInput = {
  "name": "MetafieldAccessGrantOperationInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "create": "MetafieldAccessGrantInput",
    "update": "MetafieldAccessGrantInput",
    "delete": "MetafieldAccessGrantDeleteInput"
  }
};
export default MetafieldAccessGrantOperationInput;