
const AppSubscription = {
  "name": "AppSubscription",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "createdAt": "DateTime",
    "currentPeriodEnd": "DateTime",
    "id": "ID",
    "lineItems": "AppSubscriptionLineItem",
    "name": "String",
    "returnUrl": "URL",
    "status": "AppSubscriptionStatus",
    "test": "Boolean",
    "trialDays": "Int"
  },
  "implementsNode": true
};
export default AppSubscription;