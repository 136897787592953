
const PrivateMetafieldInput = {
  "name": "PrivateMetafieldInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "owner": "ID",
    "namespace": "String",
    "key": "String",
    "valueInput": "PrivateMetafieldValueInput"
  }
};
export default PrivateMetafieldInput;