import { storefrontClient } from '../index';

export const getCustomerAccessTokenMutation = (email, password) => {
    const credentials = {
        "email": email,
        "password": password
    };
    const input = storefrontClient.variable(credentials, 'CustomerAccessTokenCreateInput!');
    const mutation = storefrontClient.mutation('customerAccessTokenCreate', input, (root) => {
        root.add('customerAccessTokenCreate', { args: { input: credentials } }, (customerAccessTokenCreate) => {
            customerAccessTokenCreate.add('customerAccessToken', (customerAccessToken) => {
                customerAccessToken.add('accessToken');
                customerAccessToken.add('expiresAt');
            });
        });
    });
    return mutation;
}

export const createCustomerMutation = (email, password, firstName = '', lastName = '') => {
    const credentials = {
        "email": email,
        "password": password,
        "firstName": firstName,
        "lastName": lastName
    };
    const input = storefrontClient.variable(credentials, 'CustomerCreateInput!');
    const mutation = storefrontClient.mutation('customerCreate', input, (root) => {
        root.add('customerCreate', { args: { input: credentials } }, (customerCreate) => {
            customerCreate.add('customer', (customer) => {
                customer.add('id');
            });
            customerCreate.add('customerUserErrors', (customerUserErrors) => {
                customerUserErrors.add('code');
                customerUserErrors.add('field');
                customerUserErrors.add('message');
            });
        });
    });
    return mutation;
}

export const customerActivateByUrlMutation = (activationUrl, password) => {
    const vars = {
        "activationUrl": activationUrl,
        "password": password
    };

    const input = storefrontClient.variable(vars, 'CustomerActivateByUrlInput!');
    const mutation = storefrontClient.mutation('customerActivateByUrl', input, (root) => {
        root.add('customerActivateByUrl', { args: { ...vars } }, (customerActivateByUrl) => {
            customerActivateByUrl.add('customer', (customer) => {
                customer.add('id');
            });
            customerActivateByUrl.add('customerAccessToken', (customerAccessToken) => {
                customerAccessToken.add('accessToken');
                customerAccessToken.add('expiresAt');
            });
            customerActivateByUrl.add('customerUserErrors', (customerUserErrors) => {
                customerUserErrors.add('code');
                customerUserErrors.add('field');
                customerUserErrors.add('message');
            });
        });
    });
    return mutation;
}


export const getCustomerQuery = (token) => {
    const query = storefrontClient.query((root) => {
        root.add('customer', { args: { customerAccessToken: token } }, (customer) => {
            customer.add('id');
            customer.add('defaultAddress', (defaultAddress) => {
                defaultAddress.add('address1');
                defaultAddress.add('address2');
                defaultAddress.add('city');
                defaultAddress.add('company');
                defaultAddress.add('country');
                defaultAddress.add('firstName');
                defaultAddress.add('lastName');
                defaultAddress.add('phone');
                defaultAddress.add('province');
                defaultAddress.add('zip');
            });
            customer.add('tags');
        });
    });
    return query;
}

export const getCollectionsQuery = () => {
    const query = storefrontClient.query((root) => {

        root.addConnection('collections', { args: { first: 250 } }, (collections) => {
            collections.add('id')
            collections.add('title')
            collections.add('handle')
            collections.add('description')
        });
    });

    return query;
}

export const getCollectionQuery = (collectionHandle) => {
    const query = storefrontClient.query((root) => {
        root.add('collectionByHandle', { args: { handle: collectionHandle } }, (collection) => {
            collection.add('title')
            collection.add('description')
            collection.add('handle')
            collection.add('updatedAt')
            collection.addConnection('products', { args: { first: 250 } }, (products) => {
                products.add('id');
                products.add('title');
                products.add('productType');
                products.add('descriptionHtml');
                products.add('description');
                products.add('handle');
                products.add('tags');
                products.addConnection('collections', { args: { first: 250 } }, (collections) => {
                    collections.add('id');
                    collections.add('title');
                });
                products.addConnection('variants', { args: { first: 250 } }, (variants) => {
                    variants.add('sku');
                    variants.add('price', (price)=>{
                        price.add('amount')
                        price.add('currencyCode')
                    })
                    variants.add('selectedOptions', (selectedOption) => {
                        selectedOption.add('name')
                        selectedOption.add('value')
                    });
                    variants.add('weight');
                    variants.add('weightUnit');
                    variants.add('barcode')
                    variants.add('title')
                });
                products.add('options', (options) => {
                    options.add('id');
                    options.add('name');
                    options.add('values');
                });
                // products.addConnection('metafields', { args: { first: 250 } }, (metafields) => {
                //         metafields.add('key');
                //         metafields.add('value');
                // });
                products.addConnection('images', { args: { first: 250 } }, (images) => {
                    images.add('id');
                    images.add('src');
                });
            });
        });
    });
    return query;
}

export const getProductsQuery = ({ first, after, last, before }) => {
    let productsArgs = {}
    if (first) {
        productsArgs.first = first
    }
    if (before) {
        productsArgs.before = before

    }
    if (after) {
        productsArgs.after = after

    }
    if (last) {
        productsArgs.last = last

    }

    const query = storefrontClient.query((root) => {

        root.addConnection('products', { args: { ...productsArgs } }, (products) => {
            products.add('id');
            products.add('title');
            products.add('productType');
            products.add('descriptionHtml');
            products.add('description');
            products.add('handle');
            products.add('tags');
            products.addConnection('collections', { args: { first: 250 } }, (collections) => {
                collections.add('id');
                collections.add('title');
                collections.add('handle');
            });
            products.addConnection('variants', { args: { first: 250 } }, (variants) => {
                variants.add('sku');
                variants.add('priceV2', (price) => {
                    price.add('amount')
                    price.add('currencyCode')
                })
                variants.add('selectedOptions', (selectedOption) => {
                    selectedOption.add('name')
                    selectedOption.add('value')
                })
            });
            products.add('options', (options) => {
                options.add('id');
                options.add('name');
            });
            products.addConnection('images', { args: { first: 250 } }, (images) => {
                images.add('id');
                images.add('src');
            });
        });

    });

    return query;
}

export const getProductByHandleQuery = (handle) => {
    const query = storefrontClient.query((root) => {
        root.add('productByHandle', { args: { handle } }, (product) => {
            product.add('id');
            product.add('title');
            product.add('productType');
            product.add('descriptionHtml');
            product.add('description');
            product.add('handle');
            product.add('tags');
            product.addConnection('collections', { args: { first: 250 } }, (collections) => {
                collections.add('id');
                collections.add('title');
            });
            product.addConnection('variants', { args: { first: 250 } }, (variants) => {
                variants.add('sku');
                variants.add('priceV2', (price) => {
                    price.add('amount')
                })
                variants.add('selectedOptions', (selectedOption) => {
                    selectedOption.add('name')
                    selectedOption.add('value')
                })
            });
            product.add('options', (options) => {
                options.add('id');
                options.add('name');
            });
            product.addConnection('images', { args: { first: 250 } }, (images) => {
                images.add('id');
                images.add('src');
            });
        })
    })

    return query;
}

export const getAllPagesQuery = () => {
    const query = storefrontClient.query((root) => {

        root.addConnection('pages', { args: { first: 25 } }, (page) => {
            page.add('body');
            page.add('title');
            page.add('id');
            page.add('bodySummary');
            page.add('createdAt');
            page.add('handle');
            page.add('updatedAt');
            // page.add('url');
        });

    });

    return query;
}

export const getPageByHandleQuery = (handle) => {
    const query = storefrontClient.query((root) => {
        root.add('pageByHandle', { args: { handle } }, (page) => {
            page.add('body');
            page.add('title');
            page.add('id');
            page.add('bodySummary');
            page.add('createdAt');
            page.add('handle');
            page.add('updatedAt');
            // page.add('url');
        })
    })

    return query;
}

export const recoverPasswordMutation = (email) => {
    const input = storefrontClient.variable(email, 'String!');
    const mutation = storefrontClient.mutation('customerRecover', input, (root) => {
        root.add('customerRecover', { args: { email: email } }, (customerRecover) => {
            customerRecover.add('customerUserErrors', (customerUserErrors) => {
                customerUserErrors.add('code');
                customerUserErrors.add('field');
                customerUserErrors.add('message');
            });
        });
    });
    return mutation;
}

export const getPagesByHandles = (handles = []) => {
    const query = storefrontClient.query((root) => {
        handles.forEach((handle, index) => {
            root.add('pageByHandle', { args: { handle: handle }, alias: "page" + index }, (page) => {
                page.add('body');
                page.add('title');
                page.add('id');
                page.add('bodySummary');
                page.add('createdAt');
                page.add('handle');
                page.add('updatedAt');
                // page.add('url');
            });
        });
    });

    return query;
}