
const CustomerPaymentMethodRemoteUserError = {
  "name": "CustomerPaymentMethodRemoteUserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "CustomerPaymentMethodRemoteUserErrorCode",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
export default CustomerPaymentMethodRemoteUserError;