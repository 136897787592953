
const CartPaymentInput = {
  "name": "CartPaymentInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "amount": "MoneyInput",
    "sourceIdentifier": "String",
    "freePaymentMethod": "CartFreePaymentMethodInput",
    "directPaymentMethod": "CartDirectPaymentMethodInput",
    "walletPaymentMethod": "CartWalletPaymentMethodInput"
  }
};
export default CartPaymentInput;