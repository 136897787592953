
const VaultPaypalBillingAgreement = {
  "name": "VaultPaypalBillingAgreement",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "inactive": "Boolean",
    "name": "String",
    "paypalAccountEmail": "String"
  },
  "implementsNode": false
};
export default VaultPaypalBillingAgreement;