
const CommentEvent = {
  "name": "CommentEvent",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "appTitle": "String",
    "attachments": "CommentEventAttachment",
    "attributeToApp": "Boolean",
    "attributeToUser": "Boolean",
    "author": "StaffMember",
    "canDelete": "Boolean",
    "canEdit": "Boolean",
    "createdAt": "DateTime",
    "criticalAlert": "Boolean",
    "edited": "Boolean",
    "embed": "CommentEventEmbed",
    "id": "ID",
    "message": "FormattedString",
    "rawMessage": "String",
    "subject": "CommentEventSubject"
  },
  "implementsNode": true
};
export default CommentEvent;