
const CompanyContactRoleAssignmentConnection = {
  "name": "CompanyContactRoleAssignmentConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "CompanyContactRoleAssignmentEdge",
    "nodes": "CompanyContactRoleAssignment",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default CompanyContactRoleAssignmentConnection;