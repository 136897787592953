
const DeliveryCountry = {
  "name": "DeliveryCountry",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "DeliveryCountryCodeOrRestOfWorld",
    "id": "ID",
    "name": "String",
    "provinces": "DeliveryProvince",
    "translatedName": "String"
  },
  "implementsNode": true
};
export default DeliveryCountry;