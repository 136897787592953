
const DraftOrder = {
  "name": "DraftOrder",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "appliedDiscount": "DraftOrderAppliedDiscount",
    "billingAddress": "MailingAddress",
    "billingAddressMatchesShippingAddress": "Boolean",
    "completedAt": "DateTime",
    "createdAt": "DateTime",
    "currencyCode": "CurrencyCode",
    "customAttributes": "Attribute",
    "customer": "Customer",
    "defaultCursor": "String",
    "email": "String",
    "events": "EventConnection",
    "hasTimelineComment": "Boolean",
    "id": "ID",
    "invoiceEmailTemplateSubject": "String",
    "invoiceSentAt": "DateTime",
    "invoiceUrl": "URL",
    "legacyResourceId": "UnsignedInt64",
    "lineItems": "DraftOrderLineItemConnection",
    "lineItemsSubtotalPrice": "MoneyBag",
    "localizationExtensions": "LocalizationExtensionConnection",
    "marketName": "String",
    "marketRegionCountryCode": "CountryCode",
    "metafield": "Metafield",
    "metafields": "MetafieldConnection",
    "name": "String",
    "note2": "String",
    "order": "Order",
    "paymentTerms": "PaymentTerms",
    "phone": "String",
    "poNumber": "String",
    "presentmentCurrencyCode": "CurrencyCode",
    "privateMetafield": "PrivateMetafield",
    "privateMetafields": "PrivateMetafieldConnection",
    "purchasingEntity": "PurchasingEntity",
    "ready": "Boolean",
    "reserveInventoryUntil": "DateTime",
    "shippingAddress": "MailingAddress",
    "shippingLine": "ShippingLine",
    "status": "DraftOrderStatus",
    "subtotalPrice": "Money",
    "subtotalPriceSet": "MoneyBag",
    "tags": "String",
    "taxExempt": "Boolean",
    "taxLines": "TaxLine",
    "taxesIncluded": "Boolean",
    "totalDiscountsSet": "MoneyBag",
    "totalLineItemsPriceSet": "MoneyBag",
    "totalPrice": "Money",
    "totalPriceSet": "MoneyBag",
    "totalShippingPrice": "Money",
    "totalShippingPriceSet": "MoneyBag",
    "totalTax": "Money",
    "totalTaxSet": "MoneyBag",
    "totalWeight": "UnsignedInt64",
    "updatedAt": "DateTime",
    "visibleToCustomer": "Boolean"
  },
  "implementsNode": true
};
export default DraftOrder;