
const MarketEdge = {
  "name": "MarketEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "Market"
  },
  "implementsNode": false
};
export default MarketEdge;