
const InventoryItemMeasurement = {
  "name": "InventoryItemMeasurement",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "weight": "Weight"
  },
  "implementsNode": true
};
export default InventoryItemMeasurement;