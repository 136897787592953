
const RestockingFee = {
  "name": "RestockingFee",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "amountSet": "MoneyBag",
    "id": "ID",
    "percentage": "Float"
  },
  "implementsNode": false
};
export default RestockingFee;