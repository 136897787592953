
const InventoryActivatePayload = {
  "name": "InventoryActivatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "inventoryLevel": "InventoryLevel",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default InventoryActivatePayload;