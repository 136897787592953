
const OrderEditAppliedDiscountInput = {
  "name": "OrderEditAppliedDiscountInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "description": "String",
    "fixedValue": "MoneyInput",
    "percentValue": "Float"
  }
};
export default OrderEditAppliedDiscountInput;