
const ShippingMethod = {
  "name": "ShippingMethod",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "String",
    "label": "String"
  },
  "implementsNode": false
};
export default ShippingMethod;