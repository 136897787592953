
const ExchangeV2 = {
  "name": "ExchangeV2",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "additions": "ExchangeV2Additions",
    "completedAt": "DateTime",
    "createdAt": "DateTime",
    "id": "ID",
    "location": "Location",
    "note": "String",
    "refunds": "Refund",
    "returns": "ExchangeV2Returns",
    "staffMember": "StaffMember",
    "totalAmountProcessedSet": "MoneyBag",
    "totalPriceSet": "MoneyBag",
    "transactions": "OrderTransaction"
  },
  "implementsNode": true
};
export default ExchangeV2;