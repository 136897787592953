
const TranslationInput = {
  "name": "TranslationInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "locale": "String",
    "key": "String",
    "value": "String",
    "translatableContentDigest": "String",
    "marketId": "ID"
  }
};
export default TranslationInput;