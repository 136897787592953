
const CustomerPaymentMethodRemoteCreditCardCreatePayload = {
  "name": "CustomerPaymentMethodRemoteCreditCardCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customerPaymentMethod": "CustomerPaymentMethod",
    "userErrors": "CustomerPaymentMethodUserError"
  },
  "implementsNode": false
};
export default CustomerPaymentMethodRemoteCreditCardCreatePayload;