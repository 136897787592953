
const ReturnRefundInput = {
  "name": "ReturnRefundInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "returnId": "ID",
    "returnRefundLineItems": "ReturnRefundLineItemInput",
    "refundShipping": "RefundShippingInput",
    "refundDuties": "RefundDutyInput",
    "orderTransactions": "ReturnRefundOrderTransactionInput",
    "notifyCustomer": "Boolean"
  }
};
export default ReturnRefundInput;