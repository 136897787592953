
const ReverseDeliveryShippingUpdatePayload = {
  "name": "ReverseDeliveryShippingUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "reverseDelivery": "ReverseDelivery",
    "userErrors": "ReturnUserError"
  },
  "implementsNode": false
};
export default ReverseDeliveryShippingUpdatePayload;