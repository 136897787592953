
const QuantityRule = {
  "name": "QuantityRule",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "increment": "Int",
    "isDefault": "Boolean",
    "maximum": "Int",
    "minimum": "Int",
    "originType": "QuantityRuleOriginType",
    "productVariant": "ProductVariant"
  },
  "implementsNode": false
};
export default QuantityRule;