
const AccessScope = {
  "name": "AccessScope",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "description": "String",
    "handle": "String"
  },
  "implementsNode": false
};
export default AccessScope;