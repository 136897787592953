
const AppUsageRecord = {
  "name": "AppUsageRecord",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "createdAt": "DateTime",
    "description": "String",
    "id": "ID",
    "idempotencyKey": "String",
    "price": "MoneyV2",
    "subscriptionLineItem": "AppSubscriptionLineItem"
  },
  "implementsNode": true
};
export default AppUsageRecord;