
const SubmitFailed = {
  "name": "SubmitFailed",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "checkoutUrl": "URL",
    "errors": "SubmissionError"
  },
  "implementsNode": false
};
export default SubmitFailed;