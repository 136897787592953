
const GiftCardEdge = {
  "name": "GiftCardEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "GiftCard"
  },
  "implementsNode": false
};
export default GiftCardEdge;