
const SellingPlanGroup = {
  "name": "SellingPlanGroup",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "appId": "String",
    "appliesToProduct": "Boolean",
    "appliesToProductVariant": "Boolean",
    "appliesToProductVariants": "Boolean",
    "createdAt": "DateTime",
    "description": "String",
    "id": "ID",
    "merchantCode": "String",
    "name": "String",
    "options": "String",
    "position": "Int",
    "productVariants": "ProductVariantConnection",
    "productVariantsCount": "Count",
    "products": "ProductConnection",
    "productsCount": "Count",
    "sellingPlans": "SellingPlanConnection",
    "summary": "String",
    "translations": "Translation"
  },
  "implementsNode": true
};
export default SellingPlanGroup;