
const QuantityPricingByVariantUpdateInput = {
  "name": "QuantityPricingByVariantUpdateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "quantityPriceBreaksToAdd": "QuantityPriceBreakInput",
    "quantityPriceBreaksToDelete": "ID",
    "quantityRulesToAdd": "QuantityRuleInput",
    "quantityRulesToDeleteByVariantId": "ID",
    "pricesToAdd": "PriceListPriceInput",
    "pricesToDeleteByVariantId": "ID"
  }
};
export default QuantityPricingByVariantUpdateInput;