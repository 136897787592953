
const InventorySetScheduledChangesPayload = {
  "name": "InventorySetScheduledChangesPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "scheduledChanges": "InventoryScheduledChange",
    "userErrors": "InventorySetScheduledChangesUserError"
  },
  "implementsNode": false
};
export default InventorySetScheduledChangesPayload;