
const ShopLocaleEnablePayload = {
  "name": "ShopLocaleEnablePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "shopLocale": "ShopLocale",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default ShopLocaleEnablePayload;