
const ProductOptionUpdateUserError = {
  "name": "ProductOptionUpdateUserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "ProductOptionUpdateUserErrorCode",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
export default ProductOptionUpdateUserError;