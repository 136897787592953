
const SuggestedOrderTransaction = {
  "name": "SuggestedOrderTransaction",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "accountNumber": "String",
    "amount": "Money",
    "amountSet": "MoneyBag",
    "formattedGateway": "String",
    "gateway": "String",
    "kind": "SuggestedOrderTransactionKind",
    "maximumRefundable": "Money",
    "maximumRefundableSet": "MoneyBag",
    "parentTransaction": "OrderTransaction",
    "paymentDetails": "PaymentDetails"
  },
  "implementsNode": false
};
export default SuggestedOrderTransaction;