
const QuantityRuleUserError = {
  "name": "QuantityRuleUserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "QuantityRuleUserErrorCode",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
export default QuantityRuleUserError;