
const SubscriptionCyclePriceAdjustment = {
  "name": "SubscriptionCyclePriceAdjustment",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "adjustmentType": "SellingPlanPricingPolicyAdjustmentType",
    "adjustmentValue": "SellingPlanPricingPolicyAdjustmentValue",
    "afterCycle": "Int",
    "computedPrice": "MoneyV2"
  },
  "implementsNode": false
};
export default SubscriptionCyclePriceAdjustment;