
const AppUsageRecordConnection = {
  "name": "AppUsageRecordConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "AppUsageRecordEdge",
    "nodes": "AppUsageRecord",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default AppUsageRecordConnection;