
const FulfillmentOrderSupportedAction = {
  "name": "FulfillmentOrderSupportedAction",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "action": "FulfillmentOrderAction",
    "externalUrl": "URL"
  },
  "implementsNode": false
};
export default FulfillmentOrderSupportedAction;