
const InventorySetScheduledChangesUserError = {
  "name": "InventorySetScheduledChangesUserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "InventorySetScheduledChangesUserErrorCode",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
export default InventorySetScheduledChangesUserError;