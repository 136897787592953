
const SubscriptionContractAtomicCreatePayload = {
  "name": "SubscriptionContractAtomicCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "contract": "SubscriptionContract",
    "userErrors": "SubscriptionDraftUserError"
  },
  "implementsNode": false
};
export default SubscriptionContractAtomicCreatePayload;