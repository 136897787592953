import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/bread-crumb';
import moment from 'moment';
import { PAGE_NAMES } from '../utils/constant';
import { AuthContext } from "../../auth/auth";
import { Trash2, XCircle } from 'react-feather';
import Modal from 'react-responsive-modal';
import Loader from '../loader/loader';
import { storefrontClient } from '../..';
import * as storefrontQuerys from '../../querys/storefront-graphql';
import { toast } from 'react-toastify';
var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

class PageList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            activePages: [],
            inactivePages: [],
            modalDeleteOpen: false,
            activePage: null,
            deleteBtnText: "Delete"
        }

    }

    componentDidMount() {
        const { pages, siteSetupStatus } = this.context;
        if (pages.length === 0 && siteSetupStatus !== "BUILDING") {
            this.fetchShopifyPages();
        }
    }

    componentDidUpdate = () => {
        const { pages, siteSetupStatus } = this.context;
        if (pages.length === 0 && siteSetupStatus !== "BUILDING" && !this.state.loading) {
            this.fetchShopifyPages();
        }
        window.analytics.page();
    }

    fetchShopifyPages = async () => {

        this.setState({ loading: true });
        const { store_subdomain, pages } = this.context


        let masterPages = [];
        let subdomainPages = [];

        let subdomainPageNames = PAGE_NAMES.map(p => `${store_subdomain.toLowerCase()}-${p}`);

        if (pages.length > 0) {

            this.setState({
                loading: false
            });

        } else {

            try {
                let pages = []
                storefrontClient.send(storefrontQuerys.getPagesByHandles(PAGE_NAMES)).then(data => {
                    if (data && data.data) {
                        masterPages = Object.values(data.data);
                        masterPages = masterPages.filter(p => p !== null);
                    }
                    storefrontClient.send(storefrontQuerys.getPagesByHandles(subdomainPageNames)).then(data => {
                        if (data && data.data) {
                            subdomainPages = Object.values(data.data);
                            subdomainPages = subdomainPages.filter(p => p !== null);
                        }
                        masterPages.forEach(async p => {
                            let page = subdomainPages.find(page => page.handle.toLowerCase() === `${store_subdomain.toLowerCase()}-${p.handle}`);
                            if (page) {
                                if (base64regex.test(page.id)) {
                                    page.id = atob(page.id).replace("gid://shopify/Page/", "");
                                }else {
                                    page.id = page.id.replace("gid://shopify/Page/", "");
                                }
                                pages.push(page);
                            } else {
                                const response = await fetch('/.netlify/functions/server/api/fetch-page-by-handle', {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify({ handle: `${store_subdomain.toLowerCase()}-${p.handle}` })
                                });
                                try {
                                    if (!response.ok) throw response;

                                    const r = await response.json();
                                    if (r && Object.keys(r).length > 0) {
                                        pages.push(r);
                                    } else {
                                        pages.push(p);
                                    }
                                }
                                catch (err) {
                                    //Show response error
                                    if (err.message) {
                                        toast.error(`${err.message}`);
                                    } else {
                                        toast.error(`${err.status}: ${err.statusText}`);
                                    }
                                }
                            }

                            this.context.setContextPages(pages);
                            this.setState({
                                loading: false
                            })

                        });
                    });
                });

            } catch (e) {
                this.setState({ loading: false });
                console.error("Page List GrapQL fetch error", e);
            }
        }
    }

    onOpenDeleteModal = ({ page }) => {
        this.setState({
            modalDeleteOpen: true,
            activePage: page
        })
    }

    onCloseDeleteModal = () => {
        this.setState({
            modalDeleteOpen: false,
            activePage: null,
            deleteBtnText: "Delete"
        })
    }

    deletePage = async () => {
        const { activePage } = this.state
        this.setState({
            deleteBtnText: "Deleting..."
        })

        const { setContextPages, pages, store_subdomain } = this.context

        const deleteResponse = await fetch('/.netlify/functions/server/api/delete-page', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ pageId: activePage.id, handle: activePage.handle })
        });
        try {
            if (!deleteResponse.ok) throw deleteResponse;

            await deleteResponse.json();
        }
        catch (err) {
            //Show response error
            if (err.message) {
                toast.error(`${err.message}`);
            } else {
                toast.error(`${err.status}: ${err.statusText}`);
            }
        }

        // update context
        const response = await fetch('/.netlify/functions/server/api/fetch-page-by-handle', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ handle: activePage.handle.toLowerCase().replace(`${store_subdomain.toLowerCase()}-`, '') })
        });

        try {
            if (!response.ok) throw response;

            const body = await response.json();

            pages.splice(pages.findIndex(p => p.id === activePage.id), 1, body)
            setContextPages(pages)
            this.setState({
                activePage: null,
                modalDeleteOpen: false,
                deleteBtnText: "Delete"
            })
        }
        catch (err) {
            //Show response error
            if (err.message) {
                toast.error(`${err.message}`);
            } else {
                toast.error(`${err.status}: ${err.statusText}`);
            }
        }
    }

    render() {

        if (this.context.siteSetupStatus === "BUILDING") return <p>We are activating your pages. Please wait.</p>
        const { loading, modalDeleteOpen, deleteBtnText } = this.state;

        if (loading) return <Loader />

        const { history } = this.props
        let { pages, store_subdomain } = this.context;
        return (
            <Fragment>
                <Breadcrumb mainTitle="Pages" title="List" parent="Pages" />
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header pb-0">
                            <h5>Pages</h5>
                            <p>Terms & Conditions and Privacy Policy are required pages on your site, and cannot be removed. They are pre-populated with language provided by Stouse, and only need to be updated to include your name, site name, and the date. All other pages are optional.</p>
                        </div>
                        <div className="card-body mt-1 pt-0">
                            <div>
                                <div className="table-responsive">
                                    <table className="table table-md border rounded">
                                        <tbody>
                                            {
                                                pages.map((page) => {
                                                    if (page) {
                                                        const active = page.handle.toLowerCase().includes(store_subdomain.toLowerCase());
                                                        const cantBeDeleted = page.handle.includes("terms-and-conditions") || page.handle.includes("privacy-policy");
                                                        let bodyText = page.bodySummary ? page.bodySummary : page.body_html ? page.body_html.replace(/<\/?[^>]+(>|$)/g, "") : "";

                                                        if (bodyText.length > 60) {
                                                            bodyText = `${bodyText.substring(0, 60)}...`;
                                                        }
                                                        return (
                                                            <tr key={`key-${page.handle}`}>
                                                                <td>
                                                                    <span className={active ? "font-weight-bold" : "text-secondary"}>{page.title}</span>
                                                                </td>
                                                                <td className="text-secondary"> {bodyText} </td>
                                                                <td className="text-custom-date"> {moment(page.created_at).format("MMM  DD, YYYY")} </td>
                                                                <td className="text-secondary">{active ? page.handle.toLowerCase().replace(`${this.context.store_subdomain.toLowerCase()}-`, "") : ""}</td>
                                                                <td width="5%">
                                                                    <a
                                                                        className={`btn ${active ? "btn-outline-primary" : "btn-primary"} px-4 py-1`}
                                                                        href="javascript:void(0)"
                                                                        onClick={() => {
                                                                            if (active) history.push(`/pages/edit/${page.handle}`)
                                                                            else history.push({ pathname: `/pages/new`, state: { page } })//this.clonePage({ page })
                                                                        }}>
                                                                        <b>{active ? `Edit` : `Add Page`}</b>
                                                                    </a>
                                                                </td>
                                                                <td width="5%">
                                                                    {active && !cantBeDeleted ? <Trash2 size="20" onClick={() => this.onOpenDeleteModal({ page })} /> : <></>}
                                                                </td>
                                                            </tr>
                                                        )
                                                    } else {
                                                        return <></>
                                                    }
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    open={modalDeleteOpen}
                    showCloseIcon={false}
                    onClose={this.onCloseDeleteModal}
                    styles={{
                        modal: {
                            'borderRadius': "10px",
                            'overflow': 'hidden'
                        }
                    }}
                    center
                >
                    <div className="container modal-lg modal-alert" >
                        <div className="py-5 px-3">
                            <div className="d-flex justify-content-between">
                                <h5 style={{ color: "black", fontSize: "150%" }} ><b>Delete Page</b></h5>
                                <div>
                                    <a onClick={this.onCloseDeleteModal}> <XCircle size={24} color="black" /></a>
                                </div>
                            </div>
                            <div className="text-secondary mt-1"
                                style={{ fontSize: "130%", color: "#777777" }} >
                                Are you sure you want to delete this page?
                            </div>
                            <div className="mt-4">
                                <button type="button"
                                    className="btn btn-outline-primary py-2 px-4 mr-3"
                                    onClick={this.onCloseDeleteModal}
                                >
                                    Cancel
                                </button>
                                <button type="button"
                                    className="btn btn-danger py-2 px-4"
                                    style={{ backgroundColor: "rgb(255,0,0) !important" }}
                                    onClick={this.deletePage}>
                                    {deleteBtnText}
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </Fragment>
        )
    }
}

PageList.contextType = AuthContext;

export default PageList;
