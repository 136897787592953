
const MarketCurrencySettings = {
  "name": "MarketCurrencySettings",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "baseCurrency": "CurrencySetting",
    "localCurrencies": "Boolean"
  },
  "implementsNode": false
};
export default MarketCurrencySettings;