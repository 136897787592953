
const DiscountCodeFreeShippingInput = {
  "name": "DiscountCodeFreeShippingInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "combinesWith": "DiscountCombinesWithInput",
    "title": "String",
    "startsAt": "DateTime",
    "endsAt": "DateTime",
    "code": "String",
    "usageLimit": "Int",
    "appliesOncePerCustomer": "Boolean",
    "minimumRequirement": "DiscountMinimumRequirementInput",
    "customerSelection": "DiscountCustomerSelectionInput",
    "destination": "DiscountShippingDestinationSelectionInput",
    "maximumShippingPrice": "Decimal",
    "recurringCycleLimit": "Int",
    "appliesOnOneTimePurchase": "Boolean",
    "appliesOnSubscription": "Boolean"
  }
};
export default DiscountCodeFreeShippingInput;