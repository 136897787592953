
const DiscountCodeBxgyUpdatePayload = {
  "name": "DiscountCodeBxgyUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "codeDiscountNode": "DiscountCodeNode",
    "userErrors": "DiscountUserError"
  },
  "implementsNode": false
};
export default DiscountCodeBxgyUpdatePayload;