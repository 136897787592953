
const SellingPlanCheckoutCharge = {
  "name": "SellingPlanCheckoutCharge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "type": "SellingPlanCheckoutChargeType",
    "value": "SellingPlanCheckoutChargeValue"
  },
  "implementsNode": false
};
export default SellingPlanCheckoutCharge;