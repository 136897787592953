
const MarketingActivityCreateExternalPayload = {
  "name": "MarketingActivityCreateExternalPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "marketingActivity": "MarketingActivity",
    "userErrors": "MarketingActivityUserError"
  },
  "implementsNode": false
};
export default MarketingActivityCreateExternalPayload;