
const FulfillmentOrderHoldInput = {
  "name": "FulfillmentOrderHoldInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "reason": "FulfillmentHoldReason",
    "reasonNotes": "String",
    "notifyMerchant": "Boolean",
    "externalId": "String",
    "fulfillmentOrderLineItems": "FulfillmentOrderLineItemInput"
  }
};
export default FulfillmentOrderHoldInput;