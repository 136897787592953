
const ImageConnection = {
  "name": "ImageConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "ImageEdge",
    "nodes": "Image",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default ImageConnection;