
const BasicEvent = {
  "name": "BasicEvent",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "appTitle": "String",
    "attributeToApp": "Boolean",
    "attributeToUser": "Boolean",
    "createdAt": "DateTime",
    "criticalAlert": "Boolean",
    "id": "ID",
    "message": "FormattedString"
  },
  "implementsNode": true
};
export default BasicEvent;