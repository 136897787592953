
const FulfillmentLineItem = {
  "name": "FulfillmentLineItem",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "lineItem": "OrderLineItem",
    "quantity": "Int"
  },
  "implementsNode": false
};
export default FulfillmentLineItem;