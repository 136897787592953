
const SubscriptionPricingPolicy = {
  "name": "SubscriptionPricingPolicy",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "basePrice": "MoneyV2",
    "cycleDiscounts": "SubscriptionCyclePriceAdjustment"
  },
  "implementsNode": false
};
export default SubscriptionPricingPolicy;