
const DeliveryMethodDefinition = {
  "name": "DeliveryMethodDefinition",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "active": "Boolean",
    "description": "String",
    "id": "ID",
    "methodConditions": "DeliveryCondition",
    "name": "String",
    "rateProvider": "DeliveryRateProvider"
  },
  "implementsNode": true
};
export default DeliveryMethodDefinition;