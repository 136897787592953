
const FulfillmentEventInput = {
  "name": "FulfillmentEventInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "address1": "String",
    "city": "String",
    "country": "String",
    "estimatedDeliveryAt": "DateTime",
    "happenedAt": "DateTime",
    "fulfillmentId": "ID",
    "latitude": "Float",
    "longitude": "Float",
    "message": "String",
    "province": "String",
    "status": "FulfillmentEventStatus",
    "zip": "String"
  }
};
export default FulfillmentEventInput;