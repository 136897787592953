
const ShopLocaleDisablePayload = {
  "name": "ShopLocaleDisablePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "locale": "String",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default ShopLocaleDisablePayload;