
const ValidationCreatePayload = {
  "name": "ValidationCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "userErrors": "ValidationUserError",
    "validation": "Validation"
  },
  "implementsNode": false
};
export default ValidationCreatePayload;