
const CompanyAddressInput = {
  "name": "CompanyAddressInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "address1": "String",
    "address2": "String",
    "city": "String",
    "zip": "String",
    "recipient": "String",
    "firstName": "String",
    "lastName": "String",
    "phone": "String",
    "zoneCode": "String",
    "countryCode": "CountryCode"
  }
};
export default CompanyAddressInput;