
const MetafieldDefinitionInput = {
  "name": "MetafieldDefinitionInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "namespace": "String",
    "key": "String",
    "name": "String",
    "description": "String",
    "ownerType": "MetafieldOwnerType",
    "type": "String",
    "validations": "MetafieldDefinitionValidationInput",
    "useAsCollectionCondition": "Boolean",
    "pin": "Boolean",
    "access": "MetafieldAccessInput"
  }
};
export default MetafieldDefinitionInput;