
const ShopPolicyUpdatePayload = {
  "name": "ShopPolicyUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "shopPolicy": "ShopPolicy",
    "userErrors": "ShopPolicyUserError"
  },
  "implementsNode": false
};
export default ShopPolicyUpdatePayload;