
const TranslationsRegisterPayload = {
  "name": "TranslationsRegisterPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "translations": "Translation",
    "userErrors": "TranslationUserError"
  },
  "implementsNode": false
};
export default TranslationsRegisterPayload;