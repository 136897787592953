
const CustomerSmsMarketingConsentError = {
  "name": "CustomerSmsMarketingConsentError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "CustomerSmsMarketingConsentErrorCode",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
export default CustomerSmsMarketingConsentError;