
const CheckoutBrandingFont = {
  "name": "CheckoutBrandingFont",
  "kind": "INTERFACE",
  "fieldBaseTypes": {
    "sources": "String",
    "weight": "Int"
  },
  "possibleTypes": ["CheckoutBrandingCustomFont", "CheckoutBrandingShopifyFont"]
};
export default CheckoutBrandingFont;