
const SubscriptionDraftDiscountCodeApplyPayload = {
  "name": "SubscriptionDraftDiscountCodeApplyPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "appliedDiscount": "SubscriptionAppliedCodeDiscount",
    "draft": "SubscriptionDraft",
    "userErrors": "SubscriptionDraftUserError"
  },
  "implementsNode": false
};
export default SubscriptionDraftDiscountCodeApplyPayload;