
const PriceListPriceEdge = {
  "name": "PriceListPriceEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "PriceListPrice"
  },
  "implementsNode": false
};
export default PriceListPriceEdge;