import React, { Component } from "react";
import { Link } from "react-router-dom";

export class MenuItems extends Component {

    render() {

        const { menus, snippet } = this.props;

        return <table className="table border rounded table-responsive-md text-content">
            <thead className="thead-white">
                <tr>
                    <th scope="col">Title</th>
                    <th scope="col">Menu Items</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {menus.map((menu, index) => (
                    <tr key={index}>
                        <td className="text-primary">
                            <b>{menu.title}</b>
                        </td>
                        <td>{menu.items.map(i => `${i.title}, `)}</td>

                        <td width="1%">
                            <Link
                                className="btn btn-custom-link px-4 py-1"
                                to={{ pathname: `/navigation/menus/edit/${menu.handle}`, state: { menu, netlifySnippetId: snippet.netlifySnippetId } }}
                            >
                                Edit
                            </Link>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    }

}

export default MenuItems;