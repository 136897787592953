
const CheckoutBrandingCustomizations = {
  "name": "CheckoutBrandingCustomizations",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "buyerJourney": "CheckoutBrandingBuyerJourney",
    "cartLink": "CheckoutBrandingCartLink",
    "checkbox": "CheckoutBrandingCheckbox",
    "choiceList": "CheckoutBrandingChoiceList",
    "control": "CheckoutBrandingControl",
    "expressCheckout": "CheckoutBrandingExpressCheckout",
    "favicon": "CheckoutBrandingImage",
    "footer": "CheckoutBrandingFooter",
    "global": "CheckoutBrandingGlobal",
    "header": "CheckoutBrandingHeader",
    "headingLevel1": "CheckoutBrandingHeadingLevel",
    "headingLevel2": "CheckoutBrandingHeadingLevel",
    "headingLevel3": "CheckoutBrandingHeadingLevel",
    "main": "CheckoutBrandingMain",
    "merchandiseThumbnail": "CheckoutBrandingMerchandiseThumbnail",
    "orderSummary": "CheckoutBrandingOrderSummary",
    "primaryButton": "CheckoutBrandingButton",
    "secondaryButton": "CheckoutBrandingButton",
    "select": "CheckoutBrandingSelect",
    "textField": "CheckoutBrandingTextField"
  },
  "implementsNode": false
};
export default CheckoutBrandingCustomizations;