
const MarketingActivityUpsertExternalInput = {
  "name": "MarketingActivityUpsertExternalInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "title": "String",
    "utm": "UTMInput",
    "budget": "MarketingActivityBudgetInput",
    "adSpend": "MoneyInput",
    "remoteId": "String",
    "status": "MarketingActivityExternalStatus",
    "remoteUrl": "URL",
    "remotePreviewImageUrl": "URL",
    "tactic": "MarketingTactic",
    "marketingChannelType": "MarketingChannel",
    "referringDomain": "String",
    "channelHandle": "String",
    "scheduledStart": "DateTime",
    "scheduledEnd": "DateTime",
    "start": "DateTime",
    "end": "DateTime",
    "urlParameterValue": "String",
    "parentRemoteId": "String",
    "hierarchyLevel": "MarketingActivityHierarchyLevel"
  }
};
export default MarketingActivityUpsertExternalInput;