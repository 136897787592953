
const DeletionEvent = {
  "name": "DeletionEvent",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "occurredAt": "DateTime",
    "subjectId": "ID",
    "subjectType": "DeletionEventSubjectType"
  },
  "implementsNode": false
};
export default DeletionEvent;