import React, { Component, Fragment } from 'react';
import { ReactComponent as SquareImg } from '../../assets/svgs/square.svg';
import { AvField, AvForm } from "availity-reactstrap-validation";
import Breadcrumb from '../../components/common/bread-crumb';
import { storefrontClient } from '../../index';
import * as storefrontQuerys from '../../querys/storefront-graphql';
import NewProductsList from './new-products-list'
import { AuthContext } from "../../auth/auth";
import { EmbedCode } from '../common/embed-code';
import { getNetlifySnippetByTitle, addNetlifySnippet, updateNetlifySnippet } from '../../querys/netlify-rest';
import { Alert } from 'reactstrap';
import Loader from '../loader/loader';
import FormHandleInput from '../../components/common/form-handle-input';
import { Link, Prompt } from 'react-router-dom';
import ReactDragListView from 'react-drag-listview'

import { ReactComponent as DraggableHandle } from '../../assets/svgs/draggable-handle.svg'
import bluebird from "bluebird";

const snippetTitle = "Client's Categories"
class CollectionEdit extends Component {

    constructor(props) {
        super(props)

        this.state = {
            collection: { products: { edges: [] } },
            loading: true,
            editMode: false,
            modalOpen: false,
            checkedNewProducts: [],
            activeProducts: [],
            collectionProducts: { edges: [] },
            newProducts: [],
            removedProducts: [],
            newProductsLoading: false,
            alertColor: "",
            alert: false,
            alertMessage: "",
            newCategoryAlertColor: "warning",
            newCategoryAlert: false,
            newCategoryAlertMessage: "Please note that it may take a few minutes for your new category to be activated.",
            alertSavingColor: "warning",
            alertSaving: false,
            alertSavingMessage: "Your changes are still being processed. Please note that it may take a few minutes for your products to be updated on your category.",
            collectionProductsLoading: false,
            productsDisplayQuantity: 10,
            updatingPage: false,
            updatingPageMessage: "Are you sure you want to leave this page? Your changes will be unsaved.",
            moves: []
        }
    }

    componentDidMount = async () => {
        if (this.props.match.params.handle) {

            const data = await this.fetchCollectionProducts();

            let collection = data.collection;

            //Shopify sometimes returns null for recently created categories. If that's the case, return to list.
            if (collection) {

                collection.transformedHandle = collection.handle.toLowerCase().replace(`${this.context.store_subdomain.toLowerCase()}-`, "")

                let cookies = document.cookie;
                cookies = cookies.split(";");

                if (cookies.find(c => c.trim() === `${collection.handle.toLowerCase()}=`)) {

                    this.setState({
                        alertSaving: true
                    })
                }

                const collectionProducts = {};

                collectionProducts.edges = collection.products.edges;

                this.setState({
                    collection,
                    loading: false,
                    editMode: true,
                    collectionProducts
                })
            } else {
                this.props.history.push("/categories");
            }

        } else {
            this.setState({
                loading: false,
                editMode: false,
                newCategoryAlert: true
            })
        }
        window.analytics.page();
    }

    fetchCollectionProducts = async () => {

        let { handle } = this.props.match.params

        const fields = `
                        id
                        title
                        description
                        handle
                        sortOrder
                        products(first: 240) {
                            edges {
                                node {
                                    id
                                    title
                                    descriptionHtml
                                    handle
                                    images (first: 1) {
                                        edges {
                                            node {
                                                id
                                                src
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `

        const response = await fetch('/.netlify/functions/server/api/get-collection-by-id', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: handle, fields })
        });
        if (!response.ok) throw response;
        const data = await response.json();

        return data;
    }

    handleValidSubmit = async (_event, values) => {

        this.setState({
            alert: true,
            alertColor: "secondary",
            alertMessage: "Saving category..."
        })

        //Remove spaces from handle
        values.transformedHandle = values.transformedHandle.replace(/ /g, "-");

        let { collection, editMode } = this.state

        //Check if user has changed the handle. For later redirection.
        const handleHasChanged = collection.transformedHandle !== values.transformedHandle

        const { store_subdomain } = this.context

        let adminCollection = {
            title: values.title,
            descriptionHtml: values.description,
            handle: `${store_subdomain}-${values.transformedHandle}`.toLowerCase(),
            sortOrder: "MANUAL"
        }

        if (editMode) {
            adminCollection.id = btoa(collection.id)
        }
        // Check if there's not another collection with that handle.
        storefrontClient.send(storefrontQuerys.getCollectionQuery(adminCollection.handle))
            .then(async ({ data }) => {

                if (!data.collectionByHandle || data.collectionByHandle.id == collection.id) {

                    const res = await this.addOrUpdateCollection(adminCollection, this.state.moves);

                    if (res.collection) {
                        
                        //Create cookie
                        let date = new Date();
                        date.setTime(date.getTime() + (5 * 60 * 1000));
                        let expires = `; expires=${date.toGMTString()}`;

                        document.cookie = `${collection.handle}=;${expires}; path=/`;

                        collection = {
                            ...collection,
                            ...values
                        }

                        //Update context
                        let { collections, setContextCollections, collectionsFetched } = this.context;
                        if (collectionsFetched) {
                            //Updating existing collection
                            if (collection.id && collections.length > 0) {
                                const collectionIndex = collections.findIndex(c => c.id === collection.id);
                                if (collectionIndex > -1) {
                                    collections.splice(collectionIndex, 1, { ...collection, handle: collection.transformedHandle });
                                }
                            } else { //Creating new collection
                                collection = {
                                    ...collection,
                                    ...res.collection
                                }
                                collections.push({ ...collection, handle: collection.transformedHandle });
                            }
                            setContextCollections({ collections });
                        }

                        if (!editMode) {
                            this.updateSnippet(res.collection.id)
                            this.setState({
                                alertSaving: true
                            });
                            this.props.history.push(`/categories/edit/${btoa(res.collection.id)}`)
                        }
                        else if (handleHasChanged) {
                            this.setState({ loading: false, collection })
                            this.setState({
                                alert: true,
                                alertColor: "success",
                                alertMessage: "Success: Category saved correctly! Changes made to your category may take a few minutes to reflect on your storefront.",
                                alertSaving: true,
                                updatingPage: false,
                                moves: []
                            });
                        } else {

                            this.setState({
                                loading: false, collection, newProducts: [], removedProducts: [],
                                alert: true,
                                alertColor: "success",
                                alertMessage: "Success: Category saved correctly! Changes made to your category may take a few minutes to reflect on your storefront.",
                                alertSaving: true,
                                updatingPage: false,
                                moves: []
                            })
                        }
                    }

                } else {
                    this.setState({
                        loading: false,
                        alert: true,
                        alertColor: "danger",
                        alertMessage: "Error: Another category already exists with this handle."
                    });
                }
            })
    }

    addOrUpdateCollection = async (collection, moves = []) => {

        const { newProducts, removedProducts } = this.state;

        const response = await fetch(`/.netlify/functions/server/api/${this.state.editMode ? "update" : "create"}-collection`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ collection, newProducts, removedProducts })
        });
        const res = await response.json();

        if (moves.length > 0) {
            // await 1 second, otherwise the reorder of new added products doesn't work
            await bluebird.delay(1000);
            const response = await fetch('/.netlify/functions/server/api/reorder-collection-products', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ collectionId: collection.id, moves })
            });

            if (!response.ok) throw response;
        }

        return res;
    }

    loadNewProducts = () => {

        this.setState({ newProductsLoading: true })

        // Get all products in the reseller's custom products collection
        storefrontClient.send(storefrontQuerys.getCollectionQuery(this.context.reseller_collection_handle)).then(({ data }) => {

            const customCollection = data.collectionByHandle

            const customProducts = customCollection.products.edges

            this.setState({
                activeProducts: customProducts,
                newProductsLoading: false
            })
        })

    }

    onOpenProductsModal = () => {
        this.loadNewProducts()
        //Load products

        this.setState({
            modalOpen: true,
        })
    }

    onCloseProductsModal = () => {
        this.setState({
            modalOpen: false
        })
    }

    onProductCheck = (product, checked) => {

        let { checkedNewProducts } = this.state

        if (checked) {
            checkedNewProducts.push(product)
        } else {
            // eslint-disable-next-line no-self-compare
            const productIndex = checkedNewProducts.findIndex(p => product.node.id === p.node.id)
            if (productIndex > -1) {
                checkedNewProducts.splice(productIndex, 1)
            }
        }

        this.setState({
            checkedNewProducts
        })
    }

    addProducts = () => {
        if (this.state.editMode) {
            this.setState({
                updatingPage: true
            })
        }
        this.setState({ collectionProductsLoading: true })

        let { checkedNewProducts, newProducts, removedProducts } = this.state

        checkedNewProducts = checkedNewProducts.map((checkedProduct) => {
            checkedProduct.node.images.edges = checkedProduct.node.images.edges.map(image => {
                let node = {
                    ...image.node,
                    originalSrc: image.node.src
                }
                return { node }
            })

            const node = {
                handle: checkedProduct.node.handle,
                id: checkedProduct.node.id,
                images: checkedProduct.node.images,
                title: checkedProduct.node.title
            }

            checkedProduct.node = node;

            if (newProducts.findIndex(p => p.node.id === checkedProduct.node.id) < 0) {
                newProducts.push(checkedProduct)
            }

            const removedProductsIndex = removedProducts.findIndex(cP => cP.node.id === checkedProduct.node.id);
            if (removedProductsIndex > -1) {
                removedProducts.splice(removedProductsIndex, 1)
            }

            return { node };
        })

        this.setState((state) => ({
            collectionProducts: {
                ...state.collectionProducts,
                edges: checkedNewProducts.concat(state.collectionProducts.edges)
            },
        }))

        this.setState({
            checkedNewProducts: [],
            newProducts,
            removedProducts,
            loading: false,
            modalOpen: false,
        }, async () => {

            this.setState({
                collectionProductsLoading: false,
            });

            var element = document.getElementById("category-products-container");
            element.scrollIntoView({ behavior: "smooth" });

        });
    }

    removeProductFromCategory = (product) => {
        if (this.state.editMode) {
            this.setState({ updatingPage: true });
        }
        let { removedProducts, collectionProducts, newProducts } = this.state

        const removedProductsIndex = removedProducts.findIndex(cP => cP.node.id === product.node.id);
        if (removedProductsIndex <= -1) {
            removedProducts.push(product)
        }

        this.setState({
            collectionProducts,
            removedProducts,
            collectionProductsLoading: true
        }, async () => {

            const newProductIndex = newProducts.findIndex(cP => cP.node.id === product.node.id);
            if (newProductIndex > -1) {
                newProducts.splice(newProductIndex, 1)
            }

            const collectionProductsIndex = collectionProducts.edges.findIndex(cP => cP.node.id === product.node.id);
            if (collectionProductsIndex > -1) {
                collectionProducts.edges.splice(collectionProductsIndex, 1)
            }
            if (this.state.editMode) {

                this.setState({
                    collectionProducts,
                    newProducts,
                })

            } else {
                const isNewProductIndex = this.state.newProducts.findIndex(np => np.node.id === product.node.id);
                if (isNewProductIndex > -1) {
                    const newProducts = this.state.newProducts.splice(isNewProductIndex, 1);
                    this.setState({
                        newProducts,
                        collectionProducts
                    })
                }
            }

            this.setState({
                collectionProductsLoading: false,
            });
        })

    }

    updateSnippet = (id) => {
        getNetlifySnippetByTitle(this.context.reseller_site_id, snippetTitle)
            .then(response => {
                let collectionIDs = []
                if (response.id && response.general) {
                    collectionIDs = JSON.parse(response.general.replace("<script>window.cat=", "").replace("</script>", ""))
                    collectionIDs.push(id)

                    const content = `<script>window.cat=${JSON.stringify(collectionIDs)}</script>`
                    //Decode snippet

                    updateNetlifySnippet(this.context.reseller_site_id, snippetTitle, content, response.id)
                } else {
                    collectionIDs.push(id)

                    const content = `<script>window.cat=${JSON.stringify(collectionIDs)}</script>`

                    addNetlifySnippet(this.context.reseller_site_id, snippetTitle, content)
                }
            })
    }

    toggleAlert = () => {
        this.setState({
            alert: !this.state.alert
        });
    }

    toggleNewCategoryAlert = () => {
        this.setState({
            newCategoryAlert: !this.state.newCategoryAlert
        });
    }

    toggleSavingAlert = () => {
        this.setState({
            alertSaving: !this.state.alertSaving
        });
    }
    reorderProduct = async (product, fromIndex, toIndex) => {

        if (this.state.editMode) {
            this.setState({ updatingPage: true });
        }

        let { moves } = this.state;

        this.setState({
            collectionProductsLoading: true,
        });

        const { collectionProducts } = this.state;


        if (this.state.editMode) {
            toIndex = parseInt(toIndex);

            moves.push({
                id: product.node.id,
                newPosition: (toIndex).toString()
            });

        }

        const updatedCollection = collectionProducts;

        updatedCollection.edges.splice(fromIndex, 1);
        updatedCollection.edges.splice(toIndex, 0, product);

        if (this.state.editMode) {

            this.setState({
                collectionProducts: updatedCollection,
                collectionProductsLoading: false,
            });
        } else {

            let newProducts = this.state.newProducts;
            newProducts.splice(fromIndex, 1);
            newProducts.splice(toIndex, 0, product);

            this.setState({
                collectionProducts: updatedCollection,
                collectionProductsLoading: false,
                newProducts
            })
        }

    }

    showMoreProducts = async () => {

        this.setState(prevState => ({
            productsDisplayQuantity: prevState.productsDisplayQuantity + 10
        }));
    }

    render() {
        const { loading } = this.state
        if (loading) return <Loader />
        const { collection, editMode, modalOpen, activeProducts, collectionProducts, newProductsLoading, checkedNewProducts, productsDisplayQuantity } = this.state

        let rows = []
        let visibleProducts = 0

        for (var index = 0; index < productsDisplayQuantity; index++) {
            const product = collectionProducts.edges[index];
            if (product) {
                let firstImage = null
                if (product.node.images.edges.length > 0) {
                    firstImage = product.node.images.edges[0].node
                }
                visibleProducts++;
                rows.push(
                    <tr key={index} >
                        <td className="text-secondary" width="10%">
                            <a href="#" className="px-1 mr-1"><DraggableHandle /></a>
                            {index + 1}.
                        </td>
                        <td className="text-primary align-items-center" width="80%">
                            {firstImage ? <img style={{ width: "30px", height: "30px" }} src={firstImage.src} /> : <SquareImg />}
                            <span className="pl-3"> <b> {product.node.title} </b> </span> </td>
                        <td width="8%"></td>
                        <td width="1%">
                            <button type="button" className="close pr-2" aria-label="Close" onClick={() => this.removeProductFromCategory(product)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </td>
                    </tr>
                )
            }
        }

        const self = this;

        const dragProps = {
            onDragEnd(fromIndex, toIndex) {
                const product = collectionProducts.edges[fromIndex];
                self.reorderProduct(product, fromIndex, toIndex);
            },
            lineClassName: 'global-drag-line',
        }

        return (
            <Fragment>
                <Breadcrumb mainTitle="Categories" title={`${editMode ? "Edit" : "Create"} Category`} parent="Categories" />
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <div className="top-buttons" style={{ minHeight: 50 }}>
                                <div className="top-left">
                                    <Link className="btn-custom-link mb-3" to="/categories/">
                                        &lt; Categories
                                    </Link>
                                </div>
                                <div className="top-right">
                                    {editMode ?
                                        <EmbedCode resourceId={collection.id} type={'collection'} /> :
                                        null
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-3 col-xl-2 text-secondary mb-3">
                                    <h5>{editMode ? `Edit` : `Create`} Category</h5>
                                </div>
                                <div className="col-sm-12 col-md-8 offset-md-1 offset-xl-1 col-xl-9">

                                    <Alert color={this.state.newCategoryAlertColor} isOpen={this.state.newCategoryAlert} toggle={this.toggleNewCategoryAlert.bind(this)}>
                                        {this.state.newCategoryAlertMessage}
                                    </Alert>
                                </div>
                            </div>
                        </div>
                        <div className="card-body px-4">
                            <div className="row">
                                <div className="col-sm-12 col-md-3 col-xl-2 text-secondary mb-3">
                                    <b> Categories make it easier for your customers to find specific products.</b>
                                </div>
                                <div className="col-sm-12 col-md-8 offset-md-1 offset-xl-1 col-xl-9">
                                    <Alert color={this.state.alertColor} isOpen={this.state.alert} toggle={this.toggleAlert.bind(this)}>
                                        {this.state.alertMessage}
                                    </Alert>
                                    <AvForm
                                        className="needs-validation add-product-form"
                                        onValidSubmit={this.handleValidSubmit}
                                        onInvalidSubmit={this.handleInvalidSubmit}
                                        model={collection}

                                    >

                                        <div className="form-group">
                                            <label className="mb-2"> Category Title </label>
                                            <div className="form-field">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <AvField
                                                            className="form-control"
                                                            name="title"
                                                            type="text"
                                                            placeholder="Category Title"
                                                            required
                                                            onChange={() => { if (this.state.editMode) this.setState({ updatingPage: true }) }}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="valid-feedback">Invalid</div>
                                        </div>

                                        <div className="form-group" id="category-products-container">
                                            <label className="mb-2"> Category Description </label>
                                            <div className="form-field">
                                                <AvField
                                                    className="form-control"
                                                    name="description"
                                                    type="textarea"
                                                    rows={3}
                                                    placeholder="Save time and money with our affordable Kwik-Ship Decals when you need your order to ship quick!"
                                                    required
                                                    onChange={() => { if (this.state.editMode) this.setState({ updatingPage: true }) }}
                                                />
                                            </div>
                                            <div className="valid-feedback">Invalid</div>
                                        </div>

                                        <div className="mt-5 category-products-container" >
                                            <label className="mb-2"> <b> Products in Category</b> </label>
                                            <Alert color={this.state.alertSavingColor} isOpen={this.state.alertSaving} toggle={this.toggleSavingAlert.bind(this)}>
                                                {this.state.alertSavingMessage}
                                            </Alert>

                                            <ReactDragListView {...dragProps} className="drag-container">
                                                <table className="table table-md border rounded draggable">
                                                    {this.state.collectionProductsLoading && <div className="products-loader"><Loader /></div>}
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" width="10%"></th>
                                                            <th scope="col" width="80%">Products</th>
                                                            <th scope="col" width="8%"></th>
                                                            <th scope="col" width="1%"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {rows}
                                                        {collectionProducts.edges.length > productsDisplayQuantity ? <tr>
                                                            <td colSpan="4" width="100%">
                                                                <a href="javascript:void(0)" onClick={this.showMoreProducts}><b>Show more products</b></a>
                                                            </td>
                                                        </tr>
                                                            : <></>}
                                                    </tbody>
                                                </table>
                                            </ReactDragListView>
                                        </div>
                                        < div className="text-center mt-3">
                                            <a className="btn btn-outline-primary" href="javascript:void(0);" onClick={this.onOpenProductsModal}> Add Products</a>
                                        </div>

                                        <div className="mt-5 form-label-center">
                                            <FormHandleInput labelText="URL Handle" url={`https://${this.context.store_subdomain}.readysetprint.com/category/`} required={true} name="transformedHandle" />
                                        </div>
                                        <button type="submit" className="btn btn-primary mt-2 btn-custom"> Save </button>
                                    </AvForm>
                                    <br />
                                    <br />
                                    <Alert color={this.state.alertColor} isOpen={this.state.alert} toggle={this.toggleAlert.bind(this)}>
                                        {this.state.alertMessage}
                                    </Alert>
                                </div>
                            </div>


                        </div>
                    </div>

                </div >
                <NewProductsList
                    modalOpen={modalOpen}
                    onCloseModal={this.onCloseProductsModal}
                    activeProducts={activeProducts}
                    newProductsLoading={newProductsLoading}
                    checkedNewProducts={checkedNewProducts}
                    collectionProducts={collectionProducts.edges}
                    onSaveModal={this.addProducts}
                    onProductCheck={this.onProductCheck}
                />

                <Prompt
                    when={this.state.updatingPage}
                    message={this.state.updatingPageMessage}
                />
            </Fragment >
        )
    }
};

CollectionEdit.contextType = AuthContext

export default CollectionEdit;
