
const SubscriptionBillingAttemptConnection = {
  "name": "SubscriptionBillingAttemptConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "SubscriptionBillingAttemptEdge",
    "nodes": "SubscriptionBillingAttempt",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default SubscriptionBillingAttemptConnection;