
const ShopifyPaymentsBalanceTransaction = {
  "name": "ShopifyPaymentsBalanceTransaction",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "adjustmentsOrders": "ShopifyPaymentsAdjustmentOrder",
    "id": "ID",
    "net": "MoneyV2",
    "transactionDate": "DateTime"
  },
  "implementsNode": true
};
export default ShopifyPaymentsBalanceTransaction;