
const CheckoutBrandingControlInput = {
  "name": "CheckoutBrandingControlInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "color": "CheckoutBrandingColorSelection",
    "cornerRadius": "CheckoutBrandingCornerRadius",
    "border": "CheckoutBrandingSimpleBorder",
    "labelPosition": "CheckoutBrandingLabelPosition"
  }
};
export default CheckoutBrandingControlInput;