
const MarketUpdateInput = {
  "name": "MarketUpdateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "name": "String",
    "handle": "String",
    "enabled": "Boolean"
  }
};
export default MarketUpdateInput;