
const CheckoutBrandingButtonInput = {
  "name": "CheckoutBrandingButtonInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "background": "CheckoutBrandingBackgroundStyle",
    "border": "CheckoutBrandingSimpleBorder",
    "cornerRadius": "CheckoutBrandingCornerRadius",
    "blockPadding": "CheckoutBrandingSpacing",
    "inlinePadding": "CheckoutBrandingSpacing",
    "typography": "CheckoutBrandingTypographyStyleInput"
  }
};
export default CheckoutBrandingButtonInput;