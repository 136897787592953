
const ProductVariantsBulkUpdatePayload = {
  "name": "ProductVariantsBulkUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "product": "Product",
    "productVariants": "ProductVariant",
    "userErrors": "ProductVariantsBulkUpdateUserError"
  },
  "implementsNode": false
};
export default ProductVariantsBulkUpdatePayload;