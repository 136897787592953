
const OrderEditSetQuantityPayload = {
  "name": "OrderEditSetQuantityPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "calculatedLineItem": "CalculatedLineItem",
    "calculatedOrder": "CalculatedOrder",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default OrderEditSetQuantityPayload;