
const Publishable = {
  "name": "Publishable",
  "kind": "INTERFACE",
  "fieldBaseTypes": {
    "availablePublicationsCount": "Count",
    "publicationCount": "Int",
    "publishedOnChannel": "Boolean",
    "publishedOnCurrentChannel": "Boolean",
    "publishedOnCurrentPublication": "Boolean",
    "publishedOnPublication": "Boolean",
    "resourcePublications": "ResourcePublicationConnection",
    "resourcePublicationsCount": "Count",
    "resourcePublicationsV2": "ResourcePublicationV2Connection",
    "unpublishedChannels": "ChannelConnection",
    "unpublishedPublications": "PublicationConnection"
  },
  "possibleTypes": ["Collection", "Product"]
};
export default Publishable;