
const PaymentTermsCreatePayload = {
  "name": "PaymentTermsCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "paymentTerms": "PaymentTerms",
    "userErrors": "PaymentTermsCreateUserError"
  },
  "implementsNode": false
};
export default PaymentTermsCreatePayload;