
const ProductVariant = {
  "name": "ProductVariant",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "availableForSale": "Boolean",
    "barcode": "String",
    "compareAtPrice": "Money",
    "contextualPricing": "ProductVariantContextualPricing",
    "createdAt": "DateTime",
    "defaultCursor": "String",
    "deliveryProfile": "DeliveryProfile",
    "displayName": "String",
    "fulfillmentService": "FulfillmentService",
    "fulfillmentServiceEditable": "EditableProperty",
    "harmonizedSystemCode": "String",
    "id": "ID",
    "image": "Image",
    "inventoryItem": "InventoryItem",
    "inventoryManagement": "ProductVariantInventoryManagement",
    "inventoryPolicy": "ProductVariantInventoryPolicy",
    "inventoryQuantity": "Int",
    "legacyResourceId": "UnsignedInt64",
    "media": "MediaConnection",
    "metafield": "Metafield",
    "metafieldDefinitions": "MetafieldDefinitionConnection",
    "metafields": "MetafieldConnection",
    "position": "Int",
    "presentmentPrices": "ProductVariantPricePairConnection",
    "price": "Money",
    "privateMetafield": "PrivateMetafield",
    "privateMetafields": "PrivateMetafieldConnection",
    "product": "Product",
    "productVariantComponents": "ProductVariantComponentConnection",
    "requiresComponents": "Boolean",
    "requiresShipping": "Boolean",
    "selectedOptions": "SelectedOption",
    "sellableOnlineQuantity": "Int",
    "sellingPlanGroupCount": "Int",
    "sellingPlanGroups": "SellingPlanGroupConnection",
    "sellingPlanGroupsCount": "Count",
    "sku": "String",
    "storefrontId": "StorefrontID",
    "taxCode": "String",
    "taxable": "Boolean",
    "title": "String",
    "translations": "Translation",
    "updatedAt": "DateTime",
    "weight": "Float",
    "weightUnit": "WeightUnit"
  },
  "implementsNode": true
};
export default ProductVariant;