
const HasMetafields = {
  "name": "HasMetafields",
  "kind": "INTERFACE",
  "fieldBaseTypes": {
    "metafield": "Metafield",
    "metafields": "MetafieldConnection",
    "privateMetafield": "PrivateMetafield",
    "privateMetafields": "PrivateMetafieldConnection"
  },
  "possibleTypes": ["AppInstallation", "CartTransform", "Collection", "Company", "CompanyLocation", "Customer", "CustomerSegmentMember", "DeliveryCustomization", "DiscountAutomaticNode", "DiscountCodeNode", "DiscountNode", "DraftOrder", "FulfillmentConstraintRule", "Image", "Location", "Market", "MediaImage", "Order", "PaymentCustomization", "Product", "ProductVariant", "Shop", "Validation"]
};
export default HasMetafields;