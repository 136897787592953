
const DiscountCodeFreeShippingCreatePayload = {
  "name": "DiscountCodeFreeShippingCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "codeDiscountNode": "DiscountCodeNode",
    "userErrors": "DiscountUserError"
  },
  "implementsNode": false
};
export default DiscountCodeFreeShippingCreatePayload;