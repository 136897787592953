
const ResourcePublicationConnection = {
  "name": "ResourcePublicationConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "ResourcePublicationEdge",
    "nodes": "ResourcePublication",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default ResourcePublicationConnection;