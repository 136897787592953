
const DraftOrderDuplicatePayload = {
  "name": "DraftOrderDuplicatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "draftOrder": "DraftOrder",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default DraftOrderDuplicatePayload;