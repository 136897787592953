
const DiscountCombinesWith = {
  "name": "DiscountCombinesWith",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "orderDiscounts": "Boolean",
    "productDiscounts": "Boolean",
    "shippingDiscounts": "Boolean"
  },
  "implementsNode": false
};
export default DiscountCombinesWith;