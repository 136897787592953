
const MetaobjectDefinitionUpdateInput = {
  "name": "MetaobjectDefinitionUpdateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "name": "String",
    "description": "String",
    "fieldDefinitions": "MetaobjectFieldDefinitionOperationInput",
    "access": "MetaobjectAccessInput",
    "displayNameKey": "String",
    "resetFieldOrder": "Boolean",
    "capabilities": "MetaobjectCapabilityUpdateInput"
  }
};
export default MetaobjectDefinitionUpdateInput;