
const ApplePayWalletHeaderInput = {
  "name": "ApplePayWalletHeaderInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "applicationData": "String",
    "ephemeralPublicKey": "String",
    "publicKeyHash": "String",
    "transactionId": "String"
  }
};
export default ApplePayWalletHeaderInput;