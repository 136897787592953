
const DelegateAccessTokenCreatePayload = {
  "name": "DelegateAccessTokenCreatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "delegateAccessToken": "DelegateAccessToken",
    "shop": "Shop",
    "userErrors": "DelegateAccessTokenCreateUserError"
  },
  "implementsNode": false
};
export default DelegateAccessTokenCreatePayload;