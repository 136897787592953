
const DeliveryLocationGroupZone = {
  "name": "DeliveryLocationGroupZone",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "methodDefinitionCounts": "DeliveryMethodDefinitionCounts",
    "methodDefinitions": "DeliveryMethodDefinitionConnection",
    "zone": "DeliveryZone"
  },
  "implementsNode": false
};
export default DeliveryLocationGroupZone;