import React, { useState, useRef, useEffect } from "react";
import { AvField } from "availity-reactstrap-validation";

const GeneralInput = ({ label, name, placeholder, maxCount, disabled, value, defaultValue, type = "text", min, max, id, required = true }) => {

  const inputEl = useRef(null);

  const [count, setCount] = useState(0);

  useEffect(() => {
    if (maxCount && inputEl.current && inputEl.current.FormCtrl) {
      const currentInput = inputEl.current.FormCtrl.getInput(name);
      if (currentInput) setCount(currentInput.value.length);
    }

  }, [])

  const updateCount = (ev) => {
    if (maxCount) setCount(ev.target.value.length);
  }

  return (
    <div className="form-group">
      {label ? <label className="mb-2">{label} </label> : <></>}
      <div className="form-field">
        <AvField
          ref={inputEl}
          className="form-control mb-0"
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
          required={required}
          disabled={disabled}
          defaultValue={defaultValue}
          min={min}
          max={max}
          maxLength={maxCount}
          onChange={updateCount}
          id={id}
        />
      </div>
      <div className="valid-feedback">Invalid</div>
      {maxCount && (
        <div style={{ color: "#777777" }}>
          {` ${count} of ${maxCount} characters used`}
        </div>
      )}
    </div>
  )
};

export default GeneralInput;