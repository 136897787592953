
const SaleEdge = {
  "name": "SaleEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "Sale"
  },
  "implementsNode": false
};
export default SaleEdge;