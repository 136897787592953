
const PriceRuleShippingEntitlementsInput = {
  "name": "PriceRuleShippingEntitlementsInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "targetAllShippingLines": "Boolean",
    "countryCodes": "CountryCode",
    "includeRestOfWorld": "Boolean"
  }
};
export default PriceRuleShippingEntitlementsInput;