
const FulfillmentTrackingInfo = {
  "name": "FulfillmentTrackingInfo",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "company": "String",
    "number": "String",
    "url": "URL"
  },
  "implementsNode": false
};
export default FulfillmentTrackingInfo;