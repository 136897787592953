
const CheckoutBranding = {
  "name": "CheckoutBranding",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customizations": "CheckoutBrandingCustomizations",
    "designSystem": "CheckoutBrandingDesignSystem"
  },
  "implementsNode": false
};
export default CheckoutBranding;