
const FulfillmentOrderReleaseHoldPayload = {
  "name": "FulfillmentOrderReleaseHoldPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "fulfillmentOrder": "FulfillmentOrder",
    "userErrors": "FulfillmentOrderReleaseHoldUserError"
  },
  "implementsNode": false
};
export default FulfillmentOrderReleaseHoldPayload;