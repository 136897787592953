
const CheckoutProfileConnection = {
  "name": "CheckoutProfileConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "CheckoutProfileEdge",
    "nodes": "CheckoutProfile",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default CheckoutProfileConnection;