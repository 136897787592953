
const SuggestedRefund = {
  "name": "SuggestedRefund",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "amount": "Money",
    "amountSet": "MoneyBag",
    "discountedSubtotalSet": "MoneyBag",
    "maximumRefundable": "Money",
    "maximumRefundableSet": "MoneyBag",
    "refundDuties": "RefundDuty",
    "refundLineItems": "RefundLineItem",
    "shipping": "ShippingRefund",
    "subtotal": "Money",
    "subtotalSet": "MoneyBag",
    "suggestedTransactions": "SuggestedOrderTransaction",
    "totalCartDiscountAmountSet": "MoneyBag",
    "totalDutiesSet": "MoneyBag",
    "totalTaxSet": "MoneyBag",
    "totalTaxes": "Money"
  },
  "implementsNode": false
};
export default SuggestedRefund;