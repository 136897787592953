
const QuantityRuleInput = {
  "name": "QuantityRuleInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "increment": "Int",
    "maximum": "Int",
    "minimum": "Int",
    "variantId": "ID"
  }
};
export default QuantityRuleInput;