
const SubscriptionContractEdge = {
  "name": "SubscriptionContractEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "SubscriptionContract"
  },
  "implementsNode": false
};
export default SubscriptionContractEdge;