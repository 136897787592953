
const DiscountAutomaticBasicInput = {
  "name": "DiscountAutomaticBasicInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "combinesWith": "DiscountCombinesWithInput",
    "title": "String",
    "startsAt": "DateTime",
    "endsAt": "DateTime",
    "minimumRequirement": "DiscountMinimumRequirementInput",
    "customerGets": "DiscountCustomerGetsInput",
    "recurringCycleLimit": "Int"
  }
};
export default DiscountAutomaticBasicInput;