
const DiscountCodeBxgy = {
  "name": "DiscountCodeBxgy",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "appliesOncePerCustomer": "Boolean",
    "asyncUsageCount": "Int",
    "codes": "DiscountRedeemCodeConnection",
    "codesCount": "Count",
    "combinesWith": "DiscountCombinesWith",
    "createdAt": "DateTime",
    "customerBuys": "DiscountCustomerBuys",
    "customerGets": "DiscountCustomerGets",
    "customerSelection": "DiscountCustomerSelection",
    "discountClass": "MerchandiseDiscountClass",
    "endsAt": "DateTime",
    "hasTimelineComment": "Boolean",
    "shareableUrls": "DiscountShareableUrl",
    "startsAt": "DateTime",
    "status": "DiscountStatus",
    "summary": "String",
    "title": "String",
    "totalSales": "MoneyV2",
    "updatedAt": "DateTime",
    "usageLimit": "Int",
    "usesPerOrderLimit": "Int"
  },
  "implementsNode": false
};
export default DiscountCodeBxgy;