
const CheckoutBrandingCustomFontGroupInput = {
  "name": "CheckoutBrandingCustomFontGroupInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "base": "CheckoutBrandingCustomFontInput",
    "bold": "CheckoutBrandingCustomFontInput",
    "loadingStrategy": "CheckoutBrandingFontLoadingStrategy"
  }
};
export default CheckoutBrandingCustomFontGroupInput;