
const SubscriptionContractSetNextBillingDatePayload = {
  "name": "SubscriptionContractSetNextBillingDatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "contract": "SubscriptionContract",
    "userErrors": "SubscriptionContractUserError"
  },
  "implementsNode": false
};
export default SubscriptionContractSetNextBillingDatePayload;