
const LocationEditInput = {
  "name": "LocationEditInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "name": "String",
    "address": "LocationEditAddressInput",
    "fulfillsOnlineOrders": "Boolean",
    "metafields": "MetafieldInput"
  }
};
export default LocationEditInput;