
const VaultCreditCard = {
  "name": "VaultCreditCard",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "billingAddress": "CustomerCreditCardBillingAddress",
    "brand": "String",
    "expired": "Boolean",
    "expiryMonth": "Int",
    "expiryYear": "Int",
    "lastDigits": "String",
    "name": "String"
  },
  "implementsNode": false
};
export default VaultCreditCard;