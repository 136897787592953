
const SellingPlanFixedPricingPolicy = {
  "name": "SellingPlanFixedPricingPolicy",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "adjustmentType": "SellingPlanPricingPolicyAdjustmentType",
    "adjustmentValue": "SellingPlanPricingPolicyAdjustmentValue",
    "createdAt": "DateTime"
  },
  "implementsNode": false
};
export default SellingPlanFixedPricingPolicy;