
const QueryRoot = {
  "name": "QueryRoot",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "abandonment": "Abandonment",
    "abandonmentByAbandonedCheckoutId": "Abandonment",
    "app": "App",
    "appByHandle": "App",
    "appByKey": "App",
    "appDiscountType": "AppDiscountType",
    "appDiscountTypes": "AppDiscountType",
    "appInstallation": "AppInstallation",
    "appInstallations": "AppInstallationConnection",
    "automaticDiscount": "DiscountAutomatic",
    "automaticDiscountNode": "DiscountAutomaticNode",
    "automaticDiscountNodes": "DiscountAutomaticNodeConnection",
    "automaticDiscountSavedSearches": "SavedSearchConnection",
    "automaticDiscounts": "DiscountAutomaticConnection",
    "availableCarrierServices": "DeliveryCarrierServiceAndLocations",
    "availableLocales": "Locale",
    "carrierService": "DeliveryCarrierService",
    "cartTransforms": "CartTransformConnection",
    "cashTrackingSession": "CashTrackingSession",
    "cashTrackingSessions": "CashTrackingSessionConnection",
    "catalog": "Catalog",
    "catalogOperations": "ResourceOperation",
    "catalogs": "CatalogConnection",
    "catalogsCount": "Count",
    "channel": "Channel",
    "channels": "ChannelConnection",
    "checkoutBranding": "CheckoutBranding",
    "checkoutProfile": "CheckoutProfile",
    "checkoutProfiles": "CheckoutProfileConnection",
    "codeDiscountNode": "DiscountCodeNode",
    "codeDiscountNodeByCode": "DiscountCodeNode",
    "codeDiscountNodes": "DiscountCodeNodeConnection",
    "codeDiscountSavedSearches": "SavedSearchConnection",
    "collection": "Collection",
    "collectionByHandle": "Collection",
    "collectionRulesConditions": "CollectionRuleConditions",
    "collectionSavedSearches": "SavedSearchConnection",
    "collections": "CollectionConnection",
    "companies": "CompanyConnection",
    "companiesCount": "Count",
    "company": "Company",
    "companyContact": "CompanyContact",
    "companyContactRole": "CompanyContactRole",
    "companyLocation": "CompanyLocation",
    "companyLocations": "CompanyLocationConnection",
    "currentAppInstallation": "AppInstallation",
    "currentBulkOperation": "BulkOperation",
    "customer": "Customer",
    "customerMergeJobStatus": "CustomerMergeRequest",
    "customerMergePreview": "CustomerMergePreview",
    "customerPaymentMethod": "CustomerPaymentMethod",
    "customerSegmentMembers": "CustomerSegmentMemberConnection",
    "customerSegmentMembersQuery": "CustomerSegmentMembersQuery",
    "customerSegmentMembership": "SegmentMembershipResponse",
    "customers": "CustomerConnection",
    "deletionEvents": "DeletionEventConnection",
    "deliveryCustomization": "DeliveryCustomization",
    "deliveryCustomizations": "DeliveryCustomizationConnection",
    "deliveryProfile": "DeliveryProfile",
    "deliveryProfiles": "DeliveryProfileConnection",
    "deliverySettings": "DeliverySetting",
    "discountCodesCount": "Count",
    "discountNode": "DiscountNode",
    "discountNodes": "DiscountNodeConnection",
    "discountRedeemCodeBulkCreation": "DiscountRedeemCodeBulkCreation",
    "discountRedeemCodeSavedSearches": "SavedSearchConnection",
    "dispute": "ShopifyPaymentsDispute",
    "disputeEvidence": "ShopifyPaymentsDisputeEvidence",
    "domain": "Domain",
    "draftOrder": "DraftOrder",
    "draftOrderSavedSearches": "SavedSearchConnection",
    "draftOrderTag": "DraftOrderTag",
    "draftOrders": "DraftOrderConnection",
    "fileSavedSearches": "SavedSearchConnection",
    "files": "FileConnection",
    "fulfillment": "Fulfillment",
    "fulfillmentConstraintRules": "FulfillmentConstraintRule",
    "fulfillmentOrder": "FulfillmentOrder",
    "fulfillmentOrders": "FulfillmentOrderConnection",
    "fulfillmentService": "FulfillmentService",
    "giftCard": "GiftCard",
    "giftCards": "GiftCardConnection",
    "giftCardsCount": "Count",
    "inventoryItem": "InventoryItem",
    "inventoryItems": "InventoryItemConnection",
    "inventoryLevel": "InventoryLevel",
    "inventoryProperties": "InventoryProperties",
    "job": "Job",
    "location": "Location",
    "locations": "LocationConnection",
    "locationsAvailableForDeliveryProfiles": "Location",
    "locationsAvailableForDeliveryProfilesConnection": "LocationConnection",
    "manualHoldsFulfillmentOrders": "FulfillmentOrderConnection",
    "market": "Market",
    "marketByGeography": "Market",
    "marketLocalizableResource": "MarketLocalizableResource",
    "marketLocalizableResources": "MarketLocalizableResourceConnection",
    "marketLocalizableResourcesByIds": "MarketLocalizableResourceConnection",
    "marketingActivities": "MarketingActivityConnection",
    "marketingActivity": "MarketingActivity",
    "marketingEvent": "MarketingEvent",
    "marketingEvents": "MarketingEventConnection",
    "markets": "MarketConnection",
    "metafieldDefinition": "MetafieldDefinition",
    "metafieldDefinitionTypes": "MetafieldDefinitionType",
    "metafieldDefinitions": "MetafieldDefinitionConnection",
    "metafieldStorefrontVisibilities": "MetafieldStorefrontVisibilityConnection",
    "metafieldStorefrontVisibility": "MetafieldStorefrontVisibility",
    "metaobject": "Metaobject",
    "metaobjectByHandle": "Metaobject",
    "metaobjectDefinition": "MetaobjectDefinition",
    "metaobjectDefinitionByType": "MetaobjectDefinition",
    "metaobjectDefinitions": "MetaobjectDefinitionConnection",
    "metaobjects": "MetaobjectConnection",
    "node": "Node",
    "nodes": "Node",
    "order": "Order",
    "orderPaymentStatus": "OrderPaymentStatus",
    "orderSavedSearches": "SavedSearchConnection",
    "orders": "OrderConnection",
    "paymentCustomization": "PaymentCustomization",
    "paymentCustomizations": "PaymentCustomizationConnection",
    "paymentTermsTemplates": "PaymentTermsTemplate",
    "pendingOrdersCount": "Count",
    "priceList": "PriceList",
    "priceLists": "PriceListConnection",
    "priceRule": "PriceRule",
    "priceRuleSavedSearches": "SavedSearchConnection",
    "priceRules": "PriceRuleConnection",
    "primaryMarket": "Market",
    "privateMetafield": "PrivateMetafield",
    "privateMetafields": "PrivateMetafieldConnection",
    "product": "Product",
    "productByHandle": "Product",
    "productDuplicateJob": "ProductDuplicateJob",
    "productFeed": "ProductFeed",
    "productFeeds": "ProductFeedConnection",
    "productOperation": "ProductOperation",
    "productResourceFeedback": "ProductResourceFeedback",
    "productSavedSearches": "SavedSearchConnection",
    "productVariant": "ProductVariant",
    "productVariants": "ProductVariantConnection",
    "products": "ProductConnection",
    "productsCount": "Count",
    "publicApiVersions": "ApiVersion",
    "publication": "Publication",
    "publications": "PublicationConnection",
    "publicationsCount": "Count",
    "refund": "Refund",
    "return": "Return",
    "returnableFulfillment": "ReturnableFulfillment",
    "returnableFulfillments": "ReturnableFulfillmentConnection",
    "reverseDelivery": "ReverseDelivery",
    "reverseFulfillmentOrder": "ReverseFulfillmentOrder",
    "scriptTag": "ScriptTag",
    "scriptTags": "ScriptTagConnection",
    "segment": "Segment",
    "segmentFilterSuggestions": "SegmentFilterConnection",
    "segmentFilters": "SegmentFilterConnection",
    "segmentMigrations": "SegmentMigrationConnection",
    "segmentValueSuggestions": "SegmentValueConnection",
    "segments": "SegmentConnection",
    "segmentsCount": "Count",
    "sellingPlanGroup": "SellingPlanGroup",
    "sellingPlanGroups": "SellingPlanGroupConnection",
    "serverPixel": "ServerPixel",
    "shop": "Shop",
    "shopBillingPreferences": "ShopBillingPreferences",
    "shopLocales": "ShopLocale",
    "shopifyFunction": "ShopifyFunction",
    "shopifyFunctions": "ShopifyFunctionConnection",
    "shopifyPaymentsAccount": "ShopifyPaymentsAccount",
    "shopifyqlQuery": "ShopifyqlResponse",
    "staffMember": "StaffMember",
    "standardMetafieldDefinitionTemplates": "StandardMetafieldDefinitionTemplateConnection",
    "subscriptionBillingAttempt": "SubscriptionBillingAttempt",
    "subscriptionBillingAttempts": "SubscriptionBillingAttemptConnection",
    "subscriptionBillingCycle": "SubscriptionBillingCycle",
    "subscriptionBillingCycles": "SubscriptionBillingCycleConnection",
    "subscriptionContract": "SubscriptionContract",
    "subscriptionContracts": "SubscriptionContractConnection",
    "subscriptionDraft": "SubscriptionDraft",
    "taxonomy": "Taxonomy",
    "tenderTransactions": "TenderTransactionConnection",
    "translatableResource": "TranslatableResource",
    "translatableResources": "TranslatableResourceConnection",
    "translatableResourcesByIds": "TranslatableResourceConnection",
    "urlRedirect": "UrlRedirect",
    "urlRedirectImport": "UrlRedirectImport",
    "urlRedirectSavedSearches": "SavedSearchConnection",
    "urlRedirects": "UrlRedirectConnection",
    "validation": "Validation",
    "validations": "ValidationConnection",
    "webPixel": "WebPixel",
    "webhookSubscription": "WebhookSubscription",
    "webhookSubscriptions": "WebhookSubscriptionConnection"
  },
  "implementsNode": false
};
export default QueryRoot;