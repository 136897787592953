
const LineItem = {
  "name": "LineItem",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "canRestock": "Boolean",
    "contract": "SubscriptionContract",
    "currentQuantity": "Int",
    "customAttributes": "Attribute",
    "discountAllocations": "DiscountAllocation",
    "discountedTotal": "Money",
    "discountedTotalSet": "MoneyBag",
    "discountedUnitPrice": "Money",
    "discountedUnitPriceAfterAllDiscountsSet": "MoneyBag",
    "discountedUnitPriceSet": "MoneyBag",
    "duties": "Duty",
    "fulfillableQuantity": "Int",
    "fulfillmentService": "FulfillmentService",
    "fulfillmentStatus": "String",
    "id": "ID",
    "image": "Image",
    "isGiftCard": "Boolean",
    "lineItemGroup": "LineItemGroup",
    "merchantEditable": "Boolean",
    "name": "String",
    "nonFulfillableQuantity": "Int",
    "originalTotal": "Money",
    "originalTotalSet": "MoneyBag",
    "originalUnitPrice": "Money",
    "originalUnitPriceSet": "MoneyBag",
    "product": "Product",
    "quantity": "Int",
    "refundableQuantity": "Int",
    "requiresShipping": "Boolean",
    "restockable": "Boolean",
    "sellingPlan": "LineItemSellingPlan",
    "sku": "String",
    "staffMember": "StaffMember",
    "taxLines": "TaxLine",
    "taxable": "Boolean",
    "title": "String",
    "totalDiscount": "Money",
    "totalDiscountSet": "MoneyBag",
    "unfulfilledDiscountedTotal": "Money",
    "unfulfilledDiscountedTotalSet": "MoneyBag",
    "unfulfilledOriginalTotal": "Money",
    "unfulfilledOriginalTotalSet": "MoneyBag",
    "unfulfilledQuantity": "Int",
    "variant": "ProductVariant",
    "variantTitle": "String",
    "vendor": "String"
  },
  "implementsNode": true
};
export default LineItem;