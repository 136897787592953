
const StaffMemberPrivateData = {
  "name": "StaffMemberPrivateData",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "accountSettingsUrl": "URL",
    "createdAt": "DateTime",
    "permissions": "StaffMemberPermission"
  },
  "implementsNode": false
};
export default StaffMemberPrivateData;