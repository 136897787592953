
const PriceRulePrerequisiteToEntitlementQuantityRatio = {
  "name": "PriceRulePrerequisiteToEntitlementQuantityRatio",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "entitlementQuantity": "Int",
    "prerequisiteQuantity": "Int"
  },
  "implementsNode": false
};
export default PriceRulePrerequisiteToEntitlementQuantityRatio;