
const MarketWebPresenceUpdateInput = {
  "name": "MarketWebPresenceUpdateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "domainId": "ID",
    "defaultLocale": "String",
    "alternateLocales": "String",
    "subfolderSuffix": "String"
  }
};
export default MarketWebPresenceUpdateInput;