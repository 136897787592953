
const CustomerMomentEdge = {
  "name": "CustomerMomentEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "CustomerMoment"
  },
  "implementsNode": false
};
export default CustomerMomentEdge;