
const Segment = {
  "name": "Segment",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "creationDate": "DateTime",
    "id": "ID",
    "lastEditDate": "DateTime",
    "name": "String",
    "query": "String"
  },
  "implementsNode": true
};
export default Segment;