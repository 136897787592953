
const FulfillmentTrackingInfoUpdateV2Payload = {
  "name": "FulfillmentTrackingInfoUpdateV2Payload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "fulfillment": "Fulfillment",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default FulfillmentTrackingInfoUpdateV2Payload;