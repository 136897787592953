
const Cart = {
  "name": "Cart",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "attribute": "Attribute",
    "attributes": "Attribute",
    "buyerIdentity": "CartBuyerIdentity",
    "checkoutUrl": "URL",
    "cost": "CartCost",
    "createdAt": "DateTime",
    "deliveryGroups": "CartDeliveryGroupConnection",
    "discountAllocations": "CartDiscountAllocation",
    "discountCodes": "CartDiscountCode",
    "estimatedCost": "CartEstimatedCost",
    "id": "ID",
    "lines": "BaseCartLineConnection",
    "metafield": "Metafield",
    "metafields": "Metafield",
    "note": "String",
    "totalQuantity": "Int",
    "updatedAt": "DateTime"
  },
  "implementsNode": true
};
export default Cart;