
const CheckoutProfile = {
  "name": "CheckoutProfile",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "createdAt": "DateTime",
    "editedAt": "DateTime",
    "id": "ID",
    "isPublished": "Boolean",
    "name": "String",
    "updatedAt": "DateTime"
  },
  "implementsNode": true
};
export default CheckoutProfile;