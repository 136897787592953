
const LineItemConnection = {
  "name": "LineItemConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "LineItemEdge",
    "nodes": "LineItem",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default LineItemConnection;