
const QueryRoot = {
  "name": "QueryRoot",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "article": "Article",
    "articles": "ArticleConnection",
    "blog": "Blog",
    "blogByHandle": "Blog",
    "blogs": "BlogConnection",
    "cart": "Cart",
    "cartCompletionAttempt": "CartCompletionAttemptResult",
    "collection": "Collection",
    "collectionByHandle": "Collection",
    "collections": "CollectionConnection",
    "customer": "Customer",
    "localization": "Localization",
    "locations": "LocationConnection",
    "menu": "Menu",
    "metaobject": "Metaobject",
    "metaobjects": "MetaobjectConnection",
    "node": "Node",
    "nodes": "Node",
    "page": "Page",
    "pageByHandle": "Page",
    "pages": "PageConnection",
    "predictiveSearch": "PredictiveSearchResult",
    "product": "Product",
    "productByHandle": "Product",
    "productRecommendations": "Product",
    "productTags": "StringConnection",
    "productTypes": "StringConnection",
    "products": "ProductConnection",
    "publicApiVersions": "ApiVersion",
    "search": "SearchResultItemConnection",
    "shop": "Shop",
    "urlRedirects": "UrlRedirectConnection"
  },
  "implementsNode": false
};
export default QueryRoot;