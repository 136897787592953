
const Shop = {
  "name": "Shop",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "brand": "Brand",
    "description": "String",
    "id": "ID",
    "metafield": "Metafield",
    "metafields": "Metafield",
    "moneyFormat": "String",
    "name": "String",
    "paymentSettings": "PaymentSettings",
    "primaryDomain": "Domain",
    "privacyPolicy": "ShopPolicy",
    "refundPolicy": "ShopPolicy",
    "shippingPolicy": "ShopPolicy",
    "shipsToCountries": "CountryCode",
    "subscriptionPolicy": "ShopPolicyWithDefault",
    "termsOfService": "ShopPolicy"
  },
  "implementsNode": true
};
export default Shop;