
const CustomerRequestDataErasurePayload = {
  "name": "CustomerRequestDataErasurePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customerId": "ID",
    "userErrors": "CustomerRequestDataErasureUserError"
  },
  "implementsNode": false
};
export default CustomerRequestDataErasurePayload;