
const deliveryProfileUpdatePayload = {
  "name": "deliveryProfileUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "profile": "DeliveryProfile",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default deliveryProfileUpdatePayload;