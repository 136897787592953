
const TranslatableContent = {
  "name": "TranslatableContent",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "digest": "String",
    "key": "String",
    "locale": "String",
    "type": "LocalizableContentType",
    "value": "String"
  },
  "implementsNode": false
};
export default TranslatableContent;