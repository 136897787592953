
const TableResponse = {
  "name": "TableResponse",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "parseErrors": "ParseError",
    "tableData": "TableData"
  },
  "implementsNode": false
};
export default TableResponse;