
const ReturnLineItemConnection = {
  "name": "ReturnLineItemConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "ReturnLineItemEdge",
    "nodes": "ReturnLineItem",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default ReturnLineItemConnection;