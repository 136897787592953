
const MetaobjectDeletePayload = {
  "name": "MetaobjectDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedId": "ID",
    "userErrors": "MetaobjectUserError"
  },
  "implementsNode": false
};
export default MetaobjectDeletePayload;