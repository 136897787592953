
const CustomerPaymentMethodSendUpdateEmailPayload = {
  "name": "CustomerPaymentMethodSendUpdateEmailPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customer": "Customer",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default CustomerPaymentMethodSendUpdateEmailPayload;