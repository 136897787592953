
const MarketingEventEdge = {
  "name": "MarketingEventEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "MarketingEvent"
  },
  "implementsNode": false
};
export default MarketingEventEdge;