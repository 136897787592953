
const FulfillmentOrder = {
  "name": "FulfillmentOrder",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "assignedLocation": "FulfillmentOrderAssignedLocation",
    "channelId": "ID",
    "createdAt": "DateTime",
    "deliveryMethod": "DeliveryMethod",
    "destination": "FulfillmentOrderDestination",
    "fulfillAt": "DateTime",
    "fulfillBy": "DateTime",
    "fulfillmentHolds": "FulfillmentHold",
    "fulfillmentOrdersForMerge": "FulfillmentOrderConnection",
    "fulfillments": "FulfillmentConnection",
    "id": "ID",
    "internationalDuties": "FulfillmentOrderInternationalDuties",
    "lineItems": "FulfillmentOrderLineItemConnection",
    "locationsForMove": "FulfillmentOrderLocationForMoveConnection",
    "merchantRequests": "FulfillmentOrderMerchantRequestConnection",
    "order": "Order",
    "orderId": "ID",
    "orderName": "String",
    "orderProcessedAt": "DateTime",
    "requestStatus": "FulfillmentOrderRequestStatus",
    "status": "FulfillmentOrderStatus",
    "supportedActions": "FulfillmentOrderSupportedAction",
    "updatedAt": "DateTime"
  },
  "implementsNode": true
};
export default FulfillmentOrder;