
const SearchResultEdge = {
  "name": "SearchResultEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "SearchResult"
  },
  "implementsNode": false
};
export default SearchResultEdge;