
const GenericFile = {
  "name": "GenericFile",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "alt": "String",
    "id": "ID",
    "mimeType": "String",
    "originalFileSize": "Int",
    "previewImage": "Image",
    "url": "URL"
  },
  "implementsNode": true
};
export default GenericFile;