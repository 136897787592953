
const SubscriptionContractBase = {
  "name": "SubscriptionContractBase",
  "kind": "INTERFACE",
  "fieldBaseTypes": {
    "app": "App",
    "appAdminUrl": "URL",
    "currencyCode": "CurrencyCode",
    "customAttributes": "Attribute",
    "customer": "Customer",
    "customerPaymentMethod": "CustomerPaymentMethod",
    "deliveryMethod": "SubscriptionDeliveryMethod",
    "deliveryPrice": "MoneyV2",
    "discounts": "SubscriptionManualDiscountConnection",
    "lineCount": "Int",
    "lines": "SubscriptionLineConnection",
    "linesCount": "Count",
    "note": "String",
    "orders": "OrderConnection",
    "updatedAt": "DateTime"
  },
  "possibleTypes": ["SubscriptionBillingCycleEditedContract", "SubscriptionContract"]
};
export default SubscriptionContractBase;