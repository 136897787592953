
const SellingPlanRecurringBillingPolicyInput = {
  "name": "SellingPlanRecurringBillingPolicyInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "interval": "SellingPlanInterval",
    "intervalCount": "Int",
    "anchors": "SellingPlanAnchorInput",
    "minCycles": "Int",
    "maxCycles": "Int"
  }
};
export default SellingPlanRecurringBillingPolicyInput;