
const Model3d = {
  "name": "Model3d",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "alt": "String",
    "id": "ID",
    "mediaContentType": "MediaContentType",
    "presentation": "MediaPresentation",
    "previewImage": "Image",
    "sources": "Model3dSource"
  },
  "implementsNode": true
};
export default Model3d;