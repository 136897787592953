import React, { Component } from "react";
import { uploadFileToCloudinary } from '../../../querys/cloudinary';
import { getNetlifySnippetByTitle, updateNetlifySnippet, addNetlifySnippet } from "../../../querys/netlify-rest";
import { Alert } from "reactstrap";
import { AuthContext } from "../../../auth/auth";
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import Loader from "../../loader/loader";
import FileDropzone from "../../common/file-dropzone";
import FormHandleInput from "../../common/form-handle-input";
import LinksDropdown from "../../common/links-dropdown";

const snippetTitle2 = "Homepage Hero Image + Text"

class CustomizeImageText extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      logoFileName: "No file selected.",
      logoCloudinaryUrl: "",
      alert: false,
      alertMessage: "",
      alertColor: "success",
      homepageSnippetContent: {},
      selectedOption: 0,
      fileAlert: false,
      fileAlertMessage: "",
      fileAlertColor: "success",
      link: ""
    };
  }


  componentDidMount = () => {
    this.fetchNetlifySnippet();
  }

  fetchNetlifySnippet = () => {
    // Check for existing Netlify snippet
    getNetlifySnippetByTitle(this.context.reseller_site_id, snippetTitle2)
      .then((response) => {
        if (response.id && response.general) {
          // Update the state with the Netlify snippet data

          const decodedSnippet = response.general.replace("<script>window.homepageSettings=", "").replace(";</script>", "")
          const homepageSnippetContent = JSON.parse(decodedSnippet)

          const logoFileName = homepageSnippetContent.logoFileName

          let link = homepageSnippetContent.ctaButtonLink;
          if (homepageSnippetContent.handle) {
            link = link.replace(new RegExp(`/${homepageSnippetContent.handle}/*$`), "/");
          }

          this.setState({
            netlifySnippetId: response.id,
            netlifySnippetContent: response.general,
            homepageSnippetContent,
            loading: false,
            logoFileName,
            link
          });
        } else {
          console.log(response);
          this.setState({ loading: false })
        }
      });
  }

  handleDrop = (acceptedFiles) => {

    if (acceptedFiles.length > 0) {

      this.setState({
        logoFileName: "Uploading...",
        fileAlert: false
      })
      console.log(acceptedFiles)
      const fileName = acceptedFiles[0].name
      const size = acceptedFiles[0].size;

      if (size <= 10000000) {

        uploadFileToCloudinary(acceptedFiles[0], 1400, this.context.store_subdomain)
          .then((imgUrl) => {
            this.setState({
              logoFileName: fileName,
              logoCloudinaryUrl: imgUrl
            });
          });
      } else {
        this.setState({
          fileAlert: true,
          fileAlertColor: "danger",
          fileAlertMessage: "Image file needs to be under 10MB. Please try with another image.",
          logoFileName: "No file selected."
        })
      }
    } else {
      this.setState({
        fileAlert: true,
        fileAlertColor: "danger",
        fileAlertMessage: "Wrong file type. Please upload an image.",
        logoFileName: "No file selected."
      })
    }
  }

  handleValidSubmit = (_event, values) => {

    let { homepageSnippetContent, logoCloudinaryUrl, logoFileName, link } = this.state
    values.ctaButtonLink = link + values.handle;

    if ((values.ctaButtonLink !== "" && values.ctaButtonLabel === "") || (values.ctaButtonLink === "" && values.ctaButtonLabel !== "")) {
      this.setState({
        alert: true,
        alertMessage: `Please make sure to fill both CTA Button Label and CTA Button Link or none.`,
        alertColor: "danger"
      });
      return;
    }

    if (values.ctaButtonLink !== "") {

      let expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
      let relativeExpression = /^(\/*[a-zA-Z0-9!@#&=?$\-]+)+\/*$/gi;
      var regex = new RegExp(expression);
      var relativeRegex = new RegExp(relativeExpression);


      //Check if the CTA Button Link is not a relative URL
      if (regex.test(values.ctaButtonLink) === true) {

        const conditions = ["http://", "https://"];

        //Checks if the URL is missing the protocol
        const hasProtocol = conditions.some(el => values.ctaButtonLink.includes(el));

        if (!hasProtocol) {

          this.setState({
            alert: true,
            alertMessage: `Please make sure to include the protocol in front of the CTA Button Link (${conditions.map((el, index) => ` ${el}`)})`,
            alertColor: "danger"
          });
          return;
        }
      } else if (relativeRegex.test(values.ctaButtonLink) === false) {

        this.setState({
          alert: true,
          alertMessage: `Please make sure the relative URL of the CTA Button Link is valid.`,
          alertColor: "danger"
        });
        return;
      }
    };

    homepageSnippetContent = {
      ...homepageSnippetContent,
      ...values
    }

    if (logoCloudinaryUrl != "") homepageSnippetContent.heroUrl = logoCloudinaryUrl
    if (logoFileName != "") homepageSnippetContent.logoFileName = logoFileName

    const snippetContent = `<script>window.homepageSettings=${JSON.stringify(homepageSnippetContent)};</script>`;

    getNetlifySnippetByTitle(this.context.reseller_site_id, snippetTitle2)
      .then((response) => {
        if (response.id) {
          const netlifySnippetId = response.id;
          // Update Netlify Snippet
          updateNetlifySnippet(this.context.reseller_site_id, snippetTitle2, snippetContent, netlifySnippetId).then((response) => {
            if (response.status === 200) {
              this.setState({
                alert: true,
                alertMessage: "Success: Your homepage settings were updated on your site.",
                alertColor: "success"
              });
              // Fetch the Snippet ID and update state
              this.fetchNetlifySnippet();
            } else {
              console.log(response);
              this.setState({
                alert: true,
                alertMessage: response.message,
                alertColor: "danger"
              });
            }
          });
        } else {
          // Add Netlify Snippet
          addNetlifySnippet(this.context.reseller_site_id, snippetTitle2, snippetContent)
            .then((response) => {
              if (response.status === 201) {
                this.setState({
                  alert: true,
                  alertMessage: "Success: Your homepage settings were added to your site.",
                  alertColor: "success"
                });
                // Fetch the Snippet ID and update state
                this.fetchNetlifySnippet();
              } else {
                console.log(response);
                this.setState({
                  alert: true,
                  alertMessage: response.message,
                  alertColor: "danger"
                });
              }
            });
        }
      });
  }

  toggle() {
    this.setState({
      alert: !this.state.alert
    });
  }

  handleOptionChange = (changeEvent) => {
    this.setState({ selectedOption: parseInt(changeEvent.target.value) });
  }

  onLinkTypeSelect = (selectedOption) => {
    this.setState({
      link: selectedOption.link
    });
  }

  toggleFileAlert = () => {
    this.setState({
      fileAlert: !this.state.fileAlert
    });
  }

  render() {

    if (this.state.loading) return <Loader />

    const { logoFileName, homepageSnippetContent, link } = this.state

    return (
      <React.Fragment>
        <div className="card">
          <div className="card-header">
            <h5>Homepage Hero Image + Text</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-4 col-xl-2 pr-md-3 mb-4">
                <p className="text-secondary">
                  The hero image & text is your primary visual messaging area on the home page. Upload your image, edit your headline, add a label, and link your call to action button.
                </p>
                <p className="text-secondary">
                  Minimum image size required for the hero image is 1600px &#x2715; 700px - JPG or PNG.
                </p>
              </div>
              <div className="col-md-8 col-xl-9 offset-xl-1">
                <FileDropzone handleDrop={this.handleDrop} fileName={logoFileName} fileTypes="image/*" />
                <Alert color={this.state.fileAlertColor} isOpen={this.state.fileAlert} toggle={this.toggleFileAlert} > {this.state.fileAlertMessage} </Alert>
              </div>
            </div>
            <AvForm
              className="user-add offset-lg-1 needs-validation add-product-form"
              onValidSubmit={this.handleValidSubmit}
              onInvalidSubmit={this.handleInvalidSubmit}
              model={homepageSnippetContent}
            >
              <div className="row form-group align-items-start">
                <div className="col-xl-3 col-md-4">
                  <label>
                    <span>*</span> Headline Text Location
                  </label>
                  <p className="text-secondary">Adjust the placement of your text and CTA button over your hero image.</p>
                </div>
                <div className="col-xl-6 col-md-8">
                  <div className="row">
                    <div className="col-sm-12 col-md-6 mb-4 border-right anchor-input pr-md-4">
                      <div className="mb-1"><label> Vertical Placement</label></div>
                      <AvRadioGroup required inline name="verticalAlignment" className="d-flex flex-fill justify-content-between">
                        <AvRadio type="radio" name="verticalAlignment" className="radio_animated" value="top" label="Top" />
                        <AvRadio type="radio" name="verticalAlignment" className="radio_animated" value="center" label="Center" />
                        <AvRadio type="radio" name="verticalAlignment" className="radio_animated" value="bottom" label="Bottom" />
                      </AvRadioGroup>
                    </div>
                    <div className="col-sm-12 col-md-6 mb-4 pl-md-4">
                      <div className="mb-1"><label> Horizontal Placement</label></div>
                      <AvRadioGroup required inline name="horizontalAlignment" className="d-flex justify-content-between">
                        <AvRadio type="radio" name="horizontalAlignment" className="radio_animated" value="left" label="Left" />
                        <AvRadio type="radio" name="horizontalAlignment" className="radio_animated" value="right" label="Right" />
                        <AvRadio type="radio" name="horizontalAlignment" className="radio_animated" value="center" label="Center" />
                      </AvRadioGroup>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row form-group">
                <div className="col-xl-3 col-md-4">
                  <label>Label</label>
                  <p className="text-secondary">Give your products or promo more context.</p>
                </div>
                <div className="col-xl-9 col-md-8">
                  <AvField
                    name="label"
                    className="form-control"
                    id="validationCustom0"
                    type="text"
                    required=""
                    placeholder="NOW AVAILABLE FOR KWIK-SHIP"
                  />
                </div>
              </div>
              <div className="form-group row align-items-start">
                <div className="col-xl-3 col-md-4">
                  <label>
                    <span>*</span> Headline
                  </label>
                  <p className="text-secondary">Add a headline to promote your products or promo.</p>
                </div>
                <div className="col-md-8 col-xl-9">
                  <AvField
                    name="headline"
                    className="form-control"
                    id="validationCustom1"
                    type="text"
                    required=""
                    placeholder="NOW AVAILABLE FOR KWIK-SHIP"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-xl-3 col-md-4">
                  <label>
                    <span>*</span> Headline Alignment
                  </label>
                  <p className="text-secondary">Set the justification of your headline text.</p>
                </div>
                <div className="col-xl-6 col-md-8">
                  <AvRadioGroup required inline name="headlineAlignment" className="headling-alignment checkbox checkbox-primary col-md-8 col-xl-9 ">
                    <AvRadio
                      value="left"
                      id="left-aligned"
                      className="ml-md-3 radio_animated"
                      type="checkbox"
                      data-original-title=""
                      title=""
                      label="Left Aligned"
                    />
                    <AvRadio
                      value="center"
                      id="centered"
                      className="ml-4 radio_animated"
                      type="checkbox"
                      data-original-title=""
                      title=""
                      label="Centered"
                    />
                  </AvRadioGroup >
                </div>
              </div>
              <div className="form-group row">
                <div className="col-xl-3 col-md-4">
                  <label>
                    CTA Button Label
                </label>
                  <p className="text-secondary">What do you want your button to say?</p>
                </div>
                <div className="col-md-8 col-xl-9">
                  <AvField
                    name="ctaButtonLabel"
                    className="form-control"
                    id="validationCustom-cta-button-label"
                    type="input"
                    placeholder="shop now"
                  />
                </div>
              </div>
              <div className="form-group row ">
                <div className="col-xl-3 col-md-4">
                  <label>
                    CTA Button Link
                   </label>
                  <p className="text-secondary">Where do you want your button to go?</p>
                </div>
                <div className="col-md-8 col-xl-9">
                  <div className="form-group">
                    <label className="mb-2">Link type</label>
                    <div className="form-field">
                      <LinksDropdown name="linkType" selectedValue={homepageSnippetContent.linkType} onChange={this.onLinkTypeSelect} />
                    </div>
                  </div>
                  <FormHandleInput labelText="Link" name="handle" url={link} />
                </div>
              </div>
              <div className="offset-md-4 offset-xl-3">
                <div className="col-sm-12 mt-4">
                  <button className="btn btn-primary mt-3" type="submit">
                    Save
                   </button>
                  <br></br>
                  <br></br>
                  <Alert color={this.state.alertColor} isOpen={this.state.alert} toggle={this.toggle.bind(this)} > {this.state.alertMessage} </Alert>
                </div>
              </div>
            </AvForm>
          </div>
        </div>
      </React.Fragment >
    );
  }
}

CustomizeImageText.contextType = AuthContext;

export default CustomizeImageText;
