
const SubscriptionDeliveryMethodLocalDeliveryOption = {
  "name": "SubscriptionDeliveryMethodLocalDeliveryOption",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "String",
    "description": "String",
    "instructions": "String",
    "phone": "String",
    "presentmentTitle": "String",
    "title": "String"
  },
  "implementsNode": false
};
export default SubscriptionDeliveryMethodLocalDeliveryOption;