
const MetafieldDefinitionCreateUserError = {
  "name": "MetafieldDefinitionCreateUserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "MetafieldDefinitionCreateUserErrorCode",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
export default MetafieldDefinitionCreateUserError;