
const StandardMetafieldDefinitionTemplate = {
  "name": "StandardMetafieldDefinitionTemplate",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "description": "String",
    "id": "ID",
    "key": "String",
    "name": "String",
    "namespace": "String",
    "ownerTypes": "MetafieldOwnerType",
    "type": "MetafieldDefinitionType",
    "validations": "MetafieldDefinitionValidation",
    "visibleToStorefrontApi": "Boolean"
  },
  "implementsNode": true
};
export default StandardMetafieldDefinitionTemplate;