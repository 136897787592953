
const PublishablePublishToCurrentChannelPayload = {
  "name": "PublishablePublishToCurrentChannelPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "publishable": "Publishable",
    "shop": "Shop",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default PublishablePublishToCurrentChannelPayload;