
const InventoryAdjustQuantitiesInput = {
  "name": "InventoryAdjustQuantitiesInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "reason": "String",
    "name": "String",
    "referenceDocumentUri": "String",
    "changes": "InventoryChangeInput"
  }
};
export default InventoryAdjustQuantitiesInput;