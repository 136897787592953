
const MetafieldStorefrontVisibilityConnection = {
  "name": "MetafieldStorefrontVisibilityConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "MetafieldStorefrontVisibilityEdge",
    "nodes": "MetafieldStorefrontVisibility",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default MetafieldStorefrontVisibilityConnection;