
const Video = {
  "name": "Video",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "alt": "String",
    "createdAt": "DateTime",
    "duration": "Int",
    "fileErrors": "FileError",
    "fileStatus": "FileStatus",
    "filename": "String",
    "id": "ID",
    "mediaContentType": "MediaContentType",
    "mediaErrors": "MediaError",
    "mediaWarnings": "MediaWarning",
    "originalSource": "VideoSource",
    "preview": "MediaPreviewImage",
    "sources": "VideoSource",
    "status": "MediaStatus",
    "updatedAt": "DateTime"
  },
  "implementsNode": true
};
export default Video;