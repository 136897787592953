
const MediaImageOriginalSource = {
  "name": "MediaImageOriginalSource",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "fileSize": "Int",
    "url": "URL"
  },
  "implementsNode": false
};
export default MediaImageOriginalSource;