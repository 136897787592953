
const Channel = {
  "name": "Channel",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "app": "App",
    "collectionPublicationsV3": "ResourcePublicationConnection",
    "collections": "CollectionConnection",
    "handle": "String",
    "hasCollection": "Boolean",
    "id": "ID",
    "name": "String",
    "navigationItems": "NavigationItem",
    "overviewPath": "URL",
    "productPublications": "ProductPublicationConnection",
    "productPublicationsV3": "ResourcePublicationConnection",
    "products": "ProductConnection",
    "productsCount": "Count",
    "supportsFuturePublishing": "Boolean"
  },
  "implementsNode": true
};
export default Channel;