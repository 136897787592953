
const PublicationResourceOperation = {
  "name": "PublicationResourceOperation",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "ID",
    "processedRowCount": "Int",
    "rowCount": "RowCount",
    "status": "ResourceOperationStatus"
  },
  "implementsNode": true
};
export default PublicationResourceOperation;