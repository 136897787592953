
const SegmentFilter = {
  "name": "SegmentFilter",
  "kind": "INTERFACE",
  "fieldBaseTypes": {
    "localizedName": "String",
    "multiValue": "Boolean",
    "queryName": "String"
  },
  "possibleTypes": ["SegmentAssociationFilter", "SegmentBooleanFilter", "SegmentDateFilter", "SegmentEnumFilter", "SegmentEventFilter", "SegmentFloatFilter", "SegmentIntegerFilter", "SegmentStringFilter"]
};
export default SegmentFilter;