
const CatalogContextUpdatePayload = {
  "name": "CatalogContextUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "catalog": "Catalog",
    "userErrors": "CatalogUserError"
  },
  "implementsNode": false
};
export default CatalogContextUpdatePayload;