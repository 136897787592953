
const ParseError = {
  "name": "ParseError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "ParseErrorCode",
    "message": "String",
    "range": "ParseErrorRange"
  },
  "implementsNode": false
};
export default ParseError;