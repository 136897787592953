
const Company = {
  "name": "Company",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "contactCount": "Int",
    "contactRoles": "CompanyContactRoleConnection",
    "contacts": "CompanyContactConnection",
    "contactsCount": "Count",
    "createdAt": "DateTime",
    "customerSince": "DateTime",
    "defaultCursor": "String",
    "defaultRole": "CompanyContactRole",
    "draftOrders": "DraftOrderConnection",
    "events": "EventConnection",
    "externalId": "String",
    "hasTimelineComment": "Boolean",
    "id": "ID",
    "lifetimeDuration": "String",
    "locations": "CompanyLocationConnection",
    "locationsCount": "Count",
    "mainContact": "CompanyContact",
    "metafield": "Metafield",
    "metafieldDefinitions": "MetafieldDefinitionConnection",
    "metafields": "MetafieldConnection",
    "name": "String",
    "note": "String",
    "orders": "OrderConnection",
    "ordersCount": "Count",
    "privateMetafield": "PrivateMetafield",
    "privateMetafields": "PrivateMetafieldConnection",
    "totalSpent": "MoneyV2",
    "updatedAt": "DateTime"
  },
  "implementsNode": true
};
export default Company;