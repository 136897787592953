
const QuantityPriceBreak = {
  "name": "QuantityPriceBreak",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "minimumQuantity": "Int",
    "price": "MoneyV2"
  },
  "implementsNode": false
};
export default QuantityPriceBreak;