
const ShopifyProtectOrderSummary = {
  "name": "ShopifyProtectOrderSummary",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "eligibility": "ShopifyProtectOrderEligibility",
    "status": "ShopifyProtectStatus"
  },
  "implementsNode": false
};
export default ShopifyProtectOrderSummary;