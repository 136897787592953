
const Menu = {
  "name": "Menu",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "handle": "String",
    "id": "ID",
    "items": "MenuItem",
    "itemsCount": "Int",
    "title": "String"
  },
  "implementsNode": true
};
export default Menu;