
const CustomerRemoveTaxExemptionsPayload = {
  "name": "CustomerRemoveTaxExemptionsPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customer": "Customer",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default CustomerRemoveTaxExemptionsPayload;