
const SubscriptionDeliveryMethodShipping = {
  "name": "SubscriptionDeliveryMethodShipping",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "address": "SubscriptionMailingAddress",
    "shippingOption": "SubscriptionDeliveryMethodShippingOption"
  },
  "implementsNode": false
};
export default SubscriptionDeliveryMethodShipping;