
const CheckoutBrandingColors = {
  "name": "CheckoutBrandingColors",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "global": "CheckoutBrandingColorGlobal",
    "schemes": "CheckoutBrandingColorSchemes"
  },
  "implementsNode": false
};
export default CheckoutBrandingColors;