
const CheckoutBrandingTextField = {
  "name": "CheckoutBrandingTextField",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "border": "CheckoutBrandingBorder",
    "typography": "CheckoutBrandingTypographyStyle"
  },
  "implementsNode": false
};
export default CheckoutBrandingTextField;