
const SegmentDeletePayload = {
  "name": "SegmentDeletePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedSegmentId": "ID",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default SegmentDeletePayload;