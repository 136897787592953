
const Filter = {
  "name": "Filter",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "id": "String",
    "label": "String",
    "presentation": "FilterPresentation",
    "type": "FilterType",
    "values": "FilterValue"
  },
  "implementsNode": false
};
export default Filter;