
const CustomerJourneySummary = {
  "name": "CustomerJourneySummary",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "customerOrderIndex": "Int",
    "daysToConversion": "Int",
    "firstVisit": "CustomerVisit",
    "lastVisit": "CustomerVisit",
    "moments": "CustomerMomentConnection",
    "momentsCount": "Count",
    "ready": "Boolean"
  },
  "implementsNode": false
};
export default CustomerJourneySummary;