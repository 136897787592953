
const PublicationUpdateInput = {
  "name": "PublicationUpdateInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "publishablesToAdd": "ID",
    "publishablesToRemove": "ID",
    "autoPublish": "Boolean"
  }
};
export default PublicationUpdateInput;