
const MetaobjectCapabilitiesOnlineStore = {
  "name": "MetaobjectCapabilitiesOnlineStore",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "data": "MetaobjectCapabilityDefinitionDataOnlineStore",
    "enabled": "Boolean"
  },
  "implementsNode": false
};
export default MetaobjectCapabilitiesOnlineStore;