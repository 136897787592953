
const SubscriptionBillingCycle = {
  "name": "SubscriptionBillingCycle",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "billingAttemptExpectedDate": "DateTime",
    "billingAttempts": "SubscriptionBillingAttemptConnection",
    "cycleEndAt": "DateTime",
    "cycleIndex": "Int",
    "cycleStartAt": "DateTime",
    "edited": "Boolean",
    "editedContract": "SubscriptionBillingCycleEditedContract",
    "skipped": "Boolean",
    "sourceContract": "SubscriptionContract",
    "status": "SubscriptionBillingCycleBillingCycleStatus"
  },
  "implementsNode": false
};
export default SubscriptionBillingCycle;