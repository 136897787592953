
const SegmentMigrationConnection = {
  "name": "SegmentMigrationConnection",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "edges": "SegmentMigrationEdge",
    "nodes": "SegmentMigration",
    "pageInfo": "PageInfo"
  },
  "implementsNode": false
};
export default SegmentMigrationConnection;