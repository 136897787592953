
const ReturnLineItemInput = {
  "name": "ReturnLineItemInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "fulfillmentLineItemId": "ID",
    "quantity": "Int",
    "returnReason": "ReturnReason",
    "returnReasonNote": "String"
  }
};
export default ReturnLineItemInput;