
const FulfillmentOrderSplitPayload = {
  "name": "FulfillmentOrderSplitPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "fulfillmentOrderSplits": "FulfillmentOrderSplitResult",
    "userErrors": "FulfillmentOrderSplitUserError"
  },
  "implementsNode": false
};
export default FulfillmentOrderSplitPayload;