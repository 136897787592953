
const SearchFilter = {
  "name": "SearchFilter",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "key": "String",
    "value": "String"
  },
  "implementsNode": false
};
export default SearchFilter;