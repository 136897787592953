
const CartInputMetafieldInput = {
  "name": "CartInputMetafieldInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "key": "String",
    "value": "String",
    "type": "String"
  }
};
export default CartInputMetafieldInput;