
const ShopPolicy = {
  "name": "ShopPolicy",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "body": "HTML",
    "id": "ID",
    "translations": "Translation",
    "type": "ShopPolicyType",
    "url": "URL"
  },
  "implementsNode": true
};
export default ShopPolicy;