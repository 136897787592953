
const CheckoutBrandingCustomizationsInput = {
  "name": "CheckoutBrandingCustomizationsInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "global": "CheckoutBrandingGlobalInput",
    "header": "CheckoutBrandingHeaderInput",
    "headingLevel1": "CheckoutBrandingHeadingLevelInput",
    "headingLevel2": "CheckoutBrandingHeadingLevelInput",
    "headingLevel3": "CheckoutBrandingHeadingLevelInput",
    "footer": "CheckoutBrandingFooterInput",
    "main": "CheckoutBrandingMainInput",
    "orderSummary": "CheckoutBrandingOrderSummaryInput",
    "control": "CheckoutBrandingControlInput",
    "textField": "CheckoutBrandingTextFieldInput",
    "checkbox": "CheckoutBrandingCheckboxInput",
    "select": "CheckoutBrandingSelectInput",
    "primaryButton": "CheckoutBrandingButtonInput",
    "secondaryButton": "CheckoutBrandingButtonInput",
    "favicon": "CheckoutBrandingImageInput",
    "choiceList": "CheckoutBrandingChoiceListInput",
    "merchandiseThumbnail": "CheckoutBrandingMerchandiseThumbnailInput",
    "expressCheckout": "CheckoutBrandingExpressCheckoutInput",
    "buyerJourney": "CheckoutBrandingBuyerJourneyInput",
    "cartLink": "CheckoutBrandingCartLinkInput"
  }
};
export default CheckoutBrandingCustomizationsInput;