
const Metaobject = {
  "name": "Metaobject",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "capabilities": "MetaobjectCapabilityData",
    "createdBy": "App",
    "createdByApp": "App",
    "createdByStaff": "StaffMember",
    "definition": "MetaobjectDefinition",
    "displayName": "String",
    "field": "MetaobjectField",
    "fields": "MetaobjectField",
    "handle": "String",
    "id": "ID",
    "referencedBy": "MetafieldRelationConnection",
    "staffMember": "StaffMember",
    "thumbnailField": "MetaobjectField",
    "type": "String",
    "updatedAt": "DateTime"
  },
  "implementsNode": true
};
export default Metaobject;