
const SubscriptionContractStatusUpdateUserError = {
  "name": "SubscriptionContractStatusUpdateUserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "SubscriptionContractStatusUpdateErrorCode",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
export default SubscriptionContractStatusUpdateUserError;