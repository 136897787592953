
const TranslationUserError = {
  "name": "TranslationUserError",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "code": "TranslationErrorCode",
    "field": "String",
    "message": "String"
  },
  "implementsNode": false
};
export default TranslationUserError;