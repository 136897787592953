import {
    Settings,
    Edit2,
    User,
    CreditCard,
    LogOut,
    Menu,
    Clipboard,
    FileText,
    Star
} from 'react-feather';


export const MENUITEMS = [
    {
        title: 'Products', icon: Clipboard, type: 'link', active: false, path: '/products', showBuildingLabel: true,
    },
    {
        title: 'Categories', icon: Star, type: 'link', active: false, path: '/categories', showBuildingLabel: true
    },
    {
        title: 'Menus', icon: Menu, type: 'sub', active: false, children: [
            { path: '/navigation/menus', title: 'Menus', type: 'link', showBuildingLabel: true },
            { path: '/navigation/footer', title: 'Footer', type: 'link', showBuildingLabel: true  }
        ]
    },
    {
        title: 'Pages', icon: FileText, type: 'link', active: true, path: '/pages', showBuildingLabel: true
    },
    {
        title: 'Customize', icon: Edit2, type: 'sub', active: false, children: [
            { path: '/customize/homepage', title: 'Homepage Layout', type: 'link' },
            { path: '/customize/typography', title: 'Color & Typography', type: 'link' }
        ]
    },
    {
        title: 'Customers', icon: User, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/customers`,
    },
    {
        title: 'Orders', icon: CreditCard, type: 'link', active: false, path: '/orders'
    },
    {
        title: 'Settings', icon: Settings, type: 'sub', active: false, children: [
            { path: '/settings/preferences', title: 'Preferences', type: 'link' },
            { path: '/settings/help-faq', title: 'Help & FAQs', type: 'link' },
            { path: '/settings/google-analytics', title: 'Google Analytics', type: 'link' }
        ]
    },
    {
        title: 'Logout', icon: LogOut, type: 'link', active: false
    }
]
