import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/bread-crumb";
import TypographySelect from "./components/customize-typography-select";
import ColorSettings from "./components/color-settings"
import { getNetlifySnippetByTitle } from '../../querys/netlify-rest';

import { titleFonts, bodyFonts } from './fonts'
import { AuthContext } from '../../auth/auth'

const snippetTitles = [
  {
    id: "titles", value: "Titles CSS",
  },
  { id: "body", value: "Body CSS" }
]

export class CustomizeTypography extends Component {

  constructor(props) {
    super(props)
    this.state = {
      body_typography_name: "Roboto",
      body_typography_code: "font-family: 'Roboto', sans-serif !important;",
      body_typography_google: `<link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet">`,
      body_snippet_id: null,
      titles_typography_name: "Merriweather Bold",
      titles_typography_code: "font-family: 'Merriweather', serif !important;",
      titles_typography_google: `<link href="https://fonts.googleapis.com/css2?family=Merriweather:wght@700&display=swap" rel="stylesheet">`,
      titles_snippet_id: null
    }
  }

  componentDidMount = () => {
    this.fetchSnippets();
    window.analytics.page();
  }

  fetchSnippets = () => {

    snippetTitles.forEach(title => {
      getNetlifySnippetByTitle(this.context.reseller_site_id, title.value)
        .then(response => {
          if (response.id && response.general) {
            this.decodeSnippet(response.general)
            this.setState({ [`${title.id}_snippet_id`]: response.id })
          }
        })
    })
  }

  decodeSnippet = (content) => {
    const fields = ["body_typography_code", "body_typography_name","body_typography_google", "titles_typography_name", "titles_typography_code", "titles_typography_google"]

    fields.forEach(field => {
      if (content.indexOf(field + "=") > -1) {
        let typographyValue = content.split(field + "=")[1].split("*/")[0].trim();
        this.setState({ [field]: typographyValue });
      }
    });

  }

  onTypographySelect = (fontName, id) => {

    let font = {}
    
    if (id === "titles") {
      font = titleFonts.find(t => t.name === fontName)
    } else {
      font = bodyFonts.find(t => t.name === fontName)
    }
    this.setState({
      [`${id}_typography_name`]: fontName,
      [`${id}_typography_code`]: font.code,
      [`${id}_typography_google`]: font.googleFont
    })

  }

  render() {

    return (
      <Fragment>
        <Breadcrumb mainTitle="Color & Typography" title="Color" parent="Customize" />
        <div className="typography container-fluid">
          <div className="card" key={1}>
            <div className="card-body pb-4 pt-0">
              <div className="row">
                <div className="col-sm-12 col-md-3 pr-md-5 pt-md-5 mt-md-1">
                  <p className="text-secondary pt-4">
                    ADA (Americans with Disabilities Act) compliance is a priority for Stouse and our resellers.
                    Please be considerate of your visually impaired customers by choosing colors with a high enough contrast ratio to ensure legibility.
                    </p>
                  <p><b><a href="https://webaim.org/resources/contrastchecker/" target="_blank" rel="noopener noreferrer">Color Contrast Checker</a></b></p>
                  <p className="text-secondary">If using a HEX code value, it must be proceeded by a hashtag. For example, #F58320.</p>
                </div>
                <div className="col-sm-12 pl-md-5 col-md-9">
                  <ColorSettings />
                </div>
              </div>
            </div>
          </div>
          <div className="card" key={2}>
            <div className="card-body pb-4 pt-0">
              <div className="row">
                <div className="col">
                  <TypographySelect
                    fontName={this.state.titles_typography_name}
                    fontCode={this.state.titles_typography_code}
                    fontGoogle={this.state.titles_typography_google}
                    title="Titles"
                    id="titles"
                    snippetId={this.state.titles_snippet_id}
                    snippetTitle="Titles CSS"
                    fonts={titleFonts}
                    onTypographySelect={this.onTypographySelect}
                    fetchSnippets={this.fetchSnippets}
                  />
                </div>
                <div className="col">
                  <TypographySelect
                    fontName={this.state.body_typography_name}
                    fontCode={this.state.body_typography_code}
                    fontGoogle={this.state.body_typography_google}
                    title="Body text & buttons"
                    id="body"
                    snippetId={this.state.body_snippet_id}
                    snippetTitle="Body CSS"
                    fonts={bodyFonts}
                    onTypographySelect={this.onTypographySelect}
                    fetchSnippets={this.fetchSnippets}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

CustomizeTypography.contextType = AuthContext

export default CustomizeTypography;
