
const PublicationUpdatePayload = {
  "name": "PublicationUpdatePayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "publication": "Publication",
    "userErrors": "PublicationUserError"
  },
  "implementsNode": false
};
export default PublicationUpdatePayload;