
const DeliveryUpdateConditionInput = {
  "name": "DeliveryUpdateConditionInput",
  "kind": "INPUT_OBJECT",
  "inputFieldBaseTypes": {
    "id": "ID",
    "criteria": "Float",
    "criteriaUnit": "String",
    "field": "DeliveryConditionField",
    "operator": "DeliveryConditionOperator"
  }
};
export default DeliveryUpdateConditionInput;