
const ProductDeleteImagesPayload = {
  "name": "ProductDeleteImagesPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "deletedImageIds": "ID",
    "product": "Product",
    "userErrors": "UserError"
  },
  "implementsNode": false
};
export default ProductDeleteImagesPayload;