import React, { Component } from "react";
import { getNetlifySnippetByTitle, updateNetlifySnippet, addNetlifySnippet } from "../../../querys/netlify-rest";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { AuthContext } from "../../../auth/auth"
import { encodeSnippet, decodeSnippet } from "../../utils/snippets"
import { Alert } from "reactstrap";
import Loader from "../../loader/loader";


const snippetTitle = "Flexible Messaging Area"

class CustomizeMessage extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      netlifySnippetId: null,
      netlifySnippetContent: null,
      flexibleMessage: {},
      loading: true,
      alert: false,
      alertMessage: "",
      alertColor: "success",
    }
  }

  componentDidMount = () => {
    this.fetchNetlifySnippet();
  }

  fetchNetlifySnippet = () => {
    // Check for existing Netlify snippet
    getNetlifySnippetByTitle(this.context.reseller_site_id, snippetTitle)
      .then((response) => {
        if (response.id && response.general) {
          // Update the state with the Netlify snippet data

          const decodedSnippet = decodeSnippet(response.general, "flexibleMessage")

          this.setState({
            netlifySnippetId: response.id,
            netlifySnippetContent: response.general,
            flexibleMessage: { messaging: decodedSnippet },
            loading: false,
          });
        } else {
          console.log(response);
          this.setState({ loading: false })
        }
      });
  }

  handleValidSubmit = (_ev, values) => {

    const snippetContent = encodeSnippet(values.messaging, "flexibleMessage")

    if (this.state.netlifySnippetId) {
      // Update Netlify Snippet, fetches again because of potencial snippet ID changes
      getNetlifySnippetByTitle(this.context.reseller_site_id, snippetTitle)
        .then((response) => {
          if (response.id && response.general) {


            updateNetlifySnippet(this.context.reseller_site_id, snippetTitle, snippetContent, response.id).then((response) => {
              if (response.status === 200) {
                this.setState({
                  alert: true,
                  alertMessage: "Success: Your Flexible Message was updated on your site.",
                  alertColor: "success"
                });
                // Fetch the Snippet ID and update state
                this.fetchNetlifySnippet();
              } else {
                console.log(response);
                this.setState({
                  alert: true,
                  alertMessage: response.message,
                  alertColor: "danger"
                });
              }
            });
          }
        })
    } else {
      // Add Netlify Snippet
      addNetlifySnippet(this.context.reseller_site_id, snippetTitle, snippetContent)
        .then((response) => {
          if (response.status === 201) {
            this.setState({
              alert: true,
              alertMessage: "Success: Your Flexible Message was added to your site.",
              alertColor: "success"
            });
            // Fetch the Snippet ID and update state
            this.fetchNetlifySnippet();
          } else {
            console.log(response);
            this.setState({
              alert: true,
              alertMessage: response.message,
              alertColor: "danger"
            });
          }
        });
    }
  }

  toggle() {
    this.setState({
      alert: !this.state.alert
    });
  }

  render() {

    if (this.state.loading) return <Loader />

    const { flexibleMessage } = this.state

    return (
      <div className="card">
        <div className="card-header pb-0 mb-0">
          <h5>Flexible Messaging Area</h5>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-4 col-xl-2 pr-md-3 mb-4">
              <p className="text-secondary">The flexible messaging area appears at the top of your website and can be used to advertise promotions, new products, etc. If you don’t want to display any messaging, simply delete the text in the input field.</p>
            </div>
            <div className="col-md-8 col-xl-9 offset-xl-1">
              <AvForm
                className="user-add offset-lg-1 needs-validation add-product-form"
                onValidSubmit={this.handleValidSubmit}
                onInvalidSubmit={this.handleInvalidSubmit}
                model={flexibleMessage}
              >
                <div className="col-xl-12 col-md-12">
                  <AvField
                    className="form-control"
                    type="text"
                    name="messaging"
                    placeholder="Save 25% on all Kwik-Ship order now through May"
                  />
                </div>
                <div className="col-xl-4 col-sm-12 col-md-6 mt-4">
                  <button className="btn btn-primary">
                    Save
                  </button>

                </div>
              </AvForm>
              <br/><br/>
              <Alert className="offset-lg-1" color={this.state.alertColor} isOpen={this.state.alert} toggle={this.toggle.bind(this)} > {this.state.alertMessage} </Alert>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

CustomizeMessage.contextType = AuthContext

export default CustomizeMessage;
