
const MetafieldDefinitionValidation = {
  "name": "MetafieldDefinitionValidation",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "name": "String",
    "type": "String",
    "value": "String"
  },
  "implementsNode": false
};
export default MetafieldDefinitionValidation;