
const PriceRule = {
  "name": "PriceRule",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "allocationLimit": "Int",
    "allocationMethod": "PriceRuleAllocationMethod",
    "app": "App",
    "combinesWith": "DiscountCombinesWith",
    "createdAt": "DateTime",
    "customerSelection": "PriceRuleCustomerSelection",
    "discountClass": "DiscountClass",
    "discountCodes": "PriceRuleDiscountCodeConnection",
    "discountCodesCount": "Count",
    "endsAt": "DateTime",
    "entitlementToPrerequisiteQuantityRatio": "PriceRuleEntitlementToPrerequisiteQuantityRatio",
    "events": "EventConnection",
    "features": "PriceRuleFeature",
    "hasTimelineComment": "Boolean",
    "id": "ID",
    "itemEntitlements": "PriceRuleItemEntitlements",
    "itemPrerequisites": "PriceRuleLineItemPrerequisites",
    "legacyResourceId": "UnsignedInt64",
    "oncePerCustomer": "Boolean",
    "prerequisiteQuantityRange": "PriceRuleQuantityRange",
    "prerequisiteShippingPriceRange": "PriceRuleMoneyRange",
    "prerequisiteSubtotalRange": "PriceRuleMoneyRange",
    "prerequisiteToEntitlementQuantityRatio": "PriceRulePrerequisiteToEntitlementQuantityRatio",
    "shareableUrls": "PriceRuleShareableUrl",
    "shippingEntitlements": "PriceRuleShippingLineEntitlements",
    "startsAt": "DateTime",
    "status": "PriceRuleStatus",
    "summary": "String",
    "target": "PriceRuleTarget",
    "title": "String",
    "totalSales": "MoneyV2",
    "traits": "PriceRuleTrait",
    "usageCount": "Int",
    "usageLimit": "Int",
    "validityPeriod": "PriceRuleValidityPeriod",
    "value": "PriceRuleValue",
    "valueV2": "PricingValue"
  },
  "implementsNode": true
};
export default PriceRule;