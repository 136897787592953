
const TypedAttribute = {
  "name": "TypedAttribute",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "key": "String",
    "value": "String"
  },
  "implementsNode": false
};
export default TypedAttribute;