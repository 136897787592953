
const ProductVariantAppendMediaPayload = {
  "name": "ProductVariantAppendMediaPayload",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "product": "Product",
    "productVariants": "ProductVariant",
    "userErrors": "MediaUserError"
  },
  "implementsNode": false
};
export default ProductVariantAppendMediaPayload;