
const RefundShippingLineEdge = {
  "name": "RefundShippingLineEdge",
  "kind": "OBJECT",
  "fieldBaseTypes": {
    "cursor": "String",
    "node": "RefundShippingLine"
  },
  "implementsNode": false
};
export default RefundShippingLineEdge;